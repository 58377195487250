import React, { Component } from 'react';
import PopupModal from '../Controls/Popup/Popup';
import { WINDOW_SIZES } from '../../../constants/main';
import ResponsiveContent from '../ResponsiveContent/ResponsiveContent';

interface Props {
  content: any;
  direction?: 'left' | 'right' | 'up' | 'down';
  position?: 'left' | 'right' | 'top' | 'bottom';
}
interface State {
  visible: boolean;
}

export default class Tooltip extends Component<Props, State> {
  state = {
    visible: false,
  };

  handleIn = () => {
    if (this.state.visible) return;
    this.setState({ visible: true });
  };

  handleOut = () => {
    if (!this.state.visible) return;
    this.setState({ visible: false });
  };

  toggleModal = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const { position = 'bottom', direction = 'right' } = this.props;
    return (
      <>
        <ResponsiveContent max={WINDOW_SIZES.md}>
          <PopupModal
            onClose={this.toggleModal}
            content={this.props.content}
            visible={this.state.visible}
            position={position}
            direction={direction}
          >
            <div onClick={this.toggleModal}>{this.props.children}</div>
          </PopupModal>
        </ResponsiveContent>
        <ResponsiveContent min={WINDOW_SIZES.md}>
          <PopupModal
            content={this.props.content}
            visible={this.state.visible}
            position={position}
            direction={direction}
          >
            <div
              onMouseEnter={this.handleIn}
              onMouseOver={this.handleIn}
              onMouseOut={this.handleOut}
              onMouseLeave={this.handleOut}
            >
              {this.props.children}
            </div>
          </PopupModal>
        </ResponsiveContent>
      </>
    );
  }
}
