import { observer } from 'mobx-react';
import { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { localeStore } from 'src/mobx/localesStore';
import OrderTypeSwitcher from 'src/view/containers/OrderTypeSwitcher/OrderTypeSwitcher';
import Modal from '../../../../components/Controls/Modal/Modal';
import './style.scss';

@observer
export default class MenuClosed extends Component {
  state = { modalVisible: true };

  onChange = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    return (
      <Modal className="menu-order_type-modal" isOpen={this.state.modalVisible} withPane>
        <div className="menu-order_type-container">
          {!localeStore.loading ? (
            <>
              <p className="text-size-24 family-bold">{localeStore.t('select_order_type')}</p>
            </>
          ) : (
            <>
              <Skeleton width={230} height={50} style={{ borderRadius: '16px' }} />
            </>
          )}
          <OrderTypeSwitcher
            className={'menu-order_type-container-switcher'}
            onChange={this.onChange}
            styleButton={{ background: '#FAFAFA' }}
          />
        </div>
      </Modal>
    );
  }
}
