import { observer } from 'mobx-react';
import * as React from 'react';
import { PAYMENT_TYPES } from 'src/constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { checkoutStore } from 'src/mobx/checkoutStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { getWalletCurrency } from 'src/utils/helpers';
import { PRODUCT_TYPE } from 'src/types/products';
import './style.scss';
import { appStore } from 'src/mobx/appStore';
import Toggle from 'src/view/components/Controls/Toggle/Toggle';
import Image from 'src/view/components/Image/Image';
import { messagesStore } from 'src/mobx/messagesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { ICONS } from 'src/constants/icons';
import { formatPrice } from 'src/utils/formatter';
import { Link } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { branchStore } from 'src/mobx/barnchStore';
import WalletTermsModalContent from 'src/view/screens/CheckoutScreen/containers/CheckoutWallet/WalletTermsModalContent';

@observer
export default class CheckoutWallet extends React.Component {
  handleWalletToggle = () => {
    if (this.disabled) return;

    if (cartStore.checkout.use_wallet === 0) {
      cartStore.setCheckoutWallet(1);
    } else {
      cartStore.setCheckoutWallet(0);
    }
  };

  handleTermsClick = () => {
    messagesStore.addMessage({
      id: 'wallet_limit',
      type: 'modal',
      title: localeStore.t('consumer_wallet_tc_popup_hdr'),
      message: localeStore.t('consumer_wallet_tc_bodytext_hdr', { value: `${appStore.settings?.wallet_usage_limit}%` }),
      Content: <WalletTermsModalContent />,
      options: [{ text: localeStore.t('close') }],
    });
  };

  get disabled(): boolean {
    return (
      Boolean(cartStore.checkout.promocode) ||
      !userStore.user.wallet?.balance ||
      checkoutStore.reorderStore?.order?.promocode
    );
  }

  public render() {
    const withMeal = cartStore.products.some(
      (product) => product.type === PRODUCT_TYPE.MEAL_COMBO || product.type === PRODUCT_TYPE.DEAL_COMBO
    );
    const walletCurrency = getWalletCurrency();

    if (
      cartStore.checkout.payment_type === PAYMENT_TYPES.POINTS ||
      withMeal ||
      (branchStore.branch.currency &&
        String(branchStore.branch.currency?.id) !== String(appStore.settings?.wallet_currency_id)) ||
      userStore.user.wallet?.balance === 0
    ) {
      return null;
    }

    const userBalance = formatPrice(userStore.user.wallet.balance, walletCurrency, 'r');
    const availableWalletDiscount = formatPrice(cartStore.calculator.available_wallet_discount, walletCurrency, 'r');

    return (
      <div className="mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <p className="text-size-16 mr-2">{localeStore.t('consumer_use_wallet_toggle')}</p>
              <Icon name={ICONS.WALLET_2} className="text-size-16 color-primary" />
            </div>
            {appStore.settings?.is_wallet_usage_limit ? (
              <div className="d-flex align-items-center cursor-pointer mt-2" onClick={this.handleTermsClick}>
                <p className="text-size-12 color-gray">
                  {localeStore.t('consumer_wallet_usage_limit_used_from_txt', {
                    available_amount: availableWalletDiscount,
                    amount: userBalance,
                  })}
                </p>
                <Icon name={ICONS.INFO} className="ml-2 text-size-14 color-gray" />
              </div>
            ) : (
              <p className="text-size-12 color-gray mt-2">{userBalance}</p>
            )}
          </div>
          <Toggle checked={Boolean(cartStore.checkout.use_wallet)} onToggle={this.handleWalletToggle} />
        </div>
        {cartStore.checkout.use_wallet && appStore.settings?.cashback ? (
          <div className="wallet-toggle__warning">
            <p className="color-gray text-size-14 mt-1 text-md-size-14">
              {localeStore.t('no_cashback_if_wallet_used_infomessage')}
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}
