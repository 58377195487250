import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { ADDED_FROM, IProduct } from 'src/types/products';
import Image from 'src/view/components/Image/Image';
import { localeStore } from 'src/mobx/localesStore';
import { getTranslate } from 'src/utils/formatter';
import React from 'react';
import './style.scss';
import { cartStore } from 'src/mobx/cartStore';

interface IProps {
  product: IProduct | undefined;
}

const CrossSellingProduct = (props: IProps): JSX.Element => {
  if (!props.product) {
    return <Image wrapperClassName={'cross-selling-product mr-3'} src={undefined} aspectRatio={1.5} alt="No image" />;
  }
  return (
    <Link
      rel="tag"
      title={getTranslate(props.product.name)}
      hrefLang={localeStore.language}
      to={{
        pathname: cartStore.isSMS ? SCREENS.SMS_PRODUCT(props.product.id) : SCREENS.PRODUCT(props.product.id),
        search: `?language=${localeStore.language}`,
        state: { product: toJS({ ...props.product, ...{ added_from: ADDED_FROM.CROSS_SELLING } }) },
      }}
      className="cross-selling-product mr-3"
    >
      <Image
        src={props.product ? props.product.image : undefined}
        aspectRatio={1.5}
        alt={getTranslate(props.product.name)}
      />
    </Link>
  );
};

export default React.memo(CrossSellingProduct);
