import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { checkoutStore } from 'src/mobx/checkoutStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import { Discount } from 'src/view/containers/Discount/Discount';
import LoadingCircle from 'src/view/screens/CheckoutScreen/containers/LoadingCircle/LoadingCircle';
import './style.scss';

const CartTotalInfo = observer((): JSX.Element => {
  // Promocode from calculator or reoreder
  const promocode = useMemo(() => {
    return {
      name: cartStore.calculator.promocode || checkoutStore.reorderStore?.order?.promocode,
      amount: cartStore.calculator.discount_amount || checkoutStore.reorderStore?.order.promocode_amount,
      description:
        cartStore.calculator?.discount_description || checkoutStore.reorderStore?.order?.promocode_description,
    };
  }, [cartStore.calculator, checkoutStore.reorderStore?.order]);

  const vatPercent = useMemo(() => {
    if (branchStore.vat_percent && !cartStore.isDonationOnly) return branchStore.vat_percent + '%';
    return '';
  }, [cartStore.isDonationOnly]);
  return (
    <div className=" cart-total-info cart-section full-width mb-3">
      {/* delivery fee TODO:  and selected payment type !== points */}
      <div className="d-flex full-width flex-column my-n2">
        {branchStore.delivery_type === DELIVERY_TYPES.DELIVERY ? (
          <div className="d-flex flex-row justify-content-between my-2 full-width">
            <p className={' family-semibold text-size-14'}>{localeStore.t('delivery_fee_excl_vat')}</p>
            <LoadingCircle>
              <p id="cart_delivery_fee" className={'color-dark family-semibold text-size-14'}>
                {formatPrice(cartStore.calculator.delivery_fee)}
              </p>
            </LoadingCircle>
          </div>
        ) : null}

        {/* Discount */}
        {cartStore.calculator?.promocode ? (
          <div className="d-flex flex-row justify-content-between my-2 full-width">
            <Discount className="full-width" {...promocode} loading={cartStore.calculator.loading} />
          </div>
        ) : null}

        {/* VAT % */}
        <div className="d-flex flex-column my-2 full-width">
          <div className="d-flex flex-row justify-content-between full-width">
            <p className={' family-semibold text-size-14'}>{`${localeStore.t('vat')} ${vatPercent}`}</p>
            <LoadingCircle>
              <p id="cart_vat" className={'color-dark family-semibold text-size-14'}>
                {formatPrice(cartStore.calculator.vat + cartStore.calculator.taxable_delivery_fee)}
              </p>
            </LoadingCircle>
          </div>
          {/** Vat registration number */}
          {branchStore.vat_registration_number ? (
            <div className="d-flex flex-row mt-1 justify-content-between">
              <p className={'color-gray text-size-10 mr-2'}>{localeStore.t('vat_registration_number')}:</p>
              <LoadingCircle>
                {branchStore.branch?.vat_certificate_url ? (
                  <a
                    href={branchStore.branch.vat_certificate_url}
                    target="_blank"
                    rel="noreferrer"
                    id="cart_vat_registration_number"
                    className={'color-primary family-semibold text-size-10 link'}
                  >
                    {branchStore.vat_registration_number}
                  </a>
                ) : (
                  <p id="cart_vat_registration_number" className={'color-primary family-semibold text-size-10'}>
                    {branchStore.vat_registration_number}
                  </p>
                )}
              </LoadingCircle>
            </div>
          ) : null}
        </div>

        {/* if  points available and payment type not points*/}
        {/* TODO: base on payment type */}
        {appStore.settings?.is_points ? (
          <div className="d-flex flex-row justify-content-between my-2 full-width">
            <p className={' family-semibold text-size-14'}>{localeStore.t('txt_earn_points')}</p>
            <LoadingCircle>
              <p id="cart_earn_points" className={'color-dark family-semibold text-size-14 text-uppercase'}>
                {formatPrice(cartStore.calculator.earn_points, localeStore.t('txt_points_currency'))}
              </p>
            </LoadingCircle>
          </div>
        ) : null}

        {/* if  points available and payment type is points*/}
        {/* TODO: base on payment type */}
        {/* {appStore.settings?.is_points ? (
          <React.Fragment>
            <div className="d-flex flex-row justify-content-between mb-3 mb-sm-4 full-width">
              <p className={' family-semibold text-size-12 text-size-md-14'}>
                {localeStore.t('txt_balance_points')}
              </p>
              <p className={'color-gray family-semibold text-size-14 text-size-md-16'}>{formatPrice(userStore.user.points)}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mb-3 mb-sm-4 full-width">
              <p className={' family-semibold text-size-12 text-size-md-14'}>
                {localeStore.t('txt_redeem_points')}
              </p>
              <p className={'color-gray family-semibold text-size-14 text-size-md-16'}>
                {formatPrice(cartStore.calculator.redeem_points)}
              </p>
            </div>
          </React.Fragment>
        ) : null} */}
      </div>
    </div>
  );
});

export default React.memo(CartTotalInfo);
