import client from 'src/services/client';
import { IAddressCreate, IAddressResponse } from '../types/address';

export const createAddress = async (address: IAddressCreate): Promise<IAddressResponse> => {
  try {
    return await client.post('/v3/me/addresses', address);
  } catch (error) {
    throw error;
  }
};

export const updateAddress = async (id: number, address: IAddressCreate): Promise<IAddressResponse> => {
  try {
    return await client.put(`/v3/me/addresses/${id}`, address);
  } catch (error) {
    throw error;
  }
};

export const getAddress = async (id: number): Promise<IAddressResponse> => {
  try {
    return await client.get(`/v3/me/addresses/${id}`);
  } catch (error) {
    throw error;
  }
};

export const getUserAddresses = async (user_id?: number): Promise<[IAddressResponse]> => {
  try {
    return await client.get(`/v3/me/addresses`);
  } catch (error) {
    throw error;
  }
};

export const deleteUserAddress = async (id: number): Promise<{}> => {
  try {
    return await client.delete(`/v3/me/addresses/${id}`);
  } catch (error) {
    throw error;
  }
};
