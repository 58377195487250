import { RenderArrowProps } from 'react-elastic-carousel';
import { ICONS } from 'src/constants/icons';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';

const ComboCarouselArrow: React.FC<RenderArrowProps> = ({ type, isEdge, onClick }: RenderArrowProps): JSX.Element => {
  return (
    <div
      className={`rec-arrow rec-arrow-${type === 'PREV' ? 'left' : 'right'} ${isEdge ? 'rec-arrow_hidden' : ''}`}
      onClick={onClick}
    >
      <Icon name={type === 'PREV' ? ICONS.SLIDER_LEFT : ICONS.SLIDER_RIGHT} className="color-white" />
    </div>
  );
};

// don't use memo, may cause app to crash
export default ComboCarouselArrow;
