import { observer } from 'mobx-react';
import { tryPaymentAgainStore } from '../../store';
import { ORDER_STATUS, PAYMENT_STATUS } from '../../../../../constants/main';
import { localeStore } from '../../../../../mobx/localesStore';
import { formatPrice } from '../../../../../utils/formatter';
import './style.scss';

const PaymentTotal = observer(() => {
  if (
    tryPaymentAgainStore.order?.order_status_code === ORDER_STATUS.WAITING_FOR_PAYMENT &&
    !tryPaymentAgainStore.timeOut &&
    ![PAYMENT_STATUS.NON_RECEIVED, PAYMENT_STATUS.UNDEFINED].includes(tryPaymentAgainStore.order?.payment_status)
  ) {
    return (
      <div className={'payment-total-wrapper'}>
        <p className={'total text-center'}>
          {tryPaymentAgainStore.order?.total ? formatPrice(tryPaymentAgainStore.order?.total) : null}
        </p>
        <span className="vat text-center">{localeStore.t('VAT inclusive')}</span>
      </div>
    );
  }
  return null;
});

export default PaymentTotal;
