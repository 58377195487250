import React from 'react';
import ResponsiveContent from '../../../../../../components/ResponsiveContent/ResponsiveContent';
import { WINDOW_SIZES } from '../../../../../../../constants/main';
import Skeleton from 'react-loading-skeleton';
import MenuProduct from '../../../../components/MenuProduct/MenuProduct';

export const MenuSearchSkeleton: React.FC = () => (
  <div className='search-menu'>
    <div className='search-menu-category'>
      <ResponsiveContent min={WINDOW_SIZES.md}>
        <Skeleton width={60} height={60} />
      </ResponsiveContent>
      <ResponsiveContent max={WINDOW_SIZES.md}>
        <Skeleton width={100} height={25} />
      </ResponsiveContent>
    </div>
    <div className='search-menu-items-container'>
      {[1, 2, 3, 4].map((i) => (
        <MenuProduct key={i} type='search-item'/>
      ))}
    </div>
  </div>
);
