import { FC, ReactElement, memo, useState } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { IProductExclusion, IProductModifier } from 'src/types/products';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import Image from 'src/view/components/Image/Image';
import { MealComboGroupMobileItemModal } from '../../containers/MealComboGroupMobileItemModal/MealComboGroupMobileItemModal';
import './style.scss';

interface IProps {
  item: IProductInsideCombo;
  readOnly?: boolean;
  onSelectItem: (item: IProductInsideCombo) => void;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
}

export const MealComboGroupMobileItem: FC<IProps> = memo(({ item, ...props }: IProps): ReactElement => {
  const [openItemModal, setOpenItemModal] = useState<boolean>(false);

  const units = productUtils.getComboItemUnits(item);
  const unavailableMessage: string | undefined = productUtils.getComboItemUnavailableMessage(item, true);

  return (
    <div className="product-meal-group-mobile-item" onClick={() => setOpenItemModal(true)}>
      <Image
        aspectRatio={1.05}
        src={item.image}
        wrapperClassName={`product-meal-group-mobile-item__image-wrapper ${unavailableMessage ? ' opacity-50' : ''}`}
      />

      {unavailableMessage ? (
        <p className="product-meal-group-mobile-item__unavailable-msg letter-spacing-5">
          {localeStore.t(unavailableMessage)}
        </p>
      ) : null}

      <div className="product-meal-group-mobile-item__text-block">
        <p className="family-bold text-size-14 color-dark text-overflow">{getTranslate(item.name)}</p>
        <p className="family-regular text-size-12 color-gray text-overflow">{units}</p>
      </div>

      <ButtonComponent
        title={formatPrice(productUtils.calculateProductInsideComboPrice(item))}
        className="product-meal-group-mobile-item__button color-primary"
      />

      {openItemModal && (
        <MealComboGroupMobileItemModal
          item={item}
          unavailableMessage={unavailableMessage}
          readOnly={props.readOnly}
          onSelectItem={props.onSelectItem}
          onModifierChange={props.onModifierChange}
          onExclusionsChange={props.onExclusionsChange}
          onCloseModal={() => setOpenItemModal(false)}
        />
      )}
    </div>
  );
});
