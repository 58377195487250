import React, { Component } from 'react';
import { observer } from 'mobx-react';
import '../style.scss';
import Modal from '../../../../../components/Controls/Modal/Modal';
import { localeStore } from 'src/mobx/localesStore';
import { cartStore } from 'src/mobx/cartStore';
import { IEMenuSection, IDineInItem } from 'src/types/branch';
import { branchStore } from 'src/mobx/barnchStore';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
};

@observer
export default class CheckoutDineInSection extends Component<Props> {
  onClickItem = (item: IDineInItem) => () => {
    cartStore.setSection(item);
    this.props.onRequestClose();
  };

  renderItem = ({ id, title }: IEMenuSection, index: number) => (
    <span
      className="text-size-18 family-regular checkout-dine_in-modal-container-item"
      key={index}
      onClick={this.onClickItem({ id, title })}
    >
      {title}
    </span>
  );

  closeModal = () => {
    this.setState({ isVisible: false });
  };

  render() {
    return (
      <Modal className="checkout-dine_in-modal" isOpen={this.props.isOpen} withPane>
        <div className="checkout-dine_in-modal-wrapper">
          <div className="checkout-dine_in-modal-container">
            <p className="text-size-24 family-bold checkout-dine_in-modal-container-title">
              {localeStore.t('select_section')}
            </p>
            {branchStore.branch?.emenu?.sections?.map(this.renderItem)}
          </div>
        </div>
      </Modal>
    );
  }
}
