import { FC, ReactElement, memo } from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { IProductInsideCombo } from 'src/types/combo';
import { IProduct, IProductExclusion, IProductModifier, IProductSize, PRODUCT_TYPE } from 'src/types/products';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import { IProductCashbackInfo } from '../../ProductScreen';
import ProductImagesBlock from '../../containers/ProductImagesBlock/ProductImagesBlock';
import ProductInfoBlock from '../../containers/ProductInfoBlock/ProductInfoBlock';
import ProductSubmitBlock from '../../containers/ProductSubmitBlock/ProductSubmitBlock';
import { DealComboItemsBlockMobile } from './containers/DealComboItemsBlockMobile/DealComboItemsBlockMobile';
import { MealComboItemsBlockMobile } from './containers/MealComboItemsBlockMobile/MealComboItemsBlockMobile';

interface IProps {
  product: IProduct | undefined;
  cashback: IProductCashbackInfo;
  modifiersErrors: string[];
  needScrollToError: boolean;
  isUpdateProduct: boolean;
  readOnly: boolean;
  onSizeChange: (size: IProductSize) => void;
  onChangeQuantity: (value: number) => void;
  onNotesChanged: (notes: string) => void;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
  onSelectDealItem: (item: IProductInsideCombo) => void;
  onSelectMealItem: (item: IProductInsideCombo) => void;
  setNeedScrollToError: (need: boolean) => void;
  onOpenDeleteModal: () => void;
  onCloseModal: () => void;
}

export const ProductComboScreenMobile: FC<IProps> = memo(({ product, ...props }: IProps): ReactElement => {
  const isMeal: boolean = product?.type === PRODUCT_TYPE.MEAL_COMBO;

  return (
    <ResponsiveContent max={WINDOW_SIZES.sm}>
      <div className="d-flex flex-column full-width" style={{ overflowY: 'auto', height: 'inherit' }}>
        <ProductImagesBlock
          product={product}
          updateProduct={props.isUpdateProduct && cartStore.isEditable}
          openDeleteModal={props.onOpenDeleteModal}
        />

        <ProductInfoBlock
          product={product}
          cashback={props.cashback}
          onNotesChanged={props.onNotesChanged}
          onSizeChange={props.onSizeChange}
        />

        {isMeal ? (
          <MealComboItemsBlockMobile
            product={product}
            modifiersErrors={props.modifiersErrors}
            needScrollToError={props.needScrollToError}
            readOnly={props.readOnly}
            onNotesChanged={props.onNotesChanged}
            onSelectItem={props.onSelectMealItem}
            onModifierChange={props.onModifierChange}
            onExclusionsChange={props.onExclusionsChange}
            setNeedScrollToError={props.setNeedScrollToError}
          />
        ) : (
          <DealComboItemsBlockMobile
            product={product}
            onSelectItem={props.onSelectDealItem}
            onNotesChanged={props.onNotesChanged}
          />
        )}

        <ProductSubmitBlock
          product={product}
          cashback={props.cashback}
          errors={props.modifiersErrors}
          needScrollToError={props.needScrollToError}
          onSizeChange={props.onSizeChange}
          changeQuantity={props.onChangeQuantity}
          setNeedScrollToError={props.setNeedScrollToError}
          closeModal={props.onCloseModal}
        />
      </div>
    </ResponsiveContent>
  );
});
