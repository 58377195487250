import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { WINDOW_SIZES } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { productsStore } from 'src/mobx/productsStore';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import ProductScreen from '../ProductScreen/ProductScreen';
import MenuBanner from './containers/MenuBanner/MenuBanner';
import MenuCart from './containers/MenuCart/MenuCart';
import MenuCategories from './containers/MenuCategories/MenuCategories';
import MenuProducts from './containers/MenuProducts/MenuProducts';
import MenuClosed from './containers/MenuClosed/MenuClosed';
import MenuOrderType from './containers/MenuOrderType/MenuOrderType';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { getRestaurantName, setMetaTags } from 'src/utils/helpers';
import { getTranslate } from 'src/utils/formatter';
import './styles.scss';
interface RouteParams {
  id?: string;
}

@observer
export default class MenuScreen extends Component<RouteComponentProps<RouteParams>> {
  state = {
    categoryId: this.props.match.params.id,
  };

  componentDidMount = async () => {
    setMetaTags({
      title: `${getRestaurantName()}`,
      'og:title': `${getRestaurantName()}`,
      'og:type': 'website',
      'og:image': `${appStore.settings?.img_logo}`,
      'twitter:card': 'summary',
      'twitter:site': `${appStore.settings?.domain}`,
      'twitter:title': `${getRestaurantName()}`,
      'twitter:image': `${appStore.settings?.img_logo}`,
      'twitter:domain': `${appStore.settings?.domain}`,
    });

    await productsStore.fetchMenu();
    // APPSFLYER SUPPORT
    // TODO: MOVE THIS
    const params = new URLSearchParams(this.props.location.search);
    const [category, product] = [this.state.categoryId, params.get('product')];

    if (product) {
      this.props.history.push(SCREENS.PRODUCT(product));
    }
    if (category) {
      productsStore.setCategory(Number(category));
      const findCategory = productsStore.categories.find((item) => item.id === Number(category));
      if (findCategory)
        setMetaTags({
          title: `${getRestaurantName()} - ${getTranslate(findCategory.name)}`,
          description: getTranslate(findCategory.category_meta_description),
          keywords: findCategory.items.data.map((el) => getTranslate(el.name)).join(','),
          'og:title': `${getRestaurantName()} - ${getTranslate(findCategory.name)}`,
          'og:description': getTranslate(findCategory.category_meta_description),
          'og:type': 'website',
          'twitter:title': `${getRestaurantName()} - ${getTranslate(findCategory.name)}`,
          'twitter:description': getTranslate(findCategory.category_meta_description),
        });
    }
  };

  render() {
    return (
      <>
        <div className="container mt-4 menu-container">
          <MenuBanner />
        </div>
        <div className="container">
          <MenuCategories />
        </div>
        <div className="container">
          <MenuProducts />
        </div>
        <div className="container">
          <MenuClosed />
        </div>

        {appStore.mode === 'e-menu' && branchStore.branch.emenu?.order_types && !branchStore.order_type ? (
          <ResponsiveContent max={WINDOW_SIZES.md}>
            <MenuOrderType />
          </ResponsiveContent>
        ) : null}

        {appStore.mode === 'e-menu' && branchStore.branch.delivery_type === DELIVERY_TYPES.DELIVERY ? null : (
          <ResponsiveContent max={WINDOW_SIZES.md}>
            <MenuCart />
          </ResponsiveContent>
        )}

        <Switch>
          <Route path={SCREENS.CATEGORY(':id')} />
          <Route path={SCREENS.PRODUCT(':id')} component={ProductScreen} />
        </Switch>
      </>
    );
  }
}
