import add from 'date-fns/add';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import { arSA, enUS } from 'date-fns/locale';
import moment from 'moment';
import { ICONS } from 'src/constants/icons';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { IPaymentCard } from 'src/types/user';
import { ORDER_STATUS } from '../constants/main';
import { IAddressResponse } from '../types/address';
import { IBranch, IBranchPaymentType, UnitTime } from '../types/branch';
import { getTranslate } from './formatter';
import { debounce } from './perfomance';
import { CashbackErrorCode } from 'src/constants/errors';

export const delay = async (ms: number = 1000) => new Promise((res) => setTimeout(res, ms));

export const isRTL: boolean = localeStore?.language === 'ar';

export const isObject = (data: any) => {
  if (data === null && !Array.isArray(data)) return false;
  return typeof data === 'object';
};

export const getUserPositionPromise = (): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      maximumAge: 50000,
      timeout: 5000,
      enableHighAccuracy: true,
    });
  });

export const getUserPosition = async (): Promise<GeolocationPosition | null> => {
  try {
    return await getUserPositionPromise();
  } catch (error: any) {
    logError(error);
    return null;
  }
};

export const getStatusColor = (order_status_code: string) => {
  let color = '#000000';
  switch (true) {
    case order_status_code === ORDER_STATUS.RETURNED:
    case order_status_code === ORDER_STATUS.CANCELED:
    case order_status_code === 'is_canceled':
      color = '#e00d30';
      break;
    case order_status_code === ORDER_STATUS.PENDING:
    case order_status_code === '':
    case order_status_code === 'is_soon':
    case order_status_code === 'is_pending':
      color = '#FFA775';
      break;
    case order_status_code !== ORDER_STATUS.RETURNED:
    case order_status_code !== ORDER_STATUS.CANCELED:
    case order_status_code !== ORDER_STATUS.PENDING:
    case order_status_code === ORDER_STATUS.ON_HOLD:
    case order_status_code !== 'is_ready':
    case order_status_code !== 'is_attended':
      color = '#75FF83';
      break;

    default:
      break;
  }
  return color;
};

export const isToday = (time: number | Date) => {
  const now = Date.now();

  return isSameDay(time, now);
};

export const dateFormat = (date: Date | number, formatStr: string): string =>
  format(date, formatStr, { locale: localeStore.language === 'ar' ? arSA : enUS });

/**
 * Get preparation unit translate key
 *
 * 2 minutes = دقيقتان
 *
 * 3-10 minutes = دقائق
 *
 * >=11 minutes = دقيقة
 * @param {number} gap
 * @param {UnitTime} unit
 * @return {*}
 */
export const getUnitKey = (gap: number, unit: UnitTime) => {
  let key: string = unit;
  if (unit === 'm') {
    key = 'minute';
    // 2 minutes = دقيقتان
    if (gap === 2) key = 'minutes_2';
    // 3-10 minutes = دقائق
    if (gap > 2 && gap <= 10) key = 'minutes_less_10';
    // > 11 minutes = دقيقة
    if (gap >= 11) key = 'minutes_more_11';
  }
  return key;
};

export const _isNaN = (value: any) => {
  if (typeof value !== 'number') return true;
  return isNaN(value);
};

export const getNearestWorkingTimeMessage = (): string => {
  try {
    return branchStore.branch.working_time
      .map((day) => {
        // 'hh:mm:ss'
        return `${localeStore.t(`txt_${day.working_day}`)} ${format(new Date(day.start_time), 'hh:mm:ss')} - ${format(
          new Date(day.close_time),
          'hh:mm:ss'
        )}`;
      })
      .join(' \n');
  } catch (error: any) {
    return '';
  }
};

export const getPreorderIsAvailable = (branch?: IBranch | null, shortMessage?: boolean): string | null => {
  if (!branch || branch?.id === 0 || !branch?.preparation || !branch.preorder) return null;

  const availableDays = [];
  for (let i = 0; i <= Number(branch.preparation!.preorder.days); i++) {
    const dayName = format(addDays(new Date(), i), 'EEEE').toLowerCase();
    availableDays.push(dayName);
  }

  const isAvailableForPreorder = availableDays.find((availableDay) => {
    return !!branch.working_time.find((item) => item.working_day === availableDay);
  });

  if (branch.preorder) {
    return isAvailableForPreorder ? localeStore.t(shortMessage ? 'txt_preorder' : 'preorder_available') : null;
  }

  return null;
};

export const getPreorderMessage = (preorderIsAvailable: boolean, shortMessage?: boolean): string | null => {
  if (preorderIsAvailable) {
    return localeStore.t(shortMessage ? 'txt_preorder' : 'preorder_available');
  }

  return null;
};

export const addUnit = (date: Date, unit: UnitTime, value: number) => {
  switch (unit) {
    case 'd':
      return add(date, { days: value });
    case 'h':
      return add(date, { hours: value });
    default:
      return add(date, { minutes: value });
  }
};
export const getWalletCurrency = () => {
  let currency =
    localeStore.language === 'ar' ? appStore.settings?.wallet_currency_ar : appStore.settings?.wallet_currency;
  if (!currency) currency = localeStore.t('wallet_currency');

  return currency;
};

export const getPhoneNumber = (value: string): string => {
  return value[0] === '+' ? value : `+${value}`;
};

/**
 * Get user's last payment card.
 * If last_payment card not exist then return first user card or null
 *
 * @param {IPaymentCard[]} payment_cards - array of user cards
 * @return {*}  {(IPaymentCard | null)} - user's card or null
 */
export const getUserLastCard = (payment_cards: IPaymentCard[]): IPaymentCard | null => {
  const last_payment_card =
    payment_cards.find((card) => card.id === userStore.user?.last_payment_card) || payment_cards[0];
  return last_payment_card || null;
};

/**
 * Get card icon
 * Depends on type of card
 *
 * @param {string} type - type card
 * @return {*} - name of card icon
 */
export const getCardIcon = (type: string) => {
  if (type === 'Visa') {
    return ICONS.VISA_CARD;
  } else if (type === 'MasterCard') {
    return ICONS.MASTER_CARD;
  } else {
    return ICONS.CREDIT_CARD;
  }
};

/**
 * Get Payment Type from branch payment types ( payment_types.type has already been translated )
 *
 * @param {(IBranchPaymentType['id'] | 4)} type_id - id payment type
 * @return {*} - payment type text
 */
export const getPaymentType = (type_id: IBranchPaymentType['id'] | 4) => {
  return (
    branchStore.getPaymentTypes.find((el) => el.id === type_id)?.type || localeStore.t('Select_payment_type_field')
  );
};

export const isPM = (time: string) => moment(time, 'h:mm A', 'en').format('A') === 'PM';

/**
 * Get translated 12 hours format time
 *
 * @param {string} time
 * @return {*} string
 */
export const getLocaleTime = (time: string) => {
  return `${moment(time, ['h:mm A']).format('hh:mm')} ${localeStore.t(isPM(time) ? 'PM' : 'AM')}`;
};

/**
 * Get translated 12 hours format slot time
 *
 * @param {string} time
 * @return {*} string
 */
export const getLocaleTimeSlot = (start: string, end: string) => {
  // Example: 1:00 PM and 5:00 PM is same of 6:00AM and 11:00AM
  const isSameTimeSlot = isPM(start) === isPM(end);

  const start_time = isSameTimeSlot ? moment(start, ['h:mm A']).format('hh:mm') : getLocaleTime(start);
  const end_time = getLocaleTime(end);

  return `${start_time} - ${end_time}`;
};

export const findElementAndSetAttr = (tag: string, value?: string | null) => {
  const isOpenGraph = tag.includes('og:');
  const restaurant = getRestaurantName();
  const metaDescription = getRestaurantMetaDescription();
  const defaultKeywords = getRestaurantMetaKeyWords();

  if (!tag || !restaurant) return;

  value = value ?? `${restaurant}`;

  if (tag === 'title') {
    document.title = value;

    return;
  }

  const elem = document.querySelector(`[${isOpenGraph ? 'property' : 'name'}~="${tag}"][content]`);

  const minimizeValue = (_value: string) => {
    if (tag === 'keywords') {
      const keywords = _value.split(',');
      return keywords.length > 10 ? keywords.slice(0, 10).join(',') : _value;
    }

    if (tag === 'description' && !_value) return metaDescription || restaurant;

    if (tag === 'description') return _value.substring(0, 160);

    return _value;
  };

  elem?.setAttribute('content', minimizeValue(value));

  if (!elem && !isOpenGraph) {
    const meta = document.createElement('meta');
    meta.name = tag;
    meta.content = value;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
};

export const setMetaTags = (tags: { [key: string]: string | undefined | null }) => {
  Object.keys(tags).forEach((tag) => findElementAndSetAttr(tag, tags[tag]));
};

export const flatten = (arr: any[]) => {
  return arr.reduce((accumulator: any[], value: any) => accumulator.concat(value), []);
};

export const getRestaurantName = () => {
  return localeStore.language === 'en' ? appStore.settings?.restaurant_name_en : appStore.settings?.restaurant_name_ar;
};

export const getRestaurantMetaDescription = () => {
  return getTranslate(appStore.settings?.meta_description);
};

export const getRestaurantMetaKeyWords = () => {
  return getTranslate(appStore.settings?.meta_keywords);
};

/**
 * Address string generation.
 * If address type and/or address name fields are filled, the address itself shall not be displayed in the address field on the main page.
 * If both address type and address name are not filled. the address itself shall be displayed in the address field on the main page.
 */
export const getDeliveryAddressName = (address: IAddressResponse | undefined, showAddress: boolean = true): string => {
  if (!address) return '';

  let arr: string[] = [];
  if (address?.address_type) {
    arr.push(localeStore.t(`address_type_${address?.address_type}`));
  }
  if (address?.name) {
    arr.push(address?.name);
  }
  // Show address when showAddress is true or when there is no type and name
  if (address?.address && showAddress) {
    arr.push(address?.address);
  }
  // Isolate each part of the address
  arr = arr.map((item) => `\u202a${item}\u202c`);

  return arr.join(`\u202c - \u202a`);
};

export const logError = (err: unknown) => {
  console.error({ err });
};

export const extractHtmlContent = (string: string) => {
  const span = document.createElement('span');
  span.innerHTML = string;
  const content = span.textContent || span.innerText;
  return content.trim().replace(/\r?\n/g, ' ');
};

export const fixPhoneNumber = (phone: string): string => {
  try {
    if (!phone) return phone;
    if (phone.slice(0, 2) === '00') phone = `+${phone.slice(2, phone.length)}`;

    return phone;
  } catch (error: any) {
    return phone;
  }
};

/**
 * Get radix of number
 *
 * In a positional numeral system, the radix or base is the number of unique digits, including the digit zero, used to represent numbers.
 *
 * @param {number} number
 * @return {*}  {number}
 */
export const getRadix = (number: number): number => {
  if (Math.round(number / 10)) return 1 + getRadix(Math.round(number / 10));
  return 0;
};

export const scrollToElement = debounce(
  (data: { id?: string; selector?: string; scrollParams?: ScrollIntoViewOptions }) => {
    let element = null;

    if (data.id) element = document.getElementById(data.id);
    if (data.selector) element = document.querySelector(data.selector);
    if (element) element.scrollIntoView({ behavior: 'smooth', ...data.scrollParams });
  },
  100
);

export const isCashbackErrorCode = (errCode: number) => {
  return [
    CashbackErrorCode.Unavailable,
    CashbackErrorCode.ChangedConditions,
    CashbackErrorCode.UnavailableForBranch,
    CashbackErrorCode.UnavailableForOrderType,
  ].includes(errCode);
};
