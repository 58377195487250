import React from 'react';
import { localeStore } from 'src/mobx/localesStore';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';

interface IProps {
  visible: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const ProductDeleteModal: React.FC<IProps> = (props: IProps): JSX.Element => {
  const renderContent = (): JSX.Element => {
    const { onSubmit, onClose } = props;

    return (
      <ModalMessage
        title={localeStore.t('txt_delete_cart_item')}
        options={[
          {
            text: localeStore.t('txt_yes'),
            className:
              'color-dark color_hover-darkgray background-light background_active-white th-bg-controls-primary  th-controls-secondary',
            onPress: onSubmit,
          },
          { text: localeStore.t('txt_no'), onPress: onClose },
        ]}
      />
    );
  };

  const { visible, onClose } = props;

  return (
    <PopupModal
      position={'top'}
      direction={'down'}
      justify={'center'}
      selfClose={false}
      backdrop
      onClose={onClose}
      visible={visible}
      content={renderContent}
      mobile={{
        popup: true,
        sheet: true,
      }}
    />
  );
};

export default React.memo(ProductDeleteModal);
