import { FC, ReactElement, memo, useMemo } from 'react';
import { IProductInsideCombo } from 'src/types/combo';
import { IProduct, PRODUCT_TYPE } from 'src/types/products';
import { productUtils } from 'src/utils/productUtils';
import { CartProductItem } from '../../components/CartProductItem/CartProductItem';
import './style.scss';

interface IProps {
  product: IProduct;
}

export const CartProductItems: FC<IProps> = memo(({ product }: IProps): ReactElement | null => {
  const isMeal = useMemo((): boolean => product.type === PRODUCT_TYPE.MEAL_COMBO, [product.type]);

  const selectedItems = useMemo((): IProductInsideCombo[] => {
    if (isMeal) {
      return productUtils.getDefiniteSizeSelectedItems(product.items || [], product.size);
    }

    return product.items || [];
  }, [isMeal, product.items, product.size]);

  if (!selectedItems.length) return null;

  return (
    <div className="cart-product-items">
      {selectedItems.map((item, index) => (
        <CartProductItem
          key={productUtils.getMealItemKey(item, index)}
          item={item}
          isMeal={isMeal}
          productQuantity={product.quantity}
        />
      ))}
    </div>
  );
});
