import { FC, ReactElement } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductModifierOption } from 'src/types/products';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import './style.scss';
import { observer } from 'mobx-react-lite';

interface IProps {
  option: IProductModifierOption;
  productQuantity: number;
}

export const CartProductModifierOption: FC<IProps> = observer(({ option, productQuantity }: IProps): ReactElement => {
  const price: string = formatPrice(option.price * option.quantity * productQuantity);
  const showQuantity: boolean = Boolean(option.quantity && option.quantity > 1);

  return (
    <div className="cart-product-modifier-option">
      <p className="cart-product-modifier-option__name-wrapper">
        <span
          className={`cart-product-modifier-option__name color-dark letter-spacing-5 ${
            option.error ? 'text-through' : ''
          }`}
        >
          {getTranslate(option.name)}
        </span>

        {showQuantity ? (
          <bdo
            dir={localeStore.direction}
            className="cart-product-modifier-option__name color-dark letter-spacing-5"
          >{`x${option.quantity}`}</bdo>
        ) : null}
      </p>

      <p className="cart-product-modifier-option__price-wrapper">
        {<span className="cart-product-modifier-option__price color-gray text-nowrap letter-spacing-5">{price}</span>}

        {!option.taxable && option.price > 0 ? (
          <span className="cart-product-modifier-option__price color-gray text-nowrap letter-spacing-5">
            {localeStore.t('no_vat')}
          </span>
        ) : null}
      </p>
    </div>
  );
});
