import React from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import { IProduct } from 'src/types/products';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Image from 'src/view/components/Image/Image';
import ImageSlider from 'src/view/components/ImageSlider/ImageSlider';

interface IProps {
  product?: IProduct;
  updateProduct?: boolean;
  openDeleteModal?: () => void;
}

const ProductImagesBlock = (props: IProps) => {
  return (
    <div className="product-block d-flex flex-column pb-4 pb-lg-0">
      <div className="product-slider">
        {props.product ? <ImageSlider images={props.product.images} /> : <Image />}

        {props.product?.out_of_stock ? (
          <div className="menu-product-out_of_stock_message d-flex justify-content-center align-items-center">
            <p className="color-dark text-size-14 text-center">{localeStore.t('out_of_stock')}</p>
          </div>
        ) : null}

        {props.updateProduct ? (
          <div className="product-delete_button d-flex d-lg-none">
            <Icon name={ICONS.TRASH} onPress={props.openDeleteModal} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(ProductImagesBlock);
