import ElasticCarousel, { ReactElasticCarouselProps, RenderArrowProps } from 'react-elastic-carousel';
import { ForwardedRef, forwardRef, ReactNode } from 'react';
import IconButton from '../Controls/IconButton/IconButton';
import { ICONS } from '../../../constants/icons';
import { localeStore } from '../../../mobx/localesStore';

interface Props extends Partial<ReactElasticCarouselProps> {
  children?: ReactNode;
}

const Carousel = forwardRef(({ children, ...rest }: Props, ref: ForwardedRef<ElasticCarousel>) => {
  const isArabic = localeStore.language === 'ar';

  const renderArrow = ({ type, onClick, isEdge }: RenderArrowProps) => {
    const pointer =
      type === 'PREV' ? (
        <IconButton name={isArabic ? ICONS.SLIDER_RIGHT : ICONS.SLIDER_LEFT} className={isEdge ? 'color-gray' : undefined} />
      ) : (
        <IconButton name={isArabic ? ICONS.SLIDER_LEFT : ICONS.SLIDER_RIGHT} className={isEdge ? 'color-gray' : undefined} />
      );
    return <div onClick={isEdge ? undefined : onClick}>{pointer}</div>;
  };

  return (
    <ElasticCarousel isRTL={isArabic} pagination={false} renderArrow={renderArrow} ref={ref} {...rest}>
      {children}
    </ElasticCarousel>
  );
});

export { Carousel };
