import { action, computed, observable } from 'mobx';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { DESTINATION_LOGS, EVENTS } from 'src/constants/events';
import { E_MENU_TYPE, PAYMENT_TYPES } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import client from 'src/services/client';
import history from 'src/services/history';
import logger from 'src/services/logger';
import { ICheckoutData, ICheckoutDataPay } from 'src/types/cart';
import { typeSMS } from 'src/types/orders';
import { IProduct } from 'src/types/products';
import { getPhoneNumber } from 'src/utils/helpers';
import { productUtils } from 'src/utils/productUtils';
import { appStore } from '../appStore';
import { branchStore } from '../barnchStore';
import { addressStore } from '../barnchStore/address';
import { checkoutStore } from '../checkoutStore';
import { localeStore } from '../localesStore';
import { preorderStore } from '../preorderStore';
import { userStore } from '../userStore';
import { CartCheckout, defaultCalculator, defaultCartCheckout } from './checkout';
import { getTranslate } from '../../utils/formatter';
import { productsStore } from 'src/mobx/productsStore';

export class CartStore extends CartCheckout {
  @observable public error: any;
  @observable public isCart: boolean = false;
  @observable public isSMS: boolean = false;
  @observable public isSMSType: typeSMS | null = null;

  /**
   * This parameter allow to change data in cart
   *
   * @readonly
   * @memberof CartStore
   */
  @computed get isEditable() {
    return Boolean(!this.isSMS || appStore.settings?.sms_edit_order);
  }

  @computed get isValidPaymentType() {
    return (
      !!branchStore.getPaymentTypes.find((paymentType) => paymentType.id === cartStore.checkout.payment_type) ||
      (branchStore.getPaymentTypes.find((paymentTypes) => paymentTypes.id === PAYMENT_TYPES.ONLINE_PAYMENT) &&
        cartStore.checkout.payment_type === PAYMENT_TYPES.APPLE_PAY)
    );
  }

  @computed get checkoutData(): ICheckoutData {
    const data: ICheckoutData = {
      delivery_type: branchStore.delivery_type,
      use_wallet: cartStore.checkout.use_wallet,

      payment_type:
        this.checkout.payment_type === PAYMENT_TYPES.APPLE_PAY
          ? PAYMENT_TYPES.ONLINE_PAYMENT
          : this.checkout.payment_type,
      branch_id: branchStore.branch.id,
      user_id: userStore.user.id,
      language: localeStore.language,
      subtotal: this.calculator.promocode ? this.calculator.discounted_subtotal : this.calculator.subtotal,
      total: this.calculator.promocode ? this.calculator.discounted_total : this.calculator.total,
      vat: this.calculator.vat,

      is_order_for_someone_else: cartStore.checkout.is_order_for_someone_else,

      earn_points: this.checkout.payment_type === PAYMENT_TYPES.POINTS ? 0 : this.calculator.earn_points,
      points: this.checkout.payment_type === PAYMENT_TYPES.POINTS ? this.calculator.redeem_points : 0,
      delivery_fee: this.calculator.delivery_fee,
      is_curbside: Boolean(this.checkout.is_curbside),
      curbside: this.checkout.is_curbside ? this.checkout.curbside : undefined,
      notes: this.notes,
      hash: this.checkout.hash,
      products: this.products.filter((i) => i.orderable).map(productUtils.transformProductForCalculator),

      dinein_delivery_type: branchStore.order_type,
      address_id: undefined,
      cashback_warnings_as_errors: this.checkout.cashback_warnings_as_errors,
    };

    if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY && !cartStore.isSMS) {
      data.address_id = addressStore.selectedAddressId || 0;
    }
    // if order for someone else
    if (data.is_order_for_someone_else) {
      data.should_contact_by_driver = cartStore.checkout.should_contact_by_driver;
      data.stranger_mobile = cartStore.checkout.stranger_customer_info.mobile;
      data.stranger_recipient_name = cartStore.checkout.stranger_customer_info.name;
    }

    // if preorder exist
    if (preorderStore.preorder_unix) {
      // send unix in seconds
      data.time = preorderStore.preorder_unix;
    }
    // DO NOT PUT PROMOCODE IF PAYMENT TYPE IS POINTS OR IT NOT EXIST OR WALLET IS ACTIVE
    if (
      (this.calculator.promocode && this.checkout.payment_type !== PAYMENT_TYPES.POINTS) ||
      cartStore.checkout.use_wallet
    ) {
      data.promocode = this.calculator.promocode;
    }
    if (this.checkout.payment_type === PAYMENT_TYPES.ONLINE_PAYMENT) {
      if (this.checkout.selected_card) {
        data.card_id = this.checkout.selected_card;
      } else if (this.checkout.card) {
        data.card = this.checkout.card;
      }
    }

    if (this.checkout.apple_pay) {
      data.apple_pay = this.checkout.apple_pay;
    }

    // if dine in
    if (data.dinein_delivery_type === E_MENU_TYPE.DINE_IN) {
      data.delivery_type = DELIVERY_TYPES.DINE_IN;
    }

    if (data.dinein_delivery_type === E_MENU_TYPE.DINE_IN && branchStore.branch.emenu?.sections?.length) {
      data.section = cartStore.checkout.dine_in?.section?.id;
      data.table = cartStore.checkout.dine_in?.table?.id;
    }

    if (this.isSMS) {
      data.order_id = checkoutStore.reorderStore.order.id;
      data.latitude = addressStore.address?.latitude;
      data.longitude = addressStore.address?.longitude;
    }

    if (this.calculator.cashback.id) {
      data.previous_calculation = {
        branch_id: branchStore.branch.id,
        order_type: data.delivery_type,
        cashback_id: this.calculator.cashback.id,
        cashback_amount: this.calculator.cashback.amount,
      };
    }

    if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY && this.isSMS) {
      // if the type of delivery is delivery and there are reorder data and this is SMS
      if (checkoutStore.reorderStore) {
        data.latitude = checkoutStore.reorderStore?.area?.latitude;
        data.longitude = checkoutStore.reorderStore?.area?.longitude;
      }

      /** @deprecated */
      // if the type of delivery is delivery and there are selected coordinates and this is SMS
      if (branchStore.selectMapCoordinate) {
        data.latitude = branchStore.selectMapCoordinate.latitude;
        data.longitude = branchStore.selectMapCoordinate.longitude;
      }
    }

    if (this.isSMS && checkoutStore.reorderStore?.order?.promocode) {
      data.promocode = checkoutStore.reorderStore?.order?.promocode;
    }

    if (this.isSMS && this.isSMSType === 2) {
      data.is_address_change = true;
    }

    return data;
  }

  @computed get payData(): ICheckoutDataPay {
    const data: ICheckoutDataPay = {
      payment_source: {},
      payment_type:
        this.checkout.payment_type === PAYMENT_TYPES.APPLE_PAY
          ? PAYMENT_TYPES.ONLINE_PAYMENT
          : this.checkout.payment_type,
    };
    if (this.checkout.payment_type === PAYMENT_TYPES.APPLE_PAY) {
      data.payment_source = {
        // @ts-ignore
        apple_pay: cartStore.applePaymentSource,
      };
    }
    if (this.checkout.payment_type === PAYMENT_TYPES.ONLINE_PAYMENT) {
      if (this.checkout.selected_card) {
        data.payment_source.card_id = this.checkout.selected_card;
      } else if (this.checkout.card) {
        data.payment_source.card = this.checkout.card;
      }
    }
    return data;
  }

  // actions
  openCart = () => {
    this.isCart = true;
    logger.logEvent(EVENTS.APP_PAGE_CHANGE);
    logger.logEvent(EVENTS.CART_OPENED, {
      items: cartStore.products,
      total: cartStore.calculator.total,
    });

    logger.logEvent(
      EVENTS.VIEW_CART,
      {
        user_id: userStore.user?.id,
        cart_action: 'click',
      },
      DESTINATION_LOGS.MOENGAGE
    );

    if (!this.isSMS) this.checkout = defaultCartCheckout;

    this.setLoading(false);
    this.calculate();
  };

  isSMSPage = (isSMS: boolean) => (this.isSMS = isSMS);

  SMSType = (isSMSType: typeSMS | null) => (this.isSMSType = isSMSType);

  closeCart = () => {
    this.isCart = false;
    logger.logEvent(EVENTS.APP_PAGE_CHANGE);
  };

  @action public setNotes = (notes: string) => {
    this.notes = notes;
  };

  @action add = (_product: IProduct) => {
    const product = {
      ..._product,
      orderable: true,
      hash: productUtils.getProductHash(_product),
    };

    const sameProductIdx = this.products.findIndex((_product) => _product.hash === product.hash);
    if (~sameProductIdx) {
      this.products[sameProductIdx].quantity = this.products[sameProductIdx].quantity + product.quantity;
    } else {
      this.products = [...this.products, product];
    }
    this.recalculate();
  };

  @action remove = (product: IProduct) => {
    this.products = this.products.filter((_product) => _product.hash !== product.hash);

    logger.logEvent(EVENTS.CART_REMOVE_PRODUCT, [product]);

    const category = productsStore.categories?.find((_category) => _category.id === product.category_id);

    logger.logEvent(
      EVENTS.REMOVE_FROM_CART,
      {
        item_id: product?.id,
        item_name: getTranslate(product?.name),
        item_category: getTranslate(category?.name),
        quantity: product?.quantity,
      },
      DESTINATION_LOGS.MOENGAGE
    );

    if (!this.products.length) {
      // if last item was removed neeed to clear close cart and clear calculator
      this.calculator = defaultCalculator;
      this.notes = '';
      this.closeCheckout();
      this.closeCart();

      if (this.isSMS) {
        history.replace(SCREENS.MENU());
      }
      return;
    }
    this.recalculate();
  };

  @action update = (product: IProduct) => {
    this.products = this.products.map((_product) => {
      if (_product.hash === product.hash) {
        product.orderable = true;
        // update hash
        product.hash = productUtils.getProductHash(product);

        return product;
      }

      return _product;
    });

    this.recalculate();
  };
  @computed get isDonation() {
    return this.products.some((product) => product.is_donation);
  }
  @computed get isDonationOnly() {
    return this.products.every((product) => product.is_donation);
  }
  @action validateCartHash = async (): Promise<string | null> => {
    try {
      if (!this.checkout.hash) return null;

      this.setLoading(true);
      const response = await client.get(`/v3/orders/${this.cartHash}`, {
        headers: { 'Accept-Language': localeStore.language },
      });
      this.setLoading(false);
      if (response && response.order && response.order.length > 0) {
        this.clear();
        this.closeCart();
        this.closeCheckout();
        return response.order[0].id;
      }
      return null;
    } catch (err) {
      this.setLoading(false);
      return null;
    }
  };

  @action public createOrder = async () => {
    try {
      if (this.calculator.loading) return;
      this.setLoading(true);

      const data = this.checkoutData;

      data.notes = cartStore.notes;
      if (data.stranger_mobile) data.stranger_mobile = getPhoneNumber(data.stranger_mobile);

      // create Payment
      let response;

      // TODO: remove this logic after refactoring the payment on the SMS page
      if (cartStore.isSMS) {
        response = await client.post('/v3/orders', data);
      } else {
        response = await client.post('v4/orders/customer', data, {}, true);
      }

      logger.logEvent(EVENTS.CHECKOUT_ORDER_CREATED, {
        ...data,
        products: this.products,
        id: response.data,
        branch_name: branchStore.branch.name,
      });

      userStore.setLastPaymentType(data.payment_type);
      if (data.payment_type === 1) userStore.setLastPaymentCard(cartStore.checkout.selected_card);
      if (Boolean(this.checkout.is_curbside) && this.checkout.curbside) {
        userStore.setCurbsideInfo(this.checkout.curbside);
      }

      // TODO: remove this logic after refactoring the payment on the SMS page
      if (cartStore.isSMS) {
        this.clear();
        this.closeCart();
        this.closeCheckout();
        this.setLoading(false);
      }

      return response;
    } catch (error: any) {
      this.setLoading(false);
      if (error?.response?.data) {
        if (error.response.data.code === 'pay_error') {
          this.checkout.card = defaultCartCheckout.card;
        }
      }
      throw error;
    }
  };

  /**
   * Clear cart store
   *
   * @param {boolean} [isManual] - is user action ( log event )
   * @memberof CartStore
   */
  @action clear = (isManual?: boolean) => {
    if (isManual) {
      logger.logEvent(EVENTS.CART_REMOVE_PRODUCT, this.products);

      logger.logEvent(
        EVENTS.REMOVE_ALL_FROM_CART,
        {
          user_id: userStore.user?.id,
          quantity_of_items: cartStore.products.length,
        },
        DESTINATION_LOGS.MOENGAGE
      );
    }

    this.products = [];
    this.cartHash = '';
    this.notes = '';
    this.checkout = defaultCartCheckout;
    this.calculator = defaultCalculator;
    this.cashbackError = null;
  };

  @action keepCart = () => {
    if (appStore?.settings?.donation?.enabled && !branchStore.branch.branchCovered) {
      this.products = this.products.filter((item) => item.is_donation);
    }
  };

  @action setLoading = (status: boolean) => {
    this.calculator.loading = status;
  };
}

export const cartStore = new CartStore();
