import React, { ChangeEvent, Component, useRef, useState } from 'react';
import Modal, { IModalComponentProps } from '../../../components/Controls/Modal/Modal';
import ScrollableList from '../../../components/ScrollableList/ScrollableList';
import { EmptyList } from '../../../components/ScrollableList/EmptyList';
import { Order } from '../../OrdersScreen/components/Order/Order';
import Icon from '../../../components/Controls/Icon/Icon';
import SearchInput from './SearchInput/SearchInput';
import useOrders from '../hooks/useOrders';

import { localeStore } from '../../../../mobx/localesStore';
import { IOrder } from '../../../../types/orders';

interface OrdersModalProps extends IModalComponentProps {
  onSelect?: (order?: IOrder) => void;
  branchId?: number;
}

const OrdersModal = (props: OrdersModalProps) => {
  const { pagination, fetchOrders, clear, search, setSearch } = useOrders(props.branchId);
  const scrollElementRef = useRef(null);

  const renderOrder = (order: IOrder | undefined, index: number) => (
    <Order
      order={order}
      key={order?.id || `loading-${index}`}
      preventDefault={true}
      onClick={() => props.onSelect && order && props.onSelect(order)}
    />
  );

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <Modal className="container" {...props}>
      <div className="branches orders-modal background-white d-flex flex-column">
        <div className={'d-flex flex-1 flex-row full-width justify-content-between mb-4 align-items-center'}>
          <h2>{localeStore.t('choose_order')}</h2>
          <Icon
            name={'close'}
            size={16}
            style={{ cursor: 'pointer' }}
            onPress={() => {
              setSearch('');
              if (props.onRequestClose) props.onRequestClose();
            }}
          />
        </div>
        <div className={'d-flex mb-4'}>
          <SearchInput search={search} setSearch={handleSearch} />
        </div>
        <div
          className={'d-flex full-width flex-shrink-4'}
          style={{ position: 'relative', overflowY: 'scroll', flex: 10 }}
          ref={scrollElementRef}
        >
          <ScrollableList
            clear={clear}
            renderEmptyList={<EmptyList />}
            list={pagination.data}
            loading={pagination.loading}
            last_page={pagination.last_page}
            current_page={pagination.current_page}
            fetch={fetchOrders}
            renderItem={renderOrder}
            style={{ left: 0, position: 'absolute', top: 0 }}
            scrollElement={scrollElementRef}
            key={`${scrollElementRef.current}`}
          />
        </div>
      </div>
    </Modal>
  );
};

export default OrdersModal;
