import React, { ReactElement } from 'react';
import { ICONS } from 'src/constants/icons';
import Icon from '../Icon/Icon';
import './style.scss';

interface ISpinnerProps {
  style?: React.CSSProperties;
  className?: string;
  iconClassName?: string;
  size?: number;
  color?: string;
}

export default function Spinner({
  style,
  className,
  iconClassName,
  size = 24,
  color = '#ffffff',
  ...props
}: ISpinnerProps): ReactElement {
  return (
    <div className={className}>
      <div className="spinner" style={{ width: size, height: size }}>
        <Icon
          className={`spinner-icon ${iconClassName}`}
          style={{ color }}
          size={size}
          name={ICONS.SPINNER}
        />
      </div>
    </div>
  );
}
