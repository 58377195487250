import { Component } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductModifierOption } from 'src/types/products';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
interface Props {
  option: IProductModifierOption;
  readOnly?: boolean;
  onPressOption: (option: IProductModifierOption) => void;
  onChangeOptionQuantity: (id: IProductModifierOption['id'], quantity: number) => void;
  id?: string;
}

export default class Option extends Component<Props> {
  onQuantityChange = (quantity: number) => {
    this.props.onChangeOptionQuantity(this.props.option.id, quantity);
  };

  onPressOption = () => {
    if (this.props.readOnly) return;
    this.props.onPressOption(this.props.option);
  };

  get units() {
    return productUtils.getResponseProductModifierOptionUnits(this.props.option);
  }

  render() {
    const { option, readOnly } = this.props;
    const price = option.selected ? option.price * option.quantity : option.price;

    return (
      <div className="option">
        <div
          key={option.id}
          onClick={this.onPressOption}
          className={`single-option ${readOnly ? 'readonly' : ''} ${option.selected ? 'active' : ''} `}
        >
          <div className="single-option-content">
            {!readOnly ? (
              <span id={this.props.id} className="single-option-circle background_pseudo-primary mr-3"></span>
            ) : null}
            <p className="single-option-name  mr-3">
              {getTranslate(option.name)}
              {readOnly && option.selected ? (
                <bdo className="single-option-name-quantity" dir={localeStore.direction}>
                  {`x${option.quantity}`}
                </bdo>
              ) : null}
            </p>

            {/* <p className="single-option-unit">{formatUnit(option.unit, option.weight)}</p> */}
          </div>

          <div className="single-option-content">
            {!option.taxable && option.price > 0 ? (
              <p className="multi-option-vat mr-3">{localeStore.t('non_taxable')}</p>
            ) : null}
            <p className="single-option-price text-nowrap">{`${price > 0 ? '+ ' : ''}${formatPrice(price)}`}</p>
          </div>
        </div>
        <div className="multi-option-units ml-3">
          {this.units?.map((unit) => (unit ? <span className="mr-2">{unit}</span> : null))}
        </div>
      </div>
    );
  }
}
