import { FC, ReactElement, memo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IOrderProductExclusion } from 'src/types/orders';
import { getTranslate } from 'src/utils/formatter';
import './style.scss';

interface IProps {
  exclusions: IOrderProductExclusion[] | undefined;
  className?: string;
}

export const OrderProductExclusions: FC<IProps> = memo(({ exclusions, className }: IProps): ReactElement | null => {
  if (!exclusions?.length) return null;

  return (
    <div className={`order-product-exclusions ${className || ''}`}>
      <p className="order-product-exclusions__title color-gray letter-spacing-5">{localeStore.t('Exclusions')}</p>

      {exclusions.map((exclusion) => (
        <p key={exclusion.id} className="order-product-exclusions__name color-dark">
          {getTranslate(exclusion.name)}
        </p>
      ))}
    </div>
  );
});
