import React, { forwardRef, useCallback } from 'react';
import { ITimeSlot } from 'src/types/time';
import './style.scss';

interface PreorderMonthProps {
  data: ITimeSlot;
}

const PreorderMonth = forwardRef<HTMLDivElement, PreorderMonthProps>(({ data }, ref) => {
  return (
    <div ref={ref}>
      <p className="preorder-month family-semibold font-size-16">{data.label}</p>
    </div>
  );
});

export default PreorderMonth;
