import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Component, createRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { ICONS } from 'src/constants/icons';
import { E_MENU_TYPE, PAYMENT_TYPES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { getTranslate } from 'src/utils/formatter';
import { getDeliveryAddressName } from 'src/utils/helpers';
import BackIcon from 'src/view/components/Controls/BackIcon/BackIcon';
import OrderTypeSwitcher from 'src/view/containers/OrderTypeSwitcher/OrderTypeSwitcher';
import ScreenWrapper from 'src/view/containers/ScreenWrapper/ScreenWrapper';
import { addressStore } from '../../../mobx/barnchStore/address';
import { typeSMS } from '../../../types/orders';
import { Cashback } from '../../components/Cashback/Cashback';
import Modal from '../../components/Controls/Modal/Modal';
import SmsPagePromocodeInfo from '../../components/SmsPagePromocodeInfo/SmsPagePromocodeInfo';
import CheckoutTooltip from './components/CheckoutTooltip/CheckoutTooltip';
import CheckoutDineIn from './containers/CheckouDineIn/CheckoutDineIn';
import CheckoutCurbside from './containers/CheckoutCurbside/CheckoutCurbside';
import { CheckoutDeliveryType } from './containers/CheckoutDeliveryType/CheckoutDeliveryType';
import CheckoutModals from './containers/CheckoutModals/CheckoutModals';
import CheckoutPaymentCards from './containers/CheckoutPaymentCards/CheckoutPaymentCards';
import CheckoutPaymentPoints from './containers/CheckoutPaymentPoints/CheckoutPaymentPoints';
import CheckoutPaymentType from './containers/CheckoutPaymentType/CheckoutPaymentType';
import CheckoutPaymentTypes from './containers/CheckoutPaymentTypes/CheckoutPaymentTypes';
import CheckoutPreorder from './containers/CheckoutPreorder/CheckoutPreorder';
import CheckoutPromocode from './containers/CheckoutPromocode/CheckoutPromocode';
import CheckoutSomeone from './containers/CheckoutSomeone/CheckoutSomeone';
import CheckoutSubmit from './containers/CheckoutSubmit/CheckoutSubmit';
import { CheckoutTotal } from './containers/CheckoutTotal/CheckoutTotal';
import CheckoutTotalInfo from './containers/CheckoutTotalInfo/CheckoutTotalInfo';
import CheckoutWallet from './containers/CheckoutWallet/CheckoutWallet';
import CheckoutCashback from 'src/view/screens/CheckoutScreen/containers/CheckoutCashback/CheckoutCashback';
import './style.scss';

@observer
class CheckoutScreen extends Component<RouteComponentProps> {
  private oldPromocode: string | undefined;
  /* New ref for scroll to down, after apply coupon */
  private refOnScroll = createRef<HTMLDivElement>();

  componentDidUpdate = () => {
    if (cartStore.calculator.promocode !== this.oldPromocode) {
      this.scrollDown();
      this.oldPromocode = cartStore.calculator.promocode;
    }
  };

  /**
   * Check has cart promocode now
   */
  get hasPromocode(): boolean {
    return !!cartStore.calculator.promocode;
  }

  /* Scroll to the block */
  scrollDown = () => {
    if (this.refOnScroll.current) {
      const scrollHeight = this.refOnScroll.current.scrollHeight;
      this.refOnScroll.current.scrollTo({ top: scrollHeight, left: 0, behavior: 'smooth' });
    }
  };

  closeModal = () => {
    cartStore.closeCheckout();
  };

  handleChangeBranch = () => {
    if (cartStore.isSMS && cartStore.isSMSType === typeSMS.WITHOUT_GEOLOCATION) return;
    if (cartStore.isSMS) {
      cartStore.closeCart();
      cartStore.closeCheckout();
      branchStore.openBranches();
    } else {
      this.props.history.push({
        pathname: `/branches/${branchStore.delivery_type}`,
        search: `?language=${localeStore.language}`,
      });
    }
  };

  changeOrderType = (type: number) => {
    branchStore.setOrderType(type);
  };

  getAddressName = (): string => {
    return getDeliveryAddressName(addressStore.address);
  };

  renderDeliveryType = () => {
    return (
      <>
        {branchStore.delivery_type === DELIVERY_TYPES.DELIVERY ? (
          /* Delivery */
          <CheckoutDeliveryType
            label={localeStore.t('txt_delivery_address')}
            text={this.getAddressName()}
            icon={ICONS.DELIVERY}
            onClick={this.handleChangeBranch}
          />
        ) : (
          /* Pickup */
          <CheckoutDeliveryType
            label={localeStore.t('txt_pickup_at')}
            text={getTranslate(branchStore.branch.name)}
            icon={ICONS.PICKUP}
            onClick={this.handleChangeBranch}
          />
        )}
        <span className="divider" />
      </>
    );
  };

  /**
   * Check the shopping cart to see if there is a single product with a donation (Ramadan)
   *
   * @readonly
   * @memberof CheckoutScreen
   */
  get isDonation() {
    const { products } = cartStore;
    return products.some((products) => products.is_donation);
  }

  /**
   * Check your shopping cart to see if there is one item that is not donated (Ramadan)
   *
   * @readonly
   * @memberof CheckoutScreen
   */
  get isOneNoDonation() {
    const { products } = cartStore;
    return products.some((products) => !products.is_donation);
  }

  render() {
    const { order_types } = branchStore.branch.emenu || {};

    const showWallet =
      !this.isDonation && appStore.settings?.is_wallet && userStore.isLogin && !cartStore.calculator.promocode;
    return (
      <ScreenWrapper>
        <Modal
          className="checkout-modal background-white"
          iconClassName="color-gray"
          defaultAnimation={''}
          isOpen={cartStore.isCheckout}
          onRequestClose={this.closeModal}
          withPane
        >
          <div className="checkout-wrapper d-flex flex-column justify-content-between full-height full-width mb-n4">
            <div className="checkout-wrapper-header d-flex flex-column">
              <div className="d-flex flex-row align-items-center justify-content-between mb-0 mb-lg-4">
                <div className="d-flex flex-row align-item-center">
                  <div className=" d-flex align-self-center text-pointer" onClick={this.closeModal}>
                    <BackIcon>
                      <h1 className="family-bold color-dark text-left d-flex align-items-center">
                        {localeStore.t('txt_checkout')}
                      </h1>
                    </BackIcon>
                  </div>
                </div>
              </div>
            </div>

            <div
              ref={this.refOnScroll}
              className="d-flex flex-column flex-grow-1 checkout-scroll scrollable-block__scroll_small pb-4"
            >
              <div className="d-flex flex-column mb-1 mb-lg-4 section py-0 br-top-0">
                {appStore.mode === 'e-menu' ? (
                  // Dine in switcher
                  <div className="d-flex flex-column checkout-delivery-data" id="checkout_order_type">
                    <OrderTypeSwitcher
                      className={`checkout-order_type mb-2 p-1`}
                      classNameButton={
                        !order_types?.is_dinein || !order_types?.is_togo ? 'p-0' : 'checkout-order_type-button'
                      }
                      onChange={cartStore.clearPromocode}
                    />
                  </div>
                ) : (
                  // Delivery type
                  this.renderDeliveryType()
                )}
                <CheckoutPreorder />
              </div>
              <div className="d-flex flex-column section mb-1 mb-lg-0 py-0">
                {!(appStore.mode === 'e-menu') ? (
                  <>
                    {this.isOneNoDonation && appStore.settings?.allow_order_for_else ? (
                      <>
                        <CheckoutSomeone />
                        <span className="divider" />
                      </>
                    ) : null}

                    {this.isOneNoDonation ? <CheckoutCurbside /> : null}
                  </>
                ) : branchStore.order_type === E_MENU_TYPE.DINE_IN ? (
                  <CheckoutDineIn />
                ) : null}
              </div>

              <CheckoutPaymentTypes />

              {userStore.isLogin ? <CheckoutPaymentCards /> : null}

              <div className="d-flex flex-column section mb-1 mb-lg-0">
                <CheckoutPaymentPoints />
                {showWallet ? <CheckoutWallet /> : null}

                <CheckoutCashback />

                {!cartStore.isSMS && !this.isDonation ? <CheckoutPromocode /> : null}
              </div>

              <div className="d-flex flex-column section mb-1 mb-lg-0">
                <CheckoutTotalInfo />
              </div>

              {/* *
               * show a promo code message on the sms page if the user is not logged in
               * and the call center has applied the promo code to the order
               */}
              <SmsPagePromocodeInfo />
            </div>
          </div>
          <div className="d-flex flex-column checkout-wrapper-total ">
            <CheckoutPaymentType />
            <span className="divider" />
            <div className="d-flex flex-row align-items-center mt-3 mt-lg-0 ">
              <CheckoutTotal />
              <CheckoutSubmit />
            </div>
            {cartStore.checkout.payment_type === PAYMENT_TYPES.ONLINE_PAYMENT ? <CheckoutTooltip /> : null}
          </div>
          <CheckoutModals />
        </Modal>
      </ScreenWrapper>
    );
  }
}

export default withRouter(CheckoutScreen);
