import { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { appStore } from 'src/mobx/appStore';
import { userStore } from 'src/mobx/userStore';
import { requests } from 'src/requests';
import { IUser } from 'src/types/user';
import Icon from 'src/view/components/Controls/Icon/Icon';

import ConfirmComponent from './components/ConfirmComponent';
import LoginComponent from './components/LoginComponent';

import { EVENTS } from 'src/constants/events';
import logger from 'src/services/logger';
import { cartStore } from '../../../mobx/cartStore';
import { checkoutStore } from '../../../mobx/checkoutStore';
import { localeStore } from '../../../mobx/localesStore';
import './style.scss';

export interface IOTP {
  otp_attempts: number;
  otp_changed_at: string;
  otp_resend_after: number;
}

interface Props {
  closeModal?: () => void;
  title?: string;
}

interface State {
  step: number;
  error: string;
  loading: boolean;
  otp: IOTP;
  number: string;
  needCaptcha: boolean;
}

export default class LoginContainer extends Component<Props, State> {
  state: State = {
    step: 0,
    error: '',
    loading: false,
    otp: {
      otp_attempts: 3,
      otp_changed_at: '',
      otp_resend_after: 30,
    },
    number: `${appStore.settings?.default_country_code || '966'}`,
    needCaptcha: false,
  };

  toPrevStep = () => this.setState({ step: 0, error: '' });
  toNextStep = () => this.setState({ step: 1, error: '' });

  submit = async (number: string, captcha?: string | null) => {
    try {
      this.setState(() => ({ loading: true }));
      if (cartStore.isSMS && +checkoutStore.reorderStore.order?.customer_mobile !== +number) {
        return this.setState({ error: localeStore.t('incorrect_mobile'), loading: false });
      }
      const phone = number[0] === '+' ? number : `+${number}`;

      const response = await requests.login(phone, captcha);

      this.setState({
        loading: false,
        error: '',
        otp: response,
        number,
        needCaptcha: false,
      });

      this.toNextStep();
    } catch (error: any) {
      if (error?.response) {
        if (error.response.status === 400 && error.response.data.code === 1001) {
          this.setState({ needCaptcha: true, step: 0, error: '' });
        }
        return this.setState({ error: error.response.data.message, loading: false });
      }
      return this.setState({ error: error?.message || JSON.stringify(error), loading: false });
    }
  };

  confirm = async (otp: string) => {
    try {
      logger.logEvent(EVENTS.AUTH_CONFIRM_OTP);
      this.setState(() => ({ loading: true }));
      const phone = this.state.number[0] === '+' ? this.state.number : `+${this.state.number}`;
      const response: IUser = await requests.confirm(otp, phone);
      userStore.authorize(response);
      userStore.fetchUser();
      userStore.fetchUserCards();
      logger.logEvent(EVENTS.AUTH_LOGGED_IN);
      if (this.props.closeModal) this.props.closeModal();

      this.setState(() => ({ loading: false }));
    } catch (error: any) {
      const message = error?.response.data.message || error;
      this.setState((state) => ({
        error: message,
        loading: false,
        otp: { ...state.otp, otp_attempts: state.otp.otp_attempts - 1 },
      }));
    }
  };

  render() {
    return (
      <div dir="ltr" className="login-modal background-white">
        <div className="login-wrapper">
          {/* Back Icon */}
          {this.state.step === 1 ? (
            <button className="login-back" onClick={this.toPrevStep}>
              <Icon name={ICONS.ARROW_LEFT} className={'color-primary'} size={24} />
            </button>
          ) : null}
          {/* Close Icon */}
          <button className="login-close" onClick={this.props.closeModal}>
            <Icon name={ICONS.CLOSE} color={'#6D6D6D'} size={14} />
          </button>

          {this.state.step === 0 ? (
            <LoginComponent
              number={this.state.number}
              submit={this.submit}
              error={this.state.error}
              loading={this.state.loading}
              title={this.props.title}
              needCaptcha={this.state.needCaptcha}
            />
          ) : (
            <ConfirmComponent
              number={this.state.number}
              otp={this.state.otp}
              loading={this.state.loading}
              error={this.state.error}
              confirm={this.confirm}
              submit={this.submit}
            />
          )}
        </div>
      </div>
    );
  }
}
