import { observer } from 'mobx-react';
import React from 'react';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import './style.scss';

const CartTotal = observer((): JSX.Element => {
  return (
    <div className="d-flex flex-column justify-content-between mr-3 text-nowrap">
      <p id="cart_total" className="color-dark family-semibold text-size-20 text-center">
        {formatPrice(cartStore.calculator.discounted_total || cartStore.calculator.total)}
      </p>
      {branchStore.vat_included ? (
        <span className="family-regular text-size-10 text-nowrap text-center">{localeStore.t('VAT inclusive')}</span>
      ) : null}
    </div>
  );
});

export default React.memo(CartTotal);
