import { appStore } from '../../mobx/appStore';
import { localeStore } from '../../mobx/localesStore';
import { IApplePayData } from '../../types/orders';
import { logError } from '../../utils/helpers';
import { createAppleSession } from '../../utils/ApplePay';

interface ApplePayProps {
  subtotal: number;
  discount_amount: number;
  vat: number;
  taxable_delivery_fee: number;
  delivery_fee: number;
  discounted_total: number;
  total: number;
  apple_pay: object;
  onSuccess: () => void;
}

export const applePaySession = async (props: ApplePayProps | any) => {
  try {
    const data = props;
    const currency = appStore.settings!.currency_code || 'SAR';

    const lineItems = [
      {
        label: localeStore.t('orderDetails_Subtotal'),
        amount: `${data.subtotal}`,
      },
    ];

    if (props.discount_amount && props.discount_amount > 0) {
      lineItems.push({
        label: localeStore.t('txt_discount_applied'),
        amount: `-${props.discount_amount}`,
      });
    }

    if (props.vat > 0) {
      lineItems.push({
        label: localeStore.t('vat'),
        amount: `${props.vat}`,
      });
    }

    if (props.delivery_fee > 0) {
      lineItems.push({
        label: localeStore.t('delivery_fee'),
        amount: `${data.delivery_fee}`,
      });
    }

    if (props.taxable_delivery_fee && props.taxable_delivery_fee > 0) {
      lineItems.push({
        label: localeStore.t('vat_delivery_fee'),
        amount: `${props.taxable_delivery_fee}`,
      });
    }

    const applePayData: IApplePayData = {
      merchantCapabilities: ['supports3DS'],
      countryCode: 'SA',
      currencyCode: currency,
      supportedNetworks: ['visa', 'mastercard', 'amex', 'mada'],
      total: {
        label: window.location.hostname,
        amount: `${props.discount_amount ? props.discounted_total : props.total}`,
        type: 'final',
      },
      lineItems,
    };

    // create and return token
    return await createAppleSession(applePayData);
  } catch (err) {
    logError(err);
  }
};
