import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { staticTheme } from 'src/constants/staticTheme';
import { cartStore } from 'src/mobx/cartStore';
import Spinner from 'src/view/components/Controls/Spinner/Spinner';

interface Props {
  size?: number;
}

@observer
export default class LoadingCircle extends Component<Props> {
  size = this.props.size || 15;

  render() {
    if (cartStore.calculator.loading) return <Spinner color={staticTheme.colors.color.gray} size={this.size} />;
    return this.props.children;
  }
}
