import React from 'react';
import { ICategory } from '../../../../../../../types/products';
import MenuProduct from '../../../../components/MenuProduct/MenuProduct';
import './style.scss';
import { getTranslate } from '../../../../../../../utils/formatter';

interface IProps {
  categories: ICategory[];
}

export const MenuSearchedCategories: React.FC<IProps> = ({ categories }) => {
  return (
    <>
      {categories.map((category) => (
        <div className="search-menu" key={category.id}>
          <div className="search-menu-category">{getTranslate(category.name)}</div>
          <div className="search-menu-items-container">
            {category.items.data.map((product) => (
              <MenuProduct key={product.id} product={product} type="search-item" />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
