import React, { Component } from 'react';
import { IProductModifier, IProductModifierOption } from 'src/types/products';
import Option from './components/Option';
import './style.scss';
import { getTranslate } from '../../../../utils/formatter';

interface Props {
  modifier: IProductModifier;
  error: boolean;
  readOnly?: boolean;
  onChange: (modifier: IProductModifier) => void;
  leftToMaximum?: number | null;
}
export default class SingleChoise extends Component<Props> {
  onPressOption = (option: IProductModifierOption) => {
    let isSameOption = true;
    const modifiers = {
      ...this.props.modifier,
      options: this.props.modifier.options.map((_option) => {
        if (_option.id === option.id) {
          isSameOption = Boolean(_option.selected);
          return {
            ..._option,
            selected: true,
          };
        } else {
          return {
            ..._option,
            selected: false,
          };
        }
      }),
    };
    // do not call on change if user pressed on same option
    if (isSameOption) return;
    this.props.onChange(modifiers);
  };

  onOptionQuantityChange = (optionId: IProductModifierOption['id'], quantity: number) => {
    const modifiers = {
      ...this.props.modifier,
      options: this.props.modifier.options.map((_option) => {
        if (_option.id === optionId) {
          return {
            ..._option,
            quantity,
            selected: true,
          };
        }
        return _option;
      }),
    };
    this.props.onChange(modifiers);
  };

  renderOption = (option: IProductModifierOption) => (
    <Option
      key={option.id}
      onChangeOptionQuantity={this.onOptionQuantityChange}
      onPressOption={this.onPressOption}
      option={option}
      id={`select_${option.id}`}
      readOnly={this.props.readOnly}
    />
  );

  render() {
    const { modifier } = this.props;
    return (
      <div className="single">
        <h3 className="single-title">{getTranslate(modifier.name)}</h3>
        {modifier.options.map(this.renderOption)}
      </div>
    );
  }
}
