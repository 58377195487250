import { FC, ReactElement, memo, useMemo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductModifier, IProductModifierOption } from 'src/types/products';
import { CartProductModifierOption } from '../../components/CartProductModifierOption/CartProductModifierOption';
import './style.scss';
import { observer } from 'mobx-react-lite';

interface IProps {
  modifiers: IProductModifier[] | undefined;
  productQuantity: number;
  className?: string;
}

export const CartProductModifiers: FC<IProps> = observer((props: IProps): ReactElement | null => {
  const { modifiers, productQuantity, className } = props;

  const options = useMemo((): IProductModifierOption[] => {
    if (!modifiers?.length) return [];

    return modifiers.reduce((sum: IProductModifierOption[], current) => {
      (current.options || []).forEach((option) => {
        if (option.selected) sum.push({ ...option, error: current.error || option.error });
      });

      return sum;
    }, []);
  }, [modifiers]);

  if (!options.length) return null;

  return (
    <div className={`cart-product-modifiers ${className || ''}`}>
      <p className="cart-product-modifiers__title color-gray letter-spacing-5">{localeStore.t('modifiers')}</p>

      {options.map((option) => (
        <CartProductModifierOption key={option.id} option={option} productQuantity={productQuantity} />
      ))}
    </div>
  );
});
