import * as React from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { TWalletStatus } from '../../WalletScreen';
import './style.scss';
import HorizontalScroll from 'src/view/components/HorizontalScroll/HorizontalScroll';
import client from 'src/services/client';

export interface IWalletStatusesProps {
  status: TWalletStatus;
  setStatus: (status: TWalletStatus) => void;
}

type TTypeStatus = { label: string; value: IWalletStatusesProps['status'] };

export interface IWalletStatusesState {
  types: TTypeStatus[];
}

const types: TTypeStatus[] = [
  {
    label: 'all',
    value: undefined,
  },
  {
    label: 'deposit',
    value: 1,
  },
  {
    label: 'withdrawal',
    value: 4,
  },
  {
    label: 'expiration',
    value: 3,
  },
  {
    label: 'correction',
    value: 7,
  },
];
export default class WalletStatuses extends React.Component<IWalletStatusesProps, IWalletStatusesState> {
  state = {
    types: [types[0]],
  };

  componentDidMount() {
    this.fetchStatuses();
  }

  /**
   * Fetch available statuses for transaction
   * Not to show those transactions that were not committed
   *
   * @memberof WalletStatuses
   */
  fetchStatuses = async () => {
    try {
      const response = await client.get('v3/me/wallet-transactions/types');

      const _types: TTypeStatus[] = [types[0]];

      types.forEach((type) => {
        if (response.types.some((item: any) => type?.value === item)) {
          _types.push(type);
        }
      });

      this.setState({ types: _types });
    } catch (error: any) {
      console.error({ error });
    }
  };

  public render() {
    return (
      <HorizontalScroll>
        <div className={'wallet-statuses d-flex mt-4 mb-4'}>
          {this.state.types.map((status) => (
            <p
              onClick={() => this.props.setStatus(status.value)}
              className={`wallet-status family-semibold pb-2 border_active-primary text-size-18 mr-5 color-gray color_active-dark ${
                this.props.status === status.value ? 'active' : ''
              }`}
            >
              {localeStore.t(status.label)}
            </p>
          ))}
        </div>
      </HorizontalScroll>
    );
  }
}
