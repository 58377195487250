import { EVENTS_MAP, TEvents } from 'src/constants/events';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { GTM_EVENT_HANDLER } from './handler';

export class GoogleTagManager {
  constructor() {
    this.isInitialized = true;
  }

  private isInitialized: boolean = false;

  public async logEvent(event: TEvents[keyof TEvents], params: any = {}) {
    try {
      if (this.isInitialized) {
        const default_parameters: any = {
          logged_id: userStore.isLogin ? 'logged_in' : 'guest',
          language: localeStore.language,
        };

        if (userStore.isLogin) {
          default_parameters.user_id = userStore.user.id;
        }

        const parameters = await GTM_EVENT_HANDLER(event, params);

        // @ts-ignore
        window.dataLayer?.push({ ecommerce: null });
        // @ts-ignore
        window.dataLayer?.push({
          event: EVENTS_MAP[event],
          ecommerce: parameters,
          ...default_parameters,
        });
      }
    } catch (error: any) {
      console.error(error);
    }
  }
}

export const gtm = new GoogleTagManager();
