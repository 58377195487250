import { FC, ReactElement, useMemo } from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { PRODUCT_TYPE } from 'src/types/products';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import Image from 'src/view/components/Image/Image';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import './style.scss';

interface IProps {
  type?: PRODUCT_TYPE;
  item: IProductInsideCombo;
  selected?: boolean;
  onSelectItem: (item: IProductInsideCombo) => void;
  className?: string;
}

export const ComboItem: FC<IProps> = ({ item, ...props }: IProps): ReactElement => {
  const isMeal = useMemo((): boolean => props.type === PRODUCT_TYPE.MEAL_COMBO, [props.type]);
  const units = useMemo(() => productUtils.getComboItemUnits(item), [item]);

  const unavailableMessage = productUtils.getComboItemUnavailableMessage(item, isMeal);

  const onSelectItem = () => {
    props.onSelectItem(item);
  };

  const itemClassName = useMemo((): string => {
    let className: string = 'product-combo-item';

    if (props.className) className += ` ${props.className}`;
    if (item.is_selected || props.selected) className += ' border_pseudo-primary';

    return className;
  }, [item, props.selected, props.className]);

  return (
    <ResponsiveContent min={WINDOW_SIZES.md}>
      <li className={itemClassName} onClick={onSelectItem}>
        <Image
          aspectRatio={1.263}
          src={item.image}
          wrapperClassName={`product-combo-item__image-wrapper ${unavailableMessage ? 'opacity-50' : ''}`}
        />

        {unavailableMessage ? (
          <span className="product-combo-item__unavailable-msg letter-spacing-12">
            {localeStore.t(unavailableMessage)}
          </span>
        ) : null}

        <div className={`product-combo-item__text-wrapper ${unavailableMessage ? 'opacity-50' : ''}`}>
          <p className="product-combo-item__text family-bold color-dark">{getTranslate(item.name)}</p>

          <div className={`product-combo-item__unit justify-content-${isMeal ? 'between' : 'end'}`}>
            {isMeal ? (
              <p className="family-semibold color-primary text-size-14 text-uppercase">
                {formatPrice(Number(item.price))}
              </p>
            ) : null}
            <p className="family-regular color-gray text-size-10 text-end">
              {units.map((unit) => (unit ? <span className="ml-1">{unit}</span> : null))}
            </p>
          </div>
        </div>
      </li>
    </ResponsiveContent>
  );
};
