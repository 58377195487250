import React from "react";
import Collapsible from 'react-collapsible';
import "./style.scss";

interface IProps {
  trigger: string | React.ElementType<any>;
  children?: string | React.ReactElement<any> | null | React.ReactNode;
  transitionTime?: number;
  disabled?: boolean;
}

interface IState {
  isOpen: boolean;
}

export default class Accordion extends React.Component<IProps, IState> {
  state = {
    isOpen: false,
  };

  toggleDropdownState = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { trigger: Trigger, children, transitionTime = 150, disabled = false } = this.props;

    return (
      <Collapsible
        trigger={(
          <Trigger isOpen={this.state.isOpen} />
        )}
        transitionTime={transitionTime}
        onOpen={this.toggleDropdownState}
        onClose={this.toggleDropdownState}
        triggerDisabled={disabled}
      >
        <>
          {children}
        </>
      </Collapsible>
    );
  }
}
