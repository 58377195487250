import axios, { Canceler } from 'axios';
import { action, observable } from 'mobx';
import client from 'src/services/client';
import { IBranch } from 'src/types/branch';
import { IPagination } from 'src/types/main';
import { IUser } from 'src/types/user';
import { logError } from 'src/utils/helpers';
import { IReservation } from '../../types/reservations';

export class ReservationsStore {
  cancel: Canceler | null = null;
  @observable public pagination: IPagination<IReservation> = {
    current_page: 0,
    last_page: 1,
    data: [],
    from: 0,
    to: 0,
    total: 0,
    loading: false,
  };

  @action fetchReservations = async (page?: number) => {
    try {
      this.pagination.loading = true;
      const pagination = await client.get('/v3/me/reservations', {
        params: { page: page || this.pagination.current_page + 1, exclude: ['is_hide', 'is_delete'] },
        cancelToken: new axios.CancelToken((c) => (this.cancel = c)),
      });
      this.pagination = {
        ...pagination,
        loading: false,
        data: [...this.pagination.data, ...pagination.data],
      };
    } catch (err) {
      this.pagination.loading = false;
    }
  };

  @action createReservation = async (data: {
    people_number: IReservation['people_number'];
    type: number;
    branch: IBranch['id'];
    phone_number: IUser['mobile'];
    name: IUser['name'];
  }): Promise<IReservation> => {
    try {
      const response = await client.post('/v3/reservations', data);
      this.pagination.data = [response, ...this.pagination.data];
      return response;
    } catch (error: any) {
      logError(error);
      throw error;
    }
  };

  @action cancelReservation = async (id: IReservation['id']) => {
    try {
      await client.post(`/v3/me/reservations/${id}/cancel`);
      this.pagination.data = this.pagination.data.map((order) =>
        order.id === id ? { ...order, status: 'is_canceled' } : order
      );
    } catch (err) {
      throw err;
    }
  };

  @action clear = (cancelable: boolean) => {
    if (cancelable && this.cancel) this.cancel();
    this.pagination = {
      current_page: 0,
      last_page: 1,
      data: [],
      from: 0,
      to: 0,
      total: 0,
      loading: false,
    };
  };
}

export const reservationsStore = new ReservationsStore();
