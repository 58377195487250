import React, { Component } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { ICONS } from 'src/constants/icons';
import { userStore } from 'src/mobx/userStore';
import './style.scss';
import { SCREENS } from 'src/constants/screens';
import { Link } from 'react-router-dom';

import LoginContainer from 'src/view/containers/LoginContainer/LoginContainer';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import LogoutContainer from 'src/view/containers/LogoutContainer/LogoutContainer';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import packageJson from 'src/../package.json';

@observer
class SideScreen extends Component {
  logout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };

  login = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  };

  loggedIn = [
    { name: localeStore.t('txt_edit_profile'), icon: ICONS.PROFILE, url: SCREENS.USER() },
    { name: localeStore.t('menu_contact_us'), icon: ICONS.CONTACT_US, url: SCREENS.CONTACTS() },
    { name: localeStore.t('menu_logout'), icon: ICONS.lOGOUT, onClick: this.logout },
  ];

  loggedOut = [
    { name: localeStore.t('menu_contact_us'), icon: ICONS.CONTACT_US, url: SCREENS.CONTACTS() },
    { name: localeStore.t('txt_login'), icon: ICONS.lOGOUT, onClick: this.login },
  ];

  changeLanguage = async () => {
    if (localeStore.language === 'en') {
      localeStore.setLocale('ar');
    } else {
      localeStore.setLocale('en');
    }
  };

  render() {
    return (
      <div className="more">
        <div className="more-title family-regular">{localeStore.t('txt_more')}</div>
        <div className="more-btns">
          <button
            onClick={this.changeLanguage}
            className={`more-btns-btn family-regular ${
              localeStore.language === 'en' ? 'background-primary btn-color' : 'background-secondary'
            }`}
          >
            {localeStore.t('txt_english')}
          </button>
          <button
            onClick={this.changeLanguage}
            className={`more-btns-btn-ar family-regular ${
              localeStore.language === 'ar' ? 'background-primary btn-color' : 'background-secondary'
            }`}
          >
            {localeStore.t('txt_arabic')}
          </button>
        </div>
        <div className="more-name-account family-regular">{localeStore.t('menu_setting')}</div>
        <div className="more-content">
          {userStore.isLogin
            ? this.loggedIn.map((iconNameItem, i) => (
                <div key={i}>
                  {iconNameItem.url ? (
                    <Link
                      rel="tag"
                      title={iconNameItem.name}
                      hrefLang={localeStore.language}
                      className="more-item family-regular"
                      to={{
                        pathname: iconNameItem.url,
                        search: `?language=${localeStore.language}`,
                      }}
                    >
                      <div className="more-item-icon">
                        <Icon name={iconNameItem.icon} size={24} color={'gray'} />
                      </div>
                      <div className="more-item-name family-regular">{iconNameItem.name}</div>
                    </Link>
                  ) : (
                    <div className="more-container-left">
                      <DropDownModal
                        content={({ closeModal }) => <LogoutContainer closeModal={closeModal} />}
                        selfClose={false}
                        asPopupInMobile
                        bottomSheet
                      >
                        <a className="more-item family-regular" onClick={iconNameItem.onClick} href="1">
                          <div className="more-item-icon">
                            <Icon name={iconNameItem.icon} size={24} color={'gray'} />
                          </div>
                          <div className="more-item-name family-regular">{iconNameItem.name}</div>
                        </a>
                      </DropDownModal>
                    </div>
                  )}
                </div>
              ))
            : this.loggedOut.map((iconNameItem, i) => (
                <div key={i}>
                  {iconNameItem.url ? (
                    <Link
                      rel="tag"
                      title={iconNameItem.name}
                      to={{
                        pathname: iconNameItem.url,
                        search: `?language=${localeStore.language}`,
                      }}
                      hrefLang={localeStore.language}
                      className="more-item family-regular"
                    >
                      <div className="more-item-icon">
                        <Icon name={iconNameItem.icon} size={24} color={'gray'} />
                      </div>
                      <div className="more-item-name family-regular">{iconNameItem.name}</div>
                    </Link>
                  ) : (
                    <div className="more-container-left">
                      <DropDownModal
                        asPopupInMobile
                        selfClose={false}
                        content={({ closeModal }) => <LoginContainer closeModal={closeModal} />}
                      >
                        <a className="more-item family-regular" onClick={iconNameItem.onClick} href="1">
                          <div className="more-item-icon">
                            <Icon name={iconNameItem.icon} size={24} color={'gray'} />
                          </div>
                          <div className="more-item-name family-regular">{iconNameItem.name}</div>
                        </a>
                      </DropDownModal>
                    </div>
                  )}
                </div>
              ))}
        </div>
        <div className="more-container">
          <div className="more-app-info">
            <div className="more-name family-regular">{localeStore.t('powered_by')} </div>
            <img
              className="more-taker "
              src={`${process.env.REACT_APP_CLOUD_FRONT}/media/images/Taker.png`}
              title="Taker"
              alt="Taker"
              width="45"
              height="12"
            />
          </div>
          <span className="more-app-version">V: {packageJson.version}</span>
        </div>
      </div>
    );
  }
}

export default SideScreen;
