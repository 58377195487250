import { observer } from 'mobx-react';
import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import BackIcon from 'src/view/components/Controls/BackIcon/BackIcon';
import { SCREENS } from '../../../../../constants/screens';
import { localeStore } from '../../../../../mobx/localesStore';
import { PageTitle } from '../../../../components/PageTitle/PageTitle';
import TabList from '../../../../components/TabList/TabList';
import './style.scss';

@observer
class Tabs extends React.Component<RouteComponentProps> {
  get pageTitle() {
    const location = window.location.href;
    if (!localeStore.timestamp) {
      // wait until translation will be loaded
      return '';
    } else if (location.includes(SCREENS.ABOUT())) {
      return localeStore.t('txt_about');
    } else if (location.includes(SCREENS.FAQ())) {
      return localeStore.t('txt_faq');
    } else if (location.includes(SCREENS.TERMS())) {
      return localeStore.t('txt_terms');
    } else if (location.includes(SCREENS.PRIVACY())) {
      return localeStore.t('txt_privacy');
    } else if (location.includes(SCREENS.CONTACTS())) {
      return localeStore.t('menu_contact_us');
    } else {
      return '';
    }
  }

  render() {
    const title = this.pageTitle;
    return (
      <>
        <div className="tabs d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center mb-2 mt-2 mb-lg-4 mt-lg-4">
            <Link
              to={{
                pathname: SCREENS.MENU(),
                search: `?language=${localeStore.language}`,
              }}
            >
              <BackIcon>
                <PageTitle title={title} />
              </BackIcon>
            </Link>
          </div>
        </div>

        <TabList
          list={[
            { text: localeStore.t('txt_about'), to: SCREENS.ABOUT() },
            { text: localeStore.t('txt_faq'), to: SCREENS.FAQ() },
            { text: localeStore.t('txt_terms'), to: SCREENS.TERMS() },
            { text: localeStore.t('txt_privacy'), to: SCREENS.PRIVACY() },
            { text: localeStore.t('menu_contact_us'), to: SCREENS.CONTACTS() },
          ]}
          className="more-tabs"
        />
      </>
    );
  }
}

export default withRouter(Tabs);
