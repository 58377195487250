import { observer } from 'mobx-react';
import AvailablePaymentMethods from '../../../../../../containers/AvailablePaymentMethods/AvailablePaymentMethods';
import React, { useEffect, useRef } from 'react';

interface IPaymentMethodsList {
  storeProps: any;
  visible: boolean;
}

const PaymentMethodsList = observer(({ storeProps, visible }: IPaymentMethodsList) => {
  const paymentRef = useRef(null);

  const closeModalHandler = (event: any) => {
    // @ts-ignore
    if (!paymentRef?.current?.contains(event.target)) {
      storeProps.setIsShowPaymentTypeList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeModalHandler);

    return () => {
      document.removeEventListener('click', closeModalHandler);
    };
  }, []);

  return (
    <div className="payment-modal-payment-type d-flex">
      <div className={`available-payment-methods-list animation ${visible ? 'active' : ''}`} ref={paymentRef}>
        <span className="payment-methods-items">
          <AvailablePaymentMethods storeProps={storeProps} />
        </span>
      </div>
    </div>
  );
});

export default PaymentMethodsList;
