import { FC, ReactElement, memo } from 'react';
import { appStore } from 'src/mobx/appStore';
import './style.scss';

const Copyright: FC = (): ReactElement => {
  const currentYear: number = new Date().getFullYear();
  const defaultCopyright: string = `© ${currentYear} ${appStore.settings?.restaurant_name_en}. All rights reserved`;

  return <p className="copyright mt-4 mt-lg-0">{appStore.settings?.copyright || defaultCopyright}</p>;
};

export default memo(Copyright);
