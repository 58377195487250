import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import InnerHtml from 'src/view/components/InnerHtml/InnerHtml';
import ContactUsForm from './containers/ContactUsForm';
import { localeStore } from 'src/mobx/localesStore';
import { appStore } from 'src/mobx/appStore';
import client from 'src/services/client';
import './style.scss';
import { extractHtmlContent, getRestaurantName, setMetaTags } from 'src/utils/helpers';

interface State {
  loading: boolean;
  form: { email: boolean; order_id: boolean; branch_id: boolean };
  template: string;
}

@observer
export default class ContactsScreen extends Component<{}, State> {
  state = {
    loading: false,
    form: { email: false, order_id: false, branch_id: false },
    template: '',
  };

  componentDidMount = async () => {
    try {
      this.setState(() => ({ loading: true }));
      const { template, form } = await client.get('/v3/info/contacts');
      this.setState(() => ({ loading: false, template, form }));
    } catch (error: any) {
      this.setState(() => ({ loading: false }));
    }

    setMetaTags({
      title: `${getRestaurantName()} - ${localeStore.t('menu_contact_us')}`,
      keywords: `${getRestaurantName()}, ${localeStore.t('menu_contact_us')}`,
      'og:title': `${getRestaurantName()} - ${localeStore.t('menu_contact_us')}`,
      description: `${extractHtmlContent(this.state.template)}`,
      'og:description': `${extractHtmlContent(this.state.template)}`,
    });
  };

  render() {
    if (this.state.loading || !localeStore.timestamp) {
      return (
        <div className="d-flex flex-column full-width">
          <Skeleton width={'40%'} height={36} className="mt-5" />
          <div className="mt-5">
            <Skeleton className={'text-size-18'} width={'100%'} count={8} style={{ marginBottom: 10 }} />
            <Skeleton width={'55%'} count={1} style={{ marginBottom: 10 }} />
          </div>
        </div>
      );
    }

    return (
      <div className="full-width">
        {appStore.settings?.is_enquiries ? <ContactUsForm formSettings={this.state.form} /> : null}
        <InnerHtml html={this.state.template} className={'mt-2'} />
      </div>
    );
  }
}
