import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { PAYMENT_TYPES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { cartStore } from 'src/mobx/cartStore';
import { checkoutStore } from 'src/mobx/checkoutStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { requests } from 'src/requests';
import { IPaymentCard } from 'src/types/user';
import { getUserLastCard } from 'src/utils/helpers';
import Icon from 'src/view/components/Controls/Icon/Icon';
import HorizontalScroll from 'src/view/components/HorizontalScroll/HorizontalScroll';
import PaymentCard from 'src/view/components/PaymentCard/PaymentCard';
import './style.scss';

interface State {
  deleted_id: number | null;
  loading: boolean;
}

@observer
export default class CheckoutPaymentCards extends Component<{}, State> {
  state: State = {
    deleted_id: null,
    loading: false,
  };

  /**
   *
   *
   * @memberof CheckoutPaymentCards
   */
  fetchUserCards = async () => {
    try {
      const last_payment_card = getUserLastCard(userStore.payment_cards);
      if (last_payment_card) cartStore.selectPaymentCard(last_payment_card);
    } catch (error: any) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  onAddCrediCardPress = () => {
    cartStore.setModalValue('paymentCard', { loading: false });
  };

  onCardPress = (card: IPaymentCard) => {
    cartStore.selectPaymentCard(card);
  };

  /**
   * Delete payment Card
   *
   * @param {IPaymentCard} card - payment card
   * @memberof CheckoutPaymentCards
   */
  deleteCard = async (card: IPaymentCard) => {
    try {
      if (cartStore.checkout.selected_card === card.id) {
        cartStore.clearPaymentCard();
      }
      if (!this.state.deleted_id) {
        this.setState({ deleted_id: card.id });
        await cartStore.deleteCreditCard(card);
        await userStore.fetchUserCards().then(() => this.setState({ deleted_id: null }));
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * For render PaymentCard
   *
   * @param {IPaymentCard} card - payment card
   * @memberof CheckoutPaymentCards
   * @returns {PaymentCard} PaymentCard
   */
  renderPaymentCard = (card: IPaymentCard) => {
    return (
      <PaymentCard
        key={card.id}
        card={card}
        selected={cartStore.checkout.selected_card}
        onCardPress={this.onCardPress}
        onCardDelete={this.deleteCard}
        loading={this.state.deleted_id === card.id}
      />
    );
  };

  openModal = () => {
    checkoutStore.openAddPaymentCart();
  };

  render() {
    if (!cartStore.isValidPaymentType || cartStore.checkout.payment_type !== PAYMENT_TYPES.ONLINE_PAYMENT) return null;
    const haveCards = userStore.payment_cards?.length;
    return (
      <div className="mb-lg-4 d-none d-lg-flex flex-column">
        <p className="text-size-14 color-darkgray family-regular text-uppercase mb-2">
          {localeStore.t('txt_saved_cards')}
        </p>
        <div className="payment-cards-list">
          <HorizontalScroll>
            <div className="d-flex flex-row">
              {haveCards ? (
                <>
                  <div
                    onClick={this.onAddCrediCardPress}
                    className={`payment-cards-add small d-flex align-items-center justify-content-center`}
                  >
                    <Icon name={ICONS.PLUS} />
                  </div>
                  {userStore.payment_cards.map(this.renderPaymentCard)}
                </>
              ) : (
                <div
                  onClick={this.onAddCrediCardPress}
                  className={`payment-cards-add d-flex align-items-center justify-content-center`}
                >
                  <p className="text-size-14 family-semibold">{localeStore.t('txt_enter_card_details')}</p>
                </div>
              )}
            </div>
          </HorizontalScroll>
        </div>
      </div>
    );
  }
}
