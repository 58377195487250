import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { IProduct } from 'src/types/products';
import { formatPrice } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';

interface IProps {
  product?: IProduct;
}

const ProductPrice = (props: IProps): JSX.Element => {
  const price = (): number => {
    return props.product ? productUtils.calculateProductPrice(props.product) : 0;
  };

  return (
    <div className="d-flex flex-lg-row flex-column align-items-center mr-0 mr-lg-3">
      <span className="product-price text-md-size-18 mr-0 mr-lg-2 color-primary text-nowrap">
        {props.product ? formatPrice(price()) : <Skeleton width={70} />}
      </span>
      {branchStore.vat_included ? (
        <span className="product-vat text-nowrap">{localeStore.t('VAT inclusive')}</span>
      ) : null}
    </div>
  );
};

export default ProductPrice;
