import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { appStore } from 'src/mobx/appStore';
import client from 'src/services/client';
import { IBranch, IBranchArea } from 'src/types/branch';
import { IPagination } from 'src/types/main';
import { logError } from 'src/utils/helpers';

export interface IRequestsBranchSearchResponse {
  branch: IBranch | null;
  area: IBranchArea | null;
  address: string;
  message: string;
}

export interface IRequestsBranchResponse {
  branch: IBranch | null;
  area: IBranchArea | null;
  address: string;
}
const branches = {
  async branchSearch(query: {
    latitude?: number;
    longitude?: number;
    delivery_type?: string;
    without_area?: number;
  }): Promise<IRequestsBranchSearchResponse | null> {
    try {
      const response = await client.get(`/v3/branches/search`, { params: query });
      return response;
    } catch (error: any) {
      logError(error);
      return null;
    }
  },

  /**
   * @member branchSearchAddress
   *
   * @param {{
   *     latitude?: number;
   *     longitude?: number;
   *     delivery_type?: string;
   *   }} query
   * @return {{address: string}} {(Promise<IRequestsBranchSearchResponse | null>)}
   */
  async branchSearchAddress(query: {
    latitude?: number;
    longitude?: number;
    delivery_type?: string;
  }): Promise<IRequestsBranchSearchResponse | null> {
    try {
      const response = await client.get(`/v3/delivery/address`, { params: query });
      return response;
    } catch (error: any) {
      logError(error);
      return null;
    }
  },

  async getBranch(
    id: IBranch['id'],
    query?: { latitude?: number; longitude?: number; is_emenu?: number; delivery_type?: string }
  ): Promise<IBranch | null> {
    try {
      const response = await client.get(`/v3/branches/${id}`, { params: query });
      return response;
    } catch (error: any) {
      logError(error);
      return null;
    }
  },

  async getBranches(query?: {
    page: number;
    latitude?: number;
    longitude?: number;
    delivery_type?: string;
    working_now?: boolean;
    per_page?: any;
  }): Promise<IPagination<IBranch> | null> {
    try {
      const response = await client.get(`/v3/branches`, { params: { ...query, per_page: 100 } });
      return response;
    } catch (error: any) {
      logError(error);
      return null;
    }
  },
};

export default branches;
