import React from 'react';
import './style.scss';

interface IProps {
  className?: string;
  scrollClassName?: string;
  showScrollbar: boolean;
  scrollbarSize: 'small' | 'normal';
  direction: 'horizontal' | 'vertical';
  children?: React.ReactNode;
}

interface IState {
  scroll: boolean;
}

export default class ScrollableBlock extends React.Component<IProps, IState> {
  wrapperRef: HTMLDivElement | null = null;
  scrollRef: HTMLDivElement | null = null;

  static defaultProps = {
    showScrollbar: false,
    scrollbarSize: 'normal',
    direction: 'vertical',
  };

  state = {
    scroll: false,
  };

  componentDidMount = () => {
    if (this.props.direction === 'horizontal' && this.wrapperRef) {
      this.wrapperRef.addEventListener('wheel', this.onWindowHorizontalScroll);
    }

    this.checkScroll();
  };

  componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.children !== this.props.children) this.checkScroll();
  };

  componentWillUnmount = () => {
    if (this.props.direction === 'horizontal' && this.wrapperRef) {
      this.wrapperRef.removeEventListener('wheel', this.onWindowHorizontalScroll);
    }
  };

  onWindowHorizontalScroll = async (event: WheelEvent) => {
    event.preventDefault();
    if (!this.wrapperRef) return;

    this.wrapperRef.scrollTo({ left: this.wrapperRef.scrollLeft + event.deltaY });
  };

  checkScroll = () => {
    if (!this.props.showScrollbar) return;

    if (this.wrapperRef?.getClientRects()[0] && this.scrollRef?.getClientRects()[0]) {
      const { width: wrapperWidth, height: wrapperHeight } = this.wrapperRef.getClientRects()[0];
      const { width: scrollWidth, height: scrollHeight } = this.scrollRef.getClientRects()[0];
      this.setState({
        scroll: this.props.direction === 'vertical' ? scrollHeight > wrapperHeight : scrollWidth > wrapperWidth,
      });
    }
  };

  get className(): string {
    let className: string = 'scrollable-block';

    if (this.props.direction === 'horizontal') className += ' scrollable-block_horizontal';

    if (!this.props.showScrollbar) {
      className += ' hide-scrollbar';
    } else if (this.props.scrollbarSize === 'small') {
      className += ' scrollable-block__scroll_small';
      if (this.state.scroll) {
        if (this.props.direction === 'vertical') className += ' mr-1';
        else if (this.props.direction === 'horizontal') className += ' mb-1';
      }
    } else if (this.props.scrollbarSize === 'normal') className += ' scrollable-block__scroll_normal';

    if (this.props.className) className += ` ${this.props.className}`;

    return className;
  }

  get scrollClassName(): string {
    let className: string = 'scrollable-block__scroll';
    if (this.props.scrollClassName) className += ` ${this.props.scrollClassName}`;
    if (this.props.direction === 'horizontal') className += ' scrollable-block__scroll_horizontal';
    return className;
  }

  render() {
    return (
      <div ref={(ref) => (this.wrapperRef = ref)} className={this.className}>
        <div ref={(ref) => (this.scrollRef = ref)} className={this.scrollClassName}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
