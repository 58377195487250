import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { cartStore } from 'src/mobx/cartStore';
import { ICheckoutModals } from 'src/types/cart';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import LoginContainer from 'src/view/containers/LoginContainer/LoginContainer';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModalLogin extends Component<Props> {
  closeModal = () => {
    cartStore.setModalValue('login', false);
  };

  renderLoginModal = ({ closeModal }: { closeModal: () => void }) => {
    return (
      <div className="d-flex flex-row justify-content-center">
        <LoginContainer closeModal={closeModal} />
      </div>
    );
  };

  render() {
    const { modals } = cartStore.checkout;

    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose={false}
        backdrop
        onClose={this.closeModal}
        visible={modals.login}
        content={this.renderLoginModal}
        mobile={{
          popup: true,
          sheet: true,
        }}
      />
    );
  }
}

export default withRouter(CheckoutModalLogin);
