import { observer } from 'mobx-react';
import { Component } from 'react';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { IDineInItem, IEMenuTable } from 'src/types/branch';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import Modal from '../../../../../components/Controls/Modal/Modal';
import '../style.scss';

type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
};

type State = {
  table: IDineInItem | null;
};

@observer
export default class CheckoutDineInTable extends Component<Props, State> {
  state = {
    table: cartStore.checkout.dine_in?.table || null,
  };

  onItemClick = (table: IDineInItem) => () => {
    this.setState({ table });
  };

  onSubmit = () => {
    cartStore.setTable(this.state.table);
    this.props.onRequestClose();
  };

  renderItem = ({ title, id }: IEMenuTable, index: number) => {
    return (
      <div
        className={`${
          this.state.table?.id === id ? 'th-bg-controls-primary  th-controls-secondary' : ''
        } checkout-dine_in-modal-container-grid-item `}
        key={index}
        onClick={this.onItemClick({ id, title })}
      >
        <p className="checkout-dine_in-modal-container-grid-item-text">{title}</p>
      </div>
    );
  };

  render() {
    const { section } = cartStore.checkout?.dine_in || {};
    const { table } = this.state;
    const tables = branchStore.branch?.emenu?.sections?.find((el) => el.id === section?.id)?.tables || [];
    return (
      <Modal className="checkout-dine_in-modal" isOpen={this.props.isOpen} withPane>
        <div className="checkout-dine_in-modal-wrapper">
          <div className="checkout-dine_in-modal-wrapper-scroll">
            <div className="checkout-dine_in-modal-container">
              <p className="checkout-dine_in-modal-container-subtitle color-primary">{section?.title}</p>
              <p className="checkout-dine_in-modal-container-title">{localeStore.t('select_table')}</p>
              <div className="checkout-dine_in-modal-container-grid">{tables?.map(this.renderItem)}</div>
            </div>
          </div>
          <div className="checkout-dine_in-modal-container">
            <ButtonComponent
              id={'checkout_submit'}
              disabled={!(table ?? false)}
              className="checkout-dine_in-modal-container-button"
              onClick={this.onSubmit}
            >
              {localeStore.t('txt_submit')}
            </ButtonComponent>
          </div>
        </div>
      </Modal>
    );
  }
}
