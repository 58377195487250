import { Component } from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { ICONS } from 'src/constants/icons';
import { WINDOW_SIZES } from 'src/constants/main';
import { TimeController } from 'src/controllers/TimeController/TimeController';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import { IBranch } from 'src/types/branch';
import { getTranslate } from 'src/utils/formatter';
import { getPreorderIsAvailable, getPreorderMessage } from 'src/utils/helpers';
import Icon from 'src/view/components/Controls/Icon/Icon';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import BranchesSubmitButton from '../../../components/BranchesSubmitButton/BranchesSubmitButton';
import { WorkSlots } from '../../../components/WorkSlots/WorkSlots';
import '../style.scss';

interface Props {
  selected?: IBranch['id'];
  onSelectBranch?: (branch: IBranch) => void;
  branch?: IBranch | null;
  lat?: number;
  lng?: number;
  renderPin?: boolean;
}

export default class BranchPickupPopup extends Component<Props> {
  selectBranch = () => {
    if (!this.props.branch) return null;

    if (this.props.onSelectBranch) this.props.onSelectBranch(this.props.branch);
  };
  renderPopup = () => {
    const { branch } = this.props;
    if (!branch) return null;

    const timeController = new TimeController({ branch, deliveryType: DELIVERY_TYPES.DELIVERY });
    const preorder_message = getPreorderMessage(timeController.preorderIsAvailable);
    const [status, isBranchWorking] = timeController.getWorkStatus();

    return (
      <div className="branches-popup animation slide_in_down">
        {this.props.selected === this.props.branch?.id ? (
          <div className="branches-selected background-primary">
            <Icon name={ICONS.CHECK} size={14} className={'color-white'} />
          </div>
        ) : null}
        <div className="d-flex flex-row align-items-center  mb-2">
          <p className="branches-title family-semibold">{getTranslate(branch.name)}</p>

          {preorder_message ? (
            <div className="branches-preorder-popup family-semibold background-gray color-white ml-3 d-inline-block">
              {preorder_message}
            </div>
          ) : null}
        </div>

        {branch.is_busy ? (
          <div className="branches-busy family-semibold background-danger color-white d-inline-block">
            {localeStore.t('branch_busy_status')}
          </div>
        ) : null}

        <div className="d-flex flex-column mt-2">
          <WorkSlots work_slots={branch.work_slots} status={status} isBranchWorking={isBranchWorking} />
          <div className="d-flex flex-row align-items-center justify-content-between">
            {branch.address ? (
              <div className="d-flex flex-row align-items-baseline justify-content-start">
                <Icon name={ICONS.MARKER} size={14} className="mr-2  color-gray" />
                <p className="family-regular branches-info color-gray">{branch.address}</p>
              </div>
            ) : null}
            {branch.distance ? (
              <p className="family-regular branches-info color-gray text-nowrap">{branch.distance}</p>
            ) : null}
          </div>
        </div>

        <ResponsiveContent max={WINDOW_SIZES.md}>
          <div className="d-flex flex-column px-3 py-3 mt-3">
            <BranchesSubmitButton
              delivery_type={DELIVERY_TYPES.PICKUP}
              loading={false}
              branch={this.props.branch || null}
            />
          </div>
        </ResponsiveContent>
      </div>
    );
  };

  get className() {
    const { branch } = this.props;
    if (this.props.selected === this.props.branch?.id) return 'branches-marker_selected color-white';
    if (branch?.is_busy) return 'color-danger';
    if (!branch?.working_now) return 'color-gray';
    if (branch.preorder || branch.working_now) return 'color-primary';
    return 'color-gray';
  }

  get iconClassName() {
    if (this.props.selected === this.props.branch?.id) return 'branches-name_selected color-dark';
    return 'color-white';
  }
  render() {
    if (!this.props.branch) return null;
    return (
      <div className="background-primary">
        {this.props.renderPin ? (
          <div>
            <Icon
              onPress={this.selectBranch}
              className={`branches-marker ${this.className}`}
              name={ICONS.MARKER_FILLED}
            >
              {appStore.settings?.restaurant_name_en ? (
                <div className="branches-name">
                  <p className={`${this.iconClassName}`}>{appStore.settings?.restaurant_name_en[0]}</p>
                </div>
              ) : null}
            </Icon>
          </div>
        ) : (
          <>{this.props.selected === this.props.branch?.id ? this.renderPopup() : null}</>
        )}
      </div>
    );
  }
}
