import * as React from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { EVENTS } from 'src/constants/events';
import { WINDOW_SIZES } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { productsStore } from 'src/mobx/productsStore';
import { requests } from 'src/requests';
import logger from 'src/services/logger';
import { IProductInsideCombo } from 'src/types/combo';
// import { TTranslate } from 'src/types/main';
import { ICashbackInfo, IProduct, IProductExclusion, IProductModifier, IProductSize } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { flatten, getRestaurantName, setMetaTags } from 'src/utils/helpers';
import { productUtils } from 'src/utils/productUtils';
import { validateMultiChoiseModifier, validateSingleChoiseModifier } from 'src/utils/validator';
import Modal from 'src/view/components/Controls/Modal/Modal';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import ProductDeleteModal from './components/ProductDeleteModal/ProductDeleteModal';
import ProductImagesBlock from './containers/ProductImagesBlock/ProductImagesBlock';
import ProductInfoBlock from './containers/ProductInfoBlock/ProductInfoBlock';
import ProductSelectorsBlock from './containers/ProductSelectorsBlock/ProductSelectorsBlock';
import ProductSubmitBlock from './containers/ProductSubmitBlock/ProductSubmitBlock';
import { ProductComboScreen } from './screens/ProductComboScreen/ProductComboScreen';
import { ProductComboScreenMobile } from './screens/ProductComboScreenMobile/ProductComboScreenMobile';
import './style.scss';
import { TTranslate } from 'src/types/main';
import { observer } from 'mobx-react';

interface LocationState {
  product: IProduct | undefined;
  updateProduct?: boolean | undefined;
}

export interface IProductScreenProps extends RouteComponentProps<{ id: string }, StaticContext, LocationState> {}

export interface IProductScreenState {
  product: IProduct | undefined;
  cashbackInfoData: ICashbackInfo | null;
  cashbackInfoLoading: boolean;
  isOpen: boolean;
  loading: boolean;
  errors: string[];
  needScrollToError: boolean;
  deleteModal: boolean;
}

export interface IProductCashbackInfo {
  name: TTranslate | undefined;
  amount: number;
  description: TTranslate | undefined;
  loading: boolean;
  visible: boolean;
}

class ProductScreen extends React.Component<IProductScreenProps, IProductScreenState> {
  retrieveLocationState = (): IProduct | undefined => {
    const { state } = this.props.location;
    if (state && state.product && this.props.history.action === 'PUSH') {
      return state.product;
    }
    return undefined;
  };

  state: IProductScreenState = {
    isOpen: false,
    product: this.retrieveLocationState(),
    cashbackInfoData: null,
    cashbackInfoLoading: false,
    loading: false,
    errors: [],
    needScrollToError: false,
    deleteModal: false,
  };

  componentDidMount = () => {
    this.setState({ isOpen: true });

    const inCart = !!cartStore.products.find(({ id }) => this.state.product?.id === id);

    if (!this.state.product || (this.state.product && inCart)) {
      this.fetchProduct(inCart);
    } else {
      this.validateModifiers();
      this.logEvent(this.state.product);

      setMetaTags({
        title: `${getRestaurantName()} - ${getTranslate(this.state.product?.name)}`,
        description: `${getTranslate(this.state.product?.description)}`,
        keywords: `${this.getKeywords(this.state.product)}`,
        'og:title': `${getRestaurantName()} - ${getTranslate(this.state.product?.name)}`,
        'og:type': 'website',
        'og:image': `${this.state.product?.image}`,
        'og:description': `${getTranslate(this.state.product?.description)}`,
        'twitter:title': `${getRestaurantName()} - ${getTranslate(this.state.product?.name)}`,
        'twitter:description': `${getTranslate(this.state.product?.description)}`,
        'twitter:image': `${this.state.product?.image}`,
      });

      this.fetchCashbackInfo();
    }
  };

  componentWillUnmount = () => {
    setMetaTags({
      title: `${getRestaurantName()}`,
      description: `${getRestaurantName()}`,
      'og:title': `${getRestaurantName()}`,
      'og:description': `${getRestaurantName()}`,
      'og:type': 'website',
      'og:image': `${appStore.settings?.img_logo}`,
      'twitter:card': 'summary',
      'twitter:site': `${appStore.settings?.domain}`,
      'twitter:title': `${getRestaurantName()}`,
      'twitter:image': `${appStore.settings?.img_logo}`,
      'twitter:domain': `${appStore.settings?.domain}`,
    });
  };

  logEvent = (product: IProduct) => {
    logger.logEvent(EVENTS.PRODUCT_OPENED, { product });
  };

  getKeywords = (product: IProduct) => {
    const keywords = [];
    const modifiers = product.modifiers.map((el) => el.options);
    const category = productsStore.categories.find((category) => category.id === product?.category_id);
    keywords.push(getRestaurantName());

    if (category) keywords.push(getTranslate(category.name));

    product.modifiers.forEach((el) => keywords.push(getTranslate(el.name)));
    flatten(modifiers).forEach((el) => keywords.push(getTranslate(el.name)));

    return `${keywords.join(', ')}`;
  };

  closeModal = () => {
    // If it is SMS pages and cart have product
    if (cartStore.isSMS && cartStore.products.length) {
      this.props.history.push(SCREENS.SMSPAYMENT());
    } else {
      this.props.history.push(SCREENS.ROOT());
    }
  };

  openDeleteModal = () => {
    this.setState({ deleteModal: true });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModal: false });
  };

  onProductError = () => {
    this.props.history.replace(SCREENS.NOT_FOUND());
  };

  fetchCashbackInfo = async () => {
    try {
      this.setState({ cashbackInfoLoading: true });
      const cashbackInfo = await requests.fetchCashbackInfo(
        this.state.product?.id,
        branchStore.branch.id,
        branchStore.delivery_type
      );
      this.setState({ cashbackInfoData: cashbackInfo, cashbackInfoLoading: false });
    } catch (error: any) {
      this.setState({ cashbackInfoLoading: false });
      console.error({ error });
    }
  };

  fetchProduct = async (inCart?: boolean) => {
    try {
      let product = await requests.fetchProduct(Number(this.props.match.params.id));

      if (!product) {
        this.onProductError();
      }

      // TK-3307 TOFIX
      if (inCart && this.state.product && product) {
        const {
          updatedProducts: [updatedProductOne],
        } = productUtils.transformProductsFromCalculator(
          [this.state.product], // local cart products
          [product] // remote cart products
        );
        product = updatedProductOne;

        // if (cartStore.isCart) {
        //   const { updatedProducts: [updatedProductTwo] } = productUtils.transformProductsFromCalculator(
        //     [updatedProductOne],
        //     cartStore.calculator.products,
        //   );

        //   product = updatedProductTwo;
        // }
      }

      this.logEvent(product);
      this.setState({ product }, this.validateModifiers);
    } catch (error: any) {
      console.error({ error });
      this.onProductError();
    }
  };

  validateModifiers = () => {
    if (!this.state.product) return;

    const idx = this.state.product.modifiers.reduce((sum: string[], modifier) => {
      const valid: boolean = modifier.multi_choice
        ? validateMultiChoiseModifier(modifier)
        : validateSingleChoiseModifier(modifier);

      if (!valid) sum.push(String(modifier.id));

      return sum;
    }, []);

    this.state.product.items?.forEach((item) => {
      const isSelectedItem: boolean = Boolean(item.size_id === this.state.product?.size?.id && item.is_selected);

      if (isSelectedItem) {
        item.modifiers?.forEach((modifier) => {
          const valid: boolean = modifier.multi_choice
            ? validateMultiChoiseModifier(modifier)
            : validateSingleChoiseModifier(modifier);

          if (!valid) idx.push(`${productUtils.getMealItemKey(item)}-${modifier.id}`);
        });
      }
    });

    this.setState({ errors: idx }, () => this.forceUpdate());
  };

  onSizeChange = (size: IProductSize) => {
    this.setState(
      (state) => ({ product: state.product ? { ...state.product, size } : undefined }),
      this.validateModifiers
    );
  };

  onChangeQuantity = (value: number) => {
    this.setState((state) => ({ product: state.product ? { ...state.product, quantity: value } : undefined }));
  };

  onNotesChanged = (notes: string) => {
    this.setState((state) => ({ product: state.product ? { ...state.product, notes } : undefined }));
  };

  changeQuantity = (value: number) => {
    this.setState((state) => ({ product: state.product ? { ...state.product, quantity: value } : undefined }));
  };

  onModifierChange = (modifier: IProductModifier) => {
    this.setState(
      (state) => ({
        ...state,
        product: state.product
          ? {
              ...state.product,
              modifiers: state.product.modifiers.map((_modifier: IProductModifier) => {
                if (_modifier.id === modifier.id) return modifier;
                return _modifier;
              }),
            }
          : undefined,
      }),
      this.validateModifiers
    );
  };
  onChangeModifierInComboItem = (item: IProductInsideCombo, modifier: IProductModifier) => {
    if (!this.state.product) return;

    const newItems = this.state.product.items?.map((_item) => {
      if (productUtils.isSameComboItems(_item, item)) {
        return {
          ..._item,
          modifiers: _item.modifiers?.map((_modifier) => (_modifier.id === modifier.id ? modifier : _modifier)),
        };
      }

      return _item;
    });

    this.setState({ product: { ...this.state.product, items: newItems } }, this.validateModifiers);
  };

  onExclusionsChange = (exclusions: IProductExclusion[]) => {
    this.setState((state) => ({ product: state.product ? { ...state.product, exclusions } : undefined }));
  };
  onChangeExclusionsInComboItem = (item: IProductInsideCombo, exclusions: IProductExclusion[]) => {
    if (!this.state.product) return;

    const newItems = this.state.product.items?.map((_item) => {
      if (productUtils.isSameComboItems(_item, item)) return { ..._item, exclusions };

      return _item;
    });

    this.setState({ product: { ...this.state.product, items: newItems } });
  };

  onSelectDealItem = (item: IProductInsideCombo) => {
    if (!this.state.product) return;

    const newItems = this.state.product.items?.map((_item: IProductInsideCombo) => ({
      ..._item,
      is_selected: Number(_item.id === item.id),
    }));

    this.setState({ product: { ...this.state.product, items: newItems } });
  };

  onSelectMealItem = (item: IProductInsideCombo) => {
    if (!this.state.product?.items?.length) return;

    const selectItemHandler = (_item: IProductInsideCombo): IProductInsideCombo => {
      if (productUtils.isSameComboItems(_item, item)) return { ...item, is_selected: 1 };
      if (productUtils.isSameComboItemsSizeGroup(_item, item)) return { ..._item, is_selected: 0 };
      return _item;
    };

    const newItems: IProductInsideCombo[] = this.state.product.items.map(selectItemHandler) || [];

    this.setState({ product: { ...this.state.product, items: newItems } }, this.validateModifiers);
  };

  removeProductFromCart = () => {
    const { product } = this.state;
    if (product) {
      cartStore.remove(product);
      this.closeModal();
    }
  };

  setNeedScrollToError = (need: boolean) => {
    this.setState({ needScrollToError: need });
  };

  get cashbackInfo(): IProductCashbackInfo {
    const { product, cashbackInfoData, cashbackInfoLoading } = this.state;
    const shouldShowCashback = cashbackInfoData?.id != null || cashbackInfoLoading;

    return {
      name: cashbackInfoData?.name,
      description: cashbackInfoData?.description,
      amount: productUtils.calculateProductCashbackAmount(product, cashbackInfoData),
      loading: cashbackInfoLoading,
      visible: shouldShowCashback,
    };
  }

  get readOnly(): boolean {
    return !cartStore.isEditable || (cartStore.isSMS && !this.state.product?.hash);
  }

  get comboScreenProps() {
    return {
      product: this.state.product,
      cashback: this.cashbackInfo,
      modifiersErrors: this.state.errors,
      needScrollToError: this.state.needScrollToError,
      isUpdateProduct: Boolean(this.props.location.state?.updateProduct),
      readOnly: this.readOnly,
      onSizeChange: this.onSizeChange,
      onChangeQuantity: this.onChangeQuantity,
      onNotesChanged: this.onNotesChanged,
      onModifierChange: this.onChangeModifierInComboItem,
      onExclusionsChange: this.onChangeExclusionsInComboItem,
      onSelectDealItem: this.onSelectDealItem,
      onSelectMealItem: this.onSelectMealItem,
      setNeedScrollToError: this.setNeedScrollToError,
      onOpenDeleteModal: this.openDeleteModal,
      onCloseModal: this.closeModal,
    };
  }

  renderImage = () => (
    <ProductImagesBlock
      product={this.state.product}
      updateProduct={this.props.location.state?.updateProduct && cartStore.isEditable}
      openDeleteModal={this.openDeleteModal}
    />
  );

  renderSelectors = () => (
    <ProductSelectorsBlock
      product={this.state.product}
      errors={this.state.errors}
      needScrollToError={this.state.needScrollToError}
      onModifierChange={this.onModifierChange}
      setNeedScrollToError={this.setNeedScrollToError}
      onExclusionsChange={this.onExclusionsChange}
      onNotesChanged={this.onNotesChanged}
      readonly={this.readOnly}
    />
  );

  renderInfo = (mdScreen?: boolean) => (
    <ProductInfoBlock
      mdScreen={mdScreen}
      product={this.state.product}
      cashback={this.cashbackInfo}
      onNotesChanged={this.onNotesChanged}
      onSizeChange={this.onSizeChange}
    />
  );

  renderSubmit = () => (
    <ProductSubmitBlock
      product={this.state.product}
      cashback={this.cashbackInfo}
      errors={this.state.errors}
      needScrollToError={this.state.needScrollToError}
      closeModal={this.closeModal}
      onSizeChange={this.onSizeChange}
      changeQuantity={this.onChangeQuantity}
      setNeedScrollToError={this.setNeedScrollToError}
    />
  );

  public render() {
    const isCombo: boolean = Boolean(this.state.product?.type);

    return (
      <Modal
        className="product-modal"
        isOpen={this.state.isOpen}
        onRequestClose={this.closeModal}
        withCloseIcon
        closeBtnClass="product-modal-close"
      >
        <article className="product fadein">
          {isCombo ? (
            <>
              <ProductComboScreen {...this.comboScreenProps} />
              <ProductComboScreenMobile {...this.comboScreenProps} />
            </>
          ) : (
            <>
              <div className="d-flex flex-column flex-lg-row full-width" style={{ height: 'inherit' }}>
                <ResponsiveContent min={WINDOW_SIZES.md}>
                  <div className="d-flex flex-column justify-content-between w-50">
                    {this.renderImage()}
                    {this.renderSelectors()}
                  </div>

                  <div className="d-flex flex-column justify-content-between w-50">
                    {this.renderInfo(true)}
                    {this.renderSubmit()}
                  </div>
                </ResponsiveContent>

                <ResponsiveContent min={WINDOW_SIZES.sm} max={WINDOW_SIZES.md}>
                  <div
                    className="d-flex flex-column full-width justify-content-between"
                    style={{ overflowY: 'auto', height: 'inherit' }}
                  >
                    {this.renderImage()}

                    <div className="d-flex flex-row">
                      <div className="d-flex flex-column w-50 justify-content-between">{this.renderInfo()}</div>
                      <div className="d-flex flex-column w-50 justify-content-between">{this.renderSelectors()}</div>
                    </div>

                    {this.renderSubmit()}
                  </div>
                </ResponsiveContent>

                <ResponsiveContent max={WINDOW_SIZES.sm}>
                  <div
                    className="d-flex flex-column full-width justify-content-between"
                    style={{ overflowY: 'auto', height: 'inherit' }}
                  >
                    {this.renderImage()}
                    {this.renderInfo()}
                    {this.renderSelectors()}
                    {this.renderSubmit()}
                  </div>
                </ResponsiveContent>
              </div>
            </>
          )}

          <ResponsiveContent max={WINDOW_SIZES.sm}>
            <div className="close_modal" onClick={this.closeModal} />
          </ResponsiveContent>
        </article>

        <ProductDeleteModal
          visible={this.state.deleteModal}
          onClose={this.closeDeleteModal}
          onSubmit={this.removeProductFromCart}
        />
      </Modal>
    );
  }
}

export default observer(ProductScreen);
