import React, { FC, memo } from 'react';
import { localeStore } from '../../../../../mobx/localesStore';
import Icon from '../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../constants/icons';
import Tooltip from '../../../../components/Tooltip/Tooltip';

const CheckoutTooltip: FC = (): JSX.Element => {
  return (
    <Tooltip
      content={() => (
        <div className="card" style={{ padding: 15, maxWidth: 200 }}>
          <p className={'color-dark text-size-14 family-regular'}>
            {localeStore.t('cancel_conditions')}
          </p>
        </div>
      )}
      position="top"
      direction="up"
    >
      <div className={'d-flex info-type-checkout'}>
        <p className="color-darkgray text-size-12 text-uppercase align-self-center">
          {localeStore.t('online_payment_alert')}
        </p>
        <Icon name={ICONS.INFO} size={20} color="#6d6d6d" className={"color-darkgray icon"} />
      </div>
    </Tooltip>
  )
}
export default memo(CheckoutTooltip);