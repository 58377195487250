import React, { FC, useCallback, useMemo } from 'react';
import Icon from '../../../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../../../constants/icons';
import { IAddressResponse } from '../../../../../../../types/address';
import branchesScreenStore from '../../../../branchesScreenStore';
import { observer } from 'mobx-react';
import { getDeliveryAddressName } from '../../../../../../../utils/helpers';
import { branchStore } from '../../../../../../../mobx/barnchStore';
import { getTranslate } from 'src/utils/formatter';

interface IProps {
  address: IAddressResponse;
}
const Address: FC<IProps> = observer(({ address }: IProps) => {
  const addressName = useMemo(() => {
    return getDeliveryAddressName(address);
  }, []);

  const checked = useMemo(() => {
    return branchesScreenStore.selectedAddressId === address.id;
  }, [branchesScreenStore.selectedAddressId]);

  const editAddress = useCallback(async () => {
    branchesScreenStore.onAddressEdit(address.id);
    if (branchesScreenStore.address) {
      branchStore.setCoordinate({
        latitude: branchesScreenStore.address?.latitude,
        longitude: branchesScreenStore.address?.longitude,
      });
    }
  }, []);

  const onSelect = useCallback(async () => {
    await branchesScreenStore.selectAddressId(address.id);
    if (branchesScreenStore.address) {
      branchStore.setCoordinate({
        latitude: branchesScreenStore.address?.latitude,
        longitude: branchesScreenStore.address?.longitude,
      });
    }
  }, []);

  return (
    <div className={`branches-sidebar-branch address-wrap d-flex flex-row full-width`}>
      <div className="info d-flex flex-row address-list" onClick={onSelect}>
        <span className={`check-box ${checked ? 'active' : ''} background_pseudo-primary mr-3`}>
          {checked ? <Icon name={ICONS.CHECK} size={16} className="check color-white" /> : null}
        </span>
        <div className="title">
          <p>{addressName}</p>
          {address?.error_message ? <p className={'error'}>{getTranslate(address?.error_message)}</p> : null}
        </div>
      </div>
      <div className="edit-address" onClick={editAddress}>
        <Icon name={ICONS.EDIT} size={24} className="mr-2 edit color-gray" />
      </div>
    </div>
  );
});
export default Address;
