import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SCREENS } from '../../../constants/screens';

export interface IRouterProps extends RouteComponentProps<any> {}

class ScrollToTop extends React.Component<IRouterProps> {
  approveToTop = [SCREENS.ABOUT(), SCREENS.FAQ(), SCREENS.TERMS(), SCREENS.PRIVACY(), SCREENS.CONTACTS()];
  componentDidUpdate() {
    if (this.approveToTop.includes(this.props.location.pathname)) window.scrollTo(0, 0);
  }

  render() {
    return React.Fragment;
  }
}

export default withRouter(ScrollToTop);
