import Spinner from '../../components/Controls/Spinner/Spinner';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useEffect } from 'react';
import { localeStore } from '../../../mobx/localesStore';
import { SCREENS } from '../../../constants/screens';
import { ORDER_STATUS } from '../../../constants/main';
import { observer } from 'mobx-react';
import DeliveryTimer from './components/DeliveryTimer/DeliveryTimer';
import DeliveryStatusText from './components/DeliveryStatusText/DeliveryStatusText';
import ButtonComponent from '../../components/Controls/Button/Button';
import { driverSearchStore } from './store';
import { RouteComponentProps, StaticContext } from 'react-router';
import { IOrder } from '../../../types/orders';
import BackIcon from '../../components/Controls/BackIcon/BackIcon';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import ScreenWrapper from '../../containers/ScreenWrapper/ScreenWrapper';
import socket from 'src/services/socket';

interface IDriverSearch extends RouteComponentProps<{ id: string }, StaticContext> {}
const DriverSearchScreen: FC<IDriverSearch> = observer((props: IDriverSearch) => {
  const { id } = props.match.params;
  const history = useHistory();

  useEffect(() => {
    socket.init();
    driverSearchStore.init(id as IOrder['id']);
    driverSearchStore.setOpened(true);
    return () => {
      driverSearchStore.clear();
    };
  }, []);

  useEffect(() => {
    if (
      driverSearchStore?.order?.order_status_code &&
      [ORDER_STATUS.PENDING, ORDER_STATUS.ACCEPTED].includes(driverSearchStore?.order?.order_status_code)
    ) {
      return history.push(SCREENS.ORDER(driverSearchStore.order?.id || ''));
    }
  }, [driverSearchStore?.order?.order_status_code]);

  if (driverSearchStore.loading) {
    return (
      <Spinner size={50} className="button-spinner sms_pg d-flex justify-content-center full-width" color={'#000'} />
    );
  }

  return (
    <ScreenWrapper>
      <div className="container">
        <div className="d-flex flex-row align-items-center mb-2 mt-2 mb-lg-4 mt-lg-4">
          <Link
            to={{
              pathname: SCREENS.ORDERS(),
              search: `?language=${localeStore.language}`,
            }}
          >
            <BackIcon>
              <PageTitle title={localeStore.t('txt_track_my_order')} />
            </BackIcon>
          </Link>
        </div>
      </div>
      <div className="delivery-status completed-order d-flex flex-column justify-content-center align-items-center">
        <div className="container-status full-width d-flex flex-column justify-content-center align-items-center">
          <DeliveryTimer />
          <DeliveryStatusText />
        </div>
        {driverSearchStore.order?.order_status_code !== ORDER_STATUS.ON_HOLD && (
          <Link
            to={{
              pathname: SCREENS.MENU(),
              search: `?language=${localeStore.language}`,
            }}
            className="d-flex full-width  mb-2"
          >
            <ButtonComponent
              id={'show_order'}
              style={{ minWidth: 340 }}
              className="full-width"
              title={localeStore.t('show_menu')}
            />
          </Link>
        )}

        <Link
          to={{
            pathname: SCREENS.ORDER(driverSearchStore.order?.id || ''),
            search: `?language=${localeStore.language}`,
          }}
          className="d-flex full-width"
        >
          <ButtonComponent style={{ minWidth: 340 }} className="full-width background-light">
            <span className="color-dark">{localeStore.t('txt_order_details')}</span>
          </ButtonComponent>
        </Link>
      </div>
    </ScreenWrapper>
  );
});

export default DriverSearchScreen;
