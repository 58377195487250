import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';

interface Props {
  onClick: () => void;
}

const PreorderSaveButton: React.FC<Props> = ({ onClick }) => {
  return <ButtonComponent data-testid="preorder-save" onClick={onClick} title={localeStore.t('txt_save')} />;
};

export default PreorderSaveButton;
