import { IProductInsideCombo } from 'src/types/combo';

export const isSameComboItemsPlace = (
  itemOne: IProductInsideCombo,
  itemTwo?: IProductInsideCombo,
  params?: {
    sizeId: number;
    choiceId: number;
  }
): boolean => {
  if (itemTwo) return itemOne.choice_id === itemTwo.choice_id && itemOne.size_id === itemTwo.size_id;
  if (params) return itemOne.choice_id === params.choiceId && itemOne.size_id === params.sizeId;

  return false;
};
