import { action, computed, observable } from 'mobx';

class TimerHandler {
  @observable timer: NodeJS.Timeout | null = null;
  @observable inputSeconds: number = 0;
  @observable disabled: boolean = false;

  @action secondsInput(num: number) {
    if (!num) {
      return;
    }
    this.inputSeconds += num;
    this.countDown();
  }

  /**
   * Formatting inputSeconds (ex: 60sec to 01:00)
   * return string => MM:SS
   */
  @computed get formattedResult(): string {
    return ('0' + Math.floor(this.inputSeconds / 60)).slice(-2) + ':' + ('0' + (this.inputSeconds % 60)).slice(-2);
  }

  @action countDown() {
    this.disabled = true;
    clearInterval(this.timer as NodeJS.Timeout);
    this.timer = setInterval(() => {
      this.inputSeconds -= 1;
      if (this.inputSeconds === 0) {
        clearInterval(this.timer as NodeJS.Timeout);
        this.disabled = false;
      }
    }, 1000);
  }

  @action resetCountdown() {
    this.disabled = false;
    this.inputSeconds = 0;
    clearInterval(this.timer as NodeJS.Timeout);
  }
}

export default TimerHandler;
