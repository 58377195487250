import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { userStore } from '../../../../../mobx/userStore';
import { addressStore } from '../../../../../mobx/barnchStore/address';
import './style.scss';
import BranchesSubmitButton from '../../components/BranchesSubmitButton/BranchesSubmitButton';
import { DELIVERY_TYPES } from '../../../../../constants/deliveryTypes';
import { IBranch } from '../../../../../types/branch';
import AddressesList from './components/AddressesList/AddressesList';
import AddressFormDraft from '../../components/AddressFormDraft/AddressFormDraft';
import { ADDRESS_TYPES, VIEW_MODE } from '../../../../../types/address';
import branchesScreenStore from '../../branchesScreenStore';
import Skeleton from 'react-loading-skeleton';
interface IProps {
  branch?: IBranch | null;
  onChange: () => void;
}

const AddressSidebar: FC<IProps> = observer((props: IProps): JSX.Element => {
  useEffect(() => {
    addressStore.fetchAddresses();
  }, []);
  useEffect(() => {
    props.onChange();
  }, [branchesScreenStore.selectedAddressId]);
  return (
    <>
      <AddressFormDraft branch={props.branch || null} />

      <div
        className={`branches-sidebar delivery-wrap ${userStore.isLogin && addressStore.list ? 'edit' : ''} ${
          [VIEW_MODE.CREATE].includes(branchesScreenStore.viewMode) ? 'create-address' : ''
        } animation slide_in_right d-flex flex-column`}
      >
        <div className="branches-sidebar-scroll d-flex flex-grow-1  hide-scrollbar">
          <AddressesList />
        </div>
        <div className="d-flex flex-column px-3 py-3">
          {addressStore.loading ? (
            <Skeleton width={'100%'} height={60} className="mr-2" />
          ) : (
            <BranchesSubmitButton
              delivery_type={DELIVERY_TYPES.DELIVERY}
              loading={branchesScreenStore.loading}
              branch={props.branch || null}
            />
          )}
        </div>
      </div>
    </>
  );
});

export default AddressSidebar;
