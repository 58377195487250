import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { localeStore } from 'src/mobx/localesStore';
import client from 'src/services/client';
import Accordion from 'src/view/components/Accordion/Accordion';
import './style.scss';
import { getRestaurantName, setMetaTags } from 'src/utils/helpers';

@observer
export default class FaqScreen extends Component {
  state = {
    loading: false,
    options: [],
  };

  componentDidMount = async () => {
    setMetaTags({
      title: `${getRestaurantName()} - ${localeStore.t('txt_faq')}`,
      keywords: `${getRestaurantName()}, ${localeStore.t('txt_faq')}`,
      'og:title': `${getRestaurantName()} - ${localeStore.t('txt_faq')}`,
      description: `${getRestaurantName()} - ${localeStore.t('txt_faq')}`,
      'og:description': `${getRestaurantName()} - ${localeStore.t('txt_faq')}`,
    });

    try {
      this.setState(() => ({ loading: true }));
      const response = await client.get('/v3/info/faq');
      this.setState(() => ({
        loading: false,
        options: response.map((option: any) => ({ title: option.question, description: option.answer })),
      }));
    } catch (error: any) {
      this.setState(() => ({ loading: false }));
    }
  };

  render() {
    if (this.state.loading || !localeStore.timestamp) {
      return (
        <div className="d-flex flex-column full-width mt-4">
          <Skeleton width={'100%'} height={60} className={'mb-4 faq-skeleton'} />
          <Skeleton width={'100%'} height={60} className={'mb-4 faq-skeleton'} />
          <Skeleton width={'100%'} height={60} className={'mb-4 faq-skeleton'} />
          <Skeleton width={'100%'} height={60} className={'mb-4 faq-skeleton'} />
        </div>
      );
    }
    return (
      <div className="full-width mt-4">
        <Accordion options={this.state.options} />
      </div>
    );
  }
}
