import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { FC, ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { IProduct } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { CartProductDonation } from './components/CartProductDonation/CartProductDonation';
import { CartProductExclusions } from './containers/CartProductExclusions/CartProductExclusions';
import { CartProductFooter } from './containers/CartProductFooter/CartProductFooter';
import { CartProductHeader, editProductIconId } from './containers/CartProductHeader/CartProductHeader';
import { CartProductItems } from './containers/CartProductItems/CartProductItems';
import { CartProductModifiers } from './containers/CartProductModifiers/CartProductModifiers';
import './style.scss';

interface IProps {
  product: IProduct;
}

export const CartProduct: FC<IProps> = observer(({ product }: IProps): ReactElement => {
  const linkTo = useMemo(() => {
    return {
      pathname: cartStore.isSMS ? SCREENS.SMS_PRODUCT(product.id) : SCREENS.PRODUCT(product.id),
      search: `?language=${localeStore.language}`,
      state: { product: toJS(product), updateProduct: true },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartStore.isSMS, localeStore.language, product]);

  const onHandleProductClick = (event: any) => {
    if (event.target.classList?.contains('demo-icon') && event.target.id !== editProductIconId) {
      event.preventDefault();
    }
  };

  return (
    <div className="cart-product">
      <Link
        rel="tag"
        title={getTranslate(product.name)}
        hrefLang={localeStore.language}
        onClick={onHandleProductClick}
        to={linkTo}
      >
        <CartProductHeader product={product} />

        <CartProductDonation isDonation={Boolean(product.is_donation)} />

        <CartProductModifiers modifiers={product.modifiers} productQuantity={product.quantity} className="pxpx-3" />
        <CartProductExclusions exclusions={product.exclusions} className="pxpx-3" />
        <CartProductItems product={product} />
        <CartProductFooter product={product} />
      </Link>
    </div>
  );
});
