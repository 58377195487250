import React from 'react';
import './style.scss';
import { getStatusColor } from '../../../utils/helpers';
import { ORDER_STATUS } from '../../../constants/main';
import { localeStore } from '../../../mobx/localesStore';

interface ICardProps {
  className?: string;
  children: React.ReactNode;
  [x: string]: any; // rest props
}

interface ICardStatusProps {
  status: string;
  statusCode: string;
  className?: string;
  [x: string]: any; // rest props
}

export const Card: React.FC<ICardProps> = ({ className, children, ...restProps }) => (
  <div className={`custom-card ${className ? className : ''}`} {...restProps}>
    {children}
  </div>
);

export const CardRow: React.FC<ICardProps> = ({ className, children, ...restProps }) => (
  <div className={`card-row ${className ? className : ''}`} {...restProps}>
    {children}
  </div>
);

export const CardKey: React.FC<ICardProps> = ({ className, children, ...restProps }) => (
  <div className={`card-key ${className ? className : ''}`} {...restProps}>
    {children}
  </div>
);

export const CardStatus: React.FC<ICardStatusProps> = ({ status, statusCode, className }) => (
  <div className={`card-status ${className ? className : ''}`}>
    <div className="card-status-icon mr-2" style={{ backgroundColor: getStatusColor(statusCode) }} />
    <div className="card-status-text">
      {statusCode === ORDER_STATUS.ON_HOLD ? localeStore.t('waiting_status') : status}
    </div>
  </div>
);
