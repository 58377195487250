import moment, { Moment } from 'moment';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { TTranslate } from 'src/types/main';
import { ITimeSlotHour } from 'src/types/time';
import { getRadix } from './helpers';

/**
 * Function for price rounding
 *
 * @param {(number | string)} price - price to be rounded up
 * @param {number} [divided=100] - how much to round the price in currency
 * @return {number | string} {number | string}
 */
export function roundPrice(price: number | string, divided: number = 100): number | string {
  /**
   * Bit depth of the number to which the price is rounded
   *  @type {*}
   */
  const numberOfDigits = getRadix(divided);

  if (typeof price === 'number' && !isNaN(price)) return Number(price.toFixed(numberOfDigits));

  if (typeof price === 'string') {
    const _price = Number(price);
    if (typeof _price === 'number' && !isNaN(_price)) return Number(_price.toFixed(numberOfDigits));
  }

  if (!price) return 0;

  return price;
}

/**
 * Formats the cost of the product depending on the currency and currency position
 *
 * Special characters are used for correct display currency
 *
 * @param {number} price - price number
 * @param {(string | undefined)} [currency= branchStore.currency] - currecy of price
 * @param {string} [position= branchStore.currency_position] - position of currency
 * @param {string} [devided= branchStore.currency_divided] - how much to round the price in currency
 * @return {string} - formated price
 */
export const formatPrice = (
  price: number | string,
  currency: string | undefined = branchStore.currency,
  position: string = branchStore.currency_position,
  divided: number = branchStore.currency_divided
) => {
  if (position === 'l') {
    return `\u202a${getTranslate(currency)} \u202a${roundPrice(price, divided)}\u202c`;
  }
  return `\u202A${roundPrice(price, divided)} \u202a${getTranslate(currency)}\u202c`;
};

export const formatUnit = (translate: string, weight: number): string | null => {
  if (!translate || translate === '-') return null;
  return `${weight} ${localeStore.t(translate)}`;
};

export const formatImagePath = (name: string) => `${process.env.REACT_APP_CLOUD_FRONT}/media/images/${name}`;

export const arrayMerge = <T>(main: T[], incoming: T[], field?: string): T[] => {
  const compare = (a: any, b: any) => (field ? a[field] === b[field] : a === b);
  return incoming.reduce((_main, i: T) => {
    const idx = _main.findIndex((_i) => compare(_i, i));
    if (~idx) {
      _main[idx] = i;
    } else {
      _main.push(i);
    }
    return _main;
  }, main);
};

export const getTranslate = (translate?: TTranslate | string) => {
  if (!translate) return '';
  if (typeof translate === 'string') return translate;
  return translate ? translate[localeStore.language] : '';
};

export const getSocketHost = (): string => {
  if (!appStore.settings?.socket) return '';
  let host = decodeURIComponent(appStore.settings.socket);
  if (!host.includes('https:')) host = `https://${host}`;
  return host;
};

export const getHostName = (origin: string) => origin.split(/https:\/\/|http:\/\/|:|\./)[1];

export const formatTime = (
  time: string | Moment,
  parser: string | undefined = 'hh:mm A',
  format: string | undefined = 'hh:mm'
) => {
  // TOFIX AM/PM
  const m = moment(time, parser, 'en');
  const AMPM = m.format('A');
  return `${m.format(format)} ${['AM', 'PM'].includes(AMPM) ? localeStore.t(AMPM) : AMPM}`;
};

/**
 * Format hours from {@link TimeController} to times
 *
 * @param {ITimeSlotHour[]} hours
 * @return {*}
 */
export const formatHoursToTimes = (hours: ITimeSlotHour[]) => {
  return hours.flatMap((hour) => {
    return hour.label === 'Now'
      ? [{ label: hour.label, moment: hour.moment, index: hour.index }]
      : hour.minutes.map((minute) => {
          return { ...minute, label: formatTime(minute.moment.format('hh:mm A')) };
        });
  });
};

export const formatPreorderDate = (time: Moment) => {
  const now = moment();

  if (time.isAfter(now, 'year')) {
    return time.format("MMM.DD 'YY");
  } else if (time.isSame(now, 'date')) {
    return localeStore.t('txt_today');
  } else if (time.isSame(now.clone().add(1, 'day'), 'date')) {
    return localeStore.t('txt_tomorrow');
  } else if (time.isBefore(now.clone().add(7, 'day'), 'date')) {
    return time.format('dddd');
  } else if (time.isSameOrAfter(now.clone().add(7, 'day'), 'date')) {
    return time.format('dd, MMM.D');
  } else {
    return time.format('ddd, DD MMM');
  }
};
