import * as React from 'react';
import { localeStore } from 'src/mobx/localesStore';
import Switcher from 'src/view/components/Controls/Switcher/Switcher';

class LanguageSwitcher extends React.Component {
  changeLanguage = async () => {
    if (localeStore.language === 'en') {
      localeStore.setLocale('ar');
    } else {
      localeStore.setLocale('en');
    }
  };

  public render() {
    return (
      <Switcher
        onChange={this.changeLanguage}
        firstButtonText={localeStore.t('txt_english')}
        firstButtonId={'english_lang_switcher'}
        secondButtonId={'arabic_lang_switcher'}
        secondButtonText={localeStore.t('txt_arabic')}
        active={localeStore.language === 'ar'}
      />
    );
  }
}

export default LanguageSwitcher;
