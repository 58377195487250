import React from 'react';
import { ItemData } from './Select';

type Props = {
  item: ItemData;
  onClick: (data: ItemData) => void;
};

const Option = ({ item, onClick }: Props) => (
  <div className={'select-menu__option'} key={item.key} onClick={() => onClick(item)}>
    {item.label}
  </div>
);

export default Option;
