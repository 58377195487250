import React, { ChangeEvent, Component, FormEvent } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import './style.scss';
import { observer } from 'mobx-react';
import { cartStore } from '../../../../mobx/cartStore';
import { appStore } from 'src/mobx/appStore';
interface Props {
  onSelect: (promocode: string) => void;
  onClear: () => void;
  value?: string;
  promocode?: string;
  disabled?: boolean;
}
interface State {
  error: string;
  promocode: string;
}
@observer
export default class Promocode extends Component<Props, State> {
  state = {
    error: '',
    promocode: this.props.value || '',
  };

  submit = () => {
    if (!this.props.promocode && !this.state.promocode.length) {
      this.setState({ error: localeStore.t('promocode_invalid') });
      return;
    }
    try {
      this.props.onSelect(this.state.promocode);
    } catch (error: any) {
      console.error({ error });
    }
  };

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ promocode: event.target.value, error: '' });
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.props.promocode) {
      this.setState({ promocode: '' }, this.submit);
    } else {
      this.submit();
    }
  };

  render() {
    const { error, promocode } = this.state;
    return (
      <form onSubmit={this.onFormSubmit} className="d-flex flex-column full-width mb-0 mb-lg-4">
        <div className={`promocode d-flex flex-row ${error ? 'promocode-error' : ''}`}>
          <input
            disabled={this.props.disabled}
            value={promocode}
            onChange={this.onChange}
            className={`promocode-input color-dark text-size-14 family-regular`}
            id={'promocode-input'}
            placeholder={localeStore.t('promo_code')}
          />
          <button
            disabled={this.props.disabled}
            type="submit"
            className="promocode-button color-dark text-size-12  family-regular"
          >
            {this.props.promocode ? localeStore.t('txt_clear') : localeStore.t('apply')}
          </button>
        </div>
        {this.state.error ? (
          <p className="promocode-error color-danger family-regular mt-2 text-size-14">{error}</p>
        ) : null}
        {/*If consumer has cashback for the order, text cashback_with_promo should be displayed.*/}
        {cartStore.calculator.promocode && appStore.settings?.cashback ? (
          <p className="color-gray text-size-14 mt-2 text-md-size-14">{localeStore.t('cashback_with_promo')}</p>
        ) : null}
      </form>
    );
  }
}
