import { IProductInsideCombo } from 'src/types/combo';
import { isSameComboItems } from '../isSameComboItems/isSameComboItems';

export const isMealModifierWithError = (item: IProductInsideCombo, modifierId: number, errors: string[]): boolean => {
  return errors.some((id) => {
    /**
     * @see getMealItemKey
     * combo item params id's list + modifier id
     */
    const idx: number[] = id.split('-').map(Number);

    if (idx.length > 4) {
      const _isSameComboItems = isSameComboItems(item, undefined, {
        id: idx[0],
        sizeId: idx[1],
        choiceId: idx[3],
      });
      const _isSameModifier = modifierId === idx[4];

      return _isSameComboItems && _isSameModifier;
    }

    return false;
  });
};
