import React, { Component, FormEvent, SyntheticEvent } from 'react';

import OtpInput from 'react-otp-input';
import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import CountDown from 'src/view/components/CountDown/CountDown';
import { IOTP } from '../LoginContainer';
import PhoneInput from 'react-phone-input-2';

interface Props {
  loading: boolean;
  number: string;
  otp?: IOTP;
  confirm: (otp: string) => void;
  submit: (number: string) => void;
  error: string;
}

export default class ConfirmComponent extends Component<Props> {
  state = {
    timer: true,
    isCodeFilled: false,
    code: '',
  };

  onTimerFinished = () => this.setState({ timer: false });

  resend = () => {
    this.props.submit(this.props.number);
    this.setState({ timer: true });
  };

  confirm = (event: FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLInputElement> | SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.state.code.length !== 4) return;
    this.props.confirm(this.state.code);
  };

  onChange = (code: string) => {
    const isCodeFilled = code.length === 4;
    this.setState({ code, isCodeFilled });
  };

  render() {
    return (
      <form onSubmit={this.confirm} className="confirm d-flex flex-column ">
        <h2 className="login-title family-semibold color-text mb-4 text-center">
          {localeStore.t('txt_enter_your_verificaiton_code')}
        </h2>
        <div className="login-content">
          <OtpInput
            shouldAutoFocus
            containerStyle={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 10,
            }}
            isInputNum
            className="login-otp"
            value={this.state.code}
            onChange={this.onChange}
            numInputs={4}
          />
          {this.props.error ? (
            <p className="login-error color-danger family-regular mb-4 text-center">{this.props.error}</p>
          ) : null}
        </div>
        <ButtonComponent
          disabled={!this.state.isCodeFilled}
          loading={this.props.loading}
          className="mb-3"
          type={'submit'}
          id={'verify'}
          onClick={this.confirm}
          title={localeStore.t('txt_verify')}
        />

        {this.state.timer ? (
          <div className="d-flex color-gray justify-content-center family-regular text-nowrap text-center">
            (
            <CountDown onFinished={this.onTimerFinished} autorun time={this.props.otp?.otp_resend_after || 30} />
            {`\u202c`} {localeStore.t('Sec')?.toLocaleLowerCase()}){`\u202a`} {localeStore.t('txt_resend_code')}
          </div>
        ) : (
          <div
            className="d-flex justify-content-center color-primary family-regular  text-center text-pointer"
            onClick={this.resend}
          >
            {localeStore.t('txt_resend_code')}
          </div>
        )}
      </form>
    );
  }
}
