import { observer } from 'mobx-react';
import * as React from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import IconButton from 'src/view/components/Controls/IconButton/IconButton';
import LogoutContainer from 'src/view/containers/LogoutContainer/LogoutContainer';

export interface IHamburgerLogoutItemProps {
  closeMenu?: () => void;
}

@observer
class HamburgerLogoutItem extends React.Component<IHamburgerLogoutItemProps> {
  public render() {
    return (
      <DropDownModal
        content={({ closeModal }) => <LogoutContainer closeModal={closeModal} />}
        selfClose={false}
        position={'right'}
        asPopupInMobile
        bottomSheet
      >
        <div className="hamburger-menu-item  full-width  th-bg-background_hover-secondary">
          <IconButton iconClassName={'th-background-secondary'} name={ICONS.LOGOUT} />
          <p className="th-color-primary">{localeStore.t('menu_logout')}</p>
        </div>
      </DropDownModal>
    );
  }
}

export default HamburgerLogoutItem;
