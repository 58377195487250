import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const DeliveryAddressSkeleton: FC = (): JSX.Element => {
  return (
    <div className={`branches-sidebar-branch branches-sidebar-skeleton`}>
      <div className="d-flex flex-row align-items-center justify-content-start mt-4">
        <Skeleton width={34} height={34} circle className="mr-3" />
        <Skeleton width={257} height={27} className="family-regular branches-info color-gray" />
        <Skeleton width={24} height={24} className="branches-info ml-3" />
      </div>
    </div>
  );
};
export default DeliveryAddressSkeleton;
