import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { cartStore } from 'src/mobx/cartStore';
import CheckoutModalCurbside from './components/CheckoutModalCurbside/CheckoutModalCurbside';
import CheckoutModalError from './components/CheckoutModalError/CheckoutModalError';
import CheckoutModalLocation from './components/CheckoutModalLocation/CheckoutModalLocation';
import CheckoutModalLogin from './components/CheckoutModalLogin/CheckoutModalLogin';
import CheckoutModalPaymentCard from './components/CheckoutModalPaymentCard/CheckoutModalPaymentCard';
import CheckoutModalPaymentType from './components/CheckoutModalPaymentType/CheckoutModalPaymentType';
import CheckoutModalPromocode from './components/CheckoutModalPromocode/CheckoutModalPromocode';
import CheckoutModalSomeone from './components/CheckoutModalSomeone/CheckoutModalSomeone';
import CheckoutModalTime from './components/CheckoutModalTime/CheckoutModalTime';
import CheckoutModalsErrorBranch from './components/CheckoutModalErrorBranch/CheckoutModalErrorBranch';
import CheckoutModalPreorder from './components/CheckoutModalPreorder/CheckoutModalPreorder';
import CheckoutModalConfirmPickUp from './components/CheckoutModalConfirmPickUp/CheckoutModalConfirmPickUp';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModals extends Component<Props> {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <CheckoutModalLogin />

        <CheckoutModalConfirmPickUp />

        {cartStore.checkout.is_order_for_someone_else ? <CheckoutModalLocation /> : null}

        <CheckoutModalError />

        <CheckoutModalPromocode />

        <CheckoutModalTime />

        <CheckoutModalPaymentType />

        <CheckoutModalCurbside />

        <CheckoutModalSomeone />

        <CheckoutModalPaymentCard />

        <CheckoutModalsErrorBranch />

        <CheckoutModalPreorder />
      </div>
    );
  }
}

export default withRouter(CheckoutModals);
