import { FC, ReactElement, memo, useMemo } from 'react';
import { IOrderProduct } from 'src/types/orders';
import { PRODUCT_TYPE } from 'src/types/products';
import { productUtils } from 'src/utils/productUtils';
import { OrderProductItem } from '../../components/OrderProductItem/OrderProductItem';
import './style.scss';

interface IProps {
  product: IOrderProduct;
}

export const OrderProductItems: FC<IProps> = memo(({ product }: IProps): ReactElement | null => {
  const isMeal = useMemo((): boolean => product.type === PRODUCT_TYPE.MEAL_COMBO, [product.type]);

  if (!product.items?.length) return null;

  return (
    <div className="order-product-items">
      {product.items.map((item, index) => (
        <OrderProductItem
          key={productUtils.getMealItemKey(item, index)}
          item={item}
          isMeal={isMeal}
          productQuantity={product.quantity}
        />
      ))}
    </div>
  );
});
