import { observer } from 'mobx-react-lite';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import LoadingCircle from '../../../LoadingCircle/LoadingCircle';

function CheckoutTotalInfoTotal() {
  return (
    <div className="checkout-total-info-container">
      <p className={'color-dark family-semibold text-size-14'}>{localeStore.t('total_amount_excl_vat')}</p>
      <LoadingCircle>
        <p id="checkout_subtotal" className={'color-dark family-semibold text-size-14'}>
          {formatPrice(cartStore.calculator.subtotal)}
        </p>
      </LoadingCircle>
    </div>
  );
}
export default observer(CheckoutTotalInfoTotal);
