import { Component } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductModifierOption } from 'src/types/products';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import Quantity from '../../Quantity/Quantity';

interface Props {
  option: IProductModifierOption;
  uniq: boolean;
  readOnly?: boolean;
  onPressOption: (option: IProductModifierOption) => void;
  onChangeOptionQuantity: (id: IProductModifierOption['id'], quantity: number) => void;
  leftToMaximum: number | null;
  id?: string;
}

export default class Option extends Component<Props> {
  onQuantityChange = (quantity: number) => {
    this.props.onChangeOptionQuantity(this.props.option.id, quantity);
  };

  onPressOption = () => {
    const { option, leftToMaximum, readOnly } = this.props;
    if (readOnly) return;
    if (leftToMaximum === null || leftToMaximum > 0 || option.selected) this.props.onPressOption(this.props.option);
  };

  get units() {
    return productUtils.getResponseProductModifierOptionUnits(this.props.option);
  }

  render() {
    const { option, uniq, leftToMaximum, readOnly } = this.props;
    const price = option.selected ? option.price * option.quantity : option.price;
    const showQuantity = option.selected && !uniq && option.maximum > 1 && !readOnly;

    return (
      <div className="option">
        <div
          key={option.id}
          onClick={this.onPressOption}
          className={`multi-option ${readOnly ? 'readonly' : ''} ${option.selected ? 'active' : ''} `}
        >
          <div className="multi-option-content">
            {!readOnly ? (
              <span id={this.props.id} className="multi-option-square background_pseudo-primary mr-3"></span>
            ) : null}
            <p className="multi-option-name mr-3">
              {getTranslate(option.name)}
              {readOnly && option.selected && option.quantity ? (
                <bdo className="multi-option-name-quantity" dir={localeStore.direction}>
                  {`x${option.quantity}`}
                </bdo>
              ) : null}
            </p>
            {/* <p className="multi-option-unit">{formatUnit(getTranslate(option.unit), option.weight)}</p> */}
          </div>

          <div className="multi-option-content ml-3">
            {!option.taxable && option.price > 0 ? (
              <p className="multi-option-vat mr-3">{localeStore.t('non_taxable')}</p>
            ) : null}
            <p className="multi-option-price text-nowrap">{`${price > 0 ? '+ ' : ''}${formatPrice(price)}`}</p>
          </div>
        </div>
        <div className="multi-option-units ml-3">
          {this.units?.map((unit) => (unit ? <span className="mr-2">{unit}</span> : null))}
        </div>
        {showQuantity ? (
          <Quantity
            decreaseId={`decrease_${option.id}`}
            increaseId={`increase_${option.id}`}
            maximum={option.maximum}
            minimum={option.minimum}
            plusButtonDisabled={leftToMaximum !== null && leftToMaximum <= 0}
            value={option.quantity}
            onChange={this.onQuantityChange}
            small
            className="pxmt-2"
          />
        ) : null}
      </div>
    );
  }
}
