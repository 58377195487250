import { FC, ReactElement, useCallback, useState } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { IProductExclusion, IProductModifier } from 'src/types/products';
import { productUtils } from 'src/utils/productUtils';
import { validateMultiChoiseModifier, validateSingleChoiseModifier } from 'src/utils/validator';
import CustomModalComponent from 'src/view/components/Controls/Modal/Modal';
import ImageSlider from 'src/view/components/ImageSlider/ImageSlider';
import ScrollableBlock from 'src/view/containers/ScrollableBlock/ScrollableBlock';
import { Description } from './components/Description/Description';
import { NamePrice } from './components/NamePrice/NamePrice';
import { Selectors } from './components/Selectors/Selectors';
import { Submit } from './components/Submit/Submit';
import './style.scss';

interface IProps {
  item: IProductInsideCombo;
  unavailableMessage: string | undefined;
  readOnly?: boolean;
  onSelectItem: (item: IProductInsideCombo) => void;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
  onCloseModal: () => void;
}

export const MealComboGroupMobileItemModal: FC<IProps> = (props: IProps): ReactElement => {
  const { item, unavailableMessage } = props;

  const [modifiersErrors, setModifiersErrors] = useState<string[]>([]);
  const [needScrollToError, setNeedScrollToError] = useState<boolean>(false);

  const onValidateModifiers = (): boolean => {
    const idx: string[] = [];

    item.modifiers?.forEach((modifier) => {
      const valid: boolean = modifier.multi_choice
        ? validateMultiChoiseModifier(modifier)
        : validateSingleChoiseModifier(modifier);

      if (!valid) idx.push(`${productUtils.getMealItemKey(item)}-${modifier.id}`);
    });

    setModifiersErrors(idx);

    return !idx.length;
  };

  const onSubmit = useCallback(() => {
    const isValid: boolean = onValidateModifiers();

    if (isValid) {
      props.onSelectItem(item);
      props.onCloseModal();
    } else {
      setNeedScrollToError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <CustomModalComponent
      isOpen={true}
      onRequestClose={props.onCloseModal}
      className="meal-combo-group-mobile-item-modal__modal"
    >
      <article className="meal-combo-group-mobile-item-modal__wrapper fadein">
        <ScrollableBlock scrollClassName="full-height">
          <div className={`d-flex pxpb-4 ${unavailableMessage ? 'opacity-50' : ''}`}>
            <ImageSlider images={item.images || [item.image]} />
          </div>

          {unavailableMessage ? (
            <p className="meal-combo-group-mobile-item-modal__unavailable-msg letter-spacing-5">
              {localeStore.t(unavailableMessage)}
            </p>
          ) : null}

          <div className="d-flex flex-column pxpx-3">
            <NamePrice {...item} />
            <Description {...item} />
            <Selectors
              item={item}
              modifiersErrors={modifiersErrors}
              needScrollToError={needScrollToError}
              readOnly={props.readOnly}
              onModifierChange={props.onModifierChange}
              onExclusionsChange={props.onExclusionsChange}
              setNeedScrollToError={setNeedScrollToError}
            />
          </div>

          <Submit onSubmit={onSubmit} disabled={Boolean(unavailableMessage)} />
        </ScrollableBlock>

        <div className="meal-combo-group-mobile-item-modal__close" onClick={props.onCloseModal} />
      </article>
    </CustomModalComponent>
  );
};
