import { appStore } from 'src/mobx/appStore';

import { requests } from '../requests';
import { IApplePayData } from '../types/orders';
import { cartStore } from '../mobx/cartStore';

// tslint:disable

export const createApplePaySession = (request: IApplePayData, onSuccess: (token: object) => void) => {
  try {
    /* tslint:disable: no-console */
    const { ApplePaySession } = window;
    const session = new ApplePaySession(3, request);
    session.onvalidatemerchant = async (event: any) => {
      try {
        console.log('onvalidatemerchant', event);
        const response = await requests.validateApplePaySession(event.validationURL);
        console.log({ response });

        if (!response.output) throw new Error('Wrong session data');

        session.completeMerchantValidation(JSON.parse(response.output));
      } catch (err) {
        console.log('onvalidatemerchant error and aborting', err);
        session.abort();
      }
    };

    session.onpaymentmethodselected = (event: any) => {
      // Define ApplePayPaymentMethodUpdate based on the selected payment method.
      // No updates or errors are needed, pass an empty object.
      const update = {
        newLineItems: request.lineItems,
        newTotal: request.total || 0,
      };
      session.completePaymentMethodSelection(update);
    };

    session.onshippingmethodselected = (event: any) => {
      console.log('onshippingmethodselected', event);

      // Define ApplePayShippingMethodUpdate based on the selected shipping method.
      // No updates or errors are needed, pass an empty object.
      const update = {};
      session.completeShippingMethodSelection(update);
    };

    session.onshippingcontactselected = (event: any) => {
      console.log('onshippingcontactselected', event);

      // Define ApplePayShippingContactUpdate based on the selected shipping contact.
      const update = {};
      session.completeShippingContactSelection(update);
    };

    session.onpaymentauthorized = (event: any) => {
      console.log('onpaymentauthorized', event);

      // Define ApplePayPaymentAuthorizationResult
      const result = {
        status: ApplePaySession.STATUS_SUCCESS,
      };
      session.completePayment(result);
      onSuccess(event.payment?.token);
    };

    /** Payment cancelled by WebKit */
    session.oncancel = (event: any) => {
      console.log('oncancel', event);
    };

    session.begin();
  } catch (error: any) {
    console.log('createApplePaySession error:', error);
    throw error;
  }
};
export const checkApplePayAvailable = async () => {
  try {
    const { ApplePaySession } = window;
    /** If @see applepay_enabled is exist and it's disabled, applePay is unavailable */
    if (typeof appStore.settings?.applepay_enabled !== undefined && !appStore.settings?.applepay_enabled) return false;

    if (ApplePaySession) {
      const getMerchantID = appStore.settings?.merchant_id;
      const canMakePayments = await ApplePaySession.canMakePayments(getMerchantID);
      return canMakePayments && appStore.settings?.merchant_id;
    }
    return false;
  } catch (err) {
    console.log('checkApplePay error', err);
    return false;
  }
};

export const createAppleSession = (request: IApplePayData): Promise<any> =>
  new Promise((res, rej) => {
    try {
      const { ApplePaySession } = window;
      console.log('request', request);
      const session = new ApplePaySession(3, request);
      console.log('session', session);
      session.onvalidatemerchant = async (event: any) => {
        try {
          console.log('onvalidatemerchant', event);
          const response = await requests.validateApplePaySession(event.validationURL);
          console.log({ response });

          if (!response.output) throw new Error('Wrong session data');

          session.completeMerchantValidation(JSON.parse(response.output));
        } catch (err) {
          console.log('onvalidatemerchant error and aborting', err);
          session.abort();
        }
      };

      session.onpaymentmethodselected = (event: any) => {
        console.log('onpaymentmethodselected', event);
        // Define ApplePayPaymentMethodUpdate based on the selected payment method.
        // No updates or errors are needed, pass an empty object.
        const update = {
          newLineItems: request.lineItems,
          newTotal: request.total || 0,
        };
        session.completePaymentMethodSelection(update);
      };

      session.onshippingmethodselected = (event: any) => {
        console.log('onshippingmethodselected', event);

        // Define ApplePayShippingMethodUpdate based on the selected shipping method.
        // No updates or errors are needed, pass an empty object.
        const update = {};
        session.completeShippingMethodSelection(update);
      };

      session.onshippingcontactselected = (event: any) => {
        console.log('onshippingcontactselected', event);

        // Define ApplePayShippingContactUpdate based on the selected shipping contact.
        const update = {};
        session.completeShippingContactSelection(update);
      };

      session.onpaymentauthorized = (event: any) => {
        console.log('onpaymentauthorized', event);

        // Define ApplePayPaymentAuthorizationResult
        const result = {
          status: ApplePaySession.STATUS_SUCCESS,
        };
        session.completePayment(result);
        res(event.payment?.token);
      };

      /** Payment cancelled by WebKit */
      session.oncancel = (event: any) => {
        console.log('oncancel', event);
        res(null);
      };

      session.begin();
    } catch (error: any) {
      console.log('createApplePaySession error:', error);
      throw error;
    }
  });
