import React, { Component } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductExclusion } from 'src/types/products';
import './style.scss';
import { getTranslate } from '../../../../utils/formatter';

interface Props {
  exclusions: IProductExclusion[];
  className?: string;
  readOnly?: boolean;
  onChange: (exclusions: IProductExclusion[]) => void;
}
interface State {
  error: boolean;
}

export default class Exclusions extends Component<Props, State> {
  state = {
    error: false,
  };

  onPressOption = (option: IProductExclusion) => {
    if (this.props.readOnly) return;

    const exclusions = this.props.exclusions.map((exclusion) => {
      if (exclusion.id === option.id) {
        return {
          ...exclusion,
          selected: !option.selected,
        };
      }
      return exclusion;
    });
    this.props.onChange(exclusions);
  };

  renderOption = (option: IProductExclusion) => (
    <div
      onClick={() => this.onPressOption(option)}
      key={option.id}
      className={`exclusions-option ${option.selected ? 'active' : this.props.readOnly ? 'readonly' : ''} `}
    >
      <div className="exclusions-option-content">
        {!this.props.readOnly ? <span className="exclusions-option-square background_pseudo-primary"></span> : null}
        <p className="exclusions-option-name">{getTranslate(option.name)}</p>
      </div>
    </div>
  );

  render() {
    const { exclusions } = this.props;

    return (
      <div className={`exclusions ${this.props.className || ''}`}>
        <h3 className="exclusions-title">{localeStore.t('Exclusions')}</h3>
        {exclusions.map(this.renderOption)}
      </div>
    );
  }
}
