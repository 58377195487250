import { IProductInsideCombo } from 'src/types/combo';
import { IOrderProductComboItem } from 'src/types/orders';

export const getMealItemKey = (item: IProductInsideCombo | IOrderProductComboItem, index?: number): string => {
  if (!item) return 'key-' + String(index);

  return (
    String(item.id) +
    '-' +
    String(item.size_id || 0) +
    '-' +
    String(item.group_id || 0) +
    '-' +
    String(item.choice_id || 0)
  );
};
