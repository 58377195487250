import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { localeStore } from '../../../../../mobx/localesStore';
import Icon from '../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../constants/icons';
import SearchResult from '../SearchAddress/SearchAddress';
import { logError } from '../../../../../utils/helpers';
import { IMAGES } from '../../../../../constants/images';
import Spinner from '../../../../components/Controls/Spinner/Spinner';
import { branchStore } from '../../../../../mobx/barnchStore';

interface IDataCoordinates {
  lat: number;
  lng: number;
}
interface IProps {
  coordinates: (data: IDataCoordinates) => void;
}
const SearchForm: FC<IProps> = (props: IProps) => {
  const input = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const {
    ready,
    value,
    suggestions: { status, data, loading },
    clearSuggestions,
    setValue,
  } = usePlacesAutocomplete();

  const handleInput = useCallback((e: any) => {
    setSearchValue(e.target.value);
  }, []);

  useEffect(() => {
    if (searchValue.length >= 2) {
      setValue(searchValue);
    } else {
      setValue('');
    }
  }, [searchValue]);

  useEffect(() => {
    return () => {
      setValue('', false);
      clearSuggestions();
    };
  }, []);

  const handleSelect = useCallback(async (address: any) => {
    /*
     * Clear all suggestions
     * */
    clearSuggestions();
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      props.coordinates({ lat, lng });
    } catch (error) {
      logError('Error: ' + error);
    }
  }, []);

  const searchResult = useMemo(() => {
    if (loading) {
      return <Spinner color={'#ccc'} size={50} className={'search-spinner'} />;
    }

    if (status === 'OK' && data.length) {
      return data.map(({ place_id, structured_formatting, description }) => (
        <SearchResult
          key={place_id}
          value={structured_formatting}
          description={description}
          selectAddress={handleSelect}
        />
      ));
    }

    if (status === 'ZERO_RESULTS') {
      return (
        <div className="no_results-search">
          <img
            width="210"
            height="72"
            src={IMAGES.EMPTY_SEARCH}
            className="no_results-search-img"
            title="App Store"
            alt="App Store"
          />
          <p className="no_results-search-text">Sorry, no results found</p>
        </div>
      );
    }
    return null;
  }, [status, data, loading]);

  return (
    <div className="search-screen background-white d-flex flex-grow-1">
      <div className="title-search-box family-bold">{localeStore.t('txt_search')}</div>
      <div className={`search-screen-wrapper d-flex`}>
        <input
          ref={input}
          value={searchValue}
          autoFocus
          onChange={handleInput}
          className="search-screen-input color-text family-semibold color-dark"
          placeholder={localeStore.t('Address')}
        />
        <Icon name={ICONS.SEARCH} className="search-screen-icon color-gray" />
      </div>
      <div className="search-result">{searchResult}</div>
    </div>
  );
};
export default memo(SearchForm);
