import { observer } from 'mobx-react';
import * as React from 'react';
import { PAYMENT_TYPES } from 'src/constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import Promocode from 'src/view/components/Controls/Promocode/Promocode';

@observer
export default class CheckoutPromocode extends React.Component {
  onSelectPromocode = (promocode: string) => {
    cartStore.selectPromocode(promocode);
  };

  onClearPromocode = () => {
    cartStore.clearPromocode();
  };

  public render() {
    if (cartStore.checkout.payment_type === PAYMENT_TYPES.POINTS || cartStore.checkout.use_wallet) return null;

    return (
      <div className="d-flex flex-column full-width  mb-0 mb-lg-4">
        <p className="d-none d-lg-flex text-size-18 family-bold color-dark mb-4">{localeStore.t('promo_code')}</p>

        <Promocode
          disabled={Boolean(cartStore.checkout.use_wallet)}
          key={cartStore.calculator.promocode}
          onClear={this.onClearPromocode}
          promocode={cartStore.calculator.promocode}
          value={cartStore.checkout.promocode}
          onSelect={this.onSelectPromocode}
        />
      </div>
    );
  }
}
