import { observer } from 'mobx-react';
import * as React from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import IconButton from 'src/view/components/Controls/IconButton/IconButton';
import LoginContainer from 'src/view/containers/LoginContainer/LoginContainer';

export interface HamburgerWaitListItemState {
  active: boolean;
}

@observer
class HamburgerWaitListItem extends React.Component<any, HamburgerWaitListItemState> {
  // mw - WainList form
  state = {
    active: false,
  };
  onOpenModal = () => {
    this.props.updateProps({ ml: false, mw: true });
    this.setState((state) => ({ active: true }));
  };
  onCloseModal = () => {
    this.props.updateProps({ ml: false, mw: false });
    this.setState((state) => ({ active: false }));
  };

  public render() {
    return (
      <DropDownModal
        content={({ closeModal }) => <LoginContainer closeModal={closeModal} />}
        listClassName="hamburger-login-modal"
        selfClose={false}
        position={'right'}
        asPopupInMobile
        bottomSheet
        visible={this.props.hamburgerState.mw}
        onOpen={this.onOpenModal}
        onClose={this.onCloseModal}
      >
        <div className="hamburger-menu-item full-width th-bg-background_hover-secondary">
          {this.props.icon ? (
            <IconButton iconClassName={'th-background-secondary th-selected_hover-primary'} name={this.props.icon} />
          ) : null}
          <p
            onClick={this.props.closeModal}
            className={`th-color-primary d-flex flex-grow-1 ${this.props.icon ? 'ml-1' : 'ml-3'}`}
          >
            {localeStore.t('txt_waitlist')}
          </p>
        </div>
      </DropDownModal>
    );
  }
}

export default HamburgerWaitListItem;
