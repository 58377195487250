import { Component } from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { ICONS } from 'src/constants/icons';
import { PAGE_FROM, WINDOW_SIZES } from 'src/constants/main';
import { IBranch } from 'src/types/branch';
import Icon from 'src/view/components/Controls/Icon/Icon';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import BranchesSubmitButton from '../../../components/BranchesSubmitButton/BranchesSubmitButton';
import '../style.scss';
import BranchDeliveryInfo from './components/BranchDeliveryInfo/BranchDeliveryInfo';

interface Props {
  lat?: number;
  lng?: number;
  loading: boolean;
  branch: IBranch | null;
  delivery_type: string;
  permissionsAllowed: boolean;
  fromPage?: string;
  branchNotCoveredAndGeoDisabled?: boolean;
}

export default class BranchDeliveryPopup extends Component<Props> {
  get branchModalClassName(): string {
    return `branches-popup ${this.props.fromPage === PAGE_FROM.SMS && this.props.branch ? 'branch-popap-sms' : null}`;
  }

  render() {
    const { branch, loading, fromPage, delivery_type, branchNotCoveredAndGeoDisabled } = this.props;
    if (this.props.delivery_type === DELIVERY_TYPES.PICKUP) return null;
    return (
      <>
        <div className="background-primary branches-current">
          <Icon className="branches-marker color-danger" name={ICONS.MARKER} />
        </div>
        <div className={this.branchModalClassName}>
          <BranchDeliveryInfo
            branch={branch}
            loading={this.props.loading}
            fromPage={fromPage}
            branchNotCoveredAndGeoDisabled={branchNotCoveredAndGeoDisabled}
          />
          <ResponsiveContent max={WINDOW_SIZES.md}>
            <div className="d-flex flex-column mt-3">
              <BranchesSubmitButton
                loading={loading}
                branch={branch}
                delivery_type={delivery_type}
                fromPage={fromPage === PAGE_FROM.SMS ? PAGE_FROM.SMS : ''}
              />
            </div>
          </ResponsiveContent>
        </div>
      </>
    );
  }
}
