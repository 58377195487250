import { FC, ReactElement, memo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IOrderProductModifier, IOrderProductModifierOption } from 'src/types/orders';
import { OrderProductModifierOption } from '../../components/OrderProductModifierOption/OrderProductModifierOption';
import './style.scss';

interface IProps {
  modifiers: IOrderProductModifier[] | undefined;
  productQuantity: number;
  className?: string;
}

export const OrderProductModifiers: FC<IProps> = memo((props: IProps): ReactElement | null => {
  const { modifiers, productQuantity, className } = props;

  const options = modifiers?.reduce((sum: IOrderProductModifierOption[], current) => sum.concat(current.options), []);

  if (!options?.length) return null;

  return (
    <div className={`order-product-modifiers ${className || ''}`}>
      <p className="order-product-modifiers__title color-gray letter-spacing-5">{localeStore.t('modifiers')}</p>

      {options.map((option) => (
        <OrderProductModifierOption key={option.id} option={option} productQuantity={productQuantity} />
      ))}
    </div>
  );
});
