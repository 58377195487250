export enum DELIVERY_TYPES {
  PICKUP = 'p',
  DELIVERY = 'd',
  DINE_IN = 'i',
  BOTH = 'b',
}

export const PICKUP = 'PICKUP';
export const DELIVERY = 'DELIVERY';

export const DELIVERY_TYPE_NAMES = {
  [DELIVERY_TYPES.PICKUP]: 'pickup',
  [DELIVERY_TYPES.DELIVERY]: 'delivery',
  [DELIVERY_TYPES.BOTH]: 'both',
};
