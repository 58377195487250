import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { PAYMENT_TYPES } from 'src/constants/main';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import LoadingCircle from '../../../LoadingCircle/LoadingCircle';

function CheckoutTotalInfoDelivery() {
  const showDeliveryFee = React.useMemo(
    () =>
      branchStore.delivery_type === DELIVERY_TYPES.DELIVERY && cartStore.checkout.payment_type !== PAYMENT_TYPES.POINTS,
    [branchStore.delivery_type, cartStore.checkout.payment_type]
  );

  return showDeliveryFee ? (
    <div className="checkout-total-info-container">
      <p className={'color-dark family-semibold text-size-14'}>{localeStore.t('delivery_fee_excl_vat')}</p>
      <LoadingCircle>
        <p id={'checkout_delivery_fee'} className={'color-dark family-semibold text-size-14'}>
          {formatPrice(cartStore.calculator.delivery_fee)}
        </p>
      </LoadingCircle>
    </div>
  ) : null;
}
export default observer(CheckoutTotalInfoDelivery);
