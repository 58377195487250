import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

export default class PickupBranchSkeletton extends Component {
  render() {
    return (
      <div className={`branches-sidebar-branch branches-sidebar-skeleton d-flex flex-column full-width`}>
        <div className="d-flex flex-row align-items-center  mb-2">
          <Skeleton width={60} className="branches-title mr-3" />
        </div>

        <div className="d-flex flex-column mt-2">
          <div className="d-flex flex-row align-items-center justify-content-start mb-2">
            <Skeleton width={14} height={14} className="mr-2" />

            <Skeleton width={150} className="family-regular branches-info color-gray" />
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between mb-2">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <Skeleton width={14} height={14} className="mr-2" />
              <Skeleton width={150} className="branches-info" />
            </div>
            <Skeleton width={60} className="branches-info" />
          </div>
        </div>
      </div>
    );
  }
}
