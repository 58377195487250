import { useMemo } from 'react';
import { IMealComboGroupWithItems, IProductInsideCombo } from 'src/types/combo';
import { IProductExclusion, IProductModifier, PRODUCT_TYPE } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import { Carousel } from 'src/view/components/Carousel/Carousel';
import { MealItemModifiers } from '../../../../containers/MealItemModifiers/MealItemModifiers';
import ComboCarouselArrow from '../ComboCarouselArrow/ComboCarouselArrow';
import { ComboItem } from '../ComboItem/ComboItem';

interface IProps {
  group: IMealComboGroupWithItems;
  modifiersErrors: string[];
  needScrollToError: boolean;
  readonly?: boolean;
  onNotesChanged: (notes: string) => void;
  onSelectItem: (item: IProductInsideCombo) => void;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
  setNeedScrollToError: (need: boolean) => void;
}

const MealComboGroup: React.FC<IProps> = ({ group, ...props }: IProps): JSX.Element => {
  const selectedItem = useMemo((): IProductInsideCombo | undefined => {
    return group.items.find((item) => item.is_selected);
  }, [group]);

  const units = useMemo(() => productUtils.getComboItemUnits(selectedItem), [selectedItem]);

  const selectedItemDescription = useMemo((): string => {
    return [...units, getTranslate(selectedItem?.description)].filter(Boolean).join(' • ');
  }, [selectedItem, units]);

  return (
    <div className="d-flex flex-column">
      <p className="family-semibold color-dark text-size-20 font-weight-600 mb-4">{getTranslate(group.name)}</p>

      <Carousel
        itemsToShow={3}
        itemsToScroll={2}
        renderArrow={ComboCarouselArrow}
        className="combo-items__carousel"
        disableArrowsOnEnd={true}
        focusOnSelect={true}
      >
        {group.items.map((item, index) => (
          <ComboItem
            type={PRODUCT_TYPE.MEAL_COMBO}
            key={productUtils.getMealItemKey(item, index)}
            item={item}
            onSelectItem={props.onSelectItem}
          />
        ))}
      </Carousel>

      {selectedItemDescription ? (
        <p className="family-regular color-dark text-size-14 letter-spacing-5 text-lite-elipsis pxmt-4">
          {selectedItemDescription}
        </p>
      ) : null}

      {/* TODO: combo exclusions */}
      {/* selectedItem?.modifiers?.length || selectedItem?.exclusions?.length */}
      {selectedItem?.modifiers?.length ? (
        <div className="product-scroll">
          <MealItemModifiers
            item={selectedItem}
            errors={props.modifiersErrors}
            needScrollToError={props.needScrollToError}
            readOnly={props.readonly}
            onModifierChange={props.onModifierChange}
            setNeedScrollToError={props.setNeedScrollToError}
          />

          {/* TODO: combo exclusions */}
        </div>
      ) : null}
    </div>
  );
};

export default MealComboGroup;
