import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { Route } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { EVENTS } from 'src/constants/events';
import { defaultBranch, WINDOW_SIZES } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { requests } from 'src/requests';
import logger from 'src/services/logger';
import { IBranch } from 'src/types/branch';
import { IPagination } from 'src/types/main';
import { getUserPosition } from 'src/utils/helpers';
import { debounce } from 'src/utils/perfomance';
import Marker from 'src/view/components/Marker/Marker';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import { addressStore } from '../../../../../mobx/barnchStore/address';
import { userStore } from '../../../../../mobx/userStore';
import { VIEW_MODE } from '../../../../../types/address';
import SearchScreen from '../../../SearchScreen/SearchScreen';
import branchesScreenStore from '../../branchesScreenStore';
import BranchesMapControls from '../../components/BranchesMapControls/BranchesMapControls';
import BranchSearchBox from '../../components/BranchSearchBox/BranchSearchBox';
import DeliveryTypeSwitcher from '../../components/DeliveryTypeSwitcher/DeliveryTypeSwitcher';
import NavigationMapButton from '../../components/NavigationMapButton/NavigationMapButton';
import AddressSidebar from '../AddressSidebar/AddressSidebar';
import BranchDeliveryPopup from '../BranchPopup/BranchDeliveryPopup/BranchDeliveryPopup';
import BranchPickupPopup from '../BranchPopup/BranchPickupPopup/BranchPickupPopup';
import DeliveryTypeSidebar from '../DeliveryTypeSidebar/DeliveryTypeSidebar';
import './style.scss';

declare global {
  interface Window {
    map: any;
  }
}

interface Props {
  delivery_type: string;
}
interface State {
  branch: IBranch | null;
  branches: IPagination<IBranch>;
  delivery_type: string;
  position: GeolocationPosition | null;
  userPosition: { lat: number; lng: number } | undefined;
  deliveryCenter: { lat: number; lng: number };
  pickupCenter: { lat: number; lng: number };
  ready: boolean;
  loading: boolean;
  zoom: number;
  gettingUserPosition: boolean;
  permissionsAllowed: boolean;
  isLocationChanged: boolean;
  firstShowMapWithBlockCord: boolean;
}
@observer
export default class BranchesMapContainer extends Component<Props, State> {
  map: any = null;
  input: any = null;
  minZoom: number = 3;
  maxZoom: number = 20;
  state: State = {
    branch: null,
    branches: {
      current_page: 0,
      data: [],
      to: 0,
      total: 0,
      from: 0,
      last_page: 1,
    },
    position: null,
    userPosition: undefined,
    delivery_type: this.props.delivery_type,
    deliveryCenter: {
      lat: addressStore.address?.latitude || defaultBranch.latitude,
      lng: addressStore.address?.longitude || defaultBranch.longitude,
    },
    pickupCenter: {
      lat: defaultBranch.latitude,
      lng: defaultBranch.longitude,
    },
    ready: false,
    loading: true,
    zoom: 12,

    gettingUserPosition: false,
    permissionsAllowed: !!(!!branchesScreenStore.address?.latitude && defaultBranch.latitude),
    isLocationChanged: false,
    firstShowMapWithBlockCord: true,
  };

  componentDidMount = () => {
    const isFirstLoad =
      this.state.deliveryCenter.lat === defaultBranch.latitude && branchStore.branch.id === defaultBranch.id;
    this.getUserPosition(isFirstLoad);
  };

  getUserPosition = async (goToMyLocation = false) => {
    this.setState(() => ({ gettingUserPosition: true }));
    const position = await getUserPosition();
    this.setState(() => ({
      gettingUserPosition: false,
      permissionsAllowed: !!position,
    }));

    logger.logEvent(EVENTS.USER_LOCATION_DETERMINED);

    const { delivery_type, deliveryCenter, pickupCenter } = this.state;
    let center: GoogleMapReact.Coords = delivery_type === DELIVERY_TYPES.DELIVERY ? deliveryCenter : pickupCenter;

    /** If no userPosition (rejected permissions) and no saved address */
    if (!position) {
      const isFirstLoad =
        this.state.deliveryCenter.lat === defaultBranch.latitude && branchStore.branch.id === defaultBranch.id;

      this.setState({ firstShowMapWithBlockCord: isFirstLoad });

      center = {
        lat: addressStore.address?.latitude || defaultBranch.latitude,
        lng: addressStore.address?.longitude || defaultBranch.longitude,
      };
      this.setState({ zoom: 5 });
    }

    if (position) {
      this.setState({
        userPosition: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    }
    /** If position exists and no saved address or click to MyLocation button */
    if (position && goToMyLocation) {
      center = {
        // @ts-ignore
        lat: position.coords.latitude || addressStore.address?.latitude,
        // @ts-ignore
        lng: position.coords.longitude || addressStore.address?.longitude,
      };
    }

    // If position exists and delivery type is pickup
    if (position && delivery_type !== DELIVERY_TYPES.DELIVERY) {
      this.setState({
        deliveryCenter: { lat: position.coords.latitude, lng: position.coords.longitude } || this.state.deliveryCenter,
      });
    }

    this.setCenter(center, position);
  };

  setCenter = (
    center: GoogleMapReact.Coords = this.state.deliveryCenter,
    position: GeolocationPosition | null = this.state.position
  ) => {
    const isDelivery = this.state.delivery_type === DELIVERY_TYPES.DELIVERY;

    if (addressStore.editAddressOnMobileMap) {
      branchStore.setTempCoordinate({ latitude: center?.lat, longitude: center?.lng });
    } else {
      branchStore.setCoordinate({ latitude: center?.lat, longitude: center?.lng });
    }

    this.setState(
      {
        deliveryCenter: isDelivery ? center : this.state.deliveryCenter,
        pickupCenter: isDelivery ? this.state.pickupCenter : center,
        position,
      },
      () => this.onCenterWasChanged()
    );
  };

  onCenterWasChanged = () => {
    if (this.state.delivery_type === DELIVERY_TYPES.DELIVERY) {
      this.fetchBranchInfo();
    } else if (this.state.delivery_type === DELIVERY_TYPES.PICKUP) {
      // TODO: FETCH MORE PAGES
      if (!this.state.branches.data.length) this.getBranchesList(1);
    }
  };

  onZoomLevelChanged = (increase: boolean) => {
    let zoom = increase ? this.state.zoom + 1 : this.state.zoom - 1;
    if (zoom < this.minZoom) zoom = this.minZoom;
    if (zoom > this.maxZoom) zoom = this.maxZoom;
    this.setState({ zoom });
  };

  onDragEnd = async ({ center }: { center: { lat: () => number; lng: () => number } }) => {
    if (!this.state.isLocationChanged) {
      setTimeout(() => {
        this.setState({ isLocationChanged: true });
      }, 500);
    }
    if (addressStore.editAddressOnMobileMap) {
      branchStore.setTempCoordinate({ latitude: center.lat(), longitude: center.lng() });
    } else {
      branchStore.setCoordinate({ latitude: center.lat(), longitude: center.lng() });
    }

    if (this.state.delivery_type === DELIVERY_TYPES.DELIVERY) this.fetchBranchInfo();
  };

  getBranchInfo = async () => {
    try {
      this.setState(() => ({ loading: true }));
      const isFirstLoad =
        branchStore.selectMapCoordinate?.latitude === defaultBranch.latitude &&
        branchStore.branch.id === defaultBranch.id;

      this.setState({ firstShowMapWithBlockCord: isFirstLoad });

      const response = await requests.branches.branchSearch({
        latitude: branchStore.selectMapCoordinate?.latitude || branchesScreenStore.address?.latitude,
        longitude: branchStore.selectMapCoordinate?.longitude || branchesScreenStore.address?.longitude,
        delivery_type: DELIVERY_TYPES.DELIVERY,
      });

      let branch = null;

      // if response was rejected or throw error
      if (!response) {
        this.setState({ branch, loading: false });
        return;
      }
      branch = response.branch;

      if (!this.state.permissionsAllowed && !branch) {
        this.setState({
          branch,
          loading: false,
        });
        return;
      }

      this.setState({ branch, loading: false });
    } catch (error: any) {
      this.setState({ branch: null, loading: false });
    }
  };

  getBranchesList = async (page = this.state.branches.current_page + 1) => {
    try {
      this.setState(() => ({ loading: true }));

      const response = await requests.branches.getBranches({
        page: page || this.state.branches.current_page + 1,
        delivery_type: DELIVERY_TYPES.PICKUP,
        latitude: this.state.position?.coords.latitude,
        longitude: this.state.position?.coords.longitude,
      });

      if (response) {
        let branch = response.data.find((branch: IBranch) => branch.id === branchStore.branch.id);
        if (!branch) branch = response.data[0];

        this.setState(
          {
            branch,
            branches: {
              ...response,
              data: response.data.filter((branch: IBranch) => branch.working_hours && branch.working_hours.length > 0),
            },
            loading: false,
            pickupCenter: { lat: branch.latitude, lng: branch.longitude },
          },
          () => {
            branchStore.setBranches(this.state.branches.data);
          }
        );
      } else {
        this.setState({ branch: null, loading: false });
      }
    } catch (error: any) {
      this.setState({ branch: null, loading: false });
    }
  };

  fetchBranchInfo = debounce(this.getBranchInfo, 200);

  setDeliveryType = (delivery_type: string) => {
    if (delivery_type === DELIVERY_TYPES.DELIVERY) {
      this.setState({ delivery_type, branch: null, loading: true }, () => {
        this.componentDidMount();
        this.fetchBranchInfo();
      });
    } else if (delivery_type === DELIVERY_TYPES.PICKUP) {
      this.setState({ delivery_type, branch: null, loading: true }, () => this.getBranchesList(1));
    }
  };

  onSelectBranch = (branch: IBranch) => {
    this.setState({ branch, pickupCenter: { lat: branch.latitude, lng: branch.longitude } });
  };

  renderBranchPopup = (branch: IBranch) => (
    <BranchPickupPopup
      key={branch.id}
      onSelectBranch={this.onSelectBranch}
      selected={this.state.branch?.id}
      branch={branch}
      renderPin
      lat={branch.latitude}
      lng={branch.longitude}
    />
  );

  get isDraggable(): boolean {
    if (this.state.delivery_type === DELIVERY_TYPES.PICKUP || !userStore.isLogin) return true;
    return [VIEW_MODE.EDIT, VIEW_MODE.CREATE].includes(branchesScreenStore.viewMode);
  }
  get isEditAddress(): boolean {
    if (this.state.delivery_type === DELIVERY_TYPES.PICKUP || !userStore.isLogin) return true;
    return [VIEW_MODE.EDIT, VIEW_MODE.CREATE].includes(branchesScreenStore.viewMode);
  }
  addressChange = () => {
    this.fetchBranchInfo();
  };
  get getCenterMap() {
    const { pickupCenter, delivery_type } = this.state;

    let getAddress;

    if (addressStore.editAddressOnMobileMap) {
      getAddress = branchStore.tempMapCoordinate;
    } else {
      getAddress = branchStore.selectMapCoordinate;
    }

    if (delivery_type === DELIVERY_TYPES.DELIVERY) {
      return {
        lat: getAddress?.latitude || defaultBranch.latitude,
        lng: getAddress?.longitude || defaultBranch.longitude,
      };
    } else {
      return pickupCenter;
    }
  }
  render() {
    const { branch, userPosition } = this.state;
    return (
      <div className={`branches-modal d-flex flex-grow-1`}>
        <div
          id={'delivery_map'}
          className={`branches-map-wrapper d-flex flex-grow-1 ${
            addressStore.editAddressOnMobileMap ? 'delivery_map-edit_address' : null
          }`}
        >
          <GoogleMapReact
            zoom={this.state.zoom}
            onGoogleApiLoaded={async () => {
              this.setState({ ready: true, zoom: this.map?.map_?.zoom ?? 5 });
            }}
            bootstrapURLKeys={{
              key: appStore.settings?.google_maps_pk || '',
              libraries: ['geometry'],
              language: localeStore.language,
            }}
            center={this.getCenterMap}
            ref={(map) => (this.map = map)}
            defaultZoom={15}
            draggable={this.isDraggable}
            yesIWantToUseGoogleMapApiInternals
            onDragEnd={this.onDragEnd}
            options={{
              fullscreenControl: false,
              gestureHandling: 'greedy',
              zoomControl: false,
              scrollwheel: true,
              maxZoom: this.maxZoom,
            }}
          >
            {this.state.ready && this.state.delivery_type === DELIVERY_TYPES.PICKUP
              ? this.state.branches.data.map(this.renderBranchPopup)
              : null}

            {/* User Position */}
            {userPosition ? (
              <Marker lat={userPosition?.lat} lng={userPosition?.lng}>
                <span className="user-dot" />
              </Marker>
            ) : null}
          </GoogleMapReact>

          {/*
           * Show modal on map
           * */}
          <BranchDeliveryPopup
            branch={branch}
            loading={this.state.loading}
            delivery_type={this.state.delivery_type}
            permissionsAllowed={!this.state.permissionsAllowed || this.state.isLocationChanged}
            branchNotCoveredAndGeoDisabled={this.state.firstShowMapWithBlockCord}
          />

          <Route path={SCREENS.BRANCHES(DELIVERY_TYPES.PICKUP)}>
            <BranchPickupPopup onSelectBranch={this.onSelectBranch} selected={this.state.branch?.id} branch={branch} />
          </Route>

          <ResponsiveContent max={WINDOW_SIZES.md}>
            <NavigationMapButton deliveryType={this.state.delivery_type} />
          </ResponsiveContent>

          <BranchesMapControls
            gettingUserPosition={this.state.gettingUserPosition}
            getUserPosition={this.getUserPosition}
            setCenter={this.setCenter}
            onZoomLevelChanged={this.onZoomLevelChanged}
            showMyLocation={this.isEditAddress}
          />

          {/*
           * hide the search bar on the address view screen
           * show only on edit and create address
           */}
          <ResponsiveContent min={WINDOW_SIZES.md}>
            {this.isEditAddress ? (
              <div className={`branches-top d-flex flex-column flex-lg-row`}>
                <BranchSearchBox setCenter={this.setCenter} searchAddress />
              </div>
            ) : null}
          </ResponsiveContent>
        </div>

        {/* delivery type Switcher */}
        <div className={'delivery-type-tab'}>
          <DeliveryTypeSwitcher onChange={this.setDeliveryType} ready={this.state.ready} />
        </div>

        <SearchScreen coordinates={this.setCenter} />
        {/* Delivery type - Pickup */}
        <Route path={SCREENS.BRANCHES(DELIVERY_TYPES.PICKUP)}>
          <DeliveryTypeSidebar
            branch={this.state.branch}
            onSelectBranch={this.onSelectBranch}
            selected={this.state.branch?.id}
            loading={this.state.loading}
            delivery_type={this.state.delivery_type}
            branches={this.state.branches.data}
          />
        </Route>

        {/** Delivery type - Delivery  */}
        <Route path={[SCREENS.BRANCHES(DELIVERY_TYPES.DELIVERY), SCREENS.BRANCHES(DELIVERY_TYPES.BOTH)]}>
          <AddressSidebar onChange={this.addressChange} branch={this.state.branch} />
        </Route>
      </div>
    );
  }
}
