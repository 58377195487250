import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { EVENTS, PAYMENT_EXCEPTION_CODE } from 'src/constants/events';
import { E_MENU_TYPE, ORDER_STATUS, PAYMENT_STATUS, PAYMENT_TYPES } from 'src/constants/main';
import { userStore } from 'src/mobx/userStore';
import logger from 'src/services/logger';
import { getUserPosition, getWalletCurrency } from 'src/utils/helpers';
import { Discount } from 'src/view/containers/Discount/Discount';
import { ICONS } from '../../../constants/icons';
import { paymentTypes } from '../../../constants/paymentTypes';
import { SCREENS } from '../../../constants/screens';
import { appStore } from '../../../mobx/appStore';
import { localeStore } from '../../../mobx/localesStore';
import { ordersStore } from '../../../mobx/ordersStore';
import { requests } from '../../../requests';
import { IOrder, IOrderProduct } from '../../../types/orders';
import { formatPrice, getTranslate } from '../../../utils/formatter';
import { default as Button, default as ButtonComponent } from '../../components/Controls/Button/Button';
import DropDownModal from '../../components/Controls/DropDownModal/DropDownModal';
import Icon from '../../components/Controls/Icon/Icon';
import Modal from '../../components/Controls/Modal/Modal';
import { Status } from '../../components/Status/Status';
import { DetailsProperty } from './components/DetailsProperty';
import OrderReorder from './components/OrderReorder/OrderReorder';
import { OrderProduct } from './components/OrderProduct/OrderProduct';
import { SeparatorLine } from './components/SeparatorLine/SeparatorLine';
import './style.scss';
import { ERROR_HANDLERS } from '../../../utils/helpers/errorHandler';
import handlers from './handlers';

interface IProps extends RouteComponentProps<{ id: string }, StaticContext, IOrder | undefined> {}

interface IState {
  isOpen: boolean;
  loading: boolean;
  isArriving: boolean;
  isCancelling: boolean;
}

@observer
export default class OrderScreen extends React.Component<IProps, IState> {
  state: IState = {
    isOpen: true,
    loading: false,
    isArriving: false,
    isCancelling: false,
  };

  componentDidMount = async () => {
    if (this.props.location.state?.orderError) {
      const error = this.props.location.state?.orderError;
      if (error.exceptionType === PAYMENT_EXCEPTION_CODE.ORDER_BECAME_UNAVAILABLE) {
        ERROR_HANDLERS.EXCEPTION_MESSAGE({
          title: localeStore.t('order_not_paid_error_header'),
          message: localeStore.t('order_not_available_error_message'),
        });
      }

      if (error.exceptionType === PAYMENT_EXCEPTION_CODE.ORDER_CANNOT_BE_PAID) {
        ERROR_HANDLERS.EXCEPTION_MESSAGE({
          title: localeStore.t('order_not_paid_error_header'),
          message: localeStore.t('order_already_paid_error_message'),
        });
      }
    }
    if (this.props.location.state) {
      // @ts-ignore
      ordersStore.selectOrder(this.props.location.state?.order ?? this.props.location.state);
    } else {
      this.fetchOrder();
    }
  };

  componentWillUnmount = () => {
    ordersStore.selectOrder(null);
  };

  componentDidUpdate = () => {
    const total = document.querySelector('.order-total_fixed');
    const wrapper = document.getElementById('info-wrapper');
    if (wrapper && total?.clientHeight) wrapper.style.marginBottom = total?.clientHeight + 'px';
  };

  fetchOrder = async () => {
    try {
      const order = await requests.getOrderById(this.props.match.params.id);
      ordersStore.selectOrder(order);
      this.setState({ loading: false });
    } catch (error: any) {
      this.setState({ loading: false });
      this.props.history.replace(SCREENS.NOT_FOUND());
    }
  };

  closeModal = () => {
    this.setState({ isOpen: false }, () => {
      this.props.history.push(SCREENS.ORDERS());
    });
  };

  handleClientArrived = async () => {
    if (!ordersStore.order) return;
    this.setState({ isArriving: true });
    const updated = await ordersStore.setClientArrived(ordersStore.order.id);
    if (updated) {
      const curbside: IOrder['curbside'] = ordersStore.order.curbside
        ? {
            ...ordersStore.order.curbside,
            client_arrived: 1,
          }
        : {
            driver_name: '',
            car_brand: '',
            car_color: '',
            car_type: '',
            car_number: '',
            curbside_fee: 0,
            client_arrived: 1,
            client_arrived_at: null,
          };
      const order = {
        ...ordersStore.order,
        curbside,
      };
      this.setState({
        isArriving: false,
      });
      ordersStore.selectOrder(order);
    }
    this.setState({ isArriving: false });
  };

  cancelOrder = async () => {
    try {
      if (!ordersStore.order) return;

      this.setState({ isCancelling: true });
      await ordersStore.cancelOrder(ordersStore.order.id);
      // Update wallet balance if wallet was used
      if (ordersStore.order.use_wallet) await userStore.fetchUser();

      logger.logEvent(EVENTS.ORDER_CANCELLED, ordersStore.order);
      this.closeModal();
    } catch (err) {
      this.setState({ isCancelling: false });
    }
  };

  handleMapPinClick = async () => {
    const { latitude, longitude } = ordersStore.order?.branch || {};

    const userPosition = await getUserPosition();

    if (userPosition) {
      window.location.assign(
        `https://maps.google.com?saddr=${userPosition.coords.latitude},${userPosition.coords.longitude}&daddr=${latitude},${longitude}`
      );
    } else {
      window.location.assign(`https://maps.google.com?q=${latitude},${longitude}`);
    }
  };

  renderCurbsideModal = ({ closeModal }: { closeModal: any }) => (
    <div className="d-flex flex-column full-width curbside-confirm-modal">
      <div className="info-popup-content d-flex flex-column">
        <h1 className="info-popup-title">
          <h2 className="color-dark text-size-18 mb-3 text-center">{localeStore.t('have_you_arrived')}</h2>
        </h1>
        <p className="color-dark text-size-16 mb-3 text-center">{localeStore.t('inform_admin_curbside')}</p>
        <Icon className="info-popup-close" name={ICONS.CLOSE} size={14} color={'#B6B6B6'} />
      </div>

      <div className="info-popup-buttons d-flex flex-row full-width justify-content-between">
        <ButtonComponent
          className="info-popup-button curbside-confirm-button"
          onClick={this.handleClientArrived}
          title={localeStore.t('txt_yes')}
        />
        <div className="mr-1"></div>
        <ButtonComponent className="info-popup-button background-light color-gray">
          <span className="color-dark">{localeStore.t('txt_no')}</span>
        </ButtonComponent>
      </div>
    </div>
  );

  renderCancelModal = () => {
    if (!ordersStore.order) return null;
    return (
      <div className="d-flex flex-column full-width">
        <div className="info-popup-content d-flex flex-column">
          <h2 className="color-dark text-size-18 mb-3 text-center">{localeStore.t('are_you_sure')}</h2>
          <p className="color-dark text-size-16 mb-3 text-center">
            {ordersStore.order.payment_type === 1
              ? localeStore.t('txt_cancel_payed_order_text')
              : localeStore.t('txt_cancel_order_text')}
          </p>

          <Icon className="info-popup-close" name={ICONS.CLOSE} size={14} color={'#B6B6B6'} />
        </div>
        <div className="info-popup-buttons d-flex flex-row full-width justify-content-between">
          <ButtonComponent
            fullSize
            className="info-popup-button"
            onClick={this.cancelOrder}
            title={localeStore.t('txt_delete')}
          />
        </div>
      </div>
    );
  };

  renderProperty = (key: string, value: string | number | undefined, id?: string, vital?: boolean) => {
    if (!ordersStore.order) {
      return <DetailsProperty />;
    }
    if (!value && !vital) return null;

    return <DetailsProperty id={id} property={key} value={value} />;
  };

  handleCopyToClipboard = (event: any) => {
    navigator.clipboard.writeText(event.target.dataset.value);
  };

  orderButton = () => {
    if (!ordersStore.order) {
      return null;
    }

    const { order_status_code, payment_status, is_payment_created } = ordersStore.order;
    const { countDownTimer } = ordersStore;

    const orderIsWaitingForPayment = order_status_code === ORDER_STATUS.WAITING_FOR_PAYMENT;
    const paymentStatusNonReceive = payment_status === PAYMENT_STATUS.NON_RECEIVED || !payment_status;
    const isPaymentFail = !is_payment_created || payment_status === PAYMENT_STATUS.FAIL;

    if (orderIsWaitingForPayment && is_payment_created && paymentStatusNonReceive) {
      return (
        <Button
          disabled={countDownTimer.disabled}
          className="cancel-order-button full-width mt-2 mt-lg-4"
          loading={false}
          spinnerColor={'#F1F1F1'}
          onClick={() => handlers.checkOrderStatus()}
        >
          {countDownTimer.disabled ? localeStore.t('check_status_button') + ' ' + countDownTimer.formattedResult : null}
          {!countDownTimer.disabled ? localeStore.t('check_status_button') : null}
        </Button>
      );
    }

    if (orderIsWaitingForPayment && isPaymentFail) {
      return (
        <Button
          disabled={false}
          className="cancel-order-button full-width mt-2 mt-lg-4"
          loading={false}
          spinnerColor={'#F1F1F1'}
          onClick={() => handlers.tryPaymentAgain(this.props.history)}
        >
          {localeStore.t('complete_payment_button')}
        </Button>
      );
    }
    return null;
  };

  render() {
    const { isArriving, isCancelling } = this.state;
    const vat = (ordersStore.order?.vat || 0) + (ordersStore.order?.taxable_delivery_fee || 0);
    const date = ordersStore.order?.order_date || new Date();
    const created = ordersStore.order?.created_at || new Date();
    const creation_time = ordersStore.order?.created_at || new Date();
    const copyIcon = `${process.env.REACT_APP_CLOUD_FRONT}/media/images/copy.svg`;

    const orderNumber = ordersStore.order?.order_key ? (
      <>
        {'#'}
        {ordersStore.order?.order_key}
        <img
          onClick={this.handleCopyToClipboard}
          src={copyIcon}
          title="copy"
          alt="copy"
          className="cursor-pointer pl-3"
          data-value={ordersStore.order?.order_key}
        />
      </>
    ) : (
      <Skeleton width={200} />
    );

    return (
      <Modal className="order-details-modal" isOpen={this.state.isOpen} onRequestClose={this.closeModal}>
        <div className="d-flex flex-column flex-grow-1 full-width">
          <div className="d-flex flex-column py-3 px-3  py-lg-5 px-lg-5">
            {/** Order key + order status */}
            <div className="details-key color-primary mb-4">
              <div className="d-flex">{orderNumber}</div>
              {ordersStore.order ? (
                <Status statusCode={ordersStore.order.order_status_code} status={ordersStore.order.status} />
              ) : (
                <Skeleton width={150} />
              )}
            </div>
            <div className="details-top-row d-flex flex-column flex-lg-row">
              {!ordersStore.order?.dinein_delivery_type ? (
                /** Order time */
                <div className="details-top-row d-flex flex-column flex-lg-row">
                  <div className="mb-2 mb-lg-4 mr-5">
                    <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                      {localeStore.t('creation_time')}
                    </div>
                    <div className="mt-2 mb-3 mb-lg-4 d-flex align-items-center">
                      <Icon name={ICONS.CALENDAR} size={18} color={'#6D6D6D'} className="mr-4" />
                      <span className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                        {localeStore.t(`month_${moment(created).format('M')}`)}{' '}
                        {moment(created).format(' DD, YYYY, hh:mm A')}
                      </span>
                    </div>
                  </div>
                  <div className="mb-2 mb-lg-4 mr-5">
                    <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                      {localeStore.t('order_time')}
                    </div>
                    <div className="mt-2 mb-3 mb-lg-4 d-flex align-items-center">
                      <Icon name={ICONS.CALENDAR} size={18} color={'#6D6D6D'} className="mr-4" />
                      <span className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                        {localeStore.t(`month_${moment(date).format('M')}`)} {moment(date).format(' DD, YYYY, hh:mm A')}
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}

              {/** Delivery type and address information */}
              <div className="mb-2 mb-lg-4 mr-5">
                <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                  {ordersStore.order ? (
                    localeStore.t(
                      ordersStore.order?.dinein_delivery_type
                        ? ordersStore.order?.dinein_delivery_type === E_MENU_TYPE.DINE_IN
                          ? 'dine_in'
                          : 'to_go'
                        : ordersStore.order.is_curbside
                        ? 'curbside'
                        : ordersStore.order.delivery_type_code === DELIVERY_TYPES.DELIVERY
                        ? 'txt_delivery'
                        : 'txt_pickup'
                    )
                  ) : (
                    <Skeleton width={170} />
                  )}
                </div>

                <div
                  className={`mt-2 d-flex flex-column flex-lg-row align-items-start flex-wrap align-items-lg-center ${
                    !ordersStore.order?.dinein_delivery_type ? 'mb-3' : ''
                  }`}
                >
                  {/** Branch name */}
                  <div className="mb-2  mr-5">
                    <Icon
                      name={ordersStore.order?.delivery_type_code === 'p' ? ICONS.PICKUP : ICONS.DELIVERY}
                      size={18}
                      color={'#6D6D6D'}
                      className="mr-4"
                    />
                    <span id={'order_branch'} className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                      {getTranslate(ordersStore.order?.branch.name) || <Skeleton width={70} />}
                    </span>
                  </div>

                  {/** Branch/delivery-to address */}
                  {ordersStore.order ? (
                    <div className="mb-2">
                      {ordersStore.order.delivery_type_code === 'd' && ordersStore.order?.address ? (
                        <Icon name={ICONS.MARKER} size={18} color={'#6D6D6D'} className="mr-4" />
                      ) : null}
                      <span
                        id={'order_address'}
                        className="text-size-14 text-md-size-12  text-uppercase color-darkgray"
                      >
                        {ordersStore.order?.address}
                      </span>
                    </div>
                  ) : (
                    <Skeleton width={250} />
                  )}
                  {/* Section and Table */}
                  {ordersStore.order?.dinein_delivery_type === E_MENU_TYPE.DINE_IN && ordersStore.order?.section ? (
                    <div className="mb-2">
                      <Icon name={ICONS.DINE_IN_OUTLINE} size={18} color={'#6D6D6D'} className="mr-4" />
                      <span
                        id={'order_dine_in'}
                        className="text-size-14 text-md-size-12  text-uppercase color-darkgray"
                      >
                        {`${ordersStore.order?.section}, ${ordersStore.order?.table}`}
                      </span>
                    </div>
                  ) : null}

                  {/* Phone E-menu */}
                  {ordersStore.order?.dinein_delivery_type ? (
                    <a href={`tel:${ordersStore.order.branch.mobile}`} className="mb-2  d-flex align-items-center">
                      <Icon name={ICONS.CALL} size={18} color={'#6D6D6D'} className="mr-4" />
                      <span dir="ltr" className="text-size-18 text-md-size-14 text-uppercase color-primary">
                        {ordersStore.order.branch.mobile}
                      </span>
                    </a>
                  ) : null}

                  {ordersStore.order?.delivery_type_code === DELIVERY_TYPES.PICKUP ? (
                    <div className="map-label">
                      {ordersStore.order ? (
                        <div className="map-label__box" onClick={this.handleMapPinClick}>
                          <Icon name={ICONS.MARKER} color="#FF6D6D" />
                          <p className="text-size-16 family-semibold ml-2">{localeStore.t('txt_check_on_the_map')}</p>
                        </div>
                      ) : (
                        <Skeleton width={200} className="map-label__box" />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>

              {ordersStore.order?.dinein_delivery_type ? (
                <>
                  {/** Creation time */}
                  <div className="mb-2 mb-lg-4 mr-5">
                    <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                      {localeStore.t('creation_time')}
                    </div>
                    <div className="mt-2 mb-3 mb-lg-4 d-flex align-items-center">
                      <Icon name={ICONS.CALENDAR_TIME} size={18} color={'#6D6D6D'} className="mr-4" />
                      <span className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                        {localeStore.t(`month_${moment(creation_time).format('MM')}`)}{' '}
                        {moment(creation_time).format('DD hh:mm A')}
                      </span>
                    </div>
                  </div>
                  {/** Due time */}
                  <div className="mb-2 mb-lg-4 mr-5">
                    <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                      {localeStore.t('due_time')}
                    </div>
                    <div className="mt-2 mb-3 mb-lg-4 d-flex align-items-center">
                      <Icon name={ICONS.CALENDAR_TIME} size={18} color={'#6D6D6D'} className="mr-4" />
                      <span className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                        {localeStore.t(`month_${moment(date).format('MM')}`)} {moment(date).format('DD hh:mm A')}
                      </span>
                    </div>
                  </div>
                </>
              ) : null}

              {/* Payment type */}
              <div className="mb-2 mb-lg-4 mr-5">
                <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                  {localeStore.t('txt_payment_type')}
                  <div className="mt-2 mb-3  d-flex align-items-center">
                    <Icon name={ICONS.CASH} size={18} color={'#6D6D6D'} className="mr-4" />
                    <span id={'order_payment'} className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                      {ordersStore.order ? (
                        localeStore.t(paymentTypes[ordersStore.order.payment_type || 0])
                      ) : (
                        <Skeleton width={70} />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Driver info */}
            {ordersStore.order?.taken ? (
              <div className="details-top-row d-flex flex-column flex-lg-row">
                {/** Driver name */}
                <div className="mb-2 mr-5 ">
                  <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                    {localeStore.t('driver_info')}
                  </div>
                  <div className="d-flex flex-row">
                    {ordersStore.order.taken.driver_name ? (
                      <div className="mt-2   mr-4 d-flex align-items-center">
                        <Icon name={ICONS.PROFILE} size={18} color={'#6D6D6D'} className="mr-4" />
                        <span className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                          {ordersStore.order.taken.driver_name}
                        </span>
                      </div>
                    ) : null}

                    {ordersStore.order.taken.driver_mobile ? (
                      <a
                        href={`tel:${ordersStore.order.taken.driver_mobile}`}
                        className="mt-2  d-flex align-items-center"
                      >
                        <Icon name={ICONS.CALL} size={18} color={'#6D6D6D'} className="mr-4" />
                        <span className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                          {ordersStore.order.taken.driver_mobile}
                        </span>
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            {ordersStore.order?.is_order_for_someone_else && (
              <div className="details-top-row d-flex flex-column flex-lg-row">
                <div className="mb-2 mb-lg-4 mr-5">
                  <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                    {localeStore.t('order_for_sb')}
                  </div>
                  <div className="mt-2 mb-3 mb-lg-4 d-flex flex-row">
                    <div className="mt-2   mr-4 d-flex align-items-center">
                      <Icon name={ICONS.PROFILE} size={18} color={'#6D6D6D'} className="mr-4" />
                      <span className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                        {ordersStore.order.stranger_customer_info.name}
                      </span>
                    </div>

                    <a
                      href={`tel:${ordersStore.order.stranger_customer_info.mobile}`}
                      className="mt-2  d-flex align-items-center"
                    >
                      <Icon name={ICONS.CALL} size={18} color={'#6D6D6D'} className="mr-4" />
                      <span className="text-size-18 text-md-size-14 text-uppercase color-darkgray">
                        {ordersStore.order.stranger_customer_info.mobile}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            )}
            {ordersStore.order?.notes && (
              <div className="details-top-row d-flex flex-column flex-lg-row ">
                <div className="mb-2 details-top-row-no_overflow">
                  <div className="text-size-18 text-md-size-14 font-size-lg-16 color-dark family-semibold">
                    {localeStore.t('order_notes')}
                  </div>
                  <span className="text-size-18 text-md-size-14 color-darkgray details-long_text">
                    {ordersStore.order.notes}
                  </span>
                </div>
              </div>
            )}
          </div>

          {/** Bottom details: items, total, etc */}
          <div className="order-bottom-sheet background-white  py-3 px-3  py-lg-5 px-lg-5">
            <div className="d-flex flex-column flex-lg-row   background-white full-width">
              {/** Items list */}
              <div className="flex-grow-1 full-width d-flex flex-column order-products-wrapper">
                <div className="d-flex   d-lg-none flex-row justify-content-between align-items-center mb-4">
                  <p className="text-size-18 family-bold" style={{ fontSize: 24 }}>
                    {ordersStore.order?.products.length || 0} {localeStore.t('items')}
                  </p>
                  <p className="text-size-18 family-semibold">
                    {ordersStore.formatPrice(ordersStore.order?.total || 0)}
                  </p>
                </div>

                <div className="order-products hide-scrollbar">
                  {ordersStore.order ? (
                    ordersStore.order.products.map((product: IOrderProduct) => (
                      <OrderProduct key={product.id} product={product} />
                    ))
                  ) : (
                    <Skeleton count={4} height={69} className={'mb-2'} style={{ borderRadius: 20 }} />
                  )}
                </div>
              </div>

              {/* Offset */}
              <div className="mr-0 mr-lg-5"></div>
              {/** Pricing details */}
              <div id="info-wrapper" className="flex-grow-1 full-width mt-4 mt-lg-0  order-info-wrapper">
                <div className="d-flex flex-column">
                  {appStore.settings?.is_points && ordersStore.order?.earn_point ? (
                    <>
                      {this.renderProperty(localeStore.t('Points Earned'), ordersStore.order?.earn_point)}
                      <SeparatorLine />
                    </>
                  ) : null}

                  {/* Curbside */}
                  {ordersStore.order?.is_curbside ? (
                    <>
                      {ordersStore.order.curbside?.car_brand && (
                        <DetailsProperty
                          property={localeStore.t('car_brand')}
                          value={ordersStore.order.curbside?.car_brand}
                        />
                      )}
                      {ordersStore.order.curbside?.car_color && (
                        <DetailsProperty
                          property={localeStore.t('car_color')}
                          value={ordersStore.order.curbside?.car_color}
                        />
                      )}
                      {ordersStore.order.curbside?.car_type && (
                        <DetailsProperty
                          property={localeStore.t('car_type')}
                          value={ordersStore.order.curbside?.car_type}
                        />
                      )}
                      {ordersStore.order.curbside?.car_number && (
                        <DetailsProperty
                          property={localeStore.t('car_number')}
                          value={ordersStore.order.curbside?.car_number}
                        />
                      )}
                      <SeparatorLine />
                    </>
                  ) : null}

                  {/* Subtotal */}
                  {this.renderProperty(
                    localeStore.t('total_amount_excl_vat'),
                    ordersStore.formatPrice(ordersStore.order?.subtotal || 0),
                    'total_order_amount_excl_vat'
                  )}

                  {/* Discount */}
                  {ordersStore.order?.promocode ? (
                    <Discount
                      className="details-property flex-column"
                      name={ordersStore.order?.promocode}
                      amount={ordersStore.order?.promocode_amount}
                      description={ordersStore.order?.promocode_description}
                      loading={this.state.loading}
                    />
                  ) : null}

                  {/* Delivery fee */}
                  {ordersStore.order?.delivery_type_code === 'd'
                    ? this.renderProperty(
                        localeStore.t('delivery_fee_excl_vat'),
                        ordersStore.formatPrice(ordersStore.order?.delivery_fee || 0),

                        'delivery_fee'
                      )
                    : null}

                  {/* VAT % */}
                  {this.renderProperty(
                    `${localeStore.t('vat')} ${
                      ordersStore.order?.vat_percentage ? ordersStore.order?.vat_percentage + '%' : ''
                    }`,
                    ordersStore.formatPrice(vat),
                    'order_VAT',
                    true
                  )}

                  {/* Wallet Discount */}
                  {ordersStore.order?.wallet_discount
                    ? this.renderProperty(
                        localeStore.t('wallet_discount'),
                        formatPrice(-ordersStore.order?.wallet_discount, getWalletCurrency())
                      )
                    : null}

                  {/* Points */}
                  {appStore.settings?.is_points && ordersStore.order?.promocode && ordersStore.order?.earn_point
                    ? this.renderProperty(localeStore.t('txt_points'), ordersStore.order?.earn_point)
                    : null}

                  <SeparatorLine className="d-none d-lg-flex" />
                </div>

                {/* Total */}
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column order-total_fixed background-white px-3 px-lg-0 color-light">
                    <div className="d-flex flex-row justify-content-between py-3 full-width">
                      <p className="color-primary family-semibold text-size-20">{localeStore.t('txt_total')}</p>
                      <p id={'total'} className="color-primary family-semibold text-size-20">
                        {ordersStore.order ? (
                          ordersStore.formatPrice(ordersStore.order.total)
                        ) : (
                          <Skeleton width={100} />
                        )}
                      </p>
                    </div>

                    {/** Curbside button */}
                    {ordersStore.order?.is_curbside && !['ca', 'w'].includes(ordersStore.order?.order_status_code) ? (
                      <DropDownModal
                        position={'top'}
                        asPopupInMobile
                        disabled={Boolean(ordersStore.order.curbside?.client_arrived || isArriving)}
                        content={this.renderCurbsideModal}
                      >
                        <Button
                          className="curbside-button full-width  mt-2 mt-lg-4"
                          disabled={Boolean(ordersStore.order.curbside?.client_arrived || isArriving)}
                          loading={isArriving}
                        >
                          <div className="d-flex flex-row">
                            {ordersStore.order.curbside?.client_arrived ? (
                              <Icon className={'mr-3'} name={ICONS.CHECK} color={'#fff'} />
                            ) : null}
                            <div className="curbside-button-text">{localeStore.t('im_here')}</div>
                          </div>
                        </Button>
                      </DropDownModal>
                    ) : null}

                    {/* reorder */}
                    <div className="d-flex flex-column full-width">
                      {this.orderButton()}
                      {/** Cancel order button */}
                      {((ordersStore.order?.wallet_discount && ordersStore.order?.total === 0) ||
                        Number(ordersStore.order?.payment_type) !== PAYMENT_TYPES.ONLINE_PAYMENT) &&
                      ordersStore.order &&
                      [ORDER_STATUS.PENDING, ORDER_STATUS.WAITING_FOR_PAYMENT].includes(
                        ordersStore.order?.order_status_code
                      ) ? (
                        <DropDownModal
                          asPopupInMobile
                          position={'top'}
                          justify={'center'}
                          disabled={isCancelling}
                          content={this.renderCancelModal}
                        >
                          <Button
                            disabled={isCancelling}
                            className="cancel-order-button full-width mt-2 mt-lg-4"
                            loading={isCancelling}
                            spinnerColor={'#F1F1F1'}
                          >
                            {localeStore.t('txt_cancel_order')}
                          </Button>
                        </DropDownModal>
                      ) : null}
                      {!ordersStore.order?.dinein_delivery_type ? <OrderReorder order={ordersStore.order} /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
