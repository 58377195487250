import React, { Component } from 'react';
import { PageContainer } from 'src/view/components/PageContainer/PageContainer';
import WalletBalance from './containers/WalletBalance/WalletBalance';
import WalletList from './containers/WalletList/WalletList';
import WalletStatuses from './containers/WalletStatuses/WalletStatuses';
import './style.scss';
import { userStore } from '../../../mobx/userStore';
export type TWalletStatus = undefined | 1 | 2 | 3 | 4 | 7;
interface State {
  status: TWalletStatus;
}

export default class WalletScreen extends Component<{}, State> {
  state: State = {
    status: undefined,
  };

  componentDidMount() {
    userStore.fetchUser();
  }

  setStatus = (status: State['status']) => this.setState({ status });

  render() {
    return (
      <PageContainer className={'page-container-row wallet'}>
        <div className="full-width col">
          <WalletStatuses status={this.state.status} setStatus={this.setStatus} />
          <div className="d-flex flex-row full-width align-items-start">
            <WalletList status={this.state.status} />
            <WalletBalance />
          </div>
        </div>
      </PageContainer>
    );
  }
}
