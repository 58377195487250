import { ICashbackInfo, IProduct } from 'src/types/products';
import { calculateProductPrice } from '../calculateProductPrice/calculateProductPrice';

export const calculateProductCashbackAmount = (
  product: IProduct | undefined,
  cashbackInfo: ICashbackInfo | null
): number => {
  if (product?.type) return 0;

  const percent = cashbackInfo?.percent || 0;
  const max = cashbackInfo?.maximum;
  const price = product ? calculateProductPrice(product) : 0;

  const cashback = (price * percent) / 100;
  const result = Number(cashback.toFixed(2));

  if (max && result > max) return max;
  return result;
};
