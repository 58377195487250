import Icon from '../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../constants/icons';
import React, { FC, memo, useCallback } from 'react';
import { searchStore } from '../../searchStore';

interface IProps {
  key: any;
  value: {
    main_text: string;
    secondary_text: string;
  };
  description: string;
  selectAddress: (address: string) => void;
}

const SearchResult: FC<IProps> = ({ key, value, description, selectAddress }: IProps) => {
  const checkAddress = useCallback(() => {
    selectAddress(description);
    searchStore.toggleOpen(false);
  }, []);
  return (
    <div className="search-result-block" key={key} onClick={checkAddress}>
      <div className="search-result-icon-b">
        <Icon name={ICONS.MARKER_FILLED} className="search-result-icon color-primary" />
      </div>
      <div className="search-result-text-wrap d-flex flex-column">
        <div className="search-result-text text-size-18 title family-semibold">{value.main_text}</div>
        <div className="search-result-text text-size-14 color-gray">{value.secondary_text}</div>
      </div>
    </div>
  );
};
export default memo(SearchResult);
