import { observer } from 'mobx-react';
import { cartStore } from 'src/mobx/cartStore';
import { Cashback } from 'src/view/components/Cashback/Cashback';
import './style.scss';
import { CashbackErrorCode } from 'src/constants/errors';
import { localeStore } from 'src/mobx/localesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { ICONS } from 'src/constants/icons';
import { useCallback } from 'react';

const CheckoutCashback = observer(() => {
  const { cashback } = cartStore.calculator;

  const handleClearError = useCallback(() => {
    cartStore.cashbackError = null;
  }, []);

  const showCashbackInfo = !!(cashback.name && cashback.amount);
  const cashbackChanged = cartStore.cashbackError === CashbackErrorCode.ChangedConditions;
  const cashbackUnavailable =
    cartStore.cashbackError &&
    [
      CashbackErrorCode.Unavailable,
      CashbackErrorCode.UnavailableForBranch,
      CashbackErrorCode.UnavailableForOrderType,
    ].includes(cartStore.cashbackError);

  if (Boolean(cartStore.checkout.use_wallet) || Boolean(cartStore.calculator.promocode)) {
    return null;
  }

  if (cashbackUnavailable) {
    return (
      <div className={'checkout-cashback_unavailable mb-4'}>
        <div className="checkout-cashback_unavailable-container">
          <div className="checkout-cashback_unavailable-info">
            <div className="checkout-cashback_unavailable-offer-name">{localeStore.t('cashback')}</div>
            <Icon
              onPress={handleClearError}
              name={ICONS.CLOSE}
              size={12}
              color={'#FF6D6D'}
              className="checkout-cashback_unavailable-error-icon"
            />
          </div>
          <div className="checkout-cashback_unavailable-description">
            {localeStore.t('keep_cart_cashback_is_unavailable_notification')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {showCashbackInfo && (
        <Cashback
          name={cashback.name}
          description={cashback.description}
          amount={cashback.amount}
          className={cashbackChanged ? 'mb-2' : 'mb-4'}
        />
      )}
      {cashbackChanged && (
        <div className="family-regular text-size-12 color-red mb-2 mb-4">
          {localeStore.t('keep_cart_cashback_conditions_changed_popup_bodytext')}
        </div>
      )}
    </>
  );
});

export default CheckoutCashback;
