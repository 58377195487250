import { IProduct } from 'src/types/products';
import { formatUnit, getTranslate } from 'src/utils/formatter';

/**
 * get unit string if additional unit is exist in product
 */
export const getProductUnit = (product: IProduct | undefined): string | null => {
  if (!product) return null;
  const ref: IProduct | IProduct['size'] = product.size || product;
  return formatUnit(ref.unit || getTranslate(ref.unit_name), ref.weight);
};
