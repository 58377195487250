import { FC, ReactElement, useEffect, useMemo, useRef } from 'react';
import { IProductModifier } from 'src/types/products';
import MultiChoise from 'src/view/components/Controls/MultiChoise/MultiChoise';
import SignleChoise from 'src/view/components/Controls/SignleChoise/SignleChoise';

interface IProps {
  modifier: IProductModifier;
  isModifierWithError: boolean;
  needScrollToError?: boolean;
  readOnly?: boolean;
  onModifierChange: (modifier: IProductModifier) => void;
  setNeedScrollToError?: (need: boolean) => void;
  className?: string;
}

export const ProductModifier: FC<IProps> = ({ modifier, ...props }: IProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollOnError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifier, props.isModifierWithError, props.needScrollToError]);

  const scrollOnError = () => {
    if (props.isModifierWithError && props.needScrollToError && props.setNeedScrollToError) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
      props.setNeedScrollToError(false);
    }
  };

  /**
   * Number of options, that user can add to this modifier
   * It includes modifiers.maximum and selected options quantity
   *
   * @readonly
   * @memberof ProductModifier
   */
  const optionsLeftToMaximum = useMemo((): number | null => {
    // For select any amount
    if (modifier.maximum === 0) return null;

    const numberOfSelectedOptions = modifier.options.reduce((sum: number, current) => {
      if (current.selected) sum = sum + current.quantity;

      return sum;
    }, 0);

    return modifier.maximum - numberOfSelectedOptions;
  }, [modifier]);

  const ModifierComponent = modifier.multi_choice ? MultiChoise : SignleChoise;

  return (
    <div ref={containerRef} className={props.className}>
      <ModifierComponent
        error={props.isModifierWithError}
        onChange={props.onModifierChange}
        modifier={modifier}
        leftToMaximum={optionsLeftToMaximum}
        readOnly={props.readOnly}
      />
    </div>
  );
};
