import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { userStore } from 'src/mobx/userStore';

@observer
export default class PrivateRoute extends Route<RouteProps> {
  public render() {
    if (!userStore.isLogin) return <Redirect to={SCREENS.MENU()} />;
    return <Route {...this.props} />;
  }
}
