import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react';
import { Component } from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { EVENTS } from 'src/constants/events';
import { defaultBranch, PAGE_FROM, WINDOW_SIZES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { requests } from 'src/requests';
import logger from 'src/services/logger';
import { IBranch, IBranchArea } from 'src/types/branch';
import { IPagination } from 'src/types/main';
import { getUserPosition } from 'src/utils/helpers';
import { debounce } from 'src/utils/perfomance';
import BranchSearchBox from 'src/view/screens/BranchesScreen/components/BranchSearchBox/BranchSearchBox';
import BranchDeliveryPopup from 'src/view/screens/BranchesScreen/containers/BranchPopup/BranchDeliveryPopup/BranchDeliveryPopup';
import BranchPickupPopup from 'src/view/screens/BranchesScreen/containers/BranchPopup/BranchPickupPopup/BranchPickupPopup';
import Modal from '../../../../components/Controls/Modal/Modal';
import ResponsiveContent from '../../../../components/ResponsiveContent/ResponsiveContent';
import branchesScreenStore from '../../../BranchesScreen/branchesScreenStore';
import BranchesMapControls from '../../../BranchesScreen/components/BranchesMapControls/BranchesMapControls';
import BranchesSubmitButton from '../../../BranchesScreen/components/BranchesSubmitButton/BranchesSubmitButton';
import './style.scss';
import { checkoutStore } from '../../../../../mobx/checkoutStore';
import { addressStore } from '../../../../../mobx/barnchStore/address';

declare global {
  interface Window {
    map: any;
  }
}

interface Props {
  delivery_type: string;
}
interface State {
  branch: IBranch | null;
  branches: IPagination<IBranch>;
  area: any | null;
  delivery_type: string;
  position: GeolocationPosition | null;
  deliveryCenter: { lat: number; lng: number };
  pickupCenter: { lat: number; lng: number };
  ready: boolean;
  loading: boolean;
  zoom: number;
  gettingUserPosition: boolean;
  permissionsAllowed: boolean;
  isLocationChanged: boolean;
}
// TODO: Refactor this class!
// separate methods
@observer
export default class SelectLocation extends Component<Props, State> {
  map: any = null;
  input: any = null;
  minZoom: number = 3;
  maxZoom: number = 20;
  state: State = {
    branch: null,
    branches: {
      current_page: 0,
      data: [],
      to: 0,
      total: 0,
      from: 0,
      last_page: 1,
    },
    area: null,
    position: null,
    delivery_type: this.props.delivery_type,
    deliveryCenter: {
      lat: checkoutStore.reorderStore?.area?.latitude || addressStore.address?.latitude || defaultBranch.latitude,
      lng: checkoutStore.reorderStore?.area?.longitude || addressStore.address?.longitude || defaultBranch.longitude,
    },
    pickupCenter: {
      lat: defaultBranch.latitude,
      lng: defaultBranch.longitude,
    },
    ready: false,
    loading: true,
    zoom: 12,

    gettingUserPosition: false,
    permissionsAllowed: !!(branchesScreenStore.address?.address && defaultBranch.latitude),
    isLocationChanged: false,
  };

  componentDidMount = () => {
    this.getUserPosition();
  };

  getUserPosition = async (goToMyLocation = false) => {
    this.setState(() => ({ gettingUserPosition: true }));
    const position = await getUserPosition();
    this.setState(() => ({
      gettingUserPosition: false,
      permissionsAllowed: !!position,
    }));

    logger.logEvent(EVENTS.USER_LOCATION_DETERMINED);

    const { delivery_type, deliveryCenter, pickupCenter } = this.state;
    let center: GoogleMapReact.Coords = delivery_type === DELIVERY_TYPES.DELIVERY ? deliveryCenter : pickupCenter;

    /** If no position (rejected permissions) and no saved address */
    if (!position && (!checkoutStore.reorderStore?.area?.latitude || !checkoutStore.reorderStore?.area?.longitude)) {
      center = { lat: defaultBranch.latitude, lng: defaultBranch.longitude };
      this.setState({ zoom: 5 });
    }

    /** If position exists and no saved address or click to MyLocation button */
    if (position || (position && goToMyLocation)) {
      center = { lat: position.coords.latitude, lng: position.coords.longitude };
    }

    this.setCenter(center, position);
  };

  setCenter = (
    center: GoogleMapReact.Coords = this.state.deliveryCenter,
    position: GeolocationPosition | null = this.state.position
  ) => {
    const isDelivery = this.state.delivery_type === DELIVERY_TYPES.DELIVERY;
    this.setState(
      {
        deliveryCenter: isDelivery ? center : this.state.deliveryCenter,
        pickupCenter: isDelivery ? this.state.pickupCenter : center,
        position,
      },
      this.onCenterWasChanged
    );
  };

  onCenterWasChanged = () => {
    if (this.state.delivery_type === DELIVERY_TYPES.DELIVERY) {
      this.fetchBranchInfo();
    } else if (this.state.delivery_type === DELIVERY_TYPES.PICKUP) {
      // TODO: FETCH MORE PAGES
      if (!this.state.branches.data.length) this.getBranchesList(1);
    }
  };

  onZoomLevelChanged = (increase: boolean) => {
    let zoom = increase ? this.state.zoom + 1 : this.state.zoom - 1;
    if (zoom < this.minZoom) zoom = this.minZoom;
    if (zoom > this.maxZoom) zoom = this.maxZoom;
    this.setState({ zoom });
  };

  onDragEnd = async ({ center }: { center: { lat: () => number; lng: () => number } }) => {
    if (!this.state.isLocationChanged) {
      setTimeout(() => {
        this.setState({ isLocationChanged: true });
      }, 500);
    }
    this.setCenter({ lat: center.lat(), lng: center.lng() }, null);
  };

  getBranchInfo = async () => {
    try {
      this.setState(() => ({ loading: true }));
      const { deliveryCenter } = this.state;
      const response = await requests.branches.branchSearch({
        latitude: deliveryCenter.lat,
        longitude: deliveryCenter.lng,
        delivery_type: DELIVERY_TYPES.DELIVERY,
      });

      let branch = null;
      let area = {
        address: ' ',
        latitude: deliveryCenter.lat,
        longitude: deliveryCenter.lng,
      };
      // if response was rejected or throw error
      if (!response) {
        this.setState({ branch, area, loading: false });
        return;
      }

      branch = response.branch;
      if (response.area) area = { ...area, ...response.area };

      if (!this.state.permissionsAllowed && !branch) {
        this.setState({
          branch,
          area: { ...area, address: '' },
          loading: false,
        });
        return;
      }

      this.setState({ branch, area, loading: false });

      // save coordinates if they have a branch and a zone
      if (response.branch && response.area?.id) {
        branchStore.setCoordinate({ latitude: deliveryCenter.lat, longitude: deliveryCenter.lng });
      }
    } catch (error: any) {
      this.setState({ branch: null, loading: false });
    }
  };

  getBranchesList = async (page = this.state.branches.current_page + 1) => {
    try {
      this.setState(() => ({ loading: true }));

      const response = await requests.branches.getBranches({
        page: page || this.state.branches.current_page + 1,
        delivery_type: DELIVERY_TYPES.PICKUP,
        latitude: this.state.position?.coords.latitude,
        longitude: this.state.position?.coords.longitude,
      });

      if (response) {
        let branch = response.data.find((branch: IBranch) => branch.id === branchStore.branch.id);
        if (!branch) branch = response.data[0];

        this.setState({
          branch,
          branches: {
            ...response,
            data: response.data.filter((branch: IBranch) => branch.working_hours && branch.working_hours.length > 0),
          },
          area: null,
          loading: false,
          pickupCenter: { lat: branch.latitude, lng: branch.longitude },
        });
      } else {
        this.setState({ branch: null, loading: false });
      }
    } catch (error: any) {
      this.setState({ branch: null, loading: false });
    }
  };

  fetchBranchInfo = debounce(this.getBranchInfo, 200);

  onSelectBranch = (branch: IBranch) => {
    this.setState({ branch, pickupCenter: { lat: branch.latitude, lng: branch.longitude } });
  };

  renderBranchPopup = (branch: IBranch) => (
    <BranchPickupPopup
      key={branch.id}
      onSelectBranch={this.onSelectBranch}
      selected={this.state.branch?.id}
      branch={branch}
      lat={branch.latitude}
      lng={branch.longitude}
    />
  );
  closeModal = () => {
    branchStore.closeBranches();
  };
  render() {
    const { branch, deliveryCenter, pickupCenter, delivery_type } = this.state;
    return (
      <Modal
        className="checkout-modal background-white"
        iconClassName="color-gray"
        defaultAnimation={''}
        isOpen={branchStore.isBranches}
        onRequestClose={this.closeModal}
        withPane
        fullScreen
      >
        <div className={`branches-modal d-flex flex-grow-1 full`}>
          <div id="delivery_map_full" className={`branches-map-wrapper d-flex flex-grow-1`}>
            <GoogleMapReact
              zoom={this.state.zoom}
              onGoogleApiLoaded={async () => {
                this.setState({ ready: true, zoom: this.map?.map_?.zoom ?? 5 });
              }}
              bootstrapURLKeys={{
                key: appStore.settings?.google_maps_pk || '',
                libraries: ['geometry'],
                language: localeStore.language,
              }}
              center={delivery_type === DELIVERY_TYPES.DELIVERY ? deliveryCenter : pickupCenter}
              ref={(map) => (this.map = map)}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals
              onDragEnd={this.onDragEnd}
              options={{
                fullscreenControl: false,
                gestureHandling: 'greedy',
                zoomControl: false,
                scrollwheel: true,
                maxZoom: this.maxZoom,
              }}
            >
              {this.state.ready && this.state.delivery_type === DELIVERY_TYPES.PICKUP
                ? this.state.branches.data.map(this.renderBranchPopup)
                : null}
            </GoogleMapReact>

            <BranchDeliveryPopup
              branch={branch}
              loading={this.state.loading}
              delivery_type={this.state.delivery_type}
              permissionsAllowed={this.state.permissionsAllowed || this.state.isLocationChanged}
              fromPage={PAGE_FROM.SMS}
            />

            <BranchesMapControls
              gettingUserPosition={this.state.gettingUserPosition}
              getUserPosition={this.getUserPosition}
              setCenter={this.setCenter}
              onZoomLevelChanged={this.onZoomLevelChanged}
            />
            <div
              className={`branches-top  d-flex flex-column flex-lg-row justify-content-between ${
                this.state.delivery_type === DELIVERY_TYPES.PICKUP ? 'active' : ''
              }`}
            >
              <BranchSearchBox address={branchesScreenStore.address?.address} setCenter={this.setCenter} />
            </div>

            <ResponsiveContent min={WINDOW_SIZES.md}>
              <BranchesSubmitButton
                loading={this.state.loading}
                branch={this.state.branch}
                area={this.state.area}
                delivery_type={this.state.delivery_type}
                id={'delivery_here'}
                fromPage={PAGE_FROM.SMS}
              />
            </ResponsiveContent>
          </div>
        </div>
      </Modal>
    );
  }
}
