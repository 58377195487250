import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IProps {
  property?: string;
  value?: string | number;
  id?: string;
}

export const DetailsProperty: React.FC<IProps> = ({ id, property, value }) => {
  if (!property && !value) {
    return (
      <div className="details-property ">
        <div className="color-dark family-semibold text-size-14">
          <Skeleton width={130} />
        </div>
        <div className="tcolor-dark family-semibold text-size-14">
          <Skeleton width={60} />
        </div>
      </div>
    );
  }
  return (
    <div className="details-property">
      <div className="color-dark family-semibold text-size-14">{property}</div>
      <div id={id} className="color-dark family-semibold text-size-14">
        {value}
      </div>
    </div>
  );
};
