import React, { ReactElement } from 'react';
import ModalMessage from '../../../components/ModalMessage/ModalMessage';
import PopupModal from '../../../components/Controls/Popup/Popup';
import { localeStore } from '../../../../mobx/localesStore';

interface ConfirmModalProps {
  children?: ReactElement;
  onSubmit: () => void;
  onCancel: () => void;
  visible: boolean;
}

const ConfirmModal = ({ visible, onCancel, children, onSubmit, ...rest }: ConfirmModalProps) => (
  <PopupModal
    style={{ minWidth: '300px', padding: '15px' }}
    visible={visible}
    mobile={{
      popup: true,
      sheet: true,
    }}
    backdrop
    position={'top'}
    direction={'down'}
    justify={'center'}
    selfClose={false}
    onClose={onCancel}
    disableDropdown={true}
    content={() => (
      <ModalMessage
        title={localeStore.t('Are you sure?')}
        message={localeStore.t('another_enquiry')}
        options={[
          {
            text: localeStore.t('txt_yes'),
            onPress: onSubmit,
          },
          {
            text: localeStore.t('txt_no'),
            className:
              'color-dark color_hover-darkgray background-light background_active-white th-bg-controls-primary  th-controls-secondary',
            textClassName: 'color-dark',
            onPress: onCancel,
          },
        ]}
      />
    )}
  >
    {children}
  </PopupModal>
);

export default ConfirmModal;
