import { appStore } from 'src/mobx/appStore';
import { cartStore } from 'src/mobx/cartStore';
import { IProduct } from 'src/types/products';

export const isAvailabilityToShowNotes = (product: IProduct | undefined, forCombo: boolean): boolean => {
  const isEnableNotes: boolean = Boolean(appStore.settings?.item_notes);
  const isEdit: boolean = Boolean(cartStore.isEditable && product?.hash);
  const notesAvailable: boolean = Boolean(product?.notes);
  const isCombo: boolean = Boolean(product?.type);

  const show: boolean = isEnableNotes && (!cartStore.isSMS || isEdit || notesAvailable);

  return forCombo ? show && isCombo : show;
};
