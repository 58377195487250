import { FC, ReactElement, memo, useMemo, useState } from 'react';
import { ICONS } from 'src/constants/icons';
import { LOCALES, WINDOW_SIZES } from 'src/constants/main';
import { staticTheme } from 'src/constants/staticTheme';
import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import Icon from 'src/view/components/Controls/Icon/Icon';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import Image from 'src/view/components/Image/Image';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import { DealComboItemModal } from '../DealComboItemModal/DealComboItemModal';
import './style.scss';

interface IProps {
  item: IProductInsideCombo;
  selected?: boolean;
  onSelectItem: (item: IProductInsideCombo) => void;
  className?: string;
}

export const DealComboItemMobile: FC<IProps> = memo(({ item, ...props }: IProps): ReactElement => {
  const [modal, setModal] = useState<boolean>(false);

  const isArabic: boolean = localeStore.language === LOCALES.AR;
  const units = productUtils.getComboItemUnits(item);
  const unavailableMessage: string | undefined = productUtils.getComboItemUnavailableMessage(item, false);

  const itemDescription = useMemo((): string | undefined => {
    if (!item.description) return undefined;
    return [...units, getTranslate(item.description)].filter(Boolean).join(' • ');
  }, [item, units]);

  const onSetModal = (state: boolean) => {
    setModal(state);
  };

  const onItemClick = () => {
    setModal(true);
  };

  const renderModalContent = ({ closeModal }: { closeModal: () => void }) => {
    const close = () => closeModal();

    return <DealComboItemModal item={item} onClickClose={close} />;
  };

  return (
    <ResponsiveContent max={WINDOW_SIZES.md}>
      <PopupModal
        justify="center"
        position="top"
        align="center"
        selfClose
        withCloseIcon
        content={renderModalContent}
        onClose={() => onSetModal(false)}
        mobile={{ popup: true, nonPane: true }}
        visible={modal}
        modalClassName="product-deal-item-mobile-modal"
        backdropClassName="product-deal-item-mobile__modal-backdrop"
      >
        <li className={`product-deal-item-mobile ${props.className || ''}`} onClick={onItemClick}>
          <Image
            aspectRatio={1.118}
            src={item.image}
            wrapperClassName={`product-deal-item-mobile__image-wrapper ${unavailableMessage ? ' opacity-50' : ''}`}
          />

          <div className="product-deal-item-mobile__text-wrapper">
            {unavailableMessage ? (
              <p className="product-deal-item-mobile__text-name color-red text-overflow">
                {localeStore.t(unavailableMessage)}
              </p>
            ) : (
              <p className="product-deal-item-mobile__text-name color-dark text-overflow text-nowrap">
                {getTranslate(item.name)}
              </p>
            )}

            {itemDescription ? (
              <p className="product-deal-item-mobile__text-description color-gray text-nowrap">{itemDescription}</p>
            ) : null}
          </div>

          <Icon
            name={isArabic ? ICONS.SLIDER_LEFT : ICONS.SLIDER_RIGHT}
            color={staticTheme.colors.color.gray}
            size={16}
          />
        </li>
      </PopupModal>
    </ResponsiveContent>
  );
});
