import { FC, ReactElement, memo } from 'react';
import { ordersStore } from 'src/mobx/ordersStore';
import { IOrderProductComboItem } from 'src/types/orders';
import { getTranslate } from 'src/utils/formatter';
import { OrderProductModifiers } from '../../containers/OrderProductModifiers/OrderProductModifiers';
import './style.scss';

interface IProps {
  item: IOrderProductComboItem;
  isMeal: boolean;
  productQuantity: number;
}

export const OrderProductItem: FC<IProps> = memo(({ item, productQuantity, isMeal }: IProps): ReactElement => {
  const price: string = ordersStore.formatPrice(Number(item.price) * productQuantity);

  return (
    <div className="order-product-item">
      <p className="order-product-item__header">
        <span className="order-product-item__name color-dark">{getTranslate(item.name)}</span>

        {isMeal ? <span className="order-product-item__price color-gray text-nowrap">{price}</span> : null}
      </p>

      <OrderProductModifiers modifiers={item.modifiers} productQuantity={productQuantity} />
      {/* TODO: combo exclusions */}
      {/* <OrderProductExclusions exclusions={item.exclusions} /> */}
    </div>
  );
});
