import React, { Component, RefObject } from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import { debounce, throttle } from 'src/utils/perfomance';
import Icon from '../Controls/Icon/Icon';
import './style.scss';

interface IProps {
  images: string[];
  delay?: number;
  duration?: number;
  aspectRatio?: number;
  sliderClassName?: string;
}

interface IState {
  active: number;
  offset: number;
}

export default class ImageSlider extends Component<IProps, IState> {
  aspectRatio: number = this.props.aspectRatio || 1.5;
  duration: number = this.props.duration || 800;
  delay: number = this.props.delay || 4000;
  timer: NodeJS.Timeout | null = null;
  slider: RefObject<HTMLDivElement> = React.createRef();

  state = {
    active: 0,
    offset: 0,
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.onResize);
    this.setState({ offset: this.slider.current?.clientWidth || 0 }, this.tick);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize);
  };

  handleResize = () => {
    this.setState({ offset: this.slider.current?.clientWidth || 0 });
  };

  onResize = debounce(this.handleResize, 100);

  tick = () => {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(this.nextImage, this.delay);
  };

  next = () => {
    if (this.timer) clearTimeout(this.timer);
    let active = this.state.active + 1;
    if (active > this.props.images.length - 1) active = 0;
    if (active < 0) active = this.props.images.length - 1;
    this.setState({ active }, this.tick);
  };
  nextImage = throttle(this.next, this.duration);

  prev = () => {
    if (this.timer) clearTimeout(this.timer);
    let active = this.state.active - 1;
    if (active > this.props.images.length - 1) active = 0;
    if (active < 0) active = this.props.images.length - 1;
    this.setState({ active }, this.tick);
  };
  prevImage = throttle(this.prev, this.duration);

  setActive = (index: number) => {
    if (this.timer) clearTimeout(this.timer);
    this.setState({ active: index }, this.tick);
  };

  renderOption = (image: string, index: number) => {
    return (
      <div
        key={index}
        className={`slider-option ${index === this.state.active ? 'active' : null}`}
        onClick={() => this.setActive(index)}
      ></div>
    );
  };

  renderImage = (image: string, index: number) => {
    return (
      <div
        key={index}
        className={`d-flex full-width full-height slider-image`}
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    );
  };

  render() {
    const disabled = !this.props.images || this.props.images.length <= 1;
    return (
      <div
        ref={this.slider}
        className={`slider d-flex flex-grow-1 ${this.props.sliderClassName || ''}`}
        style={{ height: this.state.offset / this.aspectRatio }}
      >
        <div
          className="slider-list  d-flex flex-grow-1"
          style={{
            transition: `${this.duration}ms`,
            transform:
              localeStore.language === 'ar'
                ? `translate3d(${this.state.active * this.state.offset}px, 0px, 0px)`
                : `translate3d(-${this.state.active * this.state.offset}px, 0px, 0px)`,
          }}
        >
          {this.props.images.map(this.renderImage)}
        </div>

        {disabled ? null : (
          <React.Fragment>
            <button className={'slider-button slider-prev'} onClick={this.prevImage}>
              <Icon name={ICONS.SLIDER_LEFT} className="color-white" />
            </button>
            <button className={'slider-button slider-next'} onClick={this.nextImage}>
              <Icon name={ICONS.SLIDER_RIGHT} className="color-white" />
            </button>
          </React.Fragment>
        )}

        {disabled ? null : (
          <div className="slider-bottom full-width d-flex">
            <div className="d-flex">{this.props.images.map(this.renderOption)}</div>
          </div>
        )}
      </div>
    );
  }
}
