import { observer } from 'mobx-react';
import { Component } from 'react';
import { IMessageModal, messagesStore } from 'src/mobx/messagesStore';
import MessagesPopup from 'src/view/components/MessagesPopup/MessagesPopup';

@observer
export default class ModalMessages extends Component<{}, {}> {
  state = {};

  renderMessage = (message: IMessageModal) => {
    return <MessagesPopup message={message} />;
  };

  render() {
    return <div>{messagesStore.messages.map(this.renderMessage)}</div>;
  }
}
