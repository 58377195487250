import { IProductInsideCombo } from 'src/types/combo';
import { isSameComboItemsPlace } from '../isSameComboItemsPlace/isSameComboItemsPlace';

export const isSameComboItems = (
  itemOne: IProductInsideCombo,
  itemTwo?: IProductInsideCombo,
  params?: {
    id: number;
    sizeId: number;
    choiceId: number;
  }
): boolean => {
  if (itemTwo) return itemOne.id === itemTwo.id && isSameComboItemsPlace(itemOne, itemTwo);
  if (params) return itemOne.id === params.id && isSameComboItemsPlace(itemOne, undefined, params);

  return false;
};
