import { PAYMENT_TYPES } from './main';

export enum ICONS {
  CART = 'cart',
  DELIVERY = 'delivery',
  MENU = 'menu',
  CLOSE = 'close',
  SHOPPING = 'close',
  PROFILE = 'profile',
  LOGOUT = 'logout',
  CALENDAR = 'calendar',
  LIST = 'list',
  CALL = 'call',
  PLUS = 'plus',
  MINUS = 'minus',
  CLOCK = 'clock',
  MARKER = 'marker',
  MARKER_FILLED = 'marker-filled',
  PICKUP = 'pickup',
  SEARCH = 'search',
  EDIT = 'edit',
  PENCIL = 'pencil',
  ARROW_RIGHT = 'arrow-right',
  ARROW_LEFT = 'arrow-left',
  CHECK = 'check',
  CHEVRON = 'chevron',
  VISA_CARD = 'visa_card',
  MASTER_CARD = 'master_card',
  CREDIT_CARD = 'credit_card',
  ARROW_RIGHT_FILLED = 'arrow-right_filled',
  TARGET = 'target',
  CASH = 'cash',
  MENU_GRID = 'menu-grid',
  PLATE = 'plate',
  BELL = 'bell',
  TRASH = 'trash',
  VISA = 'visa',
  CIRCLE = 'dry-clean',
  INFO = 'information',
  QUESTION = 'question',
  STAR = 'star',
  SLIDER_LEFT = 'slider-left',
  SLIDER_RIGHT = 'slider-right',
  SPINNER = 'spinner',
  TERMNAL = 'terminal',
  ONLINE_PAYMENY = 'online-payment',
  POINTS = 'points',
  MONEY = 'money',
  DANGER = 'danger',
  APPLE_PAY = 'a-pay',
  WALLET = 'wallet',
  MENU_TAB = 'menu-tab',
  MORE = 'more',
  ORDERS_TAB = 'orders-tab',
  WAITLIST_TAB = 'waitlist-tab',
  CONTACT_US = 'call',
  lOGOUT = 'logout',
  LOGIN = 'login',
  T = 'ic_title',
  LINES = 'ic_lines',
  SORT_ARROW_BOTTOM = 'sort-arrow',
  SORT_ARROW_TOP = 'sort-arrow-top',
  DINE_IN = 'dine_in',
  DINE_IN_OUTLINE = 'dine_in_outline',
  TO_GO = 'to_go',
  CALENDAR_TIME = 'calendar_time',
  CASHBACK = 'ic_cashback',
  MAP = 'map',
  WALLET_2 = 'wallet-2',
}

export const PAYMENT_TYPE_ICONS: {
  [key in PAYMENT_TYPES]: any;
} = {
  [PAYMENT_TYPES.CASH]: ICONS.CASH,
  [PAYMENT_TYPES.ONLINE_PAYMENT]: ICONS.CREDIT_CARD,
  [PAYMENT_TYPES.POINTS]: ICONS.POINTS,
  [PAYMENT_TYPES.TERMINAL]: ICONS.TERMNAL,
  [PAYMENT_TYPES.APPLE_PAY]: ICONS.APPLE_PAY,
};
