import { IProduct } from 'src/types/products';
import { getProductCalories } from '../getProductCalories/getProductCalories';
import { getProductUnit } from '../getProductUnit/getProductUnit';

/**
 * get list of product units
 */
export const getProductUnits = (product: IProduct | undefined): string[] => {
  if (!product) return [];
  return [getProductCalories(product), getProductUnit(product)].filter(Boolean) as string[];
};
