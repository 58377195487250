import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { appStore } from 'src/mobx/appStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import LoadingCircle from 'src/view/screens/CheckoutScreen/containers/LoadingCircle/LoadingCircle';
import './style.scss';

@observer
export default class MenuCart extends Component {
  state = {};

  render() {
    if (!appStore.settings?.is_restaurant_working) return null;
    if (!cartStore.products.length) return null;
    return (
      <div className="menu-cart text-center" onClick={cartStore.openCart}>
        {cartStore.calculator.left_to_min_order ? (
          <p className="my-2 color-dark text-size-12">
            {localeStore.t('minimum_order_alert_message')} {formatPrice(cartStore.calculator.min_order)}
          </p>
        ) : null}

        {cartStore.calculator.left_to_next_reduced_delivery_fee && cartStore.calculator.next_reduced_delivery_fee ? (
          <p className="my-2 color-dark text-size-12">
            {localeStore.t('txt_add_amount_till_rdf', {
              Amount: formatPrice(cartStore.calculator.left_to_next_reduced_delivery_fee),
              Deliveryfee: formatPrice(cartStore.calculator.next_reduced_delivery_fee),
            })}
          </p>
        ) : null}

        {cartStore.calculator.left_to_next_reduced_delivery_fee &&
        Number(cartStore.calculator.next_reduced_delivery_fee) === 0 ? (
          <p className="my-2 color-dark text-size-12">
            {localeStore.t('txt_add_amount_till_free_delivery', {
              Amount: formatPrice(cartStore.calculator.left_to_next_reduced_delivery_fee),
            })}
          </p>
        ) : null}

        <div className="menu-cart-control px-3 py-3 d-flex flex-row justify-content-between align-items-center background-primary">
          <p className="text-uppercase text-size-14  th-controls-secondary">
            {cartStore.products.length} {localeStore.t('txt_items')}
          </p>

          <LoadingCircle>
            <p className="text-uppercase text-size-14 th-controls-secondary">
              {formatPrice(cartStore.calculator.total)}
            </p>
          </LoadingCircle>
        </div>
      </div>
    );
  }
}
