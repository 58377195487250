import React, { Component } from 'react';
import './style.scss';
import { getTranslate } from '../../../../utils/formatter';
export interface IRadioSelectOption {
  name: string;
  value: string | undefined;
}
export interface IRadioSelectProps {
  options: IRadioSelectOption[];
  for?: string;
  value?: IRadioSelectOption['value'];
  onChange: (option: any) => void;
  optionClassName?: string;
  className?: string;
}
export default class RadioSelect extends Component<IRadioSelectProps> {
  for: string = this.props.for || Date.now().toString();
  state = {
    selected: this.props.value,
  };

  onPressOption = (option: IRadioSelectOption) => {
    this.setState({ selected: option.value });
    if (this.props.onChange) this.props.onChange(option);
  };

  renderOption = (option: IRadioSelectOption) => (
    <div key={option.value} className=" color-dark text-size-18 family-regular">
      <div
        key={option.value}
        onClick={() => this.onPressOption(option)}
        className={`radio-option d-flex flex-row align-items-center mr-2 ${
          this.state.selected === option.value ? 'active' : ''
        } ${this.props.optionClassName || ''}`}
      >
        <span className="radio-option-circle background_pseudo-primary mr-2"></span>
        <p className="radio-option-name">{getTranslate(option.name)}</p>
      </div>
    </div>
  );

  render() {
    return (
      <div className={`d-flex flex-row align-items-center ${this.props.className || ''}`}>
        {this.props.options.map(this.renderOption)}
      </div>
    );
  }
}
