import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { cartStore } from 'src/mobx/cartStore';
import Notes from 'src/view/components/Controls/Notes/Notes';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { ICONS } from 'src/constants/icons';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import { localeStore } from 'src/mobx/localesStore';
import TextArea from 'src/view/components/Controls/TextArea/TextArea';
import './style.scss';

interface State {
  value: string;
  modalVisible: boolean;
}

@observer
export default class CheckoutNotes extends Component<{}, State> {
  state = {
    value: cartStore.notes,
    modalVisible: false,
  };

  setModalVisible = (modalVisible: boolean) => () => {
    this.setState({ modalVisible });
    this.setState({ value: cartStore.notes });
  };

  /**
   * Set notes and save in cartStore
   * Method for Notes Component
   *
   * @param {string} notes
   * @memberof CheckoutNotes
   */
  onNotesChanged = (notes: string) => {
    this.setState({ value: notes });
    cartStore.setNotes(notes);
  };

  /**
   * Set notes if it changed
   *
   * @param {ChangeEvent<HTMLTextAreaElement>} event
   * @memberof CheckoutNotes
   */
  onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ value: event.target.value });
  };

  onClear = () => {
    cartStore.setNotes('');
    this.setState({ modalVisible: false, value: '' });
  };

  /**
   * Save notes in cartStore
   *
   * @memberof CheckoutNotes
   */
  onSubmit = () => {
    cartStore.setNotes(this.state.value);
    this.setState({ modalVisible: false });
  };

  /**
   * For render notes in Popup
   *
   * @memberof CheckoutNotes
   * @returns {Component} notes component
   */
  renderPopupContent = () => {
    const { value } = this.state;

    return (
      <>
        <div className="full-width px-2 pb-3">
          <div className="d-flex flex-row justify-content-between">
            <p className="text-size-24 family-bold mb-3">{localeStore.t('order_notes')}</p>
            {cartStore.isEditable ? <Icon name={ICONS.TRASH} onPress={this.onClear} /> : null}
          </div>
          <div className="full-width">
            {cartStore.isEditable ? (
              <>
                <TextArea
                  className="cart-notes-textarea mb-4"
                  value={value}
                  placeholder={localeStore.t('write_a_note')}
                  onChange={this.onChange}
                  disabled={!cartStore.isEditable}
                />
                <ButtonComponent
                  style={{ width: '100%' }}
                  title={localeStore.t('Submit')}
                  onClick={this.onSubmit}
                  disabled={!value}
                />
              </>
            ) : (
              <p className="text-size-16 color-dark mb-2">{value}</p>
            )}
          </div>
        </div>
      </>
    );
  };

  render() {
    const { value, modalVisible } = this.state;
    return (
      <div className="cart-notes">
        <div className="mb-4 d-none d-lg-flex">
          <Notes
            id={'cart_notes'}
            loading={false}
            notes={value}
            autosize
            onChange={this.onNotesChanged}
            disabled={!cartStore.isEditable}
          />
        </div>
        <div className="d-flex d-lg-none mb-1">
          <div className="flex-row cart-section align-items-center" onClick={this.setModalVisible(true)}>
            <div className="flex-column">
              <p className="text-size-16 family-semibold">{localeStore.t('order_notes')}</p>
              {cartStore.notes ? (
                <p className="text-size-14 color-gray flex-nowrap cart-notes-text ">{cartStore.notes}</p>
              ) : null}
            </div>
            <Icon name={ICONS.CHEVRON} className="back-arrow arrow-right" color={'#000000'} />
          </div>
          <PopupModal
            position={'top'}
            direction={'down'}
            justify={'center'}
            selfClose={false}
            backdrop
            visible={modalVisible}
            onClose={this.setModalVisible(false)}
            content={this.renderPopupContent}
            mobile={{
              popup: true,
              sheet: true,
            }}
          />
        </div>
      </div>
    );
  }
}
