import { observer } from 'mobx-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import IconButton from 'src/view/components/Controls/IconButton/IconButton';
import { localeStore } from 'src/mobx/localesStore';
import './style.scss';
import { IRoute } from '../MenuList/MenuList';
import logger from '../../../../../../../services/logger';
import { DESTINATION_LOGS, EVENTS } from '../../../../../../../constants/events';
import { userStore } from '../../../../../../../mobx/userStore';

@observer
class HamburgerMenuItem extends React.Component<IRoute> {
  private getTitle() {
    if (typeof this.props.text !== 'string') {
      return this.props.title || '';
    }

    return this.props.text;
  }

  private trackingNavigate = (event: any) => {
    if (this.props.to.includes('wallet')) {
      logger.logEvent(
        EVENTS.CHECK_WALLET_HISTORY,
        {
          wallet_amount: userStore.user.wallet?.balance,
          customer_id: userStore.user.id,
          expired_date: userStore.user.wallet?.expire_date ? new Date(userStore.user.wallet?.expire_date) : null,
        },
        DESTINATION_LOGS.MOENGAGE
      );
    }
  };

  public render() {
    return (
      <NavLink
        target={this.props.target}
        hrefLang={localeStore.language}
        title={this.getTitle()}
        rel="bookmark"
        to={{
          pathname: this.props.to,
          search: `?language=${localeStore.language}`,
        }}
        onClick={this.trackingNavigate}
        className="hamburger-menu-item background_hover-secondary th-bg-background_hover-secondary"
        activeClassName={'hamburger-menu-item_active'}
      >
        {this.props.icon ? (
          <IconButton iconClassName={'th-background-secondary th-selected_hover-primary'} name={this.props.icon} />
        ) : null}
        <p className={`th-color-primary d-flex flex-grow-1 ${this.props.icon ? 'ml-1' : 'ml-3'}`}>{this.props.text}</p>
      </NavLink>
    );
  }
}

export default HamburgerMenuItem;
