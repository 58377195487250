import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { cartStore } from '../../../../../../../mobx/cartStore';
import PopupModal from '../../../../../../components/Controls/Popup/Popup';
import { localeStore } from '../../../../../../../mobx/localesStore';
import BottomPanel from './components/BottomPanel';
import Location from './components/Location';
import './style.scss';

const CheckoutModalConfirmPickUp: FC = observer(() => {
  const closeModal = () => {
    cartStore.setModalValue('confirmPickUp', false);
  };

  const showDeliveryType = () => {
    return (
      <div
        dir="ltr"
        className="pt-3 d-flex flex-column full-height overflow-hidden confirm-pickup-modal background-white"
      >
        <p className="info-popup-title">{localeStore.t('sure_pickup')}</p>
        <Location />
        <BottomPanel />
      </div>
    );
  };

  return (
    <PopupModal
      position={'top'}
      direction={'down'}
      justify={'center'}
      selfClose={false}
      backdrop
      onClose={closeModal}
      visible={Boolean(cartStore.checkout.modals.confirmPickUp)}
      content={showDeliveryType}
      mobile={{
        popup: true,
        sheet: true,
      }}
    />
  );
});

export default CheckoutModalConfirmPickUp;
