import { DELIVERY_TYPE_NAMES } from 'src/constants/deliveryTypes';
import { EVENTS, TEvents } from 'src/constants/events';
import { PAYMENT_TYPES, PAYMENT_TYPE_NAMES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { productsStore } from 'src/mobx/productsStore';
import { IPaymentTypeId } from 'src/types/app';
import { Modify } from 'src/types/main';
import { IOrder } from 'src/types/orders';
import { IProduct } from 'src/types/products';
import { productUtils } from 'src/utils/productUtils';
import { localeStore } from '../../mobx/localesStore';
import { paymentTypes } from '../../constants/paymentTypes';

// Custom partner parameter for Firebase
export const GTM_EVENT_HANDLER = async (event: TEvents[keyof TEvents], data: any) => {
  const { calculateProductPrice } = productUtils;

  let parameters: any = {};

  const currency = appStore.settings?.currency_code || 'SAR';
  switch (event) {
    // view_item_lists
    case EVENTS.MENU_CATEGORY_CHANGED: {
      const { items, item_list_name, item_list_id } = data;
      const _items = items?.data.map((product: IProduct) => {
        return {
          item_id: `${product?.id}`,
          item_name: product?.name.en,
          item_category: item_list_name,
          item_list_id: String(item_list_id),
          item_list_name,
          price: calculateProductPrice(product),
          quantity: 1,
        };
      });
      parameters = {
        ...parameters,
        item_list_id: String(item_list_id),
        item_list_name,
        items: _items,
      };
      return parameters;
    }
    // select_item
    case EVENTS.MENU_PRODUCT_SELECTED: {
      const { product } = data;
      const category = productsStore.categories?.find((_category) => _category.id === product?.category_id);
      if (category) {
        parameters = {
          ...parameters,
          items: [
            {
              item_id: `${product.id}`,
              item_name: product.name.en,
              item_category: category.name?.en,
              item_list_id: `${category.id}`,
              item_list_name: category.name?.en,
              price: calculateProductPrice(product),
              quantity: 1,
            },
          ],
          item_list_id: `${category.id}`,
          item_list_name: category.name.en,
          content_type: 'product',
        };
        return parameters;
      }
      return parameters;
    }
    // view_item
    case EVENTS.PRODUCT_OPENED: {
      const { product } = data;
      const category = productsStore.categories?.find((_category) => _category.id === product?.category_id);
      if (category) {
        parameters = {
          ...parameters,
          items: [
            {
              item_id: `${product.id}`,
              item_name: product?.name.en,
              item_category: category?.name?.en,
              item_list_id: `${category?.id}`,
              item_list_name: category?.name?.en,
              price: calculateProductPrice(product),
              quantity: 1,
            },
          ],
          currency,
          value: calculateProductPrice(product),
        };
        return parameters;
      }
      break;
    }
    // add_to_cart
    case EVENTS.PRODUCT_SUBMITED_TO_CART: {
      const { product }: { product: IProduct } = data;
      const category = productsStore.categories?.find((_category) => _category.id === product.category_id);
      if (category) {
        parameters = {
          ...parameters,
          items: [
            {
              item_id: `${product.id}`,
              item_name: product.name.en,
              item_category: category.name.en,
              item_list_id: `${category.id}`,
              item_list_name: category.name.en,
              price: calculateProductPrice(product),
              quantity: product.quantity,
            },
          ],
          currency,
          value: calculateProductPrice(product),
        };
        return parameters;
      }
      break;
    }
    // view_cart
    case EVENTS.CART_OPENED: {
      const { items, total } = data;
      const _items = items.map((product: IProduct) => {
        const { id, name, price, category_id, quantity } = product;
        const category = productsStore.categories.find((item) => item.id === category_id);
        return {
          item_id: String(id),
          item_name: name.en,
          item_category: category?.name?.en,
          item_list_id: String(category_id),
          item_list_name: category?.name?.en,
          price,
          quantity,
        };
      });
      parameters = {
        ...parameters,
        items: _items,
        currency,
        value: total,
      };
      return parameters;
    }
    // remove_from_cart
    case EVENTS.CART_REMOVE_PRODUCT: {
      const _items = data?.map(({ name, price, id, quantity, category_id }: IProduct) => {
        const category = productsStore.categories.find((_category) => _category.id === category_id);
        return {
          item_id: String(id),
          item_name: name.en,
          item_category: category?.name?.en,
          item_list_id: String(category_id),
          item_list_name: category?.name?.en,
          price,
          quantity,
        };
      });
      parameters = {
        ...parameters,
        items: _items,
      };
      return parameters;
    }
    // begin_checkout
    case EVENTS.CHECKOUT_OPENED: {
      const { total, items } = data;
      const _items = items.map(({ name, price, id, quantity, category_id }: IProduct) => {
        const category = productsStore?.categories.find((_category) => _category?.id === category_id);
        return {
          item_id: String(id),
          item_name: name.en,
          item_category: category?.name?.en,
          item_list_id: String(category_id),
          item_list_name: category?.name?.en,
          price,
          quantity,
        };
      });
      parameters = {
        ...parameters,
        items: _items,
        currency,
        value: total,
      };
      return parameters;
    }
    // add_shipping_info
    case EVENTS.DTP_ADDRESS_CONFIRMED: {
      const { delivery_type }: { delivery_type: keyof typeof DELIVERY_TYPE_NAMES } = data;
      parameters = { ...parameters, order_type: DELIVERY_TYPE_NAMES[delivery_type] };
      return parameters;
    }
    // change_payment_method
    case EVENTS.CHECKOUT_PAYMENT_TYPE_CHANGED: {
      const { method_to, method_from }: { method_to: IPaymentTypeId; method_from: IPaymentTypeId } = data;
      parameters = {
        ...parameters,
        method_to: PAYMENT_TYPE_NAMES[method_to],
        method_from: PAYMENT_TYPE_NAMES[method_from],
      };
      return parameters;
    }
    // login
    case EVENTS.AUTH_LOGGED_IN: {
      return parameters;
    }
    // order_cancel
    // case EVENTS.ORDER_CANCELLED:
    // purchase
    case EVENTS.CHECKOUT_ORDER_CREATED: {
      const { id, products, total, vat, promocode, delivery_fee, payment_type, branch_name, branch_id }: any = data;
      const items = products.map(({ name, price, id, quantity, category_id }: IProduct) => {
        const category = productsStore?.categories.find((_category) => _category?.id === category_id);
        return {
          item_id: String(id),
          item_name: name.en,
          item_category: category?.name?.en,
          item_list_id: String(category_id),
          item_list_name: category?.name?.en,
          price,
          quantity,
        };
      });
      parameters = {
        ...parameters,
        items,
        value: total,
        currency,
        transaction_id: String(id),
        branch_id: String(branch_id),
        order_key: String(id),
        branch_name: localeStore.t(branch_name),
        payment_method: localeStore.t(paymentTypes[payment_type as IPaymentTypeId]),
        tax: vat,
        shipping: delivery_fee,
      };
      if (promocode) parameters.coupon = promocode;
      return parameters;
    }
    // search
    case EVENTS.MENU_SEARCH_PRODUCT: {
      const { search_term, num_results_found }: { search_term: string; num_results_found: number } = data;
      parameters = { ...parameters, search_term, num_results_found };
      return parameters;
    }
    // first_purchase
    case EVENTS.CHECKOUT_FIRST_ORDER: {
      const {
        id,
        total,
        delivery_type,
        payment_type,
        branch,
      }: Modify<IOrder, { delivery_type: keyof typeof DELIVERY_TYPE_NAMES }> = data;
      parameters = {
        ...parameters,
        first_purchase_value: total,
        payment_type: PAYMENT_TYPES[payment_type],
        order_type: DELIVERY_TYPE_NAMES[delivery_type],
        currency,
        transaction_id: String(id),
        branch_id: branch.id,
        branch_name: branch.name || 'na',
      };
      return parameters;
    }
    // purchase_failed
    case EVENTS.CHECKOUT_ORDER_ERROR: {
      const { message }: { message: string } = data;
      parameters = { ...parameters, failure_message: message };
      return parameters;
    }
    default: {
      parameters = { ...parameters };
      return parameters;
    }
  }
};
