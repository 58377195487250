import { IProductInsideCombo } from 'src/types/combo';
import { TTranslate } from 'src/types/main';
import {
  IProduct,
  IProductExclusion,
  IProductModifier,
  IProductModifierOption,
  IProductSize,
  IResponseProduct,
  IResponseProductExclusion,
  IResponseProductModifier,
  IResponseProductModifierOption,
  IResponseProductSize,
  PRODUCT_TYPE,
} from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { getFirstCorrectMealSize, isCorrectMealSize, isSameComboItemsSizeGroup } from '../combo';

export const transformProduct = (product: IResponseProduct): IProduct => {
  const sizes: IResponseProductSize[] = _getSizes(product);
  const isMealCombo: boolean = product.type === PRODUCT_TYPE.MEAL_COMBO;

  return {
    ...product,
    name: typeof product.name === 'string' ? { en: product.name, ar: product.name } : product.name,
    //  select prevision selected size OR select first size OR leave empty
    images: product.images ? [product.image, ...product.images] : [product.image],
    hash: '',
    notes: product.notes || '',
    quantity: _getProductQuantity(product),
    size: _transformSize(product, sizes, isMealCombo),
    sizes,
    modifiers: product.modifiers.map((modifier) => _transformModifier(modifier)),
    exclusions: product.exclusions.map((exclusion) => _transformExclusion(exclusion)),
    items: _transformComboItems(product, isMealCombo),
  };
};

const _getSizes = (product: IResponseProduct): IResponseProductSize[] => {
  /**
   * Request for categories with products does not have "is_size" key,
   * either sizes are enabled and the response contains an array, or they are disabled and the array is empty.
   * Request for a concretic product has an array of sizes whether they are included or not.
   */
  if (!product.sizes?.length || product.is_size === 0) return [];
  return product.sizes.map((size) => ({ ...size, name: _toStringValue(size.name) }));
};

const _getProductQuantity = (product: IProduct | IResponseProduct): number => {
  const { quantity, min_quantity } = product;

  if (quantity && min_quantity && quantity > min_quantity) return quantity;
  if (min_quantity && min_quantity > 0) return min_quantity;
  return 1;
};

const _toStringValue = (name: TTranslate | string): string => {
  if (!name) return '';
  return typeof name === 'string' ? name : getTranslate(name);
};

const _transformSize = (
  product: IResponseProduct,
  sizes: IResponseProductSize[],
  isMealCombo: boolean
): IProductSize | null => {
  if (isMealCombo) {
    // if size is selected and it is correct
    const correctSize = sizes.find((size) => size.is_selected && isCorrectMealSize(product.items, size));
    if (correctSize) return correctSize;

    // take the first correct size
    const firstCorrectSize: IProductSize | null = getFirstCorrectMealSize(product);

    // transform size name
    return firstCorrectSize ? { ...firstCorrectSize, name: _toStringValue(firstCorrectSize.name) } : null;
  }

  return sizes.find((size) => size.is_selected) || sizes[0] || null;
};

const _transformModifier = (modifier: IResponseProductModifier, ignoreDefaultModifiers?: boolean): IProductModifier => {
  //  tslint:disable-next-line
  let { options, isPreselected } = _transformModifierOption(modifier.options, Boolean(modifier.unique_options));

  const _modifier = {
    ...modifier,
    name: _toStringValue(modifier.name),
    options,
  };

  /** When modifier type changes from multi to single */
  if (!_modifier.multi_choice && _modifier.options.filter((option) => option.selected).length > 1) {
    isPreselected = false;
    _modifier.options = _modifier.options.map((option) => ({ ...option, selected: false, quantity: 1 }));
  }

  // do not select default options if it was preselected (FOR REORDER)
  if (isPreselected) return _modifier;

  // Ignore default modifier
  if (ignoreDefaultModifiers) {
    modifier.options = modifier.options.map((option) => ({ ...option, selected: false }));
    return modifier;
  }

  // is single choice
  if (!_modifier.multi_choice) {
    // find default option
    const defaultOptionId = _modifier.options.findIndex((option) => option.is_default);

    if (~defaultOptionId) {
      // select default option
      _modifier.options[defaultOptionId].selected = true;
    } else if (_modifier.options[0]) {
      // select first option
      _modifier.options[0].selected = true;
    }
  } else {
    // if multi choice
    _modifier.options = _modifier.options.map((option) => ({ ...option, selected: Boolean(option.is_default) }));
  }

  return _modifier;
};

const _transformModifierOption = (
  options: IResponseProductModifierOption[],
  uniqueOptions: boolean
): { options: IProductModifierOption[]; isPreselected: boolean } => {
  let isPreselected: boolean = false;

  const _options = options.map((option): IResponseProductModifierOption => {
    if (option.is_selected) isPreselected = true;

    return {
      ...option,
      name: _toStringValue(option.name),
      selected: Boolean(option.is_selected),
      quantity: _getModifierOptionQuantity(option, uniqueOptions),
      maximum: option.maximum || 99, // 0 = unlimited
      minimum: option.minimum || 1,
    };
  });

  return { options: _options, isPreselected };
};

const _getModifierOptionQuantity = (option: IResponseProductModifierOption, uniqueOptions: boolean): number => {
  const { quantity, minimum, maximum } = option;

  if (uniqueOptions) return 1;
  if (maximum && quantity > maximum) return maximum;
  if (quantity && minimum && quantity > minimum) return quantity;
  if (minimum) return minimum;

  return typeof quantity === 'number' && quantity > 0 ? quantity : 1;
};

const _transformExclusion = (exclusion: IResponseProductExclusion): IProductExclusion => {
  return {
    ...exclusion,
    name: _toStringValue(exclusion.name),
    selected: Boolean(exclusion.is_selected),
  };
};

const _transformComboItems = (product: IResponseProduct, isMealCombo: boolean): IProductInsideCombo[] => {
  if (!product.items?.length) return [];

  if (isMealCombo) {
    return product.items.map((item) => {
      item = {
        ...item,
        modifiers: item.modifiers?.map((modifier) => _transformModifier(modifier)),
        // TODO: combo exclusions
        // exclusions: item.exclusions?.map((exclusion) => _transformExclusion(exclusion)),
      };

      // if selected, not change anything
      if (item.is_selected) return item;

      // if default, then check  presence of those selected in its size and group
      if (item.is_default) {
        // whether selected item is in size and group of current item
        const selectedItem: boolean = Boolean(
          product.items?.find((_item) => isSameComboItemsSizeGroup(item, _item) && _item.is_selected)
        );

        return selectedItem ? item : { ...item, is_selected: item.is_default };
      }

      return item;
    });
  }

  return product.items.map((item, index) => (index ? { ...item, is_selected: 0 } : { ...item, is_selected: 1 }));
};
