import { FC, ReactElement, memo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import './style.scss';

interface IProps {
  disabled: boolean;
  onSubmit: () => void;
}

export const Submit: FC<IProps> = memo((props: IProps): ReactElement => {
  return (
    <div className="product-meal-combo-group-mobile-item-modal__submit">
      <ButtonComponent title={localeStore.t('Add')} onClick={props.onSubmit} disabled={props.disabled} fullSize />
    </div>
  );
});
