import { action, computed, observable } from 'mobx';
import { ordersStore } from '../../../../../mobx/ordersStore';
import moment from 'moment';

class DeliveryStatusStore {
  @observable timeOut: boolean = false;

  // show loading if the data has not yet been updated
  @computed get loading() {
    if (!ordersStore.order?.dnf?.cutoff_at) {
      return true;
    }
    return false;
  }

  @action init = () => {
    const serverDate: moment.Moment = moment.utc(ordersStore.order?.dnf?.cutoff_at);
    const localDate: moment.LocaleSpecifier = moment(serverDate).utc().local().format();
    if (moment().isAfter(localDate)) {
      this.timeOut = true;
    }
  };
  @action setTimeOut = (status: boolean) => {
    this.timeOut = status;
  };
}
export const deliveryStatusStore = new DeliveryStatusStore();
