import { observer } from 'mobx-react';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import AboutScreen from '../AboutScreen/AboutScreen';
import ContactsScreen from '../ContactsScreen/ContactsScreen';
import FaqScreen from '../FaqScreen/FaqScreen';
import PrivacyScreen from '../PrivacyScreen/PrivacyScreen';
import TermsScreen from '../TermsScreen/TermsScreen';
import Tabs from './containers/Tabs/Tabs';

@observer
export default class StaticScreen extends React.Component<RouteComponentProps> {
  public render() {
    return (
      <div className="container">
        <div className="d-flex flex-column flex-grow-1">
          <Tabs />
          <Switch>
            <Route path={SCREENS.ABOUT()}>
              <AboutScreen />
            </Route>
            <Route path={SCREENS.FAQ()}>
              <FaqScreen />
            </Route>
            <Route exact path={SCREENS.TERMS()}>
              <TermsScreen />
            </Route>
            <Route exact path={SCREENS.PRIVACY()}>
              <PrivacyScreen />
            </Route>
            <Route path={SCREENS.CONTACTS()}>
              <ContactsScreen />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}
