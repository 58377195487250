import { observer } from 'mobx-react';
import * as React from 'react';
import { ICONS } from 'src/constants/icons';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import client from 'src/services/client';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Image from 'src/view/components/Image/Image';

import './style.scss';

interface IState {
  ready: boolean;
  loading: boolean;
  data: null | {
    icon: string;
    name: string;
  };
}

@observer
export default class SmartBanner extends React.Component<{}, IState> {
  regexp = /apps.apple.com|play.google.com/g;

  get platform(): string {
    const agent = navigator.userAgent.toLowerCase();
    if (~agent.indexOf('android')) {
      return 'Android';
    } else if (~agent.indexOf('iphone')) {
      return 'iPhone';
    }
    return '';
  }

  isIOS = this.platform === 'iPhone';
  isAndroid = this.platform === 'Android';

  links: {
    [key: string]: string | undefined;
  } = {
    Android: appStore.settings?.app_android,
    iPhone: appStore.settings?.app_ios,
  };

  state: IState = {
    ready: false,
    loading: false,
    data: null,
  };

  componentDidMount = () => {
    if (!this.links[this.platform] || !appStore.smartBanner || !this.regexp.test(this.links[this.platform] || ''))
      return;
    if (!window.IS_MOBILE) {
      this.getDefaultData();
    }
  };

  getDefaultData = () => {
    if (!appStore.settings) return;
    document.body.style.paddingTop = '65px';
    this.setState({
      data: {
        icon: appStore.settings.img_logo,
        name:
          localeStore.language === 'en' ? appStore.settings?.restaurant_name_en : appStore.settings.restaurant_name_ar,
      },
      ready: true,
    });
  };

  closeBanner = () => {
    appStore.closeSmartBanner();
    document.body.style.paddingTop = '0';
  };

  public render() {
    if (!localeStore.timestamp || !this.state.ready || !this.state.data || !appStore.smartBanner || window.IS_MOBILE)
      return null;
    return (
      <div className="d-flex smart-banner-wrapper">
        <div className="smart-banner full-width d-flex align-items-center justify-content-between  background-light px-3">
          <div className="d-flex align-items-center">
            <Icon onPress={this.closeBanner} className="mr-4" name={ICONS.CLOSE} size={8} />
            <Image
              wrapperClassName="smart-banner-icon mr-3"
              aspectRatio={1}
              src={this.state.data.icon}
              height={50}
              width={50}
            />
            <p className="text">{localeStore.t('download_app', { application_name: this.state.data.name })}</p>
          </div>

          <a target="_blank" href={this.links[this.platform]}>
            <div className="smart-banner-button px-3 py-2 background_pseudo-primary ">
              <p className="text-size-12 color-primary">{localeStore.t('open_button_txt')}</p>
            </div>
          </a>
        </div>
      </div>
    );
  }
}
