import React from 'react';
import { cartStore } from 'src/mobx/cartStore';
import { IProduct, IProductExclusion, IProductModifier } from 'src/types/products';
import Exclusions from 'src/view/components/Controls/Exclusions/Exclusions';
import { ProductModifier } from '../../components/ProductModifier/ProductModifier';
import ProductNotes from '../../components/ProductNotes/ProdutcNotes';
import { isAvailabilityToShowNotes } from '../../helpers/productScreen';

interface IProps {
  product?: IProduct;
  errors: string[];
  needScrollToError: boolean;
  onModifierChange: (modifier: IProductModifier) => void;
  setNeedScrollToError: (need: boolean) => void;
  onExclusionsChange: (exclusions: IProductExclusion[]) => void;
  onNotesChanged: (notes: string) => void;
  readonly?: boolean;
}

export default class ProductSelectorsBlock extends React.Component<IProps, {}> {
  render() {
    const { product, readonly } = this.props;
    const showNotes: boolean = isAvailabilityToShowNotes(product, false);

    return (
      <div className="product-block d-flex flex-column product-submit-block product-block-padding">
        {product?.modifiers.length || product?.exclusions.length ? (
          <div className="product-selectors hide-scrollbar">
            <div className="product-scroll">
              {/* Modifiers */}
              {product.modifiers.map((modifier) => (
                <div id={`modifier_${modifier.id}`} key={modifier.id} className="mb-4">
                  <ProductModifier
                    modifier={modifier}
                    isModifierWithError={this.props.errors.includes(String(modifier.id))}
                    needScrollToError={this.props.needScrollToError}
                    readOnly={readonly}
                    onModifierChange={this.props.onModifierChange}
                    setNeedScrollToError={this.props.setNeedScrollToError}
                  />
                </div>
              ))}
              {/* Exclusions */}
              {Array.isArray(product.exclusions) && product.exclusions.length ? (
                <Exclusions
                  exclusions={product.exclusions}
                  className="mb-4"
                  onChange={this.props.onExclusionsChange}
                  readOnly={!cartStore.isEditable || (cartStore.isSMS && !product.hash)}
                />
              ) : null}
            </div>
          </div>
        ) : null}
        {showNotes ? (
          <ProductNotes
            loading={!product}
            onChange={this.props.onNotesChanged}
            value={product?.notes || ''}
            disabled={!cartStore.isEditable}
          />
        ) : null}
      </div>
    );
  }
}
