import axios, { Canceler } from 'axios';
import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { DESTINATION_LOGS, EVENTS } from 'src/constants/events';
import { E_MENU_TYPE } from 'src/constants/main';
import { _transformCategory, requests, transformCategoryPagination } from 'src/requests';
import client from 'src/services/client';
import logger from 'src/services/logger';
import { IBranch } from 'src/types/branch';
import { IOrder } from 'src/types/orders';
import { logError } from 'src/utils/helpers';
import { ICategory } from '../../types/products';
import { branchStore } from '../barnchStore';
import { preorderStore } from '../preorderStore';
import { userStore } from '../userStore';
import { appStore } from '../appStore';

type TCacheParameters = {
  delivery_type?: IOrder['delivery_type_code'];
  branch_id?: IBranch['id'];
  time?: number;
  global_category_id?: number;
  menu_update?: number;
  is_login?: boolean;
  is_donation?: 0 | 1;
};

const paramsChanged = (params1: any, params2: any) => {
  return (
    params1.is_login !== params2.is_login ||
    params1.time !== params2.time ||
    params1.delivery_type !== params2.delivery_type ||
    params1.branch_id !== params2.branch_id ||
    params1.is_donation !== params2.is_donation
  );
};

export class ProductsStore {
  @observable public loading: boolean = false;
  // @persist
  @persist @observable public categoryId: ICategory['id'] | undefined = undefined;
  @persist('list') @observable public categories: ICategory[] = [];
  @observable public searchIsActive: boolean = false;
  @observable public searchIsLoading: boolean = false;
  @observable public searchedCategories: ICategory[] | null = null;
  @persist('object') public cacheParameters: TCacheParameters = {
    menu_update: 0,
    global_category_id: 0,
  };
  @persist('object') @observable private _prevParams: {} = {
    is_login: false,
    time: 0,
    branch_id: 0,
    delivery_type: DELIVERY_TYPES.DELIVERY,
    is_donation: 1,
  };

  @computed get category(): ICategory | null {
    if (!this.categoryId) return null;
    const categoryIdx = this.categories.findIndex((category) => category.id === this.categoryId);
    if (categoryIdx === -1) {
      this.setCategory(this.categories[0].id);
    }
    return this.categories[categoryIdx];
  }

  private stack: {
    [key: string]: Canceler;
  } = {};

  public fetchMenu = async () => {
    try {
      this.loading = true;

      // cancel all request if new one are called
      if (this.stack) Object.values(this.stack).map((canceller) => canceller('Cancelled'));

      const params: { [key: string]: any } = {
        is_login: userStore.isLogin,
        delivery_type: branchStore.delivery_type || DELIVERY_TYPES.DELIVERY,
        is_donation: 1,
      };

      if (appStore.mode === 'e-menu' && appStore?.settings?.donation?.enabled) {
        params.is_donation = 0;
      }

      if (branchStore.branch.id) {
        params.branch_id = branchStore.branch.id;
      }

      if (branchStore.order_type === E_MENU_TYPE.DINE_IN) {
        params.delivery_type = DELIVERY_TYPES.DINE_IN;
      }

      if (preorderStore.preorder_unix) {
        params.time = preorderStore.preorder_unix;
      }

      // if any parameter not was changed need to make silent update
      // if menu_update or global_category_id was changed on server side api will return new menu
      // in other cases api will return empty menu
      const response = await client.get('/v4/categories/products', {
        params: {
          ...params,
          menu_update: paramsChanged(params, this._prevParams) ? 0 : this.cacheParameters.menu_update,
          global_category_id: this.cacheParameters.global_category_id || undefined,
        },
        headers: { 'Accept-Language': 'en,ar' },
        cancelToken: new axios.CancelToken((c) => (this.stack.root = c)),
      });
      const { data: categories, global_category_id, menu_update } = response;

      // if menu not changed
      if (
        menu_update === this.cacheParameters.menu_update &&
        global_category_id === this.cacheParameters.global_category_id &&
        !paramsChanged(params, this._prevParams)
      ) {
        this.loading = false;
        return;
      }

      this._prevParams = {
        is_login: params.is_login,
        time: params.time,
        branch_id: params.branch_id,
        delivery_type: params.delivery_type,
        is_donation: params.is_donation,
      };

      this.categories = categories.map(_transformCategory);
      this.loading = false;

      const categoryIdx = this.categories.findIndex((category) => category.id === this.categoryId);
      if (categoryIdx === -1) {
        this.categoryId = undefined;
      }

      if (!this.categoryId && this.categories.length) await this.setCategory(this.categories[0].id);

      // Silent loading of additional products
      const additionalProductsForCategory = []; // array of promises
      for (const category of this.categories) {
        if (category.items.current_page >= category.items.last_page) continue;
        additionalProductsForCategory.push(this.additionalProductsForCategory(category, this.cacheParameters));
      }

      await Promise.allSettled(additionalProductsForCategory);

      this.cacheParameters = {
        menu_update,
        global_category_id,
      };

      const content_items = this.category?.items.data.map((i) => i.name).join();
      const af_content_list = this.category?.items.data.map((i) => i.id);

      logger.logEvent(EVENTS.MENU_SHOW, {
        af_content_type: this.category?.name,
        af_content_list,
        content_items,
      });
    } catch (error: any) {
      console.error(error);
      if (error.message === 'Cancelled') {
        return;
      }
      // logger.logEvent(EVENTS.SENTRY_ERROR_FETCHING_MENU, {}, error);
    } finally {
      this.loading = false;
    }
  };

  additionalProductsForCategory = async (category: ICategory, _cacheParameters: TCacheParameters): Promise<void> => {
    try {
      let currentCategory: ICategory | undefined = undefined;
      const per_page = 10;
      if (category.items.current_page >= category.items.last_page) return;

      const params: { [key: string]: any } = {
        ..._cacheParameters,
        delivery_type: branchStore.delivery_type || DELIVERY_TYPES.DELIVERY,
        is_login: userStore.isLogin,
        per_page,
        page: category.items.current_page + 1,
        is_donation: 1,
      };

      if (appStore.mode === 'e-menu' && appStore?.settings?.donation?.enabled) {
        params.is_donation = 0;
      }

      if (branchStore.branch.id) {
        params.branch_id = branchStore.branch.id;
      }

      if (branchStore.order_type === E_MENU_TYPE.DINE_IN) {
        params.delivery_type = DELIVERY_TYPES.DINE_IN;
      }

      if (preorderStore.preorder_unix) {
        params.time = preorderStore.preorder_unix;
      }

      const response: ICategory['items'] = await client.get(`/v4/categories/${category.id}/products`, {
        params,
        headers: { 'Accept-Language': 'en,ar' },
        cancelToken: new axios.CancelToken((c) => (this.stack[category.id] = c)),
      });
      delete this.stack[category.id];

      // update category items in the list
      this.categories = this.categories.map((_category) => {
        if (_category.id === category.id) {
          currentCategory = {
            ..._category,
            items: {
              ..._category.items,
              ...response,
              data: [..._category.items.data, ...transformCategoryPagination(response).data],
            },
          };
          return currentCategory;
        }
        return _category;
      });
      // Do not fetch next page is data length is less than per_page
      if (response.data.length < per_page || !currentCategory) return;

      await this.additionalProductsForCategory(currentCategory, params);
    } catch (error: any) {
      // console.log('FETCH MENU CATEGORIES ERROR', category, category.items.current_page + 1);
      if (error.message === 'Cancelled') {
        return;
      }

      // logger.logEvent(EVENTS.SENTRY_ERROR_FETCHING_MENU_PART, {}, error);
    }
  };

  // actions
  @action public setCategory = async (id: ICategory['id']) => {
    try {
      const categoryIdx = this.categories.findIndex((category) => category.id === id);
      if (~categoryIdx) this.categoryId = this.categories[categoryIdx].id;
    } catch (error: any) {
      logError(error);
    }
  };

  @action public toggleSearchIsActive = () => {
    this.searchIsActive = !this.searchIsActive;
    if (!this.searchIsActive) {
      this.searchIsLoading = false;
      this.searchedCategories = null;
    }
  };

  searchingTimeout: any = null;
  @action public searchMenuItems = (value: string) => {
    if (this.searchingTimeout) clearTimeout(this.searchingTimeout);

    // 750ms debounce
    this.searchingTimeout = setTimeout(async () => {
      try {
        if (!value || value.length <= 1) {
          return (this.searchedCategories = null);
        }

        this.searchIsLoading = true;
        const { categories } = await requests.fetchMenu(value);

        if (this.searchIsActive) this.searchedCategories = categories;

        this.searchIsLoading = false;

        const num_results_found = categories.reduce(
          (sum: number, category: any) => sum + category.items.data.length,
          0
        );
        logger.logEvent(EVENTS.MENU_SEARCH_PRODUCT, { search_term: value, num_results_found });

        logger.logEvent(
          EVENTS.SEARCH,
          {
            search_term: value,
            num_results_found,
          },
          DESTINATION_LOGS.MOENGAGE
        );
      } catch (error: any) {
        logError(error);
        this.searchIsLoading = false;
      }
    }, 750);
  };
}

const productsStore = new ProductsStore();

export { productsStore };
