import { observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import ScreenWrapper from 'src/view/containers/ScreenWrapper/ScreenWrapper';
import Modal from '../../components/Controls/Modal/Modal';
import SmsPagePromocodeInfo from '../../components/SmsPagePromocodeInfo/SmsPagePromocodeInfo';
import CartInfoPopup from './components/CartInfoPopup/CartInfoPopup';
import CartNotes from './components/CartNotes/CartNotes';
import CartSubmit from './components/CartSubmit/CartSubmit';
import CartTotal from './components/CartTotal/CartTotal';
import CartTotalInfo from './components/CartTotalInfo/CartTotalInfo';
import { CartProducts } from './containers/CartProducts/CartProducts';
import ClearCart from './containers/ClearCart/ClearCart';
import CrossSelling from './containers/CrossSelling/CrossSelling';
import './style.scss';

@observer
class CartScreen extends Component<RouteComponentProps> {
  state = {
    isOpen: true,
  };

  closeModal = () => {
    if (!cartStore.isSMS) cartStore.closeCart();
  };

  clearCart = () => {
    cartStore.clear(true);
    if (cartStore.isSMS) {
      cartStore.isSMSPage(false);
      this.props.history.push(SCREENS.ROOT());
    }
    this.closeModal();
    if (cartStore.isSMS) {
      cartStore.isSMSPage(false);
      this.props.history.push(SCREENS.MENU());
    }
  };

  render() {
    const showNotes = appStore.settings?.order_notes && (cartStore.isEditable || cartStore.notes);
    const showClearCart = cartStore.isEditable;
    return (
      <ScreenWrapper>
        <Modal
          className="cart-modal animation"
          defaultAnimation={''}
          iconClassName="color-gray"
          isOpen={cartStore.isCart}
          onRequestClose={this.closeModal}
          withCloseIcon={!cartStore.isSMS}
          withPane
        >
          <div className="cart cart-wrapper d-flex flex-column justify-content-between full-height full-width ">
            <div className="cart-container d-flex flex-column justify-content-between full-height full-width mb-n4">
              <div className="cart-section border-r-0 d-flex flex-column pb-2">
                <div className="cart-header d-flex flex-row align-items-center justify-content-center justify-content-lg-between">
                  <h1 className="family-bold color-dark text-left">{localeStore.t('txt_cart')}</h1>
                  {showClearCart ? <ClearCart clearCart={this.clearCart} /> : null}
                </div>
              </div>

              <div className="cart-scroll hide-scrollbar pb-4">
                <div className="cart-content">
                  <CartProducts />

                  {appStore.settings?.is_upsales ? <CrossSelling className="cart-section mb-1" /> : null}

                  <CartInfoPopup />

                  {showNotes ? <CartNotes /> : null}

                  <CartTotalInfo />
                  {/* *
                   * show a promo code message on the sms page if the user is not logged in
                   * and the call center has applied the promo code to the order
                   */}
                  <SmsPagePromocodeInfo />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row cart-total">
              <CartTotal />
              <CartSubmit />
            </div>
          </div>
        </Modal>
      </ScreenWrapper>
    );
  }
}
export default withRouter(CartScreen);
