import { calculateMenuProductModifiersPrice } from './calculateMenuProductModifiersPrice/calculateMenuProductModifiersPrice';
import { calculateOrderProductPrice } from './calculateOrderProductPrice/calculateOrderProductPrice';
import { calculateProductCashbackAmount } from './calculateProductCashbackAmount/calculateProductCashbackAmount';
import { calculateProductPrice } from './calculateProductPrice/calculateProductPrice';
import {
  calculateProductInsideComboPrice,
  getComboItemUnavailableMessage,
  getComboItemUnits,
  getCurrentMealSizeGroups,
  getDefiniteSizeSelectedItems,
  getFirstCorrectMealSize,
  getMealItemKey,
  isCorrectMealSize,
  isInvalidMealCombo,
  isMealModifierWithError,
  isSameComboItems,
  isSameComboItemsPlace,
  isSameComboItemsSizeGroup,
} from './combo';
import { getProductCalculatedUnits } from './getProductCalculatedUnits/getProductCalculatedUnits';
import { getProductCalories } from './getProductCalories/getProductCalories';
import { getProductHash } from './getProductHash/getProductHash';
import { getProductMainPrice } from './getProductMainPrice/getProductMainPrice';
import { getProductUnit } from './getProductUnit/getProductUnit';
import { getProductUnits } from './getProductUnits/getProductUnits';
import { getResponseProductModifierOptionUnits } from './getResponseProductModifierOptionUnits/getResponseProductModifierOptionUnits';
import { transformProduct } from './transformProduct/transformProduct';
import { transformProductForCalculator } from './transformProductForCalculator/transformProductForCalculator';
import { transformProductsFromCalculator } from './transformProductsFromCalculator/transformProductsFromCalculator';

export const productUtils = {
  calculateProductPrice,
  calculateOrderProductPrice,
  calculateMenuProductModifiersPrice,
  calculateProductCashbackAmount,
  getProductMainPrice,
  transformProduct,
  transformProductForCalculator,
  transformProductsFromCalculator,
  getProductHash,
  getProductUnits,
  getProductCalculatedUnits,
  getResponseProductModifierOptionUnits,
  getProductUnit,
  getProductCalories,

  // combo
  calculateProductInsideComboPrice,
  getComboItemUnits,
  getMealItemKey,
  getComboItemUnavailableMessage,
  getFirstCorrectMealSize,
  getCurrentMealSizeGroups,
  getDefiniteSizeSelectedItems,
  isMealModifierWithError,
  isInvalidMealCombo,
  isCorrectMealSize,
  isSameComboItems,
  isSameComboItemsPlace,
  isSameComboItemsSizeGroup,
};
