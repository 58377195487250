import { IProductInsideCombo } from 'src/types/combo';

export const isSameComboItemsSizeGroup = (
  itemOne: IProductInsideCombo,
  itemTwo?: IProductInsideCombo,
  params?: {
    sizeId: number;
    groupId: number;
  }
): boolean => {
  if (itemTwo) return itemOne.size_id === itemTwo.size_id && itemOne.group_id === itemTwo.group_id;
  if (params) return itemOne.size_id === params.sizeId && itemOne.group_id === params.groupId;

  return false;
};
