import { observer } from 'mobx-react';
import { FC, ReactElement } from 'react';
import { ICONS } from 'src/constants/icons';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { IProduct } from 'src/types/products';
import { formatPrice } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Quantity from 'src/view/components/Controls/Quantity/Quantity';
import './style.scss';

interface IProps {
  product: IProduct;
}

export const CartProductFooter: FC<IProps> = observer(({ product }: IProps): ReactElement => {
  const price: string = formatPrice(productUtils.calculateProductPrice(product));

  const onChangeQuantity = (quantity: number) => {
    cartStore.update({ ...product, quantity });
  };

  const onRemoveProductFromCart = () => {
    cartStore.remove(product);
  };

  if (product.error) {
    return (
      <div className="cart-product-footer">
        <p className="cart-product-footer__error-text color-red letter-spacing-5">{product.error}</p>
        <Icon name={ICONS.CLOSE} size={14} className="color-dark cursor-pointer" onPress={onRemoveProductFromCart} />
      </div>
    );
  }

  if (cartStore.isEditable) {
    return (
      <div className="cart-product-footer">
        <p className="cart-product-footer__price color-primary text-nowrap">{price}</p>
        <Quantity value={product.quantity} onChange={onChangeQuantity} small />
      </div>
    );
  }

  return (
    <div className="cart-product-footer">
      <p className="cart-product-footer__price color-primary text-nowrap">{localeStore.t('price')}</p>
      <p className="cart-product-footer__price color-primary text-nowrap">{price}</p>
    </div>
  );
});
