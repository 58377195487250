import moment from 'moment';
import React, { useMemo } from 'react';
import { DAYS_INDEX_IN_WEEK } from 'src/constants/main';
import { hash } from 'src/utils/helpers/hash';
import { getLocaleTimeSlot, isRTL } from 'src/utils/helpers';
import { ICONS } from '../../../../../constants/icons';
import { localeStore } from '../../../../../mobx/localesStore';
import { IBranch } from '../../../../../types/branch';
import Accordion from '../../../../components/Controls/Accordion/Accordion';
import Icon from '../../../../components/Controls/Icon/Icon';
import { WorkSlot } from '../WorkSlot/WorkSlot';

interface IProps {
  expandable?: boolean;
  work_slots: IBranch['work_slots'];
  status: string;
  isBranchWorking: boolean;
}

export const WorkSlots: React.FC<IProps> = ({ expandable = false, work_slots, status, isBranchWorking }) => {
  const currentSlot = useMemo(
    () => work_slots.find((work_slot) => moment().weekday() === DAYS_INDEX_IN_WEEK[work_slot.day]),
    [work_slots]
  );

  const upcomingBreaks = useMemo(
    () =>
      isBranchWorking
        ? currentSlot?.breaks?.filter((_break) => {
            const end_time = moment(_break?.start_time, 'hh:mm a', 'en');
            return moment().isBefore(end_time);
          })
        : [],
    [currentSlot, isBranchWorking]
  );

  const hashWorkSlot = hash(JSON.stringify(work_slots[0].breaks));

  /** Check Daily branch */
  const isDaily = work_slots.filter(
    (slot) =>
      hashWorkSlot === hash(JSON.stringify(slot.breaks)) &&
      slot.time === work_slots[0].time &&
      slot.is_full_day === work_slots[0].is_full_day
  );

  return (
    <div className="work-slots-container">
      <Accordion
        disabled={!expandable}
        trigger={({ isOpen }) => (
          <div className="d-flex flex-row align-items-baseline justify-content-start mb-2">
            <Icon name={ICONS.CLOCK} size={14} className="mr-2" color={isBranchWorking ? '#88B78C' : '#FF6D6D'} />
            <div className="d-flex flex-column flex-fill">
              <div className="d-flex w-100 flex-fill flex-row align-items-center justify-content-between">
                <p className="work-slots-title family-semibold branches-info color-dark">{status}</p>
                {expandable && (
                  <Icon
                    name={ICONS.CHEVRON}
                    size={20}
                    className={`back-icon color-primary slot-dropdown-icon ${isRTL ? 'arrow-right' : 'arrow-left'} ${
                      isOpen ? 'open' : 'close'
                    }`}
                  />
                )}
              </div>
              {upcomingBreaks?.map((slotBreak, index) => (
                <div className="work-slot-break" key={index}>
                  {`${localeStore.t('break')}: ${getLocaleTimeSlot(slotBreak.start_time, slotBreak.end_time)}`}
                </div>
              ))}
            </div>
          </div>
        )}
      >
        <div className="work-slots">
          {isDaily.length === 7 ? (
            <WorkSlot key={work_slots[0].day} slot={work_slots[0]} daily />
          ) : (
            work_slots.map((slot) => <WorkSlot key={slot.day} slot={slot} daily={false} />)
          )}
        </div>
      </Accordion>
    </div>
  );
};
