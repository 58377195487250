import { observer } from 'mobx-react';
import { forwardRef, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { DESTINATION_LOGS, EVENTS } from 'src/constants/events';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import { productsStore } from 'src/mobx/productsStore';
import logger from 'src/services/logger';
import { ICategory } from 'src/types/products';
import { getRestaurantName, setMetaTags } from 'src/utils/helpers';
import { getTranslate } from '../../../../../../../utils/formatter';
import { MenuSearch } from '../../../MenuSearch/MenuSearch';
import './style.scss';

export const CategoriesList = observer(
  forwardRef<HTMLUListElement>((_, ref) => {
    useEffect(() => {
      scrollTo(productsStore.categoryId || 0);
      logger.logEvent(
        EVENTS.VIEW_ITEM_LIST,
        {
          category_id: productsStore.categoryId || 0,
          category_name: getTranslate(productsStore.category?.name),
        },
        DESTINATION_LOGS.MOENGAGE
      );
    }, [productsStore.categoryId]);

    const scrollTo = async (selectedTabId: number | undefined) => {
      const selectedTab = document.getElementById(`category_${selectedTabId}`);

      if (selectedTab?.offsetLeft) {
        selectedTab.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const selectCategory = (category: ICategory) => {
      productsStore.setCategory(category.id);
      logger.logEvent(EVENTS.MENU_CATEGORY_CHANGED, {
        items: category.items,
        item_list_name: getTranslate(category?.name),
        item_list_id: category.id,
      });

      setMetaTags({
        title: `${getRestaurantName()} - ${getTranslate(category.name)}`,
        description: getTranslate(category.category_meta_description),
        keywords: category.items.data.map((el) => getTranslate(el.name)).join(','),
        'og:title': `${getRestaurantName()} - ${getTranslate(category.name)}`,
        'og:description': getTranslate(category.category_meta_description),
        'og:type': 'website',
        'twitter:title': `${getRestaurantName()} - ${getTranslate(category.name)}`,
        'twitter:description': getTranslate(category.category_meta_description),
      });
    };

    if (productsStore.loading || !Array.isArray(productsStore.categories) || !productsStore.categories.length) {
      return (
        <ul className="flex-row d-flex align-items-center full-width mx-3">
          {[13, 11, 10, 7].map((i) => (
            <Skeleton key={i} width={i * 10} className={`tab menu-category menu-category-skeleton mr-3`} />
          ))}
        </ul>
      );
    }

    return (
      <div id="categories-list" className={'categories-list-container'}>
        <MenuSearch />
        <ul ref={ref} className="flex-row flex-wrap flex-nowrap flex-lg-wrap d-flex align-items-center mx-3">
          {productsStore.categories.map((category) => (
            <li
              id={`category_${category.id}`}
              key={category.id}
              data-targetid={category.id}
              className={`tab background-light background_active-white text-elipsis category-tab ${
                category.id === productsStore.category?.id ? 'active' : null
              } ${productsStore.searchIsActive ? 'hidden' : ''}`}
            >
              <h3>
                <Link
                  rel="bookmark"
                  title={getTranslate(category.name)}
                  hrefLang={localeStore.language}
                  to={{
                    pathname: SCREENS.CATEGORY(category.id),
                    search: `language=${localeStore.language}`,
                  }}
                  className="color-dark color_hover-darkgray family-semibold"
                  onClick={() => selectCategory(category)}
                >
                  {getTranslate(category.name)}
                </Link>
              </h3>
            </li>
          ))}
        </ul>
      </div>
    );
  })
);
