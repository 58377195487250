import React, { Component } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductModifier, IProductModifierOption } from 'src/types/products';
import Option from './components/Option';
import './style.scss';
import { getTranslate } from '../../../../utils/formatter';

interface Props {
  error: boolean;
  modifier: IProductModifier;
  readOnly?: boolean;
  onChange: (modifier: IProductModifier) => void;
  leftToMaximum: number | null;
}

export default class MultiChoise extends Component<Props> {
  onPressOption = (option: IProductModifierOption) => {
    const modifiers = {
      ...this.props.modifier,
      options: this.props.modifier.options.map((_option) => {
        if (_option.id === option.id) {
          return {
            ...option,
            selected: !option.selected,
          };
        }
        return _option;
      }),
    };
    this.props.onChange(modifiers);
  };

  onOptionQuantityChange = (optionId: number, quantity: number) => {
    const modifiers = {
      ...this.props.modifier,
      options: this.props.modifier.options.map((_option) => {
        if (_option.id === optionId) {
          return {
            ..._option,
            quantity,
          };
        }
        return _option;
      }),
    };
    this.props.onChange(modifiers);
  };

  renderOption = (option: IProductModifierOption) => (
    <Option
      key={option.id}
      id={`select_${option.id}`}
      uniq={Boolean(this.props.modifier.unique_options) || this.props.modifier.maximum === 1}
      option={option}
      onChangeOptionQuantity={this.onOptionQuantityChange}
      onPressOption={this.onPressOption}
      leftToMaximum={this.props.leftToMaximum}
      readOnly={this.props.readOnly}
    />
  );

  get message() {
    let message = localeStore.t('txt_select_any_amount');
    if (this.props.modifier.minimum) {
      message = `${localeStore.t('txt_select_from')} ${this.props.modifier.minimum}`;
      if (this.props.modifier.maximum) {
        message = `${message} ${localeStore.t('txt_select_to')} ${this.props.modifier.maximum}`;
      }
    } else if (this.props.modifier.maximum) {
      message = `${localeStore.t('txt_select_maximum')} ${this.props.modifier.maximum}`;
    }

    return message;
  }

  render() {
    const { modifier, readOnly } = this.props;
    return (
      <div className="multi">
        <h3 className="multi-title">{getTranslate(modifier.name)}</h3>
        {!readOnly ? <p className={`multi-subtitle ${this.props.error ? 'error' : ''}`}>{this.message}</p> : null}
        {modifier.options.map(this.renderOption)}
      </div>
    );
  }
}
