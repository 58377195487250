import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { cartStore } from 'src/mobx/cartStore';
import IconButton from 'src/view/components/Controls/IconButton/IconButton';

@observer
class CartButton extends Component {
  onClick = () => {
    if (cartStore.products.length) cartStore.openCart();
  };

  render() {
    return (
      <div onClick={this.onClick} id={'cart-button'}>
        <IconButton
          className="th-bg-background-secondary "
          iconClassName="th-color-primary"
          tooltip={cartStore.products.length}
          name={ICONS.CART}
        />
      </div>
    );
  }
}

export default CartButton;
