import { Component } from 'react';
import { observer } from 'mobx-react';
import { ICONS } from 'src/constants/icons';
import { IMessageModal, messagesStore } from 'src/mobx/messagesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import DropDownPortal from 'src/view/components/DropDownPortal/DropDownPortal';
import './style.scss';

interface Props {
  message: IMessageModal;
}

@observer
export default class MessagesPopup extends Component<Props, {}> {
  state = {};
  onButtonPress = (cb: (() => void) | undefined) => () => {
    if (cb) cb();
    this.requestClose();
  };

  requestClose = () => {
    if (this.props.message.message.onRequestClose) this.props.message.message.onRequestClose();
    messagesStore.removeMessage(this.props.message.id);
  };

  onBackdropPress = () => {
    if (this.props.message.message?.autoClose) this.requestClose();
  };

  render() {
    return (
      <DropDownPortal>
        <div className="message-popup">
          <div className="message-popup-backdrop" onClick={this.onBackdropPress} />
          <div className="message-popup-content">
            {this.props.message.message.title ? (
              <p className="color-dark text-size-20 family-semibold mb-3 text-center px-4">
                {this.props.message.message.title}
              </p>
            ) : null}
            {this.props.message.message.message ? (
              <p className="color-gray4 text-size-16 text-center px-4">{this.props.message.message.message}</p>
            ) : null}
            {this.props.message.message.Content ? this.props.message.message.Content : null}
            <div className="d-flex flex-1 mt-4">
              {this.props.message.message.options?.map((option, index) => (
                <button
                  onClick={this.onButtonPress(option.onPress)}
                  className={`button full-width py-3 text-size-14 family-semibold d-flex flex-1 background-primary ${
                    index ? 'ml-2' : ''
                  } ${option.className}`}
                >
                  <span className={option.textClassName}>{option.text}</span>
                </button>
              ))}
            </div>
            <Icon
              onPress={this.requestClose}
              className="modal-message-close color-darkgray"
              name={ICONS.CLOSE}
              size={14}
            />
          </div>
        </div>
      </DropDownPortal>
    );
  }
}
