import './style.scss';
import React, { FC, useEffect, useState } from 'react';
import DeliveryStatus from './components/DeliveryStatus/DeliveryStatus';
import socket from '../../../services/socket';
import { requests } from '../../../requests';
import { ordersStore } from '../../../mobx/ordersStore';
import { SCREENS } from '../../../constants/screens';
import { Link, useHistory } from 'react-router-dom';
import { RouteComponentProps, StaticContext } from 'react-router';
import { observer } from 'mobx-react';
import OrderCompleted from './components/OrderCreated/OrderCompleted';
import Spinner from '../../components/Controls/Spinner/Spinner';
import { localeStore } from '../../../mobx/localesStore';
import BackIcon from '../../components/Controls/BackIcon/BackIcon';
import { PageTitle } from '../../components/PageTitle/PageTitle';

interface IState {
  loading: boolean;
}

interface IOrderCompletedScreenProps extends RouteComponentProps<{ id: string }, StaticContext> {}

/**
 * @deprecated
 * TODO: delete this screen after refactoring sms pages
 */
const OrderCompletedScreen: FC<IOrderCompletedScreenProps> = observer((props: IOrderCompletedScreenProps) => {
  const [loading, setLoading] = useState(true);
  const { id } = props.match.params;
  const history = useHistory();

  useEffect(() => {
    socket.init();
    fetchOrder();
    return () => {
      socket.disconnect();
    };
  }, []);
  const fetchOrder = async () => {
    try {
      const order = await requests.getOrderById(id);
      ordersStore.selectOrder(order);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      history.replace(SCREENS.NOT_FOUND());
    }
  };
  if (loading)
    return (
      <Spinner size={50} className="button-spinner sms_pg d-flex justify-content-center full-width" color={'#000'} />
    );

  return (
    <>
      <div className="container">
        <div className="d-flex flex-column full-width">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center mb-2 mt-2 mb-lg-4 mt-lg-4">
              <Link
                to={{
                  pathname: SCREENS.ORDERS(),
                  search: `?language=${localeStore.language}`,
                }}
              >
                <BackIcon>
                  <PageTitle title={localeStore.t('txt_track_my_order')} />
                </BackIcon>
              </Link>
            </div>
          </div>
          {ordersStore.order?.dnf?.enable ? (
            <DeliveryStatus />
          ) : (
            // @ts-ignore
            <OrderCompleted {...props} />
          )}
        </div>
      </div>
    </>
  );
});
export default OrderCompletedScreen;
