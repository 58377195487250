import React, { Component, RefObject } from 'react';
import { Link } from 'react-router-dom';
import { WINDOW_SIZES } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import { productsStore } from 'src/mobx/productsStore';
import { IBanner } from 'src/types/products';
import { debounce, throttle } from 'src/utils/perfomance';
import './style.scss';
import { observer } from 'mobx-react';
import { promotionStore } from 'src/mobx/promotionStore';

interface State {
  list: IBanner[];
  active: number;
  duration: number;
  offset: number;
  height: number;
  offsetX: number;
}

@observer
class MenuBannerList extends Component<{}, State> {
  duration: number = 400;
  nextTimer: NodeJS.Timeout | null = null;
  prevTimer: NodeJS.Timeout | null = null;
  slider: RefObject<HTMLDivElement> = React.createRef();
  state: State = {
    list:
      promotionStore.banners.length === 1
        ? [...promotionStore.banners]
        : [...promotionStore.banners, ...promotionStore.banners, ...promotionStore.banners],
    active: promotionStore.banners.length === 1 ? 0 : promotionStore.banners.length + 1,
    duration: 0,
    offset: 0,
    height: 0,
    offsetX: 0,
  };

  componentDidMount = () => {
    const width = this.slider.current?.clientWidth || 0;
    this.setState(() => ({ offset: width, height: width / 2.7 }));
    window.addEventListener('resize', this.onResize);
    if (promotionStore.banners.length > 1) this.nextTimer = setTimeout(this.nextImage, 5000);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize);
  };

  handleResize = () => {
    const width = this.slider.current?.clientWidth || 0;
    this.setState(() => ({ offset: width, height: width / 2.7 }));
  };

  onResize = debounce(this.handleResize, 100);

  next = () => {
    if (this.nextTimer) clearTimeout(this.nextTimer);
    this.setState({ active: this.state.active + 1, duration: this.duration }, () =>
      setTimeout(this.afterNext, this.duration)
    );
  };

  afterNext = () => {
    this.setState(() => ({ duration: 0 }));
    const list = this.state.list.slice(1);
    const first = this.state.list[0];
    this.setState((state) => ({ list: [...list, first], active: state.active - 1 }));
    this.nextTimer = setTimeout(this.nextImage, 5000);
  };

  nextImage = throttle(this.next, this.duration);

  prev = () => {
    if (this.nextTimer) clearTimeout(this.nextTimer);
    this.setState({ active: this.state.active - 1, duration: this.duration }, () =>
      setTimeout(this.afterPrev, this.duration)
    );
  };

  afterPrev = () => {
    this.setState(() => ({ duration: 0 }));
    const list = this.state.list.splice(0, this.state.list.length - 1);
    const last = this.state.list[this.state.list.length - 1];

    this.setState((state) => ({ list: [last, ...list], active: state.active + 1 }));
    this.nextTimer = setTimeout(this.nextImage, 5000);
  };

  prevImage = throttle(this.prev, this.duration);

  onBannerClick = (banner: IBanner) => {
    if (banner.target) {
      if (banner.targetType === 'category') {
        productsStore.setCategory(Number(banner.target));
      } else if (banner.targetType === 'link') {
        window.open(String(banner.target));
      }
    }
  };

  renderImage = (banner: IBanner, index: number) => {
    let link = '#';
    if (banner.target && banner.targetType !== 'none') {
      link =
        banner.targetType === 'item' || banner.targetType === 'product'
          ? SCREENS.PRODUCT(banner.target)
          : `#category_${banner.target}`;
    }
    return (
      <Link
        rel="tag"
        title={banner.targetType}
        hrefLang={localeStore.language}
        to={{
          pathname: link,
          search: `?language=${localeStore.language}`,
        }}
        key={index}
        onClick={() => this.onBannerClick(banner)}
        className={`d-flex flex-grow-1 banner-image ${this.state.active === index ? 'active' : ''}`}
        style={{ backgroundImage: `url(${banner.image})` }}
      />
    );
  };
  prevMoveX = 0;

  handleTouchStart = (e: any) => {
    this.prevMoveX = e.targetTouches[0].clientX;
    if (this.nextTimer) clearTimeout(this.nextTimer);
  };
  handleTouchMove = (e: any) => {
    if (this.state.list.length > 1) {
      this.setState({ offsetX: this.prevMoveX - e.targetTouches[0].clientX });
    }
  };
  handleTouchEnd = (e: any) => {
    let active = Math.round((this.state.active * this.state.offset + this.state.offsetX * 1.9) / this.state.offset);

    if (this.state.active === active) {
      this.setState({ offsetX: 0 });
      this.nextTimer = setTimeout(this.nextImage, 5000);
      return;
    }
    const dx = this.state.active > active ? -1 : 1;
    active += dx;
    active = active > this.state.list.length - 1 ? 0 : active;
    active = active < 0 ? this.state.list.length - 1 : active;
    this.setState({
      active,
    });
    if (dx === 1) {
      this.next();
    } else {
      this.prev();
    }
    this.setState({ offsetX: 0 });
  };

  render() {
    const margin = this.state.offset <= WINDOW_SIZES.sm ? 8 : 30;
    return (
      <div
        className="banner full-width"
        ref={this.slider}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
      >
        <div
          style={{
            height: this.state.height,
            transitionDuration: `${this.state.duration}ms`,
            transform:
              localeStore.language === 'ar'
                ? `translateX(${
                    this.state.active * this.state.offset + this.state.active * margin + this.state.offsetX
                  }px)`
                : `translateX(-${
                    this.state.active * this.state.offset + this.state.active * margin + this.state.offsetX
                  }px)`,
            width: `calc(${this.state.list.length * 100}% + ${this.state.list.length * margin}px)`,
          }}
          className=" full-height d-flex flex-grow-1 flex-row banner-list"
        >
          {this.state.list.map(this.renderImage)}
        </div>
        {promotionStore.banners.length > 1 ? (
          <>
            <button
              disabled={Boolean(this.state.duration)}
              className={'banner-button banner-prev'}
              onClick={this.prevImage}
            />
            <button
              disabled={Boolean(this.state.duration)}
              className={'banner-button banner-next'}
              onClick={this.nextImage}
            />
          </>
        ) : null}
      </div>
    );
  }
}
export default MenuBannerList;
