import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ICONS } from 'src/constants/icons';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import { reservationsStore } from 'src/mobx/reservationsStore';
import { IReservation } from 'src/types/reservations';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';
export interface IReservationCancelProps extends RouteComponentProps<{ id?: string }> {
  loading: boolean;
  reservation: IReservation | undefined;
}

class ReservationCancel extends React.Component<IReservationCancelProps> {
  state = {
    loading: false,
  };

  cancelReservation = async () => {
    try {
      this.setState(() => ({ loading: true }));
      await reservationsStore.cancelReservation(Number(this.props.match.params.id));
      this.props.history.push(SCREENS.RESERVATIONS());
      this.setState(() => ({ loading: false }));
    } catch (error: any) {
      this.setState(() => ({ loading: false }));
      console.error(error);
    }
  };

  renderPopup = ({ closeModal }: { closeModal: () => void }) => {
    return (
      <div className="d-flex flex-column">
        <Icon name={ICONS.CLOSE} onPress={closeModal} className={' dropdown-close color-gray text-pointer'} size={14} />

        <div className="d-flex justify-content-center text-size-20 color-dark family-semibold mt-2 mb-4">
          {localeStore.t('are_you_sure')}
        </div>

        <div className="d-flex flex-row">
          <ButtonComponent
            onClick={this.cancelReservation}
            title={localeStore.t('txt_yes')}
            className="button-medium d-flex flex-grow-1"
          />
          <div className="mr-2"></div>
          <ButtonComponent className="button-medium d-flex flex-grow-1 background-light color-dark">
            <span className="color-dark">{localeStore.t('txt_no')}</span>
          </ButtonComponent>
        </div>
      </div>
    );
  };

  public render() {
    if (this.props.reservation && this.props.reservation.status === 'is_canceled') return null;
    return (
      <div className="reservation-modal-cancel">
        <DropDownModal
          asPopupInMobile
          justify={'center'}
          position={'top'}
          direction={'down'}
          content={this.renderPopup}
        >
          <ButtonComponent
            loading={this.props.loading || this.state.loading}
            className="reservation-modal-cancel-button background-light color-dark"
          >
            <span className="color-dark">{localeStore.t('txt_cancel')}</span>
          </ButtonComponent>
        </DropDownModal>
      </div>
    );
  }
}

export default withRouter(ReservationCancel);
