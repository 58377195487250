import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import Icon from '../Controls/Icon/Icon';
import './style.scss';
export interface IAccordionOption {
  title: string;
  description: string;
}
interface Props {
  options: IAccordionOption[];
}
interface State {
  selected: null | number;
}

export default class Accordion extends Component<Props, State> {
  state = {
    selected: null,
  };

  select = (index: number) => {
    this.setState((state) => ({ selected: state.selected === index ? null : index }));
  };

  renderOption = (option: IAccordionOption, index: number) => {
    return (
      <div
        key={index}
        className={`d-flex flex-column mb-4  color_hover-primary accordion-option ${
          index === this.state.selected ? 'active color-primary' : ''
        }`}
      >
        <div onClick={() => this.select(index)} className="d-flex flex-row  accordion-head card">
          <Icon name={ICONS.ARROW_RIGHT_FILLED} />{' '}
          <p className="color-dark text-size-18 family-semibold">{option.title}</p>
        </div>
        <div className="accordion-description">
          <p className="text-size-16 color-dark">{option.description}</p>
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.props.options.map(this.renderOption)}</div>;
  }
}
