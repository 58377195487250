import { action, observable } from 'mobx';
import { IBranchPaymentType } from 'src/types/branch';

export interface ICheckoutInfo {
  prodmocode: string;
  payment_type: null | IBranchPaymentType;
  curbside: null | {
    car_brand: string;
    car_color: string;
    car_type: string;
    car_number: string;
  };
  selected_card: null;
  notes: string;
}
export class CheckoutStore {
  @observable public addPaymentCard: boolean = false;
  @observable public reorderStore: any = null;
  // actions

  setReorderStore = (data: any) => {
    this.reorderStore = data;
  };
  openAddPaymentCart = () => {
    this.addPaymentCard = true;
  };
  closeAddPaymentCart = () => {
    this.addPaymentCard = false;
  };

  @action clear = () => {
    this.closeAddPaymentCart();
  };
}

export const checkoutStore = new CheckoutStore();
