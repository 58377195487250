import React from 'react';
import './style.scss';
import Icon from '../Controls/Icon/Icon';
import { ICONS } from '../../../constants/icons';
import { appStore } from '../../../mobx/appStore';
import { localeStore } from '../../../mobx/localesStore';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { TTranslate } from 'src/types/main';
import Skeleton from 'react-loading-skeleton';
import { SCREENS } from '../../../constants/screens';
import { useHistory } from 'react-router-dom';

interface IProps {
  name: TTranslate | undefined;
  description: TTranslate | undefined;
  amount: number;
  loading?: boolean;
  className?: string;
}

export const Cashback: React.FC<IProps> = ({ name, description, loading = false, amount, className }, props) => {
  const history = useHistory();

  const currency =
    localeStore.language === 'ar' ? appStore.settings?.wallet_currency_ar : appStore.settings?.wallet_currency;

  if (loading) {
    return <Skeleton className="product-submit-item" style={{ borderRadius: 12, height: 50 }} />;
  }
  const openTerms = () => {
    return history.push(SCREENS.TERMS());
  };
  return (
    <div className={`cashback ${className ?? ''}`}>
      <Icon name={ICONS.CASHBACK} size={18} color={'#88B78C'} />
      <div className="cashback-container">
        <div className="cashback-info">
          <div className="cashback-offer-name">{getTranslate(name)}</div>
          <div className="cashback-value">{formatPrice(+amount, currency)}</div>
        </div>
        <div className="cashback-description">{getTranslate(description)}</div>
        <div className="cashback-description open-terms" onClick={openTerms}>
          {localeStore.t('txt_terms')}
        </div>
      </div>
    </div>
  );
};
