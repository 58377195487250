import RFirebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import { ISettings } from 'src/types/app';
import { FIREBASE_EVENT_HANDLER } from './handlers';
import { EVENTS_MAP, TEvents } from 'src/constants/events';

class Firebase {
  private isInitialized: boolean = false;
  public analytics: RFirebase.analytics.Analytics | undefined;

  public setConfig(config: ISettings['firebase']) {
    if (
      config &&
      config.appId &&
      config.apiKey &&
      config.authDomain &&
      config.measurementId &&
      config.projectId &&
      config.messagingSenderId &&
      config.storageBucket
    ) {
      RFirebase.initializeApp(config);
      this.analytics = RFirebase.analytics();
      this.isInitialized = true;
    }
  }

  public logEvent(event: TEvents[keyof TEvents], params: any = {}): void {
    try {
      if (this.analytics && this.isInitialized) {
        const parameters = FIREBASE_EVENT_HANDLER(event, params);
        this.analytics.logEvent(EVENTS_MAP[event], parameters);
      }
    } catch (error: any) {
      console.error(error);
    }
  }
}
export const firebase = new Firebase();
