import { action, computed, observable } from 'mobx';
import moment from 'moment';
import OrderHandler from 'src/mobx/functionalStores/orders/orderHandler';
import { IOrder } from '../../../types/orders';

class DriverSearchStore {
  @observable timeOut: boolean = false;
  private OrderHandler: OrderHandler;

  constructor() {
    this.OrderHandler = new OrderHandler();
  }

  // show loading if the data has not yet been updated
  @computed get loading() {
    if (!this.OrderHandler?.getOrder?.dnf?.cutoff_at) {
      return true;
    }
    return false;
  }

  opened: boolean = false;

  @action init = async (id: IOrder['id']) => {
    try {
      await this.OrderHandler.defineOrder({ id });
    } catch (e) {
      console.error(e);
    }

    const serverDate: moment.Moment = moment.utc(this.OrderHandler?.getOrder?.dnf?.cutoff_at);
    const localDate: moment.LocaleSpecifier = moment(serverDate).utc().local().format();
    if (moment().isAfter(localDate)) {
      this.timeOut = true;
    }
  };

  setOpened = (opened: boolean) => (this.opened = opened);

  @action define = async (id: IOrder['id'], order: IOrder | undefined) => {
    await this.OrderHandler.defineOrder({ id, order });
  };

  @computed get order() {
    return this.OrderHandler.getOrder;
  }
  @action setTimeOut = (status: boolean) => {
    this.timeOut = status;
  };

  clear = () => {
    this.timeOut = false;
    this.opened = false;
    this.OrderHandler.clearOrder();
  };
}
export const driverSearchStore = new DriverSearchStore();
