import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { IProduct } from 'src/types/products';

/**
 * get calories string if calories unit is exist in product
 */
export const getProductCalories = (product: IProduct | IProductInsideCombo | undefined): string | null => {
  if (!product || typeof product.calories !== 'number') return null;

  if (typeof product.calories === 'number') {
    return `${product.calories} ${localeStore.t('kcal')}`;
  }

  return null;
};
