import { ReactNode, useEffect, useRef, useState } from 'react';
import './style.scss';

interface Props {
  className?: string;
  /**
   * Is responsible for whether the element is open
   *
   * @type {boolean}
   * @memberof Props
   */
  isOpened?: boolean;

  /**
   * Content that is collapsed
   *
   * @type {ReactNode}
   * @memberof Props
   */
  children?: ReactNode;
}

const Collapse = ({ isOpened, className, children }: Props) => {
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  // Set height if isOpened
  // If Opened set scroll height or 0
  useEffect(() => {
    if (contentRef.current) setHeight(isOpened ? contentRef.current.scrollHeight : 0);
  }, [isOpened, contentRef]);

  return (
    <div className={`collapse ${className} ${isOpened ? 'opened' : ''}`}>
      {/* Content */}
      <div ref={contentRef} className="collapse-content" style={{ height }}>
        {children}
      </div>
    </div>
  );
};

export default Collapse;
