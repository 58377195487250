import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { AppStore } from 'src/mobx/appStore';
import { STORES } from 'src/mobx/mainStore';
import Modal from '../../components/Controls/Modal/Modal';
import ReservationCreateMap from './containers/ReservationCreateMap/ReservationCreateMap';
import './style.scss';
interface Props extends RouteComponentProps<any> {
  appStore: AppStore;
}
@observer
class ReservationCreateScreen extends Component<Props> {
  closeModal = () => {
    if (!this.props.location.key) {
      this.props.history.push(SCREENS.RESERVATIONS());
    } else {
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <Modal
        className="reservation-modal-wrapper"
        withPane
        isOpen={true}
        onRequestClose={this.closeModal}
        withCloseIcon
      >
        {/* <Helmet title={'title_reservation_create'} /> */}
        <div className="reservation-modal reservation-create background-white">
          {/* TODO: show here skeleton until settings will load */}
          <ReservationCreateMap />
        </div>
      </Modal>
    );
  }
}

export default inject(STORES.APP_STORE)(ReservationCreateScreen);
