import { Component } from 'react';
import { IDropDownListProps } from '../DropDown/DropDown';
import Icon from '../Icon/Icon';

export interface IDropDownOption<T> {
  label: string;
  key: string | number;
  value: T;
  icon?: string;
}

export interface DropDownOptionProps<T> {
  option: IDropDownOption<T>;
  selected?: IDropDownOption<T>['key'];
  onPress: IDropDownListProps<T>['onPress'];
}

export class DropDownOption extends Component<DropDownOptionProps<any>, {}> {
  shouldComponentUpdate = (nextProps: Readonly<DropDownOptionProps<any>>) => {
    if (nextProps.selected !== this.props.selected) return true;
    return false;
  };

  onOptionPress = () => this.props.onPress(this.props.option);

  render() {
    const { option, selected } = this.props;
    return (
      <li
        onClick={this.onOptionPress}
        className={`dropdown-option background_hover-light background_active-primary d-flex flex-row align-items-center ${
          selected === option.key ? 'active' : null
        }`}
      >
        {option.icon ? <Icon className="mr-2" name={option.icon} /> : null}
        <p className={'dropdown-label'}>{option.label}</p>
      </li>
    );
  }
}
