import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';
import './style.scss';

import { getDeliveryAddressName } from '../../../../../../../utils/helpers';
import { addressStore } from '../../../../../../../mobx/barnchStore/address';
interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModalLocation extends Component<Props> {
  closeModal = () => {
    cartStore.setModalValue('location', null);
  };

  renderCheckLocationModal = ({ closeModal }: { closeModal: () => void }) => {
    const closeCheckLocationModal = (accepted: boolean) => {
      this.setState({ locationAccepted: accepted, checkLocationModal: false });
      closeModal();
    };

    return (
      <ModalMessage
        wrapperClassName={'px-0 pb-0 pt-3'}
        minWidth={320}
        title={`${
          branchStore.delivery_type === DELIVERY_TYPES.DELIVERY
            ? localeStore.t('address_confirmation', { address: addressStore.address?.address })
            : localeStore.t('branch_confirmation', { branch_name: branchStore.branch.address })
        }`}
        options={[
          {
            text: localeStore.t('txt_ok'),
            onPress: () => {
              closeCheckLocationModal(true);
              cartStore.submit();
            },
          },
          {
            text: localeStore.t('txt_change'),
            className: 'background-light',
            textClassName: 'color-dark',
            onPress: () => {
              this.props.history.push({
                pathname: `/branches/${branchStore.delivery_type}`,
                search: `?language=${localeStore.language}`,
              });
            },
          },
        ]}
        closeModal={() => closeCheckLocationModal(false)}
      />
    );
  };

  render() {
    const { modals } = cartStore.checkout;

    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose={false}
        className="checkout-modal-location"
        backdrop
        visible={modals.location?.visible}
        onClose={this.closeModal}
        content={this.renderCheckLocationModal}
        mobile={{
          popup: true,
          sheet: true,
        }}
      />
    );
  }
}

export default withRouter(CheckoutModalLocation);
