import { staticTheme } from 'src/constants/staticTheme';
import { IDefaultTheme, ISettings, IThemeParameter, TThemeKeys } from 'src/types/app';

const defaultTheme: IDefaultTheme = {
  background: {
    primary: '#ffffff',
    secondary: '#d8d6d6',
  },
  color: {
    primary: '#000000',
    secondary: '#000000',
  },
  selected: {
    primary: '#000000',
    secondary: '#ffffff',
  },
  controls: {
    primary: '#F8bc10',
    secondary: '#ffffff',
  },
};

export const injectStyles = (settings?: ISettings) => {
  if (!settings || !settings.theme) return null;
  const style = document.createElement('style');

  const mergedThemes = Object.entries(defaultTheme).reduce((main: IDefaultTheme, parameter: any) => {
    const [key]: [TThemeKeys, IThemeParameter] = parameter;
    if (settings.theme.web[key]) {
      if (settings.theme.web[key].primary) main[key].primary = settings.theme.web[key].primary;
      if (settings.theme.web[key].secondary) main[key].secondary = settings.theme.web[key].secondary;
    }

    return main;
  }, defaultTheme);

  let stylesheet = '';
  Object.entries(mergedThemes).forEach(([key, value]) => {
    stylesheet += `
        .th-${key}-primary, .th-${key}_hover-primary:hover, .th-${key}_active-primary.active {
            color: ${value.primary} ;
        }
        .th-bg-${key}-primary, .th-bg-${key}_hover-primary:hover, .th-bg-${key}_active-primary.active {
            background-color: ${value.primary} ;
        }
        .th-${key}-secondary, .th-${key}_hover-secondary:hover, .th-${key}_active-secondary.active {
            color: ${value.secondary} ;
        }
        .th-bg-${key}-secondary, .th-bg-${key}_hover-secondary:hover, .th-bg-${key}_active-secondary.active {
            background-color: ${value.secondary} ;
            border-color: ${value.secondary} ;
        }
      `;
  });

  stylesheet += `
  .header .hamburger > .dropdown > .dropdown-modal .background_hover-secondary:hover { background-color: ${settings.theme.web.background.secondary};border-color: ${settings.theme.web.background.primary} }

  .color-primary, .color_active-primary.active, .color_hover-primary:hover { color: ${settings.theme.web.controls.primary}  }
  .background-primary, .background_active-primary.active, .background_hover-primary:hover { background-color: ${settings.theme.web.controls.primary}  }
  .background-gray, .background_active-gray.active, .background_hover-gray:hover { background-color: ${staticTheme.colors.background.gray}  }
  .background-light, .background_active-light.active, .background_hover-light:hover { background-color: ${staticTheme.colors.background.light}  }
  .background_pseudo-primary::before, .background_pseudo-primary::after { background-color: ${settings.theme.web.controls.primary}  }
    .header .hamburger > .dropdown > .dropdown-modal, .header .preorder .dropdown .dropdown-modal { background-color: ${settings.theme.web.background.primary};border-color: ${settings.theme.web.background.primary} }
    .header .hamburger-menu-item:hover .demo-icon { color: ${settings.theme.web.selected.primary}  }
    .border-primary, .border_pseudo-primary::after, .border_pseudo-primary::before {
      border-color:${settings.theme.web.controls.primary}
    }`;

  if (window.location.hostname.includes('ikea')) {
    stylesheet += `
      @font-face {
        font-family: 'Open Sans';
        src: url(${process.env.REACT_APP_CLOUD_FRONT}/media/fonts/Ikea/NotoIKEAArabic_Latin-Regular.woff) format('truetype');
      }
      @font-face {
        font-family: 'PrimaryRegular';
        src: url(${process.env.REACT_APP_CLOUD_FRONT}/media/fonts/Ikea/NotoIKEAArabic_Latin-Regular.woff) format('truetype');
      }
      @font-face {
        font-family: 'PrimarySemiBold';
        src: url(${process.env.REACT_APP_CLOUD_FRONT}/media/fonts/Ikea/NotoIKEAArabic_Latin-Bold.woff) format('truetype');
      }
      @font-face {
        font-family: 'PrimaryLight';
        src: url(${process.env.REACT_APP_CLOUD_FRONT}/media/fonts/Ikea/NotoIKEAArabic_Latin-Regular.woff) format('truetype');
      }
      @font-face {
        font-family: 'PrimaryBold';
        src: url(${process.env.REACT_APP_CLOUD_FRONT}/media/fonts/Ikea/NotoIKEAArabic_Latin-Bold.woff) format('truetype');
      }
    `;
  }

  style.textContent = stylesheet;
  document.head.append(style);
};
