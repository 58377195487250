import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { defaultBranch, E_MENU_TYPE, PAYMENT_TYPES } from 'src/constants/main';
import { requests } from 'src/requests';
import { IBranch } from 'src/types/branch';
import { logError } from 'src/utils/helpers';
import { paymentTypes } from '../../constants/paymentTypes';
import { IPaymentTypeId } from '../../types/app';
import { getTranslate } from '../../utils/formatter';
import { appStore } from '../appStore';
import { cartStore } from '../cartStore';
import { localeStore } from '../localesStore';
import { messagesStore } from '../messagesStore';
import { preorderStore } from '../preorderStore';
import { productsStore } from '../productsStore';
import { userStore } from '../userStore';
import { addressStore } from './address';
import logger from '../../services/logger';
import { DESTINATION_LOGS, EVENTS } from '../../constants/events';

export class BranchStore {
  @observable public isBranches: boolean = false;
  @observable public defaultBranchId: number | null = null;
  @observable public defaultBranchPaymentMethods: IPaymentTypeId = 0;
  @observable isOpenPickupMap: boolean = false;
  @observable branches: IBranch[] | null = null;
  @observable searchValue: string | undefined = undefined;

  @observable loading: boolean = false;

  @observable public selectMapCoordinate: { latitude: number; longitude: number; address?: string } | null = null;
  @observable public tempMapCoordinate: { latitude: number; longitude: number; address?: string } | null = null;

  @persist('object') @observable public branch: IBranch = defaultBranch;
  @persist @observable public delivery_type: string =
    appStore.settings?.delivery_type === DELIVERY_TYPES.BOTH ||
    appStore.settings?.delivery_type === DELIVERY_TYPES.DELIVERY
      ? DELIVERY_TYPES.DELIVERY
      : DELIVERY_TYPES.PICKUP;
  @persist @observable public order_type?: number | null = null;

  /**
   * Get vat registration number
   *
   * If the franchise is included, then we take from the branch
   *
   * From selected branch or settings
   * @readonly
   * @memberof BranchStore
   */
  @computed get vat_registration_number() {
    return branchStore.branch?.vat_registration_number ?? appStore.settings?.vat_registration_number;
  }

  /**
   * Get vat percent
   *
   * If the franchise is included, then we take from the branch
   *
   * From selected branch or settings
   * @readonly
   * @memberof BranchStore
   */
  @computed get vat_percent() {
    return this.branch?.vat_percent ?? appStore.settings?.vat;
  }

  /**
   * Get vat incuded
   *
   * If the franchise is included, then we take from the branch
   *
   * From selected branch or settings
   * @readonly
   * @memberof BranchStore
   */
  @computed get vat_included() {
    return this.branch.vat_included ?? appStore.settings?.vat_included;
  }

  clearOrderType = () => {
    this.order_type = null;
  };

  @action setSearchValue = (value: string | undefined) => {
    this.searchValue = value;
  };
  @action togglePickupMap = (status: boolean) => {
    this.isOpenPickupMap = status;
  };
  @action setBranches = (branch: IBranch[]) => {
    this.branches = branch;
  };
  @computed get searchBranch() {
    if (!this.searchValue?.length) return null;
    const regexp = new RegExp(`${this.searchValue}`, 'i');
    const search = this.branches?.filter((el) => getTranslate(el.name).search(regexp) !== -1);
    return search;
  }

  @action setCoordinate = (data: BranchStore['selectMapCoordinate']) => {
    this.selectMapCoordinate = data;
  };

  @action setTempCoordinate = (data: { latitude: number; longitude: number } | null) => {
    this.tempMapCoordinate = data;
  };

  @action openBranches = () => (this.isBranches = true);

  @action closeBranches = () => (this.isBranches = false);

  /**
   * Set default branch for sms pages
   */
  @action setDefaultBranchId = (id: number) => (this.defaultBranchId = id);

  @action setDefaultBranchPaymentMethods = (id: IPaymentTypeId) => (this.defaultBranchPaymentMethods = id);

  fetchBranch = async (branch_id: IBranch['id']): Promise<IBranch | null> => {
    try {
      const branch = await requests.branches.getBranch(branch_id, { is_emenu: 1, delivery_type: this.delivery_type });
      if (!branch || !branch.working_now) {
        appStore.mode = null;
        branchStore.order_type = null;
        messagesStore.addMessage({
          type: 'modal',
          message: localeStore.t('not_supported_pickup'),
          options: [{ text: localeStore.t('txt_ok') }],
        });
      }
      return branch;
    } catch (error: any) {
      return null;
    }
  };
  @computed get getPaymentTypes() {
    let payment_types = this.branch.payment_types;
    if (cartStore.isDonation) {
      return payment_types.filter((type) => [PAYMENT_TYPES.ONLINE_PAYMENT, PAYMENT_TYPES.APPLE_PAY].includes(type.id));
    }
    if (cartStore.isSMS && this.branch.allow_all_payment_methods) {
      const defaultMethod = this.branch.payment_types.some((type) => type.id === this.defaultBranchPaymentMethods);
      if (!defaultMethod) {
        payment_types = [
          ...payment_types,
          { id: this.defaultBranchPaymentMethods, type: localeStore.t(paymentTypes[this.defaultBranchPaymentMethods]) },
        ];
      }
    }
    return payment_types;
  }

  /**
   * Currency of selected branch
   *
   * @readonly
   * @memberof CartStore
   */
  get currency(): string {
    // Default from settings
    if (!this.branch?.currency || !this.branch?.currency.currency_code) return appStore.settings?.currency || 'SAR';
    // Depending on language
    return this.branch?.currency.currency_code;
  }

  /**
   * Currency code of selected branch
   *
   * @readonly
   * @memberof BranchStore
   */
  @computed get currency_code(): string {
    return this.branch.currency?.currency_code || appStore.settings?.currency || 'SAR';
  }

  /**
   * Currency position of selected branch
   *
   * @readonly
   * @memberof BranchStore
   */
  @computed get currency_position(): 'l' | 'r' {
    return this.branch?.currency?.currency_position || appStore.settings?.currency_position || 'l';
  }

  /**
   * The number to which the number after the decimal point is rounded
   *
   * @readonly
   * @type {number}
   * @memberof BranchStore
   */
  @computed get currency_divided(): number {
    return this.branch.currency?.devided || 100;
  }

  @action public setBranch = (branch: IBranch, delivery_type: string) => {
    try {
      const branchChanged = branch.id !== this.branch.id;
      const deliveryTypeChanged = delivery_type !== this.delivery_type;
      const prevBranchId = this.branch.id;
      const prevOrderType = this.delivery_type;

      this.branch = branch;
      this.branch.confirmedAt = Date.now();
      this.delivery_type = delivery_type;

      if (appStore?.settings?.donation?.enabled && !branch.id) {
        this.branch.id = appStore?.settings?.donation?.branch_id;
        this.branch.branchCovered = false;
      } else {
        this.branch.branchCovered = true;
      }

      if (appStore.mode === 'e-menu') {
        const order_types = branch.emenu?.order_types;
        this.order_type =
          !order_types?.is_dinein || !order_types?.is_togo
            ? order_types?.is_dinein
              ? E_MENU_TYPE.DINE_IN
              : E_MENU_TYPE.TO_GO
            : branchChanged
            ? null
            : this.order_type;

        this.branch.payment_types = branch.emenu?.payment_types.length
          ? branch.emenu?.payment_types
          : branch.payment_types;
        this.branch.default_payment_type =
          branch?.emenu?.payment_types.find((el) => el?.is_default)?.id || branch.default_payment_type;
      }

      /** Clear preorder time if branch was changed */
      if (branchChanged) {
        // Fetch user cards if user is login
        if (userStore.isLogin) {
          userStore.fetchUserCards();
        }
      }
      if (branchChanged || deliveryTypeChanged || this.branch.branchCovered) {
        preorderStore.clear();
        preorderStore.checkPreorderStatus(this.branch, this.delivery_type);
        productsStore.fetchMenu();
      }

      if (cartStore.products.length > 0) {
        cartStore.checkout.promocode = '';
        cartStore.calculator.promocode = '';
        // Immediately recalculate total

        if (this.branch.id !== 0) {
          cartStore.calculate(true, prevBranchId, prevOrderType);
        }
      }

      // /**
      //  * Clear cart if Branch close or remove
      //  * */
      cartStore.keepCart();
    } catch (error: any) {
      logError(error);
    } finally {
      // MOENGAGE
      logger.logEvent(
        EVENTS.SELECT_ADDRESS,
        {
          branch_id: this.branch?.id,
          branch_name: getTranslate(this.branch?.name),
          user_longitude: this.branch.longitude,
          user_latitude: this.branch.latitude,
          delivery_type: this.branch?.delivery_type,
          user_id: userStore.user?.id,
        },
        DESTINATION_LOGS.MOENGAGE
      );
    }
  };

  @action public setOrderType = (type: number) => {
    this.order_type = type;
  };

  @action public setDeliveryType = (type: string) => {
    this.delivery_type = type;
  };

  private checkBranchAvailability = async () => {
    try {
      // e-menu
      if (appStore.mode === 'e-menu') {
        const params = new URLSearchParams(window.location.search);
        const branch_id = params.get('branch_id') || this.branch.id || defaultBranch.id;

        const branch = await branchStore.fetchBranch(Number(branch_id));
        if (!branch) return;

        branchStore.setBranch(branch, DELIVERY_TYPES.PICKUP);

        return;
      } else if (this.selectMapCoordinate) {
        const response = await requests.branches.branchSearch({
          latitude: addressStore.address?.latitude,
          longitude: addressStore.address?.longitude,
          delivery_type: this.delivery_type,
        });
        let delivery_type = this.delivery_type;
        let branch = defaultBranch;

        if (!response) return;

        if (response.branch) branch = response.branch;
        if (branch.id === defaultBranch.id) delivery_type = DELIVERY_TYPES.DELIVERY;
        this.setBranch(branch, delivery_type);
      } else if (this.branch.id !== defaultBranch.id) {
        const response: IBranch | null = await requests.branches.getBranch(this.branch.id, {
          delivery_type: this.delivery_type,
        });
        if (!response) {
          this.setBranch(defaultBranch, DELIVERY_TYPES.DELIVERY);
          return;
        }
        this.setBranch(response, this.delivery_type);
      }
    } catch (error: any) {
      logError(error);
    }
  };

  afterHydration() {
    this.checkBranchAvailability();
    preorderStore.checkPreorderStatus(this.branch, this.delivery_type);
  }
}

const branchStore = new BranchStore();
export { branchStore };
