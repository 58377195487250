import * as React from 'react';
import ReactDOM from 'react-dom';

const root = document.body;
export default class DropDownPortal extends React.Component<{}> {
  componentDidMount() {
    root.appendChild(this.element);
  }

  componentWillUnmount() {
    root.removeChild(this.element);
  }

  element = document.createElement('div');
  public render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}
