import { IProductInsideCombo } from 'src/types/combo';
import { IOrderProductComboItem, IOrderProductSize } from 'src/types/orders';
import { IProductSize } from 'src/types/products';

export const getDefiniteSizeSelectedItems = (
  items: IProductInsideCombo[] | IOrderProductComboItem[],
  size?: IProductSize | IOrderProductSize | null
): any => {
  if (!items.length || !size) return [];
  return (items as any[]).filter((item) => item.is_selected && item.size_id === size.id);
};
