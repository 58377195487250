import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { WINDOW_SIZES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { IProduct, IProductSize, PRODUCT_TYPE } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { _isNaN } from 'src/utils/helpers';
import { productUtils } from 'src/utils/productUtils';
import { Cashback } from 'src/view/components/Cashback/Cashback';
import Sizes from 'src/view/components/Controls/Sizes/Sizes';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import Donation from 'src/view/components/Tooltip/Donation/Donation';
import { IProductCashbackInfo } from 'src/view/screens/ProductScreen/ProductScreen';
import ProductNotes from '../../components/ProductNotes/ProdutcNotes';
import ProductPrice from '../../components/ProductPrice/ProductPrice';
import ProductUnit from '../../components/ProductUnit/ProductUnit';
import { isAvailabilityToShowNotes } from '../../helpers/productScreen';
import ProductCashback from 'src/view/screens/ProductScreen/components/ProductCashback/ProductCashback';
import { branchStore } from 'src/mobx/barnchStore';
import './style.scss';

interface IProps {
  mdScreen?: boolean;
  product?: IProduct;
  cashback: IProductCashbackInfo;
  onNotesChanged: (notes: string) => void;
  onSizeChange?: (size: IProductSize) => void;
}

export default class ProductInfoBlock extends React.Component<IProps, {}> {
  /**
   * return list of units [calories, additional unit]
   *
   * @readonly
   * @memberof MenuProduct
   */
  get units() {
    return productUtils.getProductCalculatedUnits(this.props.product);
  }

  renderSizes = (smScreen?: boolean): JSX.Element | null => {
    if (!this.isMealCombo) return null;

    return (
      <Sizes
        onChange={this.props.onSizeChange}
        options={this.mealSizes.map((size) => ({ value: size, name: size.name, key: size.id })) || []}
        selected={this.props.product?.size ? this.props.product.size : null}
        className={smScreen ? 'order-4' : ''}
      />
    );
  };

  renderDescription = (): JSX.Element | null => {
    const { product } = this.props;

    if (!product) {
      return (
        <div className="d-flex flex-column mb-4 order-3 order-lg-4">
          <p className="product-description text-md-size-14">
            <Skeleton width={'100%'} count={5} />
          </p>

          <ResponsiveContent max={WINDOW_SIZES.md}>
            <ProductUnit product={product} />
          </ResponsiveContent>
        </div>
      );
    }

    const description: string | undefined = [...this.units, getTranslate(product.description)]
      .filter(Boolean)
      .join(' • ');

    return (
      <div className="d-flex flex-column mb-4 order-3 order-lg-4">
        {description ? (
          <p className="product-description text-md-size-14">
            <div className="d-flex flex-row flex-nowrap mt-3">
              <p dangerouslySetInnerHTML={{ __html: description }} className="product-description text-md-size-14" />
            </div>
          </p>
        ) : null}
      </div>
    );
  };

  get mealSizes(): IProductSize[] {
    const { product } = this.props;
    if (!product) return [];

    return product.sizes.filter((size) => product?.items?.some((item) => item.size_id === size.id));
  }

  get isMealCombo(): boolean {
    return this.props.product?.type === PRODUCT_TYPE.MEAL_COMBO;
  }

  get className(): string {
    let className: string = 'product-info-block product-block';

    if (!this.props.product?.type || !this.props.mdScreen) className += ' product-block-padding';
    if (this.props.mdScreen && this.props.product?.type) className += ' pxpt-6 pxpb-8 pxpl-8';

    return className;
  }

  render() {
    const { product, cashback } = this.props;
    const isDonation = !!product?.is_donation;
    const isCombo: boolean = Boolean(product?.type);
    const showNotes: boolean = isAvailabilityToShowNotes(product, true);

    return (
      <div className={this.className}>
        <div className="d-flex flex-column">
          <div className={`d-flex flex-row align-items-start  ${this.isMealCombo ? 'pxmb-3' : 'mb-4 order-1'}`}>
            <h1 className="product-name mb-0">{getTranslate(product?.name) || <Skeleton width={'70%'} />}</h1>
            <ResponsiveContent max={WINDOW_SIZES.md}>
              <ProductPrice product={product} />
            </ResponsiveContent>
          </div>
          {product?.is_donation ? (
            <div className="d-flex flex-row mb-4 order-2">
              <div className="product-donation mr-3">
                <Donation />
              </div>
              <p className="text-size-14">{localeStore.t('itemview_donation_info')}</p>
            </div>
          ) : null}
          {appStore.settings?.is_points ? (
            <div className="product-points order-4 order-lg-3">
              <p className="head">{localeStore.t('txt_points')}</p>
              <div className="d-flex flex-row flex-align-center">
                <span className="head product-point mr-4">
                  {localeStore.t('txt_earn')} {_isNaN(product?.earn) ? <Skeleton width={40} /> : product?.earn}
                </span>
                <span className="head product-point">
                  {localeStore.t('txt_redeem')} {_isNaN(product?.redeem) ? <Skeleton width={40} /> : product?.redeem}
                </span>
              </div>
            </div>
          ) : null}
          <ResponsiveContent min={WINDOW_SIZES.md}>{this.renderSizes()}</ResponsiveContent>
          {this.renderDescription()}
          <ResponsiveContent max={WINDOW_SIZES.md}>{this.renderSizes(true)}</ResponsiveContent>
        </div>

        <ResponsiveContent max={WINDOW_SIZES.md}>
          <ProductCashback product={product} branchId={branchStore.branch.id} orderType={branchStore.delivery_type} />
        </ResponsiveContent>

        <ResponsiveContent min={WINDOW_SIZES.md}>
          {showNotes ? (
            <ProductNotes
              loading={!product}
              onChange={this.props.onNotesChanged}
              value={product?.notes || ''}
              disabled={!cartStore.isEditable}
            />
          ) : null}
        </ResponsiveContent>
      </div>
    );
  }
}
