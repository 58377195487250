import React, { useCallback } from 'react';
import './style.scss';

interface PreorderTimeProps {
  /**
   * Index element in list
   *
   * Needs for {@link onSelect}
   * @type {number}
   * @memberof PreorderTimeProps
   */
  index: number;
  label: string;
  isSelected?: boolean;
  height?: number;
  className?: string;
  /**
   * Need pass {@link index}
   *
   * @memberof PreorderTimeProps
   */
  onClick?: (index: number) => void;
}

const PreorderTime: React.FC<PreorderTimeProps> = ({ label, index, height, className, isSelected, onClick }) => {
  const _onClick = useCallback(() => {
    onClick?.(index);
  }, [index, isSelected]);

  return (
    <p
      className={`preorder-time th-background-secondary family-semibold ${
        isSelected ? 'th-color_active-primary active' : ''
      } ${className ?? ''}`}
      style={{ height: `${height}px` }}
      onClick={_onClick}
    >
      {label}
    </p>
  );
};

export default PreorderTime;
