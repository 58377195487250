import { FC } from 'react';
import { observer } from 'mobx-react';
import SelectedCardProps from './hooks/SelectedCardProps';
import { ICONS } from '../../../constants/icons';
import PaymentCard from './components/PaymentCard';

type SelectedPaymentTypeProps = {
  cardIcon: boolean;
  onClick?: () => void;
  rightIcon?: ICONS;
  showDivider?: boolean;
  storeProps?: any;
};

const SelectedPaymentType: FC<SelectedPaymentTypeProps> = observer(
  ({ cardIcon, onClick, rightIcon, showDivider = true, storeProps }) => {
    const selectedCard = SelectedCardProps({ cardIcon, storeProps, rightIcon });

    if (storeProps.isOrderFree) {
      return null;
    }
    const changePaymentType = () => {
      if (onClick) onClick();
    };

    return (
      <div onClick={changePaymentType}>
        <PaymentCard {...selectedCard} icon={undefined} store={storeProps} />
      </div>
    );
  }
);

export default SelectedPaymentType;
