import * as React from 'react';
import { debounce } from 'src/utils/perfomance';

export interface IHorizontalScrollProps {
  className?: string;
}

export default class HorizontalScroll extends React.Component<IHorizontalScrollProps> {
  slider: null | React.RefObject<HTMLDivElement> = React.createRef();

  isDown: boolean = false;
  scrollLeft: number = 0;
  startX: number = 0;
  scrolling: boolean = false;
  componentDidMount = () => {
    if (this.slider?.current) {
      this.slider.current.addEventListener('mousedown', this.handleMouseDown);
      this.slider.current.addEventListener('mouseleave', this.handleMouseOut);
      this.slider.current.addEventListener('mouseup', this.handleMouseOut);
      this.slider.current.addEventListener('mousemove', this.handleMove);
      this.slider.current.addEventListener('click', this.handleMouseClick);
    }
  };

  componentWillUnmount = () => {
    if (this.slider?.current) {
      this.slider.current.removeEventListener('mousedown', this.handleMouseDown);
      this.slider.current.removeEventListener('mouseleave', this.handleMouseOut);
      this.slider.current.removeEventListener('mouseup', this.handleMouseOut);
      this.slider.current.removeEventListener('mousemove', this.handleMove);
      this.slider.current.removeEventListener('click', this.handleMouseClick);
    }
  };

  handleMouseClick = (event: MouseEvent) => {
    if (this.scrolling) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.slider?.current) {
      this.isDown = true;
      this.startX = event.pageX - this.slider.current.offsetLeft;
      this.scrollLeft = this.slider.current.scrollLeft;
    }
  };
  handleMouseOut = () => {
    if (this.slider?.current) {
      this.isDown = false;
    }
  };

  handleMove = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.slider?.current) {
      if (!this.isDown) return;

      this.scrolling = true;
      const x = event.pageX - this.slider.current.offsetLeft;
      const walk = (x - this.startX) * 1; // scroll-fast
      this.slider.current.scrollLeft = this.scrollLeft - walk;
      this.stopScrolling();
    }
  };

  handleStopScrolling = () => (this.scrolling = false);
  stopScrolling = debounce(this.handleStopScrolling, 100);

  public render() {
    return (
      <div
        ref={this.slider}
        style={{
          maxWidth: '100%',
          overflowX: 'auto',
        }}
        className={`hide-scrollbar ${this.props.className || ''}`}
      >
        {this.props.children}
      </div>
    );
  }
}
