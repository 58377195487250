import React, { ChangeEvent } from 'react';
import Input from '../../../../components/Controls/Input/Input';
import Icon from '../../../../components/Controls/Icon/Icon';
import { localeStore } from '../../../../../mobx/localesStore';
import { ICONS } from '../../../../../constants/icons';

type SearchInputProps = {
  search: string;
  setSearch: (event: ChangeEvent<HTMLInputElement>) => void;
};

const SearchInput = ({ search, setSearch }: SearchInputProps) => (
  <Input
    className={'search-input'}
    inputClassName={'background-light color-black'}
    iconContainerStyle={{ top: '15px', left: '18px' }}
    style={{ fontFamily: 'PrimaryRegular' }}
    icon={<Icon name={ICONS.SEARCH} size={20} />}
    placeholder={localeStore.t('Search')}
    value={search}
    onChange={setSearch}
  />
);

export default SearchInput;
