import { Component } from 'react';
import { EVENTS } from 'src/constants/events';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import logger from 'src/services/logger';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';

interface Props {
  closeModal?: () => void;
}

export default class LogoutContainer extends Component<Props> {
  logout = async () => {
    try {
      userStore.logout();
      logger.logEvent(EVENTS.AUTH_LOGGED_OUT);
      if (this.props.closeModal) this.props.closeModal();
    } catch (error: any) {
      console.error(error);
    }
  };

  render() {
    return (
      <div className="logout-modal background-white">
        <div className="logout-wrapper">
          <button className="logout-close" onClick={this.props.closeModal}>
            <Icon name={ICONS.CLOSE} className={'color-gray'} size={14} />
          </button>
          <div className="logout-content">
            <h2 className="logout-title family-semibold color-dark mb-4 mb-lg-0">{localeStore.t('are_you_sure')}</h2>
          </div>

          <ButtonComponent
            onClick={this.logout}
            className="background-primary logout-button"
            title={localeStore.t('menu_logout')}
          />
        </div>
      </div>
    );
  }
}
