import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';
import { Carousel } from 'src/view/components/Carousel/Carousel';
import PreorderMonth from '../../components/PreorderMonth/PreorderMonth';
import { preorderPickerStore } from '../../store/store';

const PreorderMonths: React.FC = observer(() => {
  const carouselRef = useRef(null);

  useEffect(() => {
    try {
      // Scroll to selected mo
      if (carouselRef?.current) (carouselRef.current as any)?.goTo(preorderPickerStore.month);
    } catch (error) {
      console.error(error);
    }
  }, [preorderPickerStore.selectedMonth]);

  /**
   * Call selectMonth when click on arrow and change month
   * @type {*}
   */
  const onArrowClick = useCallback((prev, next: any) => {
    preorderPickerStore.selectMonth(next.index);
  }, []);

  if (!preorderPickerStore.months.length) return null;

  return (
    <div>
      <Carousel
        className={`preorder-month-picker`}
        ref={carouselRef}
        onNextStart={onArrowClick}
        onPrevStart={onArrowClick}
      >
        {preorderPickerStore.months.map((month) => (
          <PreorderMonth key={month.label} data={month} />
        ))}
      </Carousel>
    </div>
  );
});

export default PreorderMonths;
