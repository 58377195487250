import ButtonComponent from '../../../../../../../../components/Controls/Button/Button';
import { localeStore } from '../../../../../../../../../mobx/localesStore';
import React from 'react';
import { cartStore } from '../../../../../../../../../mobx/cartStore';

const BottomPanel = () => {
  const closeModal = () => {
    cartStore.setModalValue('confirmPickUp', false);
  };

  const confirm = async () => {
    cartStore.setModalValue('confirmPickUp', false);

    await cartStore.placeOrder();
    return null;
  };

  return (
    <div className={'d-flex justify-content-between'}>
      <ButtonComponent
        disabled={false}
        loading={false}
        className="full-width confirm-btn"
        type={'submit'}
        id={'verify'}
        onClick={confirm}
        title={localeStore.t('txt_confirm')}
      />
      <ButtonComponent
        disabled={false}
        loading={false}
        className="full-width confirm-btn background-light color-dark"
        type={'reset'}
        id={'verify'}
        onClick={closeModal}
        title={localeStore.t('Close')}
      />
    </div>
  );
};

export default BottomPanel;
