import React from 'react';
import moment from 'moment';
import { localeStore } from '../../../../mobx/localesStore';

interface IProps {
  cashback?: {
    cashback_amount: string;
    available: string;
  }
}

export const WalletCashback: React.FC<IProps> = ({
  cashback
}) => {
  if (!cashback || !cashback.available) return null;

  return (
    <>
      <div className="d-flex flex-row justify-content-between mt-3">
        <p className="text-size-18">{localeStore.t('cashback')}</p>
        <p className="text-size-18">{cashback.cashback_amount}</p>
      </div>
      <div className="d-flex flex-row justify-content-between mt-2">
        <p className="text-size-12 text-gray text-uppercase color-gray">
          {localeStore.t('balance_available_on')}
        </p>
        <p className="text-size-12 text-gray text-uppercase color-gray">
          {moment(cashback.available).format('DD.MM.YYYY')}
        </p>
      </div>
    </>
  );
};
