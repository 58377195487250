import React, { Component } from 'react';

interface Props {
  time: number;
  autorun: boolean;
  onStart?: () => void;
  onFinished?: () => void;
  className?: string;
}
interface State {
  timer?: number;
  time: number;
  inprogress: boolean;
}

export default class CountDown extends Component<Props, State> {
  timer: NodeJS.Timeout | null = null;
  state = {
    time: this.props.time,
    inprogress: false,
  };

  componentDidMount = () => {
    if (this.props.autorun) this.startTimer();
  };

  startTimer = () => {
    if (this.props.onStart) this.props.onStart();
    if (this.timer) clearTimeout(this.timer);
    this.setState({ timer: this.props.time, inprogress: true });
    this.timer = setTimeout(this.tick, 1000);
  };

  stopTimer = () => {
    if (this.props.onFinished) this.props.onFinished();
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.setState({ timer: this.props.time, inprogress: false });
  };

  tick = () => {
    const time = this.state.time - 1;
    if (time <= 0) {
      return this.stopTimer();
    }
    this.setState({ time });
    this.timer = setTimeout(this.tick, 1000);
  };

  render() {
    return <div className={this.props.className}>{this.state.time}</div>;
  }
}
