import { tryPaymentAgainStore } from '../../../../store';
import PaymentCardModal from '../../../../../../containers/PaymentCardModal/PaymentCardModal';
import PopupModal from '../../../../../../components/Controls/Popup/Popup';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

const PaymentCardPopup = observer(() => {
  const showAddToCard = useMemo(() => {
    return tryPaymentAgainStore.PaymentMethod?.showAddToCard;
  }, [tryPaymentAgainStore.PaymentMethod?.showAddToCard]);
  const loadingNewCard = useMemo(() => {
    return tryPaymentAgainStore.loadingNewCard;
  }, [tryPaymentAgainStore.loadingNewCard]);

  return (
    <PopupModal
      position={'bottom'}
      direction={'down'}
      justify={'center'}
      selfClose
      backdrop
      backdropClose
      visible={showAddToCard}
      onClose={() => tryPaymentAgainStore.PaymentMethod?.showAddToCardModal(false)}
      content={() => (
        <PaymentCardModal
          onSubmit={tryPaymentAgainStore.addNewCard}
          closeModal={() => tryPaymentAgainStore.PaymentMethod?.showAddToCardModal(false)}
          loading={loadingNewCard}
        />
      )}
      mobile={{
        popup: true,
        sheet: true,
      }}
    />
  );
});
export default PaymentCardPopup;
