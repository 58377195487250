import { observer } from 'mobx-react';
import { FC, ReactElement, memo } from 'react';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { IProduct } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import './style.scss';

interface IProps {
  product: IProduct;
}

export const CartProductHeaderName: FC<IProps> = memo(
  observer(({ product }: IProps): ReactElement => {
    return (
      <div className="cart-product-header__name-wrapper">
        <p className="cart-product-header__name color-dark text-lite-elipsis">{getTranslate(product.name)}</p>

        {!cartStore.isEditable ? (
          <bdo className="cart-product-header__quantity color-gray" dir={localeStore.direction}>
            {`x${product.quantity}`}
          </bdo>
        ) : null}
      </div>
    );
  })
);
