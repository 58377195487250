import { useCallback, useEffect, useState } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { debounce } from 'src/utils/perfomance';

import client from '../../../../services/client';
import { IPagination } from '../../../../types/main';
import { IOrder } from '../../../../types/orders';

const useOrders = (branchId?: number) => {
  const defaultPagination: IPagination<IOrder> = {
    current_page: 0,
    last_page: 1,
    data: [],
    from: 0,
    to: 0,
    total: 0,
    loading: false,
  };

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState<IPagination<IOrder>>(defaultPagination);

  const clear = () => setPagination(defaultPagination);

  useEffect(() => {
    searchOrders();
  }, [search]);

  /**
   * For search orders
   *
   * Clear previous pagination data and add new
   */
  const searchOrders = useCallback(async () => {
    const params: { page?: number; order_key?: string; branch_id?: number } = {
      page: 1,
    };

    if (branchId) params.branch_id = branchId;

    if (search !== '') {
      params.order_key = search;
    }

    setPagination({
      ...pagination,
      data: [],
      current_page: 0,
      last_page: 1,
      loading: true,
    });

    try {
      const response: IPagination<IOrder> = await client.get('/v3/me/orders', {
        params,
        headers: { 'Accept-Language': localeStore.language },
      });
      setPagination({ ...response, data: [...response.data], loading: false });
    } catch (e) {
      console.error(e);
    }
  }, [search, pagination, client]);

  /**
   * For lazy load
   */
  const fetchOrders = useCallback(
    async (page?: number): Promise<void> => {
      const params: { page?: number; order_key?: string; branch_id?: number } = {
        page: page || pagination.current_page + 1,
      };

      if (branchId) params.branch_id = branchId;

      if (search !== '') {
        params.order_key = search;
      }

      setPagination({ ...pagination, loading: true });

      try {
        const response: IPagination<IOrder> = await client.get('/v3/me/orders', {
          params,
          headers: { 'Accept-Language': localeStore.language },
        });
        setPagination({ ...response, data: [...pagination.data, ...response.data], loading: false });
      } catch (e) {
        console.error(e);
      }
    },
    [search, pagination, client]
  );

  return { pagination, fetchOrders, clear, search, setSearch: debounce(setSearch, 300) };
};

export default useOrders;
