import axios, { Canceler } from 'axios';
import { action, computed, observable } from 'mobx';
import { requests } from 'src/requests';
import client from 'src/services/client';
import { ICurrencySettings } from 'src/types/app';
import { IPagination } from 'src/types/main';
import { formatPrice } from 'src/utils/formatter';
import { logError } from 'src/utils/helpers';
import { ORDER_STATUS } from '../../constants/main';
import { IOrder } from '../../types/orders';
import { localeStore } from '../localesStore';
import { tryPaymentAgainStore } from '../../view/screens/TryPaymentAgainScreen/store';
import TimerHandler from '../functionalStores/timer';
import { driverSearchStore } from 'src/view/screens/DriverSearchScreen/store';

export class OrdersStore {
  cancel: Canceler | null = null;
  @observable order: IOrder | null = null;
  countDownTimer = new TimerHandler();
  @observable public pagination: IPagination<IOrder> = {
    current_page: 0,
    last_page: 1,
    data: [],
    from: 0,
    to: 0,
    total: 0,
    loading: false,
  };

  /**
   *
   * Currency and currency position
   *
   * @readonly
   * @memberof OrdersStore
   */
  @computed get currency_settings(): ICurrencySettings {
    return { currency: this.order?.currency_code, currency_position: this.order?.currency_position };
  }

  /**
   *
   * Format Price for {@link order}
   *
   * Take currency and currency position from {@link order}
   *
   * If {@link order} is undefined, calls just {@link formatPrice}
   *
   * @export
   * @param {string} price - price of something
   * @return {*}  {string} - formated price
   */ public formatPrice(price: string | number): string {
    if (this.order) {
      return formatPrice(
        price,
        this.order?.currency?.currency_code || this.order.currency_code,
        this.order?.currency?.currency_position || this.order.currency_position,
        this.order?.currency?.devided
      );
    }
    return formatPrice(price);
  }

  @action selectOrder = (order: IOrder | null) => {
    this.order = order;
  };

  @action fetchOrders = async (page?: number) => {
    try {
      this.pagination.loading = true;
      const pagination = await client.get('/v3/me/orders', {
        params: { page: page || this.pagination.current_page + 1 },
        cancelToken: new axios.CancelToken((c) => (this.cancel = c)),
        headers: { 'Accept-Language': localeStore.language },
      });
      this.pagination = {
        ...pagination,
        loading: false,
        data: [...this.pagination.data, ...pagination.data],
      };
    } catch (err) {
      logError(err);
      this.pagination.loading = false;
    }
  };

  @action updateOrder = async (order: IOrder) => {
    try {
      this.pagination.data = this.pagination.data.map((_order) => {
        if (_order.id === order.id) {
          return { ..._order, ...order };
        }
        return _order;
      });
      if (this.order?.id === order.id) {
        this.order = {
          ...this.order,
          ...order,
        };
      }

      if (driverSearchStore.opened) {
        if (driverSearchStore.order?.id === order.id) {
          driverSearchStore.define(order.id, order);
        }
      }

      if (tryPaymentAgainStore.opened) {
        if (tryPaymentAgainStore.order?.id === order.id) {
          tryPaymentAgainStore.define(order.id, order);
        }
      }
    } catch (err) {
      logError(err);
      this.pagination.loading = false;
    }
  };

  @action setClientArrived = async (id: IOrder['id']): Promise<boolean> => {
    try {
      const response = await requests.clientArrivedRequest(id);
      if (response.result && response.data?.client_arrived && response.data?.id === id) {
        this.pagination = {
          ...this.pagination,
          data: this.pagination.data.map((order) => {
            if (order.id === id) {
              return {
                ...order,
                curbside: order?.curbside
                  ? {
                      ...order?.curbside,
                      client_arrived: 1,
                    }
                  : {
                      driver_name: '',
                      car_brand: '',
                      car_color: '',
                      car_type: '',
                      car_number: '',
                      curbside_fee: 0,
                      client_arrived: 1,
                      client_arrived_at: null,
                    },
              };
            }
            return order;
          }),
        };
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  @action cancelOrder = async (id: IOrder['id']) => {
    try {
      await client.post(`/v3/me/orders/${id}/cancel`);
      const orderIdx = this.pagination.data.findIndex((order) => order.id === id);
      if (~orderIdx) {
        const order = {
          ...this.pagination.data[orderIdx],
          order_status_code: ORDER_STATUS.CANCELED,
          status: localeStore.t('Cancelled'),
        };

        this.updateOrder(order);
      }
    } catch (err) {
      throw err;
    }
  };

  @action clear = (cancelable: boolean) => {
    if (cancelable && this.cancel) this.cancel();
    this.pagination = {
      current_page: 0,
      last_page: 1,
      data: [],
      from: 0,
      to: 0,
      total: 0,
      loading: false,
    };
  };
}

export const ordersStore = new OrdersStore();
