export type TTopicKeys =
  | 'ROOM'
  | 'SETTINGS'
  | 'ORDER'
  | 'BRANCH'
  | 'RESERVATION'
  | 'PRODUCT'
  | 'ADMIN_ORDERS'
  | 'ADMIN_BRANCH'
  | 'ADMIN_PRODUCTS'
  | 'ADMIN_RESERVATIONS';
export type TTopicValues =
  | 'room'
  | 'settings'
  | 'order'
  | 'branch'
  | 'reservation'
  | 'product'
  | 'admin_orders'
  | 'admin_branches'
  | 'admin_products'
  | 'admin_reservations';

type TTopics = {
  [key in TTopicKeys]: TTopicValues;
};

export const TOPICS: TTopics = {
  ROOM: 'room',
  BRANCH: 'branch',
  PRODUCT: 'product',
  SETTINGS: 'settings',
  ORDER: 'order',
  RESERVATION: 'reservation',

  ADMIN_ORDERS: 'admin_orders',
  ADMIN_BRANCH: 'admin_branches',
  ADMIN_PRODUCTS: 'admin_products',
  ADMIN_RESERVATIONS: 'admin_reservations',

  // ADMIN_SETTINGS: 'admin:settings',
};
