import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';

interface Props {
  lat?: number;
  lng?: number;
  loading: boolean;
  address: string | undefined;
}

export default class AddressPopup extends Component<Props> {
  renderInfo = () => {
    const { address, loading } = this.props;

    if (loading) {
      return (
        <div className="branches-popup">
          <div className="d-flex flex-column ">
            <Skeleton className="text-size-20 color-dark text-center mb-2 d-flex" width={'100%'} />
            <Skeleton className="text-size-20 color-dark text-center" width={'100%'} />
          </div>
        </div>
      );
    }

    if (!address)
      return (
        <div className="branches-popup">
          <p className="branches-text family-regular">{localeStore.t('txt_branch_is_closed_now')}</p>
        </div>
      );

    return (
      <div className="branches-popup">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <p className="text-size-20 color-dark text-center">{address}</p>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className="background-primary branches-current">
        <Icon className="branches-marker color-danger" name={ICONS.MARKER} />
        {this.renderInfo()}
      </div>
    );
  }
}
