import React, { FC, useCallback, useMemo } from 'react';
import Icon from '../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../constants/icons';
import { branchStore } from '../../../../../mobx/barnchStore';

import './style.scss';
import { searchStore } from '../../../SearchScreen/searchStore';
import { DELIVERY_TYPES } from '../../../../../constants/deliveryTypes';
import { addressStore } from '../../../../../mobx/barnchStore/address';
import branchesScreenStore from '../../branchesScreenStore';
import { VIEW_MODE } from '../../../../../types/address';
import { userStore } from '../../../../../mobx/userStore';
import { observer } from 'mobx-react';
interface IMapBtn {
  deliveryType: string;
}

const NavigationMapButton: FC<IMapBtn> = observer(({ deliveryType }: IMapBtn): JSX.Element | null => {
  const backTo = useCallback(() => {
    if (deliveryType === DELIVERY_TYPES.PICKUP) {
      branchesScreenStore.backHandler();
    }
    if (deliveryType === DELIVERY_TYPES.DELIVERY) {
      if (branchesScreenStore.viewMode === VIEW_MODE.EDIT) {
        addressStore.toggleAddressOnMap(false);
      } else {
        branchesScreenStore.backHandler();
        branchStore.togglePickupMap(false);
      }
    }
    branchStore.togglePickupMap(false);
  }, []);
  const search = useCallback(() => {
    searchStore.toggleOpen(true);
  }, []);
  const isShowBackButton = useMemo(() => {
    return (
      [VIEW_MODE.CREATE, VIEW_MODE.EDIT].includes(branchesScreenStore.viewMode) &&
      userStore.isLogin &&
      deliveryType === DELIVERY_TYPES.DELIVERY &&
      Boolean(addressStore.list.length)
    );
  }, [branchesScreenStore.viewMode, addressStore.list, userStore.isLogin, deliveryType]);
  return (
    <div className={`mobile-map-top-navigation ${isShowBackButton ? 'with-back-btn' : ''}`}>
      {isShowBackButton ? (
        <div className="search-map-button" onClick={backTo}>
          <Icon name={ICONS.ARROW_LEFT} size={20} className="btn-map-icon color-dark" />
        </div>
      ) : null}

      <div className="search-map-button" onClick={search}>
        <Icon name={ICONS.SEARCH} className="btn-map-icon color-dark" />
      </div>
    </div>
  );
});

export default NavigationMapButton;
