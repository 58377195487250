import React from 'react';
import Image from '../../Image/Image';
import './style.scss';

interface IProps {
  className?: string;
}
export default class Donation extends React.PureComponent<IProps, {}> {
  render() {
    return (
      <Image
        src={`${process.env.REACT_APP_CLOUD_FRONT}/media/images/donation128x40.png`}
        aspectRatio={3}
        wrapperClassName={`donation ${this.props.className || ''}`}
        title="Donation"
        alt="Donation"
      />
    );
  }
}
