import { observer } from 'mobx-react';
import { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { localeStore } from 'src/mobx/localesStore';
import client from 'src/services/client';
import { extractHtmlContent, getRestaurantName, setMetaTags } from 'src/utils/helpers';
import InnerHtml from 'src/view/components/InnerHtml/InnerHtml';
import ScreenWrapper from 'src/view/containers/ScreenWrapper/ScreenWrapper';

@observer
export default class AboutScreen extends Component {
  state = {
    loading: false,
    template: '',
  };

  componentDidMount = async () => {
    try {
      this.setState(() => ({ loading: true }));
      const { template } = await client.get('/v3/info/about');
      this.setState(() => ({ loading: false, template }));
    } catch (error: any) {
      this.setState(() => ({ loading: false }));
    }

    setMetaTags({
      title: `${getRestaurantName()} - ${localeStore.t('txt_about')}`,
      keywords: `${getRestaurantName()}, ${localeStore.t('txt_about')}`,
      'og:title': `${getRestaurantName()} - ${localeStore.t('txt_about')}`,
      description: `${extractHtmlContent(this.state.template)}`,
      'og:description': `${extractHtmlContent(this.state.template)}`,
    });
  };

  render() {
    if (this.state.loading || !localeStore.timestamp) {
      return (
        <div className="d-flex flex-column full-width">
          <Skeleton width={'40%'} height={36} className="mt-5" />

          <div className="mt-5">
            <Skeleton className={'text-size-18'} width={'100%'} count={8} style={{ marginBottom: 10 }} />
            <Skeleton width={'55%'} count={1} style={{ marginBottom: 10 }} />
          </div>
        </div>
      );
    }
    return (
      <ScreenWrapper>
        <div className="full-width">
          <InnerHtml html={this.state.template} />
        </div>
      </ScreenWrapper>
    );
  }
}
