import { ChangeEvent, Component } from 'react';
import { EVENTS } from 'src/constants/events';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import logger from 'src/services/logger';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';

interface Props {
  loading?: boolean;
  closeModal?: () => void;
  onSubmit: (number: string, month: string, year: string, cvv: string) => void;
}
interface State {
  card: {
    number: string;
    year: string;
    month: string;
    cvv: string;
  };
  view: {
    number: string;
    date: string;
    cvv: string;
  };
  errors: string[];
  valid: boolean;
}

export default class PaymentCardModal extends Component<Props, State> {
  state: State = {
    card: {
      number: '',
      year: '',
      month: '',
      cvv: '',
    },
    view: {
      number: '',
      date: '',
      cvv: '',
    },
    valid: false,
    errors: [],
  };

  changeNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: number } = event.target;
    let view_number = number.replace(/[^\d\s]+/, '').replace(/\s{2,}/, ' ');
    let card_number = number.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = card_number.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      view_number = parts.join(' ');
    } else {
      view_number = view_number.slice(0, 16);
    }
    card_number = card_number.slice(0, 16);

    this.setState((state) => ({
      card: { ...state.card, number: card_number },
      view: { ...state.view, number: view_number },
    }));
  };

  timer: null | NodeJS.Timeout = null;

  changeDate = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: date } = event.target;
    let month = '';
    let year = '';
    const value = date.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    if (value.length <= 2) {
      month = value;
    } else {
      month = value.slice(0, 2);
      year = value.slice(2);
    }

    this.setState((state) => ({
      card: { ...state.card, year: `20${year}`, month },
      view: { ...state.view, date: year ? `${month}/${year}` : `${month}` },
    }));
  };

  maskCVV = (cvv: any) => {
    const newCvv = cvv.replaceAll(/[0-9]/g, '*');
    this.setState((state) => ({
      view: { ...state.view, cvv: newCvv },
    }));
  };

  changeCVV = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.timer) clearTimeout(this.timer);
    const { value } = event.target;
    const cvv = value.replace(/\s+/g, '').replace(/[^0-9,*]/gi, '');

    let cvvCard = value;
    if (this.state.card.cvv.length) {
      cvvCard = cvv.split('').reduce((acc, current, index) => {
        return (acc += current === '*' ? this.state.card.cvv[index] : current);
      }, '');
    }

    this.setState((state) => ({
      card: { ...state.card, cvv: cvvCard },
      view: { ...state.view, cvv },
    }));

    this.timer = setTimeout(() => {
      this.maskCVV(cvv);
    }, 500);
  };

  onFieldChange = () => {
    const errors = this.validate();
    this.setState({ valid: !errors.length });
  };

  validate = () => {
    const errors = [];
    if (this.state.card.number.length !== 16) {
      errors.push('card');
    }
    if (this.state.card.cvv.length < 3) {
      errors.push('cvv');
    }
    if (this.state.card.month.length < 2 || this.state.card.year.length < 4) {
      errors.push('date');
    }
    return errors;
  };

  submit = () => {
    const errors = this.validate();
    if (errors.length) {
      this.setState({ errors });
      return;
    }
    this.setState({ errors: [] });

    logger.logEvent(EVENTS.USER_PAYMENT_CARD_ADDED);
    this.props.onSubmit(this.state.card.number, this.state.card.month, this.state.card.year, this.state.card.cvv);
  };

  render() {
    return (
      <div dir="ltr" className="payment-modal  background-white">
        <div className="payment-wrapper pb-3 px-3 py-lg-4 px-lg-4">
          <button className="payment-close" onClick={this.props.closeModal}>
            <Icon name={ICONS.CLOSE} color={'#6D6D6D'} size={14} />
          </button>

          <div className="payment__form d-flex flex-column ">
            <h2 className="title family-semibold color-text mb-3 text-center">
              {localeStore.t('txt_enter_card_details')}
            </h2>
            <div className="d-flex flex-column">
              <input
                value={this.state.view.number}
                className={`input mb-3 full-width ${this.state.errors.includes('card') ? 'error' : ''}`}
                onChange={this.changeNumber}
                maxLength={19}
                placeholder={localeStore.t('txt_card_number')}
              />
              <div className="d-flex flex-row">
                <input
                  value={this.state.view.date}
                  className={`input mb-3 full-width ${this.state.errors.includes('date') ? 'error' : ''}`}
                  onChange={this.changeDate}
                  maxLength={5}
                  placeholder={localeStore.t('MM/YY')}
                />
                <div className="mr-4"></div>
                <input
                  type="text"
                  value={this.state.view.cvv}
                  className={`input mb-3 full-width ${this.state.errors.includes('cvv') ? 'error' : ''}`}
                  onChange={this.changeCVV}
                  maxLength={4}
                  placeholder={localeStore.t('txt_CVV')}
                />
              </div>
            </div>
          </div>
          <ButtonComponent
            onClick={this.submit}
            loading={this.props?.loading}
            title={localeStore.t('txt_submit')}
            className={'full-width'}
          />
        </div>
      </div>
    );
  }
}
