import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { ICONS } from 'src/constants/icons';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { preorderStore } from 'src/mobx/preorderStore';
import { getUnitKey } from 'src/utils/helpers';
import Icon from 'src/view/components/Controls/Icon/Icon';

type Props = {};

const CheckoutPreorder = observer((props: Props) => {
  /**
   * This means that the field is clickable and a modal window will be shown.
   * @type {boolean}
   */
  const preorderAvailable = useMemo(
    () => !cartStore.isSMS && appStore.mode !== 'e-menu' && preorderStore.preorderIsAvailable,
    [cartStore.isSMS, appStore.mode, preorderStore.preorderIsAvailable]
  );

  const defaultTime = useMemo(() => {
    if (branchStore.branch?.preparation) {
      const preparation =
        branchStore.delivery_type === DELIVERY_TYPES.DELIVERY
          ? branchStore.branch.preparation.delivery
          : branchStore.branch.preparation.pickup;

      const unit_key = getUnitKey(preparation.gap, preparation.unit || '');
      return `~ ${preparation.gap} ${localeStore.t(unit_key || '')}`;
    }
    return null;
  }, [branchStore.branch?.preparation, branchStore.delivery_type]);

  const handlePreorderTime = useCallback(() => {
    if (!cartStore.isSMS && appStore.mode !== 'e-menu' && preorderAvailable) {
      cartStore.setModalValue('time', true);
    }
  }, [cartStore.isSMS, appStore.mode, preorderAvailable]);

  return (
    <div className="d-flex flex-row section-item mb-0  order-2 " onClick={handlePreorderTime}>
      <Icon name={ICONS.CALENDAR} className="color-dark mr-3" />
      <div className="d-flex flex-row justify-content-between full-width align-items-center">
        <div className="d-flex flex-column">
          <p className="text-size-10 family-semibold text-uppercase color-dark mb-1">{localeStore.t('order_time')}</p>
          <p id={'checkout_address'} className="text-size-14 family-semibold color-dark">
            {preorderStore.preorder_time ?? defaultTime}
          </p>
        </div>
        {preorderAvailable ? <Icon name={ICONS.CHEVRON} className="color-dark arrow-right" /> : null}
      </div>
    </div>
  );
});
export default CheckoutPreorder;
