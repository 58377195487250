import { IProductInsideCombo } from 'src/types/combo';
import {
  IProduct,
  IProductExclusion,
  IProductModifier,
  IProductModifierOption,
  IProductSize,
  PRODUCT_TYPE,
} from 'src/types/products';
import { hash } from 'src/utils/helpers/hash';
import { getMealItemKey } from '../combo';

interface IModifierOptionHash {
  id: number;
  quantity: number;
  price: number;
}

type TModifiersHash = Record<string, { options: IModifierOptionHash[] }>;

type TComboItemHash = Record<string, { modifiers: TModifiersHash; exclusions: number[] }>;

export const getProductHash = (product: IProduct): string => {
  const isMealCombo: boolean = product.type === PRODUCT_TYPE.MEAL_COMBO;

  const json = {
    id: product.id,
    price: product.price,
    notes: product.notes ? product.notes : null,
    size: product.size ? product.size : null,
    options: _modifiersHash(product.modifiers),
    exclusions: _exclusionsHash(product.exclusions),
    items: _comboItemsHash(product.items, (product.size as IProductSize)?.id || 0, isMealCombo),
  };

  return hash(JSON.stringify(json));
};

const _modifiersHash = (modifiers: IProductModifier[] | undefined): TModifiersHash => {
  if (!modifiers?.length) return {};

  return modifiers.reduce((sum: TModifiersHash, current) => {
    if (!current.options.length) sum[String(current.id)] = { options: [] };

    sum[String(current.id)] = {
      options: _modifierOptionsHash(current.options),
    };

    return sum;
  }, {});
};

const _modifierOptionsHash = (options: IProductModifierOption[]): IModifierOptionHash[] => {
  if (!options.length) return [];

  return options.reduce((sum: IModifierOptionHash[], current) => {
    if (current.selected) sum.push({ id: current.id, quantity: current.quantity, price: current.price });

    return sum;
  }, []);
};

const _exclusionsHash = (exclusions: IProductExclusion[] | undefined): number[] => {
  if (!exclusions?.length) return [];

  return exclusions.reduce((sum: number[], current) => {
    if (current.selected) sum.push(current.id);

    return sum;
  }, []);
};

const _comboItemsHash = (
  items: IProductInsideCombo[] | undefined,
  sizeId: number | undefined,
  isMealCombo: boolean
): TComboItemHash | number[] => {
  if (!items) return [];

  if (isMealCombo) {
    return items.reduce((sum: TComboItemHash, current: IProductInsideCombo) => {
      if (current.is_selected && current.size_id === sizeId) {
        sum[getMealItemKey(current)] = {
          modifiers: _modifiersHash(current.modifiers),
          // TODO: combo exclusions
          // exclusions: _exclusionsHash(current.exclusions),
          exclusions: [],
        };
      }

      return sum;
    }, {});
  }

  return items.map((item) => item.id);
};
