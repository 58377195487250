import { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';

interface IProps<T> {
  sortField: T;
  name: T,
  sortDir: 'asc' | 'desc';
  onChange: (sortField: T) => void;
}

export default class ProductsSortingButton<T> extends Component<IProps<T>, {}> {
  onClick = () => {
    this.props.onChange(this.props.name);
  };

  render() {
    const active = this.props.name === this.props.sortField
    return (
      <button className="products-sorting-button d-flex flex-row" onClick={this.onClick}>
        {this.props.children}
        <div  className="products-sorting-button__arrow ml-2">
          <Icon
            name={ICONS.SORT_ARROW_TOP}
            className={`products-sorting-button__arrow-ascending ${
              active && this.props.sortDir === 'asc' ? 'products-sorting-button__arrow-ascending_active color-primary' : ''
            }`}
          ></Icon>
          <Icon
            name={ICONS.SORT_ARROW_TOP}
            className={`products-sorting-button__arrow-descending ${
              active && this.props.sortDir === 'desc' ? 'products-sorting-button__arrow-descending_active  color-primary' : ''
            }`}
          ></Icon>
        </div>
      </button>
    );
  }
}
