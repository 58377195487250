import { IProductModifier, IProductModifierOption } from 'src/types/products';

export const calculateMenuProductModifiersPrice = (modifiers: IProductModifier[] | undefined): number => {
  if (!modifiers?.length) return 0;

  return modifiers.reduce((sum: number, current) => {
    current.options?.forEach((option: IProductModifierOption) => {
      if (option.selected || option.is_selected) sum = sum + option.price * (option.quantity || 1);
    });

    return sum;
  }, 0);
};
