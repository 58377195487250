import { FC, ReactElement, memo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import './style.scss';

interface IProps {
  notes: string | undefined;
}

export const OrderProductNotes: FC<IProps> = memo(({ notes }: IProps): ReactElement | null => {
  if (!notes) return null;

  return (
    <div className="order-product-notes">
      <p className="order-product-notes__title color-dark">{localeStore.t('order_notes')}</p>
      <p className="order-product-notes__text color-dark">{notes}</p>
    </div>
  );
});
