import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { PAYMENT_TYPES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { formatPrice } from 'src/utils/formatter';
import LoadingCircle from '../../../LoadingCircle/LoadingCircle';

function CheckoutTotalInfoPoints() {
  if (!appStore.settings?.is_points) return null;

  return cartStore.checkout.payment_type === PAYMENT_TYPES.POINTS ? (
    // Payment type is points
    <React.Fragment>
      <div className="checkout-total-info-container my-1">
        <p className={'color-dark family-semibold text-size-14'}>{localeStore.t('txt_balance_points')}</p>
        <p id="checkout_balance_points" className={'color-dark family-semibold text-size-14 text-uppercase'}>
          <LoadingCircle>{formatPrice(userStore.user.points, localeStore.t('txt_points_currency'))}</LoadingCircle>
        </p>
      </div>
      <div className="checkout-total-info-container my-1">
        <p className={'color-dark family-semibold text-size-14'}>{localeStore.t('txt_redeem_points')}</p>
        <p id="checkout_redeem_points" className={'color-dark family-semibold text-size-14 text-uppercase'}>
          <LoadingCircle>
            {formatPrice(cartStore.calculator.redeem_points, localeStore.t('txt_points_currency'))}
          </LoadingCircle>
        </p>
      </div>
    </React.Fragment>
  ) : (
    // Payment type not points
    <div className="checkout-total-info-container">
      <p className={'color-dark family-semibold text-size-14'}>{localeStore.t('txt_earn_points')}</p>
      <p id="checkout_earn_points" className={'color-dark family-semibold text-size-14 text-uppercase'}>
        <LoadingCircle>
          {formatPrice(cartStore.calculator.earn_points, localeStore.t('txt_points_currency'))}
        </LoadingCircle>
      </p>
    </div>
  );
}
export default observer(CheckoutTotalInfoPoints);
