import React, { useMemo } from 'react';
import { ICONS } from 'src/constants/icons';
import { getStatusColor } from '../../../utils/helpers';
import Icon from '../Controls/Icon/Icon';
import './style.scss';
import { ORDER_STATUS } from '../../../constants/main';
import { localeStore } from '../../../mobx/localesStore';

interface IProps {
  status: string;
  statusCode: string;
}

export const Status: React.FC<IProps> = ({ status, statusCode }) => {
  const getOrderStatus = useMemo(() => {
    if (statusCode === ORDER_STATUS.ON_HOLD) return localeStore.t('waiting_status');
    return status;
  }, [statusCode]);

  return (
    <div
      className="status-component text-size-20 text-md-size-12"
      style={{ boxShadow: `0px 3px 40px ${getStatusColor(statusCode)}75` }}
    >
      <Icon
        className={'text-size-18 text-md-size-12 mr-2 mr-lg-4'}
        color={getStatusColor(statusCode)}
        name={ICONS.CIRCLE}
      />
      {getOrderStatus}
    </div>
  );
};
