import { IProductInsideCombo } from 'src/types/combo';
import { formatUnit, getTranslate } from 'src/utils/formatter';
import { getProductCalories } from '../../getProductCalories/getProductCalories';

export const getComboItemUnits = (product: IProductInsideCombo | undefined): string[] => {
  if (!product) return [];
  return [getProductCalories(product), _getComboItemUnit(product)].filter(Boolean) as string[];
};
const _getComboItemUnit = (product: IProductInsideCombo | undefined): string | null => {
  if (!product) return null;
  return formatUnit(getTranslate(product.unit_name), product.weight);
};
