import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { staticTheme } from 'src/constants/staticTheme';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import { IPaymentCard } from 'src/types/user';
import Icon from '../Controls/Icon/Icon';
import Spinner from '../Controls/Spinner/Spinner';
import './style.scss';
interface Props {
  card: IPaymentCard;
  selected: IPaymentCard['id'] | null;
  loading?: boolean;
  onCardPress?: (card: IPaymentCard) => void;
  onCardDelete?: (card: IPaymentCard) => void;
}

export default class PaymentCard extends Component<Props> {
  onCardPress = () => {
    if (this.props.onCardPress) this.props.onCardPress(this.props.card);
  };

  onDelete = (event: any) => {
    event.stopPropagation();
    if (this.props.onCardDelete) this.props.onCardDelete(this.props.card);
  };

  get icon(): string {
    if (this.props.card.type === 'Visa') {
      return ICONS.VISA_CARD;
    } else if (this.props.card.type === 'MasterCard') {
      return ICONS.MASTER_CARD;
    } else {
      return ICONS.CREDIT_CARD;
    }
  }

  render() {
    const { card, loading, selected } = this.props;
    return (
      <div
        onClick={this.onCardPress}
        className={`payment-card mr-4 color-dark ${card.id === selected ? 'active border-primary' : null}`}
      >
        <div className="payment-card-background"></div>
        <div className="d-flex flex-column justify-content-between full-height payment-card-content">
          <Icon size={30} name={this.icon} />

          <div className="d-flex flex-row color-gray align-items-center">
            <div className="d-flex flex-row color-gray mr-3">
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />
            </div>
            <div className="d-flex flex-row color-gray mr-3">
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />
            </div>
            <div className="d-flex flex-row color-gray mr-3">
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />{' '}
              <Icon className="payment-card-circle" name={ICONS.CIRCLE} size={8} />
            </div>
            <p className="color-gray family-semibold"> {card.last}</p>
          </div>

          {loading ? (
            <Spinner className="payment_type-icon" color={staticTheme.colors.color.gray} size={27} />
          ) : (
            <p onClick={this.onDelete} className="color-gray text-size-14 family-semibold payment-card-delete">
              {localeStore.t('txt_delete')}
            </p>
          )}
        </div>
      </div>
    );
  }
}
