import { FC, ReactElement, memo } from 'react';
import { ordersStore } from 'src/mobx/ordersStore';
import { IOrderProductModifierOption } from 'src/types/orders';
import { getTranslate } from 'src/utils/formatter';
import './style.scss';

interface IProps {
  option: IOrderProductModifierOption;
  productQuantity: number;
}

export const OrderProductModifierOption: FC<IProps> = memo(({ option, productQuantity }: IProps): ReactElement => {
  const price: string = ordersStore.formatPrice(option.price * option.quantity * productQuantity);
  const showQuantity: boolean = Boolean(option.quantity && option.quantity > 1);

  return (
    <div className="order-product-modifier-option">
      <p className="order-product-modifier-option__name-wrapper">
        <span className="order-product-modifier-option__name color-dark">{getTranslate(option.name)}</span>

        {showQuantity ? (
          <span className="order-product-modifier-option__name color-gray">x{option.quantity}</span>
        ) : null}
      </p>

      <p className="order-product-modifier-option__price color-gray text-nowrap">{price}</p>
    </div>
  );
});
