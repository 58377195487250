import * as React from 'react';
import { memo } from 'react';
import './style.scss';

export interface IHeaderLogo {
  logoUrl: string | undefined;
}

class HeaderLogo extends React.Component<IHeaderLogo> {
  public render() {
    if (!this.props.logoUrl) {
      return null;
    }

    return (
      <div data-testid="header-logo" className="header-content header-logo">
        <img src={this.props.logoUrl} className="website-logo" title="logo" alt="logo" />
      </div>
    );
  }
}

export default memo(HeaderLogo);
