import { IProductInsideCombo } from 'src/types/combo';
import { calculateMenuProductModifiersPrice } from '../../calculateMenuProductModifiersPrice/calculateMenuProductModifiersPrice';

export const calculateProductInsideComboPrice = (product: IProductInsideCombo): number => {
  let price: number = Number(product.price);

  price = price + calculateMenuProductModifiersPrice(product.modifiers);

  return price;
};
