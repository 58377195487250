import React, { Component } from 'react';
import UserEdit from './container/UserEdit/UserEdit';

export default class UserScreen extends Component {
  state = {};

  render() {
    return (
      <div className="">
        <div className="full-width">
          <UserEdit />
        </div>
      </div>
    );
  }
}
