import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { IBranch } from 'src/types/branch';

@observer
class DeliveryTypeSwitcher extends React.Component<RouteComponentProps> {
  state = {
    active: false,
  };

  componentDidMount = () => {
    this.checkDeliveryType();
  };

  componentDidUpdate = (props: RouteComponentProps) => {
    if (this.props.location.pathname !== props.location.pathname) {
      this.checkDeliveryType();
    }
  };

  checkDeliveryType = () => {
    const isBothAvailable = appStore.settings?.delivery_type === DELIVERY_TYPES.BOTH;

    if (!isBothAvailable) {
      this.setState({ active: true });
      return;
    }

    const path = this.props.location.pathname.split('/');
    const [screen, param] = [path[path.length - 2], path[path.length - 1]];
    let active = false;
    if (screen === 'branches') {
      active = param === DELIVERY_TYPES.PICKUP;
    } else {
      active = branchStore.delivery_type === DELIVERY_TYPES.PICKUP;
    }

    this.setState({ active });
  };

  changeDeliveryType = (delivery_type: IBranch['delivery_type']) => {
    this.props.history.push({
      pathname: SCREENS.BRANCHES(delivery_type),
      search: `?language=${localeStore.language}`,
    });
  };

  public render() {
    const isBothAvailable = appStore.settings?.delivery_type === DELIVERY_TYPES.BOTH;
    const isDeliveryAvailable = isBothAvailable || appStore.settings?.delivery_type === DELIVERY_TYPES.DELIVERY;
    const isPickupAvailable = isBothAvailable || appStore.settings?.delivery_type === DELIVERY_TYPES.PICKUP;

    return (
      <div className="switcher th-background-secondary th-bg-background-secondary ">
        {isDeliveryAvailable ? (
          <div
            onClick={() => this.changeDeliveryType(DELIVERY_TYPES.DELIVERY)}
            className={`switcher-button th-color-secondary th-selected_active-secondary th-bg-selected_active-primary ${
              !isPickupAvailable || !this.state.active ? 'active' : ''
            }`}
          >
            <p id={'delivery_switcher'} className="switcher-text family-semibold ">
              {localeStore.t('Delivery')}
            </p>
          </div>
        ) : null}

        {isPickupAvailable ? (
          <div
            onClick={() => this.changeDeliveryType(DELIVERY_TYPES.PICKUP)}
            className={`switcher-button  th-color-secondary th-selected_active-secondary th-bg-selected_active-primary ${
              !isDeliveryAvailable || this.state.active ? 'active' : ''
            }`}
          >
            <p id={'pickup_switcher'} className="switcher-text family-semibold ">
              {localeStore.t('Pickup')}
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(DeliveryTypeSwitcher);
