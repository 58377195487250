import { observer } from 'mobx-react';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import OrdersScreen from '../OrdersScreen/OrdersScreen';
import ReservationsScreen from '../ReservationsScreen/ReservationsScreen';
import UserScreen from '../UserScreen/UserScreen';
import WalletScreen from '../WalletScreen/WalletScreen';
import Tabs from './containers/Tabs/Tabs';
import MyAddressesScreen from '../MyAddressesScreen/MyAddressesScreen';

@observer
export default class MoreScreen extends React.Component<RouteComponentProps> {
  public render() {
    return (
      <div className="container">
        <div className="d-flex flex-column full-width">
          <Tabs />
          <Switch>
            <Route path={SCREENS.ORDERS()}>
              <OrdersScreen />
            </Route>
            <Route path={SCREENS.MY_ADDRESSES()}>
              <MyAddressesScreen />
            </Route>
            <Route path={SCREENS.RESERVATIONS()}>
              <ReservationsScreen />
            </Route>
            <Route exact path={SCREENS.USER()}>
              <UserScreen />
            </Route>
            {/* show wallet section just if wallet addon is enabled */}
            {appStore.settings?.is_wallet ? (
              <Route exact path={SCREENS.WALLET()}>
                <WalletScreen />
              </Route>
            ) : null}
          </Switch>
        </div>
      </div>
    );
  }
}
