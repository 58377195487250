import { Component } from 'react';
import { debounce } from 'src/utils/perfomance';

interface Props {
  min?: number;
  max?: number;
}

export default class ResponsiveContent extends Component<Props> {
  state = {
    currentWidth: window.innerWidth,
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.onResize);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  handleResize = () => {
    this.setState({ currentWidth: window.innerWidth });
  };

  onResize = debounce(this.handleResize, 100);

  render() {
    if (this.props.min && this.state.currentWidth < this.props.min) return null;
    if (this.props.max && this.state.currentWidth >= this.props.max) return null;

    return this.props.children || null;
  }
}
