import { toJS } from 'mobx';
import moment from 'moment';
import React, { CSSProperties } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { ORDER_STATUS, PAYMENT_TYPES } from 'src/constants/main';
import { Card, CardKey, CardRow, CardStatus } from 'src/view/components/CardComponents/Card';
import HorizontalScroll from 'src/view/components/HorizontalScroll/HorizontalScroll';
import { ICONS } from '../../../../../constants/icons';
import { SCREENS } from '../../../../../constants/screens';
import { localeStore } from '../../../../../mobx/localesStore';
import { IOrder } from '../../../../../types/orders';
import { formatPrice, getTranslate } from '../../../../../utils/formatter';
import Icon from '../../../../components/Controls/Icon/Icon';
import './style.scss';
import socket from 'src/services/socket';
interface Props {
  order?: IOrder;
  preventDefault?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  className?: string;
}

export class Order extends React.PureComponent<Props> {
  componentDidMount() {
    setTimeout(() => {
      if (!socket.client?.active) {
        socket.init();
      }
    }, 3000);
  }

  renderProducts = () => {
    if (!this.props.order) {
      return (
        <div className="order-items">
          <div className="order-item mr-2">
            <Skeleton width={60} />
          </div>
          <div className="order-item mr-2">
            <Skeleton width={60} />
          </div>
        </div>
      );
    }

    return (
      <HorizontalScroll>
        <div className="d-flex flex-row flex-nowrap">
          {this.props.order.products.map((item, index: number) =>
            index < 2 ? (
              <div className="order-item mr-2" key={index}>
                <span>{getTranslate(item.name)}</span>
              </div>
            ) : null
          )}
          {this.props.order.products.length - 1 >= 2 && (
            <div className="order-item">+{this.props.order.products.length - 2}</div>
          )}
        </div>
      </HorizontalScroll>
    );
  };

  pathNameTo = () => {
    const { order } = this.props;
    if (order && order.order_status_code !== ORDER_STATUS.ON_HOLD) {
      return SCREENS.ORDER(order.id);
    }
    return SCREENS.ORDER_COMPLETED(order?.id || '');
  };
  render() {
    const { order, style, className } = this.props;
    const date = order?.order_date || new Date();
    return (
      <Link
        style={style}
        className={className}
        to={
          !this.props.preventDefault
            ? {
                pathname: this.pathNameTo(),
                state: toJS(order),
                search: `?language=${localeStore.language}`,
              }
            : '#'
        }
        onClick={this.props.onClick}
      >
        <Card className="order px-3 py-3">
          <div className="order-status-row">
            <CardKey>#{order?.order_key || <Skeleton width={120} />}</CardKey>
            {order ? (
              <CardStatus statusCode={order.order_status_code} status={order.status} />
            ) : (
              <Skeleton width={80} />
            )}
          </div>

          <CardRow>
            <Icon name={ICONS.CALENDAR} size={18} color={'#6D6D6D'} className="order-icon mr-2" />
            {order ? (
              `${localeStore.t(`month_${moment(date).format('M')}`)} ${moment(date).format('DD hh:mm a')}`
            ) : (
              <Skeleton width={150} />
            )}
          </CardRow>
          <CardRow>
            <Icon name={ICONS.CASH} size={18} color={'#6D6D6D'} className="order-icon mr-2" />
            {order ? localeStore.t(PAYMENT_TYPES[order.payment_type]) : <Skeleton width={70} />}
          </CardRow>

          <div className="mt-2 d-flex align-items-center justify-content-between">
            {/* <div className="flex-grow-1"> */}
            {this.renderProducts()}
            {/* </div> */}
            <div className="order-price color-primary text-size-20 ml-4 text-nowrap">
              {order ? formatPrice(order.total, order.currency_code, order.currency_position) : <Skeleton width={90} />}
            </div>
          </div>
        </Card>
      </Link>
    );
  }
}
