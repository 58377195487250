import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import { productsStore } from 'src/mobx/productsStore';
import { ICategory } from 'src/types/products';
import DropDown from 'src/view/components/Controls/DropDown/DropDown';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';
import { getTranslate } from '../../../../../../../utils/formatter';
import { IDropDownOption } from 'src/view/components/Controls/DropDownOption/DropDownOption';

type CategoriesDropDownProps = {
  categories: ICategory[];
};

const CategoriesDropDown = observer(({ categories }: CategoriesDropDownProps) => {
  const handleDropDownPress = (option: IDropDownOption<ICategory>) => productsStore.setCategory(option.value.id);

  if (!categories.length || productsStore.loading || productsStore.categories.length < 4) {
    return null;
  }

  return (
    <div className={`d-flex flex-row categories-dropdown ${productsStore.searchIsActive ? 'hidden' : ''}`}>
      <DropDown
        selected={productsStore.category?.id || 0}
        onPress={handleDropDownPress}
        options={categories.map((category: ICategory) => ({
          key: category.id,
          label: getTranslate(category.name),
          value: category,
        }))}
      >
        <div className="menu-category tab menu-category-dropdown family-semibold">
          {localeStore.t('txt_more')} <Icon className="ml-3" name={ICONS.CHEVRON} />
        </div>
      </DropDown>
    </div>
  );
});

export default CategoriesDropDown;
