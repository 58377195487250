import React from 'react';
import { observer } from 'mobx-react';
import { productsStore } from '../../../../../mobx/productsStore';
import { MenuSearchedCategories } from './components/MenuSearchedCategories/MenuSearchedCategories';
import { SearchNotFound } from './components/SearchNotFound/SearchNotFound';
import { MenuSearchSkeleton } from './components/MenuSearchSkeleton/MenuSearchSkeleton';

export const MenuSearchItems = observer(() => {
  const { searchedCategories, searchIsLoading, categories } = productsStore;

  if (searchIsLoading) {
    return (
      <MenuSearchSkeleton />
    );
  }

  if (searchedCategories && searchedCategories.length === 0) {
    return (
      <SearchNotFound />
    );
  }

  if (searchedCategories && searchedCategories.length > 0) {
    return (
      <MenuSearchedCategories categories={searchedCategories} />
    );
  }

  return (
    <MenuSearchedCategories categories={categories} />
  );
})
