import React, { Component, FormEvent, SyntheticEvent, createRef } from 'react';
import { Link } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import PhoneInput from 'src/view/components/Controls/PhoneInput/PhoneInput';
import ReCAPTCHA from 'react-google-recaptcha';
import { appStore } from 'src/mobx/appStore';
import { observer } from 'mobx-react';

interface Props {
  submit: (number: string, captcha: string | null) => void;
  error: string;
  loading: boolean;
  needCaptcha: boolean;
  number: string;
  title?: string;
}
interface State {
  number: string;
  captcha: string | null;
}

@observer
export default class LoginComponent extends Component<Props, State> {
  state = {
    number: this.props.number,
    captcha: null,
  };

  captchaRef: ReCAPTCHA | null = null;

  onChangeNumber = (number: string) => {
    this.setState({ number });
  };

  submit = (event: FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLInputElement> | SyntheticEvent) => {
    if (event) event.preventDefault();
    this.props.submit(this.state.number, this.state.captcha);
  };

  get terms() {
    const [first, second] = localeStore.t('login_accept_terms_conditions').split('%s');
    return (
      <p className="login-terms color-lightgray family-regular  text-center mb-2">
        {first}{' '}
        <Link
          rel="license"
          title={localeStore.t('txt_terms')}
          hrefLang={localeStore.language}
          className="color-gray"
          to={{
            pathname: SCREENS.TERMS(),
            search: `?language=${localeStore.language}`,
          }}
        >
          {localeStore.t('txt_terms')}
        </Link>{' '}
        {second}
        <Link
          rel="license"
          title={localeStore.t('txt_privacy')}
          hrefLang={localeStore.language}
          className="color-gray"
          to={{
            pathname: SCREENS.PRIVACY(),
            search: `?language=${localeStore.language}`,
          }}
        >
          {localeStore.t('txt_privacy')}
        </Link>
      </p>
    );
  }

  handleCaptcha = (captcha: string | null) => {
    this.setState(
      {
        captcha,
      },
      () => {
        this.submit(null as unknown as SyntheticEvent);
      }
    );
  };

  render() {
    return (
      <form noValidate onSubmit={this.submit} className="login d-flex flex-column ">
        <h2 className="login-title family-semibold color-text mb-3 text-center">
          {this.props.title || localeStore.t('txt_enter_phone_number')}
        </h2>
        <div className="login-content">
          <div id={'phone'}>
            <PhoneInput
              containerClass={'mb-3 color-gray'}
              inputClass={'color-text family-regular'}
              onEnterKeyPress={this.submit}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true,
              }}
              prefix={'+'}
              value={this.state.number}
              onChange={this.onChangeNumber}
              // onKeyDown={this.onChangeNumber}
              // onEnterKeyPress={this.onChangeNumber}
            />
          </div>
          {this.props.error ? (
            <p className="login-error color-danger family-regular mb-3 text-center">{this.props.error}</p>
          ) : null}
          {this.props.needCaptcha && (
            <div className="d-flex justify-content-center">
              <ReCAPTCHA
                // @ts-ignore
                sitekey={appStore.settings?.recaptcha.site_key}
                onChange={this.handleCaptcha}
                dir={localeStore.direction}
                hl={localeStore.language}
                ref={(ref) => (this.captchaRef = ref)}
              />
            </div>
          )}
          {this.terms}
        </div>
        <ButtonComponent
          id={'login_submit_button'}
          disabled={this.props.needCaptcha && !this.state.captcha}
          onClick={(props) => {
            this.submit(props);
            this.captchaRef?.reset();
          }}
          loading={this.props.loading}
          title={localeStore.t('txt_submit')}
          type={'button'}
        />
      </form>
    );
  }
}
