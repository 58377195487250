import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { IBranch } from 'src/types/branch';
import PreorderSaveButton from './components/PreorderSave/PreorderSave';
import PreorderDays from './containers/PreorderDays/PreorderDays';
import PreorderMonths from './containers/PreorderMonths/PreoderMonths';
import PreorderTimes from './containers/PreorderTimes/PreorderTimes';
import { preorderPickerStore } from './store/store';
import './styles.scss';

interface Props {
  branch: IBranch;
  time?: number;
  delivery_type?: string;
  onSubmit: (time?: number) => void;
}

const Preorder: React.FC<Props> = observer(({ branch, time, delivery_type, onSubmit }) => {
  useEffect(() => {
    preorderPickerStore.prepare({ branch, time, delivery_type });
    return preorderPickerStore.clearStore;
  }, []);

  return (
    <div className="preorder-wrapper">
      <PreorderMonths />
      <div className="preorder-container">
        <PreorderDays />
        <PreorderTimes />
      </div>
      <PreorderSaveButton onClick={preorderPickerStore.save(onSubmit)} />
    </div>
  );
});

export default Preorder;
