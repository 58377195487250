import { observer } from 'mobx-react';
import React, { Component } from 'react';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import MenuLiist from './components/MenuList/MenuList';
import './style.scss';
interface State {
  active: boolean;
}

@observer
export default class MenuButton extends Component<{}, State> {
  state = {
    active: false,
  };

  onPress = () => {
    this.setState((state) => ({ active: !state.active }));

    // get Body selector
    // toggle overflow: hidden, if menu is open.
    window.document.body.classList.toggle('hide-scroll');
  };

  render() {
    return (
      <div className={`hamburger ${this.state.active ? 'active' : ''}`}>
        <DropDownModal
          direction={'right'}
          listClassName="hamburger-dropdown"
          content={({ closeModal }) => <MenuLiist closeModal={closeModal} />}
          onOpen={this.onPress}
          onClose={this.onPress}
        >
          <div className={`icon hamburger-button th-bg-background-secondary `}>
            <span className="th-bg-color-primary"></span>
            <span className="th-bg-color-primary"></span>
            <span className="th-bg-color-primary"></span>
          </div>
        </DropDownModal>
      </div>
    );
  }
}
