import { useMemo } from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { IMealComboGroupWithItems, IProductInsideCombo } from 'src/types/combo';
import { IProduct, IProductExclusion, IProductModifier } from 'src/types/products';
import { productUtils } from 'src/utils/productUtils';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import ScrollableBlock from 'src/view/containers/ScrollableBlock/ScrollableBlock';
import MealComboGroup from '../../components/MealComboGroup/MealComboGroup';
import './style.scss';

interface IProps {
  product?: IProduct;
  modifiersErrors: string[];
  needScrollToError: boolean;
  readonly?: boolean;
  onNotesChanged: (notes: string) => void;
  onSelectItem: (item: IProductInsideCombo) => void;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
  setNeedScrollToError: (need: boolean) => void;
}

export const MealComboItemsBlock: React.FC<IProps> = ({ product, ...props }: IProps): JSX.Element => {
  const currentGroups = useMemo(
    (): IMealComboGroupWithItems[] => productUtils.getCurrentMealSizeGroups(product),
    [product]
  );

  return (
    <ResponsiveContent min={WINDOW_SIZES.md}>
      <ScrollableBlock
        showScrollbar
        scrollbarSize="small"
        className="full-height pxmr-2"
        scrollClassName="product-meal-items-block__scroll"
      >
        {currentGroups.map((group) => (
          <MealComboGroup
            key={group.id + '-meal-group'}
            group={group}
            modifiersErrors={props.modifiersErrors}
            needScrollToError={props.needScrollToError}
            readonly={props.readonly}
            onNotesChanged={props.onNotesChanged}
            onSelectItem={props.onSelectItem}
            onModifierChange={props.onModifierChange}
            onExclusionsChange={props.onExclusionsChange}
            setNeedScrollToError={props.setNeedScrollToError}
          />
        ))}
      </ScrollableBlock>
    </ResponsiveContent>
  );
};
