import { appStore, AppStore } from './appStore';
import { branchStore, BranchStore } from './barnchStore';
import { cartStore, CartStore } from './cartStore';
import { LocalesStore, localeStore } from './localesStore';
import { productsStore, ProductsStore } from './productsStore';
import { userStore, UserStore } from './userStore';
import { OrdersStore, ordersStore } from './ordersStore';
import { ReservationsStore, reservationsStore } from './reservationsStore';
import { PromotionStore, promotionStore } from './promotionStore';
import { checkoutStore, CheckoutStore } from './checkoutStore';
import { addressStore, AddressStore } from './barnchStore/address';
import { PreorderStore, preorderStore } from './preorderStore';

export interface IMainStore {
  appStore: AppStore;
  checkoutStore: CheckoutStore;
  preorderStore: PreorderStore;
  userStore: UserStore;
  localeStore: LocalesStore;
  branchStore: BranchStore;
  cartStore: CartStore;
  productsStore: ProductsStore;
  ordersStore: OrdersStore;
  reservationsStore: ReservationsStore;
  promotionStore: PromotionStore;
  addressStore: AddressStore;
}

const mainStore: IMainStore = {
  appStore,
  checkoutStore,
  userStore,
  localeStore,
  branchStore,
  cartStore,
  productsStore,
  ordersStore,
  reservationsStore,
  promotionStore,
  addressStore,
  preorderStore,
};

export enum STORES {
  APP_STORE = 'appStore',
  CHECKOUT_STORE = 'checkoutStore',
  USER_STORE = 'userStore',
  LOCALE_STORE = 'localeStore',
  BRANCH_STORE = 'branchStore',
  PRODUCTS_STORE = 'productsStore',
  CART_STORE = 'cartStore',
  ORDERS_STORE = 'ordersStore',
  RESERVATIONS_STORE = 'reservationsStore',
  PROMOTION_STORE = 'promotionStore',
  ADDRESS_STORE = 'addressStore',
  PREORDER_STORE = 'preorderStore',
}

export default mainStore;
