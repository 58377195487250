import { action, computed, observable } from 'mobx';
import moment from 'moment';
import OrderHandler from 'src/mobx/functionalStores/orders/orderHandler';
import { IOrder } from '../../../types/orders';
import { IError } from '../../../types/main';
import { requests } from '../../../requests';
import { PAYMENT_TYPES, WINDOW_SIZES } from '../../../constants/main';
import { ICheckoutDataPay } from '../../../types/cart';
import client from '../../../services/client';
import { SCREENS } from '../../../constants/screens';
import { RouterChildContext } from 'react-router-dom';
import orderCreationRedirect from '../../../hooks/orderCreationRedirect';
import { DESTINATION_LOGS, EVENTS, PAYMENT_EXCEPTION_CODE, PAYMENT_RESPONSE_CODE } from '../../../constants/events';
import branches from '../../../requests/branch';
import { DELIVERY_TYPES } from '../../../constants/deliveryTypes';
import PaymentMethod from 'src/mobx/functionalStores/paymentMethods';
import { IBranch } from '../../../types/branch';
import { localeStore } from '../../../mobx/localesStore';
import { ERROR_HANDLERS } from '../../../utils/helpers/errorHandler';
import user from '../../../requests/user';
import { userStore } from '../../../mobx/userStore';
import logger from '../../../services/logger';
import { appStore } from '../../../mobx/appStore';
import { applePaySession } from '../../../services/applePay';
import TimerHandler from '../../../mobx/functionalStores/timer';

class TryPaymentAgainStore {
  @observable public timeOut: boolean = false;
  @observable public opened: boolean = false;
  @observable public openModal: boolean = false;
  @observable public paymentProcessing: boolean = false;
  @observable public error: IError | null = null;
  @observable public loading: boolean = true;
  @observable public loadingNewCard: boolean = false;

  @observable public showPaymentTypeList: boolean = false;

  private OrderHandler: OrderHandler;
  private _PaymentMethod: PaymentMethod | undefined;
  public countDownTimer = new TimerHandler();

  constructor() {
    this.OrderHandler = new OrderHandler();
  }

  @action init = async () => {
    const serverDate: moment.Moment = moment.utc(this.OrderHandler?.getOrder?.dnf?.cutoff_at);
    const localDate: moment.LocaleSpecifier = moment(serverDate).utc().local().format();

    if (moment().isAfter(localDate)) {
      this.timeOut = true;
    }
  };

  public define = async (id: IOrder['id'], order: IOrder) => {
    try {
      await this.OrderHandler.defineOrder({ id, order });

      const branch = await branches.getBranch(order?.branch.id, {
        delivery_type: order?.delivery_type_code || DELIVERY_TYPES.PICKUP,
        is_emenu: appStore.mode === 'e-menu' ? 1 : 0,
      });

      this._PaymentMethod = new PaymentMethod(branch as IBranch, order);
      this._PaymentMethod.selectPaymentType(order.payment_type);
    } catch (error: any) {
      ERROR_HANDLERS.EXCEPTION_MESSAGE({
        title: error?.response?.data,
      });
    }
  };

  @computed public get PaymentMethod() {
    return this._PaymentMethod;
  }

  @computed get order() {
    return this.OrderHandler.getOrder;
  }

  @action setLoading = (status: boolean) => {
    this.loading = status;
  };

  @action setTimeOut = (status: boolean) => {
    this.timeOut = status;
  };

  public payOrder = async (history: RouterChildContext['router']['history']): Promise<any> => {
    this.loading = true;
    this.paymentProcessing = true;
    try {
      const orderId = tryPaymentAgainStore.order?.id || 0;

      const payData = this.payData;

      if (!this.PaymentMethod?.isOrderFree && this.PaymentMethod?.paymentType === PAYMENT_TYPES.APPLE_PAY) {
        const applePayData = await applePaySession(this.order);
        if (!applePayData) {
          this.loading = false;
          this.paymentProcessing = false;
          return;
        }
        payData.payment_source.apple_pay = applePayData;
      }
      const orderPay = await client.post(`/v4/orders/${orderId}/pay`, payData, undefined, true);

      if (orderPay.status === 200) {
        const order = await requests.getOrderById(orderId);

        if (order) {
          orderCreationRedirect(orderId, order, history);
        }
      }

      if (orderPay.status === 201) {
        if (orderPay?.data?.link_3ds) {
          window.location.href = orderPay?.data?.link_3ds;
        } else {
          const order: IOrder = await requests.getOrderById(orderId);
          orderCreationRedirect(orderId, order, history);
        }
      }
    } catch (error: any) {
      const { response } = error;

      let failureMessage;
      let statusCode;

      if (response) {
        failureMessage = response?.data.message;
        statusCode = response?.status;
      } else {
        failureMessage = error.message;
        statusCode = null;
      }

      logger.logEvent(
        EVENTS.PURCHASE_FAILED,
        {
          failure_message: failureMessage,
          status_code: statusCode,
        },
        DESTINATION_LOGS.MOENGAGE
      );

      const needToCloseTheTPAScreen = [
        PAYMENT_EXCEPTION_CODE.ORDER_BECAME_UNAVAILABLE,
        PAYMENT_EXCEPTION_CODE.ORDER_CANNOT_BE_PAID,
      ].includes(error?.response?.data.exceptionType);

      if (error?.response?.status === 400 && needToCloseTheTPAScreen) {
        const order: IOrder = await requests.getOrderById(tryPaymentAgainStore.order?.id || 0);
        history.replace(SCREENS.ORDER(order.id), { order, orderError: error?.response?.data });

        return;
      }

      if (error?.response?.data.code === PAYMENT_RESPONSE_CODE.ERROR_MAXIMUM_CASH_PAYMENT_IS_EXEEDED) {
        ERROR_HANDLERS.ERROR_MAXIMUM_CASH_PAYMENT_IS_EXEEDED(error?.response?.data.order?.max_cash_payment);
        return;
      }

      if (error?.response?.data.code === PAYMENT_RESPONSE_CODE.ERROR_PAYMENT_TYPE) {
        ERROR_HANDLERS.ERROR_PAYMENT_METHOD_IS_NO_LONGER_AVAILABLE();
        return;
      }

      if (error?.response?.data.exceptionType === PAYMENT_EXCEPTION_CODE.ORDER_BECAME_UNAVAILABLE) {
        ERROR_HANDLERS.EXCEPTION_MESSAGE({
          title: localeStore.t('order_not_paid_error_header'),
          message: localeStore.t('order_not_available_error_message'),
        });
        return;
      }

      if (error?.response?.data.exceptionType === PAYMENT_EXCEPTION_CODE.ORDER_CANNOT_BE_PAID) {
        ERROR_HANDLERS.EXCEPTION_MESSAGE({
          title: localeStore.t('order_not_paid_error_header'),
          message: localeStore.t('order_already_paid_error_message'),
        });
        return;
      }

      if (error?.response?.data.exceptionType === PAYMENT_EXCEPTION_CODE.PAYMENT_EXCEPTION) {
        ERROR_HANDLERS.EXCEPTION_MESSAGE({
          title: localeStore.t('payment_failed_error_header'),
          message: localeStore.t('try_payment_again_error_message'),
        });
        return;
      }

      ERROR_HANDLERS.EXCEPTION_MESSAGE({
        title: error?.response?.data?.message,
      });
    } finally {
      this.loading = false;
      this.paymentProcessing = false;
    }

    return null;
  };

  public get payData(): ICheckoutDataPay {
    const data: ICheckoutDataPay = {
      payment_source: {},
      payment_type:
        this.PaymentMethod?.paymentType === PAYMENT_TYPES.APPLE_PAY
          ? PAYMENT_TYPES.ONLINE_PAYMENT
          : this.PaymentMethod?.paymentType,
    };
    if (this.PaymentMethod?.paymentType === PAYMENT_TYPES.ONLINE_PAYMENT) {
      if (this.PaymentMethod.paymentType) {
        data.payment_source.card_id = this.PaymentMethod.selectedPaymentCard?.id;
      } else if (this.PaymentMethod.newCard) {
        data.payment_source.card = this.PaymentMethod.newCard;
      }
    }
    return data;
  }
  addNewCard = async (number: string, month: string, year: string, cvv: string) => {
    try {
      this.loadingNewCard = true;
      const savedCard = await user.addCrediCard(
        {
          number,
          month,
          year,
          cvv,
        },
        this._PaymentMethod?.selectedBranch?.id
      );

      await userStore.fetchUserCards();
      this.PaymentMethod?.selectPaymentCard(savedCard);
      this.PaymentMethod?.showAddToCardModal(false);
    } catch (error: any) {
      logger.log(error);
    } finally {
      this.loadingNewCard = false;
    }
  };
  @action changePaymentType = () => {
    if (window.innerWidth < WINDOW_SIZES.md) {
      this.setIsShowPaymentTypeList(false);
      this.PaymentMethod?.openModal();
    } else {
      this.setIsShowPaymentTypeList(true);
      this.PaymentMethod?.closeModal();
    }
  };

  @action setIsShowPaymentTypeList = (status: boolean) => {
    this.showPaymentTypeList = status;
  };

  public setOpened = (opened: boolean) => (this.opened = opened);
  public setOpenModal = (opened: boolean) => (this.openModal = opened);

  public newError = (error: IError) => (this.error = error);

  @action clear = () => {
    this.OrderHandler.clearOrder();
    this.opened = false;
    this.loading = false;
  };
}
export const tryPaymentAgainStore = new TryPaymentAgainStore();
