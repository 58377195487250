import { action, computed, observable } from 'mobx';
import { IAddressResponse, TAddressType, VIEW_MODE } from '../../../types/address';
import { addressStore } from '../../../mobx/barnchStore/address';
import { branchStore } from '../../../mobx/barnchStore';
import history from '../../../services/history';
import { SCREENS } from '../../../constants/screens';
import { DELIVERY_TYPES } from '../../../constants/deliveryTypes';
import { requests } from '../../../requests';
import { defaultBranch } from '../../../constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { IBranch } from '../../../types/branch';
import { appStore } from '../../../mobx/appStore';

export class BranchesScreenStore {
  @observable loading: boolean = false;
  @observable selectedAddressId: number | null = null;
  @observable viewMode: VIEW_MODE = VIEW_MODE.LIST;
  @observable errorResponse: string | null = null;

  @observable from: string | undefined;

  @action setFrom = (from: string | undefined) => {
    this.from = from;
  };

  @action onAddressEdit = (id: IAddressResponse['id']) => {
    this.selectAddressId(id);
    this.setViewMode(VIEW_MODE.EDIT);
  };

  @action onAddressCreate = () => {
    this.setViewMode(VIEW_MODE.CREATE);
  };

  @action backHandler = () => {
    this.setViewMode(VIEW_MODE.LIST);
  };

  @action setViewMode = (viewMode: VIEW_MODE) => {
    this.viewMode = viewMode;
  };
  /**
   * Select address
   */
  @action selectAddressId(id: IAddressResponse['id'] | null) {
    this.selectedAddressId = id;
  }

  // return selected address
  @computed get address(): IAddressResponse | undefined {
    return addressStore.list.find((address) => address.id === this.selectedAddressId);
  }

  @action getDefaultBranch = async (addressId?: number | null): Promise<IBranch> => {
    if (!addressId && appStore?.settings?.donation?.branch_id) {
      const branch = await requests.branches.getBranch(appStore?.settings?.donation?.branch_id, {
        delivery_type: DELIVERY_TYPES.DELIVERY,
      });
      return branch ?? defaultBranch;
    }
    return defaultBranch;
  };

  @action selectBranchByAddress = async () => {
    const getBranchByCoordinate = await requests.branches.branchSearch({
      latitude: this.address?.latitude || branchStore.selectMapCoordinate?.latitude,
      longitude: this.address?.longitude || branchStore.selectMapCoordinate?.longitude,
      delivery_type: DELIVERY_TYPES.DELIVERY,
    });
    if (this.selectedAddressId) addressStore.selectAddress(this.selectedAddressId);
    const selectedBranch = getBranchByCoordinate?.branch || (await this.getDefaultBranch(this.selectedAddressId));
    await branchStore.setBranch(selectedBranch, DELIVERY_TYPES.DELIVERY);
    this.setViewMode(VIEW_MODE.LIST);
  };

  @action setError = (data: string | null) => {
    this.errorResponse = data;
  };

  @action setLoading = (status: boolean) => {
    this.loading = status;
  };

  /*
   * Click on delivery here button
   * */
  @action selectDeliveryAddress = async (address: IAddressResponse) => {
    this.loading = true;
    try {
      if (this.viewMode === VIEW_MODE.CREATE) {
        const data = await addressStore.addNewAddress(address);
        if (data?.id) {
          addressStore.selectAddress(data.id);
          branchesScreenStore.selectAddressId(data.id);
        }
      } else if (this.viewMode === VIEW_MODE.EDIT) {
        if (this.selectedAddressId) {
          await addressStore.updateAddress(this.selectedAddressId, address);
          addressStore.selectAddress(this.selectedAddressId);
        }
      } else {
        if (this.selectedAddressId) {
          addressStore.selectAddress(this.selectedAddressId);
        }
      }
      await this.selectBranchByAddress();
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
      if (this.from) {
        history.push(this.from);
      }
      // Not redirect to menu if it SMS
      else if (!cartStore.isSMS) {
        history.push({ pathname: SCREENS.MENU() });
      }
    }
  };

  /**
   * Clear store
   */
  @action clear = () => {
    this.from = undefined;
    this.selectedAddressId = null;
    this.setViewMode(VIEW_MODE.LIST);
  };
}
const branchesScreenStore = new BranchesScreenStore();

export default branchesScreenStore;
