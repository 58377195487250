import { localeStore } from '../../../../../../../../../mobx/localesStore';
import { getTranslate } from '../../../../../../../../../utils/formatter';
import { branchStore } from '../../../../../../../../../mobx/barnchStore';
import React from 'react';
import { observer } from 'mobx-react';

const Location = observer(() => {
  return (
    <div>
      <p className="pickup-type">{localeStore.t('txt_pickup_at')}</p>
      <p className="pickup-address">{getTranslate(branchStore.branch.name)}</p>
    </div>
  );
});

export default Location;
