import { TBooleanInNumber } from 'src/types/app';
import { IMealComboChoice, IMealComboGroup, IProductInsideCombo } from './combo';
import { IPagination, TTranslate } from './main';

export enum PRODUCT_TYPE {
  PRODUCT,
  DEAL_COMBO,
  MEAL_COMBO,
}

/**
 *
 * Response
 *
 */

export interface IResponseProductSize {
  id: number;
  price: number;
  weight: number;
  unit: string;
  unit_name?: TTranslate;
  calories: Calories;
  name: string;
  is_selected: number;
  error?: string;
}

export interface IResponseProductExclusion {
  id: number;
  status: number;
  is_selected: number;
  name: string;
  error?: string;
}

export interface IResponseProductModifier {
  id: number;
  minimum: number;
  maximum: number;
  required: number;
  name: string;
  options: IResponseProductModifierOption[];
  multi_choice: number;
  unique_options: number;
}

export interface IResponseProductModifierOption {
  id: number;
  price: number;
  sort: number;
  unit: string;
  weight: number;
  is_default: number;
  is_selected: number;
  calories: Calories;
  quantity: number;
  name: string;
  taxable: number;
  selected?: boolean;
  minimum: number;
  maximum: number;
  error?: string;
  status?: 1 | 0;
}
export interface IResponseProduct {
  id: number;
  category_id: number;
  order_type: number; // delivery - 4, pickup/curbside - 2, dinein - 1; (can be sum of these)
  price: number;
  weight: number;
  size: IResponseProductSize | null;
  sizes: IResponseProductSize[];
  calories: Calories;
  unit: string;
  unit_name?: TTranslate;
  image: string;
  images: string[];
  status: number;
  quantity?: number;
  min_quantity: number;
  out_of_stock: number;
  notes?: string;
  name: TTranslate;
  description: string;
  earn: number;
  redeem: number;
  modifiers: IResponseProductModifier[];
  exclusions: IResponseProductExclusion[];
  upsales_items: number[];
  is_size?: TBooleanInNumber;
  is_donation: number;
  type?: PRODUCT_TYPE;
  // combo's keys
  items?: IProductInsideCombo[];
  groups?: IMealComboGroup[];
  choices?: IMealComboChoice[];
}

export interface IResponseCategory {
  id: number;
  status: number;
  global_category_id: number;
  name: string;
  items: IPagination<IResponseProduct>;
}

/**
 *
 *
 *
 */

// tslint:disable-next-line:no-empty-interface
export interface IProductSize extends IResponseProductSize {}

export interface IProductExclusion extends IResponseProductExclusion {
  selected?: boolean;
}

export interface IProductModifier extends IResponseProductModifier {
  error?: string;
  options: IProductModifierOption[];
}

export interface IProductModifierOption extends IResponseProductModifierOption {
  selected?: boolean;
  quantity: number;
}

export interface IProduct extends IResponseProduct {
  quantity: number;
  hash: string;
  size: IProductSize | null;
  modifiers: IProductModifier[];
  exclusions: IProductExclusion[];
  sizes: IProductSize[];
  error?: string;
  orderable?: boolean;
  cashback_amount?: string | number;
  added_from?: ADDED_FROM;
}

export interface ICategory {
  id: number;
  status: number;
  name: TTranslate;
  items: IPagination<IProduct>;
  category_meta_description: string;
}

export interface IBanner {
  target?: number;
  targetType: 'none' | 'item' | 'product' | 'category' | 'link';
  image: string;
}

export enum PRODUCT_ORDER_TYPE {
  DELIVERY = 0b100,
  PICKUP_CURBSIDE = 0b010,
  DINEIN = 0b001,
}

export enum ADDED_FROM {
  MENU = 0,
  CROSS_SELLING = 1,
  BANNER = 2,
}

export interface ICashbackInfo {
  id: number;
  percent: number;
  maximum: number;
  name: TTranslate;
  description: TTranslate;
}

export type Calories = null | number;
