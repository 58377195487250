import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { PAYMENT_TYPES } from 'src/constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';

@observer
export default class CheckoutPaymentPoints extends Component {
  render() {
    if (!cartStore.isValidPaymentType || cartStore.checkout.payment_type !== PAYMENT_TYPES.POINTS) return null;
    return (
      <div className="mb-4">
        <p className="text-size-14 color-darkgray family-regular text-uppercase mb-2">
          {localeStore.t('points_pay_type')}
        </p>
        <div className="d-flex flex-column  ">
          <div className="d-flex flex-row align-items-center">
            <p className="color-dark family-bold mr-4 points-big">{userStore.user.points}</p>{' '}
            <p className="color-gray family-semibold  points-big">
              {cartStore.calculator.redeem_points} {localeStore.t('txt_points_needed')}
            </p>
          </div>
          <div className="d-flex flex-row align-items-center">
            <p className="color-gray family-semibold  points-medium">{userStore.user.points}</p>
            <Icon name={ICONS.ARROW_RIGHT} className="color-gray  points-icon" />
            <p className="color-gray family-semibold  points-medium">
              {userStore.user.points - cartStore.calculator.redeem_points}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
