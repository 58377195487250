import { IProduct, ICategory } from 'src/types/products';
import { IOrder } from '../types/orders';
import { deprecated } from 'mobx/lib/utils/utils';

export const SCREENS = {
  ROOT: () => `/`,
  BRANCHES: (delivery_type?: string, locale?: string) => {
    let path = `/branches`;
    if (delivery_type) path = `${path}/${delivery_type}`;
    return path;
  },
  MENU: () => `/menu`,
  CART: () => `/cart`,
  CHECKOUT: () => `/checkout`,
  PRODUCT: (id: IProduct['id'] | string) => `/menu/${id}`,
  CATEGORY: (id: ICategory['id'] | string) => `/menu/category/${id}`,
  ORDERS: () => `/orders`,
  ORDER: (id: IOrder['id'] | string) => `/orders/${id}`,

  // TODO: delete after refactoring SMS pages
  // @deprecated: ORDER_COMPLETED, ORDER_RESULT
  ORDER_COMPLETED: (id: IOrder['id'] | string) => `/orders/${id}/completed`,
  ORDER_RESULT: (id: IOrder['id'] | string) => `/orders/${id}/payment_result`,

  TRY_PAYMENT_AGAIN: (id: IOrder['id'] | string) => `/${id}/try_payment_again`,
  ORDER_ACTUALIZE: (id: IOrder['id'] | string) => `/orders/${id}/actualize`,
  DRIVER_SEARCH: (id: IOrder['id'] | string) => `/${id}/driver_search`,

  LOCATIONS: () => `/locations`,
  USER: () => `/user`,
  ERROR: () => `/error`,
  SMSPAYMENT: () => `/sms-payment`,
  MY_ADDRESSES: () => `/my-addresses`,
  SMS_PRODUCT: (id: IProduct['id'] | string) => `/sms-payment/${id}`,
  RESERVATIONS: () => `/reservations`,
  CREATE_RESERVATION: () => `/reservations/create`,
  RESERVATION: (id: string | number) => `/reservations/${id}`,
  CREATE_SUBSCRIPTION: () => `/subscription_packages_page`,
  SUBSCRIPTIONS: () => `/mysubscription`,
  CONTACTS: () => `/contacts`,
  TERMS: () => `/terms`,
  PRIVACY: () => `/privacy`,
  FAQ: () => `/faq`,
  ABOUT: () => `/about`,
  NOT_FOUND: () => `/oops`,
  REST: () => `*`,
  MORE: () => `/more`,
  WALLET: () => `/wallet`,
};
