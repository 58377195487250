import { observer } from 'mobx-react';
import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { EVENTS } from 'src/constants/events';
import logger from 'src/services/logger';
import socket from 'src/services/socket';
import { IOrder } from 'src/types/orders';
import { EmptyList } from 'src/view/components/ScrollableList/EmptyList';
import ScrollableList from 'src/view/components/ScrollableList/ScrollableList';
import { SCREENS } from '../../../constants/screens';
import { ordersStore } from '../../../mobx/ordersStore';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import OrderScreen from '../OrderScreen/OrderScreen';
import { Order } from './components/Order/Order';

interface IState {
  orders: IOrder[];
  current_page: number;
  last_page: number;
  loading: boolean;
}
@observer
export default class OrdersScreen extends Component<any, IState> {
  componentDidMount = () => {
    socket.init();
  };

  componentWillUnmount = () => {
    socket.disconnect();
  };

  renderOrder = (order: IOrder | undefined, index: number) => (
    <Order
      key={order?.id || `loading-${index}`}
      order={order}
      onClick={() => {
        if (order) {
          logger.logEvent(EVENTS.ORDER_OPENED, {
            currency: order.currency_code,
            items: order.products,
            transaction_id: order.id,
            shipping: order.delivery_fee,
            tax: order.vat,
            price: order.total,
          });
        }
      }}
    />
  );

  render() {
    return (
      <PageContainer>
        <ScrollableList
          clear={ordersStore.clear}
          renderEmptyList={<EmptyList />}
          list={ordersStore.pagination.data}
          loading={ordersStore.pagination.loading}
          last_page={ordersStore.pagination.last_page}
          current_page={ordersStore.pagination.current_page}
          fetch={ordersStore.fetchOrders}
          renderItem={this.renderOrder}
        />
        <Switch>
          <Route path={SCREENS.ORDER(':id')} component={OrderScreen} />
        </Switch>
      </PageContainer>
    );
  }
}
