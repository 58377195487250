import React, { Component } from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { localeStore } from 'src/mobx/localesStore';
import DropDownPortal from '../DropDownPortal/DropDownPortal';
import './style.scss';

interface IDropDownModalListProps {
  content: ({ closeModal }: { closeModal: () => void }) => React.ReactNode;
}

export interface IDropDownModalProps extends IDropDownModalListProps {
  content: IDropDownModalListProps['content'];
  selfClose?: boolean;
  portal?: boolean;
  direction?: 'left' | 'right' | 'up' | 'down';
  position?: 'left' | 'right' | 'top' | 'bottom';
  align?: 'center';
  justify?: 'center';

  listClassName?: string;
  className?: string;
  disabled?: boolean;
  visible?: boolean;
  asPopupInMobile?: boolean;
  bottomSheet?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  containerClassname?: string;
}

type Visibility = 'visible' | 'hidden' | 'default';

interface DropDownModalState {
  visibility: Visibility;
}
export default class DropDownModal extends Component<IDropDownModalProps, DropDownModalState> {
  direction = this.props.direction || 'right';
  position = this.props.position || 'bottom';
  align = this.props.align;
  justify = this.props.justify;
  modal: HTMLDivElement | null = null;
  selfClose: boolean = this.props.selfClose === undefined ? true : Boolean(this.props.selfClose);
  animations = {
    left:
      localeStore.language === 'en'
        ? { default: '', visible: 'slide_in_left', hidden: 'slide_out_left' }
        : { default: '', visible: 'slide_in_right', hidden: 'slide_out_right' },
    right:
      localeStore.language === 'en'
        ? { default: '', visible: 'slide_in_right', hidden: 'slide_out_right' }
        : { default: '', visible: 'slide_in_left', hidden: 'slide_out_left' },
    up: { default: '', visible: 'slide_in_up', hidden: 'slide_out_up' },
    down: { default: '', visible: 'slide_in_down', hidden: 'slide_out_down' },
  };

  constructor(props: IDropDownModalProps) {
    super(props);
    this.state = {
      visibility: 'default',
    };
  }

  openModal = () => {
    if (this.props.onOpen) this.props.onOpen();
    this.setState({ visibility: 'visible' });
    document.addEventListener('click', this.closeModalHandler, { once: true });
  };

  closeModalHandler = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const pressedElement: any = event.target;

    this.setState({ visibility: 'hidden' });
    if (!this.selfClose) {
      const isInside = this.modal?.contains(pressedElement);
      if (isInside) return;
    }
    this.closeModal();
  };

  closeModal = () => {
    this.setState({ ...this.state, visibility: 'hidden' });
    if (this.props.onClose) this.props.onClose();
    document.removeEventListener('click', this.closeModalHandler);
  };

  changeVisibility = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (this.state.visibility === 'default' || this.state.visibility === 'hidden') {
      this.openModal();
    } else {
      this.closeModalHandler(event);
    }
  };

  renderModal = () => {
    if (this.props.disabled) return null;
    let { visibility } = this.state;

    if (this.props.visible) visibility = 'visible';
    if (this.props.visible !== undefined && !this.props.visible) {
      visibility = 'hidden';
      if (this.state.visibility !== 'hidden') this.setState({ ...this.state, visibility: 'hidden' });
      document.removeEventListener('click', this.closeModalHandler);
    }

    const animation = this.animations[this.direction][visibility];

    return (
      <div
        ref={(ref) => (this.modal = ref)}
        className={`
        ${this.props.listClassName ?? ''}
    dropdown-modal
    ${this.props.bottomSheet ? 'modal-bottom-sheet' : ''}
    dropdown-modal-align-${this.align}
    dropdown-modal-justify-${this.justify}
    ${this.props.asPopupInMobile ? 'popup_in_mobile' : ''}
    hide-scrollbar
    dropdown-modal-${this.position}
    ${visibility === 'visible' ? 'active' : 'inactive'} animation ${animation}`}
      >
        {this.props.content({ closeModal: this.closeModal })}
        {this.props.asPopupInMobile ? <DropDownModalPane onClick={this.closeModal} /> : null}
      </div>
    );
  };

  renderModalWrapper = () => {
    if (window.innerWidth < WINDOW_SIZES.md && this.props.asPopupInMobile) {
      return <DropDownPortal>{this.renderModal()}</DropDownPortal>;
    }

    return this.renderModal();
  };

  render() {
    const { visibility } = this.state;
    const { className = '' } = this.props;
    return (
      <div className={`dropdown ${visibility} ${className ? className : ''}`} onClick={this.changeVisibility}>
        {this.props.children}
        {this.renderModalWrapper()}
      </div>
    );
  }
}

export const DropDownModalPane = ({ onClick }: { onClick: (event: any) => void }) => (
  <div onClick={onClick} className={'modal-pane'}></div>
);
