import { IProduct, IProductSize, PRODUCT_TYPE } from 'src/types/products';
import { calculateMenuProductModifiersPrice } from '../calculateMenuProductModifiersPrice/calculateMenuProductModifiersPrice';
import { getProductMainPrice } from '../getProductMainPrice/getProductMainPrice';
import { getFirstCorrectMealSize } from '../combo';

export const calculateProductPrice = (product: IProduct, withoutQuantity?: boolean): number => {
  let price = getProductMainPrice(product);

  if (product.type === PRODUCT_TYPE.MEAL_COMBO) {
    if (!product.sizes?.length || !product.items?.length) return 0;

    const correctSize: IProductSize | null = getFirstCorrectMealSize(product);
    let isCart: boolean = false;
    let cartPrice: number = 0;
    price = 0;

    product.items.forEach((item) => {
      const isSelectedSizeItem: boolean = item.size_id === correctSize?.id;
      const itemModifiersPrice: number = calculateMenuProductModifiersPrice(item.modifiers);

      // calculation for selected items of selected size
      if (isSelectedSizeItem && item.is_selected) {
        isCart = true;
        cartPrice = cartPrice + Number(item.price);
        cartPrice = cartPrice + itemModifiersPrice;
      }

      // calculation for default items of selected size
      if (isSelectedSizeItem && item.is_default && !isCart) {
        price = price + Number(item.price);
        price = price + itemModifiersPrice;
      }
    });

    return (isCart ? cartPrice : price) * (withoutQuantity ? 1 : product.quantity);
  }

  price = price + calculateMenuProductModifiersPrice(product.modifiers);
  price = price * product.quantity;

  return price;
};
