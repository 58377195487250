import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { PageContainer } from '../../components/PageContainer/PageContainer';
import { EmptyList } from '../../components/ScrollableList/EmptyList';
import ScrollableList from '../../components/ScrollableList/ScrollableList';
import { addressStore } from '../../../mobx/barnchStore/address';
import { IAddressResponse } from '../../../types/address';
import Address from './components/Address/Address';
import './styles.scss';

const MyAddressesScreen: FC = observer(() => {
  const renderAddress = (address: IAddressResponse, key: number) => {
    return <Address address={address} key={key} />;
  };

  return (
    <PageContainer className="my_addresses ">
      <ScrollableList
        clear={addressStore.clear}
        renderEmptyList={<EmptyList />}
        list={addressStore.list}
        loading={addressStore.loading}
        last_page={1}
        current_page={1}
        fetch={addressStore.fetchAddresses}
        renderItem={renderAddress}
      />
    </PageContainer>
  );
});
export default MyAddressesScreen;
