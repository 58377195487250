import moment, { Moment } from 'moment';
import { IDaySlot } from 'src/types/time';

export default class DaySlot {
  /**
   * Start of Time slot
   *
   * @type {string}
   * @memberof IDaySlot
   */
  public readonly from: string;
  /**
   * End of Time Slot
   *
   * @type {string}
   * @memberof IDaySlot
   */
  public readonly to: string;

  public readonly isFullDay: boolean;

  /**
   * Is Time Slot have time over midnight
   *
   * @type {boolean}
   * @memberof IDaySlot
   */
  public readonly crossesOverMidnight: boolean;
  public readonly isToday: boolean;
  public readonly isTomorrow: boolean;
  /**
   * This time slot includes Now
   *
   * @type {boolean}
   * @memberof IDaySlot
   */
  public readonly isNow?: boolean;
  public readonly isBeforeBreak?: boolean;
  public readonly isAfterBreak?: boolean;

  public readonly moment: Moment;
  public readonly weekday: number;
  public readonly date: number;
  public readonly month: number;
  public readonly year: number;

  constructor({
    from,
    to,
    isFullDay,
    crossesOverMidnight,
    isToday,
    isTomorrow,
    isNow,
    isBeforeBreak,
    isAfterBreak,
    moment: momentSlot,
  }: IDaySlot) {
    this.from = from;
    this.to = to;

    this.crossesOverMidnight = crossesOverMidnight;

    this.isBeforeBreak = isBeforeBreak;
    this.isAfterBreak = isAfterBreak;

    this.isToday = isToday;
    this.isTomorrow = isTomorrow;
    this.isNow = isNow;
    this.isFullDay = isFullDay;

    if (typeof momentSlot === 'string') {
      this.moment = moment(momentSlot);
    } else {
      this.moment = momentSlot;
    }

    this.weekday = this.moment.weekday();
    this.date = this.moment.date();
    this.month = this.moment.month();
    this.year = this.moment.year();
  }

  public get info(): DaySlot {
    return JSON.parse(JSON.stringify(this));
  }

  /**
   * {@link from From} at Moment
   *
   * If {@link crossesOverMidnight}, plus 1 day
   * @readonly
   * @type {Moment}
   * @memberof DaySlot
   */
  public get fromMoment(): Moment {
    const from = moment(this.from, 'hh:mm A', 'en').date(this.date).month(this.month).year(this.year);
    return from;
  }

  /**
   * {@link to To} at Moment
   *
   * If {@link crossesOverMidnight}, plus 1 day
   * @readonly
   * @type {Moment}
   * @memberof DaySlot
   */
  public get toMoment(): Moment {
    const to = moment(this.to, 'hh:mm A', 'en').date(this.date).month(this.month).year(this.year);
    const isNextDay = !this.crossesOverMidnight && this.to === '12:00 AM';

    return isNextDay ? to.add(1, 'days') : to;
  }

  /**
   * Clone {@link DaySlot} with parameters
   *
   * Use it if you need object with `new params`
   *
   * If you do not pass `new parameters`, the `old parameters` will be used
   * @param {Partial<DaySlot>} params
   * @memberof DaySlot
   */
  public cloneWithParams = (params: Partial<DaySlot>): DaySlot => {
    return new DaySlot({ ...this.info, ...params });
  };
}
