import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { SCREENS } from 'src/constants/screens';
import Spinner from 'src/view/components/Controls/Spinner/Spinner';

class ErrorBoundary extends React.Component<RouteComponentProps> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    this.props.history.push(SCREENS.ERROR());
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Spinner />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
