import { observer } from 'mobx-react';
import { FC, ReactElement, memo } from 'react';
import { ICONS } from 'src/constants/icons';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { messagesStore } from 'src/mobx/messagesStore';
import { IProduct } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Image from 'src/view/components/Image/Image';
import { CartProductHeaderName } from '../../components/CartProductHeaderName/CartProductHeaderName';
import { CartProductHeaderUnit } from '../../components/CartProductHeaderUnit/CartProductHeaderUnit';
import './style.scss';

export const editProductIconId: string = 'edit-product-icon-id';

interface IProps {
  product: IProduct;
}

export const CartProductHeader: FC<IProps> = memo(
  observer(({ product }: IProps): ReactElement => {
    const productName: string = getTranslate(product.name);

    const onRemoveProduct = () => {
      if (product.error) {
        cartStore.remove(product);
        return;
      }

      messagesStore.addMessage({
        type: 'modal',
        title: localeStore.t('txt_delete_cart_item'),
        options: [
          { text: localeStore.t('txt_yes'), onPress: () => cartStore.remove(product) },
          { text: localeStore.t('txt_no'), className: 'cart-product-header__btn-no color-dark' },
        ],
      });
    };

    return (
      <div className="cart-product-header">
        <Image
          src={product.image}
          aspectRatio={1.35}
          title={productName}
          alt={productName}
          wrapperClassName={`cart-product-header__image ${product.error ? 'opacity-25' : ''}`}
        />

        <div className="cart-product-header__content">
          <div className="cart-product-header__text-container">
            <CartProductHeaderName product={product} />
            <CartProductHeaderUnit product={product} />
          </div>

          <div className="cart-product-header__icons">
            <Icon id={editProductIconId} name={ICONS.PENCIL} size={16} className="color-dark color_hover-primary" />

            {cartStore.isEditable ? (
              <Icon name={ICONS.CLOSE} size={14} onPress={onRemoveProduct} className="color-dark color_hover-primary" />
            ) : null}
          </div>
        </div>
      </div>
    );
  })
);
