import { observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { EVENTS } from 'src/constants/events';
import { defaultBranch, PAGE_FROM } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { checkoutStore } from 'src/mobx/checkoutStore';
import { localeStore } from 'src/mobx/localesStore';
import logger from 'src/services/logger';
import { IBranch } from 'src/types/branch';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';
import { addressStore } from '../../../../../mobx/barnchStore/address';
import { cartStore } from '../../../../../mobx/cartStore';
import { requests } from '../../../../../requests';
import branchesScreenStore from '../../branchesScreenStore';
import './style.scss';
import { TimeController } from 'src/controllers/TimeController/TimeController';

interface Props extends RouteComponentProps {
  loading: boolean;
  branch: IBranch | null;
  area?: any | null;
  delivery_type: string;
  id?: string;
  fromPage?: string;
}
interface State {
  loading?: boolean;
  address: string;
  error: null | ISubmitError;
}
interface IErrorOption {
  text: string;
  className?: string;
  textClassName?: string;
  onPress?: () => void;
}
interface ISubmitError {
  title?: string;
  message?: string;
  options: IErrorOption[];
}

@observer
class BranchesSubmitButton extends Component<Props, State> {
  state: State = {
    loading: false,
    address: '',
    error: null,
  };

  handlerForScreen = () => {
    if (cartStore.isSMS) {
      branchStore.closeBranches();
      cartStore.openCart();
    } else {
      this.props.history.push(SCREENS.MENU());
    }
    if (branchesScreenStore.from) {
      this.props.history.push(branchesScreenStore.from);
    }
  };

  closeModal = () => {
    this.setState({ error: null, loading: false });
  };

  submit = async () => {
    try {
      // if (this.props.delivery_type === DELIVERY_TYPES.DELIVERY && !appStore?.settings?.donation?.enabled) {
      //   return this.props.history.push(SCREENS.MENU());
      // }

      /* TODO: delete after checked branch flow*/
      /* && (addressStore.isOpen || addressStore.editId)*/

      if (addressStore.editAddressOnMobileMap) {
        await branchStore.setCoordinate(branchStore.tempMapCoordinate);
        await addressStore.toggleAddressOnMap(false);
        return;
      }
      if (this.props.delivery_type === DELIVERY_TYPES.DELIVERY && !cartStore.isSMS) {
        const data: any = {};
        const coordinate = await branchStore.selectMapCoordinate;
        data.latitude = coordinate?.latitude;
        data.longitude = coordinate?.longitude;
        await branchesScreenStore.selectDeliveryAddress(data);
      }
      this.setState((prevState) => ({ ...prevState, loading: true }));

      const branch = this.props.branch || defaultBranch;

      const branchChanged = branch.id !== branchStore.branch.id;

      // If it SMS, order is preorder and change branch from with preorder to without it
      if (cartStore.isSMS && branchChanged && !branch.preorder && checkoutStore.reorderStore.order.preorder) {
        this.setState({
          error: {
            title: localeStore.t('no_preorder'),
            options: [
              {
                text: localeStore.t('txt_continue'),
                onPress: () => {
                  branchStore.setBranch(branch, this.props.delivery_type);
                  this.closeModal();
                  this.handlerForScreen();
                },
              },
              {
                className: 'background-light',
                textClassName: 'color-dark',
                text: localeStore.t('txt_change'),
                onPress: () => {
                  this.closeModal();
                },
              },
            ],
          },
        });
        return;
      }

      //  Fetch default branch if donation enabled and branch not chosen
      if (appStore?.settings?.donation?.enabled && !this.props.branch) {
        const response: IBranch | null = await requests.branches.getBranch(
          appStore?.settings?.donation?.branch_id || 0,
          { delivery_type: this.props.delivery_type }
        );
        if (response) {
          // Set default id
          response.id = 0;
          branchStore.setBranch(response, this.props.delivery_type);
        }
        this.setState((prevState) => ({ ...prevState, loading: false }));
        this.handlerForScreen();
        return;
      } else {
        if (cartStore.isSMS && branch.id !== checkoutStore.reorderStore?.branch.id) {
          this.validateBranchSMSPages(branch, this.props.area);
          return;
        }
        branchStore.setBranch(branch, this.props.delivery_type);
        if (this.props.area) {
          addressStore.addNewAddress(this.props.area);
        }

        this.setState((prevState) => ({ ...prevState, loading: false }));
        this.handlerForScreen();

        logger.logEvent(EVENTS.DTP_ADDRESS_CONFIRMED);
        return;
      }
    } catch (error: any) {
      console.error(error);
      this.setState((prevState) => ({ ...prevState, loading: false }));
    }
  };
  /**
   * if we are on the sms page and the user has changed the branch, we need to warn him that the delivery time is changed to asap
   * TODO: refactor code and create new modal
   */
  validateBranchSMSPages = (branch: IBranch, area: any) => {
    this.setState({
      error: {
        title: localeStore.t('due_time_not_available'),
        options: [
          {
            text: localeStore.t('txt_continue'),
            onPress: () => {
              addressStore.addNewAddress(area);
              branchStore.setBranch(branch, this.props.delivery_type);
              this.setState((prevState) => ({ ...prevState, loading: false }));
              this.closeModal();
              this.handlerForScreen();
            },
          },
          {
            className: 'background-light',
            textClassName: 'color-dark',
            text: localeStore.t('txt_change'),
            onPress: () => {
              this.closeModal();
            },
          },
        ],
      },
    });
  };
  get title() {
    const { branch, delivery_type } = this.props;

    const branchTime = new TimeController({
      branch,
      date: undefined,
      deliveryType: delivery_type,
    });

    // check if the branch is active now or if a pre-order is available
    const isActive =
      branchTime.availableAtAll ||
      branch?.preorder ||
      (!branch?.preorder && !branch?.is_busy && branch?.is_round_clock);

    if (addressStore.editAddressOnMobileMap) return localeStore.t('update_location');

    if (isActive && delivery_type === DELIVERY_TYPES.DELIVERY) {
      return localeStore.t('deliver_here');
    }
    if (branch && delivery_type === DELIVERY_TYPES.DELIVERY && this.props.fromPage === PAGE_FROM.SMS)
      return localeStore.t('update_location');

    if (branch && delivery_type === DELIVERY_TYPES.DELIVERY && this.props.fromPage === PAGE_FROM.SMS)
      return localeStore.t('update_location');

    if (branch && delivery_type === DELIVERY_TYPES.PICKUP) return localeStore.t('select_pickup_branch');
    return localeStore.t('show_menu');
  }

  /**
   * @deprecated
   * @memberOf submitButtonDisabled
   */
  get submitButtonDisabled() {
    const { branch } = this.props;
    return (
      cartStore.isCheckout &&
      ((!branch?.working_now && !branch?.preorder) ||
        !branch.working_hours ||
        branch.working_hours.length === 0 ||
        !Boolean(branch.status))
    );
  }

  renderModalContent = ({ closeModal }: { closeModal: () => void }) => (
    <ModalMessage
      wrapperClassName={'px-0 pb-0 pt-3'}
      minWidth={320}
      title={this.state.error?.title}
      message={this.state.error?.message}
      options={this.state.error?.options}
      closeModal={closeModal}
    />
  );

  render() {
    const { branch } = this.props;

    if (this.props.fromPage === PAGE_FROM.SMS && !branch) {
      return null;
    }
    return (
      <>
        {/* Submit Button */}
        <ButtonComponent
          onClick={this.submit}
          disabled={false}
          className="branches-submit"
          loading={this.props.loading || !appStore.timestamp || this.state.loading}
          title={this.title}
          id={this.props.id}
        />
        {/* Error Modal */}
        <PopupModal
          className="branches-submit-modal"
          modalClassName="branches-submit-modal-content"
          mobile={{
            popup: true,
            sheet: false,
          }}
          backdrop
          backdropClose
          onClose={this.closeModal}
          position={'top'}
          visible={!!this.state.error}
          justify={'center'}
          content={this.renderModalContent}
        />
      </>
    );
  }
}

export default withRouter(BranchesSubmitButton);
