import { FC, ReactElement, memo } from 'react';
import { IProduct } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import './style.scss';

interface IProps {
  product: IProduct;
}

export const CartProductHeaderUnit: FC<IProps> = memo(({ product }: IProps): ReactElement => {
  const units = productUtils.getProductUnits(product);

  return (
    <p className="cart-product-header__size-unit-wrapper">
      {product.size ? (
        <span className={`cart-product-header__size-unit color-darkgray ${product.size.error ? 'text-through' : ''}`}>
          {getTranslate(product.size.name)}
        </span>
      ) : null}

      {product.size && units.length ? (
        <span className="cart-product-header__size-unit color-darkgray">{' • '}</span>
      ) : null}

      {units.length ? <span className="cart-product-header__size-unit color-darkgray">{units}</span> : null}
    </p>
  );
});
