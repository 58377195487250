import { ICONS } from 'src/constants/icons';
import { staticTheme } from 'src/constants/staticTheme';
import { cartStore } from 'src/mobx/cartStore';
import { IPaymentCard } from 'src/types/user';
import { getCardIcon } from 'src/utils/helpers';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Spinner from 'src/view/components/Controls/Spinner/Spinner';
import '../style.scss';
interface IPaymentTypeCard {
  card: IPaymentCard;
  icon?: string;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}
export function PaymentTypeCard(props: IPaymentTypeCard) {
  const { card, icon, className, loading, onClick, onDelete } = props;
  const selected = cartStore.checkout.selected_card === card.id;

  const _onClick = () => {
    if (!loading) if (onClick) onClick();
  };

  const _onDelete = (e: MouseEvent | React.MouseEvent) => {
    e.stopPropagation();
    if (onDelete) onDelete();
  };

  const renderIcon = () => {
    if (loading) return <Spinner className="payment_type-icon" color={staticTheme.colors.color.gray} size={27} />;
    if (onDelete) return <Icon name={ICONS.TRASH} className={`payment_type-icon`} onPress={_onDelete} />;
  };

  return (
    <div
      className={`payment_type justify-content-between ${className || ''} ${selected ? 'selected' : ''}`}
      onClick={_onClick}
    >
      <div className="row">
        <Icon name={icon || getCardIcon(card.type)} className={`payment_type-icon`} />
        <p>{'**** ' + card.last}</p>
      </div>
      {renderIcon()}
    </div>
  );
}
