import { create as createHydrator } from 'mobx-persist';
import { Provider } from 'mobx-react';
import React from 'react';
import { EVENTS } from './constants/events';
import mainStore from './mobx/mainStore';
import logger from './services/logger';
import RootView from './view/RootView';
import moengage from './services/moengage';
import { appStore } from './mobx/appStore';

export interface IAppState {
  loading: boolean;
}
export default class App extends React.Component<{}, IAppState> {
  state = {
    loading: true,
  };

  componentDidMount = () => {
    logger.logEvent(EVENTS.APP_LOADED);
    this.loadSources();
  };

  private loadSources = async () => {
    this.setState(() => ({ loading: true }));
    await this.hydrateStores();

    if (appStore.settings?.moengage.enabled) {
      moengage.init();
    }

    this.setState(() => ({ loading: false }));
  };

  private hydrate = createHydrator({
    storage: localStorage,
    jsonify: true,
  });

  private hydrateStores = async () => {
    // hudrate all stores
    await Promise.all(
      Object.entries(mainStore).map(async ([key, store]) => {
        if (!store) return;
        await this.hydrate(key, store);
        if (store.afterHydration) {
          await store.afterHydration();
        }
      })
    );
  };

  public render() {
    if (this.state.loading) return <h1>LOADING</h1>;

    return (
      <Provider {...mainStore}>
        <RootView />
      </Provider>
    );
  }
}
