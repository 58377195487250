import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ORDER_STATUS } from '../../../../../../../constants/main';
import { observer } from 'mobx-react';
import moment from 'moment';
import { ordersStore } from '../../../../../../../mobx/ordersStore';
import { deliveryStatusStore } from '../../store';

const DeliveryTimer: FC = observer((): JSX.Element | null => {
  const [currentTime, setCurrentTime] = useState<moment.Moment>(moment());
  const [loading, setLoading] = useState<boolean>(true);

  const serverDate: moment.Moment = moment.utc(ordersStore.order?.dnf.cutoff_at);
  const localDate: moment.LocaleSpecifier = moment(serverDate).utc().local().format();
  const timeBetween: moment.Duration = moment.duration(moment(localDate).diff(moment()));

  const interval: { current: NodeJS.Timeout | null } = useRef(null);

  const isTimeOff = useMemo(() => {
    return moment().isAfter(localDate);
  }, [localDate]);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current as NodeJS.Timeout);
    }
    interval.current = setTimeout(() => {
      if (moment().isAfter(localDate)) {
        clearInterval(interval.current as NodeJS.Timeout);
        deliveryStatusStore.setTimeOut(true);
      } else {
        deliveryStatusStore.setTimeOut(false);
        setCurrentTime(moment());
      }
    }, 1000);
    setLoading(false);
    return () => {
      clearInterval(interval.current as NodeJS.Timeout);
      deliveryStatusStore.setTimeOut(false);
    };
  }, [currentTime]);

  const getSeconds = useMemo(() => {
    return timeBetween.seconds() < 10 ? '0' + timeBetween.seconds() : timeBetween.seconds();
  }, [timeBetween]);

  if (ordersStore.order?.order_status_code !== ORDER_STATUS.ON_HOLD) {
    return null;
  }

  if (loading) {
    return null;
  }
  return (
    <div className={`timer-left ${deliveryStatusStore.timeOut ? 'inactive' : ''}`}>
      {isTimeOff || deliveryStatusStore.timeOut ? '00:00' : timeBetween.minutes() + ':' + getSeconds}
    </div>
  );
});

export default DeliveryTimer;
