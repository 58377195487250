import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import Icon from '../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../constants/icons';
import { productsStore } from '../../../../../mobx/productsStore';
import { WINDOW_SIZES } from '../../../../../constants/main';
import ResponsiveContent from '../../../../components/ResponsiveContent/ResponsiveContent';
import Modal from '../../../../components/Controls/Modal/Modal';
import { localeStore } from '../../../../../mobx/localesStore';
import { MenuSearchItems } from '../MenuSearchItems/MenuSearchItems';
import './style.scss';

export const MenuSearch: React.FC = observer(() => {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    productsStore.searchMenuItems(event.target.value);
  };

  const focusInput = () => {
    if (inputRef?.current) inputRef.current.focus();
  };

  useEffect(() => {
    if (productsStore.searchIsActive) {
      focusInput();
    } else {
      setValue('');
    }
  }, [productsStore.searchIsActive]);

  const toggleActiveSearchState = () => {
    productsStore.toggleSearchIsActive();
  };

  return (
    <div
      className={`
        tab color-dark color_hover-darkgray background-light background_active-white text-elipsis menu-search-container ${
          productsStore.searchIsActive ? 'active' : ''
        }
      `}
    >
      {/** Desktop */}
      <ResponsiveContent min={WINDOW_SIZES.md}>
        <div className="search-icon-container" onClick={toggleActiveSearchState}>
          <Icon name={ICONS.SEARCH} size={16} color={'#1D1D1D'} />
        </div>
        <div className={`search-input-container ${productsStore.searchIsActive ? 'visible' : ''}`}>
          <input
            ref={inputRef}
            type="text"
            className={'search-input'}
            placeholder={localeStore.t('txt_search')}
            value={value}
            onChange={handleInput}
          />
        </div>
        <div
          className={`search-input-close-icon ${productsStore.searchIsActive ? 'visible' : ''}`}
          onClick={toggleActiveSearchState}
        >
          <Icon name={ICONS.CLOSE} size={14} color={'#1D1D1D'} />
        </div>
      </ResponsiveContent>

      {/** Mobile/tablet */}
      <ResponsiveContent max={WINDOW_SIZES.md}>
        <div className="search-icon-container" onClick={toggleActiveSearchState}>
          <Icon name={ICONS.SEARCH} size={16} color={'#1D1D1D'} />
        </div>

        <Modal
          className="search-modal"
          isOpen={productsStore.searchIsActive}
          onRequestClose={toggleActiveSearchState}
          onAfterOpen={focusInput}
          withCloseIcon
          withPane
        >
          <div className="search-modal-container">
            <div className="search-title">{localeStore.t('txt_search')}</div>
            <div className="search-input-container-mob">
              <Icon name={ICONS.SEARCH} size={16} color={'#1D1D1D'} />
              <input
                ref={inputRef}
                type="text"
                className={'search-input'}
                placeholder={localeStore.t('txt_search')}
                value={value}
                onChange={handleInput}
              />
            </div>
            <div className="searched-categories">
              <MenuSearchItems />
            </div>
          </div>
        </Modal>
      </ResponsiveContent>
    </div>
  );
});
