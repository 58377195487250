import { FC, ReactElement, memo, useMemo } from 'react';
import { ordersStore } from 'src/mobx/ordersStore';
import { IOrderProduct } from 'src/types/orders';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import './style.scss';

interface IProps {
  product: IOrderProduct;
}

export const OrderProductPlate: FC<IProps> = memo(({ product }: IProps): ReactElement => {
  const name: string = `${getTranslate(product.name)} ${product.size ? ` (${getTranslate(product.size.name)}) ` : ''}`;
  const price: string = ordersStore.formatPrice(productUtils.calculateOrderProductPrice(product));

  const isOpenable = useMemo((): boolean => {
    switch (true) {
      case Boolean(product.modifiers?.length):
      case Boolean(product.exclusions?.length):
      case Boolean(product.items?.length):
      case Boolean(product.notes):
        return true;
      default:
        return false;
    }
  }, [product]);

  return (
    <div className={`order-product-plate ${isOpenable ? 'cursor-pointer' : ''}`}>
      <p className="order-product-plate__name-wrapper">
        <span className="order-product-plate__name color-dark">{name}</span>
        <span className="order-product-plate__quantity color-dark background-white">{product.quantity}</span>
      </p>

      <div className="order-product-plate__price color-darkgray text-nowrap">{price}</div>
    </div>
  );
});
