import React from 'react';
import { appStore } from 'src/mobx/appStore';
import { promotionStore } from 'src/mobx/promotionStore';
import Image from 'src/view/components/Image/Image';

const MenuBannerSkeleton: React.FC = () => {
  return (
    <div className="banner full-width">
      <Image
        src={promotionStore.banners[0]?.image || appStore.settings?.default_image}
        aspectRatio={2.7}
        wrapperClassName="banner-image active"
        title="banner-image"
        alt="banner-image"
      />
    </div>
  );
};
export default MenuBannerSkeleton;
