import React, { useMemo } from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { PAGE_FROM } from 'src/constants/main';
import { TimeController } from 'src/controllers/TimeController/TimeController';
import { localeStore } from 'src/mobx/localesStore';
import { IBranch } from 'src/types/branch';
import { formatPrice } from 'src/utils/formatter';
import { getPreorderIsAvailable } from 'src/utils/helpers';
import { WorkSlots } from 'src/view/screens/BranchesScreen/components/WorkSlots/WorkSlots';
import BranchDeliveryError from '../BranchDeliveryError/BranchDeliveryError';
import BranchDeliverySkeleton from '../BranchDeliverySkeleton/BranchDeliverySkeleton';
import ReducedDeliveryFeeInfo from '../ReducedDeliveryFeeInfo/ReducedDeliveryFeeInfo';

interface Props {
  branchNotCoveredAndGeoDisabled?: boolean;
  loading: boolean;
  branch?: IBranch | null;
  fromPage?: string;
}

const BranchDeliveryInfo: React.FC<Props> = ({ branch, loading, branchNotCoveredAndGeoDisabled, ...props }) => {
  const preorder_message = useMemo(() => getPreorderIsAvailable(branch), [branch]);
  const timeController = new TimeController({ branch, deliveryType: DELIVERY_TYPES.DELIVERY });
  const [status, isBranchWorking] = timeController.getWorkStatus();

  if (loading) {
    return <BranchDeliverySkeleton />;
  }

  if (!branch) return <BranchDeliveryError branchNotCoveredAndGeoDisabled={Boolean(branchNotCoveredAndGeoDisabled)} />;

  return (
    <>
      {/* Specifiy your address popup */}
      {props.fromPage === PAGE_FROM.SMS ? (
        <div className="specify-exact-location-container">
          <div className="specify-exact-location">{localeStore.t('specifiy_your_address')}</div>
        </div>
      ) : null}

      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center branches-header">
          {/* Preparation  */}
          {branch.working_now ? (
            <p className="branches-title text-size-20 family-semibold">
              ~{branch.preparation?.delivery?.gap} {localeStore.t(branch.preparation?.delivery?.unit || '')}
            </p>
          ) : (
            <p className="branches-title family-semibold">{localeStore.t('branch_closed')}</p>
          )}
          {/* Preorder */}
          {preorder_message ? (
            <div className="branches-preorder-popup family-semibold background-gray color-white ml-3 d-inline-block">
              {preorder_message}
            </div>
          ) : null}
        </div>

        {/* Busy */}
        {branch.is_busy ? (
          <div className="branches-busy family-semibold background-danger color-white d-inline-block">
            {localeStore.t('branch_busy_status')}
          </div>
        ) : null}

        {/* Work Slots */}
        <WorkSlots work_slots={branch.work_slots} status={status} isBranchWorking={isBranchWorking} />

        <div className="branches-order_info row">
          {/* Min order */}
          <div className="branches-row col-12">
            <span className="branches-head family-semi color-dark">{localeStore.t('min_order')}</span>
            <p className="branches-price family-semibold color-dark">{formatPrice(branch.min_order)}</p>
          </div>
          {/* Reduce delivery fee */}
          <div className="branches-row col-12">
            <span className="branches-head family-semi color-dark">{localeStore.t('txt_delivery_fee')}</span>
            <ReducedDeliveryFeeInfo branch={branch} />
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchDeliveryInfo;
