// @ts-ignore
import moengage from '@moengage/web-sdk';
import { IUser } from '../../types/user';
import { appStore } from '../../mobx/appStore';
import { DESTINATION_LOGS, EVENTS, TEvents } from '../../constants/events';
import logger from '../logger';

class MoengageServices {
  public async init() {
    if (!appStore.settings?.moengage.enabled) return null;
    const { app_id, cluster, is_debug } = appStore.settings?.moengage;
    moengage.initialize({ app_id, cluster, debug_logs: is_debug });
  }

  public login = async (user: IUser) => {
    if (!appStore.settings?.moengage.enabled) return null;
    moengage.add_unique_user_id(user.id);
    moengage.add_mobile(user.mobile);
    logger.logEvent(
      EVENTS.LOGIN,
      {
        user_id: user.id,
        mobileNumber: user.mobile,
      },
      DESTINATION_LOGS.MOENGAGE
    );
  };

  public logout = () => {
    if (!appStore.settings?.moengage.enabled) return null;
    moengage.destroy_session();
  };
  public sendEvents = (eventName: TEvents[keyof TEvents], events: any = {}) => {
    if (!appStore.settings?.moengage.enabled) return null;
    moengage.track_event(eventName, events);
  };
}

export default new MoengageServices();
