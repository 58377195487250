import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { useHistory } from 'react-router-dom';
import socket from 'src/services/socket';
import { PAYMENT_TYPES } from '../../../constants/main';
import { SCREENS } from '../../../constants/screens';
import orderCreationRedirect from '../../../hooks/orderCreationRedirect';
import { requests } from '../../../requests';
import Spinner from '../../components/Controls/Spinner/Spinner';
import './style.scss';

interface IOrderActualize extends RouteComponentProps<{ id: string }, StaticContext> {}

const OrderCompletedScreen: FC<IOrderActualize> = observer((props: IOrderActualize) => {
  const { id } = props.match.params;
  const history = useHistory();

  useEffect(() => {
    actualizeOrder();
    return () => {
      socket.disconnect();
    };
  }, []);

  const actualizeOrder = async () => {
    try {
      let order = await requests.getOrderById(id);
      let error: any = '';

      if ([PAYMENT_TYPES.ONLINE_PAYMENT, PAYMENT_TYPES.APPLE_PAY].includes(order.payment_type)) {
        await requests.orderActualize(Number(id), (err) => {
          error = { message: err.response.data.message };
        });
      }
      order = await requests.getOrderById(id);

      orderCreationRedirect(id, order, history, error);
    } catch (error: any) {
      history.replace(SCREENS.NOT_FOUND());
    }
  };

  return (
    <Spinner size={50} className="button-spinner sms_pg d-flex justify-content-center full-width" color={'#000'} />
  );
});
export default OrderCompletedScreen;
