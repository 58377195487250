import { observer } from 'mobx-react';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { WINDOW_SIZES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { IProduct, IProductSize } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import { Cashback } from 'src/view/components/Cashback/Cashback';
import Quantity from 'src/view/components/Controls/Quantity/Quantity';
import QuantitySkeleton from 'src/view/components/Controls/Quantity/QuantitySkeleton';
import Sizes from 'src/view/components/Controls/Sizes/Sizes';
import SizesSkeleton from 'src/view/components/Controls/Sizes/SizesSkeleton';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import { IProductCashbackInfo } from 'src/view/screens/ProductScreen/ProductScreen';
import ProductPrice from '../../components/ProductPrice/ProductPrice';
import ProductSubmit from '../../components/ProductSubmit/ProductSubmit';
import ProductCashback from 'src/view/screens/ProductScreen/components/ProductCashback/ProductCashback';

interface IProps {
  product?: IProduct;
  cashback: IProductCashbackInfo;
  errors: string[];
  needScrollToError: boolean;
  closeModal: () => void;
  onSizeChange: (size: IProductSize) => void;
  changeQuantity: (value: number) => void;
  setNeedScrollToError: (need: boolean) => void;
}

@observer
export default class ProductSubmitBlock extends React.Component<IProps, {}> {
  renderSizes = (): JSX.Element | null => {
    const { product } = this.props;

    if (!product) return <SizesSkeleton className="pxmt-2" />;
    if (product.type) return null;

    if (product) {
      return (
        <Sizes
          className="product-submit-item"
          selected={product.size || null}
          disabled={!cartStore.isEditable}
          options={product.sizes.map((size) => ({ value: size, name: getTranslate(size.name), key: size.id })) || []}
          onChange={this.props.onSizeChange}
        />
      );
    }

    return null;
  };

  get disabled(): boolean {
    const { product } = this.props;

    return Boolean((!product || product.out_of_stock || productUtils.isInvalidMealCombo(product)) && !cartStore.isSMS);
  }

  render() {
    const { product } = this.props;
    const isDonation = !!product?.is_donation;
    const isCombo = !!product?.items?.length;

    const showQuantity = cartStore.isEditable && product;
    return (
      <article className="product-block product-submit background-white d-flex flex-column product-block-padding mt-auto">
        {/* Price and Unit */}
        <div className="flex-row align-items-center  justify-content-between d-none d-md-flex product-submit-item">
          <ResponsiveContent min={WINDOW_SIZES.md}>
            <ProductPrice product={product} />
            {/* <ProductUnit product={product} /> */}
          </ResponsiveContent>
        </div>

        <ResponsiveContent min={WINDOW_SIZES.md}>
          <ProductCashback product={product} branchId={branchStore.branch.id} orderType={branchStore.delivery_type} />
        </ResponsiveContent>

        {/* Price and Unit
        <div className="flex-row align-items-center  justify-content-between d-none d-md-flex">
          <ResponsiveContent min={WINDOW_SIZES.md}>
            <ProductPrice product={product} />
            <ProductUnit product={product} />
          </ResponsiveContent>
        </div> */}

        {/* Sizes */}
        {this.renderSizes()}

        {/* Quanity and Submit */}
        {appStore.mode === 'e-menu' && branchStore.branch.delivery_type === DELIVERY_TYPES.DELIVERY ? null : (
          <div className={`product-submit-button product-submit-item ${!cartStore.isEditable ? 'd-lg-none' : ''} `}>
            <div className="d-flex flex-row align-items-center">
              {product ? (
                showQuantity ? (
                  <Quantity
                    decreaseId={'product_quantity_decrease'}
                    increaseId={'product_quantity_increase'}
                    value={product.quantity}
                    onChange={this.props.changeQuantity}
                    minimum={product.min_quantity}
                  />
                ) : null
              ) : (
                <QuantitySkeleton />
              )}
              <div className="mr-2" />

              <div className={`d-flex flex-column full-height full-width`}>
                {product ? (
                  <ProductSubmit
                    product={product}
                    modifiersErrors={this.props.errors}
                    disabled={this.disabled}
                    closeModal={this.props.closeModal}
                    setNeedScrollToError={this.props.setNeedScrollToError}
                  />
                ) : (
                  <Skeleton width={'100%'} height={52} />
                )}
              </div>
            </div>
          </div>
        )}
      </article>
    );
  }
}
