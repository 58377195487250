import React from 'react';
import './style.scss';
import { localeStore } from '../../../mobx/localesStore';

interface IProps {
  /**
   * Activate shadow on label
   *
   * @type {boolean}
   * @memberof IProps
   */
  shadow?: boolean;

  /**
   * label - text to be displayed on the label
   * default label  - out of stock
   *
   * @type {string}
   * @memberof IProps
   */
  label?: string;

  /**
   * Fix on the upper right panel
   *
   * @type {boolean}
   * @memberof IProps
   */
  fixed?: boolean;

  /**
   * Create custom class
   *
   * @type {string}
   * @memberof IProps
   */
  customClass?: string;
}

/**
 * Component for render Out Of Stock label
 *
 * @component
 * @param {*} { shadow, label, fixed, customClass }
 * @return {*}  {JSX.Element}
 */
export const OutOfStock: React.FC<IProps> = ({ shadow, label, fixed, customClass }): JSX.Element => {
  const text = label ? label : localeStore.t('out_of_stock');
  return (
    <div
      className={`out_of_stock_wrapper d-flex justify-content-start
      ${shadow && 'shadow'}
      ${fixed && 'fixed'}
      ${customClass && customClass}`}
    >
      <p className={'text-size-12 text-center color-red'}>{text}</p>
    </div>
  );
};
