import moment from 'moment';
import React from 'react';
import { getLocaleTime, getLocaleTimeSlot } from 'src/utils/helpers';
import { localeStore } from '../../../../../mobx/localesStore';
import { IWorkSlot } from '../../../../../types/branch';
import './style.scss';

interface IProps {
  slot: IWorkSlot;
  daily?: boolean;
}

export const WorkSlot: React.FC<IProps> = ({ slot, daily = false }) => {
  const currentDay = moment().format('dddd').toLowerCase();
  const isActiveDay = slot.day === currentDay;

  return (
    <div className="work-slot" key={slot.day}>
      <div className={`work-slot-day ${isActiveDay || daily ? 'active' : ''}`}>
        {daily ? localeStore.t(`daily`) : localeStore.t(`txt_${slot.day}`)}
      </div>
      <div className="work-slot-times">
        {slot.start_time && slot.close_time ? (
          <div className={`work-slot-time ${isActiveDay ? 'active' : ''}`}>
            {slot.is_full_day
              ? localeStore.t('round_clock')
              : `${getLocaleTime(slot.start_time)}–${getLocaleTime(slot.close_time)}`}
          </div>
        ) : (
          <span className="slot-closed">{localeStore.t('txt_closed')}</span>
        )}

        {slot.breaks &&
          slot.breaks.length &&
          slot.breaks.map((slotBreak, index) => (
            <div className="work-slot-break" key={index}>
              {`${localeStore.t('break')}: ${getLocaleTimeSlot(slotBreak.start_time, slotBreak.end_time)}`}
            </div>
          ))}
      </div>
    </div>
  );
};
