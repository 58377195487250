import React, { Component } from 'react';
import './style.scss';
interface Props {
  progress: number;
  height?: number;
}

export default class Progress extends Component<Props> {
  render() {
    return (
      <div className="progress card background-white" style={{ height: this.props.height || 48 }}>
        <div className="progress-complete background-primary" style={{ width: `${this.props.progress}%` }}></div>
      </div>
    );
  }
}
