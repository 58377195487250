import { observer } from 'mobx-react';
import { tryPaymentAgainStore } from '../../store';
import { ICONS } from '../../../../../constants/icons';
import SelectedPaymentType from '../../../../containers/SelectedPaymentType/SelectedPaymentType';
import PaymentMethodsList from './components/PaymentMethodsList/PaymentMethodsList';
import PaymentCardPopup from './components/PaymentCardPopup/PaymentCardPopup';
import AvailablePaymentMethodsPopup from './components/AvailablePaymentMethodsPopup/AvailablePaymentMethodsPopup';
import React from 'react';
import './style.scss';
import { ORDER_STATUS } from '../../../../../constants/main';
import { IOrder } from '../../../../../types/orders';

const PaymentMethod = observer(() => {
  const { order_status_code, is_tpa_needed } = tryPaymentAgainStore.order as IOrder;

  if (order_status_code === ORDER_STATUS.WAITING_FOR_PAYMENT && !tryPaymentAgainStore.timeOut && is_tpa_needed) {
    return (
      <div className={'payment-method-wrapper'}>
        <SelectedPaymentType
          cardIcon={false}
          rightIcon={ICONS.ARROW_RIGHT_FILLED}
          storeProps={tryPaymentAgainStore.PaymentMethod}
          showDivider={false}
          onClick={tryPaymentAgainStore.changePaymentType}
        />
        {tryPaymentAgainStore.showPaymentTypeList ? (
          <PaymentMethodsList storeProps={tryPaymentAgainStore} visible={tryPaymentAgainStore.showPaymentTypeList} />
        ) : null}

        <div style={{ width: '100%' }}>
          <PaymentCardPopup />
          <AvailablePaymentMethodsPopup />
        </div>
      </div>
    );
  }

  return null;
});

export default PaymentMethod;
