import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { EVENTS } from 'src/constants/events';
import { requests } from 'src/requests';
import client from 'src/services/client';
import cookies from 'src/services/cookie';
import logger from 'src/services/logger';
import { TLocale } from 'src/types/main';
import { logError } from 'src/utils/helpers';
import { productsStore } from '../productsStore';
import moment from 'moment';
import 'moment/locale/ar';

// Display english figures instead arabic, 1-10 instead ١-١٠
moment?.defineLocale('ar-custom', {
  parentLocale: 'ar',
  preparse: (string: string) => string,
  postformat: (string: string) => string,
  week: {
    dow: 0,
  },
});

export class LocalesStore {
  @observable loading: boolean = false;
  @persist @observable language: TLocale =
    process.env.NODE_ENV === 'production' && window.SETTINGS ? window.SETTINGS?.default_language : 'en';
  @persist @observable timestamp: null | number = null;
  @persist('object') @observable locales: { en: any; ar: any } = { en: null, ar: null };
  @persist('object') @observable urls: { en: string; ar: string } | undefined = { en: '', ar: '' };

  @action setURLs = (urls: { en: string; ar: string } | undefined) => {
    this.urls = urls;
  };

  @computed get direction() {
    return this.language === 'ar' ? 'rtl' : 'ltr';
  }

  // actions

  @action fetchLocale = async () => {
    this.loading = true;

    if (this.urls?.[this.language]) {
      try {
        const locale = await fetch(this.urls[this.language] + `?=${Date.now()}`).then((response) => response.json());

        const localeWasEmpty = !Boolean(this.locales[this.language]);

        this.locales[this.language] = locale;
        this.timestamp = Date.now();

        this.loading = false;

        if (localeWasEmpty) {
          productsStore.cacheParameters.menu_update = 0;
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
        return;
      } catch (error) {
        this.loading = false;
        logError(error);
      }
    }

    try {
      const locale = await requests.fetchLocales(this.language);

      this.locales[this.language] = locale;
      this.timestamp = Date.now();

      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      logError(error);
    }
  };

  @action public setLocale(language: TLocale, shouldChange: boolean = true) {
    const isChanged = language !== this.language;

    logger.logEvent(EVENTS.APP_LANGUAGE_CHANGES, {
      language_from: this.language,
      language_to: language,
    });

    cookies.setCookie('language', language);
    this.language = language;
    client.headers = {
      'Accept-Language': language,
    };

    this.fetchLocale();
    productsStore.fetchMenu();

    if (isChanged && shouldChange) {
      return (window.location.href = window.location.origin);
    }
  }

  @action public t = (key: string, options: any = {}): string => {
    if (!this.locales || !this.locales[this.language]) return key;
    let translate = this.locales[this.language][key] || key;
    if (options) {
      translate = Object.entries(options).reduce((_translate, [_key, value]) => {
        return _translate.replace(`{{${_key}}}`, value);
      }, translate);
    }
    return translate;
  };

  afterHydration() {
    // Will be called manually after getting translations URLs
  }

  @action afterGetURLs = () => {
    this.fetchLocale();

    cookies.setCookie('language', this.language);
    client.headers = {
      'Accept-Language': this.language,
    };

    moment.locale(this.language === 'ar' ? 'ar-custom' : this.language);
  };
}

const localeStore = new LocalesStore();

export { localeStore };
