import * as React from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';
import { branchStore } from '../../../../../mobx/barnchStore';
export interface IBranchSearchBoxProps {
  setCenter?: any;
  address?: string | undefined;
  customClass?: string;
  searchMapIcon?: boolean;
  searchValue?: () => void;
  searchBranch?: boolean;
  searchAddress?: boolean;
}

interface IBranchSearchBoxState {
  prevision: string | undefined;
  search: string | undefined;
  loading: boolean;
  searchValue: string;
}

export default class BranchSearchBox extends React.Component<IBranchSearchBoxProps, IBranchSearchBoxState> {
  autocomplete: google.maps.places.SearchBox | null = null;
  input: React.RefObject<HTMLInputElement> = React.createRef();
  state = {
    prevision: this.props.address,
    search: this.props.address,
    searchAddress: Boolean(this.props.searchAddress),
    loading: true,
    searchValue: '',
  };

  componentDidMount = () => {
    if (this.input.current && !this.props.searchBranch) {
      this.autocomplete = new google.maps.places.SearchBox(this.input.current);
      this.autocomplete.addListener('places_changed', this.onPlacesChanged);
    }
  };

  componentWillUnmount() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
    if (this.props.searchBranch) {
      branchStore.setSearchValue(undefined);
    }
  }

  componentDidUpdate(prevProps: Readonly<IBranchSearchBoxProps>, prevState: Readonly<IBranchSearchBoxState>) {
    if (prevProps.address && !this.props.address) {
      this.clear();
    }
  }

  onPlacesChanged = (select: any) => {
    if (this.autocomplete) {
      const places = this.autocomplete.getPlaces();
      // @ts-ignore
      if (places.length === 0) {
        return;
      }
      // @ts-ignore
      const [lat, lng] = [places[0].geometry?.location.lat(), places[0].geometry?.location.lng()];
      if (lat && lng) {
        this.props.setCenter({ lat, lng }, null);
      }
    }
  };

  onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value }, () => {
      if (this.props.searchBranch) {
        branchStore.setSearchValue(this.state.search);
      }
    });
  };

  clear = () => {
    this.setState({ search: '' });
  };

  handleBlur = (e: any) => {
    this.setState({ searchValue: e.target.value });
  };

  openMap = () => {
    branchStore.togglePickupMap(true);
  };

  showPlaceholder = (): string => {
    return this.state.searchAddress ? localeStore.t('Address') : localeStore.t('branch_name');
  };

  public render() {
    return (
      <div
        className={`
        branches-searchbox-wrapper d-flex flex-grow-1
        ${this.state.searchValue.length && 'blur'}
        ${this.props.customClass ?? ' '}
      `}
      >
        <input
          ref={this.input}
          value={this.state.search}
          onChange={this.onChangeText}
          className="branches-searchbox-input color-text family-semibold"
          placeholder={this.showPlaceholder()}
          onBlur={this.handleBlur}
        />
        <Icon name={ICONS.SEARCH} className="branches-searchbox-icon color-gray" />

        {this.props.searchMapIcon ? (
          <Icon name={ICONS.MAP} onPress={this.openMap} className="branches-searchbox-open-map color-gray" />
        ) : null}
      </div>
    );
  }
}
