import { FC, ReactElement, memo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import Donation from 'src/view/components/Tooltip/Donation/Donation';
import './style.scss';

interface IProps {
  isDonation: boolean;
}

export const CartProductDonation: FC<IProps> = memo(({ isDonation }: IProps): ReactElement | null => {
  if (!isDonation) return null;

  return (
    <div className="cart-product-donation">
      <Donation className="cart-product-donation__image" />
      <p className="cart-product-donation__text color-dark">{localeStore.t('cart_donation_info')}</p>
    </div>
  );
});
