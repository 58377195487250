import React, { FC, useCallback, useMemo } from 'react';
import { userStore } from '../../../../../../../mobx/userStore';
import { localeStore } from '../../../../../../../mobx/localesStore';
import { addressStore } from '../../../../../../../mobx/barnchStore/address';
import ButtonComponent from '../../../../../../components/Controls/Button/Button';
import branchesScreenStore from '../../../../branchesScreenStore';
import { observer } from 'mobx-react';

const AddNewAddressButton: FC = observer((): JSX.Element | null => {
  const isUserHaveAddress = useMemo(() => {
    return !!(userStore.isLogin && addressStore.list.length);
  }, [userStore.isLogin, addressStore.list]);

  const addNewAddress = useCallback(() => {
    branchesScreenStore.onAddressCreate();
  }, []);
  if (!isUserHaveAddress) return null;
  return (
    <ButtonComponent
      onClick={addNewAddress}
      className="add-new-address"
      loading={addressStore.loading}
      title={localeStore.t('add_address')}
      id={'0'}
    />
  );
});
export default AddNewAddressButton;
