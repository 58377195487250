import * as React from 'react';
import { IBranch } from 'src/types/branch';
import PickupBranch from 'src/view/screens/BranchesScreen/components/PickupBranch/PickupBranch';
import PickupBranchSkeletton from 'src/view/screens/BranchesScreen/components/PickupBranch/PickupBranchSkeleton';
import './style.scss';

export interface IBranchesListProps {
  branches: IBranch[];
  onSelectBranch: (branch: IBranch) => void;
  loading: boolean;
  selected?: IBranch['id'] | undefined;
}

export default class BranchesList extends React.Component<IBranchesListProps> {
  renderBranch = (branch: IBranch) => {
    if (!branch.working_now) return;
    return (
      <PickupBranch
        key={branch.id}
        onSelectBranch={this.props.onSelectBranch}
        branch={branch}
        selected={this.props.selected}
      />
    );
  };

  public render() {
    return (
      <div className="reservation-branches-sidebar animation slide_in_right">
        <div className="reservation-branches-sidebar-scroll">
          <div className="reservation-branches-sidebar-list d-flex flex-column">
            {this.props.loading ? (
              <React.Fragment>
                <PickupBranchSkeletton />
                <PickupBranchSkeletton />
                <PickupBranchSkeletton />
                <PickupBranchSkeletton />
              </React.Fragment>
            ) : (
              this.props.branches.map(this.renderBranch)
            )}
          </div>
        </div>
      </div>
    );
  }
}
