import { observer } from 'mobx-react';
import { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { EVENTS } from 'src/constants/events';
import { ICONS } from 'src/constants/icons';
import { PAYMENT_TYPES } from 'src/constants/main';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import logger from 'src/services/logger';
import { IBranchPaymentType } from 'src/types/branch';
import { ICartCheckout } from 'src/types/cart';
import { IPaymentCard } from 'src/types/user';
import { checkApplePayAvailable } from 'src/utils/ApplePay';
import { getPaymentType, getUserLastCard } from 'src/utils/helpers';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import { DELIVERY_TYPES } from '../../../../../../../constants/deliveryTypes';
import { PaymentType } from '../../../CheckoutPaymentTypes/CheckoutPaymentType/CheckoutPaymentType';
import { PaymentTypeCard } from '../../../CheckoutPaymentTypes/CheckoutPaymentTypeCard/CheckoutPaymentTypeCard';
import './style.scss';

interface State {
  isApplePayAvaliable: boolean;
  loading: boolean;
  deleted_id: number | null;
}
@observer
export default class CheckoutModalPaymentType extends Component<{}, State> {
  state: State = {
    isApplePayAvaliable: false,
    loading: false,
    deleted_id: null,
  };

  componentDidMount = async () => {
    const isApplePayAvaliable = await checkApplePayAvailable();
    this.setState({ isApplePayAvaliable });
  };

  /**
   * Update select payment card
   *
   * @memberof CheckoutPaymentType
   */
  updateSelectPaymentCard = async () => {
    try {
      // Update payment type list
      await userStore.fetchUserCards();

      const last_payment_card = getUserLastCard(userStore.payment_cards);
      if (last_payment_card) cartStore.selectPaymentCard(last_payment_card);

      this.setState({ deleted_id: null });
    } catch (error: any) {
      this.setState({ loading: false, deleted_id: null });
    }
  };

  closeModal = () => {
    cartStore.setModalValue('paymentType', false);
  };

  /**
   * Set payment_type
   *
   * @param {ICartCheckout['payment_type']} payment_type
   * @param {() => void} [action]
   * @memberof CheckoutModalPaymentType
   */
  setPaymentType = (payment_type: ICartCheckout['payment_type'], action?: () => void) => () => {
    // @param payment_type: IPaymentTypeId | 4
    cartStore.selectPaymentType(payment_type);
    if (action) action();
    this.closeModal();
  };

  onAddCrediCardPress = () => {
    /* @param payment_type
       @param action
    */
    this.setPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT)();
    // For add card modal
    cartStore.setModalValue('paymentCard', { loading: false });
  };

  setSelectPaymentCard = (card: IPaymentCard) => () => {
    cartStore.selectPaymentCard(card);
  };

  /**
   * Delete payment card
   *
   * @param {IPaymentCard} card
   * @memberof CheckoutModalPaymentType
   */
  onDeleteCard = (card: IPaymentCard) => () => {
    if (!this.state.deleted_id)
      this.setState({ deleted_id: card.id }, async () => {
        await cartStore.deleteCreditCard(card);
        await this.updateSelectPaymentCard();
      });
  };

  /**
   * For render Online Payment Types Components
   *
   * @memberof CheckoutModalPaymentType
   * @return Payment Types Components
   */
  renderOnlinePaymentTypes = () => {
    const { payment_cards } = userStore;
    const { isApplePayAvaliable, deleted_id } = this.state;
    return (
      <>
        {userStore.isLogin ? (
          <>
            {/* user payment cards */}
            {payment_cards.map((item, index) => (
              <PaymentTypeCard
                key={index}
                card={item}
                onClick={this.setPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT, this.setSelectPaymentCard(item))}
                onDelete={this.onDeleteCard(item)}
                loading={item.id === deleted_id}
              />
            ))}
            {!this.state.loading ? (
              payment_cards?.length ? (
                // Add card
                <PaymentType
                  icon={ICONS.PLUS}
                  type_id={PAYMENT_TYPES.ONLINE_PAYMENT}
                  text={localeStore.t('txt_enter_card_details')}
                  onClick={this.onAddCrediCardPress}
                />
              ) : (
                // Online payment
                <PaymentType
                  icon={ICONS.CREDIT_CARD}
                  type_id={PAYMENT_TYPES.ONLINE_PAYMENT}
                  text={getPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT)}
                  onClick={this.onAddCrediCardPress}
                />
              )
            ) : (
              <>
                <Skeleton height={45} style={{ marginBottom: '10px', borderRadius: '10px' }} />
                <Skeleton height={45} style={{ marginBottom: '10px', borderRadius: '10px' }} />
                <Skeleton height={45} style={{ marginBottom: '10px', borderRadius: '10px' }} />
              </>
            )}
          </>
        ) : (
          // Online payment if user not logged in
          <PaymentType
            icon={ICONS.CREDIT_CARD}
            type_id={PAYMENT_TYPES.ONLINE_PAYMENT}
            text={getPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT)}
            onClick={this.setPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT)}
          />
        )}
        {/* Apple Pay */}
        {isApplePayAvaliable ? (
          <PaymentType
            icon={ICONS.APPLE_PAY}
            type_id={PAYMENT_TYPES.APPLE_PAY}
            text={localeStore.t('applePay')}
            onClick={this.setPaymentType(PAYMENT_TYPES.APPLE_PAY)}
          />
        ) : null}
      </>
    );
  };

  /**
   * For render Payment Type depending on type
   *
   * @param {IBranchPaymentType} { id } - id payment type
   * @memberof CheckoutModalPaymentType
   * @returns {PaymentType} PaymentType
   */
  renderPaymentTypes = ({ id }: IBranchPaymentType) => {
    if (branchStore.delivery_type === DELIVERY_TYPES.PICKUP && cartStore.isSMS && id !== PAYMENT_TYPES.ONLINE_PAYMENT) {
      return;
    }
    if (
      branchStore.delivery_type === DELIVERY_TYPES.DELIVERY &&
      cartStore.isSMS &&
      branchStore.branch.id === branchStore.defaultBranchId &&
      id !== branchStore.defaultBranchPaymentMethods
    ) {
      return;
    }
    switch (id) {
      case PAYMENT_TYPES.CASH:
        return <PaymentType key={id} type_id={id} text={getPaymentType(id)} onClick={this.setPaymentType(id)} />;
      case PAYMENT_TYPES.TERMINAL:
        return <PaymentType key={id} type_id={id} text={getPaymentType(id)} onClick={this.setPaymentType(id)} />;
      case PAYMENT_TYPES.ONLINE_PAYMENT:
        return <div key={id}>{this.renderOnlinePaymentTypes()}</div>;
      case PAYMENT_TYPES.POINTS:
        return <PaymentType key={id} type_id={id} text={getPaymentType(id)} onClick={this.setPaymentType(id)} />;
    }
  };

  renderContent = () => {
    return (
      <div className="checkout-modal-payment-type d-flex d-lg-none  ">
        {branchStore.getPaymentTypes.map(this.renderPaymentTypes)}
      </div>
    );
  };

  render() {
    const { modals } = cartStore.checkout;
    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose
        backdrop
        backdropClose
        visible={modals.paymentType}
        onClose={this.closeModal}
        content={this.renderContent}
        mobile={{
          popup: true,
          sheet: true,
        }}
      />
    );
  }
}
