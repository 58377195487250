import { observer } from 'mobx-react';
import React from 'react';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import './style.scss';

const CartInfoPopup = observer((): JSX.Element | null => {
  if (!cartStore.calculator.left_to_min_order && !cartStore.calculator.left_to_next_reduced_delivery_fee) return null;

  return (
    <>
      {cartStore.calculator.left_to_min_order ? (
        <div className={'d-flex flex-column card cart-info-popup'}>
          {/* if left left_to_min_order is presend and more of zero need to show this message  */}
          <p className="family-semibold text-size-14 color color-gray text-center">
            {localeStore.t('minimum_order_alert_message')} {formatPrice(cartStore.calculator.min_order)}
          </p>
        </div>
      ) : null}

      {cartStore.calculator.left_to_next_reduced_delivery_fee && cartStore.calculator.next_reduced_delivery_fee ? (
        <div className={'d-flex flex-column card cart-info-popup'}>
          <p className="family-semibold text-size-14 color color-gray text-center">
            {localeStore.t('txt_add_amount_till_rdf', {
              Amount: formatPrice(cartStore.calculator.left_to_next_reduced_delivery_fee),
              Deliveryfee: formatPrice(cartStore.calculator.next_reduced_delivery_fee),
            })}
          </p>
        </div>
      ) : null}

      {cartStore.calculator.left_to_next_reduced_delivery_fee &&
      Number(cartStore.calculator.next_reduced_delivery_fee) === 0 ? (
        <div className={'d-flex flex-column card cart-info-popup'}>
          <p className="family-semibold text-size-14 color color-gray text-center">
            {localeStore.t('txt_add_amount_till_free_delivery', {
              Amount: formatPrice(cartStore.calculator.left_to_next_reduced_delivery_fee),
            })}
          </p>
        </div>
      ) : null}
    </>
  );
});

export default React.memo(CartInfoPopup);
