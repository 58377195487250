import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { preorderStore } from 'src/mobx/preorderStore';
import { ICheckoutModals } from 'src/types/cart';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';
import Preorder from 'src/view/containers/Preorder/Preorder';
import './style.scss';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModalTime extends Component<Props> {
  closeModal = () => {
    cartStore.setModalValue('time', false);
  };

  onPreorderSubmit = (time?: number) => {
    preorderStore.setPreorder(time, true);
    this.closeModal();
  };

  renderContent = ({ closeModal }: { closeModal: () => void }) => {
    return (
      <div className="checkout-modal-time preorder-helper">
        <Preorder
          branch={branchStore.branch}
          delivery_type={branchStore.delivery_type}
          time={preorderStore.preorder_unix}
          onSubmit={this.onPreorderSubmit}
        />
      </div>
    );
  };

  render() {
    const { modals } = cartStore.checkout;
    return (
      <PopupModal
        justify={'center'}
        position={'top'}
        selfClose={true}
        backdrop
        backdropClose
        visible={modals.time}
        onClose={this.closeModal}
        content={this.renderContent}
        mobile={{ sheet: true, popup: true }}
      />
    );
  }
}

export default withRouter(CheckoutModalTime);
