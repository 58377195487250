import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import './style.scss';

export default class NotFoundScreen extends Component {
  state = {};

  render() {
    return (
      <div className="d-flex flex-grow-1 align-items-center ">
        <div className="container">
          <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
            <h1 className="notfound-title mb-2">Opps!</h1>
            <h2 className="mb-5">Looks like something is missing</h2>

            <Link
              rel="prev"
              title={localeStore.t('txt_back_to_menu')}
              hrefLang={localeStore.language}
              to={SCREENS.MENU()}
              className="d-flex "
            >
              <ButtonComponent style={{ minWidth: 340 }} className="full-width background-light">
                <span className="color-dark">{localeStore.t('txt_back_to_menu')}</span>
              </ButtonComponent>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
