import React, { Component } from 'react';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { preorderStore } from 'src/mobx/preorderStore';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import Preorder from '../../Preorder/Preorder';
import './style.scss';
interface Props {
  className?: string;
  backdrop?: boolean;
  backdropClose?: boolean;
  visible: boolean;
  message?: string;
  onClose?: () => void;
}

export default class PreorderHelperModal extends Component<Props> {
  closeModal = () => {
    if (this.props.onClose) this.props.onClose();
  };

  onPrerderSubmit = (time?: number) => {
    preorderStore.setPreorder(time, true);
    this.closeModal();
  };

  renderPreorderModal = ({ closeModal }: { closeModal: () => void }) => {
    return (
      <div className="pt-3 d-flex flex-column full-height overflow-hidden">
        <p className="info-popup-title">
          {this.props?.message ?? localeStore.t('branch_is_closed_now_select_preorder_time')}
        </p>
        <Preorder
          branch={branchStore.branch}
          delivery_type={branchStore.delivery_type}
          time={preorderStore.preorder_unix}
          onSubmit={this.onPrerderSubmit}
        />
      </div>
    );
  };

  render() {
    const { className, backdrop, backdropClose, visible } = this.props;
    return (
      <PopupModal
        className={className}
        modalClassName="preorder_helper_modal"
        backdrop={backdrop}
        backdropClose={backdropClose}
        justify={'center'}
        position={'top'}
        selfClose={true}
        content={this.renderPreorderModal}
        onClose={this.closeModal}
        mobile={{ sheet: false, popup: true }}
        visible={visible}
      />
    );
  }
}
