import React, { useRef, useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import DropDownModal from '../../../components/Controls/DropDownModal/DropDownModal';
import LoginContainer from '../../../containers/LoginContainer/LoginContainer';
import Input from '../../../components/Controls/Input/Input';
import Icon from '../../../components/Controls/Icon/Icon';
import Chevron from './Chevron';
import { localeStore } from '../../../../mobx/localesStore';
import { userStore } from '../../../../mobx/userStore';
import { ICONS } from '../../../../constants/icons';

type Props = {
  setOpenLoginContainer: (opened: boolean) => void;
  setOrderModalOpened: (opened: boolean) => void;
  isOpenLoginContainer: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: () => void;
  isSubmitted: boolean;
  required: boolean;
  selected: boolean;
  clearOrder: (event: MouseEvent | React.MouseEvent) => void;
};

const OrdersInput = ({
  setOpenLoginContainer,
  setOrderModalOpened,
  isOpenLoginContainer,
  required,
  value,
  onChange,
  isSubmitted,
  selected,
  clearOrder,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const openLoginContainer = () => {
    setOpenLoginContainer(true);
    document.addEventListener('click', closeLoginContainer);
  };

  const handleClick = () => {
    !userStore.isLogin ? openLoginContainer() : setOrderModalOpened(true);
  };

  const closeLoginContainer = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const pressedElelement: any = event.target;
    const contains = containerRef.current?.contains(pressedElelement);

    if (!contains) {
      setOpenLoginContainer(false);
      document.removeEventListener('click', closeLoginContainer);
    }
  };

  const onValid = (value: string): boolean => {
    return !!!value && value.length > 0;
  };

  return (
    <div ref={containerRef}>
      <>
        <Input
          className={'orders-input'}
          value={value}
          placeholder={localeStore.t('Order')}
          contentEditable={false}
          onClick={handleClick}
          error={value === '' && isSubmitted && required}
          onChange={onChange}
          readOnly={true}
          rightIcon={
            !selected ? (
              <Chevron className={'orders-input-chevron'} onClick={handleClick} />
            ) : (
              <Icon
                name={ICONS.CLOSE}
                color={'darkgrey'}
                size={12}
                onPress={clearOrder}
                style={{ cursor: 'pointer' }}
              />
            )
          }
          rightIconContainerStyle={{ top: '15px' }}
          required={required}
          validate={onValid}
        />
      </>
      {!userStore.isLogin && isOpenLoginContainer && (
        <DropDownModal
          listClassName={'full-width'}
          visible={isOpenLoginContainer}
          content={() => (
            <LoginContainer title={localeStore.t('contact_order')} closeModal={() => setOpenLoginContainer(false)} />
          )}
        ></DropDownModal>
      )}
    </div>
  );
};

export default OrdersInput;
