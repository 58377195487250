import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import client from 'src/services/client';
import { IBanner } from 'src/types/products';

export class PromotionStore {
  @observable public loadingBanners: boolean = false;
  @persist('list') @observable public banners: IBanner[] = [];

  @action public fetchBanners = async () => {
    try {
      this.loadingBanners = true;
      const response = await client.get(`/v3/promotion/banners`);
      this.banners = response;
      this.loadingBanners = false;
    } catch (error: any) {
      this.loadingBanners = false;
    }
  };

  afterHydration() {
    this.fetchBanners();
  }
}

const promotionStore = new PromotionStore();

export { promotionStore };
