import { localeStore } from 'src/mobx/localesStore';
import {
  Calories,
  IProduct,
  IResponseProductModifier,
  IResponseProductModifierOption,
  PRODUCT_TYPE,
} from 'src/types/products';
import { getProductUnit } from '../getProductUnit/getProductUnit';

/**
 * get list of calculated units
 */
export const getProductCalculatedUnits = (product: IProduct | undefined | null): string[] => {
  if (!product) return [];
  return [_getProductCalculatedCalories(product), getProductUnit(product)].filter(Boolean) as string[];
};

/**
 * get calculated calories string if calories unit is exist in product
 */
const _getProductCalculatedCalories = (product: IProduct | undefined): null | string => {
  if (!product) return null;
  const calories = _calculateProductCalories(product);
  if (typeof calories === 'number') {
    return `${calories} ${localeStore.t('kcal')}`;
  }
  return null;
};

/**
 * method will calculate product calories based on selected variabilities
 * support all product types (like meal, deal, default)
 */
const _calculateProductCalories = (product: IProduct | undefined): number | null => {
  if (!product) return null;

  let calories: null | number = null;

  const mainCalories = _getProductMainCalories(product);

  let additionalCalories = null;

  if (product.type === undefined || product.type === PRODUCT_TYPE.PRODUCT) {
    additionalCalories = product?.modifiers.reduce(
      (productCalories: number | null, modifier: IResponseProductModifier): number | null => {
        return modifier.options.reduce((optionCalories: number | null, option: IResponseProductModifierOption) => {
          if (option.selected && typeof option.calories === 'number') {
            if (typeof option.calories === 'number') {
              optionCalories = _calculateCaloriesSum(optionCalories, option.calories * option.quantity);
            }
          }
          return optionCalories;
        }, productCalories);
      },
      null
    );
  } else if (product.items && product.type === PRODUCT_TYPE.MEAL_COMBO) {
    additionalCalories = product.items?.reduce((comboCalories: null | number, item) => {
      if (product.size && item.size_id === product.size?.id && item.is_selected) {
        if (typeof item.calories === 'number') {
          comboCalories = _calculateCaloriesSum(comboCalories, item.calories);
        }
      }

      return comboCalories;
    }, null);
  } else if (product.items && PRODUCT_TYPE.DEAL_COMBO === product.type) {
    additionalCalories = _calculateCaloriesSum(...product.items.map((item) => item.calories));
  }

  calories = _calculateCaloriesSum(mainCalories, additionalCalories);

  if (product.quantity && typeof calories === 'number') {
    calories = calories * product.quantity;
  }

  return calories;
};

const _getProductMainCalories = (product: IProduct | undefined): number | null => {
  if (product?.size && typeof product?.size?.calories === 'number') {
    return product.size.calories;
  }

  if (typeof product?.calories === 'number') {
    return product.calories;
  }

  return null;
};

/**
 * calculate multiple calories values to one
 */
const _calculateCaloriesSum = (...calories: Calories[]): number | null =>
  calories.reduce((sum, calorie): null | number => {
    if (calorie !== null) {
      if (sum === null) sum = 0;
      sum = sum + calorie;
    }

    return sum;
  }, null);
