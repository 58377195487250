export const hash = (json: string) => {
  let _hash = 0;
  let i = undefined;
  let chr = undefined;
  for (i = 0; i < json.length; i++) {
    chr = json.charCodeAt(i);
    _hash = (_hash << 5) - _hash + chr;
    _hash |= 0; // Convert to 32bit integer
  }
  return `${_hash}`;
};
