import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import './style.scss';
import { localeStore } from '../../../../mobx/localesStore';
import { autorun } from 'mobx';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  resizable?: boolean;
  icon?: ReactNode;
  containerStyle?: CSSProperties;
  iconContainerStyle?: CSSProperties;
  error?: boolean;
  required?: boolean;
  animated?: boolean;
  onClick?: () => void;
}

const TextArea = forwardRef(
  (
    {
      resizable,
      className = '',
      icon,
      error,
      containerStyle,
      iconContainerStyle,
      required,
      onClick,
      placeholder,
      animated,
      value,
      ...rest
    }: Props,
    innerRef: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const [focused, setFocus] = useState(false);
    const ref = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(innerRef, () => ref.current!, [ref]);

    const _onClick = () => {
      setFocus(true);
      ref.current?.focus();
      if (onClick) onClick();
    };

    const _onBlur = () => {
      setFocus(false);
    };
    return (
      <div>
        {false && (
          <div className={'login-error color-white family-regular background-danger error-container mb-2'}>{error}</div>
        )}
        <div
          className={`text-area ${className}${error ? ' error' : ''}${required ? ' required' : ''}${
            focused ? ' focused' : ''
          }`}
          style={containerStyle}
          onClick={_onClick}
        >
          {/* Animated placehoder */}
          {animated ? (
            <span className={`text-area-placeholder family-semibold ${!!value ? 'compressed' : ''}`}>
              {placeholder}
            </span>
          ) : null}

          <textarea
            ref={ref}
            style={{
              resize: resizable ? 'unset' : 'none',
              padding: icon
                ? localeStore.language === 'ar'
                  ? '15px 55px 15px 20px'
                  : '15px 20px 15px 55px'
                : '15px 20px',
            }}
            className={`color-dark`}
            onBlur={_onBlur}
            defaultValue={value}
            placeholder={animated ? '' : placeholder}
            {...rest}
          />

          {/* Required dot */}
          {required && <div className={'right-icon danger-circle background-danger'} />}

          {icon && (
            <div className={'text-area__trailing-icon color-gray'} style={iconContainerStyle}>
              {icon}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default TextArea;
