import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ICONS } from 'src/constants/icons';
import { cartStore } from 'src/mobx/cartStore';
import { defaultCartCheckout } from 'src/mobx/cartStore/checkout';
import { localeStore } from 'src/mobx/localesStore';
import { ICartCheckout, ICheckoutCurbside } from 'src/types/cart';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Input from 'src/view/components/Controls/Input/Input';
import PhoneInput from 'src/view/components/Controls/PhoneInput/PhoneInput';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import Tooltip from 'src/view/components/Tooltip/Tooltip';
import CheckoutModalSomeoneForm from './components/CheckoutModalSomeoneForm';
import './style.scss';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModalSomeone extends Component<Props> {
  closeModal = () => {
    cartStore.setModalValue('someone', false);
  };

  renderContent = () => {
    return <CheckoutModalSomeoneForm onClose={this.closeModal} />;
  };

  render() {
    const { modals } = cartStore.checkout;
    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose
        backdrop
        style={{ maxHeight: '100%', border: 0 }}
        onClose={this.closeModal}
        visible={!!modals.someone}
        content={this.renderContent}
        mobile={{
          popup: true,
          sheet: true,
        }}
      />
    );
  }
}
export default withRouter(CheckoutModalSomeone);
