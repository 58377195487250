import { useEffect, useMemo, useState } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { requests } from 'src/requests';
import { ICashbackInfo } from 'src/types/products';
import { IProduct } from 'src/types/products';
import { productUtils } from 'src/utils/productUtils';
import { Cashback } from 'src/view/components/Cashback/Cashback';

interface IProductCashbackProps {
  product: IProduct | undefined;
  branchId: number;
  orderType: string;
}

const ProductCashback = ({ product, branchId, orderType }: IProductCashbackProps) => {
  const [cashback, isLoading] = useProductCashback(product?.id, branchId, orderType);

  const cashbackAmount = useMemo(
    () => productUtils.calculateProductCashbackAmount(product, cashback),
    [cashback, product]
  );

  if (product?.is_donation || Boolean(product?.items?.length) || (cashback?.id == null && !isLoading)) {
    return null;
  }

  return (
    <>
      <Cashback
        name={cashback?.name}
        description={cashback?.description}
        amount={cashbackAmount}
        loading={isLoading}
        className="my-2"
      />
      {!isLoading && (
        <p className="family-regular text-size-10 color-gray mt-1">
          {localeStore.t('cashback_final_amount_will_be_in_checkout_stage_infotext')}
        </p>
      )}
    </>
  );
};

const useProductCashback = (
  productId: number | undefined,
  branchId: number,
  orderType: string
): [ICashbackInfo | null, boolean] => {
  const [cashback, setCashback] = useState<ICashbackInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const runEffect = async () => {
      try {
        setIsLoading(true);

        const cashback = await requests.fetchCashbackInfo(productId, branchId, orderType);

        if (isMounted) {
          setCashback(cashback);
        }
      } catch (error: any) {
        console.error({ error });
      } finally {
        setIsLoading(false);
      }
    };

    runEffect();

    return () => {
      isMounted = false;
    };
  }, []);

  return [cashback, isLoading];
};

export default ProductCashback;
