import React, { memo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import ImageSlider from 'src/view/components/ImageSlider/ImageSlider';
import ScrollableBlock from 'src/view/containers/ScrollableBlock/ScrollableBlock';
import './style.scss';

interface IProps {
  item: IProductInsideCombo;
  onClickClose: () => void;
}

export const DealComboItemModal: React.FC<IProps> = memo(({ item, onClickClose }: IProps): JSX.Element => {
  const units = productUtils.getComboItemUnits(item);
  const description: string | undefined = [...units, getTranslate(item.description)].filter(Boolean).join(' • ');
  const unavailableMessage: string | undefined = productUtils.getComboItemUnavailableMessage(item, false);

  return (
    <div className="d-flex flex-column flex-grow-1 full-height">
      <ImageSlider
        images={item.images || [item.image]}
        aspectRatio={295 / 250}
        sliderClassName={`product-deal-item-mobile-modal__image ${unavailableMessage ? 'opacity-50' : ''}`}
      />

      {unavailableMessage ? (
        <p className="product-deal-item-mobile-modal__unavailable-msg letter-spacing-5">
          {localeStore.t(unavailableMessage)}
        </p>
      ) : null}

      <div className="product-deal-item-mobile-modal__bottom-block">
        <div className="product-deal-item-mobile-modal__text-wrapper">
          <p className="product-deal-item-mobile-modal__name color-dark">{getTranslate(item.name)}</p>

          <ScrollableBlock showScrollbar scrollbarSize="small" className="product-deal-item-mobile-modal__scroll">
            <p className="product-deal-item-mobile-modal__description color-dark text-lite-elipsis">{description}</p>
          </ScrollableBlock>
        </div>

        <div className="product-deal-item-mobile-modal__btn-selected background_pseudo-primary" onClick={onClickClose}>
          <p className="product-deal-item-mobile-modal__btn-selected_text family-semibold color-primary">
            {localeStore.t('selected')}
          </p>
        </div>
      </div>
    </div>
  );
});
