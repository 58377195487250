import { IProductInsideCombo } from 'src/types/combo';

export const getComboItemUnavailableMessage = (item: IProductInsideCombo, isMeal: boolean): string | undefined => {
  if (isMeal) {
    switch (true) {
      case !item.category_status:
      case !item.status:
        return 'cmgci_tile_unavailable_label';
      case Boolean(item.out_of_stock):
      case Boolean(item.is_delete):
      case Boolean(item.category_is_delete):
      case Boolean(item.is_size):
      case Boolean(item.is_exclusions): // TODO: combo exclusions
      case Boolean(item.is_donation):
        return 'cmgci_tile_oos_label';
      default:
        return undefined;
    }
  } else {
    switch (true) {
      case !item.status:
        return 'unavailable';
      case Boolean(item.out_of_stock):
      case Boolean(item.is_delete):
      case !item.category_status:
      case Boolean(item.category_is_delete):
      case Boolean(item.is_required_modifiers):
      case Boolean(item.is_size):
      case Boolean(item.is_exclusions):
      case Boolean(item.is_donation):
        return 'out_of_stock';
      default:
        return undefined;
    }
  }
};
