import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { addressStore } from '../../../../../../../mobx/barnchStore/address';
import DeliveryAddressSkeleton from '../../../../components/DeliveryAddressBranch/DeliveryAddressSkeleton';
import Address from '../Address/Address';
import AddNewAddressButton from '../AddNewAddressButton/AddNewAddressButton';

const AddressesList: FC = observer((): JSX.Element => {
  return (
    <div className="branches-sidebar-list d-flex flex-column">
      {addressStore.loading ? (
        <React.Fragment>
          <DeliveryAddressSkeleton />
          <DeliveryAddressSkeleton />
          <DeliveryAddressSkeleton />
          <DeliveryAddressSkeleton />
          <DeliveryAddressSkeleton />
          <DeliveryAddressSkeleton />
        </React.Fragment>
      ) : (
        addressStore.list?.map((address) => <Address address={address} key={address.id} />)
      )}
      {!addressStore.loading && addressStore.list.length ? <AddNewAddressButton /> : null}
    </div>
  );
});
export default AddressesList;
