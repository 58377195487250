import moment, { Moment } from 'moment';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { DAYS_INDEX_IN_WEEK } from 'src/constants/main';
import { localeStore } from 'src/mobx/localesStore';
import { BranchStatus, IBranch, IPreparation, IWorkSlot } from 'src/types/branch';
import { ISlot, ISlots, ITimeSlot, ITimeSlotHour, IWeekDaysSlots, TBranchStatus } from 'src/types/time';
import { calculatePreorderLimit, getDayLabel } from 'src/utils/branch';
import { formatTime } from 'src/utils/formatter';
import { isRTL } from 'src/utils/helpers';
import { isNumber } from 'util';
import DaySlot from '../../models/DaySlot/DaySlot';
import { appStore } from 'src/mobx/appStore';

export class TimeController {
  /**
   * Is available create order NOW
   * @memberof TimeController
   */
  public readonly availableNow = false;
  /**
   * Is it possible to place an order at all
   *
   * @memberof TimeController
   */
  public readonly availableAtAll: boolean = false;

  /**
   * Is available make preoprder
   * @memberof TimeController
   */
  public readonly preorderIsAvailable: boolean = false;
  /**
   * Months for picker
   * @type {[key: string]: TPreOrderMonth}
   */
  public readonly months: ITimeSlot[] = [];
  /**
   * Dates for picker
   * @type {[key: string]: TPreOrderDay }
   */
  public readonly dates: ITimeSlot[] = [];
  /**
   * Hours for picker
   * @type {[key: string]: ITimeSlotHour }
   */
  public readonly hours: ITimeSlotHour[] = [];
  /**
   * Branch {@link BranchStatus status}
   * @type {TBranchStatus}
   */
  public readonly status: TBranchStatus = {
    type: undefined,
    time: '',
    day: undefined,
  };

  private branch: IBranch | null | undefined;
  private deliveryType: DELIVERY_TYPES | string | undefined;
  private selectedYear: number | undefined;
  private selectedMonth: number | undefined;
  private selectedDate: number | undefined;

  /**
   *
   * Is available create order NOW
   *
   * For local using in TimeController
   * @private
   * @type {boolean}
   * @memberof TimeController
   */
  private isAvailableNow: boolean = false;
  /**
   * Maximum number of days for pre-order
   * @private
   * @type {number}
   * @memberof TimeController
   */
  private limit: number = 0;
  /**
   * Last date before which you can make a preorder
   * @type {*}
   */
  private limit_date: Moment = moment();
  /**
   * Current time
   * @private
   * @type {moment}
   */
  private now: moment.Moment = moment();
  /**
   * Time to prepare making order
   *
   * Depends on {@link deliveryType}
   * @type {IPreparation}
   */
  private preparation: IPreparation = {
    unit: 'm', // m | h | d,
    time: 0, // pickup time
    preparation_time: 0,
    gap: 0,
  };

  /**
   * Neares day to make order
   * @private
   * @memberof TimeController
   */
  private nearestDate: string | undefined;
  /**
   * Slots from start time divided into intervals to end time (last available date)
   * @type {ISlots}
   */
  private slots: ISlots = {};

  /**
   * Object Dates for picker
   * @type {[key: string]: TPreOrderDay }
   */
  private datesObj: { [key: string]: ITimeSlot } = {};
  /**
   * Object Months for picker
   * @type {[key: string]: TPreOrderMonth}
   */
  private monthsObj: { [key: string]: ITimeSlot } = {};

  private shortPrepUnits = ['m'];
  private longPrepUnits = ['d'];

  /**
   * The time that is available after a long preparation
   *
   * {@link now} + long {@link preparation}
   * @type {moment.Moment | null}
   */
  private endOfLongPreparation: moment.Moment | null = null;

  /**
   * Slots by week day index
   * @type {IWeekDaysSlots}
   */
  private slotsByWeekDayIndex: IWeekDaysSlots = {};

  /**
   * Creates an instance of TimeController.
   * @param {({
   *     year?: number;
   *     month?: number;
   *     date?: number;
   *     deliveryType: DELIVERY_TYPES;
   *     branch: IBranch | null | undefined;
   *   })} {
   *     branch,
   *     deliveryType,
   *     year: selectedYear,
   *     month: selectedMonth,
   *     date: selectedDate,
   *   }
   * @memberof TimeController
   */
  constructor({
    branch,
    deliveryType,
    year: selectedYear,
    month: selectedMonth,
    date: selectedDate,
  }: {
    branch?: IBranch | null;
    deliveryType?: DELIVERY_TYPES | string;
    year?: number;
    month?: number;
    date?: number;
  }) {
    this.branch = branch;
    this.deliveryType = deliveryType ?? this.branch?.delivery_type ?? DELIVERY_TYPES.PICKUP;
    this.selectedYear = selectedYear;
    this.selectedMonth = selectedMonth;
    this.selectedDate = selectedDate;

    // If branch busy or without preparation then order is unavailable
    if (this.checkIsBusyForWeek()) {
      // brunch is open even if she is busy
      // set status here because without preorder or when brunch business calculation is not possible
      this.status.type = BranchStatus.Open24H;
      return;
    }
    if (!branch?.preparation?.preorder) {
      return;
    }

    // Set preparation based on delivery_type
    if (this.deliveryType === DELIVERY_TYPES.DELIVERY) {
      this.preparation = branch?.preparation.delivery;
    } else {
      this.preparation = branch?.preparation.pickup;
    }

    this.now = moment();

    this.limit = calculatePreorderLimit(branch?.preparation?.preorder);
    this.limit_date = moment('12:00 AM', 'hh:mm A', 'en').add(
      branch?.preparation?.preorder.period === 'today' ? 1 : this.limit + 1,
      'days'
    );

    // If limit less 0 then order is unavailable
    if (this.limit < 0) return;

    if (!branch?.work_slots?.length) return;

    // If the preparation lasts more than 5 hours, it is a long preparation.
    if (this.preparation?.unit === 'h' && this.preparation.gap > 5) {
      this.longPrepUnits.push('h');
    } else {
      this.shortPrepUnits.push('h');
    }

    this.endOfLongPreparation = this.getEndOfLongPreparation();

    this.slotsByWeekDayIndex = this.getSlotsByWeekDayIndex();

    const daysByLimit = Array.from(Array(branch?.preparation?.preorder.period === 'today' ? 1 : this.limit + 1).keys());

    for (let i = 0; i < daysByLimit.length; i++) {
      const slotMoment = this.now.clone().add(i, 'days');
      /**
       * Day number of the week
       *  @type {*}
       */
      const slotWeekday = slotMoment.weekday();

      /**
       * Slot from {@link slotsByWeekDayIndex}[{@link weekday}]
       *  @type {IWorkSlot[]}
       */
      const slot: IWorkSlot = this.slotsByWeekDayIndex[slotWeekday];

      /**
       * Slots by day with settlement after midnight
       *  @type {DaySlot[]}
       */
      const daySlots: DaySlot[] = this.getDaySlots(i);

      /**
       * Days slots with breaks
       *
       * Array of day slots
       * @private
       * @memberof TimeController
       */
      let slotsWithBreaks: DaySlot[] = this.getSlotsWithBreaks({ daySlots, slot });

      // Check availableNow in branch
      this.isAvailableNow = this.isAvailableNow || this.checkIsAvailableNow({ slotsWithBreaks });

      // If brach not working now not add preparation time
      if (!this.isAvailableNow) {
        this.endOfLongPreparation = null;
      }
      // Set branch status
      if (!this.status.type) this.status = this.getBranchStatus({ slotsWithBreaks, work_slot: slot });

      // Filter busy slots
      slotsWithBreaks = this.filterBusySlots({ slotsWithBreaks });

      const availableSlots = this.getAvailablePickerSlots({
        slotsWithBreaks,
      });

      const { dates, months, _slots } = this.getSlotsForPicker({ availableSlots });

      Object.entries(_slots).forEach(([key, value]: any) => {
        if (this.slots[key]) {
          this.slots[key] = [...this.slots[key], ...value];
        } else {
          this.slots[key] = [...value];
        }
      });
      this.datesObj = { ...this.datesObj, ...dates };
      this.monthsObj = { ...this.monthsObj, ...months };
    }

    this.hours = this.getHoursSlotsForPicker();
    this.dates = Object.values(this.datesObj).sort((prevDay, day) => prevDay.moment.diff(day.moment));
    this.months = Object.values(this.monthsObj);

    this.availableAtAll = Boolean(this.dates.length > 0 && this.hours.length > 0);

    this.availableNow = this.getAvailableNow();

    this.preorderIsAvailable = Boolean(
      this.branch?.preorder && (this.hours.some((hour) => !hour.now) || this.dates.length > 1 || this.months.length > 1)
    );
  }

  /**
   * Get Branch Work Status
   *
   * @param {IBranch} [branch] - Branch
   * @return {*}  {[string, boolean]} - [status, isWorking]
   */
  public getWorkStatus(): [string, boolean] {
    if (!this.branch || !this.branch.work_slots.length) {
      return [localeStore.t('txt_closed'), false];
    }

    const isTimeSimplified = appStore.settings?.simplified_working_hours;

    switch (this.status.type) {
      case BranchStatus.Open24H:
        return [localeStore.t('open_button_txt'), true];
      case BranchStatus.OpenUntilToday:
        return [
          isTimeSimplified
            ? localeStore.t('open_button_txt')
            : `${localeStore.t('open_until')} ${formatTime(this.status.time)}`,
          true,
        ];
      case BranchStatus.BreakUntilToday:
        return [`${localeStore.t('break_until')} ${formatTime(this.status.time)}`, false];
      case BranchStatus.ClosedUntilToday:
        return [
          isTimeSimplified
            ? localeStore.t('txt_closed')
            : `${localeStore.t('closed_until')} ${formatTime(this.status.time)}`,
          false,
        ];
      case BranchStatus.ClosedUntilTomorrow:
        return [
          isTimeSimplified
            ? localeStore.t('txt_closed')
            : `${localeStore.t('closed_until')} ${localeStore.t('txt_tomorrow')} ${formatTime(this.status.time)}`,
          false,
        ];
      case BranchStatus.ClosedUntilWeekday:
        return [
          isTimeSimplified
            ? localeStore.t('txt_closed')
            : `${localeStore.t('closed_until')} ${localeStore.t(`txt_${this.status.day}`)} ${formatTime(
                this.status.time
              )}`,
          false,
        ];
      default: {
        const slotsByWeekDayIndex: { [key: number]: IWorkSlot } = this.branch!.work_slots.reduce(
          (prev, curr) => ({
            ...prev,
            [DAYS_INDEX_IN_WEEK[curr.day]]: {
              ...curr,
            },
          }),
          {}
        );
        const currentWeekday = moment().weekday();

        for (let i = 0; i < 7; i++) {
          const index = (currentWeekday + i + 1) % 7;
          if (slotsByWeekDayIndex[index].start_time) {
            return [
              isTimeSimplified
                ? localeStore.t('txt_closed')
                : `${localeStore.t('closed_until')} ${localeStore.t(
                    `txt_${slotsByWeekDayIndex[index].day}`
                  )} ${formatTime(moment(slotsByWeekDayIndex[index].start_time, 'hh:mm A', 'en'))}`,
              false,
            ];
          }
        }

        return [localeStore.t('txt_closed'), false];
      }
    }
  }

  /**
   * Is 24 hours brunch and it is busy
   *  @type {boolean}
   */
  private checkIsBusyForWeek = () => {
    return this.branch?.is_round_clock && this.branch?.is_busy;
  };

  /**
   * Get the time that is available after a long preparation
   *
   * If it's long preparation - return {@link now} + long {@link preparation}
   * @private
   * @memberof TimeController
   */
  private getEndOfLongPreparation = (): moment.Moment | null => {
    if (!this.now || !this.preparation) return null;
    return this.longPrepUnits.includes(this.preparation?.unit)
      ? this.now.clone().add(this.preparation?.gap, this.preparation?.unit)
      : null;
  };

  /**
   * Get Slots by week day index
   * @param {IBranch} branch
   * @return {IWeekDaysSlots}  {IWeekDaysSlots}
   */
  private getSlotsByWeekDayIndex = (): IWeekDaysSlots => {
    if (!this.branch?.work_slots) return {};
    return this.branch?.work_slots.reduce(
      (prev, curr) => ({
        ...prev,
        [DAYS_INDEX_IN_WEEK[curr.day]]: {
          ...curr,
          weekday: DAYS_INDEX_IN_WEEK[curr.day],
        },
      }),
      {}
    );
  };

  /**
   * Slots by Day
   *
   * For calculate slots crosses overmidnight
   * @param {*} { slotsByWeekDayIndex, index, now }
   * @return {*}
   */
  private getDaySlots = (index: number): DaySlot[] => {
    if (!this.now) return [];

    const slotMoment = this.now?.clone().add(index, 'days');

    const weekday = slotMoment.weekday();
    const slot: IWorkSlot = this.slotsByWeekDayIndex[weekday];

    const start_time = moment(slot.start_time, 'hh:mm A', 'en');
    // Move to the next day if close_time = 12:00 AM
    const close_time =
      slot?.close_time === '12:00 AM'
        ? moment(slot?.close_time, 'hh:mm A', 'en').add(1, 'day')
        : moment(slot?.close_time, 'hh:mm A', 'en');

    const timeIsSwapped = close_time.isSameOrBefore(start_time);
    const daySlots: DaySlot[] = [];

    if (index === 0) {
      // Previos slot. Example now saturday, prevSlot - friday
      const prevSlot = this.slotsByWeekDayIndex[(7 + weekday - 1) % 7];
      // If close_time before start_time. Example: start_time - 8:00PM, close_time - 2:00AM
      const prevSlotTimeIsSwapped = moment(prevSlot.close_time, 'hh:mm A', 'en').isSameOrBefore(
        moment(prevSlot.start_time, 'hh:mm A', 'en')
      );

      // If previous day crosses over midnight
      if (prevSlotTimeIsSwapped && prevSlot.start_time && prevSlot.close_time && prevSlot.close_time !== '12:00 AM') {
        // If slot is empty, slot = prevSlot
        if (!slot.start_time || !slot.close_time) {
          slot.start_time = '12:00 AM';
          slot.close_time = prevSlot.close_time;
        } else {
          daySlots.push(
            new DaySlot({
              from: '12:00 AM',
              to: prevSlot.close_time,
              isFullDay: slot.is_full_day,
              crossesOverMidnight: false,
              isToday: true,
              isTomorrow: false,
              moment: slotMoment.clone(),
            })
          );
        }
      }
    }

    if (timeIsSwapped) {
      const beforeMidnightSlot = new DaySlot({
        from: slot.start_time,
        to: '11:59:59 PM',
        isFullDay: slot.is_full_day,
        crossesOverMidnight: false,
        moment: slotMoment.clone(),
        isToday: index === 0,
        isTomorrow: index === 1,
      });

      const afterMidnightSlot = new DaySlot({
        from: '12:00 AM',
        to: slot.close_time,
        isFullDay: slot.is_full_day,
        crossesOverMidnight: true,
        moment: slotMoment.clone().add(1, 'day'),
        isToday: index === 0,
        isTomorrow: index === 0,
      });

      daySlots.push(beforeMidnightSlot);
      daySlots.push(afterMidnightSlot);
    } else {
      const prevWeekday = (7 + weekday - 1) % 7;
      const prevSlotCloseTime = this.slotsByWeekDayIndex[prevWeekday].close_time;
      const crossesOverMidnight = slot.start_time === '12:00 AM' && prevSlotCloseTime === '11:59 PM';

      daySlots.push(
        new DaySlot({
          from: slot.start_time,
          to: slot.close_time,
          isFullDay: slot.is_full_day,
          crossesOverMidnight,
          moment: crossesOverMidnight ? slotMoment.clone().add(1, 'day') : slotMoment.clone(),
          isToday: index === 0,
          isTomorrow: index === 1,
        })
      );
    }
    // Sort the slots because they might go in the wrong order
    return daySlots.sort((slot1, slot2) => slot1.toMoment.diff(slot2.toMoment, 'minutes'));
  };

  /**
   * Remove busy slots from daySlots
   *
   * @private
   * @param {{ daySlots: DaySlot[] }} { daySlots }
   * @memberof TimeController
   */
  private filterBusySlots = ({ slotsWithBreaks }: { slotsWithBreaks: DaySlot[] }) => {
    const result = slotsWithBreaks.filter((slot) => this.checkSlotAfterBusyExpiration(slot));
    return result;
  };

  private checkSlotAfterBusyExpiration = (slot: DaySlot) => {
    return !(
      this.branch?.is_busy &&
      this.branch?.busy_expire_in &&
      slot.fromMoment.isSameOrBefore(moment(this.branch?.busy_expire_in)) &&
      slot.toMoment.isSameOrBefore(moment(this.branch?.busy_expire_in))
    );
  };

  /**
   * Get slots with breaks
   *
   * Separate slots with breaks
   * @private
   * @param {*} { daySlots, slot }
   * @memberof TimeController
   */
  private getSlotsWithBreaks = ({ daySlots, slot }: { daySlots: DaySlot[]; slot: IWorkSlot }): DaySlot[] => {
    const result = daySlots.flatMap((daySlot) => {
      let daySlotWithBreaks: DaySlot[] = [daySlot];
      // separate slots with breaks
      if (slot.breaks) {
        for (const _break of slot.breaks) {
          const { start_time: breakStart, end_time: breakEnd } = _break;

          daySlotWithBreaks = daySlotWithBreaks.flatMap((_slot) => {
            const { from, to } = _slot;

            const slotStart = moment(from, 'hh:mm A', 'en');
            const slotEnd = to === '12:00 AM' ? moment(to, 'hh:mm A', 'en').add(1, 'day') : moment(to, 'hh:mm A', 'en');

            /**
             * Is slot include now
             * @type {boolean}
             */
            const isIncludeNow = this.now.isSameOrAfter(slotStart) && this.now.isSameOrBefore(slotEnd);

            /**
             * If slot have correct break, not busy, not now and break more 30 minutes, then slot is splitted
             *
             * @type {boolean}
             */
            const isSplittedSlot =
              moment(breakStart, 'hh:mm A', 'en').isSameOrAfter(slotStart) &&
              moment(breakEnd, 'hh:mm A', 'en').isSameOrBefore(slotEnd) &&
              (!isIncludeNow || !this.branch?.is_busy);

            if (isSplittedSlot) {
              return [
                _slot.cloneWithParams({
                  isAfterBreak: false,
                  isBeforeBreak: true,
                  to: breakStart,
                }),
                _slot.cloneWithParams({
                  from: breakEnd,
                  isAfterBreak: true,
                }),
              ];
            }
            return [_slot.cloneWithParams({ isAfterBreak: false })];
          });
        }
      }
      return daySlotWithBreaks;
    });

    return result;
  };

  /**
   * Checking if it is available now by analyzing slots and now
   * For {@link isAvailableNow}
   * @export
   * @param {*} { slotsWithBreaks }
   * @return {*}
   */
  private checkIsAvailableNow({ slotsWithBreaks }: { slotsWithBreaks: DaySlot[] }) {
    if (!this.now) return false;

    let isAvailableNow = false;

    slotsWithBreaks.flatMap((slotWithBreaks) => {
      const from = slotWithBreaks.fromMoment;
      const to = slotWithBreaks.toMoment;
      const nowTo = slotWithBreaks.to === '11:59 PM' ? to.clone().add(1, 'm') : to;

      if (this.now?.isAfter(from) && this.now.isSameOrBefore(nowTo)) {
        isAvailableNow = true;
      }
    });

    return isAvailableNow;
  }

  /**
   * Get {@link availableNow} from {@link slots}
   *
   * Check isNow in slots and get availableNow
   *
   * @private
   * @return {*}
   * @memberof TimeController
   */
  private getAvailableNow(): any {
    let availableNow = false;

    const hoursDate =
      this.selectedYear &&
      typeof this.selectedMonth === 'number' &&
      this.selectedDate &&
      this.slots[`${this.selectedYear}-${this.selectedMonth}-${this.selectedDate}`]
        ? `${this.selectedYear}-${this.selectedMonth}-${this.selectedDate}`
        : this.nearestDate;

    this.slots[hoursDate || Object.keys(this.slots)[0]]?.flatMap((slot) => {
      if (slot.isNow) {
        /**  Check if is now set {@link availableNow} true */
        availableNow = true;
      }
    });

    return availableNow;
  }

  /**
   * Get branch status
   *
   * @param {*} slotsWithBreaks - all slots with breaks
   * @param {*} slot - analyzed slot
   * @return {*}  {TBranchStatus}
   */
  private getBranchStatus = ({
    slotsWithBreaks,
    work_slot,
  }: {
    slotsWithBreaks: DaySlot[];
    work_slot: IWorkSlot;
  }): TBranchStatus => {
    const status: TBranchStatus = {
      type: undefined,
      time: '',
      day: undefined,
    };

    let prevSlot: DaySlot | undefined;

    slotsWithBreaks.flatMap((slotWithBreaks) => {
      const from = slotWithBreaks.fromMoment;
      const to = slotWithBreaks.toMoment;

      // OPEN 24 HOURS
      if (!status.type && this.now.isSameOrBefore(to)) {
        if ((this.now.isSameOrAfter(from) && slotWithBreaks.isFullDay) || this.branch?.is_round_clock) {
          status.type = BranchStatus.Open24H;
        }
        // BEFORE BREAK PREV SLOT
        // If time between two slots
        else if (
          prevSlot &&
          prevSlot.isToday &&
          prevSlot.isBeforeBreak &&
          slotWithBreaks.isToday &&
          !(this.now.isSameOrAfter(from) && this.now.isBefore(to))
        ) {
          status.type = BranchStatus.BreakUntilToday;
          status.time = slotWithBreaks.from;
        }
        // BEFORE BREAK
        else if (
          slotWithBreaks.isToday &&
          !(this.now.isSameOrAfter(from) && this.now.isBefore(to)) &&
          slotWithBreaks.isAfterBreak
        ) {
          status.type = BranchStatus.BreakUntilToday;
          status.time = slotWithBreaks.from;
        }
        // OPEN UNTIL TODAY
        else if (slotWithBreaks.isToday && this.now.isSameOrAfter(from) && this.now.isBefore(to)) {
          status.type = BranchStatus.OpenUntilToday;
          status.time = work_slot.close_time;
        }

        // CLOSE UNTIL TODAY
        else if (slotWithBreaks.isToday && !(this.now.isSameOrAfter(from) && this.now.isBefore(to))) {
          status.type = BranchStatus.ClosedUntilToday;
          status.time = slotWithBreaks.from;
        }
        // CLOSED UNTIL TOMORROW
        else if (slotWithBreaks.isTomorrow) {
          status.type = BranchStatus.ClosedUntilTomorrow;
          status.time = slotWithBreaks.from;
        }
        // CLOSED UNTIL WEEKDAY
        else if (!slotWithBreaks.isToday) {
          status.type = BranchStatus.ClosedUntilWeekday;
          status.day = this.now.clone().locale('en').weekday(slotWithBreaks.weekday).format('dddd').toLowerCase();
          status.time = slotWithBreaks.from;
        }
      }
      prevSlot = slotWithBreaks;
    });
    return status;
  };

  /**
   * Get Available slots
   *
   * Calculation with preorder and preparation
   *
   * @param {*} { slotsWithBreaks }
   * @return {*}
   */
  private getAvailablePickerSlots = ({ slotsWithBreaks }: { slotsWithBreaks: DaySlot[] }) => {
    const availableSlots = slotsWithBreaks.flatMap((slotWithBreaks) => {
      let isNow = false;

      /**
       * Start time from slot
       *
       * For comparing with {@link from}
       * @type {*}
       */
      const start_time = slotWithBreaks.fromMoment;

      const from = slotWithBreaks.fromMoment;
      const to = slotWithBreaks.toMoment;

      const nowTo = slotWithBreaks.to === '11:59 PM' ? to.clone().add(1, 'm') : to;

      // Set to 12:00 if it's after limit_date
      if (to.isAfter(this.limit_date)) {
        to.subtract(to.diff(this.limit_date, 'minutes'), 'minutes');
      }

      if (slotWithBreaks.isToday && this.now.isAfter(from) && this.now.isSameOrBefore(nowTo) && !this.branch?.is_busy) {
        // If slot includes "now"
        from.add(this.now.diff(from, 'm'), 'm');
        if (this.shortPrepUnits.includes(this.preparation?.unit)) {
          // Example: now is 11:07 + preparation 30m = 11:37
          from.add(this.preparation.gap, this.preparation.unit);
        }
        isNow = true;
        // Preparation is too long (few days, 8h, etc.) and between working hours
      } else if (!slotWithBreaks.isToday && this.endOfLongPreparation?.isBefore(to)) {
        if (this.endOfLongPreparation.isSameOrAfter(from) && this.endOfLongPreparation.isBefore(to)) {
          from.add(this.endOfLongPreparation.diff(from, 'm'), 'm');
        }
        this.endOfLongPreparation = null;
      }

      // Is need calculate first time after now like (CURRENT_TIME + GAP + PREORDER_INTERVAL) ≈ stands for rounding to the nearest interval time
      let moveToNearest = false;

      if (slotWithBreaks.isTomorrow && this.shortPrepUnits.includes(this.preparation.unit)) {
        const prevSlot = this.slotsByWeekDayIndex[(7 + slotWithBreaks.weekday - 1) % 7];
        const prevSlotTimeIsSwapped = moment(prevSlot.close_time, 'hh:mm A', 'en').isSameOrBefore(
          moment(prevSlot.start_time, 'hh:mm A', 'en')
        );

        const nearestFromNow = this.now
          .clone()
          .add(this.branch?.preparation?.preorder.interval, 'm')
          .add(this.preparation.gap, this.preparation.unit);

        // Example: now is 23:45 + interval is 15m + gap is  5m ≈ 00:15
        if (prevSlotTimeIsSwapped && slotWithBreaks.from === '12:00 AM' && nearestFromNow.isSameOrAfter(from)) {
          moveToNearest = true;
        }
      }

      if (this.branch?.preparation && !isNow && !slotWithBreaks.crossesOverMidnight) {
        from.add(this.branch.preparation.preorder.interval, 'm');
      }

      // Example: now is 12:19 + preparation is 20m + interval is 15m = 12:44
      if (
        !start_time.clone().add(this.branch?.preparation?.preorder.interval, 'm').isSame(from) &&
        this.now.isAfter(start_time)
      ) {
        moveToNearest = true;
      }

      // Next time slot from now
      // (CURRENT_TIME + GAP + PREORDER_INTERVAL) ≈ stands for rounding to the nearest interval time
      if (this.branch?.preparation) {
        if (moveToNearest) {
          const nearestFromNow = this.now
            .clone()
            .add(this.branch.preparation.preorder.interval, 'm')
            .add(this.preparation.gap, this.preparation.unit);
          // How many minutes do you need to add to get the nearest slot
          // Example:15-interval, 12:51-nearestFromNow, 10:07-start_time
          // 15 - 164 % 15 = 2
          const minutesToNearSlot =
            this.branch.preparation.preorder.interval -
            (nearestFromNow.diff(start_time, 'm') % this.branch.preparation.preorder.interval);

          const nearestSlot = start_time.clone().add(nearestFromNow.diff(start_time, 'm') + minutesToNearSlot, 'm');

          from.add(nearestSlot.diff(from, 'm'), 'm');
        }
      }

      const result: any[] = [];

      if (isNow) {
        result.push({
          ...slotWithBreaks,
          from: from.isBefore(to) ? from : null,
          to: from.isBefore(to) ? to : null,
          isToday: slotWithBreaks.isToday,
          isTomorrow: slotWithBreaks.isTomorrow,
          isNow,
        });
      }

      if (
        !isNow &&
        !this.endOfLongPreparation &&
        from.isBefore(to) &&
        (!slotWithBreaks.isToday || (slotWithBreaks.isToday && this.now.isBefore(from)))
      ) {
        result.push({
          ...slotWithBreaks,
          from,
          to,
          isToday: slotWithBreaks.isToday,
          isTomorrow: slotWithBreaks.isTomorrow,
          isNow,
        });
      }
      return result;
    });
    return availableSlots;
  };

  private getSlotsForPicker = ({
    availableSlots,
  }: {
    availableSlots: ISlot[];
  }): {
    dates: { [key: string]: ITimeSlot };
    months: { [key: string]: ITimeSlot };
    _slots: ISlots;
  } => {
    const slots: ISlots = {};
    const dates: { [key: string]: ITimeSlot } = {};
    const months: { [key: string]: ITimeSlot } = {};

    availableSlots.forEach((daySlot: ISlot) => {
      if (slots[`${daySlot.year}-${daySlot.month}-${daySlot.date}`]) {
        slots[`${daySlot.year}-${daySlot.month}-${daySlot.date}`].push(daySlot);
      } else {
        slots[`${daySlot.year}-${daySlot.month}-${daySlot.date}`] = [daySlot];
      }
      // Dates
      if (!dates[`${daySlot.year}-${daySlot.month}-${daySlot.date}`]) {
        // Nearest Date
        if (!this.nearestDate) {
          this.nearestDate = `${daySlot.year}-${daySlot.month}-${daySlot.date}`;
        }
        const dayMoment = this.now.clone().year(daySlot.year).month(daySlot.month).date(daySlot.date);

        dates[`${daySlot.year}-${daySlot.month}-${daySlot.date}`] = {
          moment: dayMoment,
          index: daySlot.date,
          label: getDayLabel(dayMoment, this.limit < 7),
        };
      }

      // Don't show months if limit less 7
      if (this.limit < 7) return;

      // Months
      if (!months[`${daySlot.year}-${daySlot.month}`]) {
        const monthMoment = this.now.clone().year(daySlot.year).month(daySlot.month);

        months[`${daySlot.year}-${daySlot.month}`] = {
          moment: monthMoment,
          index: daySlot.month,
          label: monthMoment.format('MMMM YYYY'),
        };
      }
    });
    return { dates, months, _slots: slots };
  };

  /**
   * Get hours and minutes and set {@link availableNow}s
   * @private
   * @memberof TimeController
   */
  private getHoursSlotsForPicker = (): ITimeSlotHour[] => {
    const hoursDate =
      this.selectedYear &&
      isNumber(this.selectedMonth) &&
      this.selectedDate &&
      this.slots[`${this.selectedYear}-${this.selectedMonth}-${this.selectedDate}`]
        ? `${this.selectedYear}-${this.selectedMonth}-${this.selectedDate}`
        : this.nearestDate;

    const _hours = this.slots[hoursDate || Object.keys(this.slots)[0]]?.reduce((hours: any, slot: ISlot) => {
      // Add Now
      if (slot.isNow) {
        hours[0] = {
          index: 0,
          hour: undefined,
          now: true,
          moment: this.now.clone(),
          label: localeStore.t('txt_asap'),
          minutes: {},
        };
      }

      // If preorder disabled, only one slot available
      if (!this.branch?.preorder) {
        return hours;
      }

      if (slot.from && slot.to && this.branch?.preparation) {
        /**
         * Time of slot (HOUR and MINUTES)
         *
         * Increases by interval every tick of loop
         * @type {*}
         */
        const time = slot.from.clone();
        const end = slot.to.clone();

        while (time.isSameOrBefore(end) && time.date() === slot.from.date()) {
          /**
           * Key of {@link hours} property
           * If slot.crossesOverMidnight, plus 24 for displaying slots after overMidnight
           */
          const index = time.hour() + 1;
          const minute_index = time.minute();
          // If HOURS is exist, record MINUTES in this HOUR
          if (hours?.[index]) {
            hours[index].minutes[minute_index] = {
              index: time.minute(),
              moment: time.clone(),
              label: time.format('mm'),
            };
          } else {
            // Add new HOUR and write MINUTES in this
            hours[index] = {
              index: time.hour(),
              hour: time.hour(),
              now: false,
              moment: time.clone(),
              label: isRTL ? `${time.format('hh')} ${localeStore.t(time.format('A'))}` : time.format('hh A'),
              minutes: {
                [minute_index]: {
                  index: time.minutes(),
                  moment: time.clone(),
                  label: time.format('mm'),
                },
              },
            };
          }
          // Increases time by interval
          time.add(this.branch?.preparation.preorder.interval || 0, 'm');
        }
      }

      return hours;
    }, {});

    return _hours
      ? Object.values(_hours).map((item: any) => {
          return { ...item, minutes: Object.values(item.minutes) };
        })
      : [];
  };
}
