import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import Icon from '../Icon/Icon';
import './style.scss';
interface Props {
  onPress?: () => void;
}
export default class BackIcon extends Component<Props> {
  render() {
    return (
      <div className="back-wrapper d-flex flex-row">
        <div className="back text-pointer">
          <Icon name={ICONS.ARROW_LEFT} size={20} className={'back-icon color-primary'} />
        </div>
        {this.props.children}
      </div>
    );
  }
}
