import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import './style.scss';

export default class NotesSkeleton extends Component {
  render() {
    return (
      <div className="notes">
        <Skeleton width={50} className="notes-title" />
        <Skeleton className="notes-textarea" />
      </div>
    );
  }
}
