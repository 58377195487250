import { DESTINATION_LOGS, EVENTS_MAP, MOENGAGE_EVENTS, TEvents } from 'src/constants/events';
import { firebase } from './firebase';
import { gtm } from './gtm';
import moengage from './moengage';

// type TEvents = typeof EVENTS;
class Logger {
  logEvent = (event: TEvents[keyof TEvents], data?: any, destination?: DESTINATION_LOGS) => {
    // Log to firebase
    if (EVENTS_MAP[event]) {
      firebase.logEvent(event, data);
    }
    // Log to Google Tag Manager
    if (EVENTS_MAP[event]) {
      gtm.logEvent(event, data);
    }
    if (EVENTS_MAP[event] && destination === DESTINATION_LOGS.MOENGAGE) {
      moengage.sendEvents(event, data);
    }
    return null;
  };

  log = (message: string, error?: any) => {
    console.info(message, error);
  };
}

const logger = new Logger();

export default logger;
