import add from 'date-fns/add';
import isBefore from 'date-fns/isBefore';
import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { requests } from 'src/requests';
import { firebase } from 'src/services/firebase';
import { ISettings } from 'src/types/app';
import { logError } from 'src/utils/helpers';
import { injectStyles } from 'src/utils/injector';
import { branchStore } from '../barnchStore';
import { cartStore } from '../cartStore';
import { checkoutStore } from '../checkoutStore';
import { preorderStore } from '../preorderStore';
import { localeStore } from '../localesStore';

export class AppStore {
  // tslint:disable-next-line:no-empty
  public logEvent: (event: string, params: object) => void = () => {};
  @observable public loading: boolean = false;
  @observable public modals: { preorder: boolean } = {
    preorder: false,
  };

  @observable public smartBanner: boolean = true;
  @persist @observable public lastUsage: number | null = Date.now();
  @persist @observable public mode: string | null = null;

  @persist @observable public cookies_accepted_at: number | null = null;

  @persist @observable public timestamp: number | null = window.SETTINGS ? Date.now() : null;
  @persist('object') @observable public settings?: ISettings = window.SETTINGS;
  timer: null | NodeJS.Timeout = null;

  // actions
  @action smsPageSession = () => {
    this.timer = setTimeout(() => {
      this.clearSmsPageSession();
    }, 30 * 60 * 1000);
  };

  @action clearSmsPageSession = () => {
    if (this.timer) clearTimeout(this.timer);
    cartStore.clear();
    cartStore.isSMSPage(false);
    cartStore.SMSType(null);
    checkoutStore.setReorderStore(null);
  };

  @action closeSmartBanner = () => {
    this.smartBanner = false;
  };
  @action handleModal = (modal: 'preorder', isOpen: boolean) => {
    this.modals[modal] = isOpen;
  };

  @action acceptCookies = () => {
    this.cookies_accepted_at = Date.now();
  };

  @action public fetchSettings = async () => {
    try {
      this.loading = true;
      const data = await requests.fetchAppSettiings();
      this.settings = data;

      localeStore.setURLs(this.settings?.translations_file_url);
      localeStore.afterGetURLs();

      this.timestamp = Date.now();
      injectStyles(this.settings);
      this.loading = false;
      this.setFirebaseSettings();
    } catch (error: any) {
      this.loading = false;
      logError(error);
    }
  };

  checkLastUsage = () => {
    if (this.lastUsage) {
      if (isBefore(add(new Date(this.lastUsage), { hours: 2 }), new Date())) {
        preorderStore.resetTime();
        this.mode = null;
        branchStore.clearOrderType();
        cartStore.clear();
      }
    }

    this.lastUsage = Date.now();
    setTimeout(this.checkLastUsage, 180000);
  };

  fetchMode = async () => {
    const params = new URLSearchParams(window.location.search);
    this.mode = this.mode || params.get('mode');
  };

  afterHydration() {
    if (!this.settings || process.env.NODE_ENV === 'development') {
      this.fetchSettings();
    } else if (window.SETTINGS) {
      this.settings = window.SETTINGS;
      this.setFirebaseSettings();
      localeStore.setURLs(this.settings?.translations_file_url);
      localeStore.afterGetURLs();
    }

    this.checkLastUsage();
    this.fetchMode();

    if (window.location.pathname !== '/sms-payment' && cartStore.isSMS) appStore.clearSmsPageSession();

    this.timestamp = Date.now();
    injectStyles(this.settings);
  }

  setFirebaseSettings() {
    firebase.setConfig(this.settings!.firebase);
  }
}

const appStore = new AppStore();

export { appStore };
