import React, { FC, useMemo } from 'react';
import { userStore } from '../../../../../../../mobx/userStore';
import { addressStore } from '../../../../../../../mobx/barnchStore/address';
import AddressFormHeader from './components/AddressFormHeader/AddressFormHeader';
import AddressFormMap from './components/AddressFormMap/AddressFormMap';
import AddressFormHandler from './components/AddressFormHandler/AddressFormHandler';
import branchesScreenStore from '../../../../branchesScreenStore';
import { VIEW_MODE } from '../../../../../../../types/address';
import { observer } from 'mobx-react';
import '../../style.scss';
import { IBranch } from '../../../../../../../types/branch';
interface IAddressForm {
  branch: IBranch | null;
}
const AddressForm: FC<IAddressForm> = observer((props: IAddressForm): JSX.Element => {
  const fullForm = useMemo(() => {
    if (!userStore.isLogin) return false;
    return addressStore.list?.length;
  }, [addressStore.list]);

  return (
    <div
      className={`address-details-wrap ${!fullForm ? 'mini' : ''} ${
        branchesScreenStore.viewMode === VIEW_MODE.EDIT ? 'edit' : ''
      } animation slide_in_right`}
    >
      <AddressFormHeader />
      <div className={'edit-container'}>
        <AddressFormMap />
        <AddressFormHandler branch={props.branch} />
      </div>
    </div>
  );
});

export default AddressForm;
