import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import LoadingCircle from '../LoadingCircle/LoadingCircle';
import './style.scss';

export const CheckoutTotal = observer(() => {
  /**
   * Chechout total
   *  @type {number | undefined}
   */
  const total = useMemo(
    () =>
      cartStore.calculator?.promocode
        ? cartStore.calculator?.discounted_total ?? cartStore.calculator?.total
        : cartStore.calculator?.total,
    [cartStore.calculator]
  );

  return (
    <div className="d-flex flex-column justify-content-center mr-3">
      <LoadingCircle size={27}>
        <div className="d-flex flex-row align-items-center  justify-content-center text-uppercase text-nowrap">
          <p id="checkout_total" className="color-dark family-semibold text-size-20">
            {formatPrice(total || 0)}
          </p>
        </div>
        {branchStore.vat_included ? (
          <span className="family-regular text-size-10 text-nowrap text-center">{localeStore.t('VAT inclusive')}</span>
        ) : null}
      </LoadingCircle>
    </div>
  );
});
