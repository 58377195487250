import React from 'react';
import Skeleton from 'react-loading-skeleton';

const BranchDeliverySkeleton: React.FC = () => {
  return (
    <>
      <div className="d-flex flex-row align-items-center  mb-2">
        <Skeleton className="branches-time mr-3" width={100} />
        <Skeleton className="branches-preorder" width={130} />
      </div>

      <div className="d-flex flex-row mt-2 row">
        <div className="d-flex flex-column col col-4">
          <Skeleton className="branches-head" width={70} />
          <Skeleton className="branches-price" width={80} />
        </div>
        <div className="d-flex flex-column  col col-4">
          <Skeleton className="branches-head" width={70} />
          <Skeleton className="branches-price" width={80} />
        </div>
        <div className="d-flex flex-column col col-4">
          <Skeleton className="branches-head" width={70} />
          <Skeleton className="branches-price" width={80} />
        </div>
      </div>
    </>
  );
};

export default BranchDeliverySkeleton;
