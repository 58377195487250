import { IAddressResponse } from '../../../../../types/address';
import React, { FC, memo, useCallback, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { localeStore } from '../../../../../mobx/localesStore';
import { Card, CardKey, CardRow } from '../../../../components/CardComponents/Card';
import { getDeliveryAddressName } from '../../../../../utils/helpers';
import './style.scss';
import { IMAGES } from '../../../../../constants/images';
import { SCREENS } from '../../../../../constants/screens';
import { DELIVERY_TYPES } from '../../../../../constants/deliveryTypes';

interface IAddressProps {
  address: IAddressResponse;
}
const Address: FC<IAddressProps> = (props: IAddressProps, index: number): JSX.Element => {
  const addressName = useMemo(() => {
    if (props.address) return getDeliveryAddressName(props?.address, false);
    return <Skeleton width={120} />;
  }, [props.address]);

  const deliveryAddress = useMemo(() => {
    if (props.address) return props.address.address || ' ';
    return <Skeleton width={150} />;
  }, []);

  const openAddress = useCallback(() => {
    // branchesScreenStore.onAddressEdit(props.address.id);
    // if (branchesScreenStore.address) {
    //   branchStore.setCoordinate({
    //     latitude: branchesScreenStore.address?.latitude,
    //     longitude: branchesScreenStore.address?.longitude,
    //   });
    // }
  }, []);
  return (
    <Link
      to={{
        pathname: SCREENS.BRANCHES(DELIVERY_TYPES.DELIVERY),
        state: { address_id: props?.address?.id },
        search: `?language=${localeStore.language}&edit=true`,
      }}
      onClick={openAddress}
    >
      <Card className={`addressCart px-3 py-3`}>
        {addressName ? <CardKey className={'addressCart_name family-semibold'}>{addressName}</CardKey> : null}
        <CardRow className={'addressCart_address color-dark'}>{deliveryAddress}</CardRow>
      </Card>
    </Link>
  );
};
export default memo(Address);
