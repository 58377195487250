import React from 'react';
import { ICONS } from 'src/constants/icons';
import ButtonComponent from '../Controls/Button/Button';
import Icon from '../Controls/Icon/Icon';

interface IOption {
  text: string;
  className?: string;
  textClassName?: string;
  onPress?: () => void;
}

interface Props {
  title?: string;
  message?: string;
  closeModal?: () => void;
  options?: IOption[];
  minWidth?: number;
  maxWidth?: number;
  wrapperClassName?: string;
}

export default class ModalMessage extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={`d-flex flex-column full-width ${this.props.wrapperClassName}`}
        style={{ minWidth: this.props.minWidth || 'initial', maxWidth: this.props.maxWidth || 'initial' }}
      >
        <div className="px-4 d-flex flex-column modal-message" style={{ paddingTop: 16 }}>
          {this.props.title ? <p className="info-popup-title">{this.props.title}</p> : null}
          {this.props.message ? <p className="info-popup-message mb-3">{this.props.message}</p> : null}
        </div>
        <div className="info-popup-buttons d-flex flex-row full-width justify-content-between">
          {this.props.options?.map((option, idx) => (
            <>
              {idx ? <span className="ml-2"></span> : null}
              <ButtonComponent
                key={idx}
                className={`${option.className}`}
                style={{
                  borderRadius: 14,
                  minHeight: 52,
                  width: '100%',
                }}
                onClick={option.onPress ? option.onPress : this.props.closeModal}
              >
                <span className={option.textClassName}>{option.text}</span>
              </ButtonComponent>
            </>
          ))}
        </div>

        <Icon
          onPress={this.props.closeModal}
          className="info-popup-close"
          name={ICONS.CLOSE}
          size={14}
          color={'#B6B6B6'}
        />
      </div>
    );
  }
}
