import { observer } from 'mobx-react';
import * as React from 'react';
import { ICONS } from 'src/constants/icons';
import { WINDOW_SIZES } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import HamburgerLoginItem from '../HamburgerLoginItem/HamburgerMenuItem';
import HamburgerLogoutItem from '../HamburgerLogoutItem/HamburgerLogoutItem';
import HamburgerMenuItem from '../HamburgerMenuItem/HamburgerMenuItem';
import HamburgerMobileHead from '../HamburgerMobileHead/HamburgerMobileHead';
import HamburgerWaitListItem from '../HamburgerWaitListLoginItem/HamburgerWaitListLoginItem';

export interface IAppProps {
  closeModal: () => void;
}

export interface IRoute {
  icon?: string;
  text?: string | React.ReactElement;
  to: string;
  target?: string;
  hide?: boolean;
  title?: string;
}
interface IRouter {
  default: IRoute[];
  authorized: IRoute[];
}

export interface HamburgerState {
  ml: boolean; // ml - login form
  mw: boolean; // mw - WainList form
}

@observer
export default class MenuLiist extends React.Component<any, HamburgerState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      ml: false,
      mw: false,
    };
    this.updateState = this.updateState.bind(this);
  }

  updateState = (newState: object): void => {
    this.setState(newState);
  };
  renderNavLink = (route: IRoute) => {
    if (route.hide) return null;
    return <HamburgerMenuItem {...route} />;
  };

  public render() {
    const router: IRouter = {
      default: [
        { icon: ICONS.MENU, text: localeStore.t('txt_menu'), to: SCREENS.MENU(), target: '_self' },
        { icon: ICONS.CALL, text: localeStore.t('menu_contact_us'), to: SCREENS.CONTACTS(), target: '_self' },
      ],
      authorized: [
        { icon: ICONS.MENU, text: localeStore.t('txt_my_order'), to: SCREENS.ORDERS(), target: '_self' },
        { icon: ICONS.MARKER, text: localeStore.t('my_addresses'), to: SCREENS.MY_ADDRESSES(), target: '_self' },
        {
          icon: ICONS.CALENDAR,
          text: localeStore.t('txt_myreservations'),
          to: SCREENS.RESERVATIONS(),
          target: '_self',
          hide: !appStore.settings?.is_waitlist || appStore.mode === 'e-menu',
        },
        { icon: ICONS.PROFILE, text: localeStore.t('txt_profile'), to: SCREENS.USER(), target: '_self' },
        {
          hide: !appStore.settings?.is_wallet,
          icon: ICONS.WALLET,
          text: (
            <div className="d-flex flex-row flex-grow-1 mr-3 justify-content-between full-width align-items-center">
              <p>{localeStore.t('wallet')}</p>{' '}
              <span className="text-size-12 family-bold color-primary">{userStore.user.wallet?.balance}</span>
            </div>
          ),
          to: SCREENS.WALLET(),
          target: '_self',
          title: localeStore.t('wallet'),
        },
        {
          icon: ICONS.LIST,
          text: localeStore.t('my_subscription'),
          to: SCREENS.SUBSCRIPTIONS(),
          target: '_blank',
          hide: !appStore.settings?.is_subscriptions_system || appStore.mode === 'e-menu',
        },
        { icon: ICONS.CALL, text: localeStore.t('menu_contact_us'), to: SCREENS.CONTACTS(), target: '_self' },
      ],
    };
    const routes = userStore.isLogin ? router.authorized : router.default;
    return (
      <nav className="hamburger-menu">
        <ResponsiveContent max={WINDOW_SIZES.md}>
          <HamburgerMobileHead />
        </ResponsiveContent>
        {routes.map(this.renderNavLink)}

        {userStore.isLogin ? (
          <HamburgerLogoutItem />
        ) : (
          <HamburgerLoginItem hamburgerState={this.state} updateProps={this.updateState} />
        )}

        {(appStore.settings?.is_subscriptions_system || appStore.settings?.is_waitlist) &&
        !(appStore.mode === 'e-menu') ? (
          <>
            <p className="hamburger-menu-head th-background-secondary ml-3">{localeStore.t('other')}</p>
            {/* <HamburgerMenuItem text={localeStore.t('food_subscription')} to={SCREENS.SUBSCRIBTIONS()} /> */}
            {appStore.settings?.is_waitlist ? (
              userStore.isLogin ? (
                <HamburgerMenuItem text={localeStore.t('txt_waitlist')} to={SCREENS.CREATE_RESERVATION()} />
              ) : (
                <HamburgerWaitListItem hamburgerState={this.state} updateProps={this.updateState} />
              )
            ) : null}

            {appStore.settings?.is_subscriptions_system ? (
              <HamburgerMenuItem
                target={'_blank'}
                text={localeStore.t('food_subscription')}
                to={SCREENS.CREATE_SUBSCRIPTION()}
              />
            ) : null}
          </>
        ) : null}
      </nav>
    );
  }
}
