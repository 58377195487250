import React, { ChangeEvent, Component } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProduct } from 'src/types/products';
import NotesSkeleton from './NotesSkeleton';
import './style.scss';

interface Props {
  notes: IProduct['notes'];
  loading: boolean;
  autosize?: boolean;
  disabled?: boolean;
  onChange: (notes: string) => void;
  id?: string;
}
interface State {
  error: boolean;
}

export default class Notes extends Component<Props, State> {
  textarea: HTMLTextAreaElement | null = null;
  state = {
    error: false,
  };

  /**
   * For set true height if autosize turned on
   *
   * @memberof Notes
   */
  componentDidMount = () => {
    if (this.props.autosize && this.textarea) {
      this.textarea.style.height = '0px';
    }
  };

  componentDidUpdate = () => {
    // Set auto height if autosize is true
    if (this.props.autosize && this.textarea) {
      this.textarea.style.height = '0px';
      const scrollHeight = this.textarea.scrollHeight;
      this.textarea.style.height = scrollHeight + 'px';
    }
  };

  onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { notes, loading, disabled } = this.props;
    if (loading) {
      return <NotesSkeleton />;
    }
    return (
      <div className="notes full-width">
        <h3 className="notes-title">{localeStore.t('Notes')}</h3>
        {!disabled ? (
          <textarea
            id={this.props.id}
            ref={(ref) => (this.textarea = ref)}
            onChange={this.onChange}
            className="notes-textarea"
            placeholder={localeStore.t('write_a_note')}
            value={notes}
          />
        ) : (
          <p className="text-size-16 ">{notes}</p>
        )}
      </div>
    );
  }
}
