import { IProduct, PRODUCT_TYPE } from 'src/types/products';
import { getComboItemUnavailableMessage } from '../getComboItemUnavailableMessage/getComboItemUnavailableMessage';

export const isInvalidMealCombo = (product: IProduct | undefined): boolean => {
  const isSomeSelectedComboItemUnavailable = (): boolean => {
    if (!product?.items?.length) return false;

    return product?.items?.some((item) => {
      return item.size_id === product?.size?.id && item.is_selected && getComboItemUnavailableMessage(item, true);
    });
  };

  switch (true) {
    case !product || product.type !== PRODUCT_TYPE.MEAL_COMBO:
      return false;
    case isSomeSelectedComboItemUnavailable():
      return true;
    default:
      return false;
  }
};
