import { FC, ReactElement } from 'react';
import { ICONS } from 'src/constants/icons';
import { IMealComboGroupWithItems, IProductInsideCombo } from 'src/types/combo';
import { IProductExclusion, IProductModifier } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import Icon from 'src/view/components/Controls/Icon/Icon';
import CustomModalComponent from 'src/view/components/Controls/Modal/Modal';
import ScrollableBlock from 'src/view/containers/ScrollableBlock/ScrollableBlock';
import { MealComboGroupMobileItem } from '../../components/MealComboGroupMobileItem/MealComboGroupMobileItem';
import './style.scss';

interface IProps {
  group: IMealComboGroupWithItems;
  readOnly?: boolean;
  onSelectItem: (item: IProductInsideCombo) => void;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
  onCloseList: () => void;
}

export const MealComboGroupMobileItems: FC<IProps> = ({ group, ...props }: IProps): ReactElement => {
  return (
    <CustomModalComponent
      isOpen={true}
      onRequestClose={props.onCloseList}
      className="product-meal-group-mobile-items__modal"
    >
      <div className="product-meal-group-mobile-items__wrapper">
        <Icon name={ICONS.ARROW_LEFT} size={24} onPress={props.onCloseList} className="th-color-primary pxmb-4" />

        <ScrollableBlock>
          <p className="product-meal-group-mobile-items__group-name family-semibold color-dark text-overflow pxmb-3">
            {getTranslate(group.name)}
          </p>

          <div className="product-meal-group-mobile-items">
            {group.items.map((item, index) => (
              <MealComboGroupMobileItem
                key={productUtils.getMealItemKey(item, index)}
                item={item}
                readOnly={props.readOnly}
                onSelectItem={props.onSelectItem}
                onModifierChange={props.onModifierChange}
                onExclusionsChange={props.onExclusionsChange}
              />
            ))}
          </div>
        </ScrollableBlock>

        <div className="product-meal-group-mobile-items__close" onClick={props.onCloseList} />
      </div>
    </CustomModalComponent>
  );
};
