import add from 'date-fns/add';
import isBefore from 'date-fns/isBefore';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { DESTINATION_LOGS, EVENTS } from 'src/constants/events';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { addressStore } from 'src/mobx/barnchStore/address';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { preorderStore } from 'src/mobx/preorderStore';
import logger from 'src/services/logger';
import { IProduct } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { getNearestWorkingTimeMessage } from 'src/utils/helpers';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';
import PreorderHelperModal from 'src/view/containers/Modals/PreorderHelperModal/PreorderHelperModal';
import { productsStore } from 'src/mobx/productsStore';
import './style.scss';

interface Props extends RouteComponentProps<{ id: string }> {
  product: IProduct;
  modifiersErrors: string[];
  disabled: boolean;
  closeModal: () => void;
  setNeedScrollToError: (need: boolean) => void;
}

interface IProductErrorOption {
  text: string;
  className?: string;
  textClassName?: string;
  onPress?: () => void;
}

interface IProductError {
  title?: string;
  message?: string;
  options: IProductErrorOption[];
}

interface State {
  error: null | IProductError;
  preorder?: { visible: boolean; message?: string };
}

class ProductSubmit extends Component<Props, State> {
  state: State = {
    error: null,
    preorder: { visible: false },
  };

  createError = (title: string, message: string, options: IProductErrorOption[]): IProductError => {
    return {
      title,
      message,
      options,
    };
  };

  clearError = () => {
    this.setState({ error: null });
  };

  goToBranches = (delivery_type: string | undefined, search?: string, state?: any) => {
    this.props.history.push({
      pathname: SCREENS.BRANCHES(delivery_type !== DELIVERY_TYPES.BOTH ? delivery_type : DELIVERY_TYPES.DELIVERY),
      search,
      state,
    });
  };

  validate = (): IProductError | null | boolean => {
    if (Boolean(this.props.modifiersErrors.length)) {
      this.props.setNeedScrollToError(true);
      return true;
    }

    // Restaurant is closed
    if (!appStore.settings?.is_restaurant_working && !this.props.product.is_donation) {
      return this.createError(localeStore.t('txt_closed'), localeStore.t('the_restaurants_is_closed_now'), [
        {
          text: localeStore.t('Close'),
          className: 'background-light',
          textClassName: 'color-dark',
        },
      ]);
    }

    // Brunch is not covered and donation active
    if (branchStore.branch.id && !branchStore.branch.branchCovered && this.props.product.is_donation) {
      if (cartStore.products.some((item) => item.id === this.props.product.id)) {
        this.add();
        return null;
      }
      const title = localeStore.t('order_donation');
      const message = localeStore.t('donation_only_alert');
      return this.createError(title, message, [
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('txt_change'),
          onPress: () => {
            this.goToBranches(appStore.settings?.delivery_type);
          },
        },
        {
          text: localeStore.t('txt_continue'),
          onPress: () => {
            this.props.closeModal();
            this.add();
            return null;
          },
        },
      ]);
    }

    // Branch is not selected
    if (
      !branchStore.branch.id ||
      (branchStore.branch.id && !branchStore.branch.branchCovered && !this.props.product.is_donation)
    ) {
      let title = '';
      let delivery_type = DELIVERY_TYPES.BOTH;

      switch (appStore.settings?.delivery_type) {
        case DELIVERY_TYPES.BOTH:
          title = localeStore.t('please_specific_your_address_or_select_pickup');
          break;
        case DELIVERY_TYPES.PICKUP:
          delivery_type = DELIVERY_TYPES.PICKUP;
          title = localeStore.t('please_select_pickup_branch');
          break;
        case DELIVERY_TYPES.DELIVERY:
          delivery_type = DELIVERY_TYPES.DELIVERY;
          title = localeStore.t('please_specific_your_address');
          break;
        default:
          title = localeStore.t('please_select_pickup_branch');
          break;
      }

      return this.createError(title, '', [
        {
          text: localeStore.t('txt_change'),

          onPress: () => {
            this.props.closeModal();
            this.goToBranches(delivery_type, `?language=${localeStore.language}`, {
              from: SCREENS.PRODUCT(this.props.product.id),
            });
          },
        },
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('Close'),
        },
      ]);
    }
    // Branch is busy and preorder time is not selected
    if (branchStore.branch.is_busy) {
      // Preorder is available
      if (preorderStore.preorderIsAvailable) {
        // Preorder time not selected
        if (!preorderStore.preorder_unix || !preorderStore.isTimeConfirmed) {
          this.setState({ preorder: { visible: true, message: localeStore.t('busy_preorder') } });
          return true;
        }
      } else {
        return this.createError(
          localeStore.t('branch_busy_status'),
          localeStore.t('menu_busy_notification', { name: getTranslate(branchStore.branch.name) }),
          [
            {
              text: localeStore.t('txt_ok'),
              className: 'background-light',
              textClassName: 'color-dark',
            },
          ]
        );
      }
    }

    // if branch is not working now and preorder is not available or branch is closed
    if (
      (!branchStore.branch.working_now && !branchStore.branch.preorder && !this.props.product.is_donation) ||
      branchStore.branch.status === 0
    ) {
      return this.createError('', localeStore.t('branch_is_closed_now'), [
        {
          text: localeStore.t('txt_change'),
          onPress: () => {
            this.props.closeModal();
            this.goToBranches(appStore.settings?.delivery_type, `?language=${localeStore.language}`);
          },
        },
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('Close'),
        },
      ]);
    }

    // if branch changed delivery type and not supported current anymore
    if (
      // delivery type not both
      branchStore.branch.delivery_type !== DELIVERY_TYPES.BOTH &&
      // selected delivery type is different to branch delivery type
      branchStore.delivery_type !== branchStore.branch.delivery_type
    ) {
      const message =
        branchStore.delivery_type === DELIVERY_TYPES.DELIVERY
          ? localeStore.t('delivery_currently_not_available')
          : localeStore.t('pickup_is_currently_not_available');

      return this.createError('', message, [
        {
          text: localeStore.t('txt_change'),
          onPress: () => {
            this.props.closeModal();
            this.goToBranches(branchStore.delivery_type, `?language=${localeStore.language}`);
          },
        },
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('Close'),
        },
      ]);
    }

    // if branch not closed and not working now and preorder available and preorder is not selected
    if (
      !branchStore.branch.working_now &&
      branchStore.branch.preorder &&
      branchStore.branch.status === 1 &&
      !this.props.product.is_donation &&
      (!preorderStore.preorder_unix || !preorderStore.preorder_time || !preorderStore.isTimeConfirmed)
    ) {
      if (
        !preorderStore.preorderIsAvailable &&
        Array.isArray(branchStore.branch.working_time) &&
        branchStore.branch.working_time.length
      ) {
        const message = getNearestWorkingTimeMessage();
        return this.createError(localeStore.t('the_restaurants_is_closed_now'), message, [
          {
            text: localeStore.t('txt_ok'),
          },
        ]);
      } else if (Array.isArray(branchStore.branch.working_time) && branchStore.branch.working_time.length) {
        this.setState({ preorder: { visible: true } });
        return true;
      } else if (!Array.isArray(branchStore.branch.working_time) || !branchStore.branch.working_time.length) {
        const message = localeStore.t('no_working_hr');
        return this.createError(localeStore.t('branch_is_closed_now_select_preorder_time'), message, [
          {
            text: localeStore.t('txt_ok'),
            onPress: () => {
              this.handleProduct();
            },
          },
        ]);
      }
    }
    // if branch was confirmed more of hour ago return errors
    if (isBefore(add(new Date(branchStore.branch.confirmedAt || new Date()), { hours: 1 }), new Date())) {
      return this.createError(
        `Please confirm your ${branchStore.delivery_type === DELIVERY_TYPES.DELIVERY ? 'address' : 'pickup branch'}`,
        `Branch: ${getTranslate(branchStore.branch.name)} ; Address: ${
          !addressStore.address?.address || branchStore.branch.address
        }`,
        [
          {
            text: localeStore.t('txt_confirm'),
            onPress: this.confirm,
          },
        ]
      );
    }

    return null;
  };

  submit = () => {
    const { product } = this.props;

    // For SMS page
    if (cartStore.isSMS && !product.hash) {
      this.props.closeModal();
      return;
    }

    // TODO don't forget to return
    // if (!product || product.out_of_stock) return;

    const error = this.validate();

    if (error && typeof error !== 'boolean') {
      this.setState({ error });
      return;
    }

    if (error) return;
    this.handleProduct();
  };

  handleProduct = () => {
    const { product } = this.props;

    if (product.hash) {
      this.update();
    } else {
      this.add();
      logger.logEvent(EVENTS.PRODUCT_SUBMITED_TO_CART, { product });

      const itemCategory = productsStore.categories.find((category) => category.id === product?.category_id);

      logger.logEvent(
        EVENTS.ADD_TO_CART,
        {
          item_id: product?.id,
          item_name: getTranslate(product?.name),
          item_category: getTranslate(itemCategory?.name),
          currency: branchStore.currency,
          price: product?.price,
          quantity: product?.quantity,
        },
        DESTINATION_LOGS.MOENGAGE
      );

      if (product.added_from) {
        logger.logEvent(EVENTS.CROSS_SELLING_PRODUCT_ADDED_TO_CARD, { product });
      }
    }
  };

  add = () => {
    cartStore.add(this.props.product);
    this.props.closeModal();
  };

  update = () => {
    cartStore.update(this.props.product);
    this.props.closeModal();
  };

  confirm = () => {
    // set selected branch to confirm it
    branchStore.setBranch(branchStore.branch, branchStore.delivery_type);

    this.handleProduct();
    this.props.closeModal();
  };

  renderModalContent = ({ closeModal }: { closeModal: () => void }) => (
    <ModalMessage
      wrapperClassName={'px-0 pb-0 pt-3'}
      minWidth={320}
      title={this.state.error?.title}
      message={this.state.error?.message}
      options={this.state.error?.options}
      closeModal={closeModal}
    />
  );

  closePreorderModal = () => {
    this.setState({ preorder: { visible: false } });
  };

  get message() {
    if (!cartStore.isEditable || (cartStore.isSMS && !this.props.product.hash)) return localeStore.t('back_to_cart');
    if (this.props.product.out_of_stock) return localeStore.t('out_of_stock');
    if (this.props.product.hash) return localeStore.t('txt_save');
    return localeStore.t('Add');
  }

  render() {
    const { error } = this.state;
    return (
      <div className="full-width product-submit">
        <PopupModal
          mobile={{
            popup: true,
            sheet: false,
          }}
          position={'top'}
          selfClose={false}
          visible={Boolean(error && Object.keys(error).length)}
          justify={'center'}
          content={this.renderModalContent}
          onClose={this.clearError}
        >
          <ButtonComponent
            disabled={this.props.disabled}
            onClick={this.submit}
            className={'product-button'}
            id={'product_submit'}
            fullSize
            title={this.message}
          />
        </PopupModal>
        <PreorderHelperModal
          onClose={this.closePreorderModal}
          visible={!!this.state.preorder?.visible}
          message={this.state.preorder?.message}
        />
      </div>
    );
  }
}

export default withRouter(ProductSubmit);
