import { io, Socket } from 'socket.io-client';
import { TRoomKeys } from 'src/constants/rooms';
import { TOPICS } from 'src/constants/topics';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { TEventMessage, TOrderCRUDEvents } from 'src/constants/orders';
import { getHostName, getSocketHost } from 'src/utils/formatter';
import { IOrder } from 'src/types/orders';
import { ordersStore } from 'src/mobx/ordersStore';
import { logError } from 'src/utils/helpers';

class NSocket {
  private lastSuccessfullyConnect = Date.now();
  private timer: null | NodeJS.Timeout = null;
  port: number = 0;

  private get _headers() {
    return {
      'Accept-Language': localeStore.language,
      Authorization: userStore.user.auth_token || '',
      domain:
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_SOCKET_CLIENT || getHostName(window.location.origin)
          : getHostName(window.location.origin),
    };
  }

  client: null | Socket = null;

  public subscribeToRoom(room: TRoomKeys, id: [number | string, number | string | undefined]) {
    this.client?.emit(TOPICS.ROOM, {
      id,
      room,
    });
  }

  init = () => {
    try {
      if (!appStore.settings?.socket) return;
      this.client = io(getSocketHost(), {
        extraHeaders: this._headers,
        path: '/sockets/socket.io',
      });

      this.client.on('connect', () => {
        console.info('Socket connected');
      });
      this.client.on('disconnect', (reason: any) => {
        console.info('Socket disconnected', reason);
      });
      this.client.on(TOPICS.ORDER, this.handleOrder);
    } catch (error: any) {
      logError(error);
    }
  };

  disconnect = () => {
    this.client?.disconnect();
  };

  async handleOrder(message: TEventMessage<IOrder, TOrderCRUDEvents>) {
    try {
      if (!message) return;
      const data: IOrder = message.data;
      ordersStore.updateOrder(data);
    } catch (error: any) {
      logError(error);
    }
  }
}
const socket = new NSocket();

export default socket;
