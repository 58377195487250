import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import './style.scss';

interface IProps {
  className?: string;
}

export default class SizesSkeleton extends Component<IProps, {}> {
  render() {
    return <Skeleton className={`sizes ${this.props.className || ''}`} />;
  }
}
