import { CashbackErrorText } from 'src/types/error';

export const ERRORS: any = {
  10401: 'promo_code',
  10403: 'apply_promo_code_after_login',
};

export enum CashbackErrorCode {
  UnavailableForBranch = 10601,
  ChangedConditions = 10602,
  Unavailable = 10603,
  UnavailableForOrderType = 10604,
}

export const cashbackErrorDesc: CashbackErrorText = {
  [CashbackErrorCode.UnavailableForBranch]: 'keep_cart_cashback_unavailable_for_branch_popup_bodytext',
  [CashbackErrorCode.ChangedConditions]: 'keep_cart_cashback_conditions_changed_popup_bodytext',
  [CashbackErrorCode.Unavailable]: 'keep_cart_cashback_is_unavailable_popup_bodytext',
  [CashbackErrorCode.UnavailableForOrderType]: 'keep_cart_cashback_unavailable_for_order_type_popup_bodytext',
};

export const cashbackErrorTitles: CashbackErrorText = {
  [CashbackErrorCode.UnavailableForBranch]: 'keep_cart_cashback_unavailable_for_branch_popup_hdr',
  [CashbackErrorCode.ChangedConditions]: 'keep_cart_cashback_conditions_changed_popup_hdr',
  [CashbackErrorCode.Unavailable]: 'keep_cart_cashback_is_unavailable_popup_hdr',
  [CashbackErrorCode.UnavailableForOrderType]: 'keep_cart_cashback_unavailable_for_order_type_popup_hdr',
};

export const cashbackErrorLabel: CashbackErrorText = {
  [CashbackErrorCode.UnavailableForBranch]: 'keep_cart_cashback_unavailable_for_branch_notification',
  [CashbackErrorCode.Unavailable]: 'keep_cart_cashback_is_unavailable_notification',
  [CashbackErrorCode.UnavailableForOrderType]: 'keep_cart_cashback_unavailable_for_order_type_notification',
};
