import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { cartStore } from 'src/mobx/cartStore';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';
import './style.scss';
import { localeStore } from '../../../../../../../mobx/localesStore';
import { branchStore } from '../../../../../../../mobx/barnchStore';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModalsErrorBranch extends Component<Props> {
  closeModal = () => {
    cartStore.setModalValue('errorBranch', null);
  };

  renderErrorBranch = ({ closeModal }: { closeModal: () => void }) => {
    const { errorBranch } = cartStore.checkout.modals;
    return (
      <ModalMessage
        wrapperClassName={'px-0 pb-0 pt-3'}
        minWidth={320}
        title={errorBranch || ' '}
        closeModal={closeModal}
        options={[
          {
            text: localeStore.t('choose_address'),
            onPress: () => {
              this.closeModal();
              cartStore.closeCart();
              cartStore.closeCheckout();
              this.props.history.push({
                pathname: `/branches/${branchStore.delivery_type}`,
                search: `?language=${localeStore.language}`,
              });
            },
          },
        ]}
      />
    );
  };

  render() {
    const { modals } = cartStore.checkout;

    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose
        backdrop
        onClose={this.closeModal}
        visible={!!modals.errorBranch}
        content={this.renderErrorBranch}
        className="checkout-modal-error"
        mobile={{
          popup: true,
          sheet: false,
        }}
      />
    );
  }
}

export default withRouter(CheckoutModalsErrorBranch);
