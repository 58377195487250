import { action, observable } from 'mobx';
import React from 'react';
import { arrayMerge } from 'src/utils/formatter';

interface IOption {
  text: string;
  className?: string;
  textClassName?: string;
  onPress?: () => void;
}

export interface IMessageModalProps {
  id?: string;
  type: 'modal' | 'snake';
  title?: string;
  message?: string;
  Content?: React.ReactNode;
  options?: IOption[];
  onClose?: () => any;
  onRequestClose?: () => any;
  autoClose?: boolean | false;
}

export interface IMessageModal {
  id: string;
  message: IMessageModalProps;
}

export class MessagesStore {
  public get persist() {
    return false;
  }

  @observable public messages: IMessageModal[] = [];

  @action public addMessage(message: IMessageModalProps) {
    this.messages = arrayMerge(this.messages, [{ id: message.id || `${Date.now()}`, message }], 'id');
  }

  @action public removeMessage(id: string) {
    this.messages = this.messages.filter((message) => message.id !== id);
  }
}

const messagesStore = new MessagesStore();

export { messagesStore };
