import './style.scss';

import moment from 'moment';
import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { localeStore } from 'src/mobx/localesStore';
import client from 'src/services/client';
import { IPagination, TTranslate } from 'src/types/main';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import ProductsSortingButton from 'src/view/components/ProductsSortingButton/ProductsSortingButton';
import { EmptyList } from 'src/view/components/ScrollableList/EmptyList';
import ScrollableList from 'src/view/components/ScrollableList/ScrollableList';

import { dateFormat } from '../../../../../utils/helpers';
import { TWalletStatus } from '../../WalletScreen';

interface Props {
  status: TWalletStatus;
}

interface IWallet {
  amount: string;
  created_at: string;
  expires_at: string;
  id: number;
  order_id: number;
  order_key: string;
  type: 1 | 2 | 3 | 4 | 7;
  wallet_id: number;
  wallet_type: 1 | 2 | 3;
  wallet_reason_list: TTranslate;
}

interface State {
  pagination: IPagination<IWallet>;
  sort_by: 'created_at' | 'expires_at';
  sort_dir: 'asc' | 'desc';
}

enum WALLET_TYPE {
  DEPOSIT = 1,
  REFUND = 2,
  EXPIRATION = 3,
  WITHDRAWAL = 4,
  CORRECT = 7,
}

enum BALANCE_TYPE {
  TOP_UP = 1,
  REFUND = 2,
  CASHBACK = 3,
}

export default class WalletList extends Component<Props, State> {
  state: State = {
    pagination: {
      current_page: 0,
      last_page: 1,
      data: [],
      from: 0,
      to: 0,
      total: 0,
      loading: false,
    },
    sort_by: 'created_at',
    sort_dir: 'desc',
  };

  headers: {
    [key in IWallet['type']]: string;
  } = {
    1: localeStore.t('deposit'),
    2: localeStore.t('refund'),
    3: localeStore.t('expiration'),
    4: localeStore.t('withdrawal'),
    7: localeStore.t('correction'),
  };

  balance_types: {
    [key in IWallet['wallet_type']]: string;
  } = {
    1: localeStore.t('top up'),
    2: localeStore.t('refund'),
    3: localeStore.t('cashback'),
  };

  componentDidUpdate = (props: Props) => {
    if (this.props.status !== props.status) {
      this.clear();
      this.fetchWallet(1);
    }
  };

  fetchWallet = async (page: number = 0) => {
    try {
      if (!page) page = this.state.pagination.current_page + 1;
      this.setState((state) => ({ pagination: { ...state.pagination, loading: true } }));
      const response = await client.get('/v3/me/wallet-transactions', {
        params: {
          page: page || this.state.pagination.current_page + 1,
          type: this.props.status,
          sort_by: this.state.sort_by,
          sort_dir: this.state.sort_dir,
        },
      });
      this.setState((state) => ({
        pagination: {
          ...response,
          data: page > 1 ? [...state.pagination.data, ...response.data] : response.data,
          loading: false,
        },
      }));
    } catch (error: any) {
      this.setState((state) => ({ pagination: { ...state.pagination, loading: false } }));
    }
  };

  setFilter = (sort_by: State['sort_by']) => {
    this.setState(
      (state) => ({ sort_by, sort_dir: state.sort_dir === 'asc' ? 'desc' : 'asc' }),
      () => {
        this.clear();
        this.fetchWallet(1);
      }
    );
  };

  /**
   * Get Wallet Amount with sing
   * If isDebit added '-' sign to amount
   *
   * @param {string} amount
   * @param {boolean} isDebit
   * @memberof WalletList
   * @returns {string} Wallet amount
   */
  formatWalletAmount = (amount: string, isDebit: boolean) => {
    return (isDebit && Number(amount) > 0 ? '-' : '') + formatPrice(Number(amount), '');
  };

  renderWallet = (wallet: IWallet | undefined, index: number) => {
    const isCashback = wallet?.wallet_type === BALANCE_TYPE.CASHBACK;

    const isDebit: boolean = Boolean(
      wallet?.type && [WALLET_TYPE.WITHDRAWAL, WALLET_TYPE.EXPIRATION, WALLET_TYPE.CORRECT].includes(wallet?.type)
    );

    return (
      <div
        key={wallet?.id || index}
        className="wallet-item row mt-2 mt-lg-4 d-flex flex-row justify-lg-content-between"
      >
        <div className="col-6 col-lg-3">
          <div className="d-flex flex-column">
            <p className="text-size-16 family-bold mb-3">
              {wallet ? this.headers[wallet.type] : <Skeleton width={100} />}
            </p>
            <p className="text-size-13  text-md-size-14  text-uppercase">
              {wallet ? isDebit ? null : this.balance_types[wallet?.wallet_type] : <Skeleton width={60} />}
              {wallet && isCashback && !isDebit && !!wallet.wallet_reason_list ? (
                // Render current translate
                <div className="wallet-offer-name">{getTranslate(wallet?.wallet_reason_list)}</div>
              ) : null}
            </p>
          </div>
        </div>
        <div className="col-6 col-lg-3">
          <div className="d-flex flex-column">
            <p className="text-size-16  text-md-size-14  family-semibold mb-3  d-flex justify-content-lg-start justify-content-end">
              {wallet ? this.formatWalletAmount(wallet.amount, isDebit) : <Skeleton width={50} />}
            </p>
            <p className="text-size-13 text-md-right text-md-size-14 text-uppercase color-gray d-flex justify-content-lg-start justify-content-end">
              {wallet ? wallet.order_id ? `ORDER #${wallet.order_key}` : null : <Skeleton width={120} />}
            </p>
          </div>
        </div>
        <div className="col-3 mr-5 mr-lg-0 ">
          <div className="d-flex flex-column">
            <div className="d-flex d-lg-none mb-2 mt-3  color-darkgray text-uppercase">
              <p className="text-size-10">{localeStore.t('transaction_date')}</p>
            </div>
            <p className="text-size-16 text-md-size-14 family-semibold  mb-0 mb-lg-3">
              {wallet ? (
                wallet.created_at ? (
                  dateFormat(moment(wallet.created_at).toDate(), 'dd.MM.yyyy')
                ) : null
              ) : (
                <Skeleton width={80} />
              )}
            </p>
          </div>
        </div>
        <div className="col-3">
          <div className="d-flex flex-column">
            {!isDebit ? (
              <div className="d-flex d-lg-none mb-2 mt-3 color-darkgray text-uppercase">
                <p className="text-size-10">{localeStore.t('transaction_expired')}</p>
              </div>
            ) : null}
            <p className="text-size-16 text-md-size-14 family-semibold mb-0 mb-lg-3">
              {wallet ? (
                wallet.expires_at && !isDebit ? (
                  dateFormat(moment(wallet.expires_at).toDate(), 'dd.MM.yyyy')
                ) : null
              ) : (
                <Skeleton width={80} />
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  renderHeader = () => {
    return (
      <div className="row mt-4 d-flex flex-row justify-content-between color-darkgray text-uppercase">
        <div className="col-6 col-lg-3">
          <p className="text-size-12 ">{localeStore.t('transaction_type')}</p>
        </div>
        <div className="col-6 col-lg-3">
          <p className="text-size-12 text-md-right">{localeStore.t('transaction_amount')}</p>
        </div>
        <div className="col-3 d-none d-lg-flex">
          <ProductsSortingButton
            sortDir={this.state.sort_dir}
            sortField={this.state.sort_by}
            name={'created_at'}
            onChange={this.setFilter}
          >
            <p className="text-size-12 color-darkgray text-uppercase">{localeStore.t('transaction_date')}</p>
          </ProductsSortingButton>
        </div>
        <div className="col-3 d-none d-lg-flex">
          <ProductsSortingButton
            sortDir={this.state.sort_dir}
            sortField={this.state.sort_by}
            name={'expires_at'}
            onChange={this.setFilter}
          >
            <p className="text-size-12 color-darkgray text-uppercase">{localeStore.t('transaction_expired')}</p>
          </ProductsSortingButton>
        </div>
      </div>
    );
  };

  clear = () => {
    this.setState(() => ({
      pagination: {
        current_page: 0,
        last_page: 1,
        data: [],
        from: 0,
        to: 0,
        total: 0,
        loading: false,
      },
    }));
  };

  render() {
    return (
      <ScrollableList
        clear={this.clear}
        renderEmptyList={<EmptyList />}
        renderHeader={this.renderHeader}
        list={this.state.pagination.data}
        loading={this.state.pagination.loading}
        last_page={this.state.pagination.last_page}
        current_page={this.state.pagination.current_page}
        fetch={this.fetchWallet}
        renderItem={this.renderWallet}
        className="d-flex flex-column full-width wallet-list row px-4 py-4 background-white flex-grow-1 mr-lg-4"
      />
    );
  }
}
