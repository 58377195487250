import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import moment from 'moment';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { defaultBranch } from 'src/constants/main';
import { TimeController } from 'src/controllers/TimeController/TimeController';
import { IBranch, IPreorder } from 'src/types/branch';
import { formatPreorderDate, formatTime } from 'src/utils/formatter';
import { getUnitKey } from 'src/utils/helpers';
import { cartStore } from '../cartStore';
import { localeStore } from '../localesStore';
import { productsStore } from '../productsStore';
import { branchStore } from '../barnchStore';

export class PreorderStore {
  /**
   * Timer for check preorder
   *
   * @memberof PreorderStore
   */
  timer: any = null;

  /**
   * Preorder time at unix
   *
   * @type {(number | undefined)}
   * @memberof BranchPreorderStore
   */
  @persist('object') @observable public preorder_unix: number | undefined;

  /**
   * Has the time been confirmed by the user?
   *
   * @type {boolean}
   * @memberof BranchPreorderStore
   */
  @persist('object') @observable public isTimeConfirmed: boolean = false;

  @persist('object') @observable private branch: IBranch | undefined;

  @persist('object') @observable private delivery_type: string | undefined;

  /**
   * Time Handler for preorder
   *
   * @type {(TimeController | null)}
   * @memberof BranchPreorderStore
   */
  @observable public timeController: TimeController | null = null;

  /**
   * Is it possible to place an order at all
   *
   * @type {boolean}
   * @memberof PreorderStore
   */
  @computed get availableAtAll(): boolean {
    return this.timeController?.availableAtAll ?? false;
  }

  /**
   * Is it possible make a preorder
   *
   * @type {boolean}
   * @memberof PreorderStore
   */
  @computed get preorderIsAvailable(): boolean {
    return this.timeController?.preorderIsAvailable ?? false;
  }

  /**
   * Preorder time - text
   *
   * Displayed on Header
   * Example: "~10 min", "Today 10:00 PM"
   *
   * @readonly
   * @type {(string | null)}
   * @memberof BranchPreorderStore
   */
  @computed get preorder_time(): string | null {
    if (!this.branch || !this.timeController) {
      return null;
    }

    const preparation =
      this.delivery_type === DELIVERY_TYPES.PICKUP
        ? this.branch.preparation?.pickup
        : this.branch.preparation?.delivery;

    if (!this.timeController?.availableAtAll) {
      return null;
    }

    if (!this.branch.preorder || (!this.preorder_unix && this.timeController.availableNow)) {
      if (!preparation || (!this.branch.preorder && !this.timeController.availableNow)) {
        return null;
      }
      // Example: "Now", "~10 hours"
      const unit_key = getUnitKey(preparation.gap, preparation.unit || '');
      return `~ ${preparation.gap} ${localeStore.t(unit_key || '')}`;
    }

    // Example: "Tomorrow 04:58 AM"
    if (this.preorder_unix || (!this.preorder_unix && !this.timeController.availableNow)) {
      const selectedTime = this.preorder_unix
        ? moment(this.preorder_unix * 1000)
        : this.timeController.hours[0].minutes[0]?.moment || this.timeController.hours[0].moment;
      return `${formatPreorderDate(selectedTime)} ${formatTime(selectedTime, undefined)}`;
    }

    return null;
  }

  /**
   * Set Preorder
   *
   * If preorder its number, will call {@link calculatePreorder} and {@link TimeController}
   *
   * @param {(IPreorder | number)} preorder - preorder
   * @param {boolean} [manual] - if its manual (user action for example), call {@link setTimeConfirmed}
   * @memberof BranchPreorderStore
   */
  @action public setPreorder = async (preorder: number | undefined, manual?: boolean) => {
    try {
      this.preorder_unix = preorder;

      if (manual) this.isTimeConfirmed = true;

      // recheck if selected time is still available after timer
      this.checkPreorderStatus();

      if (!cartStore.isSMS) {
        // fetch menu if preorder time was changed
        await productsStore.fetchMenu();
        if (cartStore.products.length > 0) {
          await cartStore.calculate(false, branchStore.branch.id, branchStore.delivery_type);
        }
      }
    } catch (error) {
      console.error('Set preorder error', error);
    }
  };

  /**
   * Check status of Time
   *
   *
   * @memberof BranchPreorderStore
   */
  checkPreorderStatus = (branch?: IBranch, delivery_type?: string) => {
    if (branch) this.branch = branch;
    if (delivery_type) this.delivery_type = delivery_type;

    const preorder_moment = this.preorder_unix ? moment(this.preorder_unix * 1000) : moment();

    this.timeController = new TimeController({
      branch: this.branch,
      deliveryType: this.delivery_type,
      year: preorder_moment.year(),
      month: preorder_moment.month(),
      date: preorder_moment.date(),
    });

    // No check if its default branch
    if (this.branch?.id === defaultBranch.id || this.delivery_type !== delivery_type) return;

    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (!this.branch?.preorder) {
      // clear selected time if preorder is not available
      this.setPreorder(undefined);
      return;
    }

    if (!this.timeController.availableAtAll) {
      this.setPreorder(undefined);
    }

    // branch is not working now and pre-order time is not selected
    if (!this.preorder_unix && this.timeController.availableAtAll && !this.timeController.availableNow) {
      this.setPreorder(this.timeController.hours[0].minutes[0]?.moment.unix());
      return;
    }

    if (!this.timeController.availableAtAll) {
      this.setPreorder(undefined);
      return;
    }
    // selected preorder time is exist and it is in the past
    if (
      this.preorder_unix &&
      this.timeController.availableAtAll &&
      moment(this.preorder_unix * 1000).isSameOrBefore(
        this.timeController.hours[0].minutes[0]?.moment || this.timeController.hours[0].moment
      )
    ) {
      if (this.timeController.availableNow) {
        this.setPreorder(undefined);
      } else if (this.timeController.hours[0]) {
        this.setPreorder(
          this.timeController.hours[0].minutes[0]?.moment.unix() || this.timeController.hours[0].moment.unix()
        );
      } else {
        this.setPreorder(undefined);
      }
      return;
    }

    this.setPreOrderReCheckTimer();
  };

  /**
   * Recheck if selected time is still available after timer
   *
   * @private
   * @memberof PreorderStore
   */
  private setPreOrderReCheckTimer = () => {
    if (this.timer) clearTimeout(this.timer);
    const diff = moment().endOf('minute').diff(moment(), 'milliseconds');
    if (diff > 0) {
      this.timer = setTimeout(this.checkPreorderStatus, diff);
    }
  };

  @action public resetTime = () => {
    this.preorder_unix = undefined;
    this.isTimeConfirmed = false;
  };

  @action public clear = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.branch = undefined;
    this.delivery_type = undefined;

    if (this.preorder_unix !== null) {
      this.preorder_unix = undefined;
      this.isTimeConfirmed = false;
      productsStore.fetchMenu();
    }
  };
}

export const preorderStore = new PreorderStore();
