import { observer } from 'mobx-react';
import { WINDOW_SIZES } from 'src/constants/main';
import { productsStore } from 'src/mobx/productsStore';
import { ICategory } from 'src/types/products';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import CategoriesDropDown from './components/CategoriesDropDown/CategoriesDropDown';
import { CategoriesList } from './components/CategoriesList/CategoriesList';
import { useCategoriesVisibility } from 'src/view/screens/MenuScreen/containers/MenuCategories/components/CategoriesList/hooks/useCategoriesVisibility';
import './style.scss';

const MenuCategories = observer(() => {
  const [containerRef, visibilityMap] = useCategoriesVisibility(productsStore.loading, productsStore.categories);
  const hiddenCategories = productsStore.categories?.filter((category: ICategory) => !visibilityMap[category.id]);

  return (
    <div className="d-flex flex-row justify-content-between full-width  menu-categories">
      <div className={'menu-categories-list mx-n3 hide-scrollbar'}>
        <CategoriesList ref={containerRef} />
      </div>
      <ResponsiveContent min={WINDOW_SIZES.md}>
        <CategoriesDropDown categories={hiddenCategories} />
      </ResponsiveContent>
    </div>
  );
});

export default MenuCategories;
