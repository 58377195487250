import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import client from 'src/services/client';
import InnerHtml from 'src/view/components/InnerHtml/InnerHtml';
import { localeStore } from 'src/mobx/localesStore';
import { appStore } from 'src/mobx/appStore';
import { extractHtmlContent, getRestaurantName, setMetaTags } from 'src/utils/helpers';

export default class PrivacyScreen extends Component {
  state = {
    loading: false,
    template: '',
  };

  componentDidMount = async () => {
    try {
      this.setState(() => ({ loading: true }));
      const { template } = await client.get('/v3/info/privacy');
      this.setState(() => ({ loading: false, template }));
    } catch (error: any) {
      this.setState(() => ({ loading: false }));
    }

    setMetaTags({
      title: `${getRestaurantName()} - ${localeStore.t('title_privacy')}`,
      keywords: `${getRestaurantName()}, ${localeStore.t('title_privacy')}`,
      description: `${extractHtmlContent(this.state.template)}`,
      'og:title': `${getRestaurantName()} - ${localeStore.t('title_privacy')}`,
      'og:type': 'website',
      'og:image': `${appStore.settings?.img_logo}`,
      'og:description': `${extractHtmlContent(this.state.template)}`,
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="d-flex flex-column full-width">
          <Skeleton width={'40%'} height={36} className="mt-5" />

          <div className="mt-5">
            <Skeleton className={'text-size-18'} width={'100%'} count={8} style={{ marginBottom: 10 }} />
            <Skeleton width={'55%'} count={1} style={{ marginBottom: 10 }} />
          </div>
        </div>
      );
    }
    return (
      <div className="full-width">
        <InnerHtml html={this.state.template} />
      </div>
    );
  }
}
