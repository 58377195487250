export const paymentTypes: IPaymentTypes = [
    "cash",
    "RadioCreditdebit",
    "txt_points_currency",
    "payment_terminal"
];

interface IPaymentTypes {
    0: "cash",
    1: "RadioCreditdebit",
    2: "txt_points_currency",
    3: "payment_terminal",
}
