import { FC, ReactElement, memo } from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { IMealComboGroupWithItems, IProductInsideCombo } from 'src/types/combo';
import { IProduct, IProductExclusion, IProductModifier } from 'src/types/products';
import { productUtils } from 'src/utils/productUtils';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import ProductNotes from '../../../../components/ProductNotes/ProdutcNotes';
import { isAvailabilityToShowNotes } from '../../../../helpers/productScreen';
import { MealComboGroupMobile } from '../../components/MealComboGroupMobile/MealComboGroupMobile';
import './style.scss';

interface IProps {
  product?: IProduct;
  modifiersErrors: string[];
  needScrollToError: boolean;
  readOnly?: boolean;
  onSelectItem: (item: IProductInsideCombo) => void;
  onNotesChanged: (notes: string) => void;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
  setNeedScrollToError: (need: boolean) => void;
}

export const MealComboItemsBlockMobile: FC<IProps> = memo(({ product, ...props }: IProps): ReactElement => {
  const currentGroups: IMealComboGroupWithItems[] = productUtils.getCurrentMealSizeGroups(product);
  const showNotes: boolean = isAvailabilityToShowNotes(product, true);

  return (
    <ResponsiveContent max={WINDOW_SIZES.md}>
      <div className="product-meal-items-block-mobile">
        {currentGroups.map((group) => (
          <MealComboGroupMobile
            key={group.id + '-meal-group'}
            group={group}
            modifiersErrors={props.modifiersErrors}
            needScrollToError={props.needScrollToError}
            readOnly={props.readOnly}
            onNotesChanged={props.onNotesChanged}
            onSelectItem={props.onSelectItem}
            onModifierChange={props.onModifierChange}
            onExclusionsChange={props.onExclusionsChange}
            setNeedScrollToError={props.setNeedScrollToError}
          />
        ))}

        {showNotes ? (
          <ProductNotes
            value={product?.notes || ''}
            loading={!product}
            disabled={!cartStore.isEditable}
            onChange={props.onNotesChanged}
          />
        ) : null}
      </div>
    </ResponsiveContent>
  );
});
