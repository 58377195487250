import * as React from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import Collapse from 'src/view/components/Collapse/Collapse';
import Icon from 'src/view/components/Controls/Icon/Icon';
import LoadingCircle from 'src/view/components/LoadingCircle/LoadingCircle';
import './style.scss';

export interface IDiscountProps {
  /**
   * Name of Promocode
   *
   * @type {string}
   * @memberof IDiscountProps
   */
  name: string;
  /**
   * Promocode discount amount
   *
   *      default 0
   * @type {number}
   * @memberof IDiscountProps
   */
  amount: number;
  /**
   * Discount Description
   * Which products the promocode applies to
   * If this props doesn't pass, chevron will not be rendered
   * @type {string}
   * @memberof IDiscountProps
   */
  description?: string;
  className?: string;
  loading?: boolean;
}

export function Discount({ name, amount = 0, description, className, loading }: IDiscountProps) {
  const [isOpened, setOpened] = React.useState(false);

  /**
   * Toggle collapse element
   * @type {*}
   */
  const onClickHeader = React.useCallback(() => {
    setOpened(!isOpened);
  }, [isOpened]);

  return (
    <div className={`discount ${isOpened ? 'open' : ''} ${className || ''}`}>
      <div className={`discount-header ${description ? 'text-pointer' : ''}`} onClick={onClickHeader}>
        <div className="d-flex flex-row align-items-center">
          {/* Label */}
          <p className={'color-dark family-semibold text-size-14'}>{localeStore.t('discount')}</p>
          {/* Chevron */}
          {description ? <Icon name={ICONS.CHEVRON} className="discount-chevron" /> : null}
          {/* Discount name */}
          <p id="promocode" className={'discount-promocode color-darkgray family-regular'}>
            <LoadingCircle loading={loading}>{name}</LoadingCircle>
          </p>
        </div>
        {/* Discount Amount */}
        <p id="discount_amount" className={'color-dark family-semibold text-size-14'}>
          <LoadingCircle loading={loading}>{formatPrice(-amount)}</LoadingCircle>
        </p>
      </div>
      {/* Discount Description*/}
      {description ? (
        <Collapse className="discount-collapse" isOpened={isOpened}>
          <div className="d-flex flex-row justify-content-between full-width">
            <p className={'color-gray family-semibold text-size-10'}>{description}</p>
          </div>
        </Collapse>
      ) : null}
    </div>
  );
}
