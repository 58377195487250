import React from 'react';
import './style.scss';

interface Props {
  className?: string;
}

export const SeparatorLine: React.FC<Props> = ({ className }: Props) => (
  <div className={`separator-line ${className ?? ''}`} />
);
