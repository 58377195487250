import React from 'react';
import { IBranch } from 'src/types/branch';
import { formatPrice } from 'src/utils/formatter';
import { localeStore } from 'src/mobx/localesStore';

interface Props {
  branch?: IBranch | null;
}
const ReducedDeliveryFeeInfo = ({ branch }: Props) => {
  if (!branch) return null;
  const { current_reduced_settings, delivery_fee } = branch;

  if (!Number(current_reduced_settings?.length) && Number(delivery_fee)) {
    return <p className="branches-price family-semibold color-dark">{formatPrice(delivery_fee)}</p>;
  }

  if (!(Number(current_reduced_settings?.[0]?.reduced_delivery_fee) && Number(delivery_fee))) {
    return <p className="branches-price color-green family-semibold">{localeStore.t('txt_free')}</p>;
  }

  const getMinimumValue = Math.min(Number(current_reduced_settings[0]?.reduced_delivery_fee), Number(delivery_fee));
  return <p className="branches-price family-semibold color-dark">{formatPrice(getMinimumValue)}</p>;
};

export default ReducedDeliveryFeeInfo;
