import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { PAYMENT_TYPES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { IBranchPaymentType } from 'src/types/branch';
import { ICartCheckout } from 'src/types/cart';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { DELIVERY_TYPES } from '../../../../../constants/deliveryTypes';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import ApplePayButton from '../../../../containers/ApplePayButton/ApplePayButton';
import './style.scss';
interface State {
  isOpen: boolean;
}

@observer
export default class CheckoutPaymentTypes extends Component<{}, State> {
  state = {
    isOpen: false,
  };

  icons = {
    [PAYMENT_TYPES.CASH]: ICONS.MONEY,
    [PAYMENT_TYPES.ONLINE_PAYMENT]: ICONS.ONLINE_PAYMENY,
    [PAYMENT_TYPES.POINTS]: ICONS.STAR,
    [PAYMENT_TYPES.TERMINAL]: ICONS.TERMNAL,
    [PAYMENT_TYPES.APPLE_PAY]: ICONS.APPLE_PAY,
  };

  selectPaymentType = (payment_type: ICartCheckout['payment_type']) => () => {
    this.toggleList();
    cartStore.selectPaymentType(payment_type);
  };

  toggleList = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  /**
   * For render Payment Type Button
   *
   * @param {IBranchPaymentType} payment_type
   * @memberof CheckoutPaymentTypes
   * @returns {Component} Payment Type button
   */
  renderPaymentType = (payment_type: IBranchPaymentType) => {
    const selected = cartStore.checkout.payment_type === payment_type.id;
    // do not show payment types except "Online Payment" if any of product donation
    if (cartStore.checkout.is_donation && payment_type.id !== PAYMENT_TYPES.ONLINE_PAYMENT) return null;

    if (
      branchStore.delivery_type === DELIVERY_TYPES.PICKUP &&
      cartStore.isSMS &&
      payment_type.id !== PAYMENT_TYPES.ONLINE_PAYMENT
    ) {
      return;
    }

    /**
     * Show only default payment
     */
    if (
      branchStore.delivery_type === DELIVERY_TYPES.DELIVERY &&
      cartStore.isSMS &&
      branchStore.branch.id === branchStore.defaultBranchId &&
      payment_type.id !== branchStore.defaultBranchPaymentMethods
    ) {
      return;
    }
    return (
      <React.Fragment key={payment_type.id}>
        <div id={payment_type.type.toLowerCase()} className="col-6 text-pointer mb-2 px-1">
          <button
            disabled={cartStore.calculator.loading}
            onClick={this.selectPaymentType(payment_type.id)}
            className={`full-width checkout-payment-types col text-pointer ${
              selected ? 'border-primary' : 'background-light'
            }`}
          >
            <div className="d-flex align-items-center ">
              <Icon name={this.icons[payment_type.id]} className="color-dark  d-lg-none mr-3 ml-1" size={24} />
              <p className={`family-regular text-size-16 color-dark`}>{payment_type.type}</p>
            </div>
          </button>
        </div>
        {payment_type.id === PAYMENT_TYPES.ONLINE_PAYMENT && appStore.settings?.merchant_id ? (
          <ApplePayButton
            onClick={this.selectPaymentType(PAYMENT_TYPES.APPLE_PAY)}
            selected={cartStore.checkout.payment_type === PAYMENT_TYPES.APPLE_PAY}
          />
        ) : null}
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="d-none d-lg-flex flex-column mb-4">
        {/* Payment type */}
        <p className="text-size-18 family-bold color-dark mb-3 payment-types-title">
          {localeStore.t('txt_payment_type')}
          {!cartStore.isValidPaymentType ? (
            <Tooltip
              content={() => (
                <div className="card" style={{ padding: 15, minWidth: 200 }}>
                  <p className={'color-dark text-size-14 family-regular'}>
                    {localeStore.t('txt_invalid_payment_type', { paymentType: cartStore.checkout.payment_type })}
                  </p>
                </div>
              )}
              position="top"
              direction="left"
            >
              <Icon name={ICONS.DANGER} size={20} color="#FF6D6D" className="ml-2 invalid-payment-types-icon" />
            </Tooltip>
          ) : null}
        </p>
        {/* Payment types */}
        <div className={`checkout-payments ${this.state.isOpen ? 'd-flex' : 'd-none'} d-lg-flex flex-column`}>
          <div className="row d-flex flex-row align-items-center flex-wrap mx-n1">
            {branchStore.getPaymentTypes.map(this.renderPaymentType)}
          </div>
        </div>
      </div>
    );
  }
}
