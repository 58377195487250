import React, { ChangeEvent, Component } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ICONS } from 'src/constants/icons';
import { cartStore } from 'src/mobx/cartStore';
import { defaultCartCheckout } from 'src/mobx/cartStore/checkout';
import { localeStore } from 'src/mobx/localesStore';
import { ICartCheckout } from 'src/types/cart';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Input from 'src/view/components/Controls/Input/Input';
import PhoneInput from 'src/view/components/Controls/PhoneInput/PhoneInput';
import '../style.scss';
import { appStore } from '../../../../../../../../mobx/appStore';

interface Props extends RouteComponentProps<null> {
  onClose: () => void;
}
interface State {
  stranger_customer_info: ICartCheckout['stranger_customer_info'];
  stranger_customer_tel: string;
  [key: string]: any;
}

@observer
class CheckoutModalSomeoneForm extends Component<Props, State> {
  state: State = {
    stranger_customer_info: cartStore.checkout.stranger_customer_info,
    stranger_customer_tel:
      cartStore.checkout.stranger_customer_info.mobile || `${appStore.settings?.default_country_code || '966'}`,
  };

  onChange =
    (name: keyof ICartCheckout['stranger_customer_info']) => (event: ChangeEvent<HTMLInputElement> | string) => {
      if (name === 'name') {
        const { stranger_customer_info } = this.state;
        this.setState({
          stranger_customer_info: {
            ...stranger_customer_info,
            [name]: typeof event === 'string' ? event : event.target.value,
          },
          [`${name}_error`]: false,
        });
      } else {
        this.setState({ stranger_customer_tel: typeof event === 'string' ? event : event.target.value });
      }
    };

  onSubmit = async () => {
    const { stranger_customer_info } = this.state;
    cartStore.toggleSomeone(true);
    cartStore.setSomeone(stranger_customer_info);
    cartStore.setSomeoneValue('mobile', this.state.stranger_customer_tel);
    this.props.onClose();
  };

  onClear = () => {
    cartStore.toggleSomeone(false);
    cartStore.setSomeone(defaultCartCheckout.stranger_customer_info);
    this.props.onClose();
  };

  render() {
    const { stranger_customer_info, stranger_customer_tel, ...errors } = this.state;
    const { name } = stranger_customer_info;

    const disabledSubmit = stranger_customer_tel.length < 9 || stranger_customer_tel.length > 13 || !name;
    return (
      <div className="checkout-modal-someone checkout-scroll hide-scrollbar align-items-center px-3 py-3">
        <div className="d-flex flex-row justify-content-between align-items-center full-width  mb-3">
          <div className="d-flex flex-row align-items-center full-width">
            <p className="text-size-24 family-bold">{localeStore.t('order_for_sb')}</p>
          </div>
          <Icon name={ICONS.TRASH} onPress={this.onClear} />
        </div>
        <div className="d-flex flex-column full-width mb-3  mr-1">
          <div className="d-flex flex-column full-width mb-3">
            <div className="d-flex flex-row full-width relative mb-1">
              <PhoneInput
                animated
                required
                value={stranger_customer_tel}
                className="input-phone"
                inputClass="input-phone-input"
                inputProps={{
                  name: 'mobile',
                  required: true,
                }}
                countryCodeEditable={false}
                disableDropdown={false}
                enableSearch={false}
                disableSearchIcon={true}
                enableAreaCodes
                onChange={this.onChange('mobile')}
                placeholder={localeStore.t('mobile_of_recipient')}
              />
            </div>
            {stranger_customer_tel.length < 9 || stranger_customer_tel.length > 13 ? (
              <p className="login-error color-danger family-regular">{localeStore.t('error_invalid_mobile')}</p>
            ) : null}
          </div>
          <Input
            inputClassName={'family-semibold text-size-16 color-dark p-0 '}
            className="text-pointer mb-2 full-width"
            placeholder={localeStore.t('name_of_recipient')}
            onChange={this.onChange('name')}
            value={name}
            required
            animated
          />
        </div>
        <ButtonComponent
          style={{ width: '100%' }}
          title={localeStore.t('Submit')}
          onClick={this.onSubmit}
          disabled={disabledSubmit}
        />
      </div>
    );
  }
}
export default withRouter(CheckoutModalSomeoneForm);
