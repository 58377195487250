import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import PaymentCardModal from 'src/view/containers/PaymentCardModal/PaymentCardModal';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModals extends Component<Props> {
  closeModal = () => {
    cartStore.setModalValue('paymentCard', null);
  };

  addPaymentCard = (number: string, month: string, year: string, cvv: string) => {
    cartStore.addPaymentCard(
      {
        number,
        month,
        year,
        cvv,
      },
      branchStore.branch.id
    );
    cartStore.setModalValue('paymentCard', { loading: true });
  };

  render() {
    const { modals } = cartStore.checkout;

    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose={false}
        backdrop
        onClose={this.closeModal}
        visible={!!modals.paymentCard}
        content={() => (
          <PaymentCardModal
            onSubmit={this.addPaymentCard}
            closeModal={this.closeModal}
            loading={modals.paymentCard?.loading}
          />
        )}
        mobile={{
          popup: true,
          sheet: true,
        }}
      />
    );
  }
}

export default withRouter(CheckoutModals);
