import { Link } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { messagesStore } from 'src/mobx/messagesStore';

const WalletTermsModalContent = () => {
  const handleTermsClick = () => {
    cartStore.closeCheckout();
    cartStore.closeCart();
    messagesStore.removeMessage('wallet_limit');
  };

  return (
    <div className="text-center mt-2">
      <Link to={SCREENS.TERMS} className="text-size-14 color-primary" onClick={handleTermsClick}>
        {localeStore.t('check_TC')}
      </Link>
    </div>
  );
};

export default WalletTermsModalContent;
