import { RouterChildContext } from 'react-router-dom';
import { IOrder } from '../types/orders';
import { SCREENS } from '../constants/screens';
import { ORDER_STATUS, PAYMENT_STATUS } from '../constants/main';

const orderCreationRedirect = (
  id: string | number,
  order: IOrder,
  history: RouterChildContext['router']['history'],
  error?: any
) => {
  const orderIsWaitingForPayment = order.order_status_code === ORDER_STATUS.WAITING_FOR_PAYMENT;
  const isPaymentFail = order.is_tpa_needed || order.payment_status !== PAYMENT_STATUS.SUCCESS;

  if (orderIsWaitingForPayment && isPaymentFail) {
    return history.replace(SCREENS.TRY_PAYMENT_AGAIN(id), { error });
  }

  if (order.dnf.enable) {
    return history.replace(SCREENS.DRIVER_SEARCH(id));
  }

  return history.replace(SCREENS.ORDER(id));
};

export default orderCreationRedirect;
