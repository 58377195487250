import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { deliveryStatusStore } from '../../../OrderCompletedScreen/components/DeliveryStatus/store';
import { tryPaymentAgainStore } from '../../store';
import { ORDER_STATUS } from '../../../../../constants/main';
import moment from 'moment';
import { observer } from 'mobx-react';
import './style.scss';
import { localeStore } from '../../../../../mobx/localesStore';
import { IOrder } from '../../../../../types/orders';

interface IProps {
  time: string;
}

const PaymentTimer: FC<{ time: any }> = observer(({ time }: IProps): JSX.Element | null => {
  const [currentTime, setCurrentTime] = useState<moment.Moment>(moment());
  const [loading, setLoading] = useState<boolean>(true);

  const serverDate: moment.Moment = moment.utc(time).utcOffset(3, true).add(15, 'm');
  const localDate: moment.LocaleSpecifier = moment(serverDate).utc().utcOffset(3).format();
  const timeBetween: moment.Duration = moment.duration(moment(localDate).diff(moment().local().utcOffset(3)));

  const interval: { current: NodeJS.Timeout | null } = useRef(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current as NodeJS.Timeout);
    }
    interval.current = setTimeout(() => {
      if (timeBetween.minutes() <= 0 && timeBetween.seconds() <= 0) {
        clearInterval(interval.current as NodeJS.Timeout);
        tryPaymentAgainStore.setTimeOut(true);
      } else {
        tryPaymentAgainStore.setTimeOut(false);
        setCurrentTime(moment());
      }
    }, 1000);
    setLoading(false);
    return () => {
      clearInterval(interval.current as NodeJS.Timeout);
      tryPaymentAgainStore.setTimeOut(false);
      setLoading(true);
    };
  }, [currentTime]);

  const isTimeOff = useMemo(() => {
    return timeBetween.minutes() <= 0 && timeBetween.seconds() <= 0;
  }, [timeBetween]);

  const getSeconds = useMemo(() => {
    return timeBetween.seconds() < 10 ? '0' + timeBetween.seconds() : timeBetween.seconds();
  }, [timeBetween]);

  const getMinutes = useMemo(() => {
    return timeBetween.minutes() < 10 ? '0' + timeBetween.minutes() : timeBetween.minutes();
  }, [timeBetween]);

  if (loading) {
    return null;
  }
  const { order_status_code, is_tpa_needed } = tryPaymentAgainStore.order as IOrder;

  if (order_status_code !== ORDER_STATUS.WAITING_FOR_PAYMENT || isTimeOff || !is_tpa_needed) {
    return null;
  }

  return (
    <div className={`tpa-time-left ${deliveryStatusStore.timeOut ? 'inactive' : ''}`}>
      {localeStore.t('time_left')}{' '}
      {isTimeOff || deliveryStatusStore.timeOut ? '00:00' : timeBetween.minutes() + ':' + getSeconds}
    </div>
  );
});

export default PaymentTimer;
