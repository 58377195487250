import { FC, ReactElement, memo } from 'react';
import { IOrderProduct } from 'src/types/orders';
import Accordion from 'src/view/components/Controls/Accordion/Accordion';
import { OrderProductDonation } from './components/OrderProductDonation/OrderProductDonation';
import { OrderProductNotes } from './components/OrderProductNotes/OrderProductNotes';
import { OrderProductPlate } from './components/OrderProductPlate/OrderProductPlate';
import { OrderProductExclusions } from './containers/OrderProductExclusions/OrderProductExclusions';
import { OrderProductItems } from './containers/OrderProductItems/OrderProductItems';
import { OrderProductModifiers } from './containers/OrderProductModifiers/OrderProductModifiers';
import './style.scss';

interface IProps {
  product: IOrderProduct;
}

export const OrderProduct: FC<IProps> = memo(({ product }: IProps): ReactElement => {
  return (
    <div className="order-product">
      <Accordion trigger={() => <OrderProductPlate product={product} />}>
        <div className="order-product__content">
          <OrderProductModifiers modifiers={product.modifiers} productQuantity={product.quantity} />
          <OrderProductExclusions exclusions={product.exclusions} />
          <OrderProductItems product={product} />
          <OrderProductNotes notes={product.notes} />
        </div>
      </Accordion>

      <OrderProductDonation isDonation={Boolean(product.is_donation)} />
    </div>
  );
});
