import { IProduct, IProductSize, IResponseProduct } from 'src/types/products';

export const getFirstCorrectMealSize = (product: IResponseProduct | IProduct): IProductSize | null => {
  const currentSizeIsCorrect: boolean = Boolean(
    product.items?.some((item) => item.size_id === (product.size as IProductSize)?.id)
  );

  if (currentSizeIsCorrect) return product.size as IProductSize;
  if (!product.sizes.length) return null;

  return product.sizes.find((size) => product.items?.some((item) => item.size_id === size.id)) || null;
};
