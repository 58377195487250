import axios, { Canceler } from 'axios';
import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { CashbackErrorCode, ERRORS } from 'src/constants/errors';
import { DESTINATION_LOGS, EVENTS } from 'src/constants/events';
import { E_MENU_TYPE, PAYMENT_TYPE_NAMES, PAYMENT_TYPES } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { requests } from 'src/requests';
import user from 'src/requests/user';
import client from 'src/services/client';
import history from 'src/services/history';
import logger from 'src/services/logger';
import { IPaymentTypeId } from 'src/types/app';
import { IDineInError, IDineInItem } from 'src/types/branch';
import { ICartCheckout, ICartTotal, ICheckoutCurbside, ICheckoutDataPay, ICheckoutModals } from 'src/types/cart';
import { IError, IErrorOption } from 'src/types/main';
import { IProduct } from 'src/types/products';
import { IPaymentCard } from 'src/types/user';
import { checkApplePayAvailable } from 'src/utils/ApplePay';
import { getTranslate } from 'src/utils/formatter';
import { getNearestWorkingTimeMessage, isCashbackErrorCode, isObject, logError } from 'src/utils/helpers';
import { hash } from 'src/utils/helpers/hash';
import { debounce } from 'src/utils/perfomance';
import { productUtils } from 'src/utils/productUtils';
import { DELIVERY_TYPES } from '../../constants/deliveryTypes';
import { appStore } from '../appStore';
import { branchStore } from '../barnchStore';
import { addressStore } from '../barnchStore/address';
import { checkoutStore } from '../checkoutStore';
import { localeStore } from '../localesStore';
import { preorderStore } from '../preorderStore';
import { userStore } from '../userStore';
import { cartStore } from './index';
import { CHECKOUT_HANDLERS, RESPONSE_ERROR_HANDLER } from './validation';
import { IOrder } from '../../types/orders';
import { applePaySession } from '../../services/applePay';

export const defaultCartCheckout: ICartCheckout = {
  loading: false,
  promocode: '',
  payment_type: 0,
  is_curbside: false,
  hash: hash(`${Date.now()}`),
  is_donation: false,
  is_donation_only: false,
  curbside: {
    car_brand: '',
    car_color: '',
    car_type: '',
    car_number: '',
  },
  is_order_for_someone_else: false,
  should_contact_by_driver: false,
  stranger_customer_info: {
    stranger_id: null,
    mobile: '',
    name: '',
  },
  card: undefined,
  selected_card: null,
  use_wallet: 0,

  modals: {}, // for call modals from any place
  cashback_warnings_as_errors: 1,
};

export const defaultCalculator: ICartTotal = {
  loading: false,
  total: 0,
  subtotal: 0,
  earn_points: 0,
  redeem_points: 0,
  discount_amount: 0,
  discount_error: undefined,
  discounted_total: 0,
  discounted_subtotal: 0,
  left_to_free_delivery: 0,
  is_free_delivery: 0,
  delivery_type: 0,
  taxable_delivery_fee: 0,
  min_order: 0,
  free_delivery: 0,
  left_to_min_order: 0,
  max_cash_payment: 0,
  delivery_fee: 0,
  vat: 0,
  promocode: '',
  available_wallet_discount: 0,
  wallet_discount: 0,
  cashback_amount: 0,
  promo_code: {
    id: 0,
    name: '',
    amount: 0,
    expired_at: '',
    type: 'amount_based',
  },
  cashback: {
    id: 0,
    name: undefined,
    description: undefined,
    amount: 0,
    percent: 0,
    maximum: 0,
    warning: null,
    warning_code: null,
  },
  products: [],
  previous_branch_id: 0,
  next_reduced_delivery_fee: null,
  left_to_next_reduced_delivery_fee: null,
};
export class CartCheckout {
  cancel: Canceler | null = null;
  @observable public isCheckout: boolean = false;
  @observable public applePaymentSource: ICheckoutDataPay['payment_source'] | null = null;
  @observable public errors: IError[] = [];
  @observable public checkout: ICartCheckout = defaultCartCheckout;
  @persist('object') @observable public calculator: ICartTotal = defaultCalculator;
  @persist('list') @observable public products: IProduct[] = [];
  @persist @observable public notes: string = '';
  @persist @observable public cartHash: string = '';

  @observable cashbackError: CashbackErrorCode | null = null;

  @computed get areAllProductsUnavailable() {
    return this.products.every((i) => !i.orderable);
  }

  // payment type
  @action public selectPaymentType = (payment_type: IPaymentTypeId | 4) => {
    if (payment_type === this.checkout.payment_type) return;

    logger.logEvent(EVENTS.CHECKOUT_PAYMENT_TYPE_CHANGED, {
      method_from: cartStore.checkout.payment_type,
      method_to: payment_type,
    });

    logger.logEvent(
      EVENTS.CHANGE_PAYMENT_METHOD,
      {
        method_id: payment_type,
        method_name: PAYMENT_TYPE_NAMES[payment_type],
      },
      DESTINATION_LOGS.MOENGAGE
    );

    this.checkout.payment_type = payment_type;

    this.recalculate();
  };

  // modals

  /**
   * Use this methor for open or close modal
   *
   * @template K
   * @param {K} key - modal name
   * @param {ICheckoutModals[K]} value - value for modal (look at the interface)
   * @memberof CartCheckout
   */
  @action
  public setModalValue<K extends keyof ICheckoutModals>(key: K, value: ICheckoutModals[K]) {
    this.checkout.modals[key] = value;
  }

  // toggle curbside
  @action public toggleCurbside = () => {
    this.checkout.is_curbside = !this.checkout.is_curbside;
    if (this.calculator.promocode) this.calculate();
  };

  // toggle wallet
  @action public setCheckoutWallet = (use_wallet: number) => {
    this.checkout.use_wallet = use_wallet;
    this.checkout.promocode = '';
    this.recalculate();
  };

  @action public setCurbsideValue = (name: keyof ICheckoutCurbside, value: string) => {
    this.checkout.curbside[name] = value;
    userStore.user.curbside[name] = value;
  };

  @action public setCurbside = (curbside: ICheckoutCurbside) => {
    this.checkout.curbside = curbside;
  };

  // toggle someone
  @action public toggleSomeone = (is_order_for_someone_else?: boolean) => {
    if (typeof is_order_for_someone_else === 'boolean') {
      this.checkout.is_order_for_someone_else = is_order_for_someone_else;
      return;
    }
    this.checkout.is_order_for_someone_else = !this.checkout.is_order_for_someone_else;
  };

  @action public setSomeoneValue = (name: 'name' | 'mobile', value: string) => {
    this.checkout.stranger_customer_info[name] = value;
  };

  @action public setSomeone = (stranger_customer_info: ICartCheckout['stranger_customer_info']) => {
    this.checkout.stranger_customer_info = stranger_customer_info;
  };

  // promocode
  @action public selectPromocode = (promocode: string) => {
    this.checkout.promocode = promocode;
    this.recalculate();
  };

  @action public clearPromocode = () => {
    this.checkout.promocode = '';
    this.calculator.promocode = '';
    this.recalculate();
  };

  // payment cardcon
  @action public clearPaymentCard = () => {
    this.checkout.selected_card = 0;
    this.checkout.card = undefined;
    this.recalculate();
  };

  // delete credit card
  @action public deleteCreditCard = async (card: IPaymentCard) => {
    return requests.deleteCreditCard(card.id);
  };

  @action public selectPaymentCard = (card: IPaymentCard) => {
    this.checkout.selected_card = card.id;
    this.checkout.card = undefined;
    this.recalculate();
  };

  @action public addPaymentCard = async (card: ICartCheckout['card'], branch_id?: number) => {
    try {
      const response = await user.addCrediCard(card, branch_id);
      // update payment card list
      await userStore.fetchUserCards();

      this.selectPaymentCard(response);
      // this.checkout.selected_card = response?.id;
      // close paymentCard modal
      cartStore.setModalValue('paymentCard', null);
    } catch (error: any) {
      logError(error);
      const message = error?.response?.data || error?.message;

      if (message) {
        // open error modal
        cartStore.setModalValue(
          'error',
          this.createError(localeStore.t(!isObject(message) ? message : message.message), '', [
            {
              className: 'background-light',
              textClassName: 'color-dark',
              text: localeStore.t('Close'),
              onPress: () => {
                cartStore.setModalValue('paymentCard', { loading: false });
                cartStore.setModalValue('error', null);
              },
            },
          ])
        );
      }
    }
  };

  // dine in
  @action public setSection = (section: IDineInItem | null) => {
    this.checkout.dine_in = { section, table: null };
    this.checkout.dine_in_error = { ...this.checkout.dine_in_error, section_error: false };
  };
  @action public setTable = (table: IDineInItem | null) => {
    this.checkout.dine_in = { section: this.checkout.dine_in?.section || null, table };
    this.checkout.dine_in_error = { ...this.checkout.dine_in_error, table_error: false };
  };
  @action public setDineInError = ({ section_error, table_error }: IDineInError) => {
    this.checkout.dine_in_error = { section_error, table_error };
  };

  // calculate
  @action public calculate = async (
    wasBranchChanged: boolean = false,
    prevBranchId: number | undefined = undefined,
    prevOrderType?: string,
    keepCashbackError?: boolean
  ) => {
    if (this.cancel) {
      this.cancel();
    }

    try {
      this.calculator.loading = true;
      const data: any = {
        use_wallet: cartStore.checkout.use_wallet,
        delivery_type: branchStore.delivery_type,
        // DO NOT PUT PROMOCODE IF PAYMENT TYPE IS POINTS OR WALLET IS ACTIVE
        promocode:
          this.checkout.payment_type === PAYMENT_TYPES.POINTS || cartStore.checkout.use_wallet
            ? ''
            : this.checkout.promocode,
        payment_type: this.checkout.payment_type,
        branch_id: branchStore.branch.id,
        products: this.products.map(productUtils.transformProductForCalculator),
      };

      // send the coordinates of the user's saved address if it is not on the sms page
      if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY && !cartStore.isSMS) {
        data.latitude = addressStore.address?.latitude;
        data.longitude = addressStore.address?.longitude;
        // if the type of delivery is delivery and there are selected coordinates and this is SMS
      } else if (
        branchStore.delivery_type === DELIVERY_TYPES.DELIVERY &&
        cartStore.isSMS &&
        branchStore.selectMapCoordinate
      ) {
        data.latitude = branchStore.selectMapCoordinate?.latitude;
        data.longitude = branchStore.selectMapCoordinate?.longitude;
        // if the type of delivery is delivery and there are reorder data and this is SMS
      } else if (
        branchStore.delivery_type === DELIVERY_TYPES.DELIVERY &&
        cartStore.isSMS &&
        checkoutStore.reorderStore
      ) {
        data.latitude = checkoutStore.reorderStore?.area?.latitude;
        data.longitude = checkoutStore.reorderStore?.area?.longitude;
      } else {
        if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY) {
          if (branchStore.selectMapCoordinate?.latitude) {
            data.latitude = branchStore.selectMapCoordinate.latitude;
            data.longitude = branchStore.selectMapCoordinate.longitude;
          } else if (checkoutStore.reorderStore?.address_id) {
            data.address_id = checkoutStore.reorderStore?.address_id;
          }
        } else {
          // // send the coordinates from branches if user on the sms pages
          data.latitude = branchStore.branch.latitude;
          data.longitude = branchStore.branch.longitude;
        }
      }

      // TK-3307 TOFIX
      // if (this.isCheckout || cartStore.isCart) {
      data.return_items = true;
      // }
      // if preorder exist
      if (preorderStore.preorder_unix) {
        // send unix in seconds
        data.time = preorderStore.preorder_unix;
      }

      // dine in
      if (branchStore.order_type === E_MENU_TYPE.DINE_IN) {
        data.delivery_type = DELIVERY_TYPES.DINE_IN;
      }

      if (branchStore.branch.emenu) {
        data.dinein_delivery_type = branchStore.order_type;
      }

      if (this.checkout?.is_curbside) {
        data.is_curbside = 1;
      }
      if (cartStore.isSMS) {
        data.order_id = checkoutStore.reorderStore.order.id;
      }
      if (
        wasBranchChanged &&
        (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY || !branchStore.branch.is_curbside)
      ) {
        this.checkout.is_curbside = false;
      }
      if (cartStore.isSMS && checkoutStore.reorderStore.order?.promocode) {
        data.promocode = checkoutStore.reorderStore.order?.promocode;
      }

      if (typeof this.calculator.cashback.id === 'number') {
        data.previous_calculation = {
          branch_id: prevBranchId,
          order_type: prevOrderType,
          cashback_id: this.calculator.cashback.id,
          cashback_amount: this.calculator.cashback.amount,
        };
      }

      const response = await client.post('/v3/orders/calculate', data, {
        cancelToken: new axios.CancelToken((c) => (this.cancel = c)),
      });

      // Error Handler for discount (cart, checkout)
      if (response && response.discount_error) this.handleError(response.discount_error);

      /** Navigate user to CartScreen if total was changed after changing branch from CheckoutScreen */
      if (
        this.isCheckout &&
        this.calculator.total !== response.total &&
        this.calculator.wallet_discount === response.wallet_discount
      ) {
        this.isCheckout = false;
      }

      if (data.promocode && !response.discount_error) {
        logger.logEvent(EVENTS.CHECKOUT_PROMOCODE_APPLY_SUCCESS, {
          promocode: data.promocode,
          promocode_discount: response.promocode_amount,
        });

        logger.logEvent(
          EVENTS.PROMO_CODE_CHOSEN,
          {
            promocode_id: response.promo_code.id,
            promocode_amount: response.promo_code.amount,
            promocode_type: response.promo_code.type,
            expired_date: response.promo_code.expired_at ? new Date(response.promo_code.expired_at) : null,
            customer_id: userStore.user?.id,
          },
          DESTINATION_LOGS.MOENGAGE
        );
      }
      if (response.use_wallet) {
        // MOENGAGE
        logger.logEvent(
          EVENTS.WALLET_CHOSEN,
          {
            wallet_amount: userStore.user?.wallet?.balance,
            customer_id: userStore.user?.id,
            expired_date: userStore.user?.wallet?.expire_date ? new Date(userStore.user?.wallet?.expire_date) : null,
          },
          DESTINATION_LOGS.MOENGAGE
        );
      }

      if (response.products) {
        const { updatedProducts, haveErrors } = productUtils.transformProductsFromCalculator(
          cartStore.products, // local cart products
          response.products // remote calculator products
        );

        cartStore.products = updatedProducts;

        // if items have errors or min_order validation fails - redirect to cart
        if (
          this.isCheckout &&
          ((wasBranchChanged && haveErrors) ||
            (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY &&
              !cartStore.calculator.promocode &&
              response.left_to_min_order))
        ) {
          this.isCheckout = false;
          this.calculate(wasBranchChanged, prevBranchId, prevOrderType);
          this.cashbackError = null;
        }
      }

      if (isCashbackErrorCode(response?.cashback?.warning_code) && this.isCheckout) {
        // If cashback just appeared, don't show ChangedConditions warning
        this.cashbackError =
          ((!this.calculator.cashback?.id && response?.cashback?.id) ||
            (this.calculator.cashback?.id && this.calculator.cashback.amount === response.cashback.amount)) &&
          response.cashback.warning_code === CashbackErrorCode.ChangedConditions
            ? null
            : (response.cashback.warning_code as CashbackErrorCode);
        // If previous error was Unavailable - user can clear by himself
      } else if (
        this.cashbackError &&
        this.cashbackError === CashbackErrorCode.ChangedConditions &&
        !keepCashbackError
      ) {
        this.cashbackError = null;
      }

      this.calculator = response;
      this.calculator.loading = false;
    } catch (error: any) {
      if (axios.isCancel(error)) return;

      if (this.isCheckout) {
        cartStore.setModalValue(
          'error',
          this.createError(error.response.data.message, '', [
            {
              className: 'background-light',
              textClassName: 'color-dark',
              text: localeStore.t('Close'),
            },
          ])
        );
      }
      this.calculator = defaultCalculator;
      this.calculator.loading = false;
      logError(error);
    }
  };

  @action disableCashbackErrors = () => {
    this.checkout.cashback_warnings_as_errors = 0;
  };

  handleError = (error: any) => {
    const error_code = error.errorCode;
    switch (true) {
      case Boolean(ERRORS[error_code]):
        this.errors.push({ title: '', message: error.error_promo, options: [] });
        break;

      default:
        break;
    }
  };

  /**
   * Is payment type available
   *
   * @param {(IPaymentTypeId | 4)} payment_type
   * @memberof CartCheckout
   */
  isAvailablePayment = (payment_type: number) => {
    return branchStore.getPaymentTypes.some((type) => type.id === payment_type);
  };

  openCheckout = async () => {
    const { default_payment_type, pre_select_default } = branchStore.branch;

    this.isCheckout = true;

    // Open modal windows that were initialized before opening checkout
    const modals = this.checkout.modals;
    this.checkout.modals = {};
    setTimeout(() => {
      this.checkout.modals = modals;
    }, 300);

    logger.logEvent(EVENTS.APP_PAGE_CHANGE);
    // this.checkout = defaultCartCheckout;

    if (!this.cartHash) this.cartHash = `${Date.now()}-cartHash`;
    this.checkout.hash = this.cartHash;

    if (!userStore.user.curbside) {
      userStore.user.curbside = defaultCartCheckout.curbside;
    }

    if (userStore.isLogin) {
      userStore.fetchUserCards();
    }

    this.checkout.curbside = userStore.user.curbside;

    this.checkout.is_donation_only = this.products.every((product) => product.is_donation);
    this.checkout.is_donation = this.products.some((product) => product.is_donation);

    let payment_type: IPaymentTypeId | 4 = 0;
    const applePayAvailable = await checkApplePayAvailable();

    // Select online payment type if it is donation
    if (this.checkout.is_donation) {
      payment_type = PAYMENT_TYPES.ONLINE_PAYMENT;
      // Select default payment type if preselected is enabled and it is available
    } else if (pre_select_default && this.isAvailablePayment(default_payment_type)) {
      payment_type = default_payment_type;
      // Select the last user payment type if it exists and is available
    } else if (userStore.user.last_payment_type && this.isAvailablePayment(userStore.user.last_payment_type)) {
      payment_type = userStore.user.last_payment_type;
      // Select default payment type if it is enabled
    } else if (default_payment_type && this.isAvailablePayment(default_payment_type)) {
      payment_type = default_payment_type;
      // Select first available payment type
    } else if (branchStore.getPaymentTypes[0]) {
      payment_type = branchStore.getPaymentTypes[0].id;
    }

    /**
     * Set default payment methods for sms pages
     */
    if (cartStore.isSMS && branchStore.branch.id === branchStore.defaultBranchId) {
      payment_type = branchStore.defaultBranchPaymentMethods;
    }

    if (payment_type === PAYMENT_TYPES.ONLINE_PAYMENT && applePayAvailable && !userStore.user.last_payment_card) {
      payment_type = PAYMENT_TYPES.APPLE_PAY;
    }

    this.selectPaymentType(payment_type);

    logger.logEvent(EVENTS.CHECKOUT_OPENED, {
      coupon: cartStore.checkout.promocode,
      items: cartStore.products,
      total: cartStore.calculator.total,
    });
    logger.logEvent(
      EVENTS.BEGIN_CHECKOUT,
      {
        user_id: userStore.user?.id,
        checkout_action: 'click',
      },
      DESTINATION_LOGS.MOENGAGE
    );
    // this.recalculate();
  };

  closeCheckout = () => {
    this.isCheckout = false;
    const _checkout = { ...defaultCartCheckout };
    this.checkout = _checkout;
    this.recalculate();
    logger.logEvent(EVENTS.APP_PAGE_CHANGE);
  };

  private calculationHandler = (handler: () => void) => {
    return async (...args: any) => {
      if (this.cancel) this.cancel();
      await this.calculate(...args);
      handler();
    };
  };

  public recalculate = debounce((wasBranchChanged: boolean = false) => {
    // Cancel prev calculate
    if (this.cancel) this.cancel();
    this.calculate(wasBranchChanged, branchStore.branch.id, branchStore.delivery_type);
  }, 300);

  public createError = (title: string, message: string, options: IErrorOption[]): IError => {
    return {
      title,
      message,
      options,
    };
  };

  public onCheckoutSubmit = async () => {
    if (!userStore.isLogin) {
      cartStore.setModalValue('login', true);
      return;
    }

    if (
      branchStore.delivery_type === DELIVERY_TYPES.PICKUP &&
      this.checkout.payment_type === PAYMENT_TYPES.CASH &&
      branchStore.order_type !== E_MENU_TYPE.DINE_IN
    ) {
      cartStore.setModalValue('confirmPickUp', true);
      return;
    }
    await this.placeOrder();
  };

  public getApplePaySession = async () => {
    if (cartStore.checkout.payment_type === PAYMENT_TYPES.APPLE_PAY) {
      const dataForApplePaySession = { ...cartStore.checkoutData, ...cartStore.calculator };
      // TODO: remove this logic after refactoring the payment on the SMS page
      let applePayData;
      if (cartStore.isSMS) {
        applePayData = await applePaySession(dataForApplePaySession);
        if (!applePayData) {
          cartStore.setLoading(false);
          return false;
        }
        cartStore.checkout.apple_pay = await applePayData;
      } else {
        applePayData = await applePaySession(dataForApplePaySession);
        if (!applePayData) {
          cartStore.setLoading(false);
          return false;
        }
        this.applePaymentSource = applePayData;
      }
      return true;
    }
    return true;
  };

  public placeOrder = async () => {
    cartStore.setLoading(true);

    const allowNextStep = await this.getApplePaySession();
    if (!allowNextStep) {
      return cartStore.setLoading(false);
    }
    const response = await this.submit();
    // Stop loading if submit is not successful
    if (!response) cartStore.setLoading(false);
  };

  public submit = async () => {
    if (branchStore.order_type === E_MENU_TYPE.DINE_IN && branchStore.branch.emenu?.sections?.length) {
      const { section, table } = cartStore.checkout.dine_in || {};

      let section_error;
      let table_error;
      if (!section) section_error = true;
      if (!table) table_error = true;
      cartStore.setDineInError({ section_error, table_error });
      if (section_error || table_error) {
        document.getElementById('checkout_order_type')?.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }

    const error = await this.validate();
    logError(error);
    if (error && typeof error !== 'boolean') {
      CHECKOUT_HANDLERS.MESSAGE_EVENT(error);
      return;
    }
    if (error) return;

    if (
      !cartStore.calculator.promocode ||
      (cartStore.calculator.promocode && cartStore.calculator.discounted_total !== 0)
    ) {
      if (
        cartStore.checkout.payment_type === PAYMENT_TYPES.ONLINE_PAYMENT &&
        !cartStore.checkout.selected_card &&
        !cartStore.checkout.card &&
        cartStore.calculator.total
      ) {
        cartStore.setModalValue('paymentCard', { loading: false });
        return;
      }
    }
    if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY) {
      const query = {
        latitude: addressStore.address?.latitude,
        longitude: addressStore.address?.longitude,
        delivery_type: branchStore.delivery_type,
        without_area: 1,
      };
      if (!appStore?.settings?.donation?.enabled && branchStore.branch.branchCovered) {
        // @ts-ignore
        delete query.without_area;
      }

      const response = await requests.branches.branchSearch(query);
      if (!branchStore.branch.id && branchStore.branch.branchCovered) {
        cartStore.setModalValue('errorBranch', response?.message);
        return;
      }
    }
    this._createOrder();
    return true;
  };

  clearCart = () => {
    cartStore.clear();
    cartStore.closeCart();
    cartStore.closeCheckout();
    cartStore.setLoading(false);
  };

  _createOrder = async () => {
    if (cartStore.checkout.curbside) {
      logger.logEvent(
        EVENTS.ADD_SHIPPING_INFO,
        {
          delivery_type_delivery: branchStore.delivery_type,
          licence_plate_number: cartStore.checkout.curbside.car_number,
        },
        DESTINATION_LOGS.MOENGAGE
      );
    }

    let craeteOrderResponse: any = undefined;
    try {
      const isOrderCreatedId = await cartStore.validateCartHash();
      if (isOrderCreatedId) {
        return history.push({ pathname: SCREENS.ORDER_COMPLETED(isOrderCreatedId) });
      }

      craeteOrderResponse = await cartStore.createOrder();
      userStore.fetchUser();
    } catch (error: any) {
      logError(error);

      const { response } = error;

      if (isCashbackErrorCode(response?.data?.code)) {
        this.cashbackError = (response.data.code as CashbackErrorCode) || null;
        this.calculate(false, branchStore.branch.id, branchStore.delivery_type, false);
      } else {
        RESPONSE_ERROR_HANDLER(error?.response);
      }
    }

    if (!craeteOrderResponse) {
      return;
    }

    try {
      // TODO: remove this logic after refactoring the payment on the SMS page
      if (cartStore.isSMS) {
        // Old Logic
        if (craeteOrderResponse.online_pay?._3ds && craeteOrderResponse.online_pay?.url) {
          window.location.href = craeteOrderResponse.online_pay.url;
        } else {
          if (cartStore.isSMS) {
            appStore.clearSmsPageSession();
          }
          history.push({
            pathname: SCREENS.ORDER_COMPLETED(craeteOrderResponse.id),
            state: { order: craeteOrderResponse },
          });
        }
      } else {
        // New logic
        if (craeteOrderResponse.status === 201) {
          const orderPay = await client.post(
            `/v4/orders/${craeteOrderResponse.data}/pay`,
            cartStore.payData,
            undefined,
            true
          );
          if (orderPay?.data?.link_3ds) {
            window.location.href = orderPay.data.link_3ds;
          } else {
            history.push(SCREENS.ORDER_ACTUALIZE(craeteOrderResponse.data));
          }
        } else {
          history.push(SCREENS.ORDER_ACTUALIZE(craeteOrderResponse.data));
        }
      }
    } catch (error: any) {
      const { response } = error;

      let failureMessage;
      let statusCode;

      if (response) {
        failureMessage = response?.data.message;
        statusCode = response?.status;
      } else {
        failureMessage = error.message;
        statusCode = null;
      }

      logger.logEvent(
        EVENTS.PURCHASE_FAILED,
        {
          failure_message: failureMessage,
          status_code: statusCode,
        },
        DESTINATION_LOGS.MOENGAGE
      );

      if (response?.status === 400 && !isCashbackErrorCode(response?.data?.code)) {
        const parsed = error?.response.request.responseURL.split('/');
        const id = Number(parsed[parsed.length - 2]) as IOrder['id'];
        history.push(SCREENS.ORDER_ACTUALIZE(id));
      }
    } finally {
      this.clearCart();
    }
  };

  private validate = (): IError | null | boolean => {
    // Restaurant is closed
    if (!appStore.settings?.is_restaurant_working) {
      return this.createError(localeStore.t('txt_closed'), localeStore.t('the_restaurants_is_closed_now'), [
        {
          text: localeStore.t('Close'),
          className: 'background-light',
          textClassName: 'color-dark',
        },
      ]);
    }

    // Branch is not selected
    if (!branchStore.branch.id) {
      let message = '';
      let delivery_type = DELIVERY_TYPES.BOTH;

      switch (appStore.settings?.delivery_type) {
        case DELIVERY_TYPES.BOTH:
          message = localeStore.t('please_specific_your_address_or_select_pickup');
          break;
        case DELIVERY_TYPES.PICKUP:
          delivery_type = DELIVERY_TYPES.PICKUP;
          message = localeStore.t('please_select_pickup_branch');
          break;
        case DELIVERY_TYPES.DELIVERY:
          delivery_type = DELIVERY_TYPES.DELIVERY;
          message = localeStore.t('please_specific_your_address');
          break;
        default:
          message = localeStore.t('please_select_pickup_branch');
          break;
      }

      return this.createError('', message, [
        {
          text: localeStore.t('txt_change'),

          onPress: () => {
            cartStore.closeCart();
            cartStore.closeCheckout();
            history.push(SCREENS.BRANCHES(delivery_type));
          },
        },
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('Close'),
        },
      ]);
    }
    // Branch is busy and preorder time is not selected
    if (branchStore.branch.is_busy) {
      if (branchStore.branch.preorder) {
        if (!preorderStore.preorder_unix || !preorderStore.isTimeConfirmed) {
          this.setModalValue('preorder', { visible: true, message: 'busy_preorder' });
          return true;
        }
      } else {
        return this.createError(
          localeStore.t('branch_busy_status'),
          localeStore.t('menu_busy_notification', { name: getTranslate(branchStore.branch.name) }),
          [
            {
              text: localeStore.t('txt_ok'),
              className: 'background-light',
              textClassName: 'color-dark',
            },
          ]
        );
      }
    }

    // if someone else phone is not specified
    if (cartStore.checkout.is_order_for_someone_else) {
      if (
        typeof cartStore.checkout.stranger_customer_info.mobile !== 'string' ||
        cartStore.checkout.stranger_customer_info.mobile.length < 9 ||
        cartStore.checkout.stranger_customer_info.mobile.length > 14
      ) {
        return this.createError(localeStore.t('mobile_required'), '', [
          {
            text: localeStore.t('txt_ok'),
            className: 'background-light',
            textClassName: 'color-dark',
          },
        ]);
      }
    }
    const handleClick = () => {
      cartStore.closeCart();
      cartStore.closeCheckout();

      /**
       * Show simple map if user on SMS pages
       */
      if (cartStore.isSMS) {
        branchStore.openBranches();
      } else {
        history.push(SCREENS.BRANCHES(appStore.settings?.delivery_type));
      }
    };

    // if branch is not working now and preorder is not available or branch is closed
    if (
      (!cartStore.calculator.branch?.is_working_at_specified_time &&
        !branchStore.branch.preorder &&
        !cartStore.checkout.is_donation) ||
      branchStore.branch.status === 0
    ) {
      /**
       * If the branch does not work and the user is on the smsScreen - show a simple warning,
       * no information about the change to the deliever brunch
       */
      const title = cartStore.isSMS ? 'sms_branch_is_closed_now' : 'branch_is_closed_now';

      return this.createError('', localeStore.t(title), [
        {
          text: localeStore.t('txt_change'),
          onPress: () => handleClick(),
        },
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('Close'),
        },
      ]);
    }
    // if branch not closed and not working now and preorder available and preorder is not selected
    if (
      // branch not working now
      !cartStore.calculator.branch?.is_working_at_specified_time &&
      // preorder available
      branchStore.branch.preorder &&
      // branch active
      branchStore.branch.status === 1 &&
      // preorder not selected
      !preorderStore.preorder_unix
    ) {
      let message = localeStore.t('no_working_hr');
      const isWorkingTime = Array.isArray(branchStore.branch.working_time) && branchStore.branch.working_time.length;

      const options: IErrorOption[] = preorderStore.preorderIsAvailable
        ? [
            {
              text: localeStore.t('txt_ok'),
              onPress: () => {
                this.submit();
              },
            },
            {
              text: localeStore.t('txt_change'),
              className: 'background-light',
              textClassName: 'color-dark',
              onPress: () => {
                cartStore.closeCart();
                cartStore.closeCheckout();
                appStore.handleModal('preorder', true);
              },
            },
          ]
        : [
            {
              text: localeStore.t('txt_ok'),
              className: 'background-light',
              textClassName: 'color-dark',
              onPress: () => cartStore.setModalValue('error', null),
            },
          ];

      if (isWorkingTime) {
        message = getNearestWorkingTimeMessage();
      }

      const error = localeStore.t(
        preorderStore.preorderIsAvailable
          ? 'branch_is_closed_now_select_preorder_time'
          : 'the_restaurants_is_closed_now'
      );
      return this.createError(error, message, options);
    }

    // if branch changed delivery type and not supported current anymore
    if (
      // delivery type not both
      branchStore.branch.delivery_type !== DELIVERY_TYPES.BOTH &&
      // selected delivery type is different to branch delivery type
      branchStore.delivery_type !== branchStore.branch.delivery_type
    ) {
      let message;
      if (cartStore.isSMS) {
        message = 'sms_branch_is_closed_now';
      } else {
        message =
          branchStore.delivery_type === DELIVERY_TYPES.DELIVERY
            ? localeStore.t('delivery_currently_not_available')
            : localeStore.t('pickup_is_currently_not_available');
      }

      return this.createError('', message, [
        {
          text: localeStore.t('txt_change'),
          onPress: () => handleClick(),
        },
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('Close'),
        },
      ]);
    }

    if (cartStore.checkout.is_curbside) {
      if (
        !cartStore.checkout.curbside.car_brand ||
        !cartStore.checkout.curbside.car_color ||
        !cartStore.checkout.curbside.car_number ||
        !cartStore.checkout.curbside.car_type
      ) {
        return this.createError(localeStore.t('You must enter all fields for curbside'), '', [
          {
            text: localeStore.t('txt_ok'),
            className: 'background-light',
            textClassName: 'color-dark',
          },
        ]);
      }
    }

    return null;
  };
}
