import { action, observable } from 'mobx';
import moment, { Moment } from 'moment';
import { TimeController } from 'src/controllers/TimeController/TimeController';
import { IBranch } from 'src/types/branch';
import { ITimeSlot, ITimeSlotHour } from 'src/types/time';
import { formatHoursToTimes } from 'src/utils/formatter';

export class PreorderPickerStore {
  /**
   * list of available months
   *
   * @type {TPreOrderMonth[]}
   * @memberof PreOrderScreenStore
   */
  @observable public months: ITimeSlot[] = [];
  /**
   * list of available dates
   *
   * @type {TPreOrderDay[]}
   * @memberof PreOrderScreenStore
   */
  @observable public dates: ITimeSlot[] = [];

  /**
   * selected hours index
   *
   * @type {ITimeSlotHour}
   * @memberof PreOrderScreenStore
   */
  @observable public times: ITimeSlot[] = [];
  /**
   * selected date index
   *
   * @type {number}
   * @memberof PreOrderScreenStore
   */
  @observable date: number = 0;
  /**
   * selected time index
   *
   * Hour + minute
   * @type {number}
   * @memberof PreOrderScreenStore
   */
  @observable time: number = 0;
  /**
   * selected month index
   *
   * @type {number}
   * @memberof PreOrderScreenStore
   */
  @observable month: number = 0;

  @observable branch: IBranch | null = null;

  @observable delivery_type?: string;

  /**
   * selected time in picker
   * @computed
   *
   * @readonly
   * @type {(keyof ITimeSlotHour | undefined)}
   * @memberof PreOrderScreenStore
   */
  get selectedTime(): ITimeSlot | undefined {
    return this.times[this.time];
  }

  /**
   * selected date in picker
   * @computed
   *
   * @readonly
   * @type {(TPreOrderDay | undefined)}
   * @memberof PreOrderScreenStore
   */
  get selectedDate(): ITimeSlot | undefined {
    return this.dates[this.date];
  }

  /**
   * selected month in picker
   * @computed
   * @readonly
   * @type {(TPreOrderMonth | undefined)}
   * @memberof PreOrderScreenStore
   */
  get selectedMonth(): ITimeSlot | undefined {
    if (this.months.length) return this.months?.[this.month];
    return undefined;
  }

  /**
   * selected month in picker
   *
   * @readonly
   * @type {(TPreOrderMonth | undefined)}
   * @memberof PreOrderScreenStore
   */
  get selectedDateTime(): Moment | undefined {
    if (!this.selectedDate || !this.selectedTime) {
      return undefined;
    }
    return moment()
      .year(this.selectedMonth ? this.months[this.month].moment.year() : this.selectedDate?.moment.year())
      .month(this.selectedDate?.moment.month())
      .date(this.selectedDate?.moment.date())
      .hours(this.selectedTime?.moment.hour())
      .minutes(this.selectedTime?.moment.minutes());
  }

  prepare({ branch, delivery_type, time }: { branch: IBranch; delivery_type?: string; time: number | undefined }) {
    const selected = time ? moment(time * 1000) : moment();

    this.branch = branch;
    this.delivery_type = delivery_type;

    if (!branch?.preparation) {
      return;
    }

    const times = new TimeController({
      branch: this.branch,
      date: undefined,
      deliveryType: delivery_type,
      month: undefined,
      year: undefined,
    });

    this.months = times.months.length > 1 ? times.months : [];
    this.dates = times.dates;
    this.times = formatHoursToTimes(times.hours);

    if (!this.months.length || !this.months[this.month]) {
      this.selectMonth(0);
    }
    if (time) {
      this.defineDate(selected);
    }
  }

  /**
   * action to set selected month index
   *
   * @param {number} index
   * @memberof PreOrderScreenStore
   */
  selectMonth = (monthIndex: number, byDay?: boolean) => {
    this.month = monthIndex;

    if (!byDay) {
      const dayIndex = this.dates.findIndex(
        (day) =>
          day.moment.isSame(this.selectedMonth?.moment, 'month') &&
          day.moment.isSame(this.selectedMonth?.moment, 'year')
      );

      if (dayIndex !== -1) {
        this.selectDay(dayIndex, true);
      }
    }
  };

  /**
   * action to set selected day index
   *
   * @param {number} index
   * @memberof PreOrderScreenStore
   */
  selectDay = (dayIndex: number, byMonth?: boolean) => {
    this.date = dayIndex;

    const { hours } = new TimeController({
      branch: this.branch,
      date: this.selectedDate?.moment.date(),
      deliveryType: this.delivery_type,
      month: this.selectedDate?.moment.month(),
      year: this.selectedDate?.moment.year(),
    });

    if (this.selectedDate && this.branch) {
      this.times = formatHoursToTimes(hours);

      if (!byMonth && this.months.length > 0) {
        const monthByDate = this.selectedDate.moment.month();
        const yearByDate = this.selectedDate.moment.year();

        const monthIndex = this.months.findIndex(
          (month) => month.moment.month() === monthByDate && month.moment.year() === yearByDate
        );

        if (monthIndex !== -1) {
          setTimeout(() => {
            this.selectMonth(monthIndex, true);
          }, 500);
        }
      }
    } else {
      this.times = [];
    }

    this.selectTime(0);
  };

  /**
   * action to set selected hour index
   *
   * @param {number} index
   * @memberof PreOrderScreenStore
   */
  selectTime = (index: number) => {
    this.time = index;
  };

  defineDate = (selected: Moment) => {
    // Month
    if (this.months.length) {
      const monthIndex = this.months.findIndex(
        (month) => month.moment.isSame(moment(selected), 'month') && month.moment.isSame(moment(selected), 'year')
      );

      if (monthIndex === -1) {
        return;
      }
      this.selectMonth(monthIndex);
    }
    // Date
    const dayIndex = this.dates.findIndex((day) => day.moment.isSame(selected, 'date'));
    if (dayIndex === -1) {
      return;
    }
    this.selectDay(dayIndex);

    // Time
    const timeIndex = Object.values(this.times).findIndex((time) => time.moment.isSame(selected, 'minute'));
    if (timeIndex === -1) {
      return;
    }
    this.selectTime(timeIndex);
  };

  /**
   * action to save selected time
   *
   * @memberof PreOrderScreenStore
   */
  @action save = (onSubmit?: (time?: number) => void) => () => {
    if (!this.selectedDate) {
      return;
    }

    // For displaying default time in Preorder
    if (this.selectedDateTime?.isSame(moment(), 'minute')) {
      onSubmit?.(undefined);
      return;
    }

    onSubmit?.(this.selectedDateTime?.unix());
  };

  @action clearStore = () => {
    this.dates = [];
    this.times = [];
    this.months = [];
    this.date = 0;
    this.month = 0;
    this.time = 0;
  };
}

export const preorderPickerStore = new PreorderPickerStore();
