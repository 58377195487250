import { toJS } from 'mobx';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { DESTINATION_LOGS, EVENTS } from 'src/constants/events';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import logger from 'src/services/logger';
import { IProduct } from 'src/types/products';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import Image from 'src/view/components/Image/Image';
import { OutOfStock } from 'src/view/components/OutOfStock/OutOfStock';
import Donation from 'src/view/components/Tooltip/Donation/Donation';
import './style.scss';
import { branchStore } from '../../../../../mobx/barnchStore';
import { productsStore } from 'src/mobx/productsStore';

export interface IMenuProductProps {
  product?: IProduct;
  type?: string;
}

class MenuProduct extends React.Component<IMenuProductProps> {
  get units() {
    return productUtils.getProductUnits(this.props.product);
  }

  onClick = () => {
    logger.logEvent(EVENTS.MENU_PRODUCT_SELECTED, { product: this.props.product });
    logger.logEvent(EVENTS.SELECT_ITEM, {
      product: this.props.product,
    });

    const itemCategory = productsStore.categories.find((category) => category.id === this.props.product?.category_id);

    logger.logEvent(
      EVENTS.SELECT_ITEM,
      {
        item_id: this.props.product?.id,
        item_name: getTranslate(this.props.product?.name),
        item_category: getTranslate(itemCategory?.name),
        currency: branchStore.currency,
        price: this.props.product?.price,
        quantity: this.props.product?.quantity,
      },
      DESTINATION_LOGS.MOENGAGE
    );
  };

  renderOutOfStock = () => {
    const { product, type } = this.props;
    return type === 'search-item' ? (
      <div className={'d-flex flex-column ml-2'}>
        <OutOfStock shadow fixed customClass={'search'} />
        <p className={'text-size-16 color-darkgray ml-1 sub_title'}>{getTranslate(product?.name)}</p>
      </div>
    ) : (
      <div className={'d-flex flex-column ml-2'}>
        <OutOfStock fixed />
      </div>
    );
  };

  public render() {
    const { product, type } = this.props;

    const description: string | undefined = [...this.units, getTranslate(product?.description)]
      .filter(Boolean)
      .join(' • ');

    const ifOutOfStock = product?.out_of_stock && type === 'search-item' ? 'text_for_out_of_stock' : null;
    return (
      <article id={`Product_${this.props.product?.id}`} className={`card-wrapper ${type || ''}`}>
        <Link
          onClick={this.onClick}
          className={`menu-product card ${product?.out_of_stock ? 'menu-product-out_of_stock' : ''}`}
          title={getTranslate(product?.name)}
          to={{
            pathname: SCREENS.PRODUCT(product?.id || ''),
            search: `?language=${localeStore.language}`,
            state: {
              product: toJS(product),
            },
          }}
        >
          <Image
            loading="lazy"
            src={product?.image}
            aspectRatio={1.5}
            description={description}
            is_donation={product?.is_donation}
            type={type}
            wrapperClassName={`menu-product-image${product?.out_of_stock ? '-out_of_stock' : ''}`}
            title={getTranslate(product?.name)}
            alt={getTranslate(product?.name)}
          />
          <div className={`menu-product-content ${ifOutOfStock}`}>
            <h2 className={`menu-product-name text-md-size-14 mb-5`}>
              {getTranslate(product?.name) || <Skeleton width={'70%'} />}
            </h2>
            <div className="d-flex flex-row justify-content-between">
              <p className="menu-product-price color-primary text-md-size-14">
                {product ? formatPrice(productUtils.calculateProductPrice(product, true)) : <Skeleton width={60} />}
              </p>
              <p className="color-gray text-size-14 text-md-size-12 ml-2">
                {/* TODO: refactor this later */}
                {product ? (
                  this.units?.map((unit, key) =>
                    unit ? (
                      <span key={key} className="ml-1">
                        {unit}
                      </span>
                    ) : null
                  )
                ) : (
                  <Skeleton width={40} />
                )}
              </p>
            </div>
          </div>
          {product?.is_donation && type === 'search-item' ? (
            <div className="menu-product-donation menu-search-item">
              <Donation />
            </div>
          ) : null}
          {product?.out_of_stock ? this.renderOutOfStock() : null}
        </Link>
      </article>
    );
  }
}

export default MenuProduct;
