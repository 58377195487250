import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';

export default class ErrorScreen extends Component {
  render() {
    return (
      <div className="container">
        <div className="d-flex py-5 px-5">
          <h1 className="family-regular">Some error ocorupted</h1>
          <Link
            to={{
              pathname: SCREENS.MENU(),
              search: `?language=${localeStore.language}`,
            }}
          >
            <ButtonComponent className="mt-5">{localeStore.t('txt_menu')}</ButtonComponent>
          </Link>
        </div>
      </div>
    );
  }
}
