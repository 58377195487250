import { observer } from 'mobx-react';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import LoadingCircle from '../../../LoadingCircle/LoadingCircle';
import './style.scss';
import { useMemo } from 'react';

function CheckoutTotalInfoVat() {
  const vatPercent = useMemo(() => {
    if (branchStore.vat_percent && !cartStore.isDonationOnly) return branchStore.vat_percent + '%';
    return '';
  }, [cartStore.isDonationOnly]);
  return (
    <div className="checkout-total-info-container checkout-total-info-vat">
      {/* VAT % */}
      <div className="checkout-total-info-container m-0">
        <p className={'color-dark family-semibold text-size-14'}>{`${localeStore.t('vat')} ${vatPercent}`}</p>
        <LoadingCircle>
          <p id="checkout_vat" className={'color-dark family-semibold text-size-14'}>
            {formatPrice(cartStore.calculator.vat + cartStore.calculator.taxable_delivery_fee)}
          </p>
        </LoadingCircle>
      </div>
      {/** Vat registration number */}
      {branchStore.vat_registration_number ? (
        <div className="d-flex flex-row mt-1 justify-content-between">
          <p className={'color-gray family-semibold text-size-10 text-md-size-10 mr-2'}>
            {localeStore.t('vat_registration_number')}:
          </p>
          {branchStore.branch?.vat_certificate_url ? (
            <a
              href={branchStore.branch.vat_certificate_url}
              target="_blank"
              rel="noreferrer"
              id="cart_vat_registration_number"
              className={'color-primary family-semibold text-size-10 link'}
            >
              {branchStore.vat_registration_number}
            </a>
          ) : (
            <p id="cart_vat_registration_number" className={'color-primary family-semibold text-size-10'}>
              {branchStore.vat_registration_number}
            </p>
          )}
        </div>
      ) : null}
    </div>
  );
}
export default observer(CheckoutTotalInfoVat);
