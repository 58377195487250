import PopupModal from '../../../../../../components/Controls/Popup/Popup';
import { tryPaymentAgainStore } from '../../../../store';
import AvailablePaymentMethods from '../../../../../../containers/AvailablePaymentMethods/AvailablePaymentMethods';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

const AvailablePaymentMethodsPopup = observer(() => {
  const showAvailablePaymentMethods = useMemo(() => {
    return tryPaymentAgainStore.PaymentMethod?.showAvailablePaymentMethods;
  }, [tryPaymentAgainStore.PaymentMethod?.showAvailablePaymentMethods]);

  return (
    <PopupModal
      position={'top'}
      direction={'down'}
      justify={'center'}
      selfClose
      backdrop
      backdropClose
      visible={showAvailablePaymentMethods}
      onClose={() => tryPaymentAgainStore.PaymentMethod?.closeModal()}
      content={() => <AvailablePaymentMethods storeProps={tryPaymentAgainStore} />}
      mobile={{
        popup: true,
        sheet: true,
      }}
    />
  );
});

export default AvailablePaymentMethodsPopup;
