import * as React from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductSize } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import './style.scss';

export interface IPickerSize<T> {
  value: T;
  name: string;
  key: string | number | null | undefined;
}
export interface ISizePickerProps<T> {
  options: IPickerSize<T>[];
  selected?: IProductSize | null;
  disabled?: boolean;
  className?: string;
  onChange?: (option: IPickerSize<T>['value']) => void;
}

export default class Sizes extends React.Component<ISizePickerProps<any>> {
  onSizePress = (option: IPickerSize<any>) => () => {
    if (this.props.onChange) this.props.onChange(option.value);
  };

  public render() {
    const { selected, disabled, options, className } = this.props;

    // Show seleced size if Sized is disabled
    if (disabled && selected)
      return (
        <p className={`sizes-text text-size-18 color-darks ${className ?? ''}`}>
          {localeStore.t('txt_size')} — {getTranslate(selected?.name)}
        </p>
      );

    // Not show sizes if it now exist or lenght === 1
    if (!this.props.options || this.props.options.length <= 1) return null;

    return (
      <div className={`sizes ${className ?? ''}`}>
        {options.map((option) => (
          <div
            onClick={this.onSizePress(option)}
            className={`sizes-size text-elipsis ${selected?.id === option.key ? 'active' : ''}`}
            key={option.key}
          >
            <p className="text-size-14 family-semibold color-darkgray text-elipsis">{getTranslate(option.name)}</p>
          </div>
        ))}
      </div>
    );
  }
}
