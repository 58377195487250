import { Location } from 'history';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { EVENTS } from 'src/constants/events';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import history from 'src/services/history';
import logger from 'src/services/logger';
import {
  getRestaurantMetaDescription,
  getRestaurantMetaKeyWords,
  getRestaurantName,
  setMetaTags,
} from 'src/utils/helpers';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Cookies from './containers/Cookies/Cookies';
import Footer from './containers/Footer/Footer';
import Header from './containers/Header/Header';
import BranchesScreen from './screens/BranchesScreen/BranchesScreen';
import CartScreen from './screens/CartScreen/CartScreen';
import CheckoutScreen from './screens/CheckoutScreen/CheckoutScreen';
import ErrorScreen from './screens/ErrorScreen/ErrorScreen';
import MenuScreen from './screens/MenuScreen/MenuScreen';
import MoreScreen from './screens/MoreScreen/MoreScreen';
import NotFoundScreen from './screens/NotFoundScreen/NotFoundScreen';
import OrderCompletedScreen from './screens/OrderCompletedScreen/OrderCompletedScreen';
import OrderResultScreen from './screens/OrderResultScreen/OrderResultScreen';
import SelectLocation from './screens/PaymentScreen/components/selectLocation/selectLocation';
import PaymentScreen from './screens/PaymentScreen/PaymentScreen';
import SideScreen from './screens/SideScreen/SideScreen';
import StaticScreen from './screens/StaticScreen/StaticScreen';
import { checkoutStore } from '../mobx/checkoutStore';
import { observer } from 'mobx-react';
import { cartStore } from '../mobx/cartStore';
import { typeSMS } from '../types/orders';
import OrderActualizeScreen from './screens/OrderActualizeScreen/OrderActualizeScreen';
import DriverSearchScreen from './screens/DriverSearchScreen/DriverSearchScreen';
import TryPaymentAgainScreen from './screens/TryPaymentAgainScreen/TryPaymentAgainScreen';

export interface IRouterProps extends RouteComponentProps<any> {}

@observer
class Router extends React.Component<IRouterProps> {
  componentDidMount = () => {
    const { search } = this.props.location;

    this.handleRouteChange(this.props.location);

    const params = new URLSearchParams(search);
    const lang = params.get('language');

    if (lang && localeStore.language !== lang) {
      localeStore.setLocale(lang === 'en' ? 'en' : 'ar', false);
    }

    setMetaTags({
      title: `${getRestaurantName()}`,
      description: `${getRestaurantMetaDescription()}`,
      keywords: `${getRestaurantMetaKeyWords()}`,
      'og:title': `${getRestaurantName()}`,
      'og:description': `${getRestaurantMetaDescription()}`,
      'twitter:title': `${getRestaurantName()}`,
      'twitter:description': `${getRestaurantMetaDescription()}`,
    });
  };
  unlisten: any;

  componentWillMount() {
    this.unlisten = history.listen(this.handleRouteChange);
  }

  handleRouteChange = (location: Location) => {
    logger.logEvent(EVENTS.APP_PAGE_CHANGE);
  };

  componentWillUnmount() {
    this.unlisten();
  }

  public render() {
    const { location } = this.props;
    const isSMSPage = location.pathname.includes('/sms-payment');
    const showChangeAddressMap =
      isSMSPage && checkoutStore.reorderStore && cartStore.isSMSType === typeSMS.WITH_GEOLOCATION;
    return (
      <>
        {/* Hide the header for the sms page */}
        {!isSMSPage ? <Header /> : null}

        <div className="router-wrapper">
          <Switch location={location}>
            <Route
              exact
              path={[
                SCREENS.ROOT(),
                SCREENS.MENU(),
                SCREENS.PRODUCT(':id'),
                SCREENS.BRANCHES(),
                SCREENS.BRANCHES(':delivery_type'),
                SCREENS.CART(),
                SCREENS.CHECKOUT(),
                SCREENS.CATEGORY(':id'),
              ]}
              component={MenuScreen}
            />

            <Route path={[SCREENS.MORE()]} component={SideScreen} />

            <Route path={SCREENS.ORDER_RESULT(':id')} component={OrderCompletedScreen} />

            <PrivateRoute path={SCREENS.ORDER_ACTUALIZE(':id')} component={OrderActualizeScreen} />
            <PrivateRoute path={SCREENS.TRY_PAYMENT_AGAIN(':id')} component={TryPaymentAgainScreen} />
            <PrivateRoute path={SCREENS.DRIVER_SEARCH(':id')} component={DriverSearchScreen} />

            <PrivateRoute path={SCREENS.ORDER_COMPLETED(':id')} component={OrderCompletedScreen} />
            <PrivateRoute path={SCREENS.ERROR()} component={ErrorScreen} />
            <PrivateRoute
              exact
              path={[
                SCREENS.ORDERS(),
                SCREENS.ORDER(':id'),
                SCREENS.MY_ADDRESSES(),
                SCREENS.RESERVATIONS(),
                SCREENS.CREATE_RESERVATION(),
                SCREENS.RESERVATION(':id'),
                SCREENS.USER(),
                SCREENS.WALLET(),
                SCREENS.LOCATIONS(),
                SCREENS.SUBSCRIPTIONS(),
              ]}
              component={MoreScreen}
            />
            <Route
              exact
              path={[SCREENS.ABOUT(), SCREENS.FAQ(), SCREENS.TERMS(), SCREENS.PRIVACY(), SCREENS.CONTACTS()]}
              component={StaticScreen}
            />

            {/* SMS Page Flow */}
            <Route exact path={[SCREENS.SMSPAYMENT(), SCREENS.SMS_PRODUCT(':id')]} component={PaymentScreen}></Route>

            {process.env.NODE_ENV === 'production' ? <Redirect path={SCREENS.ROOT()} to={SCREENS.MENU()} /> : null}

            <Route exact path={SCREENS.NOT_FOUND()}>
              <NotFoundScreen />
            </Route>
          </Switch>

          <Route strict path={[SCREENS.BRANCHES(':delivery_type'), SCREENS.BRANCHES()]} component={BranchesScreen} />
        </div>
        <CartScreen />
        <CheckoutScreen />
        {/* Selecting a branch for the sms page  */}
        {showChangeAddressMap ? <SelectLocation delivery_type={'d'} /> : null}

        {/* Hide the footer for the mobile version and for the sms page */}
        {!isSMSPage ? <Footer /> : null}

        <Cookies />
      </>
    );
  }
}

export default withRouter(Router);
