import React, { ReactNode } from 'react';
import PopupModal, { IPopupModalProps } from '../../../components/Controls/Popup/Popup';
import ModalMessage from '../../../components/ModalMessage/ModalMessage';
import { localeStore } from '../../../../mobx/localesStore';

interface ErrorPopupProps extends Omit<IPopupModalProps, 'content'> {
  children: ReactNode;
}

const ErrorPopup = ({ children, onClose, ...rest }: ErrorPopupProps) => (
  <PopupModal
    style={{ padding: '15px', minWidth: '300px' }}
    mobile={{
      popup: true,
      sheet: true,
    }}
    backdrop
    position={'top'}
    direction={'down'}
    justify={'center'}
    onClose={onClose}
    selfClose={false}
    content={() => (
      <ModalMessage
        closeModal={onClose}
        message={localeStore.t('enquiry_disabled')}
        options={[{ text: localeStore.t('txt_ok'), onPress: onClose }]}
      />
    )}
    {...rest}
    disableDropdown={true}
  >
    {children}
  </PopupModal>
);

export default ErrorPopup;
