import { IPaymentTypeId } from './app';
import { TTranslate } from './main';

export type TDeliveryType = 'b' | 'p' | 'd';

export interface IBranchPaymentType {
  id: IPaymentTypeId;
  type: string;
}

interface IBranchWorkingTime {
  working_day: string;
  start_time: string;
  close_time: string;
}
// export type BranchDeliveryType = DELIVERY_TYPES.DELIVERY | DELIVERY_TYPES.PICKUP | 'b';

export interface IBranch {
  id: number;
  name: TTranslate;
  address: string;
  delivery_type: string;
  subscription_delivery_type: string;
  preorder: null | IPreorder;
  latitude: number;
  longitude: number;
  status: number;
  delivery_fee: number;
  curbside_fee: number;
  is_curbside: number;
  min_order: number;
  free_delivery: number;
  max_cash_payment: number;
  payment_type: number;
  is_free_delivery: number;

  working_now: boolean;
  working_hours: string;
  closest_day: IBranchWorkingTime;
  is_busy: boolean;
  distance?: string;
  working_time: IBranchWorkingTime[];
  payment_types: IBranchPaymentType[];
  default_payment_type: IPaymentTypeId;
  /**
   * Is a preselect default payment type
   * @type {number} 0 | 1
   * @memberof IBranch
   */
  pre_select_default?: number;
  work_slots: IWorkSlot[];

  confirmedAt?: number;
  preparation?: IBranchPreparation | null;

  emenu?: IEMenu | null;

  vat_registration_number?: string;
  /**
   * Link on VAT certificate
   *
   * @type {string}
   * @memberof IBranch
   */
  vat_certificate_url?: string;
  vat_percent?: number;
  vat_included?: boolean;

  is_round_clock?: boolean;

  available_preorder_at?: Date;

  /**
   * Show wallet is available for branch
   *
   * @type {boolean}
   * @memberof IBranch
   */
  is_cashback?: boolean;
  is_wallet?: boolean;

  currency?: {
    currency_code: string;
    currency_code_ar: string;
    currency_position?: 'l' | 'r';
    devided?: number;
    id: number;
  };
  branchCovered?: boolean;
  allow_all_payment_methods?: 0 | 1;

  /**
   * Date of expire busy
   * For calculation preorder
   * @type {Date}
   * @memberof IBranch
   */
  busy_expire_in: Date | null;

  current_reduced_settings: IReduced[];
}
export interface IReduced {
  order_more_than: number;
  reduced_delivery_fee: number;
}
export interface IWorkSlot {
  day: string;
  breaks:
    | null
    | {
        start_time: string;
        end_time: string;
        time: string;
      }[];
  start_time: string;
  close_time: string;
  is_full_day: boolean;
  time: string;
}

export interface IBranchArea {
  address: string;
  lat: number;
  lng: number;
  message?: string;

  id?: number;
  area_place_id?: string;
  city_id?: number;
  city_place_id?: string;
  place_id?: string;
}

export type TPreorderDay =
  | 'today'
  | 'tomorrow'
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export const DAYS_IN_WEEK = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
};

export interface IPreorder {
  date: string;
  unix: number;
}

export interface INearestTime {
  day: TPreorderDay;
  time: string;
}

export interface ITimeSlot {
  from: string;
  to: string;
}

export interface ITimeSlots {
  [key: string]: ITimeSlot[];
}

export interface IBranchTimeResponse {
  nearest: INearestTime;
  times: ITimeSlots;
  latest: { day: string };
  preparation: IBranchTimePreparation;
}

interface IBranchTimePreparation extends Omit<IBranchPreparation, 'id' | 'external_id' | 'name'> {}
export interface IBranchPreparation {
  id: number;
  external_id: number | string | null;
  name: string;
  delivery: {
    unit: UnitTime; // m | h | d,
    time: number; // delivery time
    preparation_time: number;
    gap: number;
  };
  pickup: {
    unit: UnitTime; // m | h | d,
    time: number; // pickup time
    preparation_time: number;
    gap: number;
  };
  preorder: IPreparationPreorder;
}

export interface IPreparationPreorder {
  interval: 1 | 5 | 15 | 30 | 60; // mins
  days: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7'; // preorder latest date
  period: TPreparationPeriod;
}

export type UnitTime = 'm' | 'h' | 'd';
export type TPreparationPeriod = 'days' | 'months' | 'today' | 'weeks';

export interface IEMenu {
  order_types: IEMenuOrderTypes;
  payment_types: IEMenuPaymentTypes[];
  // default_payment_type: IPaymentTypeId,
  sections: IEMenuSection[] | null;
}

export interface IEMenuOrderTypes {
  is_dinein: boolean | false;
  is_togo: boolean | false;
}
export interface IEMenuPaymentTypes {
  id: IPaymentTypeId;
  type: string;
  is_default: number;
}
export interface IEMenuSection {
  id: number;
  title: string;
  tables: IEMenuTable[];
}

export interface IEMenuTable {
  id: number;
  title: string;
}

export interface IDineIn {
  section: IDineInItem | null;
  table: IDineInItem | null;
}

export interface IDineInError {
  section_error?: boolean;
  table_error?: boolean;
}
export interface IDineInItem {
  id: number;
  title: string;
}

export enum BranchStatus {
  Closed = 'CLOSED',
  Open24H = 'OPEN_24H',
  OpenUntilToday = 'OPEN_UNTIL_TODAY',
  BreakUntilToday = 'BREAK_UNTIL_TODAY',
  ClosedUntilToday = 'CLOSED_UNTIL_TODAY',
  ClosedUntilTomorrow = 'CLOSED_UNTIL_TOMORROW',
  ClosedUntilWeekday = 'CLOSED_UNTIL_WEEKDAY',
}

export interface IPreparation {
  unit: 'm' | 'h' | 'd'; // m | h | d,
  time: number; // pickup time
  preparation_time: number;
  /**
   * Gap between time slots
   *
   * @type {number}
   * @memberof IPreparation
   */
  gap: number;
}
