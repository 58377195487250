import { FC, ReactElement, useMemo } from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { IProduct } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import { Carousel } from 'src/view/components/Carousel/Carousel';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import ComboCarouselArrow from '../../components/ComboCarouselArrow/ComboCarouselArrow';
import { ComboItem } from '../../components/ComboItem/ComboItem';
import './style.scss';

interface IProps {
  product?: IProduct;
  onSelectItem: (item: IProductInsideCombo) => void;
}

export const DealComboItemsBlock: FC<IProps> = ({ product, ...props }: IProps): ReactElement | null => {
  const itemDescription = useMemo((): string | undefined => {
    if (!product || !product.items?.length) return undefined;

    const selectItem: IProductInsideCombo | undefined = product.items.find((item) => item.is_selected);
    const units = productUtils.getComboItemUnits(selectItem);
    const text = [...units, getTranslate(selectItem?.description)].filter(Boolean).join(' • ');

    return text;
  }, [product]);

  if (!product?.items?.length) return null;

  return (
    <ResponsiveContent min={WINDOW_SIZES.md}>
      <div className="product-deal-items-block">
        <p className="product-deal-items-block__header family-bold color-dark">{localeStore.t('combo_products')}</p>

        <Carousel
          itemsToShow={3}
          itemsToScroll={2}
          renderArrow={ComboCarouselArrow}
          className="combo-items__carousel"
          disableArrowsOnEnd={true}
          focusOnSelect={true}
        >
          {product.items.map((item) => (
            <ComboItem
              key={item.id}
              item={item}
              onSelectItem={props.onSelectItem}
              selected={Boolean(item.is_selected)}
            />
          ))}
        </Carousel>

        {itemDescription ? (
          <p className="product-deal-items-block__item-description family-regular color-dark text-lite-elipsis">
            {itemDescription}
          </p>
        ) : null}
      </div>
    </ResponsiveContent>
  );
};
