import React from "react";
import "./style.scss";
import Spinner from '../Controls/Spinner/Spinner';
import Skeleton from "react-loading-skeleton";

interface IProps {
    title: string;
    count?: null | number;
    countIsLoading?: null | boolean;
}

export const PageTitle: React.FC<IProps> = ({ title = "", count = null, countIsLoading = null }) => (
  <div className="page-title">
      <span className="page-title-name">{title || <Skeleton width={150} />}</span>
      {count !== null && (
          countIsLoading ? (
              <Spinner className="page-title-count-spinner ml-3" color={"#6D6D6D"} size={26} />
          ) : (
              <>
                  <span className="page-title-count count-line">-</span>
                  <span className="page-title-count">{count}</span>
              </>
          )
      )}
  </div>
);
