import { localeStore } from '../../../../mobx/localesStore';
import Chevron from '../../../screens/ContactsScreen/components/Chevron';
import Icon from '../Icon/Icon';
import { ICONS } from '../../../../constants/icons';
import Input from '../Input/Input';
import React, { ChangeEvent, Component } from 'react';
import '../../../screens/ContactsScreen/components/BranchesSelect/style.scss';
import { debounce, throttle } from '../../../../utils/perfomance';
import Option from './Option';
import Skeleton from 'react-loading-skeleton';

export type ItemData = {
  key: string;
  label: string;
  data: any;
};

export type LoadDataParams = {
  page: number;
  search: string;
};

export type LoadDataResponse = {
  items: ItemData[];
  page: number;
  hasMore: boolean;
};

type Props = {
  loadData: (params: LoadDataParams) => Promise<LoadDataResponse>;
  searchable?: boolean;
  placeholder?: string;
  onOptionSelect?: (data: ItemData) => void;
  error?: boolean;
  disabled: boolean;
  selected?: ItemData;
  required?: boolean;
  onDeselect?: () => void;
};

type State = {
  selected?: ItemData;
  menuOpened: boolean;
  search: string;
  page: number;
  items: ItemData[];
  loading: boolean;
  hasMore: boolean;
};

class Select extends Component<Props, State> {
  menu: HTMLDivElement | null = null;
  select: HTMLDivElement | null = null;
  scrollableElement: HTMLDivElement | null = null;

  initialState = {
    menuOpened: false,
    search: '',
    page: 1,
    items: [],
    loading: true,
    hasMore: true,
  };

  state = {
    selected: this.props.selected,
    menuOpened: false,
    search: '',
    page: 1,
    items: [],
    loading: true,
    hasMore: true,
  };

  constructor(props: Props) {
    super(props);
  }

  loadData = (withPaginate?: boolean) => {
    this.props.loadData({ page: this.state.page, search: this.state.search }).then(({ items, page, hasMore }) => {
      this.setState({
        items: withPaginate ? [...this.state.items, ...items] : items,
        page: withPaginate ? page : 1,
        hasMore,
        loading: false,
      });
    });
  };

  onScroll = () => {
    if (this.scrollableElement && this.scrollableElement.scrollHeight - this.scrollableElement.scrollTop < 400) {
      if (!this.state.hasMore) return;
      this.setState({ loading: true });
      this.loadData(true);
    }
  };

  throttleScroll = throttle(this.onScroll, 150);

  closeMenuHandler = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const pressedElelement: any = event.target;
    const isInside = this.select?.contains(pressedElelement);

    if (isInside) return;

    this.closeMenu();
  };

  openMenu = () => {
    if (this.state.menuOpened || this.props.disabled) return;
    this.setState({ menuOpened: true });
    document.addEventListener('click', this.closeMenuHandler);
    this.scrollableElement?.addEventListener('scroll', this.throttleScroll);

    this.loadData();
  };

  closeMenu = () => {
    this.setState({ menuOpened: false });
    document.removeEventListener('click', this.closeMenuHandler);
    this.scrollableElement?.removeEventListener('scroll', this.throttleScroll);
    this.setState(this.initialState);
  };

  debounceSearchLoad = debounce(() => {
    this.loadData();
  }, 300);

  handleSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value, items: [], loading: true }, this.debounceSearchLoad);
  };

  handleOptionSelect = (item: ItemData) => {
    this.setState({ selected: item });
    if (this.props.onOptionSelect) this.props.onOptionSelect(item);
    this.closeMenu();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (this.props.selected && prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
  }

  renderInput = () => (
    <>
      <Input
        className={'select-input'}
        placeholder={this.props.placeholder || localeStore.t('txt_select')}
        value={this.state.selected ? this.state.selected!.label : undefined}
        contentEditable={false}
        readOnly={true}
        error={this.props.error}
        disabled={this.props.disabled}
        rightIcon={
          !this.state.selected || this.props.disabled ? (
            <Chevron onClick={() => this.openMenu()} />
          ) : (
            <Icon
              name={ICONS.CLOSE}
              color={'darkgrey'}
              size={11}
              style={{ cursor: 'pointer' }}
              onPress={() => {
                if (this.props.onDeselect) this.props.onDeselect();
                this.setState({ selected: undefined });
              }}
            />
          )
        }
        onClick={() => this.openMenu()}
        rightIconContainerStyle={{ top: '15px', width: '10px', height: '10px' }}
        required={this.props.required}
      />
      {this.props.required && !this.state.selected ? (
        <div className="right-icon danger-circle background-danger"></div>
      ) : null}
    </>
  );

  renderEmpty = () => {
    return (
      <>
        <Skeleton height={46} style={{ borderRadius: '10px' }} />
        <Skeleton height={46} style={{ borderRadius: '10px' }} />
        <Skeleton height={46} style={{ borderRadius: '10px' }} />
      </>
    );
  };

  renderMenu = () => (
    <div className={`select-menu ${!this.state.menuOpened ? 'hidden' : ''}`} ref={(ref) => (this.menu = ref)}>
      <div className={'select-menu__search-input'}>
        <Input
          iconContainerStyle={{ top: '15px' }}
          icon={<Icon name={ICONS.SEARCH} size={20} />}
          inputClassName={'family-regular'}
          className={'search-input'}
          placeholder={localeStore.t('select')}
          onChange={this.handleSearchChange}
          value={this.state.search}
        />
      </div>
      <div className={'select-menu__list-container'} ref={(ref) => (this.scrollableElement = ref)}>
        <div className={'select-menu__list'}>
          {this.state.loading && this.renderEmpty()}
          {!this.state.loading &&
            this.state.items.map((item: ItemData) => (
              <Option key={item.key} item={item} onClick={this.handleOptionSelect} />
            ))}
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <div className={'select-wrapper'} ref={(ref) => (this.select = ref)}>
        {this.renderInput()}
        {this.renderMenu()}
      </div>
    );
  }
}

export default Select;
