import React, { Component } from 'react';
import DropDownModal, { IDropDownModalProps } from '../DropDownModal/DropDownModal';
import { DropDownOption, IDropDownOption } from '../DropDownOption/DropDownOption';
import Icon from '../Icon/Icon';
import './style.scss';

export interface IDropDownListProps<T> {
  options: IDropDownOption<T>[];
  selected?: IDropDownOption<T>['key'];
  onPress: (option: IDropDownOption<T>) => void;
  direction?: IDropDownModalProps['direction'];
  className?: string;
  listClassName?: string;
}

export default class DropDown extends Component<IDropDownListProps<any>> {
  renderOption = (option: IDropDownOption<any>) => (
    <DropDownOption key={option.key} selected={this.props.selected} option={option} onPress={this.props.onPress} />
  );

  render() {
    return (
      <DropDownModal
        className={this.props.className}
        listClassName={this.props.listClassName}
        direction={this.props.direction}
        content={() => (
          <ul className="dropdown-list d-flex flex-column">{this.props.options.map(this.renderOption)}</ul>
        )}
      >
        {this.props.children}
      </DropDownModal>
    );
  }
}
