import { observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import ScreenWrapper from 'src/view/containers/ScreenWrapper/ScreenWrapper';
import Modal from '../../components/Controls/Modal/Modal';
import './style.scss';
import { searchStore } from './searchStore';
import SearchForm from './components/SearchForm/SearchForm';
interface IDataCoordinates {
  lat: number;
  lng: number;
}
interface IProps {
  coordinates: (data: IDataCoordinates) => void;
}
const SearchScreen: FC<IProps> = observer(({ coordinates }: IProps) => {
  const closeModal = () => {
    searchStore.toggleOpen(false);
  };

  return (
    <ScreenWrapper>
      <Modal
        isOpen={searchStore.isOpen}
        className="container"
        closeBtnClass={'branchClose'}
        onRequestClose={closeModal}
        withCloseIcon
        withPane
      >
        <SearchForm coordinates={coordinates} />
      </Modal>
    </ScreenWrapper>
  );
});
export default SearchScreen;
