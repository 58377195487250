import DropDown from '../../../../../../../../components/Controls/DropDown/DropDown';
import { localeStore } from '../../../../../../../../../mobx/localesStore';
import Icon from '../../../../../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../../../../../constants/icons';
import Input from '../../../../../../../../components/Controls/Input/Input';
import ButtonComponent from '../../../../../../../../components/Controls/Button/Button';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ADDRESS_TYPES, VIEW_MODE } from '../../../../../../../../../types/address';
import { SubmitHandler, useForm } from 'react-hook-form';
import { branchStore } from '../../../../../../../../../mobx/barnchStore';
import { SCREENS } from '../../../../../../../../../constants/screens';
import branchesScreenStore from '../../../../../../branchesScreenStore';
import PopupModal from '../../../../../../../../components/Controls/Popup/Popup';
import ModalMessage from '../../../../../../../../components/ModalMessage/ModalMessage';
import { observer } from 'mobx-react';
import { addressStore } from '../../../../../../../../../mobx/barnchStore/address';
import { IBranch } from '../../../../../../../../../types/branch';
import { DELIVERY_TYPES } from '../../../../../../../../../constants/deliveryTypes';
import { TimeController } from 'src/controllers/TimeController/TimeController';

type FormValues = {
  address_type: number | undefined;
  name: string | undefined;
  apt_of_suite: string | undefined;
  floor: string | undefined;
  notes: string | undefined;
};
interface IAddressFormHandler {
  branch: IBranch | null;
}
const AddressFormHandler = observer(({ branch }: IAddressFormHandler) => {
  const history = useHistory();

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      address_type:
        branchesScreenStore.viewMode === VIEW_MODE.EDIT
          ? branchesScreenStore.address?.address_type || undefined
          : undefined,
      name:
        branchesScreenStore.viewMode === VIEW_MODE.EDIT ? branchesScreenStore.address?.name || undefined : undefined,
      apt_of_suite:
        branchesScreenStore.viewMode === VIEW_MODE.EDIT
          ? branchesScreenStore.address?.apt_of_suite || undefined
          : undefined,
      floor:
        branchesScreenStore.viewMode === VIEW_MODE.EDIT ? branchesScreenStore.address?.floor || undefined : undefined,
      notes:
        branchesScreenStore.viewMode === VIEW_MODE.EDIT ? branchesScreenStore.address?.notes || undefined : undefined,
    },
  });
  const selectValue = watch('address_type');

  const onChangeTextArea = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value) setValue('notes', event.target?.value || undefined);
  }, []);

  const onChangeDropDown = useCallback((event: any) => {
    setValue('address_type', event.key || undefined);
  }, []);

  const onSubmit: SubmitHandler<FormValues> = useCallback(async (data: any) => {
    branchesScreenStore.setLoading(true);
    try {
      const coordinate = branchStore.selectMapCoordinate;
      data.latitude = coordinate?.latitude;
      data.longitude = coordinate?.longitude;
      await branchesScreenStore.selectDeliveryAddress(data);
    } catch (error: any) {
      branchesScreenStore.setError(error?.response?.data?.message || null);
    } finally {
      branchesScreenStore.setLoading(false);
    }
  }, []);

  const closeError = useCallback(() => {
    branchesScreenStore.setError(null);
  }, []);

  const titleBtn = useMemo(() => {
    if (branchesScreenStore.viewMode === VIEW_MODE.EDIT) return localeStore.t('update_location');

    const branchTime = new TimeController({
      branch,
      date: undefined,
      deliveryType: DELIVERY_TYPES.DELIVERY,
    });

    // check if branch work now
    const isActive =
      branchTime.availableAtAll ||
      branch?.preorder ||
      (!branch?.preorder && !branch?.is_busy && branch?.is_round_clock);

    if (branchesScreenStore.viewMode === VIEW_MODE.CREATE && isActive) return localeStore.t('deliver_here');

    if (branch && branchesScreenStore.viewMode === VIEW_MODE.CREATE && isActive) return localeStore.t('deliver_here');
    return localeStore.t('show_menu');
  }, [addressStore.editAddressOnMobileMap, branchesScreenStore.viewMode, branch]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="content">
        <div className="d-flex flex-row full-width">
          <DropDown
            selected={selectValue}
            onPress={onChangeDropDown}
            className={'address-container'}
            options={Object.values(ADDRESS_TYPES).map((id) => ({
              key: id,
              label: localeStore.t(`address_type_${id}`),
              value: id,
            }))}
          >
            <div className="dropdown-select input text-pointer mb-2 full-width family-semibold">
              {localeStore.t(selectValue ? localeStore.t(`address_type_${selectValue}`) : 'txt_hint_address_type')}
              <Icon className="ml-3" name={ICONS.CHEVRON} />
            </div>
          </DropDown>
        </div>
        <div className="d-flex flex-row">
          <Input
            inputClassName={'family-semibold text-size-16 color-dark'}
            className="text-pointer mb-2 full-width"
            placeholder={localeStore.t('address_name')}
            maxLength={250}
            {...register('name')}
          />
        </div>
        <div className="d-flex flex-row full-width multiple">
          <Input
            inputClassName={'family-semibold text-size-16 color-dark'}
            className="text-pointer mb-2 mini"
            placeholder={localeStore.t('suite')}
            maxLength={50}
            {...register('apt_of_suite')}
          />
          <Input
            inputClassName={'family-semibold text-size-16 color-dark'}
            className="text-pointer mb-2 mini"
            placeholder={localeStore.t('floor')}
            maxLength={10}
            {...register('floor')}
          />
        </div>
        <div className="d-flex flex-row full-width">
          <textarea
            onChange={onChangeTextArea}
            className="notes-textarea text-size-14 color-dark"
            maxLength={250}
            placeholder={localeStore.t('write_a_note')}
          >
            {getValues('notes')}
          </textarea>
        </div>
      </div>
      <div className="d-flex flex-column px-3 py-3 create-address zIndex-100">
        <PopupModal
          position={'top'}
          direction={'down'}
          justify={'center'}
          selfClose={true}
          backdrop
          backdropClose
          onClose={closeError}
          visible={Boolean(branchesScreenStore.errorResponse)}
          content={() => (
            <ModalMessage
              wrapperClassName={'px-0 pb-0 pt-3'}
              minWidth={320}
              title={branchesScreenStore.errorResponse || ''}
              closeModal={closeError}
            />
          )}
          mobile={{
            popup: true,
            sheet: true,
          }}
        >
          <ButtonComponent
            disabled={branchesScreenStore.loading}
            className="branches-submit full-width"
            loading={branchesScreenStore.loading}
            title={titleBtn}
          />
        </PopupModal>
      </div>
    </form>
  );
});
export default AddressFormHandler;
