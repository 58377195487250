import { action, computed, observable } from 'mobx';
import { IOrder } from '../../../types/orders';
import { userStore } from '../../userStore';
import logger from '../../../services/logger';
import { IBranch, IBranchPaymentType } from '../../../types/branch';
import { appStore } from '../../appStore';
import { IPaymentCard } from '../../../types/user';
import { PAYMENT_TYPE_NAMES, PAYMENT_TYPES } from '../../../constants/main';
import { IPaymentCardData, IPaymentCardNew } from '../../../types/checkout';
import { DESTINATION_LOGS, EVENTS } from '../../../constants/events';
import { branchStore } from '../../barnchStore';

interface IPaymentMethod {
  branch: IBranch;
  order: IOrder;
  onError?: () => void;
}
class PaymentMethod {
  constructor(branch: IPaymentMethod['branch'], order: IPaymentMethod['order']) {
    this.selectedBranch = branch;
    this.order = order;
  }

  @observable public order: IOrder | undefined = undefined;
  @observable public selectedPaymentType: PAYMENT_TYPES | undefined = undefined;
  @observable public selectedBranch: IBranch | undefined = undefined;
  @observable public selectedСard: IPaymentCard['id'] | null = null;
  @observable public newCard?: IPaymentCardData | null = null;
  @observable public showAvailablePaymentMethods: boolean = false;
  @observable public showAddToCard: boolean = false;

  @computed public get selectedPaymentCard(): IPaymentCardNew | IPaymentCard | undefined {
    if (this.selectedСard) {
      const card = userStore.payment_cards.find((paymentCard) => paymentCard.id === this.selectedСard);
      if (card) {
        return card;
      }
    }

    if (userStore.payment_cards.length) {
      return userStore.payment_cards[0];
    }
    return undefined;
  }
  public selectPaymentCard = (payment_card: IPaymentCard) => {
    // clear added card on select
    this.newCard = undefined;
    this.selectedСard = payment_card.id;
    this.selectPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT);
  };

  public get isPaymentTypeAvailable() {
    // if selected payment type is apple pay we should check online payment type
    const paymentType = this.paymentType === PAYMENT_TYPES.APPLE_PAY ? PAYMENT_TYPES.ONLINE_PAYMENT : this.paymentType;

    if (this.isDonation) {
      // donation can be paid just by card/apple payment
      return [PAYMENT_TYPES.ONLINE_PAYMENT, PAYMENT_TYPES.APPLE_PAY].includes(paymentType as PAYMENT_TYPES);
    }

    const isPaymentTypeAvailableInBranch = this.selectedBranch?.payment_types.some((type) => type.id === paymentType);
    return isPaymentTypeAvailableInBranch;
  }

  public selectPaymentType = (paymentType: PAYMENT_TYPES) => {
    logger.logEvent(EVENTS.CHECKOUT_PAYMENT_TYPE_CHANGED, { method_to: paymentType, method_from: this.paymentType });
    logger.logEvent(
      EVENTS.CHANGE_PAYMENT_METHOD,
      {
        method_id: PAYMENT_TYPE_NAMES[paymentType],
        method_name: this.paymentType,
      },
      DESTINATION_LOGS.MOENGAGE
    );
    this.selectedPaymentType = paymentType;
  };

  @computed public get defaultPaymentType(): PAYMENT_TYPES | undefined {
    // priority 1; if pre_select_default enabled we should select branch default payment type
    if (this.selectedBranch?.pre_select_default && this.selectedBranch.default_payment_type !== undefined) {
      if (this.selectedBranch.default_payment_type === PAYMENT_TYPES.ONLINE_PAYMENT && this.isApplePaymentAvailable) {
        return PAYMENT_TYPES.APPLE_PAY;
      }

      return this.selectedBranch.default_payment_type;
    }
    // priority 2; if user already created order we should select last user payment type
    if (
      userStore.user?.last_payment_type !== undefined &&
      userStore.user?.last_payment_type !== null &&
      this.availablePaymentTypes?.some((type) => type.id === userStore.user?.last_payment_type)
    ) {
      return userStore.user.last_payment_type;
    }
    // priority 3; if branch has default_payment_type we should select it
    if (this.selectedBranch?.default_payment_type !== undefined) {
      if (this.selectedBranch.default_payment_type === PAYMENT_TYPES.ONLINE_PAYMENT && this.isApplePaymentAvailable) {
        return PAYMENT_TYPES.APPLE_PAY;
      }

      return this.selectedBranch.default_payment_type;
    }
    // priority 4; select first branch payment type
    if (this.selectedBranch?.payment_types.length) {
      return this.selectedBranch?.payment_types[0].id;
    }
    // priority 5; select first  payment type available in all branches
    if (this.availablePaymentTypes?.length) {
      return this.availablePaymentTypes[0].id;
    }

    return undefined;
  }

  public get isApplePaymentAvailable(): boolean {
    return Boolean(appStore.settings?.applepay_enabled && appStore.settings?.merchant_id);
  }

  @action private paymentTypesList = (branch: IBranch): IBranchPaymentType[] => {
    const isEMenu: boolean = appStore.mode === 'e-menu';
    if (isEMenu && branch.emenu?.payment_types.length) {
      return branch.emenu?.payment_types;
    } else {
      return branch.payment_types;
    }
  };

  @computed public get availablePaymentTypes(): IBranch['payment_types'] | undefined {
    //   if user has selected branch
    if (this.selectedBranch) {
      // payment with card should be available if order includes at least one donated product
      if (this.isDonation) {
        const availablePaymentTypesList = this.paymentTypesList(this.selectedBranch);
        return availablePaymentTypesList.filter((paymentType) =>
          [PAYMENT_TYPES.APPLE_PAY, PAYMENT_TYPES.ONLINE_PAYMENT].includes(paymentType.id)
        );
      }

      return this.paymentTypesList(this.selectedBranch);
    }
    // return all available payment types
    if (branchStore?.branches) {
      return branchStore.branches.reduce((acc: IBranch['payment_types'], branch: IBranch) => {
        const availablePaymentTypesList = this.paymentTypesList(branch);
        availablePaymentTypesList.forEach((paymentType) => {
          if (!acc.some((_payment_type) => _payment_type.id === paymentType.id)) {
            acc.push(paymentType);
          }
        });
        return acc;
      }, []);
    }
  }

  public get isFullyWallet() {
    return Boolean(this.order?.wallet_discount && this.order?.subtotal + this.order.vat === this.order.wallet_discount);
  }

  public get isFullyPromoCode() {
    return Boolean(this.order?.discount_amount && this.order?.discount_amount === this.order.total);
  }

  public get isOrderFree() {
    return Boolean(this.isFullyPromoCode || this.isFullyWallet);
  }
  @action public openModal = () => {
    this.showAvailablePaymentMethods = true;
  };
  @action public closeModal = () => {
    this.showAvailablePaymentMethods = false;
  };
  @action public showAddToCardModal = (status: boolean) => {
    this.showAddToCard = status;
  };

  @computed public get paymentType(): PAYMENT_TYPES | undefined {
    // if cart had at least one donated item we should return "Online Payment"
    if (this.isDonation) {
      if (this.selectedPaymentType === PAYMENT_TYPES.APPLE_PAY) {
        return PAYMENT_TYPES.APPLE_PAY;
      }
      return PAYMENT_TYPES.ONLINE_PAYMENT;
    }

    if (this.selectedPaymentType !== undefined) {
      return this.selectedPaymentType;
    }

    return this.defaultPaymentType;
  }

  public get isDonation() {
    return this.order?.products.some((product) => product.is_donation);
  }

  public clearPaymentCard = () => {
    // clear added card on select
    this.newCard = undefined;
    this.selectedСard = null;
    this.selectPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT);
  };

  public clear = () => {
    this.order = undefined;
    this.newCard = null;
    this.selectedСard = null;
    this.selectedBranch = undefined;
    this.selectedPaymentType = undefined;
  };
}

export default PaymentMethod;
