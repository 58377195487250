import { persist } from 'mobx-persist';
import { requests } from 'src/requests';
import client from 'src/services/client';
import { ICartCheckout } from 'src/types/cart';

import { action, observable } from 'mobx';

import { IPaymentTypeId } from '../../types/app';
import { IAddressBookRequest, IPaymentCard, IUpdateUser, IUser } from '../../types/user';
import { cartStore } from '../cartStore';
import { localeStore } from '../localesStore';
import { branchStore } from '../barnchStore';
import { createAddress } from '../../requests/address';
import { addressStore } from '../barnchStore/address';
import { logError } from '../../utils/helpers';
import { checkoutStore } from '../checkoutStore';
import moengage from '../../services/moengage';
import { DESTINATION_LOGS, EVENTS } from 'src/constants/events';
import logger from 'src/services/logger';

export class UserStore {
  private initialUserObject: IUser = {
    id: 0,
    name: '',
    email: '',
    mobile: '',
    points: 0,
    auth_token: '',
    language: '',
    birth_day: '',
    gender: '',
    last_payment_type: null,
    wallet: {
      balance: 0,
      expire_soon: 0,
      expire_date: null,
    },
    cashback: [],
    cashback_sum_available_soon: '',
    curbside: {
      car_brand: '',
      car_color: '',
      car_number: '',
      car_type: '',
    },
  };
  @persist @observable public isLogin: boolean = false;
  @persist('object') @observable public user: IUser = this.initialUserObject;
  @observable payment_cards: IPaymentCard[] = [];

  @action onLogin = async () => {
    try {
      await addressStore.fetchAddresses();
      await addressStore.validateTempAddress();

      // run the calculator after login, if the user entered a promo code
      if (cartStore.checkout.promocode) {
        cartStore.calculate();
      }
      if (checkoutStore.reorderStore.order.wallet_discount) {
        cartStore.setCheckoutWallet(1);
      }
    } catch (e) {
      logError(e);
    }
  };

  // actions

  @action public authorize = async (user: IUser) => {
    this.user = user;
    this.isLogin = true;
    if (user.auth_token) {
      client.headers = {
        Authorization: `Bearer ${user.auth_token}`,
      };
    }
    this.onLogin();
    moengage.login(user);
    if (cartStore.isSMS) cartStore.calculate();
  };

  @action public updateUser = async (user: IUpdateUser) => {
    try {
      const response = await requests.updateUser(user);
      if (response) {
        this.user = { ...this.user, ...user };
      }
    } catch (error: any) {
      logError(error);
      throw error;
    }
  };

  @action public fetchUser = async () => {
    try {
      const user: IUser = await requests.fetchUser();
      this.user = { ...this.user, ...user };
    } catch (error: any) {
      logError(error);
    }
  };

  /**
   * Fetch user payment cards
   *
   * @memberof UserStore
   */
  @action public fetchUserCards = async () => {
    try {
      this.payment_cards = await requests.user.fetchCards(branchStore.branch.id);
    } catch (error: any) {
      logError(error);
    }
  };

  @action public addAddressBook = async (body: IAddressBookRequest) => {
    return await client.post(`/v1/add_addressbook`, body, { params: { language: localeStore.language } });
  };

  @action public setLastPaymentType = async (payment_type: IPaymentTypeId) => {
    this.user.last_payment_type = payment_type;
  };

  @action public setLastPaymentCard = async (payment_card: IUser['last_payment_card']) => {
    this.user.last_payment_card = payment_card;
  };

  @action public setCurbsideInfo = async (curbside: ICartCheckout['curbside']) => {
    this.user.curbside = curbside;
  };

  @action public logout = async () => {
    this.isLogin = false;
    this.user = this.initialUserObject;

    logger.logEvent(EVENTS.LOG_OUT, { user_id: this.user.id, log_out_date: new Date() }, DESTINATION_LOGS.MOENGAGE);

    try {
      await client.post('/v3/auth/logout');
      addressStore.clear();
    } catch (error: any) {
      logError(error);
    }

    client.headers = {
      Authorization: ``,
    };
    /** Clear saved addresses */
    moengage.logout();
    addressStore.clear();
    // TODO: CLEAR CART ON LOGOUT
    cartStore.clear();
  };

  public async afterHydration() {
    if (this.isLogin && this.user.auth_token) {
      client.headers = {
        Authorization: `Bearer ${this.user.auth_token}`,
      };
    } else if (this.isLogin) {
      this.logout();
    }
    if (this.user.id) {
      this.fetchUser();
    }
  }
}

export const userStore = new UserStore();
