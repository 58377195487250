import { observer } from 'mobx-react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import var_logo from 'src/assets/images/vat-logo.png';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import Copyright from 'src/view/components/Copyright/Copyright';
import { WINDOW_SIZES } from '../../../constants/main';
import ResponsiveContent from '../../components/ResponsiveContent/ResponsiveContent';
import './style.scss';

interface ILink {
  label: string;
  link: string;
}

@observer
export default class Footer extends React.Component {
  links: ILink[] = [
    {
      label: 'txt_about',
      link: SCREENS.ABOUT(),
    },
    {
      label: 'txt_faq',
      link: SCREENS.FAQ(),
    },
    {
      label: 'txt_privacy',
      link: SCREENS.PRIVACY(),
    },
    {
      label: 'menu_contact_us',
      link: SCREENS.CONTACTS(),
    },
    {
      label: 'txt_terms',
      link: SCREENS.TERMS(),
    },
  ];
  social = [
    {
      image: `${process.env.REACT_APP_CLOUD_FRONT}/media/images/youtube.svg`,
      link: appStore.settings?.youtube,
      target: '_blank',
    },
    {
      image: `${process.env.REACT_APP_CLOUD_FRONT}/media/images/tiktok.svg`,
      link: appStore.settings?.tiktok,
      target: '_blank',
    },
    {
      image: `${process.env.REACT_APP_CLOUD_FRONT}/media/images/new_facebook.svg`,
      link: appStore.settings?.facebook,
      target: '_blank',
    },
    {
      image: `${process.env.REACT_APP_CLOUD_FRONT}/media/images/twitter-x.svg`,
      link: appStore.settings?.twitter,
      target: '_blank',
    },
    {
      image: `${process.env.REACT_APP_CLOUD_FRONT}/media/images/new_instagram.svg`,
      link: appStore.settings?.instagram,
      target: '_blank',
    },
    {
      image: `${process.env.REACT_APP_CLOUD_FRONT}/media/images/new_whatsapp.svg`,
      link: appStore.settings?.whatsapp ? `https://wa.me/${appStore.settings.whatsapp}` : ``,
      target: '_self',
    },
  ].filter((social) => social.link);

  renderSocial = (social: { image: string; link?: string; target: string }, index: number) => {
    return (
      <li className="mr-3" key={index}>
        <a href={social.link || '#'} target={social.target} className="color-white">
          <img width="36" height="36" src={social.image} title={social.link} alt={social.link} />
        </a>
      </li>
    );
  };

  renderLink = (link: ILink, index: number) => (
    <li key={index} className={`d-flex flex-grow-1 mb-3 col-${link.link === '/terms' ? '12' : '6 '} mr-0`}>
      <Link
        hrefLang={localeStore.language}
        title={link.label}
        rel="bookmark"
        className="color-white"
        to={{
          pathname: link.link,
          search: `?language=${localeStore.language}`,
        }}
      >
        {localeStore.timestamp ? localeStore.t(link.label) : <Skeleton width={80} />}
      </Link>
    </li>
  );
  public render() {
    const isF6orFaris = window.location.href.includes('f6or-faris') || window.location.href.includes('f6orfaris');

    return (
      <footer id="footer" className="footer">
        <div className="">
          <div className="container d-flex flex-column align-items-center flex-lg-row  d-flex flex-grow-1 full-width">
            <div className="col-lg-3 pl-0 mt-3 mt-md-0 order-md-1 order-0 d-flex flex-column justify-content-start">
              {appStore.settings?.img_app_banner_logo && (
                <span className="col-12 d-flex flex-column align-items-start mb-4 footer-store-logo">
                  <img src={appStore.settings?.img_app_banner_logo} className="website-logo" title="logo" alt="logo" />
                  {isF6orFaris ? (
                    <p className="color-gray text-size-16 family-semibold mt-3">شركة فطور فارس شركة لشخص واحد</p>
                  ) : null}
                </span>
              )}

              {appStore.settings?.certificate ? (
                <ResponsiveContent min={WINDOW_SIZES.md}>
                  <a
                    href={appStore.settings.certificate}
                    target="_blank"
                    className="d-flex align-items-center mb-0 mt-3"
                    rel="noreferrer"
                  >
                    <img width="50" height="64" src={var_logo} title="vat" alt="vat" />
                  </a>
                </ResponsiveContent>
              ) : null}
            </div>

            <div className="col-12 col-lg-7 d-flex flex-column justify-content-between order-md-2 order-1">
              <div className="d-flex align-items-center mb-3 mb-md-4">
                <ul className="d-flex row flex-row flex-wrap align-items-center  text-size-16 family-semibold">
                  {this.links.map(this.renderLink)}
                </ul>
              </div>
              <div className="d-flex flex-row flex-lg-row align-items-lg-center">
                <div className="d-flex flex-column flex-md-row align-items-lg-center">
                  <ul className="d-flex flex-row align-items-center mr-md-5">{this.social.map(this.renderSocial)}</ul>
                  <Copyright />
                </div>

                {appStore.settings?.certificate ? (
                  <ResponsiveContent max={WINDOW_SIZES.md}>
                    <a
                      href={appStore.settings.certificate}
                      target="_blank"
                      className="d-flex align-items-center ml-auto mt-0"
                      rel="noreferrer"
                    >
                      <img src={var_logo} title="vat" alt="vat" />
                    </a>
                  </ResponsiveContent>
                ) : null}
              </div>
            </div>

            <div
              className="col-12 col-lg-2 pr-0 d-flex flex-row flex-lg-column
              justify-content-center justify-content-lg-end
               order-2 align-items-end mb-lg-0 mb-md-0 mt-4
            "
            >
              {appStore.settings?.app_ios ? (
                <a className="footer-store-link" href={appStore.settings?.app_ios}>
                  <img
                    width="210"
                    height="72"
                    src={`${process.env.REACT_APP_CLOUD_FRONT}/media/images/app_store.svg`}
                    className="footer-store"
                    title="App Store"
                    alt="App Store"
                  />
                </a>
              ) : null}

              {appStore.settings?.app_ios && appStore.settings?.app_android ? (
                <div className="mr-3 mt-1 mt-md-3"></div>
              ) : null}

              {appStore.settings?.app_android ? (
                <a className="footer-store-link" href={appStore.settings?.app_android}>
                  <img
                    width="210"
                    height="72"
                    src={`${process.env.REACT_APP_CLOUD_FRONT}/media/images/play_market.svg`}
                    className="footer-store"
                    title="Play Market"
                    alt="Play Market"
                  />
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
