import { FC, ReactElement, memo, useMemo } from 'react';
import { IProductInsideCombo } from 'src/types/combo';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import './style.scss';

export const Description: FC<IProductInsideCombo> = memo((item: IProductInsideCombo): ReactElement | null => {
  const description = useMemo((): string | undefined => {
    return [...productUtils.getComboItemUnits(item), getTranslate(item.description)].filter(Boolean).join(' • ');
  }, [item]);

  if (!description) return null;

  return (
    <p
      dangerouslySetInnerHTML={{ __html: description }}
      className="product-meal-combo-group-mobile-item-modal__description color-dark pxmb-4"
    />
  );
});
