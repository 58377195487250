import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { cartStore } from 'src/mobx/cartStore';
import { ICheckoutModals } from 'src/types/cart';
import PreorderHelperModal from 'src/view/containers/Modals/PreorderHelperModal/PreorderHelperModal';
import './style.scss';

type Props = {};

const CheckoutModalPreorder = observer((props: Props) => {
  const closeModal = useCallback(() => {
    cartStore.setModalValue('preorder', { visible: false });
  }, []);

  return (
    <PreorderHelperModal
      className="checkout-modal-preorder"
      onClose={closeModal}
      visible={Boolean(cartStore.checkout.modals.preorder?.visible)}
      backdrop
      backdropClose
    />
  );
});

export default CheckoutModalPreorder;
