import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';

interface Props {
  clearCart: () => void;
}

export default class ClearCart extends Component<Props, {}> {
  renderModal = ({ closeModal }: { closeModal: () => void }) => (
    <div className="cart-clear-modal background-white">
      <div className="cart-clear-wrapper">
        <button className="cart-clear-close" onClick={closeModal}>
          <Icon name={ICONS.CLOSE} className={'color-gray'} size={14} />
        </button>
        <div className="cart-clear-content">
          <p className="cart-clear-title text-size-20 family-semibold color-dark">{localeStore.t('are_you_sure')}</p>
        </div>

        <ButtonComponent
          onClick={() => {
            this.props.clearCart();
            closeModal();
          }}
          className="background-light cart-clear-button color-dark"
          title={localeStore.t('delete_order')}
        />
      </div>
    </div>
  );

  render() {
    return (
      <DropDownModal content={this.renderModal} selfClose={false} position={'bottom'}>
        <Icon name={ICONS.TRASH} className="color-gray text-pointer" />
      </DropDownModal>
    );
  }
}
