import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ICONS } from 'src/constants/icons';
import { cartStore } from 'src/mobx/cartStore';
import { defaultCartCheckout } from 'src/mobx/cartStore/checkout';
import { localeStore } from 'src/mobx/localesStore';
import { ICheckoutCurbside } from 'src/types/cart';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Input from 'src/view/components/Controls/Input/Input';
import Tooltip from 'src/view/components/Tooltip/Tooltip';
import '../style.scss';

interface Props extends RouteComponentProps<null> {
  onClose: () => void;
}

interface State {
  curbside: ICheckoutCurbside;
}

@observer
class CheckoutModalCurbsideForm extends Component<Props, State> {
  state: State = {
    curbside: cartStore.checkout.curbside,
  };

  /**
   * Set value to namefield
   *
   * @param {keyof ICheckoutCurbside} name - name field
   * @memberof CheckoutModalCurbsideForm
   */
  onChange = (name: keyof ICheckoutCurbside) => (event: ChangeEvent<HTMLInputElement>) => {
    const { curbside } = this.state;
    this.setState({ curbside: { ...curbside, [name]: event.currentTarget.value } });
  };

  /**
   * Add curbside to checkout
   *
   * @memberof CheckoutModalCurbsideForm
   * @description Checks for empty fields, if there are none, then toggleCurbside, add curbside to order and close modal
   */
  onSubmit = () => {
    const { curbside } = this.state;
    const empty = Object.entries(curbside).filter((el) => !el[1]);
    if (!empty.length) {
      cartStore.toggleCurbside();
      /**
       * @param {ICheckoutCurbside} curbside
       */
      cartStore.setCurbside(curbside);
      this.props.onClose();
    }
  };

  /**
   * Delete curbside from checkout
   *
   * @memberof CheckoutModalCurbsideForm
   * @description Clear curbside form, toggleCurbside and close modal
   */
  onClear = () => {
    cartStore.toggleCurbside();
    cartStore.setCurbside(defaultCartCheckout.curbside);
    this.props.onClose();
  };

  render() {
    const { curbside } = this.state;
    const { car_brand, car_color, car_type, car_number } = curbside;

    const disabledSubmit = [...Object.values(curbside).map((el) => !el)].some(Boolean);
    return (
      <div className="checkout-modal-curbside align-items-center px-3 py-3 checkout-scroll hide-scrollbar">
        <div className="d-flex flex-row justify-content-between align-items-center full-width  mb-3">
          <div className="d-flex flex-row align-items-center full-width">
            <p className="text-size-24 family-bold">{localeStore.t('Delivery to car')}</p>
            <Tooltip
              content={() => (
                <div className="card" style={{ padding: 15, minWidth: 200 }}>
                  <p className={'color-dark text-size-14 family-regular'}>{localeStore.t('curbside_info_popup')}</p>
                </div>
              )}
            >
              <Icon name={ICONS.QUESTION} className="text-pointer color-gray ml-2" />
            </Tooltip>
          </div>
          <Icon name={ICONS.TRASH} onPress={this.onClear} />
        </div>
        <div className="d-flex flex-row mb-2 full-width">
          <Input
            animated
            inputClassName={'family-semibold text-size-16 color-dark p-0 '}
            className="text-pointer mb-2 full-width"
            value={car_brand}
            onChange={this.onChange('car_brand')}
            placeholder={localeStore.t('car_brand')}
            required
          />
        </div>
        <div className="d-flex flex-row full-width">
          <Input
            animated
            inputClassName={'family-semibold text-size-16 color-dark p-0 '}
            className="text-pointer mb-2 full-width"
            value={car_type}
            onChange={this.onChange('car_type')}
            placeholder={localeStore.t('car_type')}
            required
          />
        </div>
        <div className="d-flex flex-row full-width">
          <Input
            animated
            inputClassName={'family-semibold text-size-16 color-dark p-0 '}
            className="text-pointer mb-2 full-width"
            value={car_number}
            onChange={this.onChange('car_number')}
            placeholder={localeStore.t('car_number')}
            required
          />
        </div>
        <div className="d-flex flex-row full-width">
          <Input
            animated
            inputClassName={'family-semibold text-size-16 color-dark p-0 '}
            className="text-pointer mb-2 full-width"
            placeholder={localeStore.t('car_color')}
            onChange={this.onChange('car_color')}
            value={car_color}
            required
          />
        </div>
        <ButtonComponent
          style={{ width: '100%' }}
          title={localeStore.t('Submit')}
          onClick={this.onSubmit}
          disabled={disabledSubmit}
        />
      </div>
    );
  }
}

export default withRouter(CheckoutModalCurbsideForm);
