import React, { Component } from 'react';

interface Props {
  html?: string;
  className?: string;
}

export default class InnerHtml extends Component<Props> {
  render() {
    if (!this.props.html) return null;
    return (
      <div className={`template ${this.props.className}`} dangerouslySetInnerHTML={{ __html: this.props.html }}></div>
    );
  }
}
