import { throttle } from '../../../utils/perfomance';
import { IOrder } from '../../../types/orders';
import { tryPaymentAgainStore } from './store';
import { PAYMENT_STATUS } from '../../../constants/main';
import { requests } from '../../../requests';

class Handlers {
  checkOrderStatus = throttle(async (id: IOrder['id']) => {
    if (!tryPaymentAgainStore.order) {
      return;
    }

    if (![PAYMENT_STATUS.FAIL, PAYMENT_STATUS.SUCCESS].includes(tryPaymentAgainStore.order?.payment_status)) {
      await requests.orderActualize(id);
    }

    tryPaymentAgainStore.countDownTimer.secondsInput(59);
    return;
  }, 500);
}

export default new Handlers();
