import { Component } from 'react';

interface Props {
  lat: number;
  lng: number;
}

export default class Marker extends Component<Props> {
  render() {
    return this.props.children;
  }
}
