import React from 'react';
import { PageTitle } from '../../../../components/PageTitle/PageTitle';
import TabList from '../../../../components/TabList/TabList';
import { SCREENS } from '../../../../../constants/screens';
import { localeStore } from '../../../../../mobx/localesStore';
import { ordersStore } from '../../../../../mobx/ordersStore';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import Icon from '../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../constants/icons';
import './style.scss';
import { reservationsStore } from '../../../../../mobx/reservationsStore';
import BackIcon from 'src/view/components/Controls/BackIcon/BackIcon';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import { WINDOW_SIZES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';

@observer
class Tabs extends React.Component<RouteComponentProps> {
  get pageTitle() {
    const location = window.location.href;

    if (!localeStore.timestamp) {
      // wait until translated will be loaded
      return '';
    } else if (location.includes(SCREENS.ORDERS())) {
      return localeStore.t('txt_orders');
    } else if (location.includes(SCREENS.MY_ADDRESSES())) {
      return localeStore.t('my_addresses');
    } else if (location.includes(SCREENS.RESERVATIONS())) {
      return localeStore.t('txt_myreservations');
    } else if (location.includes(SCREENS.USER())) {
      return localeStore.t('Edit profile');
    } else if (location.includes(SCREENS.WALLET())) {
      return localeStore.t('wallet');
    } else if (location.includes(SCREENS.LOCATIONS())) {
      return localeStore.t('locations');
    } else if (location.includes(SCREENS.SUBSCRIPTIONS())) {
      return localeStore.t('subscriptions');
    } else {
      return '';
    }
  }

  get pageCount() {
    const location = window.location.href;
    if (location.includes(SCREENS.ORDERS())) {
      return ordersStore.pagination.total;
    } else if (location.includes(SCREENS.RESERVATIONS())) {
      return reservationsStore.pagination.total;
    } else {
      return null;
    }
  }

  get list() {
    const list = [
      { text: localeStore.t('txt_orders'), to: SCREENS.ORDERS() },
      { text: localeStore.t('my_addresses'), to: SCREENS.MY_ADDRESSES() },
      { text: localeStore.t('txt_profile'), to: SCREENS.USER() },
    ];

    if (appStore.settings?.is_waitlist && appStore.mode !== 'e-menu') {
      list.splice(1, 0, { text: localeStore.t('txt_myreservations'), to: SCREENS.RESERVATIONS() });
    }

    if (appStore.settings?.is_wallet) {
      list.push({ text: localeStore.t('wallet'), to: SCREENS.WALLET() });
    }

    return list;
  }

  get pageCountIsLoading() {
    const location = window.location.href;
    if (location.includes(SCREENS.ORDERS())) {
      return ordersStore.pagination.loading;
    } else if (location.includes(SCREENS.RESERVATIONS())) {
      return false;
    } else {
      return false;
    }
  }

  get isReservationsScreen() {
    const location = window.location.href;
    return location.includes(SCREENS.RESERVATIONS());
  }

  render() {
    return (
      <>
        <div className="tabs d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center mb-2 mt-2 mb-lg-3 mt-lg-5">
            <Link
              rel="prev"
              title={this.pageTitle}
              hrefLang={localeStore.language}
              to={{
                pathname: SCREENS.MENU(),
                search: `?language=${localeStore.language}`,
              }}
            >
              <BackIcon>
                <PageTitle title={this.pageTitle} count={this.pageCount} countIsLoading={this.pageCountIsLoading} />
              </BackIcon>
            </Link>
          </div>
          {this.isReservationsScreen && (
            <Link
              rel="bookmark"
              title="reservation"
              hrefLang={localeStore.language}
              className="create-reservation-link mt-5 mt-lg-0"
              to={{
                pathname: SCREENS.CREATE_RESERVATION(),
                search: `?language=${localeStore.language}`,
              }}
            >
              <Icon name={ICONS.PLUS} size={25} color={'#B6B6B6'} />
            </Link>
          )}
        </div>

        <ResponsiveContent min={WINDOW_SIZES.sm}>
          <TabList list={this.list} className="more-tabs" />
        </ResponsiveContent>
      </>
    );
  }
}

export default withRouter(Tabs);
