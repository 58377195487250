import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatPrice } from 'src/utils/formatter';
import { getWalletCurrency } from 'src/utils/helpers';
import LoadingCircle from '../../../LoadingCircle/LoadingCircle';

function CheckoutTotalInfoWallet() {
  const showWallet = React.useMemo(
    () => appStore.settings?.is_wallet && branchStore.branch?.is_wallet && cartStore.calculator.wallet_discount,
    [appStore.settings?.is_wallet, branchStore.branch?.is_wallet, cartStore.calculator.wallet_discount]
  );

  return showWallet ? (
    <div className="checkout-total-info-container">
      <p className={'color-dark family-semibold text-size-14'}>{localeStore.t('wallet_discount')}</p>
      <p id="checkout_wallet_discount" className={'color-dark family-semibold text-size-14 text-uppercase'}>
        <LoadingCircle>{formatPrice(-cartStore.calculator.wallet_discount, getWalletCurrency())}</LoadingCircle>
      </p>
    </div>
  ) : null;
}
export default observer(CheckoutTotalInfoWallet);
