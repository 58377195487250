import { localeStore } from 'src/mobx/localesStore';
import { IResponseProductModifierOption } from 'src/types/products';
import { formatUnit } from 'src/utils/formatter';

/**
 * get list of units
 */
export const getResponseProductModifierOptionUnits = (option: IResponseProductModifierOption | undefined): string[] => {
  return [_getOptionCalories(option), _getOptionUnit(option)].filter(Boolean) as string[];
};

/**
 * get calories string from option
 */
const _getOptionCalories = (option: IResponseProductModifierOption | undefined): string | null => {
  if (!option || typeof option.calories !== 'number') return null;

  return `${option.calories} ${localeStore.t('kcal')}`;
};

/**
 * get unit string if additional unit is exist in option
 */
const _getOptionUnit = (option: IResponseProductModifierOption | undefined): string | null => {
  if (!option || !option.unit) return null;
  return formatUnit(option.unit, option.weight);
};
