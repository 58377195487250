import { observer } from 'mobx-react';
import { ChangeEvent, Component } from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { ICONS } from 'src/constants/icons';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { ICheckoutCurbside } from 'src/types/cart';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Input from 'src/view/components/Controls/Input/Input';
import Toggle from 'src/view/components/Controls/Toggle/Toggle';
import Tooltip from 'src/view/components/Tooltip/Tooltip';
import './style.scss';

@observer
export default class CheckoutCurbside extends Component {
  state = {};

  toggleCurbside = () => {
    cartStore.toggleCurbside();
  };

  onChange = (name: keyof ICheckoutCurbside) => (event: ChangeEvent<HTMLInputElement>) => {
    cartStore.setCurbsideValue(name, event.target.value);
  };

  openModal = () => {
    cartStore.setModalValue('curbside', true);
  };

  render() {
    if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY || !branchStore.branch.is_curbside) return null;
    const { car_brand, car_color, car_type, car_number } = cartStore.checkout.curbside;
    return (
      <div className="checkout_curbside ">
        <div className="d-none d-lg-flex flex-column mb-4">
          <div className="d-flex flex-row justify-content-between">
            <label style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <Toggle checked={cartStore.checkout.is_curbside} onToggle={this.toggleCurbside} />
              <span className="color-dark family-regular text-size-18">{localeStore.t('Delivery to car')}</span>
            </label>
            <Tooltip
              content={() => (
                <div className="card" style={{ padding: 15, minWidth: 200 }}>
                  <p className={'color-dark text-size-14 family-regular'}>{localeStore.t('curbside_info_popup')}</p>
                </div>
              )}
            >
              <Icon name={ICONS.QUESTION} className="text-pointer color-gray" />
            </Tooltip>
          </div>

          {cartStore.checkout.is_curbside ? (
            <div className="d-flex flex-row align-items-center flex-wrap mt-4 mx-n1">
              <div className="d-flex flex-row mb-2 full-width">
                <div className="d-flex flex-row  full-width">
                  <Input
                    inputClassName={'family-semibold text-size-16 color-dark p-0 '}
                    className="col-12 px-1 text-pointer mb-2"
                    value={cartStore.checkout.curbside.car_brand}
                    onChange={this.onChange('car_brand')}
                    placeholder={localeStore.t('car_brand')}
                    required
                  />
                </div>
                <div className="d-flex flex-row full-width">
                  <Input
                    inputClassName={'family-semibold text-size-16 color-dark p-0 '}
                    className="col-12 px-1 text-pointer mb-2"
                    placeholder={localeStore.t('car_color')}
                    onChange={this.onChange('car_color')}
                    value={cartStore.checkout.curbside.car_color}
                    required
                  />
                </div>
              </div>
              <div className="d-flex flex-row mb-2 full-width">
                <div className="d-flex flex-row full-width">
                  <Input
                    inputClassName={'family-semibold text-size-16 color-dark p-0 '}
                    className="col-12 px-1 text-pointer mb-2"
                    value={cartStore.checkout.curbside.car_type}
                    onChange={this.onChange('car_type')}
                    placeholder={localeStore.t('car_type')}
                    required
                  />
                </div>
                <div className="d-flex flex-row full-width">
                  <Input
                    inputClassName={'family-semibold text-size-16 color-dark p-0 '}
                    className="col-12 px-1 text-pointer mb-2"
                    value={cartStore.checkout.curbside.car_number}
                    onChange={this.onChange('car_number')}
                    placeholder={localeStore.t('car_number')}
                    required
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="d-flex d-lg-none flex-row justify-content-between py-3" onClick={this.openModal}>
          <div className="d-flex flex-column overflow-hidden">
            <span className="text-size-16 family-semibold color-dark">{localeStore.t('Delivery to car')}</span>
            {cartStore.checkout.is_curbside ? (
              <span className="text-size-10 color-gray checkout_curbside-text">{`${car_brand} ${car_type} ${car_number} ${car_color}`}</span>
            ) : null}
          </div>
          {!cartStore.checkout.is_curbside ? (
            <Icon name={ICONS.CHEVRON} className="color-dark arrow-right" />
          ) : (
            <Icon name={ICONS.PENCIL} className="d-flex align-items-center color-dark " />
          )}
        </div>
      </div>
    );
  }
}
