import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { ICONS } from 'src/constants/icons';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { useGetCookiesTerm } from './hooks/useGetCookiesTerm';
import './style.scss';

const Cookies = observer(() => {
  const [visibility, setVisibility] = useState(true);
  const cookiesTerm = useGetCookiesTerm();

  const hideCookies = useCallback(() => {
    setVisibility(false);
  }, []);

  if (
    appStore.cookies_accepted_at ||
    !cookiesTerm ||
    !localeStore.timestamp || // Wait Translations
    !visibility
  ) {
    return null;
  }

  return (
    <div className="cookies background-white">
      <p className="cookies-title family-bold color-dark">
        {/* Close Icon */}
        <div onClick={hideCookies}>
          <Icon name={ICONS.CLOSE} className={'color-gray cookies-close'} size={14} />
        </div>
        {/* Title */}
        {cookiesTerm.title}
      </p>

      <div className="cookies-body">
        {/* Message */}
        <p className="cookies-message color-dark" dangerouslySetInnerHTML={{ __html: cookiesTerm.message }}></p>
        {/* Save Button */}
        <ButtonComponent onClick={appStore.acceptCookies} title={localeStore.t('txt_accept')} />
      </div>
    </div>
  );
});

export default Cookies;
