import { FC, ReactElement, memo, useMemo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductModifier, IProductModifierOption } from 'src/types/products';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import './style.scss';

interface IProps {
  modifiers: IProductModifier[] | undefined;
}

const Option = (option: IProductModifierOption): ReactElement => {
  const price: string = formatPrice(option.price * option.quantity);
  const showQuantity: boolean = Boolean(option.quantity && option.quantity > 1);

  return (
    <div className="product-meal-group-mobile-item-modifiers__option pxpl-1">
      <p className="product-meal-group-mobile-item-modifiers__option-name-block">
        <span className="family-semibold text-size-10 color-dark text-overflow">{getTranslate(option.name)}</span>
        {showQuantity ? (
          <span className="family-semibold text-size-10 color-gray text-overflow">x{option.quantity}</span>
        ) : null}
      </p>

      <p className="family-semibold text-size-12 color-dark text-overflow">{price}</p>
    </div>
  );
};

const Modifier = (modifier: IProductModifier): ReactElement => {
  return (
    <div className="product-meal-group-mobile-item-modifiers__modifier">
      <p className="family-semibold text-size-12 color-dark text-overflow">{getTranslate(modifier.name)}</p>

      {modifier.options.map(Option)}
    </div>
  );
};

export const MealComboGroupMobileItemModifiers: FC<IProps> = memo(({ modifiers }: IProps): ReactElement | null => {
  const selectedModifiers = useMemo((): IProductModifier[] => {
    if (!modifiers?.length) return [];

    return structuredClone(modifiers).reduce((sum: IProductModifier[], current: IProductModifier) => {
      current.options = current.options.filter((option) => option.is_selected || option.selected);

      if (current.options.length) sum.push(current);

      return sum;
    }, []);
  }, [modifiers]);

  if (!selectedModifiers.length) return null;

  return (
    <div className="product-meal-group-mobile-item-modifiers">
      <p className="family-semibold text-size-10 color-gray letter-spacing-5 text-uppercase">
        {localeStore.t('modifiers')}
      </p>

      {selectedModifiers.map(Modifier)}
    </div>
  );
});
