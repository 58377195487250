import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Spinner from 'src/view/components/Controls/Spinner/Spinner';
import './style.scss';
interface Props {
  onZoomLevelChanged: (increase: boolean) => void;
  setCenter: (center: GoogleMapReact.Coords, position?: GeolocationPosition | null) => void;
  getUserPosition: () => void;
  gettingUserPosition: boolean;
}

export default class BranchesMapControls extends Component<Props> {
  increaseZoomLevel = () => this.props.onZoomLevelChanged(true);
  decreaseZoomLevel = () => this.props.onZoomLevelChanged(false);

  render() {
    return (
      <div className="branches-controls-wrapper">
        <div className="branches-controls-control d-flex flex-column">
          <Icon
            onPress={this.increaseZoomLevel}
            name={ICONS.PLUS}
            size={24}
            className={'color-gray  branches-controls-icon'}
          />
          <Icon
            onPress={this.decreaseZoomLevel}
            name={ICONS.MINUS}
            size={24}
            className={'color-gray branches-controls-icon'}
          />
        </div>
        <div className="branches-controls-control  d-flex flex-column mt-4">
          <Icon
            onPress={this.props.getUserPosition}
            disabled={this.props.gettingUserPosition}
            name={ICONS.TARGET}
            size={24}
            className={'color-gray branches-controls-icon'}
          />
          {this.props.gettingUserPosition ? <Spinner className={'branches-controls-spinner'} /> : null}
        </div>
      </div>
    );
  }
}
