import { observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { cartStore } from 'src/mobx/cartStore';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';
import { localeStore } from '../../../../../../../mobx/localesStore';
import './style.scss';

@observer
class CheckoutModalPromocode extends Component<RouteComponentProps<null>> {
  renderError = ({ closeModal }: { closeModal: () => void }) => {
    const { error } = cartStore.checkout.modals;
    return (
      <ModalMessage
        wrapperClassName={'px-0 pb-0 pt-3'}
        minWidth={320}
        title={error?.title}
        message={error?.message}
        options={error?.options}
        closeModal={closeModal}
      />
    );
  };

  renderContent = ({ closeModal }: { closeModal: () => void }) => {
    //  handler click to close button
    const handlerClose = () => {
      closeModal();
      cartStore.calculator.discount_error = undefined;

      // Clear form promocde and recalculate checkout
      cartStore.clearPromocode();
    };
    const error_code_auth_required: boolean = cartStore.calculator.discount_error?.errorCode === 10403;
    return (
      <ModalMessage
        wrapperClassName={'px-0 pb-0'}
        minWidth={320}
        title={cartStore.calculator.discount_error?.error_promo}
        options={
          error_code_auth_required
            ? [
                {
                  text: localeStore.t('txt_login'),
                  onPress: () => {
                    closeModal();
                    cartStore.setModalValue('login', true);
                  },
                },
                {
                  className: 'background-light',
                  textClassName: 'color-dark',
                  text: localeStore.t('Close'),
                },
              ]
            : []
        }
        closeModal={closeModal}
      />
    );
  };

  render() {
    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose
        backdrop
        modalClassName="checkout-modal-promocode"
        visible={Boolean(cartStore.calculator.discount_error)}
        onClose={() => {
          cartStore.calculator.discount_error = undefined;
          cartStore.clearPromocode();
        }}
        content={this.renderContent}
        mobile={{
          popup: true,
          sheet: false,
        }}
      />
    );
  }
}

export default withRouter(CheckoutModalPromocode);
