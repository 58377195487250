import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import HeaderLogo from '../../../HeaderLogo/HeaderLogo';
import { appStore } from 'src/mobx/appStore';

export default class HamburgerMobileHead extends Component {
  changeLanguage = () => (localeStore.language === 'en' ? localeStore.setLocale('ar') : localeStore.setLocale('en'));

  render() {
    return (
      <div className="d-flex flex-column pt-3 px-3">
        <div className="d-flex flex-row  justify-content-between aliggn-items-center mb-4">
          <HeaderLogo logoUrl={appStore.settings?.img_app_header_logo} />

          <div className="d-flex flex-row justify-content-between">
            <Icon className="th-background-secondary th-selected_hover-primary text-size-18" name={ICONS.CLOSE} />
          </div>
        </div>

        <div className="d-flex flex-row  justify-content-between aliggn-items-center">
          <div
            onClick={this.changeLanguage}
            className="th-bg-background-secondary th-color-primary color-dark text-size-16"
            style={{
              borderRadius: 8,
              padding: '8px 20px',
            }}
          >
            {localeStore.language === 'en' ? localeStore.t('txt_arabic') : localeStore.t('txt_english')}
          </div>
        </div>

        <p className="hamburger-menu-head th-background-secondary  mt-5">{localeStore.t('menu_setting')}</p>
      </div>
    );
  }
}
