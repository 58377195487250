import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';
import { localeStore } from 'src/mobx/localesStore';
import './style.scss';

interface ILink {
  text: string;
  to: string;
}

export interface ITabListProps {
  list: ILink[];
  className?: string;
}

export default class TabList extends React.Component<ITabListProps> {
  renderLink = (link: ILink) => (
    <li key={link.to} className="mr-3">
      <NavLink
        className="family-semibold tabs-link tab color-darkgray  color_hover-dark text-elipsis slidein"
        activeClassName="active"
        hrefLang={localeStore.language}
        rel="tag"
        to={{
          pathname: link.to,
          search: `?language=${localeStore.language}`,
        }}
      >
        {localeStore.timestamp ? link.text : <Skeleton width={80} />}
      </NavLink>
    </li>
  );
  public render() {
    return <ul className={`tabs  ${this.props.className}`}>{this.props.list.map(this.renderLink)}</ul>;
  }
}
