import { useEffect, useRef, useState } from 'react';

export const useCategoriesVisibility = (
  ...deps: any[]
): [React.RefObject<HTMLUListElement>, { [key: string]: boolean }] => {
  const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});

  const containerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: containerRef.current,
      threshold: 1.0,
    });

    const elements = containerRef.current?.querySelectorAll('.tab');
    elements?.forEach((element) => observer.observe(element));

    return () => {
      elements?.forEach((element) => observer.unobserve(element));
    };
  }, [containerRef.current, ...deps]);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const updatedEntries: { [key: string]: boolean } = {};

    entries.forEach((entry) => {
      const targetId = (entry.target as HTMLElement).dataset.targetid;
      if (targetId) updatedEntries[targetId] = entry.isIntersecting;
    });

    setVisibilityMap((prev) => ({ ...prev, ...updatedEntries }));
  };

  return [containerRef, visibilityMap];
};
