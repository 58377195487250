import React from "react";
import "./style.scss";

interface IProps {
    children?: React.ReactNode,
    [x:string]: any; // rest props
}

export const PageContainer: React.FC<IProps> = ({ children, ...props }) => {
    return (
        <div className="page-container-row" {...props}>
            <div className="page-container row">
                {children}
            </div>
        </div>
    )
}
