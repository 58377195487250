import { observer } from 'mobx-react-lite';
import PreorderList from '../../components/PreorderList/PreorderList';
import { preorderPickerStore } from '../../store/store';
/**
 * Scrollable list of Preorder times
 *
 * @type {React.FC}
 */
const PreorderTimes: React.FC = observer(() => {
  return (
    <PreorderList
      data_testid="preorder-times"
      data={preorderPickerStore.times}
      activeIndex={preorderPickerStore.time}
      onSelect={preorderPickerStore.selectTime}
    />
  );
});

export default PreorderTimes;
