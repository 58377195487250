import { toJS } from 'mobx';
import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { ICONS } from '../../../../../constants/icons';
import { SCREENS } from '../../../../../constants/screens';
import { localeStore } from '../../../../../mobx/localesStore';
import { IReservation } from '../../../../../types/reservations';
import { Card, CardKey, CardRow, CardStatus } from '../../../../components/CardComponents/Card';
import Icon from '../../../../components/Controls/Icon/Icon';
import './style.scss';
import { dateFormat } from '../../../../../utils/helpers';
import { getTranslate } from 'src/utils/formatter';
export interface IReservationItemProps {
  reservation?: IReservation;
}

export default class ReservationItem extends React.PureComponent<IReservationItemProps> {
  date = this.props.reservation?.reservation_date || new Date();

  public render() {
    const { reservation } = this.props;
    return (
      <Link
        rel="tag"
        title="reservation"
        hrefLang={localeStore.language}
        to={{
          pathname: reservation ? SCREENS.RESERVATION(reservation.id) : '',
          search: `?language=${localeStore.language}`,
          state: {
            reservation: toJS(reservation),
          },
        }}
      >
        <Card className="reservation">
          <div className="reservation-status-row">
            <CardKey>#{reservation?.whaitlist_id || <Skeleton width={100} />}</CardKey>
            {reservation ? (
              <CardStatus status={localeStore.t(`txt_${reservation.status}`)} statusCode={reservation.status} />
            ) : (
              <Skeleton width={75} />
            )}
          </div>

          <CardRow>
            <Icon name={ICONS.CALENDAR} size={18} color={'#6D6D6D'} className="reservation-icon mr-2" />
            {reservation ? (
              `${localeStore.t(`month_${moment(this.date).format('M')}`)} ${moment(this.date).format('DD hh:mm a')}`
            ) : (
              <Skeleton width={200} />
            )}
          </CardRow>

          <CardRow>
            <Icon name={ICONS.PICKUP} size={18} color={'#6D6D6D'} className="reservation-icon  mr-2" />
            {getTranslate(reservation?.branch.name) || <Skeleton width={110} />}
          </CardRow>

          <CardRow>
            <Icon name={ICONS.BELL} size={15} color={'#6D6D6D'} className="reservation-icon plate-icon  mr-2" />
            {reservation ? reservation.type : <Skeleton width={100} />}
          </CardRow>

          <CardRow>
            <Icon name={ICONS.PROFILE} size={19} color={'#6D6D6D'} className="reservation-icon  mr-2" />
            {reservation?.people_number || <Skeleton width={100} />}
          </CardRow>
        </Card>
      </Link>
    );
  }
}
