import { IPaymentTypeId } from 'src/types/app';
import { DAYS_IN_WEEK, IBranch } from 'src/types/branch';
import { DELIVERY_TYPES } from './deliveryTypes';

export const LOCALES = {
  EN: 'en',
  AR: 'ar',
  en: 'EN',
  ar: 'AR',
};

export const DAYS_INDEX_IN_WEEK = {
  [DAYS_IN_WEEK.SUNDAY]: 0,
  [DAYS_IN_WEEK.MONDAY]: 1,
  [DAYS_IN_WEEK.TUESDAY]: 2,
  [DAYS_IN_WEEK.WEDNESDAY]: 3,
  [DAYS_IN_WEEK.THURSDAY]: 4,
  [DAYS_IN_WEEK.FRIDAY]: 5,
  [DAYS_IN_WEEK.SATURDAY]: 6,
};

export enum ORDER_STATUS {
  DRAFT = 'df',
  PENDING = 'p',
  CANCELED = 'ca',
  ASSIGNED = 'as',
  COMPLETED = 'd',
  READY_FOR_PICKUP = 'da',
  ACCEPTED = 'a',
  RETURNED = 'r',
  WAITING_FOR_PAYMENT = 'w',
  PROCESSING = 'pr',
  PICKEDUP = 'pi',
  OUT_FOR_DELIVERY = 'o',
  WAITING_FOR_PICKUP = 'da',
  ON_HOLD = 'h',
}

export enum PAYMENT_STATUS {
  FAIL = 'f',
  SUCCESS = 's',
  NON_RECEIVED = 'n',
  UNDEFINED = '',
}

export const WINDOW_SIZES = {
  xs: 575,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 2000,
};
window.SETTINGS =
  process.env.NODE_ENV === 'production' && window.APP_SETTINGS ? JSON.parse(window.APP_SETTINGS) : undefined;

export const defaultBranch: IBranch = {
  id: 0,
  is_busy: false,
  busy_expire_in: null,
  distance: '0 km',
  name: {
    en: '',
  },
  latitude: 22.66058572209651,
  longitude: 48.66347673601963,
  status: 1,
  is_free_delivery: 1,
  delivery_fee: 0,
  free_delivery: 0,
  payment_type: 1,
  default_payment_type: 0,
  pre_select_default: 0,
  delivery_type:
    window.SETTINGS?.delivery_type === DELIVERY_TYPES.BOTH || window.SETTINGS?.delivery_type === DELIVERY_TYPES.DELIVERY
      ? DELIVERY_TYPES.DELIVERY
      : DELIVERY_TYPES.PICKUP,
  subscription_delivery_type: 'b',
  preorder: null,
  min_order: 0,
  max_cash_payment: 0,
  is_curbside: 0,
  curbside_fee: 0,
  payment_types: [
    { id: 0, type: 'Cash' },
    { id: 1, type: 'Online Payment' },
    { id: 3, type: 'Payment Terminal' },
  ],
  address: 'Riyadh',
  working_hours: '12:00 AM - 11:59 PM',
  working_now: true,
  working_time: [],
  closest_day: {
    working_day: 'tuesday',
    start_time: '00:00',
    close_time: '23:59',
  },
  work_slots: [],
  branchCovered: false,
  allow_all_payment_methods: 0,
  preparation: null,
  current_reduced_settings: [],
};

export enum PAYMENT_TYPES {
  CASH,
  ONLINE_PAYMENT,
  POINTS,
  TERMINAL,
  APPLE_PAY,
}

export const GENDERS = [
  { value: 'male', name: 'txt_male' },
  { value: 'famale', name: 'txt_female' },
];

export enum E_MENU_TYPE {
  DINE_IN = 1,
  TO_GO = 2,
}
export const PAGE_FROM = {
  SMS: 'sms_page',
};

export const PAYMENT_TYPE_NAMES = {
  [PAYMENT_TYPES.CASH]: 'Cash',
  [PAYMENT_TYPES.ONLINE_PAYMENT]: 'Credit/Debit cart',
  [PAYMENT_TYPES.POINTS]: 'Points',
  [PAYMENT_TYPES.TERMINAL]: 'Terminal',
  [PAYMENT_TYPES.APPLE_PAY]: 'ApplePay',
};
