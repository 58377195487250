import { PAYMENT_TYPES } from '../../../../constants/main';
import { IPaymentCardNew } from '../../../../types/checkout';
import { IBranch } from '../../../../types/branch';
import { IOrder } from '../../../../types/orders';
import React, { useCallback } from 'react';
import { userStore } from '../../../../mobx/userStore';
import { PAYMENT_TYPE_ICONS } from '../../../../constants/icons';
import { localeStore } from '../../../../mobx/localesStore';
import { IPaymentCard } from '../../../../types/user';

type SelectedCardProps = {
  text: string;
  icon?: string;
  image?: any;
  selected?: boolean;
  available?: boolean;
  rightIconProps?: string;
  onPress?: ((event: any) => void) | undefined;
};

interface ISelectedCardProps {
  storeProps: IPaymentStoreProps;
  cardIcon?: boolean;
  rightIcon?: string;
}

export interface IPaymentStoreProps {
  selectedPaymentType: PAYMENT_TYPES | undefined;
  selectedBranch: IBranch | undefined;
  selectedСard: IPaymentCard['id'] | null;
  newCard: IPaymentCardNew | null;
  order: IOrder | undefined;
  availablePaymentTypes: IBranch['payment_types'];
  selectedPaymentCard: IPaymentCardNew | IPaymentCard | undefined;
  defaultPaymentType: PAYMENT_TYPES | undefined;
  paymentType: PAYMENT_TYPES | undefined;
  isPaymentTypeAvailable: boolean;
  selectPaymentType: (paymentType: PAYMENT_TYPES) => void;
  selectPaymentCard: (payment_card: IPaymentCard) => void;
  selectNewPaymentCard: (payment_card?: IPaymentCardNew) => void;
  clearPaymentCard: () => void;
  isApplePaymentAvailable: boolean;
  openModal: () => void;
}

const SelectedCardProps = ({ storeProps, cardIcon = true, rightIcon }: ISelectedCardProps): SelectedCardProps => {
  const openPopup = useCallback(() => {
    storeProps.openModal();
  }, []);

  if (storeProps.paymentType === undefined) {
    return {
      text: localeStore.t('Select_payment_type_field'),
      icon: cardIcon ? PAYMENT_TYPE_ICONS[PAYMENT_TYPES.CASH] : null,
      available: false,
      rightIconProps: rightIcon || undefined,
      onPress: openPopup,
    };
  }

  // online payment selected
  if (storeProps.paymentType === PAYMENT_TYPES.ONLINE_PAYMENT) {
    if (storeProps.selectedPaymentCard) {
      return {
        text: `${localeStore.t('card_number')} **** ${storeProps.selectedPaymentCard.last}`,
        image: cardIcon ? PAYMENT_TYPE_ICONS[storeProps.paymentType] : null,
        available: storeProps.isPaymentTypeAvailable,
        rightIconProps: rightIcon || undefined,
        onPress: openPopup,
      };
    }
  }

  if (storeProps.paymentType === PAYMENT_TYPES.ONLINE_PAYMENT) {
    return {
      text: userStore.payment_cards.length
        ? localeStore.t(`${storeProps.paymentType}_payment_type`)
        : localeStore.t('RadioCreditdebit'),
      icon: cardIcon ? PAYMENT_TYPE_ICONS[storeProps.paymentType] : undefined,
      available: storeProps.isPaymentTypeAvailable,
      rightIconProps: rightIcon || undefined,
      onPress: openPopup,
    };
  }

  return {
    text: localeStore.t(`${storeProps.paymentType}_payment_type`),
    icon: cardIcon ? PAYMENT_TYPE_ICONS[storeProps.paymentType] : undefined,
    available: storeProps.isPaymentTypeAvailable,
    rightIconProps: rightIcon || undefined,
    onPress: openPopup,
  };
};
export default SelectedCardProps;
