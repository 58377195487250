import { observer } from 'mobx-react';
import * as React from 'react';
import { appStore } from 'src/mobx/appStore';
import { productsStore } from 'src/mobx/productsStore';
import MenuProduct from '../../components/MenuProduct/MenuProduct';
import { MenuSearchItems } from '../MenuSearchItems/MenuSearchItems';
import Spinner from 'src/view/components/Controls/Spinner/Spinner';
import './style.scss';

@observer
class MenuProducts extends React.Component {
  /**
   * Disable the donation item if the e-menu is open
   *
   * @readonly
   * @memberof MenuProducts
   */
  get products() {
    if (appStore.mode === 'e-menu' && appStore?.settings?.donation?.enabled) {
      return productsStore?.category?.items.data.filter((item) => !item.is_donation);
    } else {
      return productsStore?.category?.items.data;
    }
  }

  loader = () => <Spinner className="page-title-count-spinner ml-3" color={'#6D6D6D'} size={26} />;

  renderProduct = (product: any) => <MenuProduct key={product.id} product={product} />;

  renderProducts = () => {
    return this.products?.map(this.renderProduct);
  };

  public render() {
    if (productsStore.loading) {
      return (
        <div className={'menu-list'}>
          <div className="menu-products-list">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
              <MenuProduct key={i} />
            ))}
          </div>
        </div>
      );
    }

    if (!appStore.settings?.is_restaurant_working || !productsStore.categories.length) return null;
    return (
      <div className={'menu-list'}>
        <div className="menu-products-list" key={productsStore.category?.id}>
          {productsStore.searchIsActive ? <MenuSearchItems /> : <>{this.renderProducts()}</>}
        </div>
      </div>
    );
  }
}

export default MenuProducts;
