import axios from 'axios';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { E_MENU_TYPE } from 'src/constants/main';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { preorderStore } from 'src/mobx/preorderStore';
import { productsStore } from 'src/mobx/productsStore';
import client from 'src/services/client';
import { IBranch } from 'src/types/branch';
import { TLocale } from 'src/types/main';
import { ICategory, IProduct } from 'src/types/products';
import { IPaymentCard, IUpdateUser, IUser } from 'src/types/user';
import { logError } from 'src/utils/helpers';
import { productUtils } from 'src/utils/productUtils';
import { appStore } from '../mobx/appStore';
import { userStore } from '../mobx/userStore';
import { IOrder } from '../types/orders';
import branches from './branch';
import user from './user';

class Requests {
  branches = branches;
  user = user;

  login = async (number: string, captcha?: string | null) => {
    const body: { [key: string]: string } = { phone_number: number };

    if (captcha) {
      body.captcha = captcha;
    }

    return await client.post(`/v3/auth/authorize`, body);
  };

  confirm = async (otp: string, number: string) => {
    return await client.post(`/v3/auth/confirm`, { phone_number: number, otp });
  };

  fetchLocales = async (language: TLocale) => {
    const locale = await client.get(`/v3/app/locales?language=${language}`, {
      headers: {
        'Accept-Language': language,
      },
    });
    return locale;
  };

  fetchAppSettiings = async () => {
    const response = await client.get(`/v3/app/settings`, { params: { language: localeStore.language } });
    return response;
  };

  fetchCashbackInfo = async (productId: IProduct['id'] | undefined = 0, branchId: IBranch['id'], orderType: string) => {
    if (!branchId) {
      return null;
    }
    const response = await client.get(`/v3/products/${productId}/cashback`, {
      params: {
        branch_id: branchId,
        order_type: orderType,
        time: preorderStore.preorder_unix ? Math.round(preorderStore.preorder_unix) : undefined,
      },
    });
    return response;
  };

  fetchProduct = async (id: IProduct['id']) => {
    const params: { branch_id?: number } = {};
    if (branchStore.branch.id) {
      params.branch_id = branchStore.branch.id;
    }
    const response = await client.get(`/v4/products/${id}`, {
      params,
    });
    return productUtils.transformProduct(response);
  };

  createOptions = async (type: string, data: {}) => {
    const newParams = {
      delivery_type: branchStore.delivery_type,
      time: preorderStore.preorder_unix ? preorderStore.preorder_unix / 1000 : undefined,
      branch_id: branchStore.branch.id,
      menu_update: productsStore.cacheParameters.menu_update,
      global_category_id: productsStore.cacheParameters.global_category_id,
      is_donation: 1,
      ...data,
    };

    if (appStore.mode === 'e-menu' && appStore?.settings?.donation?.enabled) {
      newParams.is_donation = 0;
    }
    if (branchStore.order_type === E_MENU_TYPE.DINE_IN) {
      newParams.delivery_type = DELIVERY_TYPES.DINE_IN;
    }

    if (!branchStore?.branch?.id) {
      // @ts-ignore
      delete newParams.branch_id;
    }
    if (!productsStore.cacheParameters.global_category_id) {
      delete newParams.global_category_id;
    }

    return newParams;
  };

  fetchMenu = async (
    search?: string
  ): Promise<{ categories: ICategory[]; menu_update: number; global_category_id: number }> => {
    const params = await this.createOptions('fetchMenu', { search });
    const response = await client.get(`/v4/categories/products`, { params, headers: { 'Accept-Language': 'en,ar' } });
    const { menu_update, global_category_id, data } = response;
    const categories = data.map(_transformCategory);
    return { menu_update, global_category_id, categories };
  };

  fetchMenuPart = async (id: ICategory['id'], page: number): Promise<ICategory['items']> => {
    const params = await this.createOptions('fetchMenu', { page });
    const response = await client.get(`/v3/categories/${id}/products`, { params });
    return transformCategoryPagination(response);
  };

  getOrderById = async (id: IOrder['id']): Promise<IOrder> => {
    const response = await client.get(`/v3/me/orders/${id}`, { headers: { 'Accept-Language': localeStore.language } });
    return response;
  };

  clientArrivedRequest = async (order_id: IOrder['id']) => {
    return await client.post(`/v2/curbside/client_arrive?order_id=${order_id}&language=${localeStore.language}`, {});
  };

  cancelOrder = async (id: IOrder['id']) => {
    return client.post(`/v3/orders/${id}/cancel`);
  };

  getReservationsList = async () => {
    const response = await client.get(`/v1/api_reservation_list`, {
      params: {
        user_id: userStore.user.id,
        language: localeStore.language,
      },
    });
    return {
      data: response.info,
    };
  };

  getWaitlistData = async (lat?: number, lng?: number) => {
    let URL = `/v1/get_reservation_info`;
    if (lat && lng) {
      URL = `${URL}?latitude=${lat}&longitude=${lng}`;
    }
    const response = await client.get(URL);
    return response;
  };

  fetchProducts = async (ids: number[]): Promise<IProduct[]> => {
    try {
      const { data } = await client.get(`/v4/products`, {
        params: { products: ids, branch_id: branchStore.branch.id },
      });
      return data.map((item: IProduct) => productUtils.transformProduct(item));
    } catch (error: any) {
      logError(error);
      throw error;
    }
  };

  updateUser = async (_user: IUpdateUser): Promise<IUser | null> => {
    try {
      const data = {
        name: _user.name,
        mobile: _user.mobile,
        gender: _user.gender,
        email: _user.email,
        birth_day: _user.birth_day,
      };

      return await client.put('/v3/me', data);
    } catch (error: any) {
      logError(error);
      throw error.response.data;
    }
  };

  fetchUser = async (): Promise<IUser> => {
    return await client.get('/v3/me');
  };

  getIsDomainVerifiedForApplePay = async () => {
    try {
      const response = await axios.get('/.well-known/apple-developer-merchantid-domain-association.txt');
      return !!response.data;
    } catch (error: any) {
      return false;
    }
  };

  validateApplePaySession = (url: string) => {
    return client.post('v4/apple_pay/payment_services/payment_session', { url });
  };

  deleteCreditCard = async (id: IPaymentCard['id']) => {
    return client.delete(`/v3/me/cards/${id}`);
  };

  fetchCookiesTerm = async () => {
    return client.get('/v3/info/cookies');
  };

  async orderActualize(id: number | string, onError?: (error: any) => void): Promise<boolean | undefined> {
    try {
      await client.post(`/v4/orders/${id}/actualize`, {}, undefined);
      return true;
    } catch (error: any) {
      onError?.(error);
      logError(error);
      return undefined;
    }
  }
}

export const requests = new Requests();

export const _transformCategory = (category: any): ICategory => {
  return {
    ...category,
    items: transformCategoryPagination(category.items),
  };
};

export const transformCategoryPagination = (items: any): ICategory['items'] => {
  return {
    current_page: items.current_page,
    from: items.from,
    last_page: items.last_page,
    to: items.to,
    total: items.total,
    loading: false,
    data: items.data.map((item: IProduct) => productUtils.transformProduct(item)),
  };
};
