import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import { reservationsStore } from 'src/mobx/reservationsStore';
import { IBranch } from 'src/types/branch';
import { IReservation } from 'src/types/reservations';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import { IReservationType } from '../../containers/ReservationCreateMap/ReservationCreateMap';
import './style.scss';
interface Props extends RouteComponentProps {
  loading: boolean;
  branch: IBranch | null;
  form: {
    type?: IReservationType;
    people_number: number;
    name: string;
    phone_number: string;
  };
}

@observer
class BranchesSubmitButton extends Component<Props> {
  state = {
    loading: false,
  };

  submit = async () => {
    try {
      this.setState(() => ({ loading: true }));
      if (!this.props.branch || !this.props.form.type) return;
      const reservation: IReservation = await reservationsStore.createReservation({
        people_number: this.props.form.people_number,
        type: this.props.form.type.id,
        branch: this.props.branch.id,
        phone_number: this.props.form.phone_number,
        name: this.props.form.name,
      });

      if (reservation.id) {
        this.props.history.push(SCREENS.RESERVATION(reservation.id), { reservation });
      }
      this.setState(() => ({ loading: false }));
    } catch (error: any) {
      this.setState(() => ({ loading: false }));
      console.error({ error });
    }
  };

  render() {
    return (
      <ButtonComponent
        onClick={this.submit}
        disabled={!this.props.form.type || !this.props.form.name}
        className="branches-submit"
        loading={this.props.loading || !appStore.timestamp || this.state.loading}
        title={localeStore.t('txt_submit')}
      />
    );
  }
}

export default withRouter(BranchesSubmitButton);
