import React, { RefObject } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getTranslate } from 'src/utils/formatter';
import { debounce } from 'src/utils/perfomance';

import { WINDOW_SIZES } from '../../../constants/main';
import ResponsiveContent from '../ResponsiveContent/ResponsiveContent';

import './style.scss';
import Donation from '../Tooltip/Donation/Donation';
import InnerHtml from '../InnerHtml/InnerHtml';

/**
 * @typedef {object} Props
 * @prop {number} aspectRatio
 * @prop {string} wrapperClassName
 * @prop {string} description
 */
interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  aspectRatio?: number;
  wrapperClassName?: string;
  description?: string | undefined;
  is_donation?: number | undefined;
  type?: any;
}

/**
 *
 *  @props {number} height
 * @props {boolean} loading
 * @props {(string|undefined)} src
 * @interface State
 */
interface State {
  height: number;
  loading: boolean;
  src: string | undefined;
  alt?: string | undefined;
  is_donation: boolean | undefined;
}

export default class Image extends React.PureComponent<Props, State> {
  aspectRatio: number = this.props.aspectRatio || 1.5;
  image: RefObject<HTMLImageElement> = React.createRef();
  /** @type {State} */
  state: State = {
    height: 0,
    loading: true,
    src: this.props.src,
    alt: this.props.alt,
    is_donation: false,
  };

  componentDidMount = () => {
    if (this.image.current) {
      const height = this.image.current.clientWidth / this.aspectRatio;
      this.setState({ height });
    }
    window.addEventListener('resize', this.onResize);
  };

  static getDerivedStateFromProps = (props: Props, state: State) => {
    if (props.src !== state.src) {
      return {
        ...state,
        src: props.src,
      };
    }
    return null;
  };

  onImageLoad = () => {
    this.setState({ loading: false });
  };

  onImageLoadError = () => {
    this.setState({
      loading: false,
      src: 'https://osbornegroupcre.com/wp-content/uploads/2016/02/missing-image-640x360.png',
    });
  };

  handleResize = () => {
    if (this.image.current) {
      const height = this.image.current.clientWidth / this.aspectRatio;
      this.setState({ height });
    }
  };

  onResize = debounce(this.handleResize, 100);

  render() {
    const { aspectRatio, is_donation, type, wrapperClassName, description, className, ...props } = this.props;
    const ifSearch = type === 'search-item' ? 'donation-search' : 'donation';
    return (
      <div className={`image-wrapper ${wrapperClassName || ''}`}>
        {/* Use description if needed to showing text on img */}
        {description && (
          <ResponsiveContent min={WINDOW_SIZES.md}>
            <div className={'image_description'}>
              <InnerHtml html={getTranslate(description)} />
            </div>
          </ResponsiveContent>
        )}
        {is_donation ? (
          <div className={`menu-product-donation ${ifSearch}`}>
            <Donation />
          </div>
        ) : null}
        <img
          {...props}
          src={this.state.src}
          onLoad={this.onImageLoad}
          onError={this.onImageLoadError}
          loading={'lazy'}
          className={`image full-width ${className || ''}`}
          style={{
            height: this.state.height,
          }}
          ref={this.image}
          title={this.state.alt}
          alt={this.state.alt}
        />
        {this.state.loading ? (
          <div className={`image-skeleton`}>
            <Skeleton width="100%" height={this.state.height} />
          </div>
        ) : null}
      </div>
    );
  }
}
