import Select, { ItemData, LoadDataParams, LoadDataResponse } from '../../../../components/Controls/Select/Select';
import { IPagination } from '../../../../../types/main';
import { IBranch } from '../../../../../types/branch';
import client from '../../../../../services/client';
import { localeStore } from '../../../../../mobx/localesStore';
import { getTranslate } from 'src/utils/formatter';

type Props = {
  onSelect: (item: IBranch) => void;
  onDeselect: () => void;
  error?: boolean;
  disabled: boolean;
  selected?: ItemData;
  required: boolean;
};

const BranchesSelect = ({ onSelect, onDeselect, error, disabled, selected, required }: Props) => {
  const loadData = async ({ page, search }: LoadDataParams): Promise<LoadDataResponse> => {
    const response: IPagination<IBranch> = await client.get(`/v3/branches`, {
      params: {
        page,
        per_page: 10,
        search,
      },
    });

    const items: ItemData[] = response.data.map(
      (branch): ItemData => ({ label: getTranslate(branch.name), data: branch, key: `${branch.id}` })
    );

    return {
      items,
      hasMore: response.current_page < response.last_page,
      page: page + 1,
    };
  };

  return (
    <Select
      placeholder={localeStore.t('branch')}
      error={error}
      loadData={loadData}
      onOptionSelect={({ data }) => onSelect(data)}
      onDeselect={onDeselect}
      disabled={disabled}
      selected={selected}
      key={`${selected}`}
      required={required}
    />
  );
};

export default BranchesSelect;
