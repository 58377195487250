export const staticTheme = {
  fonts: {
    light: 'PrimaryLight',
    regular: 'PrimaryRegular',
    semibold: 'PrimarySemiBold',
    bold: 'PrimaryBold',
  },
  colors: {
    //   text colors
    color: {
      primary: '#ffae73',
      secondary: '#ffae73',
      dark: '#1d1d1d',
      light: '#f1f1f1',
      gray: '#b6b6b6',
      darkgray: '#6D6D6D',
      lightgray: '#C6C6C6',
      danger: '#ff6d6d',
      white: '#ffffff',
    },
// background colors
    background: {
        primary: '#ffae73',
        secondary: '#ffae73',
        dark: '#1d1d1d',
        light: '#f1f1f1',
        gray: '#b6b6b6',
        darkgray: '#6D6D6D',
        lightgray: '#C6C6C6',
        danger: '#ff6d6d',
        white: '#ffffff',
    },
  },
};
