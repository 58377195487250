import { IOrderProduct, IOrderProductModifier, IOrderProductModifierOption } from 'src/types/orders';
import { PRODUCT_TYPE } from 'src/types/products';
import { getProductMainPrice } from '../getProductMainPrice/getProductMainPrice';

export const calculateOrderProductPrice = (product: IOrderProduct): number => {
  let price = getProductMainPrice(product);

  if (product.type === PRODUCT_TYPE.MEAL_COMBO) {
    if (!product.items?.length) return 0;

    product.items.forEach((item) => {
      const itemModifiersPrice: number = _calculateOrderProductModifiersPrice(item.modifiers);

      price = price + itemModifiersPrice;
    });

    return price * product.quantity;
  }

  price = price + _calculateOrderProductModifiersPrice(product.modifiers);
  price = price * product.quantity;

  return price;
};

const _calculateOrderProductModifiersPrice = (modifiers: IOrderProductModifier[] | undefined): number => {
  if (!modifiers?.length) return 0;

  return modifiers.reduce((sum: number, current) => {
    current.options?.forEach(
      (option: IOrderProductModifierOption) => (sum = sum + option.price * (option.quantity || 1))
    );

    return sum;
  }, 0);
};
