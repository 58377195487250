import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { defaultBranch, WINDOW_SIZES } from '../../../../../../../../../constants/main';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Icon from '../../../../../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../../../../../constants/icons';
import ResponsiveContent from '../../../../../../../../components/ResponsiveContent/ResponsiveContent';
import { branchStore } from '../../../../../../../../../mobx/barnchStore';
import { localeStore } from '../../../../../../../../../mobx/localesStore';
import { appStore } from '../../../../../../../../../mobx/appStore';
import { addressStore } from '../../../../../../../../../mobx/barnchStore/address';
import branchesScreenStore from '../../../../../../branchesScreenStore';
import { observer } from 'mobx-react';

const libraries = ['geometry'];

const AddressFormMap: FC = observer((): JSX.Element | null => {
  const mapRef = useRef<HTMLDivElement>();

  const { isLoaded, loadError } = useJsApiLoader({
    // @ts-ignore
    libraries,
    language: localeStore.language,
    googleMapsApiKey: appStore.settings?.google_maps_pk || '',
  });
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    if (mapRef.current) {
      // @ts-ignore
      mapRef.current.panTo({ lat, lng });
    }
  }, []);
  const defaultCenter = useMemo(() => {
    return {
      lat: branchesScreenStore.address?.latitude || defaultBranch.latitude,
      lng: branchesScreenStore.address?.longitude || defaultBranch.longitude,
    };
  }, []);

  useEffect(() => {
    if (branchStore.selectMapCoordinate) {
      panTo({ lat: branchStore.selectMapCoordinate.latitude, lng: branchStore.selectMapCoordinate.longitude });
    }
  }, [branchStore.selectMapCoordinate]);

  const openMap = useCallback(async () => {
    branchStore.setTempCoordinate({
      latitude: branchesScreenStore.address?.latitude || defaultBranch.latitude,
      longitude: branchesScreenStore.address?.longitude || defaultBranch.longitude,
    });
    await addressStore.toggleAddressOnMap(true);
  }, []);

  return (
    <>
      <ResponsiveContent max={WINDOW_SIZES.md}>
        <div className="edit_address-on-map" onClick={openMap}>
          {isLoaded ? (
            <GoogleMap
              id="map"
              mapContainerStyle={{
                height: '140px',
                width: '100%',
              }}
              center={defaultCenter}
              zoom={4}
              onLoad={onMapLoad}
              options={{
                draggable: false,
                fullscreenControl: false,
              }}
              clickableIcons={false}
            >
              <div className="background-primary branches-current">
                <Icon className="branches-marker color-danger" name={ICONS.MARKER} />
              </div>
            </GoogleMap>
          ) : null}
        </div>
      </ResponsiveContent>
    </>
  );
});
export default AddressFormMap;
