import React, { Component, CSSProperties } from 'react';
import './style.scss';

interface Props {
  name: string;
  disabled?: boolean;
  color?: string;
  size?: number;
  className?: string;
  onPress?: (event: MouseEvent | React.MouseEvent) => void;
  style?: CSSProperties;
  id?: string;
}

export default class Icon extends Component<Props> {
  onPress = (event: MouseEvent | React.MouseEvent) => {
    if (this.props.disabled) return;
    if (this.props.onPress) this.props.onPress(event);
  };

  render() {
    return (
      <i
        id={this.props.id}
        onClick={this.onPress}
        style={{
          fontSize: this.props.size,
          ...(this.props.color ? { color: this.props.color } : {}),
          ...this.props.style,
        }}
        className={`demo-icon icon-${this.props.name} ${this.props.className}`}
      >
        {this.props.children}
      </i>
    );
  }
}
