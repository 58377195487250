import { observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { ICONS } from 'src/constants/icons';
import { WINDOW_SIZES } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { getTranslate } from 'src/utils/formatter';
import Icon from 'src/view/components/Controls/Icon/Icon';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import './style.scss';
import { appStore } from 'src/mobx/appStore';
import { addressStore } from 'src/mobx/barnchStore/address';
import { getDeliveryAddressName } from 'src/utils/helpers';
export interface IDeliveryAddressProps extends RouteComponentProps<any> {}

@observer
class DeliveryAddress extends React.Component<IDeliveryAddressProps> {
  get getAddress() {
    /**
     *  Show current label
     * @type {string}
     */
    let adress: string = getTranslate(localeStore.t('choose_address'));

    if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY) {
      if (addressStore.address) {
        adress = getDeliveryAddressName(addressStore.address, false) || addressStore.address.address;
      }
    } else {
      adress = getTranslate(branchStore.branch.name);
    }
    return adress;
  }

  render() {
    const linkTitle =
      branchStore.delivery_type === DELIVERY_TYPES.DELIVERY
        ? localeStore.t('delivery_to')
        : localeStore.t('pickup_from');

    return (
      <Link
        rel="bookmark"
        title={linkTitle}
        hrefLang={localeStore.language}
        to={{
          pathname: SCREENS.BRANCHES(
            appStore.settings?.delivery_type === DELIVERY_TYPES.BOTH
              ? branchStore.delivery_type
              : appStore.settings?.delivery_type
          ),
          search: `?language=${localeStore.language}`,
        }}
        className="delivery-address th-bg-background-secondary"
      >
        {branchStore.branch.id ? (
          <div className="d-flex flex-row align-items-center full-width">
            <Icon
              className="th-color-primary mr-3"
              name={branchStore.delivery_type === DELIVERY_TYPES.DELIVERY ? ICONS.DELIVERY : ICONS.PICKUP}
            />
            <div className="d-flex flex-column address-field">
              <ResponsiveContent max={WINDOW_SIZES.md}>
                <p className="text-size-10 family-regular  th-color-primary text-uppercase">{linkTitle}</p>
              </ResponsiveContent>

              <p className="family-semibold th-color-primary text-size-16 text-md-size-14">{this.getAddress}</p>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row  align-items-center">
            <Icon className=" th-color-primary mr-3" name={ICONS.DELIVERY} />
            <div className="d-flex flex-column">
              <p className="text-size-10 family-regular color-dark"></p>
              <p className="family-semibold th-color-primary">{localeStore.t('txt_tap_to_select_address')}</p>
            </div>
          </div>
        )}
      </Link>
    );
  }
}

export default withRouter(DeliveryAddress);
