import { FC, ReactElement } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { IProductModifier } from 'src/types/products';
import { productUtils } from 'src/utils/productUtils';
import { ProductModifier } from '../../components/ProductModifier/ProductModifier';
import './style.scss';

interface IProps {
  item: IProductInsideCombo;
  errors: string[];
  needScrollToError: boolean;
  readOnly?: boolean;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  setNeedScrollToError: (need: boolean) => void;
}

export const MealItemModifiers: FC<IProps> = ({ item, ...props }: IProps): ReactElement => {
  const checkModifierErrors = (modifier: IProductModifier): boolean => {
    return productUtils.isMealModifierWithError(item, modifier.id, props.errors);
  };

  return (
    <div className="product-meal-item-modifiers">
      <p className="product-meal-item-modifiers__title color-gray letter-spacing-5">{localeStore.t('modifiers')}</p>

      {item.modifiers?.map((modifier) => (
        <div id={`modifier_${modifier.id}`} key={modifier.id}>
          <ProductModifier
            modifier={modifier}
            isModifierWithError={checkModifierErrors(modifier)}
            needScrollToError={props.needScrollToError}
            onModifierChange={(modifier) => props.onModifierChange(item, modifier)}
            setNeedScrollToError={props.setNeedScrollToError}
            readOnly={props.readOnly}
          />
        </div>
      ))}
    </div>
  );
};
