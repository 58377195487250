import React from 'react';
import './style.scss';
import { localeStore } from '../../../../../mobx/localesStore';

interface IProps {
  errorKey: string;
  errorIcon: boolean;
}

const LinkError: React.FC<IProps> = ({ errorKey, errorIcon = true }) => {
  const failIcon = `${process.env.REACT_APP_CLOUD_FRONT}/media/images/ic_fail.svg`;
  const successfulIcon = `${process.env.REACT_APP_CLOUD_FRONT}/media/images/ic_successful.svg`;
  const icon = errorIcon ? failIcon : successfulIcon;
  return (
    <div className="d-flex justify-content-center container error_link">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img src={icon} title="logo" alt="Link Expire" />
        <h2 className="mt-2 pr-4 pl-4 text-center">{localeStore.t(errorKey)}</h2>
      </div>
    </div>
  );
};

export default LinkError;
