import { IOrder } from '../../../../types/orders';
import { action, computed, observable } from 'mobx';
import { requests } from '../../../../requests';

interface IOrderHandler {
  id: IOrder['id'];
  order?: IOrder | undefined;
  onError?: () => void;
}

class OrderHandler {
  @observable private order: IOrder | undefined = undefined;
  @action public async defineOrder({ id, order, onError }: IOrderHandler) {
    if (order) {
      this.order = order;
      return;
    }

    const getOrder = await requests.getOrderById(id);
    this.order = getOrder;

    if (!this.order) {
      if (onError) {
        onError();
      }
    }
  }
  @computed public get getOrder() {
    return this.order;
  }
  @action public clearOrder = () => {
    this.order = undefined;
  };
}

export default OrderHandler;
