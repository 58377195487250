import { observer } from 'mobx-react';
import { constants } from 'os';
import { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { PAYMENT_TYPES } from 'src/constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { getPaymentType, getUserLastCard } from 'src/utils/helpers';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Tooltip from 'src/view/components/Tooltip/Tooltip';
import { PaymentType } from '../CheckoutPaymentTypes/CheckoutPaymentType/CheckoutPaymentType';
import { PaymentTypeCard } from '../CheckoutPaymentTypes/CheckoutPaymentTypeCard/CheckoutPaymentTypeCard';
import './style.scss';

@observer
export default class CheckoutPaymentType extends Component {
  /**
   * Update select payment card
   *
   * @memberof CheckoutPaymentType
   */
  updateSelectPaymentCard = async () => {
    try {
      const last_payment_card = getUserLastCard(userStore.payment_cards);
      if (last_payment_card) cartStore.selectPaymentCard(last_payment_card);
    } catch (error: any) {
      console.error(error);
    }
  };

  openModal = () => {
    cartStore.setModalValue('paymentType', true);
  };

  /**
   * Return selected card
   *
   * @memberof CheckoutPaymentType
   */
  getSelectedCard = () => {
    return userStore.payment_cards.find((el) => el.id === cartStore.checkout.selected_card);
  };

  /**
   * For render PaymentType
   * Depending on payment_type and selected card
   *
   * @memberof CheckoutPaymentType
   * @returns {PaymentType | PaymentCard} PaymentType or PaymentCard
   */
  renderPaymentType = () => {
    const { payment_cards } = userStore;
    const card = this.getSelectedCard();

    switch (cartStore.checkout.payment_type) {
      case PAYMENT_TYPES.ONLINE_PAYMENT:
        return card ? (
          <PaymentTypeCard card={card} />
        ) : (
          <PaymentType
            icon={ICONS.CREDIT_CARD}
            type_id={PAYMENT_TYPES.ONLINE_PAYMENT}
            text={
              payment_cards.length
                ? localeStore.t('txt_enter_card_details')
                : getPaymentType(cartStore.checkout.payment_type)
            }
          />
        );
      case PAYMENT_TYPES.APPLE_PAY:
        return (
          <PaymentType icon={ICONS.APPLE_PAY} type_id={PAYMENT_TYPES.APPLE_PAY} text={localeStore.t('applePay')} />
        );
      default:
        return (
          <PaymentType
            type_id={cartStore.checkout.payment_type}
            text={getPaymentType(cartStore.checkout.payment_type)}
          />
        );
    }
  };

  render() {
    return (
      <div
        className="checkout-payment-type d-flex d-lg-none full-width justify-content-between"
        onClick={this.openModal}
      >
        <div className="d-flex flex-row align-items-center">
          {this.renderPaymentType()}

          {!cartStore.isValidPaymentType ? (
            <Tooltip
              content={() => (
                <div className="card" style={{ padding: 15, minWidth: 200 }}>
                  <p className={'color-dark text-size-14 family-regular'}>
                    {localeStore.t('txt_invalid_payment_type', { paymentType: cartStore.checkout.payment_type })}
                  </p>
                </div>
              )}
              position="top"
              direction="left"
            >
              <Icon name={ICONS.DANGER} size={20} color="#FF6D6D" className="ml-2 invalid-payment-types-icon" />
            </Tooltip>
          ) : null}
        </div>
        <Icon name={ICONS.CHEVRON} className="arrow-right" />
      </div>
    );
  }
}
