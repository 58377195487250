import { IMealComboGroupWithItems, IProductInsideCombo } from 'src/types/combo';
import { IProduct } from 'src/types/products';
import { isSameComboItemsSizeGroup } from '../isSameComboItemsSizeGroup/isSameComboItemsSizeGroup';

export const getCurrentMealSizeGroups = (product: IProduct | undefined): IMealComboGroupWithItems[] => {
  if (!product?.groups?.length || !product.items?.length) return [];

  const { groups, items } = product;

  return groups.reduce((sum: IMealComboGroupWithItems[], current) => {
    const groupItems: IProductInsideCombo[] = items.filter((item) =>
      isSameComboItemsSizeGroup(item, undefined, { sizeId: product?.size?.id || 0, groupId: current.id })
    );

    sum.push({ ...current, items: groupItems });

    return sum;
  }, []);
};
