import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IProduct } from 'src/types/products';
import { formatUnit, getTranslate } from 'src/utils/formatter';

interface IProps {
  product?: IProduct;
}

const ProductUnit = (props: IProps): JSX.Element | null => {
  const getUnit = (): string | null => {
    if (!props.product) return null;

    return props.product.size
      ? formatUnit(getTranslate(getTranslate(props.product.size.unit)), props.product.size.weight)
      : formatUnit(getTranslate(getTranslate(props.product.unit)), props.product.weight);
  };

  if (!props.product)
    return (
      <div className="product-unit d-flex flex-row align-items-center">
        <Skeleton width={40} />
      </div>
    );

  return getUnit() ? <div className="product-unit d-flex flex-row align-items-center">{getUnit()}</div> : null;
};

export default React.memo(ProductUnit);
