import { observer } from 'mobx-react';
import { ChangeEvent, Component } from 'react';
import { EVENTS } from 'src/constants/events';
import { ICONS } from 'src/constants/icons';
import { GENDERS, WINDOW_SIZES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import logger from 'src/services/logger';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Input from 'src/view/components/Controls/Input/Input';
import RadioSelect, { IRadioSelectOption } from 'src/view/components/Controls/RadioSelect/RadioSelect';
import Sizes from 'src/view/components/Controls/Sizes/Sizes';
import Progress from 'src/view/components/Pregress/Progress';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';

import './style.scss';

@observer
export default class UserEdit extends Component {
  state: any = {
    name: userStore.user.name,
    email: userStore.user.email,
    birth_day: userStore.user.birth_day,
    mobile: userStore.user.mobile,
    gender: userStore.user.gender,

    loading: false,
    isSuccess: false,
  };

  changeName = (event: ChangeEvent<HTMLInputElement>) => this.setState({ name: event.target.value });
  changeEmail = (event: ChangeEvent<HTMLInputElement>) => this.setState({ email: event.target.value });
  changeMobile = (event: ChangeEvent<HTMLInputElement>) => this.setState({ mobile: event.target.value });
  changeBirthDate = (event: ChangeEvent<HTMLInputElement>) => this.setState({ birth_day: event.target.value });
  changeGender = (option: IRadioSelectOption) => {
    this.setState({ gender: option.value });
  };

  submit = async () => {
    try {
      this.setState(() => ({ loading: true }));
      this.logEvent();

      await userStore.updateUser({
        name: this.state.name,
        email: this.state.email,
        birth_day: this.state.birth_day,
        mobile: this.state.mobile,
        gender: this.state.gender,
      });

      this.setState({ loading: false, isSuccess: true });
    } catch (error: any) {
      this.setState({ loading: false, error });
    }
  };

  logEvent = () => {
    const params: any = {};

    if (this.state.name !== userStore.user.name) {
      params.name_from = userStore.user.name;
      params.name_to = this.state.name;
    }

    if (this.state.mobile !== userStore.user.mobile) {
      params.mobile_from = userStore.user.mobile;
      params.mobile_to = this.state.mobile;
    }

    if (this.state.email !== userStore.user.email) {
      params.email_from = userStore.user.email;
      params.email_to = this.state.email;
    }

    if (this.state.birth_day !== userStore.user.birth_day) {
      params.dob_from = userStore.user.birth_day;
      params.dob_to = this.state.birth_day;
    }

    if (this.state.gender !== userStore.user.gender) {
      params.sex = this.state.gender;
    }

    logger.logEvent(EVENTS.USER_UPDATE_INFO, params);
  };

  get status() {
    const keys = ['name', 'email', 'birth_day', 'mobile', 'gender'];
    const perKey = 100 / keys.length;
    return keys.reduce((total, key) => {
      if (this.state[key]) {
        total += perKey;
      }
      return total;
    }, 0);
  }

  closeModal = (modalName: string) => () => {
    this.setState({ [modalName]: null });
  };

  render() {
    return (
      <div className="d-flex flex-column user-edit mb-4">
        <div className="d-flex flex-column flex-lg-row align-items-center mb-4 mt-4 mt-lg-0">
          {appStore.settings?.is_points ? (
            <div className="d-flex flex-row flex-nowrap align-items-center mr-lg-4 mb-4 mb-lg-0 card user-points">
              <Icon name={ICONS.STAR} className="color-primary mr-4" />
              <p className="color-dark family-semibold text-size-18 text-nowrap">
                {userStore.user.points} {localeStore.t('txt_points')}
              </p>
            </div>
          ) : null}
          <div className="d-flex flex-row align-items-center card user-info background-lightdanger full-height">
            <p className="color-primary family-semibold text-size-14">{localeStore.t('Profile info')}</p>
          </div>
        </div>

        <div className="d-flex flex-column mb-3">
          <div className="d-flex flex-row align-items-center justify-content-between mb-2">
            <p className="text-size-14 text-uppercase family-regular color-dark">
              {localeStore.t('Profile completeness')}
            </p>
            <p className="text-size-14 text-uppercase family-regular color-darkgray">100%</p>
          </div>
          <Progress progress={this.status} height={48} />
        </div>

        <div className="row mb-4">
          <div className="col-xs-12 col-md-6 mb-3">
            <Input
              className="full-width"
              onChange={this.changeName}
              value={this.state.name}
              placeholder={localeStore.t('hint_name')}
            />
          </div>

          <div className="col-xs-12 col-md-6 mb-3">
            <Input
              className="full-width"
              onChange={this.changeEmail}
              value={this.state.email}
              placeholder={localeStore.t('email_placeholder')}
            />
          </div>

          <div className="col-xs-12 col-md-6 mb-3">
            <Input
              className="full-width"
              onChange={this.changeMobile}
              value={this.state.mobile}
              placeholder={localeStore.t('Phone Number')}
              disabled
            />
          </div>

          <div className="col-xs-12 col-md-6 mb-3">
            <Input
              className="full-width"
              value={this.state.birth_day}
              onChange={this.changeBirthDate}
              placeholder={localeStore.t('date_of_birth')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <ResponsiveContent min={WINDOW_SIZES.md}>
              <RadioSelect
                className={'justify-content-between'}
                for="gender"
                value={this.state.gender}
                options={GENDERS.map((option) => ({ ...option, name: localeStore.t(option.name) }))}
                onChange={this.changeGender}
              />
            </ResponsiveContent>

            <ResponsiveContent max={WINDOW_SIZES.md}>
              <Sizes
                selected={this.state.gender}
                options={GENDERS.map((option) => ({
                  value: option,
                  key: option.value,
                  name: localeStore.t(option.name),
                }))}
                onChange={this.changeGender}
              />
            </ResponsiveContent>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="d-flex flex-row full-width justify-content-center justify-content-md-start">
              <DropDownModal
                position={'top'}
                justify={'center'}
                direction={'down'}
                asPopupInMobile
                content={({ closeModal }: { closeModal: () => void }) => {
                  return (
                    <div className="d-flex flex-column full-width">
                      <div className="info-popup-content d-flex flex-column">
                        <p className="info-popup-title">{localeStore.t('txt_do_you_want_to_save_changes')}</p>
                        <p className="info-popup-message">{this.state.error?.message}</p>
                      </div>
                      <div className="info-popup-buttons d-flex flex-row full-width justify-content-between">
                        <ButtonComponent
                          className={`full-width`}
                          style={{
                            borderRadius: 14,
                            minHeight: 52,
                            width: '100%',
                          }}
                          title={localeStore.t('txt_yes')}
                          onClick={this.submit}
                        />
                      </div>

                      <Icon
                        onPress={closeModal}
                        className="info-popup-close"
                        name={ICONS.CLOSE}
                        size={14}
                        color={'#B6B6B6'}
                      />
                    </div>
                  );
                }}
              >
                <ButtonComponent
                  className="full-width"
                  loading={this.state.loading}
                  title={localeStore.t('txt_save')}
                  style={{ maxWidth: 345, width: 345 }}
                />
              </DropDownModal>

              {/* Error update user modal*/}
              <DropDownModal
                position={'top'}
                justify={'center'}
                direction={'down'}
                asPopupInMobile
                visible={this.state.error}
                content={({ closeModal }: { closeModal: () => void }) => {
                  return (
                    <div className="d-flex flex-column full-width">
                      <div className="info-popup-content d-flex flex-column">
                        <p className="info-popup-title">{localeStore.t('txt_error')}</p>
                        <p className="info-popup-message">{this.state.error?.message}</p>
                        {/* <p className="info-popup-title">{this.state.error?.message}</p> */}
                        <p className="info-popup-message"></p>
                      </div>
                      <ButtonComponent
                        className="full-width background-light color-dark"
                        style={{ maxWidth: 345, width: 345 }}
                        title={localeStore.t('txt_yes')}
                        onClick={this.closeModal('error')}
                      />
                    </div>
                  );
                }}
              />

              {/* Success update user modal */}
              <DropDownModal
                position={'top'}
                justify={'center'}
                direction={'down'}
                asPopupInMobile
                visible={this.state.isSuccess}
                content={({ closeModal }: { closeModal: () => void }) => {
                  return (
                    <div className="d-flex flex-column full-width">
                      <div className="info-popup-content d-flex flex-column">
                        <p className="info-popup-title">{localeStore.t('txt_success')}</p>
                        <p className="info-popup-message"></p>
                      </div>
                      <ButtonComponent
                        className="full-width background-light color-dark"
                        style={{ maxWidth: 345, width: 345 }}
                        title={localeStore.t('txt_yes')}
                        onClick={this.closeModal('isSuccess')}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
