import { observer } from 'mobx-react';
import { Component } from 'react';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import Donation from 'src/view/components/Tooltip/Donation/Donation';

interface Props {
  branchNotCoveredAndGeoDisabled: boolean;
}

const BranchDeliveryError: React.FC<Props> = observer(({ branchNotCoveredAndGeoDisabled }) => {
  return (
    <p className="branches-text text-md-size-16 family-regular">
      {/*
       * Donation Message
       * Check your shopping cart to see if there is one item that is not donated (Ramadan)
       */}
      {appStore?.settings?.donation?.enabled ? (
        <div className="d-flex flex-column justify-content-center">
          {localeStore.t('txt_no_pickup_wrong_area')}
          <div className="d-flex flex-row  mb-2 mt-2 order-2 align-items-center justify-content-center">
            <div className="product-donation mr-3">
              <Donation />
            </div>
            <p className="text-size-14">{localeStore.t('area_donation_only')}</p>
          </div>
        </div>
      ) : (
        // Error Message
        localeStore.t(branchNotCoveredAndGeoDisabled ? 'specifiy_your_address' : 'txt_no_pickup_wrong_area')
      )}
    </p>
  );
});

export default BranchDeliveryError;
