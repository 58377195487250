import { ICONS } from 'src/constants/icons';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { typeSMS } from '../../../../../types/orders';

interface IProps {
  label: string;
  text: string | undefined;
  icon: string;
  onClick: () => void;
}

export const CheckoutDeliveryType = (props: IProps) => {
  const { label, text, icon, onClick } = props;

  const renderDonationMessage = () =>
    cartStore.checkout.is_donation ? <p className="mt-2">{localeStore.t('checkout_donation_info')}</p> : null;

  return (
    <div className="d-flex flex-column mb-0 mb-lg-3">
      <div className="d-flex flex-row section-item" onClick={onClick}>
        <Icon name={icon} className="color-dark mr-3" />
        <div className="d-flex flex-row justify-content-between full-width align-items-center">
          <div className="d-flex flex-column">
            <p className="text-size-10 family-semibold text-uppercase color-dark mb-1">{label}</p>
            <p id={'checkout_address'} className="text-size-14 family-semibold color-dark">
              {text}
            </p>
          </div>
          {cartStore.isSMSType !== typeSMS.WITHOUT_GEOLOCATION ? (
            <Icon name={ICONS.CHEVRON} className="color-dark arrow-right" />
          ) : null}
        </div>
      </div>
      {renderDonationMessage()}
    </div>
  );
};
