import React, { Component, CSSProperties } from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { localeStore } from 'src/mobx/localesStore';
import DropDownPortal from '../DropDownPortal/DropDownPortal';
import ResponsiveContent from '../../ResponsiveContent/ResponsiveContent';
import Icon from '../Icon/Icon';
import { ICONS } from 'src/constants/icons';
import { staticTheme } from 'src/constants/staticTheme';
import './style.scss';

interface IPopupModalListProps {
  content: ({ closeModal }: { closeModal: () => void }) => React.ReactNode;
}

export interface IPopupModalProps extends IPopupModalListProps {
  content: IPopupModalListProps['content'];
  selfClose?: boolean;

  direction?: 'left' | 'right' | 'up' | 'down';
  position?: 'left' | 'right' | 'top' | 'bottom';
  align?: 'center';
  justify?: 'center';
  backdrop?: boolean;
  backdropClose?: boolean;
  visible?: boolean;
  mobile?: {
    popup?: boolean;
    sheet?: boolean;
    nonPane?: boolean;
  };
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  modalClassName?: string;
  paneClassName?: string;
  backdropClassName?: string;
  style?: CSSProperties;
  disableDropdown?: boolean;
  dBlock?: boolean;
  withCloseIcon?: boolean;
}

type Visibility = 'visible' | 'hidden' | 'default';

interface PopupModalState {
  visibility: Visibility;
}
export default class PopupModal extends Component<IPopupModalProps, PopupModalState> {
  direction = this.props.direction || 'right';
  position = this.props.position || 'bottom';
  align = this.props.align;
  justify = this.props.justify;
  modal: HTMLDivElement | null = null;
  selfClose: boolean = this.props.selfClose === undefined ? true : this.props.selfClose;
  animations = {
    left:
      localeStore.language === 'en'
        ? { default: '', visible: 'slide_in_left', hidden: 'slide_out_left' }
        : { default: '', visible: 'slide_in_right', hidden: 'slide_out_right' },
    right:
      localeStore.language === 'en'
        ? { default: '', visible: 'slide_in_right', hidden: 'slide_out_right' }
        : { default: '', visible: 'slide_in_left', hidden: 'slide_out_left' },
    up: { default: '', visible: 'slide_in_up', hidden: 'slide_out_up' },
    down: { default: '', visible: 'slide_in_down', hidden: 'slide_out_down' },
  };

  state: PopupModalState = {
    visibility: 'default',
  };

  componentDidUpdate = (props: IPopupModalProps) => {
    if (this.props.visible !== props.visible) {
      if (this.props.visible) {
        if (this.state.visibility !== 'visible') {
          this.openModal();
        }
      } else if (!this.props.visible) {
        if (this.state.visibility === 'visible') {
          this.closeModal();
        }
      }
    }
  };

  openModal = () => {
    if (this.props.onOpen) this.props.onOpen();
    this.setState({ visibility: 'visible' });
    document.addEventListener('click', this.closeModalHandler);
  };

  closeModalHandler = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const pressedElelement: any = event.target;
    if (!this.selfClose) {
      const isInside = this.modal?.contains(pressedElelement);
      if (isInside) return;
    }
    // this.closeModal();
  };

  closeModal = () => {
    this.setState({ visibility: 'hidden' });
    if (this.props.onClose) this.props.onClose();
    document.removeEventListener('click', this.closeModalHandler);
  };

  closeModalBackdrop = () => {
    if (this.props?.backdropClose) this.closeModal();
  };

  handleChildClick = (event: MouseEvent | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  renderModal = () => {
    const { visibility } = this.state;

    const animation = this.animations[this.direction][visibility];

    return (
      <div
        ref={(ref) => (this.modal = ref)}
        style={this.props.style}
        className={`
        dropdown-modal
        ${this.props.mobile?.sheet ? 'modal-bottom-sheet' : ''}
        dropdown-modal-align-${this.align}
        dropdown-modal-justify-${this.justify}
        ${this.props.mobile?.popup ? 'popup_in_mobile' : ''}
        hide-scrollbar
        dropdown-modal-${this.position}
        ${visibility === 'visible' ? 'active' : 'inactive'} animation ${animation}
        ${this.props.modalClassName || ''}
    `}
      >
        {this.props.content({ closeModal: this.closeModal })}
        {this.props.mobile?.popup && !this.props.mobile.nonPane ? (
          <PopupModalPane onClick={this.closeModal} className={this.props.paneClassName} />
        ) : null}
        {this.props.withCloseIcon ? (
          <div className="dropdown__icon-close" onClick={this.closeModal}>
            <Icon name={ICONS.CLOSE} size={10} color={staticTheme.colors.color.white} />
          </div>
        ) : null}
      </div>
    );
  };

  renderModalWrapper = () => {
    if (!this.props.visible) return null;
    if (window.innerWidth < WINDOW_SIZES.md && this.props.mobile?.popup) {
      return <DropDownPortal>{this.renderModal()}</DropDownPortal>;
    }
    return this.renderModal();
  };

  render() {
    const { visibility } = this.state;
    const { className, disableDropdown, dBlock } = this.props;

    const style: CSSProperties = {};
    if (dBlock) style.display = 'block';

    return (
      <div
        style={style}
        className={`dropdown ${visibility} ${this.props.backdrop ? 'dropdown-backdrop' : ''} ${
          className ? className : ''
        }`}
        onClick={this.closeModalBackdrop}
      >
        <div className="dropdown-content" onClick={this.handleChildClick}>
          <ResponsiveContent max={WINDOW_SIZES.md}>
            <div className={`backdrop ${this.props.backdropClassName || ''}`} onClick={this.closeModal} />
          </ResponsiveContent>
          {this.props.children}
          {this.renderModalWrapper()}
        </div>
      </div>
    );
  }
}

export const PopupModalPane = ({ onClick, className }: { onClick: (event: any) => void; className?: string }) => (
  <div onClick={onClick} className={`modal-pane ${className || ''}`}></div>
);
