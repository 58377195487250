import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { cartStore } from 'src/mobx/cartStore';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import CheckoutModalCurbsideForm from './components/CheckoutModalCurbsideForm';
import './style.scss';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModalCurbside extends Component<Props> {
  closeModal = () => {
    cartStore.setModalValue('curbside', false);
  };

  renderContent = () => {
    return <CheckoutModalCurbsideForm onClose={this.closeModal} />;
  };

  render() {
    const { modals } = cartStore.checkout;
    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose
        backdrop
        style={{ maxHeight: '100%', border: 0 }}
        onClose={this.closeModal}
        visible={!!modals.curbside}
        content={this.renderContent}
        mobile={{
          popup: true,
          sheet: true,
        }}
      />
    );
  }
}
export default withRouter(CheckoutModalCurbside);
