import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { cartStore } from 'src/mobx/cartStore';
import { checkoutStore } from 'src/mobx/checkoutStore';
import { Discount } from 'src/view/containers/Discount/Discount';

function CheckoutTotalInfoDiscount() {
  const promocode = useMemo(() => {
    return {
      name: cartStore.calculator.promocode || checkoutStore.reorderStore?.order?.promocode,
      amount: cartStore.calculator.discount_amount || checkoutStore.reorderStore?.order.promocode_amount,
      description:
        cartStore.calculator?.discount_description || checkoutStore.reorderStore?.order?.promocode_description,
    };
  }, [cartStore.calculator, checkoutStore.reorderStore?.order]);

  return cartStore.calculator.promocode || checkoutStore.reorderStore?.order?.promocode ? (
    <Discount
      {...promocode}
      className="checkout-total-info-container flex-column"
      loading={cartStore.calculator.loading}
    />
  ) : null;
}

export default observer(CheckoutTotalInfoDiscount);
