import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ERROR_HANDLERS } from 'src/utils/helpers/errorHandler';
import { ORDER_STATUS } from '../../../constants/main';
import { SCREENS } from '../../../constants/screens';
import orderCreationRedirect from '../../../hooks/orderCreationRedirect';
import { localeStore } from '../../../mobx/localesStore';
import { userStore } from '../../../mobx/userStore';
import { requests } from '../../../requests';
import socket from '../../../services/socket';
import BackIcon from '../../components/Controls/BackIcon/BackIcon';
import Spinner from '../../components/Controls/Spinner/Spinner';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import ScreenWrapper from '../../containers/ScreenWrapper/ScreenWrapper';
import PaymentActionBtn from './components/PaymentActionBtn/PaymentActionBtn';
import PaymentError from './components/PaymentError/PaymentError';
import PaymentMethod from './components/PaymentMethod/PaymentMethod';
import PaymentTimer from './components/PaymentTimer/PaymentTimer';
import PaymentTotal from './components/PaymentTotal/PaymentTotal';
import { tryPaymentAgainStore } from './store';
import logger from 'src/services/logger';
import { DESTINATION_LOGS, EVENTS } from 'src/constants/events';
import './style.scss';

interface ITryPaymentAgainScreenProps extends RouteComponentProps<{ id: string }, StaticContext> {}

interface ITryPaymentAgainScreenLocationType {
  error: { message: string };
}

const TryPaymentAgainScreen: FC<ITryPaymentAgainScreenProps> = observer((props: ITryPaymentAgainScreenProps) => {
  const { id } = props.match.params;
  const location = useLocation<ITryPaymentAgainScreenLocationType>();
  const history = useHistory<{ error?: { message?: string } }>();

  useEffect(() => {
    socket.init();
    fetchOrder();

    const { error } = history.location.state;

    if (error) {
      logger.logEvent(
        EVENTS.PURCHASE_FAILED,
        {
          failure_message: error?.message,
          status_code: 400,
        },
        DESTINATION_LOGS.MOENGAGE
      );
    }

    return () => {
      tryPaymentAgainStore.clear();
      socket.disconnect();
    };
  }, []);

  const fetchOrder = async () => {
    try {
      await userStore.fetchUserCards();
      const order = await requests.getOrderById(id);

      if (!order.id) return history.replace(SCREENS.NOT_FOUND());

      await tryPaymentAgainStore.define(id, order);
      await tryPaymentAgainStore.init();

      if (![ORDER_STATUS.WAITING_FOR_PAYMENT].includes(order.order_status_code as ORDER_STATUS)) {
        orderCreationRedirect(id, order, history);
      }
      tryPaymentAgainStore.setOpened(true);
      tryPaymentAgainStore.setLoading(false);

      if (location.state?.error?.message) {
        ERROR_HANDLERS.EXCEPTION_MESSAGE({
          message: location.state?.error.message,
          title: localeStore.t('payment_failed_error_header'),
        });
      }
    } catch (error: any) {
      history.replace(SCREENS.NOT_FOUND());
    }
  };

  if (tryPaymentAgainStore.loading || tryPaymentAgainStore.paymentProcessing || !tryPaymentAgainStore.order)
    return (
      <Spinner size={50} className="button-spinner sms_pg d-flex justify-content-center full-width" color={'#000'} />
    );

  return (
    <>
      <ScreenWrapper>
        <div className="container">
          <div className="d-flex flex-column full-width">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row align-items-center mb-2 mt-2 mb-lg-4 mt-lg-4">
                <Link
                  to={{
                    pathname: SCREENS.ORDERS(),
                    search: `?language=${localeStore.language}`,
                  }}
                >
                  <BackIcon>
                    <PageTitle title={localeStore.t('try_payment_again_page')} />
                  </BackIcon>
                </Link>
              </div>
            </div>
            {/*Code Here*/}
            <div className={'payment-wrapper-screen'}>
              <div className={'container-data'}>
                <PaymentError />
                <PaymentTimer time={tryPaymentAgainStore.order?.created_at} />
                <PaymentMethod />
              </div>
              <div className="payment-action-wrapper d-flex flex-column justify-content-center align-items-center">
                <PaymentTotal />
                <PaymentActionBtn />
              </div>
            </div>
          </div>
        </div>
      </ScreenWrapper>
    </>
  );
});
export default TryPaymentAgainScreen;
