import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { ICONS } from 'src/constants/icons';
import { SCREENS } from 'src/constants/screens';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import './style.scss';
interface Props {
  ready: boolean;
  onChange: (delivery_type: string) => void;
}

export default class DeliveryTypeSwitcher extends Component<Props> {
  onSelectDeliveryType = (delivery_type: string) => () => {
    this.props.onChange(delivery_type);
  };

  render() {
    const { ready } = this.props;
    if (!ready || !appStore.timestamp)
      return <Skeleton className="branches-delivery-type branches-delivery-type-skeleton " />;
    return (
      <div className="branches-delivery-type d-flex flex-row justify-content-space-between background-white">
        {appStore.settings?.delivery_type === DELIVERY_TYPES.BOTH ||
        appStore.settings?.delivery_type === DELIVERY_TYPES.DELIVERY ? (
          <NavLink
            exact
            to={{
              pathname: SCREENS.BRANCHES(DELIVERY_TYPES.DELIVERY),
              search: `?language=${localeStore.language}`,
            }}
            hrefLang={localeStore.language}
            title={localeStore.t('Delivery')}
            rel="tag"
            activeClassName={'active'}
            onClick={this.onSelectDeliveryType(DELIVERY_TYPES.DELIVERY)}
            className={`branches-delivery-type-button background_pseudo-primary color-gray color_active-primary  d-flex flex-row flex-grow-1 justify-content-center align-items-center  `}
          >
            <Icon name={ICONS.DELIVERY} size={24} className="mr-2" />
            <p className="family-semibold text-md-size-18">{localeStore.t('Delivery')}</p>
          </NavLink>
        ) : null}
        {appStore.settings?.delivery_type === DELIVERY_TYPES.BOTH ||
        appStore.settings?.delivery_type === DELIVERY_TYPES.PICKUP ? (
          <NavLink
            exact
            hrefLang={localeStore.language}
            title={localeStore.t('Pickup')}
            rel="tag"
            to={{
              pathname: SCREENS.BRANCHES(DELIVERY_TYPES.PICKUP),
              search: `?language=${localeStore.language}`,
            }}
            activeClassName={'active'}
            onClick={this.onSelectDeliveryType(DELIVERY_TYPES.PICKUP)}
            className={`branches-delivery-type-button background_pseudo-primary color-gray color_active-primary d-flex flex-row flex-grow-1 justify-content-center align-items-center `}
          >
            <Icon name={ICONS.PICKUP} size={24} className=" mr-2" />
            <p className="family-semibold text-md-size-18">{localeStore.t('Pickup')}</p>
          </NavLink>
        ) : null}
      </div>
    );
  }
}
