import { observer } from 'mobx-react-lite';
import PreorderList from '../../components/PreorderList/PreorderList';
import { preorderPickerStore } from '../../store/store';

/**
 * Scrollable list of Preorder days
 *
 * @type {React.FC}
 */
const PreorderDays: React.FC = observer(() => {
  return (
    <PreorderList
      data_testid="preorder-days"
      data={preorderPickerStore.dates}
      activeIndex={preorderPickerStore.date}
      onSelect={preorderPickerStore.selectDay}
    />
  );
});

export default PreorderDays;
