import { observer } from 'mobx-react';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { WINDOW_SIZES } from 'src/constants/main';
import { appStore, AppStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import SmartBanner from '../SmartBanner/SmartBanner';
import CartButton from './containers/CartButton/CartButton';
import DeliveryAddress from './containers/DeliveryAddress/DeliveryAddress';
import DeliveryTypeSwitcher from './containers/DeliveryTypeSwitcher/DeliveryTypeSwitcher';
import LanguageSwitcher from './containers/LanguageSwitcher/LanguageSwitcher';
import OrderTypeSwitcher from '../OrderTypeSwitcher/OrderTypeSwitcher';
import MenuButton from './containers/MenuButton/MenuButton';
import PreorderPicker from './containers/PreorderPicker/PreorderPicker';
import './style.scss';
import {
  getRestaurantMetaDescription,
  getRestaurantMetaKeyWords,
  getRestaurantName,
  setMetaTags,
} from '../../../utils/helpers';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { preorderStore } from 'src/mobx/preorderStore';
import HeaderLogo from './containers/HeaderLogo/HeaderLogo';
export interface IHeaderProps {
  appStore?: AppStore;
}

@observer
class Header extends React.Component<IHeaderProps> {
  state = {
    isFirstLoading: false,
  };

  componentDidUpdate = () => {
    if (!this.state.isFirstLoading && getRestaurantName()) {
      setMetaTags({
        title: `${getRestaurantName()}`,
        description: `${getRestaurantMetaDescription()}`,
        keywords: `${getRestaurantMetaKeyWords()}`,
        'og:title': `${getRestaurantName()}`,
        'og:description': `${getRestaurantMetaDescription()}`,
        'twitter:title': `${getRestaurantName()}`,
        'twitter:description': `${getRestaurantMetaDescription()}`,
      });
      this.setState({ isFirstLoading: true });
    }
  };

  public render() {
    const ready = appStore.timestamp && localeStore.timestamp;

    return appStore.mode === 'e-menu' && branchStore.branch.delivery_type === DELIVERY_TYPES.DELIVERY ? null : (
      <div className={'skeletonThemeWrapper'}>
        <SkeletonTheme
          color={appStore.settings?.theme?.web.background.secondary}
          highlightColor={appStore.settings?.theme?.web.background.primary}
        >
          <div className="header-wrapper">
            {appStore.timestamp && localeStore.timestamp && appStore.mode !== 'e-menu' ? <SmartBanner /> : null}

            <header className="header th-bg-background-primary">
              <section className="container">
                <HeaderLogo logoUrl={appStore.settings?.img_app_header_logo} />
                {appStore.mode !== 'e-menu' && appStore.settings?.is_restaurant_working ? (
                  <ResponsiveContent min={WINDOW_SIZES.md}>
                    <div className="header-content d-none d-lg-flex">
                      {ready ? (
                        <DeliveryTypeSwitcher />
                      ) : (
                        <Skeleton className="header-skeleton header-skeleton-delivery" />
                      )}
                    </div>
                  </ResponsiveContent>
                ) : null}
                {appStore.mode !== 'e-menu' || !branchStore.order_type ? (
                  <div className="header-content full-width address-container">
                    {ready ? <DeliveryAddress /> : <Skeleton className="header-skeleton header-skeleton-address" />}
                  </div>
                ) : null}
                {appStore.mode !== 'e-menu' && branchStore.branch.id && preorderStore.availableAtAll ? (
                  <div className="header-content">
                    {ready ? <PreorderPicker /> : <Skeleton className="header-skeleton header-skeleton-preorder" />}
                  </div>
                ) : null}
                {appStore.mode === 'e-menu' && branchStore.order_type ? (
                  <div className="header-content  full-width address-container">
                    <OrderTypeSwitcher
                      className="mr-3 p-1 th-background-secondary th-bg-background-secondary"
                      classNameButton="th-color-primary th-selected_active-secondary th-bg-selected_active-primary"
                      styleButton={{ height: '100%' }}
                    />
                  </div>
                ) : null}
                <ResponsiveContent min={WINDOW_SIZES.md}>
                  <div className="header-content  d-none d-lg-flex">
                    {ready ? <LanguageSwitcher /> : <Skeleton className="header-skeleton header-skeleton-language" />}
                  </div>
                </ResponsiveContent>
                <ResponsiveContent min={WINDOW_SIZES.md}>
                  <div className="header-content  d-none d-lg-flex">
                    {ready ? <CartButton /> : <Skeleton className="header-skeleton header-skeleton-button" />}
                  </div>
                </ResponsiveContent>

                {!window.IS_MOBILE ? (
                  <div className="header-content">
                    {ready ? <MenuButton /> : <Skeleton className="header-skeleton header-skeleton-button" />}
                  </div>
                ) : null}
              </section>
            </header>
          </div>

          <div className="header-mocked"></div>
        </SkeletonTheme>
      </div>
    );
  }
}

export default Header;
