import { toJS } from 'mobx';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import ButtonComponent from '../../../components/Controls/Button/Button';
import Icon from '../../../components/Controls/Icon/Icon';
import Input from '../../../components/Controls/Input/Input';
import PhoneInput from '../../../components/Controls/PhoneInput/PhoneInput';
import { ItemData } from '../../../components/Controls/Select/Select';
import TextArea from '../../../components/Controls/TextArea/TextArea';
import BranchesSelect from '../components/BranchesSelect/BranchesSelect';
import CompletePopup from '../components/CompletePopup';
import ConfirmModal from '../components/ConfirmModal';
import ErrorPopup from '../components/ErrorPopup';
import OrdersInput from '../components/OrdersInput';
import OrdersModal from '../components/OrdersModal';

import { EVENTS } from 'src/constants/events';
import logger from 'src/services/logger';
import { getTranslate } from 'src/utils/formatter';
import { ICONS } from '../../../../constants/icons';
import { localeStore } from '../../../../mobx/localesStore';
import { userStore } from '../../../../mobx/userStore';
import client from '../../../../services/client';
import { IOrder } from '../../../../types/orders';

type ContactUsFormProps = {
  formSettings: { email: boolean; order_id: boolean; branch_id: boolean };
};

const ContactUsForm = ({ formSettings }: ContactUsFormProps) => {
  const history = useHistory();

  const [isOpenLoginContainer, setOpenLoginContainer] = useState(false);
  const [orderModalOpened, setOrderModalOpened] = useState(false);
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);
  const [openResultPopup, setOpenResultPopup] = useState(false);
  const [errorPopupVisible, setErrorPopupVisible] = useState(false);

  const [branch, setBranch] = useState<ItemData | null>(null);
  const [order, setOrder] = useState<IOrder | null>(null);
  const [message, setMessage] = useState<string>('');
  const [modalConfirmed, setModalConfirmed] = useState(false);

  const [loading, setLoading] = useState(false);
  const [mobile, setMobile] = useState('');
  const [messageError, setMessageError] = useState(false);

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    watch,
    setError,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: userStore.user.name || '',
      email: userStore.user.email || '',
      mobile: userStore.user.mobile || '',
      subject: '',
      message: '',
      order_key: '',
      branch: false,
    },
  });

  useEffect(() => {
    register('name', { required: localeStore.t('name_required') });

    register('mobile', {
      required: localeStore.t('mobile_required'),
      minLength: { value: 9, message: localeStore.t('error_invalid_mobile') },
      maxLength: { value: 13, message: localeStore.t('error_invalid_mobile') },
    });

    register('branch', {
      required: formSettings.branch_id,
    });

    register('order_key', {
      required: formSettings.order_id,
    });
  }, []);

  useEffect(() => {
    setValue('name', userStore.user.name);
    setValue('email', userStore.user.email);

    setMobile(toJS(userStore.user.mobile.toString()));
    setValue('mobile', toJS(userStore.user.mobile.toString()));
  }, [userStore.user]);

  useEffect(() => {
    setValue('order_key', `${order ? order.order_key : ''}`);
  }, [order]);

  useEffect(() => {
    setValue('branch', !!branch);
  }, [branch]);

  useEffect(() => {
    let count = 0;
    if (confirmModalOpened && modalConfirmed && !loading && count === 1) {
      handleSubmit(onSubmit)();
      count++;
    }
  }, [confirmModalOpened, modalConfirmed, loading]);

  const onSubmit = async (formData: any) => {
    if (confirmModalOpened && !modalConfirmed && loading) return;

    const data = { ...formData };

    if (!formData.message) {
      setMessageError(true);
      return;
    }

    setLoading(true);

    if (order?.id) data.order_id = order?.id;
    if (branch) data.branch_id = branch.data;

    delete data.order_key;
    delete data.branch;

    if (modalConfirmed) {
      data.modal_confirm = true;
    }
    setMessageError(false);

    await client
      .post('/v3/enquiries', data)
      .then(() => {
        logger.logEvent(EVENTS.CONTACTS_SUBMIT);
        setOpenResultPopup(true);

        if (confirmModalOpened && modalConfirmed) {
          setConfirmModalOpened(false);
          setModalConfirmed(false);
        }
      })
      .catch((error) => {
        if (error.response?.data.message === 'enquiry_already_exists') {
          setConfirmModalOpened(true);
          return;
        }

        setErrorPopupVisible(true);
      })
      .finally(() => setLoading(false));
  };

  const createItemDataBranch = (id: number, name: string) => ({ key: `${id}`, data: id, label: name });

  const handleSelectOrder = (order: IOrder | undefined) => {
    if (order) {
      setOrder(order);
      const branch = order.branch;
      setBranch(createItemDataBranch(branch.id, getTranslate(branch.name)));
      setOrderModalOpened(false);
    }
  };

  const handleSubmitConfirmModal = async () => {
    setModalConfirmed(true);
  };

  const handleCancelConfirmModal = () => {
    setModalConfirmed(false);
    setConfirmModalOpened(false);
  };

  const validateName = (name: string) => {
    return name.length > 0;
  };

  const validatePhone = useCallback(() => {
    return mobile.length >= 0 && mobile.length <= 10;
  }, [mobile]);

  const validateEmail = useCallback((email: string) => {
    return email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  }, []);

  const validateMessage = useCallback((msg: string) => {
    setMessageError(!msg.length);
    setMessage(msg);
  }, []);

  const hasFormErrors = useMemo(() => {
    const hasErrors = Boolean(Object.values(errors).length);
    return {
      phone: hasErrors && validatePhone(),
      email: Boolean(errors.email || (hasErrors && !validateEmail(watch('email')))),
      message: Boolean((hasErrors && messageError) || (hasErrors && !message.length)),
    };
  }, [validatePhone, validateEmail, errors, watch, messageError, message]);

  return (
    <form className={'contact-form col-md-10 mt-2'} style={{ padding: 0 }} onSubmit={handleSubmit(onSubmit)}>
      {orderModalOpened && (
        <OrdersModal
          isOpen={orderModalOpened}
          onRequestClose={() => setOrderModalOpened(false)}
          onSelect={handleSelectOrder}
          branchId={branch?.data}
        />
      )}
      <div className={'d-flex flex-column mb-3 mt-2'}>
        <div className={'row align-items-end'}>
          <div className={'col-md-4 mb-3 full-height'}>
            <div
              className={`login-error color-white family-regular background-danger error-container mb-2 ${
                Object.keys(errors).length !== 0 ? 'v-visible' : 'v-hidden'
              }`}
            >
              {Object.keys(errors).length > 1 ? localeStore.t('fields_required') : localeStore.t('field_required')}
            </div>
            <Input
              className={'full-width'}
              placeholder={localeStore.t('hint_name')}
              type={'name'}
              error={!!errors.name}
              {...register('name', { required: localeStore.t('name_required') })}
              validate={validateName}
              required
            />
          </div>
          <div className={'col-md-4 mb-3 full-height'}>
            <div>
              <PhoneInput
                containerClass={'color-gray background-white'}
                inputClass={`enquiry-phone-input text-size-16 ${hasFormErrors.phone ? 'error' : ''}`}
                value={mobile}
                prefix={'+'}
                hideFlagsIcon
                placeholder={localeStore.t('Mobile')}
                onChange={(value) => {
                  setMobile(value);
                  setValue('mobile', value);
                }}
              />
              {validatePhone() ? (
                <div className={'enquiry-phone-input-right_icon danger-circle background-danger'} />
              ) : null}
            </div>
          </div>
          <div className={'col-md-4 mb-3'}>
            <Input
              iconContainerStyle={{ top: '16px' }}
              className={'full-width'}
              placeholder={localeStore.t('email_placeholder')}
              type={'email'}
              error={hasFormErrors.email}
              {...register('email', { required: formSettings.email })}
              validate={validateEmail}
              required
            />
          </div>
        </div>
        <div className={'row align-items-end mb-2'}>
          <div className={'col-md-6 mb-4'}>
            <BranchesSelect
              selected={!branch ? undefined : branch}
              error={!branch && isSubmitted && formSettings.branch_id}
              onSelect={(branch) => branch && setBranch(createItemDataBranch(branch.id, getTranslate(branch.name)))}
              disabled={!!order}
              required={formSettings.branch_id && !!!branch}
              onDeselect={() => {
                setBranch(null);
              }}
            />
          </div>
          <div className={'col-md-6 mb-4'}>
            <OrdersInput
              setOpenLoginContainer={setOpenLoginContainer}
              setOrderModalOpened={setOrderModalOpened}
              isOpenLoginContainer={isOpenLoginContainer}
              isSubmitted={isSubmitted}
              required={formSettings.order_id && !!!order}
              value={order ? `#${order.order_key}` : ''}
              selected={!!order}
              clearOrder={(event: MouseEvent | React.MouseEvent) => {
                event.stopPropagation();
                if (branch && order) setBranch(null);
                setOrder(null);
              }}
            />
          </div>
        </div>
        <div className={'row align-items-end'}>
          <div className={'col-md-12 mb-3'}>
            <Input
              className={'full-width'}
              placeholder={localeStore.t('title')}
              iconContainerStyle={{ top: '12px', left: '18px' }}
              icon={<Icon size={24} name={ICONS.T} />}
              error={!!errors.subject}
              {...register('subject')}
              validate={validateName}
            />
          </div>
          <div className={'col-md-12'}>
            <TextArea
              containerStyle={{ height: '150px' }}
              placeholder={localeStore.t('hint_message')}
              icon={<Icon name={ICONS.LINES} />}
              error={hasFormErrors.message}
              className={'text-size-16'}
              {...register('message')}
              onChange={(e) => {
                validateMessage(e.target.value);
              }}
              required={!message.length}
            />
            {}
          </div>
        </div>
      </div>
      <div className={'row mb-3 mt-5'}>
        <div className={'col-md-4 full-width'}>
          <ErrorPopup
            dBlock={true}
            visible={errorPopupVisible}
            onClose={() => {
              setErrorPopupVisible(false);
              history.push('/');
            }}
          >
            <CompletePopup
              dBlock={true}
              visible={openResultPopup}
              onClose={() => {
                setOpenResultPopup(false);
                history.push('/');
              }}
            >
              <ConfirmModal
                visible={confirmModalOpened}
                onSubmit={handleSubmitConfirmModal}
                onCancel={handleCancelConfirmModal}
              >
                <ButtonComponent
                  title={localeStore.t('txt_submit')}
                  loading={loading}
                  type={'submit'}
                  className={'full-width'}
                />
              </ConfirmModal>
            </CompletePopup>
          </ErrorPopup>
        </div>
      </div>
    </form>
  );
};

export default ContactUsForm;
