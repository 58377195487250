import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import CheckoutSelectSection from './components/CheckoutDineInSection';
import CheckoutSelectTable from './components/CheckoutDineInTable';
import './style.scss';

type Props = {
  className?: string | '';
  placeholder?: string | '';
  text?: string | '';
  error?: boolean;
};
type State = {
  modalVisible: boolean;
  modal_type: string | null;
  section_error: boolean;
  table_error: boolean;
};

@observer
export default class CheckoutDineIn extends Component<Props, State> {
  state = {
    modalVisible: true,
    modal_type: 'section',
    section_error: false,
    table_error: false,
  };

  openModal = (modal_type: string) => () => {
    const { section } = cartStore.checkout?.dine_in || {};
    if (modal_type === 'table' && !section) cartStore.setDineInError({ section_error: true });
    else this.setState({ modalVisible: true, modal_type, section_error: false });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  switchModal = () => {
    this.setState({ modal_type: 'table' });
  };

  /**
   * Get text in right direction
   *
   * @param {string} label
   * @param {string} text
   * @memberof CheckoutOrderType
   */
  concatText = (label: string, text: string) =>
    localeStore.language !== 'ar' ? `${label}: ${text}` : `${text} :${label}`;

  render() {
    const { modalVisible, modal_type } = this.state;
    const { order_types, sections } = branchStore.branch.emenu || {};
    const { table, section } = cartStore.checkout?.dine_in || {};
    const { section_error, table_error } = cartStore.checkout?.dine_in_error || {};
    return (
      <>
        {sections?.length ? (
          <>
            <div
              className={`checkout-dine_in-wrapper ${section_error || table_error ? 'error' : ''}`}
              onClick={this.openModal('section')}
            >
              <div>
                <p className="family-semibold text-size-16 color-dark">
                  {(section && this.concatText(localeStore.t('section_selected'), section.title)) ||
                    localeStore.t('select_section')}
                </p>
                <p className="family-semibold text-size-16 color-dark">
                  {(table && this.concatText(localeStore.t('table_selected'), table.title)) ||
                    localeStore.t('select_table')}
                </p>
              </div>
              <div className="d-flex flex-row align-items-center ">
                <Icon name={ICONS.EDIT} color={'#1D1D1D'} />
              </div>
            </div>
            {modal_type === 'section' ? (
              <CheckoutSelectSection isOpen={modalVisible} onRequestClose={this.switchModal} />
            ) : (
              <CheckoutSelectTable isOpen={modalVisible} onRequestClose={this.closeModal} />
            )}
          </>
        ) : null}
      </>
    );
  }
}
