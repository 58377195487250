import React from 'react';
import { localeStore } from '../../../../../../../mobx/localesStore';
import './style.scss';

const notFoundImg = `${process.env.REACT_APP_CLOUD_FRONT}/media/images/empty-box.png`;

export const SearchNotFound: React.FC = () => (
  <div className="search-menu-not-found">
    <img src={notFoundImg} title="not found" alt="not found" />
    <div className="search-menu-not-found-message">{localeStore.t('products_not_found')}</div>
  </div>
);
