import React, { ReactElement } from 'react';
import Spinner from '../Spinner/Spinner';
import './style.scss';

interface IButtonComponentProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  spinnerColor?: string;
  fullSize?: boolean;
  children?: React.ReactNode;
  id?: string;
}

export default function ButtonComponent({
  title = 'button',
  style,
  className,
  disabled = false,
  loading = false,
  fullSize = false,
  children = null,
  spinnerColor = '#ffffff',
  onClick,
  id,
  ...props
}: IButtonComponentProps): ReactElement {
  let button: null | HTMLButtonElement = null;
  const _onClick = (event: any) => {
    if (disabled || loading) {
      if (button && button.contains(event.target)) {
        event.stopPropagation();
      }
    }
  };
  return (
    <button
      {...props}
      id={id}
      ref={(ref) => (button = ref)}
      onClick={onClick}
      className={`button ${className ? className : ''} th-bg-controls-primary  th-controls-secondary  ${
        disabled ? 'button-disabled' : ''
      } ${fullSize ? 'full-size' : ''}`}
      style={style}
      disabled={disabled || loading}
    >
      {loading && <Spinner size={24} className="button-spinner" color={spinnerColor || '#ffffff'} />}
      <div
        onClick={_onClick}
        style={{
          opacity: loading ? 0 : 1, // keeps button width the same when spinner is showing
        }}
      >
        {children || title}
      </div>
    </button>
  );
}
