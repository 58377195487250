import { localeStore } from '../../../mobx/localesStore';
import React, { FC } from 'react';
import { userStore } from '../../../mobx/userStore';
import { checkoutStore } from '../../../mobx/checkoutStore';
import './style.scss';
import { observer } from 'mobx-react';

const SmsPagePromocodeInfo: FC = observer((): JSX.Element | null => {
  if (!userStore.isLogin && checkoutStore.reorderStore?.order?.promocode) {
    return (
      <div className="cart-notes">
        <div className="d-flex promocode_notes">
          <div className="flex-row cart-section align-items-center">
            <div className="flex-column">
              <p className="text-size-14 color-gray">{localeStore.t('sms_promocode')}</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
});
export default SmsPagePromocodeInfo;
