import React, { useMemo } from 'react';
import { ICONS } from 'src/constants/icons';
import { TimeController } from 'src/controllers/TimeController/TimeController';
import { localeStore } from 'src/mobx/localesStore';
import { IBranch } from 'src/types/branch';
import { getTranslate } from 'src/utils/formatter';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { getPreorderMessage } from '../../../../../utils/helpers';
import { WorkSlots } from '../WorkSlots/WorkSlots';
import { appStore } from 'src/mobx/appStore';

interface Props {
  onSelectBranch: (branch: IBranch) => void;
  branch: IBranch;
  selected?: IBranch['id'];
}

const PickupBranch: React.FC<Props> = ({ branch, onSelectBranch, selected }) => {
  const timeController = new TimeController({ branch });
  const [status, isBranchWorking] = timeController.getWorkStatus();

  const preorder = getPreorderMessage(timeController.preorderIsAvailable, true);

  /// If branch is working now or preorder of 24/7, busy and preorder unavailable
  const isActive = useMemo(
    () => branch.working_now || preorder || (!preorder && branch.is_busy && branch.is_round_clock),
    [branch, preorder]
  );

  /**
   * Is Selected Branch
   * @type {boolean }
   */
  const isSelected: boolean = useMemo(() => selected === branch.id, [selected, branch]);

  return (
    <div
      onClick={() => onSelectBranch(branch)}
      className={`branches-sidebar-branch background_pseudo-primary d-flex flex-column ${isSelected ? 'active' : ''}`}
    >
      {isSelected ? (
        <div className="branches-selected background-primary">
          <Icon name={ICONS.CHECK} size={14} className={'color-white'} />
        </div>
      ) : null}

      {/* Preorder */}
      <div className="d-flex flex-row align-items-center  mb-2">
        <p
          className={`branches-title d-flex flex-row align-items-center family-semibold mr-3 ${
            isActive ? 'color-text' : 'color-gray'
          }`}
        >
          {getTranslate(branch.name)}
          {preorder ? (
            <div className="branches-preorder family-semibold background-gray color-white ml-3 d-inline-block">
              {preorder}
            </div>
          ) : null}
        </p>
      </div>
      {/* Busy */}
      {branch.is_busy ? (
        <div>
          <div className="branches-busy family-semibold background-danger color-white d-inline-block">
            {localeStore.t('branch_busy_status')}
          </div>
        </div>
      ) : null}

      {/* Work Slots */}
      <div className="d-flex flex-column mt-2">
        <WorkSlots
          work_slots={branch.work_slots}
          status={status}
          isBranchWorking={isBranchWorking}
          expandable={!appStore.settings?.simplified_working_hours}
        />

        <div className="d-flex flex-row align-items-center justify-content-between">
          {/* Address */}
          {branch.address ? (
            <div className="d-flex flex-row align-items-baseline justify-content-start">
              <Icon name={ICONS.MARKER} size={14} className="mr-2  color-gray" />
              <p className="family-regular branches-info color-gray">{branch.address}</p>
            </div>
          ) : null}
          {/* Distance  */}
          {branch.distance ? (
            <p className="family-regular branches-info color-gray text-nowrap">{branch.distance}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PickupBranch;
