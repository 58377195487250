export const ADDRESS_TYPES = {
  HOME: 1,
  WORK: 2,
  HANGOUT: 3,
  FRIEND: 4,
};

export type KeysAddressType = keyof typeof ADDRESS_TYPES;

type Values = typeof ADDRESS_TYPES[KeysAddressType];

export enum VIEW_MODE {
  LIST,
  EDIT,
  CREATE,
}

export type TAddressType = Values | undefined;

export interface IAddressCreate {
  id: number;
  latitude: number;
  longitude: number;
  lat?: number;
  lng?: number;
  name: string;
  address: string;
  apt_of_suite: string;
  floor: string;
  notes: string;
  address_type: TAddressType;
  error_message: string | null;
}

export interface IAddressResponse {
  id: number;
  latitude: number;
  longitude: number;
  name: string;
  address: string;
  apt_of_suite: string;
  floor: string;
  notes: string;
  address_type: TAddressType;
  error_message: string | null;
}
