import { inject, observer } from 'mobx-react';
import React from 'react';
import { Router as BrowserRouter } from 'react-router';
import { localeStore } from 'src/mobx/localesStore';
import { IMainStore, STORES } from 'src/mobx/mainStore';
import ModalMessages from './containers/ModalMessages/ModalMessages';
import Router from './Router';
import './style.scss';
import ScrollToTop from './containers/ScrollToTop/ScrollToTop';
import history from 'src/services/history';

export interface IRootViewProps {
  localeStore?: IMainStore['localeStore'];
}

@observer
class RootView extends React.Component<IRootViewProps> {
  componentDidMount = () => {
    if (localeStore.language === 'ar') {
      window.document.dir = 'rtl';
    }
  };

  public render() {
    return (
      <BrowserRouter history={history}>
        <ScrollToTop />
        <Router />
        <ModalMessages />
      </BrowserRouter>
    );
  }
}

export default inject(STORES.LOCALE_STORE)(RootView);
