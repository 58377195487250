import { observer } from 'mobx-react';
import * as React from 'react';
import { ICONS } from 'src/constants/icons';
import { localeStore } from 'src/mobx/localesStore';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import IconButton from 'src/view/components/Controls/IconButton/IconButton';
import LoginContainer from 'src/view/containers/LoginContainer/LoginContainer';

export interface IHamburgerLoginItemState {
  active: boolean;
}

@observer
class HamburgerLoginItem extends React.Component<any, IHamburgerLoginItemState> {
  // ml - Login Form
  state = {
    active: false,
  };
  onOpenModal = () => {
    this.props.updateProps({ mw: false, ml: true });
    this.setState({ active: !this.state.active });
  };
  onCloseModal = () => {
    this.props.updateProps({ ml: false, mw: false });
    this.setState((state) => ({ active: false }));
  };
  public render() {
    return (
      <DropDownModal
        content={({ closeModal }) => <LoginContainer closeModal={closeModal} />}
        listClassName="hamburger-login-modal"
        selfClose={false}
        position={'right'}
        asPopupInMobile
        bottomSheet
        visible={this.props.hamburgerState.ml}
        onOpen={this.onOpenModal}
        onClose={this.onCloseModal}
      >
        <div className="hamburger-menu-item full-width th-bg-background_hover-secondary">
          <IconButton iconClassName={'th-background-secondary'} name={ICONS.LOGOUT} />
          <p className="th-color-primary">{localeStore.t('menu_login')}</p>
        </div>
      </DropDownModal>
    );
  }
}

export default HamburgerLoginItem;
