import React, {
  ChangeEvent,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { localeStore } from '../../../../mobx/localesStore';
import './style.scss';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  inputClassName?: string;
  iconContainerStyle?: CSSProperties;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  rightIconContainerStyle?: CSSProperties;
  error?: boolean;
  required?: boolean;
  animated?: boolean;
  onClick?: () => void;
  validate?: (value: any) => any;
}

const Input = forwardRef(
  (
    {
      icon,
      className,
      inputClassName,
      iconContainerStyle,
      error,
      required,
      rightIcon,
      rightIconContainerStyle,
      onClick,
      style,
      placeholder,
      animated,
      value,
      onBlur,
      onChange,
      validate,
      ...rest
    }: Props,
    innerRef?: ForwardedRef<HTMLInputElement>
  ): ReactElement => {
    const [focused, setFocus] = useState(false);
    const [isValid, setValid] = useState(false);

    const ref = useRef<HTMLInputElement>(null);
    useImperativeHandle(innerRef, () => ref.current!, [ref]);

    useEffect(() => {
      if (!required || ref.current?.value.length) setValid(true);
    }, [required, ref.current?.value]);

    const _onClick = () => {
      setFocus(true);
      ref.current?.focus();
      if (onClick) onClick();
    };

    const _onBlur = (ev: any) => {
      if (onBlur) onBlur(ev);
      setFocus(false);
    };

    const _onChange = (e: any) => {
      if (onChange) {
        if (!validate) onChange(e);

        if (validate && validate(e.target.value)) {
          onChange(e);
          setValid(true);
        } else {
          setValid(false);
        }
      }
    };

    return (
      <div className={`${className || ''}`}>
        {false && (
          <div className={'login-error color-white family-regular background-danger error-container mb-2'}>{error}</div>
        )}
        <div
          className={`input ${error ? 'error' : ''} ${required ? 'required' : ''}  ${focused ? 'focused' : ''} ${
            animated ? 'flex-column' : ''
          }`}
          onClick={_onClick}
        >
          {animated ? (
            <span
              className={`input-placeholder family-semibold ${
                value ? 'compressed color-gray text-uppercase text-size-10' : ''
              }`}
            >
              {placeholder}
            </span>
          ) : null}
          <input
            style={{
              padding: icon ? (localeStore.language === 'ar' ? `0 55px 0 20px` : `0 20px 0 55px`) : '',
              ...style,
            }}
            {...rest}
            ref={ref}
            defaultValue={value}
            placeholder={animated ? '' : placeholder}
            className={`text-size-16 ${inputClassName || ''}`}
            onBlur={_onBlur}
            onChange={_onChange}
          />
          {required && !isValid ? <div className={'right-icon danger-circle background-danger'} /> : null}
        </div>
      </div>
    );
  }
);

export default Input;
