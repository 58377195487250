import React, { Component } from 'react';
import './style.scss';
interface Props {
  name: string;
  className?: string;
  iconClassName?: string;
  tooltip?: string | number;
  active?: boolean;
  onPress?: () => void;
}
export default class IconButton extends Component<Props> {
  onPress = () => {
    if (this.props.onPress) this.props.onPress();
  };

  render() {
    const { tooltip } = this.props;
    return (
      <div
        onClick={this.onPress}
        className={`icon icon-button ${this.props.className} ${this.props.active ? 'icon-active' : ''}`}
      >
        <i className={`demo-icon icon-${this.props.name} ${this.props.iconClassName || ''}`}></i>
        {tooltip ? <span className={'icon-tooltip'}>{tooltip}</span> : null}
      </div>
    );
  }
}
