import React, { ChangeEvent, Component } from 'react';
import { ICONS } from 'src/constants/icons';
import Icon from '../Icon/Icon';
import './style.scss';
interface Props {
  value: number;
  minimum?: number;
  maximum?: number;
  small?: boolean;
  onChange?: (value: number) => void;
  minusButtonDisabled?: boolean;
  plusButtonDisabled?: boolean;
  decreaseId?: string;
  increaseId?: string;
  className?: string;
}
interface State {
  value: number;
  defaultValue: number;
}

export default class Quantity extends Component<Props, State> {
  minimum = this.props.minimum || 1;
  maximum = this.props.maximum || 99;
  state = {
    defaultValue: this.props.value || 1,
    value: this.props.value || 1,
  };

  componentDidUpdate = () => {
    if (this.props.maximum && this.maximum !== this.props.maximum) {
      this.maximum = this.props.maximum;
      this.onChange(this.state.value);
    }

    if (this.props.minimum && this.minimum !== this.props.minimum) {
      this.minimum = this.props.minimum;
      this.onChange(this.state.value);
    }
  };

  static getDerivedStateFromProps = (props: Props, state: State) => {
    if (props.value !== state.defaultValue) {
      return {
        ...state,
        defaultValue: props.value,
        value: props.value,
      };
    }
    return state;
  };

  onChange = (value: number) => {
    value = this.validate(value);
    this.setState({ value });
    if (this.props.onChange) this.props.onChange(value);
  };

  onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    this.onChange(value);
  };

  validate = (value: number) => {
    if (value <= this.minimum || isNaN(value)) value = this.minimum;
    if (value > this.maximum) value = this.maximum;
    return value;
  };

  increase = () => {
    this.onChange(this.state.value + 1);
  };

  decrease = () => {
    this.onChange(this.state.value - 1);
  };

  get className(): string {
    let className: string = 'quantity';

    if (this.props.small) className += ' quantity-small';
    if (this.props.className) className += ` ${this.props.className}`;

    return className;
  }

  render() {
    const { value } = this.state;
    const minusButtonDisabled = this.props.minusButtonDisabled || value === this.minimum;
    const plusButtonDisabled = this.props.plusButtonDisabled || value === this.maximum;

    return (
      <div className={this.className}>
        <Icon
          id={this.props.decreaseId}
          className={minusButtonDisabled ? 'disabled' : ''}
          disabled={minusButtonDisabled}
          onPress={this.decrease}
          name={ICONS.MINUS}
          color="#B6B6B6"
        />
        <input value={this.state.value} onChange={this.onInputChange} type={'number'} />
        <Icon
          id={this.props.increaseId}
          className={plusButtonDisabled ? 'disabled' : ''}
          disabled={plusButtonDisabled}
          onPress={this.increase}
          name={ICONS.PLUS}
          color="#B6B6B6"
        />
      </div>
    );
  }
}
