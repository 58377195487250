import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import { formatImagePath } from 'src/utils/formatter';
import Image from 'src/view/components/Image/Image';
import './style.scss';
import { productsStore } from '../../../../../mobx/productsStore';

@observer
export default class MenuClosed extends Component {
  state = {};

  render() {
    if ((!localeStore.timestamp || appStore.settings?.is_restaurant_working) && productsStore.categories.length)
      return null;

    return (
      <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 my-5">
        <Image wrapperClassName="menu-closed-image my-5" aspectRatio={4} src={formatImagePath('closed.png')} />
        <p style={{ fontSize: 26 }} className="text-center mb-5 mt-3">
          {localeStore.t('txt_app_settings_error')}
        </p>
      </div>
    );
  }
}
