import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import Icon from '../../../../../../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../../../../../../constants/icons';
import { addressStore } from '../../../../../../../../../mobx/barnchStore/address';
import { localeStore } from '../../../../../../../../../mobx/localesStore';
import branchesScreenStore from '../../../../../../branchesScreenStore';
import { VIEW_MODE } from '../../../../../../../../../types/address';

const AddressFormHeader: FC = observer(() => {
  const isEditAddress = useMemo(() => {
    return (
      (branchesScreenStore.viewMode === VIEW_MODE.EDIT || branchesScreenStore.viewMode === VIEW_MODE.CREATE) &&
      addressStore.list.length
    );
  }, [branchesScreenStore.viewMode, addressStore.list]);

  const deleteAddress = useCallback(async () => {
    if (branchesScreenStore.selectedAddressId && !branchesScreenStore.loading) {
      branchesScreenStore.setLoading(true);
      try {
        await addressStore.deleteAddress(branchesScreenStore.selectedAddressId);
      } catch (error: any) {
        branchesScreenStore.setError(error?.response?.data?.message || null);
      } finally {
        branchesScreenStore.setLoading(false);
      }
    }
  }, []);

  return (
    <>
      <div className="top-bar">
        {isEditAddress ? (
          <>
            <p className="back" onClick={branchesScreenStore.backHandler}>
              <Icon name={ICONS.ARROW_LEFT} size={24} className="mr-2 edit color-gray" />
            </p>
            <button className="delete-address" onClick={deleteAddress}>
              <Icon name={ICONS.TRASH} className={'color-gray'} size={20} />
            </button>
          </>
        ) : null}
      </div>
      <p className="title">
        {localeStore.t(branchesScreenStore.viewMode === VIEW_MODE.EDIT ? 'edit_address' : 'add_address')}
      </p>
    </>
  );
});

export default AddressFormHeader;
