import { FC, ReactElement, memo, useMemo } from 'react';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { IProductInsideCombo } from 'src/types/combo';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import './style.scss';

export const NamePrice: FC<IProductInsideCombo> = memo((item: IProductInsideCombo): ReactElement => {
  const price = useMemo((): string => formatPrice(productUtils.calculateProductInsideComboPrice(item)), [item]);

  return (
    <div className="d-flex flex-row align-items-center justify-content-between pxmb-3">
      <h1 className="product-meal-combo-group-mobile-item-modal__name">{getTranslate(item.name)}</h1>

      <div className="d-flex flex-column align-items-center">
        <span className="product-meal-combo-group-mobile-item-modal__price color-primary text-nowrap">{price}</span>

        {branchStore.vat_included ? (
          <span className="product-meal-combo-group-mobile-item-modal__vat text-nowrap">
            {localeStore.t('VAT inclusive')}
          </span>
        ) : null}
      </div>
    </div>
  );
});
