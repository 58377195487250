import GoogleMapReact from 'google-map-react';
import { ChangeEvent, Component } from 'react';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { defaultBranch, WINDOW_SIZES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { requests } from 'src/requests';
import { IBranch } from 'src/types/branch';
import { IPagination } from 'src/types/main';
import { getUserPosition } from 'src/utils/helpers';
import { IDropDownOption } from 'src/view/components/Controls/DropDownOption/DropDownOption';
import Marker from 'src/view/components/Marker/Marker';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import BranchSearchBox from 'src/view/screens/BranchesScreen/components/BranchSearchBox/BranchSearchBox';
import BranchPickupPopup from 'src/view/screens/BranchesScreen/containers/BranchPopup/BranchPickupPopup/BranchPickupPopup';
import BranchesMapControls from '../../components/BranchesMapControls/BranchesMapControls';
import BranchesSubmitButton from '../../components/BranchesSubmitButton/BranchesSubmitButton';
import BranchesList from '../BranchesList/BranchesList';
import CreateReservationForm from '../CreateReservationForm/CreateReservationForm';
import './style.scss';

export interface IReservationType {
  id: number;
  types: {
    en: string;
    ar: string;
  };
}

interface State {
  branch: IBranch | null;
  branches: IPagination<IBranch>;
  position: GeolocationPosition | null;
  center: { lat: number; lng: number };
  ready: boolean;
  loading: boolean;
  zoom: number;
  gettingUserPosition: boolean;
  form: {
    type?: IReservationType;
    people_number: number;
    name: string;
    phone_number: string;
  };
  userPosition: { lat: number; lng: number } | undefined;
}

export default class ReservationCreateMap extends Component<{}, State> {
  map: any = null;
  input: any = null;
  minZoom: number = 3;
  maxZoom: number = 20;
  state: State = {
    branch: null,
    branches: {
      current_page: 0,
      data: [],
      to: 0,
      total: 0,
      from: 0,
      last_page: 1,
    },
    position: null,
    center: {
      lat: defaultBranch.latitude,
      lng: defaultBranch.longitude,
    },
    ready: false,
    loading: true,
    zoom: 12,

    gettingUserPosition: false,
    form: {
      name: userStore.user.name,
      phone_number: userStore.user.mobile,
      type: undefined,
      people_number: 1,
    },
    userPosition: undefined,
  };

  componentDidMount = () => {
    this.getUserPosition();
  };

  getUserPosition = async () => {
    this.setState(() => ({ gettingUserPosition: true }));
    const position = await getUserPosition();
    this.setState(() => ({ gettingUserPosition: false }));
    if (position) {
      this.setState({
        userPosition: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    }
    let center: GoogleMapReact.Coords = this.state.center;
    if (position) {
      center = { lat: position.coords.latitude, lng: position.coords.longitude };
    }
    this.setCenter(center, position);
  };

  setCenter = (
    center: GoogleMapReact.Coords = this.state.center,
    position: GeolocationPosition | null = this.state.position
  ) => this.setState({ center, position }, this.onCenterWasChanged);

  onCenterWasChanged = () => {
    if (!this.state.branches.data.length) this.getBranchesList(1);
  };

  onZoomLevelChanged = (increase: boolean) => {
    let zoom = increase ? this.state.zoom + 1 : this.state.zoom - 1;
    if (zoom < this.minZoom) zoom = this.minZoom;
    if (zoom > this.maxZoom) zoom = this.maxZoom;
    this.setState({ zoom });
  };

  onDragEnd = async ({ center }: { center: { lat: () => number; lng: () => number } }) => {
    this.setCenter({ lat: center.lat(), lng: center.lng() });
  };

  getBranchesList = async (page = this.state.branches.current_page + 1) => {
    try {
      this.setState(() => ({ loading: true }));

      const response = await requests.branches.getBranches({
        page: page || this.state.branches.current_page + 1,
        delivery_type: DELIVERY_TYPES.PICKUP,
        latitude: this.state.position?.coords.latitude,
        longitude: this.state.position?.coords.longitude,
      });

      if (response) {
        // get the selected brunch, if it is not working now, choose the first one that works from the list
        let branch = response.data.find(
          (branch: IBranch) => (branch.id === branchStore.branch.id && branch.working_now) || branch.working_now
        );
        if (!branch) branch = response.data[0];

        this.setState({
          branch,
          branches: response,
          loading: false,
          center: { lat: branch.latitude, lng: branch.longitude },
        });
      } else {
        this.setState({ branch: null, loading: false });
      }
    } catch (error: any) {
      this.setState({ branch: null, loading: false });
    }
  };

  onSelectBranch = (branch: IBranch) => {
    this.setState({ branch, center: { lat: branch.latitude, lng: branch.longitude } });
  };

  renderBranchPopup = (branch: IBranch) => (
    <BranchPickupPopup
      key={branch.id}
      onSelectBranch={this.onSelectBranch}
      selected={this.state.branch?.id}
      branch={branch}
      lat={branch.latitude}
      lng={branch.longitude}
    />
  );

  changePeopleNumber = (people_number: number) =>
    this.setState((state) => ({ form: { ...state.form, people_number } }));
  changeType = (option: IDropDownOption<IReservationType>) =>
    this.setState((state) => ({ form: { ...state.form, type: option.value } }));
  changeName = (event: ChangeEvent<HTMLInputElement>) =>
    this.setState((state) => ({ form: { ...state.form, name: event.target.value } }));
  changePhone = (phone_number: string) => this.setState((state) => ({ form: { ...state.form, phone_number } }));

  render() {
    const { center, userPosition } = this.state;
    return (
      <div className={`branches-modal d-flex flex-grow-1 flex-column`}>
        <div className={'background-white reservation-head'}>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row align-items-center mb-4">
              <h1 className="color-dark family-bold">{localeStore.t('txt_makereservation')}</h1>
            </div>
            <CreateReservationForm
              form={this.state.form}
              branches={this.state.branches}
              onSelectBranch={this.onSelectBranch}
              branch={this.state.branch}
              loading={this.state.loading}
              changePeopleNumber={this.changePeopleNumber}
              changeType={this.changeType}
              changeName={this.changeName}
              changePhone={this.changePhone}
            />
          </div>
        </div>

        {/* DO NOT SHOW MAP IN MOBILE */}
        <ResponsiveContent min={WINDOW_SIZES.md}>
          <div className={`d-flex flex-row flex-grow-1 `}>
            <div className={`branches-map-wrapper d-flex flex-grow-1`}>
              <GoogleMapReact
                zoom={this.state.zoom}
                onGoogleApiLoaded={async () => {
                  this.setState({ ready: true, zoom: this.map?.map_?.zoom ?? 5 });
                }}
                bootstrapURLKeys={{
                  key: appStore.settings?.google_maps_pk || '',
                  libraries: [],
                }}
                center={center}
                ref={(map) => (this.map = map)}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                onDragEnd={this.onDragEnd}
                options={{
                  fullscreenControl: false,

                  zoomControl: false,
                  scrollwheel: true,
                  maxZoom: this.maxZoom,
                }}
              >
                {/* User Position */}
                {userPosition ? (
                  <Marker lat={userPosition?.lat} lng={userPosition?.lng}>
                    <span className="user-dot" id="fuck" />
                  </Marker>
                ) : null}

                {this.state.branches.data.map(this.renderBranchPopup)}
              </GoogleMapReact>

              <BranchesMapControls
                gettingUserPosition={this.state.gettingUserPosition}
                getUserPosition={this.getUserPosition}
                setCenter={this.setCenter}
                onZoomLevelChanged={this.onZoomLevelChanged}
              />
              <div className={`branches-top d-flex flex-row justify-content-between active`}>
                <BranchSearchBox setCenter={this.setCenter} address={undefined} />
              </div>

              <BranchesSubmitButton form={this.state.form} loading={this.state.loading} branch={this.state.branch} />
            </div>
            <BranchesList
              onSelectBranch={this.onSelectBranch}
              selected={this.state.branch?.id}
              loading={this.state.loading}
              branches={this.state.branches.data}
            />
          </div>
        </ResponsiveContent>
      </div>
    );
  }
}
