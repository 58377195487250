import { FC, ReactElement, memo } from 'react';
import { IProductInsideCombo } from 'src/types/combo';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import { CartProductModifiers } from '../../containers/CartProductModifiers/CartProductModifiers';
import './style.scss';
import { observer } from 'mobx-react-lite';

interface IProps {
  item: IProductInsideCombo;
  isMeal: boolean;
  productQuantity: number;
}

export const CartProductItem: FC<IProps> = observer(({ item, productQuantity, isMeal }: IProps): ReactElement => {
  const price: string = formatPrice(Number(item.price) * productQuantity);
  const error: string | undefined = item.error || productUtils.getComboItemUnavailableMessage(item, isMeal);

  return (
    <div className="cart-product-item">
      <p className="cart-product-item__header">
        <span className={`cart-product-item__name color-dark ${error ? 'text-through' : ''}`}>
          {getTranslate(item.name)}
        </span>

        {isMeal ? <span className="cart-product-item__price color-dark text-nowrap">{price}</span> : null}
      </p>

      <CartProductModifiers modifiers={item.modifiers} productQuantity={productQuantity} />
      {/* TODO: combo exclusions */}
      {/* <CartProductExclusions exclusions={item.exclusions} /> */}
    </div>
  );
});
