import React, { Component } from 'react';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { requests } from 'src/requests';
import { IProduct } from 'src/types/products';
import HorizontalScroll from 'src/view/components/HorizontalScroll/HorizontalScroll';
import CrossSellingProduct from './components/CrossSellingProduct/CrossSellingProduct';
import './style.scss';

interface Props {
  className?: string;
}

interface State {
  list: number[];
  products: IProduct[];
  loading: boolean;
}

export default class CrossSelling extends Component<Props, State> {
  state: State = {
    list: [],
    products: [],
    loading: true,
  };

  componentDidMount = () => {
    const exclude = cartStore.products.map((product) => product.id);
    const list = cartStore.products.reduce((products: number[], product: IProduct): number[] => {
      //   if products exist
      if (product.upsales_items.length) {
        product.upsales_items.forEach((upsales_item) => {
          // add item if upsale item not in cart and not already added
          if (!exclude.includes(upsales_item) && !products.includes(upsales_item)) {
            products.push(upsales_item);
          }
        });
      }
      return products;
    }, []);
    this.setState({ list, loading: Boolean(list.length) }, this.fetchProducts);
  };

  fetchProducts = async () => {
    try {
      if (!this.state.list.length) return;
      this.setState(() => ({ loading: true, products: [], list: [] }));
      const response = await requests.fetchProducts(this.state.list);
      this.setState({ products: response, list: [], loading: false });
    } catch (error: any) {
      console.error({ error });
      this.setState({ loading: false, products: [], list: [] });
    }
  };

  renderProduct = (product: IProduct | undefined, index: number) => (
    <CrossSellingProduct key={product?.id || index} product={product} />
  );

  render() {
    const { className } = this.props;
    if (!this.state.list.length && !this.state.products.length && !this.state.loading) return null;
    return (
      <div className={`d-flex flex-row ${className}`}>
        <div className="d-flex flex-column full-width">
          <h3 className="text-uppercase color-darkgray family-regular">{localeStore.t('people_also_ordered')}</h3>
          <div className="cross-selling-wrapper color-light hide-scrollbar">
            <HorizontalScroll>
              <div className="cross-selling-list d-flex flex-row">
                {this.state.loading
                  ? [undefined, undefined, undefined].map(this.renderProduct)
                  : this.state.products.map(this.renderProduct)}
              </div>
            </HorizontalScroll>
          </div>
        </div>
      </div>
    );
  }
}
