import { observer } from 'mobx-react';
import { FC, ReactElement } from 'react';
import { cartStore } from 'src/mobx/cartStore';
import { CartProduct } from '../../components/CartProduct/CartProduct';
import './style.scss';

export const CartProducts: FC = observer((): ReactElement => {
  return (
    <section className="cart-products">
      {cartStore.products.map((product) => (
        <CartProduct key={product.hash} product={product} />
      ))}
    </section>
  );
});
