import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { appStore } from 'src/mobx/appStore';
import { promotionStore } from 'src/mobx/promotionStore';
import MenuBannerList from './MenuBannerList';
import MenuBannerSkeleton from './MenuBannerSkeleton';

@observer
export default class MenuBanner extends Component {
  render() {
    if (!promotionStore.banners.length && !promotionStore.loadingBanners && !appStore.settings?.default_image)
      return null;
    if (promotionStore.banners.length && !promotionStore.loadingBanners) return <MenuBannerList />;
    // Do not show skeleton if banners are cached
    return <MenuBannerSkeleton />;
  }
}
