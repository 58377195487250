import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ITimeSlot } from 'src/types/time';
import PreorderTime from '../../components/PreorderTime/PreorderTime';

interface Props {
  /**
   * List of elements
   *
   * @type {ITimeSlot[]}
   * @memberof Props
   */
  data: ITimeSlot[];
  /**
   * Index of selected element
   *
   * @type {number}
   * @memberof Props
   */
  activeIndex: number;
  data_testid?: string;
  /**
   * On select element by its index
   *
   * @memberof Props
   */
  onSelect: (index: number) => void;
}

/**
 * Scrollable list of preorder elements
 * @type {React.FC<Props>}
 */
const PreorderList: React.FC<Props> = observer(({ data, activeIndex, data_testid, onSelect }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const elementHeight = useMemo(() => 37, []);
  const containerHeight = useMemo(() => elementHeight * 5, [elementHeight]);

  // Init Scroll to element without animation
  useEffect(() => {
    if (containerRef.current) containerRef.current.scrollTop = elementHeight * (activeIndex - 1);
  }, [containerRef.current]);

  // Scroll to selected element
  useEffect(() => {
    scrollToElement(activeIndex, 'smooth');
  }, [activeIndex]);

  /**
   * Scroll to element by its index
   * @param {number} elementIndex - Element index to scroll to
   * @param {ScrollBehavior} [behavior] - behavior scrolls
   */
  const scrollToElement = useCallback(
    (elementIndex: number, behavior?: ScrollBehavior) => {
      // Scroll only when container Ref is inited
      if (!containerRef.current) return;

      /**
       * Scroll Top for scrollTo container
       * @type {number}
       */
      const scrollTop = elementHeight * (elementIndex - 1);

      // To prevent calls scrollTo when not needed
      // If scrollTop is greater than 0 and not equal to container.scrollTop, scroll to element
      if (scrollTop !== containerRef.current.scrollTop) {
        // Scroll to element
        containerRef.current?.scrollTo({
          top: scrollTop,
          behavior,
        });
      }
    },
    [containerRef.current, elementHeight]
  );

  return (
    <div
      data-testid={data_testid}
      ref={containerRef}
      className="preorder-picker hide-scrollbar"
      style={{ height: `${containerHeight}px` }}
    >
      {data.map((time, index) => (
        <PreorderTime
          key={time.label + index}
          index={index}
          label={time.label}
          isSelected={activeIndex === index}
          onClick={onSelect}
          height={elementHeight}
        />
      ))}
    </div>
  );
});

export default PreorderList;
