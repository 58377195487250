import { action, observable } from 'mobx';

class SearchStore {
  @observable isOpen: boolean = false;

  @action toggleOpen = (status: boolean) => {
    this.isOpen = status;
  };
}

export const searchStore = new SearchStore();
