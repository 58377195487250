import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { driverSearchStore } from '../../store';
import { localeStore } from '../../../../../mobx/localesStore';
import { ORDER_STATUS } from '../../../../../constants/main';

const DeliveryStatusText: FC = observer(() => {
  const failIcon = `${process.env.REACT_APP_CLOUD_FRONT}/media/images/ic_sad.svg`;

  return (
    <div>
      {/** Order waiting */}
      {driverSearchStore.order?.order_status_code === ORDER_STATUS.ON_HOLD && (
        <div className="text-info">
          {localeStore.t(!driverSearchStore.timeOut ? 'waiting_status' : 'wait_notification')}
        </div>
      )}

      {/** Cancelled order */}
      {driverSearchStore.order?.order_status_code === ORDER_STATUS.CANCELED && (
        <>
          <img src={failIcon} className={'canceled-icon'} title="logo" alt="Order cancelled" />
          <div className="text-info canceled">{localeStore.t('Canceled')}</div>
          <div className="text-info">{localeStore.t('order_canceled')}</div>
        </>
      )}
    </div>
  );
});

export default DeliveryStatusText;
