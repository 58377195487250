import { useCallback, useEffect, useState } from 'react';
import { appStore } from 'src/mobx/appStore';
import { requests } from 'src/requests';
import { ICookiesTerm } from 'src/types/app';

export const useGetCookiesTerm = (): ICookiesTerm | undefined => {
  const [term, setTerm] = useState<ICookiesTerm | undefined>();

  const fetchCookiesTerm = useCallback(async () => {
    try {
      if (appStore.settings?.cookies_enabled && !appStore.cookies_accepted_at) {
        const response = await requests.fetchCookiesTerm();
        setTerm(response);
      }
    } catch (error) {
      console.error(error);
    }
  }, [appStore.settings?.cookies_enabled, appStore.cookies_accepted_at]);

  useEffect(() => {
    fetchCookiesTerm();
  }, [appStore.settings?.cookies_enabled, appStore.cookies_accepted_at]);

  return term;
};
