import { observer } from 'mobx-react';
import { tryPaymentAgainStore } from '../../store';
import { ORDER_STATUS, PAYMENT_STATUS } from '../../../../../constants/main';
import { localeStore } from '../../../../../mobx/localesStore';
import { IOrder } from '../../../../../types/orders';
import React from 'react';
import './style.scss';

const PaymentError = observer(() => {
  const failIcon = `${process.env.REACT_APP_CLOUD_FRONT}/media/images/ic_sad.svg`;

  const { order_status_code, payment_status } = tryPaymentAgainStore?.order as IOrder;

  const checkOrderStatus = (status: ORDER_STATUS): boolean => {
    if (!order_status_code) {
      return false;
    }
    return [status].includes(order_status_code);
  };

  const payment_non_received = [PAYMENT_STATUS.NON_RECEIVED, PAYMENT_STATUS.UNDEFINED].includes(payment_status);
  const isTPANeeded = tryPaymentAgainStore.order?.is_tpa_needed;

  const orderCancelledWithoutPaymentInfo = checkOrderStatus(ORDER_STATUS.CANCELED) && isTPANeeded;
  const orderCancelledWithPaymentInfo = checkOrderStatus(ORDER_STATUS.CANCELED) && !isTPANeeded;

  return (
    <div className={'payment-error'}>
      <>
        <img src={failIcon} className={'canceled-icon'} title="logo" alt="Order cancelled" />

        {/*Sorry, the payment has failed. Please try again*/}
        {checkOrderStatus(ORDER_STATUS.WAITING_FOR_PAYMENT) && isTPANeeded && !tryPaymentAgainStore.timeOut && (
          <div className="title">{localeStore.t('payment_failed_try_again')}</div>
        )}

        {/*Order not complete or timeout is left*/}
        {checkOrderStatus(ORDER_STATUS.WAITING_FOR_PAYMENT) &&
          (!payment_non_received || isTPANeeded) &&
          tryPaymentAgainStore.timeOut && (
            <>
              <div className="text">{localeStore.t('payment_not_completed_message')}</div>
              <div className="description">{localeStore.t('order_canceled_message')}</div>
            </>
          )}

        {/*Order canceled*/}
        {(orderCancelledWithoutPaymentInfo || orderCancelledWithPaymentInfo) && (
          <>
            <div className="text">{localeStore.t('Canceled')}</div>
            <div className="description">{localeStore.t('order_expired_error_message')}</div>
          </>
        )}

        {/* Order non-received */}
        {!checkOrderStatus(ORDER_STATUS.CANCELED) && payment_non_received && !isTPANeeded && (
          <>
            <div className="text">{localeStore.t('payment_status_not_received_error_header')}</div>
            <div className="description">{localeStore.t('payment_status_not_received_error_message')}</div>
          </>
        )}
      </>
    </div>
  );
});
export default PaymentError;
