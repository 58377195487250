import { IPaymentTypeId } from './app';
import { IBranch, IBranchArea } from './branch';
import { IResponseProduct, PRODUCT_TYPE } from './products';
import { IUser } from './user';
import { ORDER_STATUS, PAYMENT_STATUS } from '../constants/main';

export enum typeSMS {
  WITHOUT_GEOLOCATION = 1,
  WITH_GEOLOCATION = 2,
}

export interface IOrder {
  id: number | string;
  branch: {
    id: number;
    name: string;
    latitude: number;
    longitude: number;
    address: string;
    mobile: string;
  };
  address: string; // delivery address or branch address based on delivery type    "hash": String,
  is_order_for_someone_else: boolean;
  stranger_customer_info: {
    mobile: string;
    name: string;
  };
  order_key: number;
  invoice_number: number;
  /**
   * @deprecated since Franchise 1.2
   *
   * @type {string}
   * @memberof IOrder
   */
  currency_code: string;
  /**
   * @deprecated since Franchise 1.2
   *
   * @type {string}
   * @memberof IOrder
   */
  currency_position?: string;
  currency?: {
    currency_code?: string;
    currency_position?: string;
    devided?: number;
  };
  customer_mobile: number;
  total: number;
  subtotal: number;
  service_tax_percentage: number;
  service_tax: number;
  vat_percentage: number;
  vat: number;
  promocode: string;
  promocode_amount: number;
  promocode_value: number;
  promocode_type: number;
  promocode_description: string;
  delivery_type: string;
  delivery_fee: number;
  taxable_delivery_fee: number;
  is_curbside: number;
  payment_status: PAYMENT_STATUS;
  is_payment_created: boolean;
  is_tpa_needed: boolean;
  discount_amount: number;
  payment_type: IPaymentTypeId;
  status: string;
  notes: string;
  order_datetime: string;
  customer_id: number;
  address_id: number;
  address_type_id: number;
  is_verified: number;
  is_address_change: number;
  accepted_at: string;
  confirmed_at: null;
  assigned_at: null;
  delivered_at: null;
  created_at: string;
  created_by: string;
  delivery_time_name: number;
  earn_point: number;
  created_by_name: string;
  deliver_at: null;
  redeem_point: number;
  area_id: number;
  kiosk_id: number;
  tax: number;
  include_delivery_vat: number;
  include_vat: number;
  vat_delivery: number;
  tax_delivery: number;
  is_restored_payment_id: number;
  is_manual_refund: number;
  is_refund: number;
  wallet_discount: number;
  use_wallet?: boolean;
  curbside?: {
    driver_name: string;
    car_brand: string;
    car_color: string;
    car_type: string;
    car_number: string;
    curbside_fee: number;
    client_arrived: number;
    client_arrived_at: null | string;
  };
  deleted_at: null;
  delivery_type_code: string;
  order_status_code: ORDER_STATUS;
  vat_tax_percentage: number;
  order_date: string;
  taken?: {
    driver_name: string;
    driver_mobile: string;
  };
  products: IOrderProduct[];

  dinein_delivery_type?: number | null;
  section?: string;
  table?: string;
  payment_result_message: string;
  dnf: {
    enable: boolean;
    cutoff_at: string;
  };
  orderError?: any;
}

export interface IOrderProduct {
  name: string;
  description: string;
  id: number;
  image: string;
  price: number;
  quantity: number;
  notes: string;
  is_donation: number;
  size: IOrderProductSize | null;
  modifiers: IOrderProductModifier[];
  exclusions?: IOrderProductExclusion[];
  items?: IOrderProductComboItem[];
  type?: PRODUCT_TYPE;
}

export interface IOrderProductSize {
  id: number;
  price: number;
  weight: number;
  name: string;
  unit: string;
}

export interface IOrderProductModifierOption {
  name: string;
  price: number;
  id: number;
  weight: number;
  unit: string;
  quantity: number;
}

export interface IOrderProductModifier {
  id: number;
  name: string;
  price: number;
  options: IOrderProductModifierOption[];
}

export interface IOrderProductExclusion {
  id: number;
  name: string;
}

export interface IOrderProductComboItem {
  id: number;
  name: string;
  description: string;
  price: string; // float number
  image: string;
  // meal keys
  size_id?: number;
  group_id?: number;
  choice_id?: number;
  selected?: boolean;
  modifiers?: IOrderProductModifier[];
  exclusions?: IOrderProductExclusion[];
}

export interface IDiscountError {
  error_promo: string;
  active_promocode: boolean;
  error_type: string;
  errorCode: number;
  promocode_minimum: number;
}

// REORDER

export interface IReorderResponse {
  products: IResponseProduct[];
  branch: IBranch | null;
  area: IBranchArea | null;
  address_id: number;
  notes: string;
  address: string;
  curbside: IUser['curbside'];
}

// Apple pay data object
export interface IApplePayData {
  merchantCapabilities: string[];
  countryCode: string;
  currencyCode: string;
  supportedNetworks: string[]; // 'visa', 'mastercard', 'amex', 'mada'
  total: {
    label: string;
    amount: string;
    type: string;
  };
  lineItems: { label: string; amount: string }[];
}
