import React, { useState, useEffect } from 'react';
import { cartStore } from '../../../mobx/cartStore';
import Icon from '../../components/Controls/Icon/Icon';
import { ICONS } from '../../../constants/icons';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from '../../../mobx/localesStore';
import './style.scss';
import { requests } from '../../../requests';

interface IProps {
  onClick: () => void;
  selected: boolean;
}

const ApplePayButton = ({ onClick, selected = false }: IProps) => {
  const [showApplePayButton, setShowApplePayButton] = useState(false);

  useEffect(() => {
    const checkApplePay = async () => {
      try {
        const { ApplePaySession } = window;

        if (ApplePaySession) {
          const getMerchantID = appStore.settings?.merchant_id;
          const canMakePayments = await ApplePaySession.canMakePayments(getMerchantID);

          if (canMakePayments) {
            setShowApplePayButton(true);
          }
        }
      } catch (err) {
        console.error('checkApplePay error', err);
      }
    };

    checkApplePay();
  }, []);

  if (!showApplePayButton) return null;

  return (
    <div className="col-6 text-pointer mb-2 px-1 payment-type-apple-pay">
      <button
        disabled={cartStore.calculator.loading}
        onClick={onClick}
        className={`full-width checkout-payment-types col text-pointer ${
          selected ? 'border-primary' : 'background-light'
        }`}
      >
        <div className="d-flex align-items-center ">
          <p className="family-regular text-size-16 color-dark">{localeStore.t('applePay')}</p>
        </div>
      </button>
    </div>
  );
};

export default ApplePayButton;
