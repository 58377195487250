import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ICONS } from 'src/constants/icons';
import { E_MENU_TYPE } from 'src/constants/main';
import { DELIVERY_TYPES } from '../../../constants/deliveryTypes';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { localeStore } from 'src/mobx/localesStore';
import { productsStore } from '../../../mobx/productsStore';
import Icon from '../../components/Controls/Icon/Icon';
import Skeleton from 'react-loading-skeleton';
import './style.scss';
export interface Props extends RouteComponentProps {
  className?: string | null;
  classNameButton?: string | null;
  styleButton?: React.CSSProperties;
  onChange?: (type: number) => void;
}
interface State {
  options: number[] | null;
}
@observer
class OrderTypeSwitcher extends React.Component<Props, State> {
  onClick = (type: number) => () => {
    branchStore.setOrderType(type);
    productsStore.fetchMenu();
    if (this.props.onChange) this.props.onChange(type);
  };

  getClassName(name: number) {
    const order_types = branchStore.branch.emenu?.order_types;
    if (!order_types?.is_dinein || !order_types?.is_togo) return 'left m-0';
    if (order_types?.is_dinein && order_types?.is_togo && branchStore.order_type === name) return `active`;
    return '';
  }

  public render() {
    const { className, classNameButton, styleButton } = this.props;
    const order_types = branchStore.branch.emenu?.order_types;
    return (
      <div
        className={`order_type-wrapper ${className} ${
          !order_types?.is_dinein || !order_types?.is_togo ? 'bg-inherit' : ''
        }`}
      >
        {order_types && !localeStore.loading ? (
          <>
            {order_types?.is_dinein ? (
              <div
                className={`order_type-button mr-1 ${this.getClassName(E_MENU_TYPE.DINE_IN)}  ${classNameButton}`}
                onClick={this.onClick(E_MENU_TYPE.DINE_IN)}
                style={styleButton}
              >
                {/* <Icon
                  className={'order_type-button-icon'}
                  name={ICONS.DINE_IN}
                  color={appStore.settings?.theme?.web.background.primary}
                /> */}
                <p className="order_type-button-text">{localeStore.t('dine_in')}</p>
              </div>
            ) : null}
            {order_types?.is_togo ? (
              <div
                className={`order_type-button ml-1 ${this.getClassName(E_MENU_TYPE.TO_GO)} ${classNameButton}`}
                onClick={this.onClick(E_MENU_TYPE.TO_GO)}
                style={styleButton}
              >
                {/* <Icon
                  className={'order_type-button-icon'}
                  name={ICONS.TO_GO}
                  color={appStore.settings?.theme?.web.background.primary}
                /> */}
                <p className="order_type-button-text">{localeStore.t('to_go')}</p>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <Skeleton height={42} style={{ borderRadius: '16px' }} />
            <Skeleton height={42} style={{ borderRadius: '16px', marginLeft: '5px' }} />
          </>
        )}
      </div>
    );
  }
}

export default withRouter(OrderTypeSwitcher);
