import moment, { Moment } from 'moment';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { DAYS_INDEX_IN_WEEK } from 'src/constants/main';
import { TimeController } from 'src/controllers/TimeController/TimeController';
import { localeStore } from 'src/mobx/localesStore';
import { BranchStatus, IBranch, IPreparationPreorder, IWorkSlot } from 'src/types/branch';
import { formatTime } from './formatter';

export const calculatePreorderLimit = (preorder: IPreparationPreorder): number => {
  switch (preorder?.period) {
    case 'days':
      return +preorder.days;
    case 'weeks':
      return +preorder.days * 7;
    case 'months':
      return +preorder.days * 30;
    case 'today':
      return 1;
    default:
      return +preorder.days;
  }
};

export const getDayLabel = (date: Moment, weekdaysOnly = false) => {
  if (date.isSame(moment(), 'day')) {
    return localeStore.t('txt_today');
  }
  if (date.isSame(moment().add(1, 'day'), 'day')) {
    return localeStore.t('txt_tomorrow');
  }
  return weekdaysOnly ? date.format('dddd') : date.format('ddd, MMMM DD');
};

/**
 * Get Branch Work Status
 *
 * @param {IBranch} [branch] - Branch
 * @return {*}  {[string, string, string]} - [status, isWorking, color]
 */
export const getBranchWorkSettings = (branch?: IBranch): [string, boolean] => {
  if (!branch || !branch.work_slots.length) {
    return [localeStore.t('txt_closed'), false];
  }

  const { status } = new TimeController({
    branch,
    deliveryType: DELIVERY_TYPES.PICKUP,
    date: undefined,
    month: undefined,
    year: undefined,
  });

  switch (status.type) {
    case BranchStatus.Open24H:
      return [localeStore.t('open_button_txt'), true];
    case BranchStatus.OpenUntilToday:
      return [`${localeStore.t('open_until')} ${formatTime(status.time)}`, true];
    case BranchStatus.BreakUntilToday:
      return [`${localeStore.t('break_until')} ${formatTime(status.time)}`, false];
    case BranchStatus.ClosedUntilToday:
      return [`${localeStore.t('closed_until')} ${formatTime(status.time)}`, false];
    case BranchStatus.ClosedUntilTomorrow:
      return [`${localeStore.t('closed_until')} ${localeStore.t('txt_tomorrow')} ${formatTime(status.time)}`, false];
    case BranchStatus.ClosedUntilWeekday:
      return [
        `${localeStore.t('closed_until')} ${localeStore.t(`txt_${status.day}`)} ${formatTime(status.time)}`,
        false,
      ];
    default: {
      const slotsByWeekDayIndex: { [key: number]: IWorkSlot } = branch.work_slots.reduce(
        (prev, curr) => ({
          ...prev,
          [DAYS_INDEX_IN_WEEK[curr.day]]: {
            ...curr,
          },
        }),
        {}
      );
      const currentWeekday = moment().weekday();

      for (let i = 0; i < 7; i++) {
        const index = (currentWeekday + i + 1) % 7;
        if (slotsByWeekDayIndex[index].start_time) {
          return [
            `${localeStore.t('closed_until')} ${localeStore.t(`txt_${slotsByWeekDayIndex[index].day}`)} ${formatTime(
              moment(slotsByWeekDayIndex[index].start_time, 'hh:mm A', 'en')
            )}`,
            false,
          ];
        }
      }

      return [localeStore.t('txt_closed'), false];
    }
  }
};
