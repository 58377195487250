import PhoneInput2, { PhoneInputProps } from 'react-phone-input-2';
import React, { ChangeEvent, Component } from 'react';
import './style.scss';

interface IProps extends PhoneInputProps {
  onChange: (value: string, data: {}, event: ChangeEvent<HTMLInputElement>) => void;
  hideFlagsIcon?: boolean;
  className?: string;
  animated?: boolean;
  required?: boolean;
}

export default class PhoneInput extends Component<IProps> {
  render() {
    const {
      className = '',
      containerClass,
      hideFlagsIcon = false,
      placeholder,
      animated,
      required,
      value,
      ...rest
    } = this.props;
    return (
      <div className={`phone_input flex-row full-width ${className}`}>
        <div className=" d-flex flex-column full-height full-width">
          {animated ? (
            <span className={`phone_input-placeholder family-semibold ${value ? 'compressed' : ''}`}>
              {placeholder}
            </span>
          ) : null}
          <PhoneInput2
            {...rest}
            containerClass={`${containerClass} ${hideFlagsIcon ? 'hide-flags' : ''} ${
              placeholder && placeholder?.length > 14 ? 'decrease' : null
            }`}
            prefix={'+'}
            value={this.props.value}
            placeholder={animated ? '' : placeholder}
            onChange={this.props.onChange}
          />
        </div>
        {required && !value ? <div className="danger-circle" /> : null}
      </div>
    );
  }
}
