import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import { branchStore } from 'src/mobx/barnchStore';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { formatPrice } from 'src/utils/formatter';

interface Props extends RouteComponentProps<null> {}

@observer
class CartSubmit extends Component<Props> {
  toCheckout = () => {
    cartStore.cashbackError = null;
    cartStore.openCheckout();
  };

  validate = () => {
    let disabled = false;
    let title = localeStore.t('txt_checkout');

    if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY && cartStore.calculator.left_to_min_order) {
      disabled = true;
      title = `${localeStore.t('minimum_order_alert_message')} ${formatPrice(cartStore.calculator.min_order)}`;
    }

    return { disabled, title };
  };

  render() {
    const { disabled, title } = this.validate();

    return (
      <ButtonComponent
        loading={cartStore.calculator.loading}
        style={{ width: '100%' }}
        title={title}
        onClick={this.toCheckout}
        disabled={disabled}
      />
    );
  }
}

export default withRouter(CartSubmit);
