import { localeStore } from '../../mobx/localesStore';
import { messagesStore } from '../../mobx/messagesStore';
import { tryPaymentAgainStore } from '../../view/screens/TryPaymentAgainScreen/store';

export const ERROR_HANDLERS = {
  ERROR_MAXIMUM_CASH_PAYMENT_IS_EXEEDED: (max_cash_payment: string | null) => {
    messagesStore.addMessage({
      type: 'modal',
      title: localeStore.t('payment_failed_error_header'),
      message: localeStore.t('max_cash_not_reached', { max_cash_payment }),
      options: [
        {
          text: localeStore.t('change_payment_method_button'),
          className: 'cart-product-header__btn-no color-dark',
          onPress: () => tryPaymentAgainStore.changePaymentType(),
        },
      ],
    });
  },
  ERROR_PAYMENT_METHOD_IS_NO_LONGER_AVAILABLE: () => {
    messagesStore.addMessage({
      type: 'modal',
      title: localeStore.t('payment_failed_error_header'),
      message: localeStore.t('payment_method_not_available_error_message'),
      options: [
        {
          text: localeStore.t('change_payment_method_button'),
          className: 'cart-product-header__btn-no color-dark',
          onPress: () => tryPaymentAgainStore.changePaymentType(),
        },
      ],
    });
  },
  EXCEPTION_MESSAGE: ({ title, message }: { title: string; message?: string }) => {
    messagesStore.addMessage({
      type: 'modal',
      title,
      message,
      options: [{ text: localeStore.t('close'), className: 'cart-product-header__btn-no color-dark' }],
    });
  },
};
