import React from 'react';
import Image from '../Image/Image';

export const EmptyList: React.FC = () => {
  return (
    <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center full-width py-5">
      <Image style={{ maxWidth: 400 }} src={`${process.env.REACT_APP_CLOUD_FRONT}/media/images/empty-box.png`} alt="empty"  />
      <div className="no-orders-text">Nothing to show here yet</div>
    </div>
  );
};
