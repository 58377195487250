import { ICONS } from 'src/constants/icons';
import { PAYMENT_TYPES } from 'src/constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { IBranchPaymentType } from 'src/types/branch';
import Icon from 'src/view/components/Controls/Icon/Icon';
import '../style.scss';
interface IPaymentType {
  icon?: string;
  type_id: IBranchPaymentType['id'] | 4;
  text: string;
  className?: string;
  onClick?: () => void;
}
export function PaymentType(props: IPaymentType) {
  const { icon, type_id, text, className, onClick } = props;

  const icons = {
    [PAYMENT_TYPES.CASH]: ICONS.MONEY,
    [PAYMENT_TYPES.ONLINE_PAYMENT]: ICONS.ONLINE_PAYMENY,
    [PAYMENT_TYPES.POINTS]: ICONS.STAR,
    [PAYMENT_TYPES.TERMINAL]: ICONS.TERMNAL,
    [PAYMENT_TYPES.APPLE_PAY]: ICONS.APPLE_PAY,
  };

  const selected = cartStore.checkout.payment_type === type_id;
  return (
    <div className={`payment_type ${className || ''} ${selected ? 'selected' : ''}`} onClick={onClick}>
      <Icon name={icon || icons[type_id]} className={`payment_type-icon`} />
      <p>{text}</p>
    </div>
  );
}
