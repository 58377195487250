import React, { ChangeEvent, Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { WINDOW_SIZES } from 'src/constants/main';
import { staticTheme } from 'src/constants/staticTheme';
import { localeStore } from 'src/mobx/localesStore';
import client from 'src/services/client';
import { IBranch } from 'src/types/branch';
import { IPagination } from 'src/types/main';
import { getTranslate } from 'src/utils/formatter';
import DropDown from 'src/view/components/Controls/DropDown/DropDown';
import { IDropDownOption } from 'src/view/components/Controls/DropDownOption/DropDownOption';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Input from 'src/view/components/Controls/Input/Input';
import Quantity from 'src/view/components/Controls/Quantity/Quantity';
import Spinner from 'src/view/components/Controls/Spinner/Spinner';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import BranchesSubmitButton from '../../components/BranchesSubmitButton/BranchesSubmitButton';
import { IReservationType } from '../ReservationCreateMap/ReservationCreateMap';
import './style.scss';
interface Props {
  form: {
    type?: IReservationType;
    people_number: number;
    name: string;
    phone_number: string;
  };
  branches: IPagination<IBranch>;
  branch: IBranch | null;
  loading: boolean;
  onSelectBranch: (branch: IBranch) => void;
  changeName: (event: ChangeEvent<HTMLInputElement>) => void;
  changePhone: (phone_number: string) => void;
  changeType: (option: IDropDownOption<IReservationType>) => void;
  changePeopleNumber: (people_number: number) => void;
}
interface State {
  types: IReservationType[];
  people_number: number;
  loading: boolean;
}

export default class CreateReservationForm extends Component<Props, State> {
  state: State = {
    types: [],
    people_number: 1,
    loading: true,
  };

  componentDidMount = () => {
    this.fetchReservationSettings();
  };

  selectBranch = (option: IDropDownOption<IBranch>) => this.props.onSelectBranch(option.value);

  fetchReservationSettings = async () => {
    try {
      this.setState({ loading: true });
      const { people_number, types } = await client.get(`/v3/reservations/settings`);
      this.setState({ people_number: Number(people_number), types });
      if (types.length) {
        const type: IReservationType = types[0];
        this.props.changeType({
          label: getTranslate(type.types),
          key: type.id,
          value: type,
        });
      }
      this.setState({ loading: false });
    } catch (error: any) {
      console.error({ error });
      this.setState({ loading: false });
    }
  };

  render() {
    const { type, name } = this.props.form;
    const getWorkingBranch = this.props.branches.data.filter((branch) => branch.working_now);
    return (
      <div className="row d-flex flex-column flex-lg-row align-items-center">
        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
          <Input
            className="full-width"
            value={name}
            onChange={this.props.changeName}
            placeholder={localeStore.t('hint_name')}
          />
        </div>
        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
          <DropDown
            onPress={this.props.changeType}
            selected={type?.id}
            options={this.state.types.map(
              (type): IDropDownOption<IReservationType> => ({
                value: type,
                label: getTranslate(type.types),
                key: type.id,
              })
            )}
          >
            <div className="input full-width d-flex align-items-center color-darkgray text-size-16 justify-content-between  family-semibold text-pointer">
              {type ? getTranslate(type.types) : localeStore.t('reservation_type')}
              {this.state.loading ? (
                <Spinner color={staticTheme.colors.color.light} />
              ) : (
                <Icon className="ml-3" name={ICONS.CHEVRON} />
              )}
            </div>
          </DropDown>
        </div>
        <div className="col-12 col-lg-3 mb-2 mb-lg-0">
          <div className="reservation-created-flex flex-row reservation-head-quantity">
            <div className="input full-width background-white d-flex align-items-center">
              <div className="d-flex d-lg-none">{localeStore.t('number_of_people')}</div>
              <div className="d-none d-lg-flex">
                <Icon className="color-darkgray" name={ICONS.PROFILE} />
              </div>
            </div>
            {/* <Input className="full-width background-white" disabled placeholder={} /> */}
            <Quantity
              maximum={this.state.people_number}
              minimum={1}
              value={1}
              onChange={this.props.changePeopleNumber}
            />
          </div>
        </div>

        <ResponsiveContent max={WINDOW_SIZES.md}>
          <div className="col-12 col-lg-3 mb-2 mb-lg-0">
            <DropDown
              onPress={this.selectBranch}
              selected={this.props.branch?.id}
              options={getWorkingBranch.map(
                (branch): IDropDownOption<IBranch> => ({
                  value: branch,
                  label: getTranslate(branch.name),
                  key: branch.id,
                })
              )}
            >
              <div className="input full-width d-flex align-items-center color-darkgray text-size-16 justify-content-between  family-semibold text-pointer">
                {this.props.branch ? getTranslate(this.props.branch.name) : 'Select branch'}
                {this.props.loading ? (
                  <Spinner color={staticTheme.colors.color.light} />
                ) : (
                  <Icon className="ml-3" name={ICONS.CHEVRON} />
                )}
              </div>
            </DropDown>
          </div>

          <div className="d-flex flex-column" style={{ position: 'fixed', bottom: 20, left: 15, right: 15 }}>
            <BranchesSubmitButton
              loading={this.props.loading || this.state.loading}
              branch={this.props.branch}
              form={this.props.form}
            />
          </div>
        </ResponsiveContent>
      </div>
    );
  }
}
