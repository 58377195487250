import { observer } from 'mobx-react';
import * as React from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { dateFormat } from '../../../../../utils/helpers';
import { getWalletCurrency } from 'src/utils/helpers';
import './style.scss';
import { WINDOW_SIZES } from '../../../../../constants/main';
import ResponsiveContent from '../../../../components/ResponsiveContent/ResponsiveContent';
import { WalletCashback } from '../../components/WalletCashback';
import moment from 'moment';

@observer
export default class WalletBalance extends React.Component {
  renderCashbacks = () => {
    return userStore.user.cashback?.length ? (
      <div className="wallet-balance-item mt-4 background-white d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <p className="family-bold text-size-20 mr-2">{localeStore.t('balance_available_soon')}</p>
          <p className="family-bold text-size-20">{userStore.user.cashback_sum_available_soon}</p>
        </div>
        {/*{userStore.user.wallet.cashbacks.map((cashback) => (*/}

        <ResponsiveContent min={WINDOW_SIZES.md}>
          {userStore.user.cashback.map((cashback, index) => (
            <WalletCashback cashback={cashback} key={index} />
          ))}
        </ResponsiveContent>

        <ResponsiveContent max={WINDOW_SIZES.md}>
          <WalletCashback cashback={userStore.user.cashback.find((i) => !!i.available)} />
        </ResponsiveContent>
      </div>
    ) : null;
  };

  public render() {
    return (
      <div className="wallet-balance-container">
        <div className="wallet-balance px-4 py-4 background-white d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <p className="family-bold text-size-20">{getWalletCurrency()}</p>
            <p className="family-bold text-size-20">{userStore.user.wallet?.balance}</p>
          </div>
          {userStore.user.wallet.expire_date && userStore.user.wallet.expire_soon ? (
            <React.Fragment>
              <div className="d-flex flex-row justify-content-between mt-4">
                <p className="text-size-18">{localeStore.t('expiring_soon')}</p>
                <p className="text-size-18">{userStore.user.wallet.expire_soon}</p>
              </div>
              <div className="d-flex flex-row justify-content-between mt-2">
                <p className="text-size-12 text-gray text-uppercase color-gray">
                  {localeStore.t('active_till')}{' '}
                  {dateFormat(moment(userStore.user.wallet.expire_date).toDate(), `dd.MM.yyyy`)}
                </p>
              </div>
            </React.Fragment>
          ) : null}

          <ResponsiveContent max={WINDOW_SIZES.md}>{this.renderCashbacks()}</ResponsiveContent>
        </div>

        <ResponsiveContent min={WINDOW_SIZES.md}>{this.renderCashbacks()}</ResponsiveContent>
      </div>
    );
  }
}
