import React, { FC, useEffect } from 'react';
import './style.scss';
import { Link, useHistory } from 'react-router-dom';
import { SCREENS } from '../../../../../constants/screens';
import { localeStore } from '../../../../../mobx/localesStore';
import ButtonComponent from '../../../../components/Controls/Button/Button';
import DeliveryStatusText from './components/DeliveryStatusText/DeliveryStatusText';
import DeliveryTimer from './components/DeliveryTimer/DeliveryTimer';
import { ordersStore } from '../../../../../mobx/ordersStore';
import { ORDER_STATUS } from '../../../../../constants/main';
import { observer } from 'mobx-react';
import { deliveryStatusStore } from './store';
import Spinner from '../../../../components/Controls/Spinner/Spinner';

const DeliveryStatus: FC = observer(() => {
  const history = useHistory();

  useEffect(() => {
    if (
      ordersStore.order &&
      ordersStore.order.order_status_code &&
      [ORDER_STATUS.PENDING, ORDER_STATUS.ACCEPTED].includes(ordersStore.order?.order_status_code)
    ) {
      return history.push(SCREENS.ORDER(ordersStore.order?.id || ''));
    }
    deliveryStatusStore.init();
  }, [ordersStore.order?.order_status_code]);

  if (deliveryStatusStore.loading) {
    return <Spinner size={24} className="button-spinner" color={'#000'} />;
  }

  return (
    <div className="delivery-status completed-order d-flex flex-column justify-content-center align-items-center">
      <div className="container-status full-width d-flex flex-column justify-content-center align-items-center">
        <DeliveryTimer />
        <DeliveryStatusText />
      </div>
      {ordersStore.order?.order_status_code !== ORDER_STATUS.ON_HOLD && (
        <Link
          to={{
            pathname: SCREENS.MENU(),
            search: `?language=${localeStore.language}`,
          }}
          className="d-flex full-width  mb-2"
        >
          <ButtonComponent
            id={'show_order'}
            style={{ minWidth: 340 }}
            className="full-width"
            title={localeStore.t('show_menu')}
          />
        </Link>
      )}

      <Link
        to={{
          pathname: SCREENS.ORDER(ordersStore.order?.id || ''),
          search: `?language=${localeStore.language}`,
        }}
        className="d-flex full-width"
      >
        <ButtonComponent style={{ minWidth: 340 }} className="full-width background-light">
          <span className="color-dark">{localeStore.t('txt_order_details')}</span>
        </ButtonComponent>
      </Link>
    </div>
  );
});

export default DeliveryStatus;
