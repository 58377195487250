import { FC, ReactElement, memo } from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { cartStore } from 'src/mobx/cartStore';
import { IProductInsideCombo } from 'src/types/combo';
import { IProduct } from 'src/types/products';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import ProductNotes from '../../../../components/ProductNotes/ProdutcNotes';
import { isAvailabilityToShowNotes } from '../../../../helpers/productScreen';
import { DealComboItemMobile } from '../../components/DealComboItemMobile/DealComboItemMobile';
import './style.scss';

interface IProps {
  product?: IProduct;
  onSelectItem: (item: IProductInsideCombo) => void;
  onNotesChanged: (notes: string) => void;
}

export const DealComboItemsBlockMobile: FC<IProps> = memo(({ product, ...props }: IProps): ReactElement | null => {
  if (!product?.items?.length) return null;

  const showNotes: boolean = isAvailabilityToShowNotes(product, true);

  return (
    <ResponsiveContent max={WINDOW_SIZES.md}>
      <div className="product-deal-items-block-mobile">
        <ul className="product-deal-items-block-mobile__wrapper">
          {product.items.map((item) => (
            <DealComboItemMobile
              key={item.id}
              item={item}
              onSelectItem={props.onSelectItem}
              selected={Boolean(item.is_selected)}
            />
          ))}
        </ul>

        {showNotes ? (
          <ProductNotes
            loading={!product}
            onChange={props.onNotesChanged}
            value={product?.notes || ''}
            disabled={!cartStore.isEditable}
          />
        ) : null}
      </div>
    </ResponsiveContent>
  );
});
