import { FC, ReactElement, memo } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IProductExclusion } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import './style.scss';

interface IProps {
  exclusions: IProductExclusion[] | undefined;
  className?: string;
}

export const CartProductExclusions: FC<IProps> = memo(({ exclusions, className }): ReactElement | null => {
  const selectedExclusions: IProductExclusion[] | undefined = exclusions?.filter((exclusion) => exclusion.selected);

  if (!selectedExclusions?.length) return null;

  return (
    <div className={`cart-product-exclusions ${className || ''}`}>
      <p className="cart-product-exclusions__title color-gray letter-spacing-5">{localeStore.t('Exclusions')}</p>

      {selectedExclusions.map((exclusion) => (
        <p
          key={exclusion.id}
          className={`cart-product-exclusions__name color-dark ${exclusion.error ? 'text-through' : ''}`}
        >
          {getTranslate(exclusion.name)}
        </p>
      ))}
    </div>
  );
});
