export const MOENGAGE_EVENTS = {
  VIEW_ITEM_LIST: 'view_item_list',
  SELECT_ITEM: 'select_item',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  SELECT_ADDRESS: 'select_address',
  ADD_TO_CART: 'add_to_cart',
  VIEW_CART: 'view_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  REMOVE_ALL_FROM_CART: 'remove_all_from_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  CHANGE_PAYMENT_METHOD: 'change_payment_method',
  LOGIN: 'login',
  PURCHASE: 'purchase',
  SEARCH: 'search',
  FIRST_PURCHASE: 'first_purchase',
  PURCHASE_FAILED: 'purchase_failed',
  WALLET_CHOSEN: 'wallet_chosen',
  ORDER_DELIVERED: 'order_delivered',
  ORDER_REFUND: 'order_refund',
  PROMO_CODE_CHOSEN: 'promocode_chosen',
  CHECK_WALLET_HISTORY: 'check_wallet_history',
  LOG_OUT: 'log_out',
};

export const EVENTS = {
  // ROOT 10
  APP_LOADED: 10001,
  APP_FIRST_OPENED: 10002,
  APP_BRANCHES_LOADED: 10003,
  APP_HYDRATED: 10004,
  APP_LANGUAGE_UPDATED: 10005,
  APP_LANGUAGE_CHANGES: 10006,
  APP_FETCHING_UPDATES_ERROR: 10007,
  APP_CHECKING_UPDATES_ERROR: 10007,
  APP_PAGE_CHANGE: 10008,

  BRANCHES_LOADING_START: 11007,
  BRANCHES_LOADING_END: 11008,
  BRANCHES_LOADING_ERROR: 11019,
  // MENU 11
  MENU_LOADED: 11001,
  MENU_CATEGORY_CHANGED: 11002,
  MENU_PRODUCT_SELECTED: 11003,
  MENU_BANNERS_LOADED: 11004,
  MENU_BANNERS_CHANGED: 11005,
  MENU_BANNERS_PRESSED: 11006,
  MENU_SEARCH_OPENED: 11007,

  MENU_LOADING_START: 11008,
  MENU_LOADING_END: 11009,

  MENU_PART_LOADING_START: 11008,
  MENU_PART_LOADING_END: 11009,

  MENU_PART_LOADING_ERROR: 11010,
  MENU_SEARCH_PRODUCT: 11011,

  MENU_SHOW: 11012,

  // PRODUCT 12
  PRODUCT_OPENED: 12001,
  PRODUCT_ADDED_TO_FAVORITE: 12002,
  PRODUCT_REMOVED_FROM_FAVORITE: 12003,
  PRODUCT_SIZE_SELECTED: 12004,
  PRODUCT_SIZE_DESELECTED: 12005,
  PRODUCT_OPTION_SELECTED: 12006,
  PRODUCT_OPTION_DESELECTED: 12007,
  PRODUCT_NOTES_CHANGED: 12008,
  PRODUCT_QUANTITY_INCREASED: 12009,
  PRODUCT_QUANTITY_DECREASED: 12010,
  PRODUCT_SUBMITED_TO_CART: 12011,
  PRODUCT_OPTION_QUANTITY_INCREASED: 12012,
  PRODUCT_OPTION_QUANTITY_DECREASED: 12013,
  PRODUCT_EXCLUSION_SELECTED: 120014,
  PRODUCT_EXCLUSION_DESELECTED: 120015,
  CROSS_SELLING_PRODUCT_ADDED_TO_CARD: 120017,
  // CART 13
  CART_REMOVE_PRODUCT: 13002,
  CART_UPDATE_PRODUCT: 13003,
  CART_INCREASE_PRODUCT_QUANTITY: 13004,
  CART_DECREASE_PRODUCT_QUANTITY: 13005,
  CART_OPEN_PRODUCT: 13006,
  CART_OPEN_UPSALES_PRODUCT: 13007,
  CART_TO_CHECKOUT: 13008,
  CART_OPENED: 13009,
  CART_COSED: 13010,
  CART_CLEAR_MANUALLY: 13011,
  CART_NOTES_CHANGED: 13012,
  // CHECKOUT 14
  CHECKOUT_OPENED: 14001,
  CHECKOUT_PAYMENT_TYPE_CHANGED: 14002,
  CHECKOUT_ORDER_CREATED: 14003,
  CHECKOUT_PROMOCODE_APPLY_SUCCESS: 14004,
  CHECKOUT_PROMOCODE_APPLY_FAIL: 14005,
  CHECKOUT_WALLET_CHANGED: 14006,
  CHECKOUT_SOE_STATUS_CHANGED: 14007,
  CHECKOUT_SOE_INFO_CHANGED: 14008,
  CHECKOUT_CURBSIDE_STATUS_CHANGED: 14009,
  CHECKOUT_CURBSIDE_INFO_CHANGED: 14010,
  CHECKOUT_PREORDER_OPENED: 14011,
  CHECKOUT_DELIVERY_TYPE_OPENED: 14012,
  CHECKOUT_ORDER_SUBMITTED: 14013,
  CHECKOUT_ORDER_ERROR: 14014,
  CHECKOUT_PAYMENT_CARD_ADDED: 14015,
  CHECKOUT_PAYMENT_CARD_REMOVED: 14016,
  CHECKOUT_FIRST_ORDER: 14017,
  //   SOCKET 15
  SOCKET_CONNECTED: 15001,
  SOCKET_DISCONNECTED: 15002,
  SOCKET_RECONNECTED: 15003,
  SOCKET_ERROR: 15004,
  SOCKET_INCOMING_TOPIC: 15005,
  //   CONTACT US 16
  CONTACTS_OPENED: 16001,
  CONTACTS_CLOSED: 16002,
  CONTACTS_SUBMIT: 16003,
  CONTACTS_INFO_CHANGED: 16004,
  //   TABBAR 17
  //   HEADER 18
  //   DELIVERY TYPE PICKER 19
  DTP_OPENED: 19001,
  DTP_CLOSED: 19002,
  DTP_BRANCHES_OPENED: 19003,
  DTP_DELIVERY_OPENED: 19004,
  DTP_BRANCH_CHANGED: 19005,
  DTP_ADDRESS_CHANGED: 19006,
  DTP_BRANCH_CONFIRMED: 19007,
  DTP_ADDRESS_CONFIRMED: 19008,
  //   AUTH 20
  AUTH_LOGGED_IN: 20001,
  AUTH_LOGGED_OUT: 20002,
  AUTH_SUBMIT_PHONE: 20003,
  AUTH_CONFIRM_OTP: 20004,
  //   ORDERS 21
  ORDERS_LOADED: 21001,
  ORDERS_OPEDER_SELECTED: 21002,
  //   ORDER 22
  ORDER_OPENED: 22001,
  ORDER_CANCELLED: 22002,
  ORDER_CLIENT_ARRIVED: 22003,
  ORDER_REORDER_SUCCESS: 22004,
  ORDER_REORDER_FAIL: 22005,
  //   USER 23
  USER_UPDATE_INFO: 23001,
  USER_FETCH_INFO: 23002,
  USER_PAYMENT_CARD_ADDED: 23003,
  USER_PAYMENT_CARD_REMOVED: 23004,
  USER_LOCATION_DETERMINED: 23005,
  // 99
  UNHANDLED_ERROR_CREATING_ORDER: 99001,
  UNHANDLED_ERROR_CREATING_ORDER_NO_INFO_FROM_SERVER: 99001,

  ...MOENGAGE_EVENTS,
};

export type TEvents = typeof EVENTS;

export const EVENTS_MAP = {
  [EVENTS.APP_PAGE_CHANGE]: 'page_view',
  [EVENTS.APP_LOADED]: 'Mounted',
  [EVENTS.AUTH_LOGGED_IN]: 'login',
  [EVENTS.PRODUCT_OPENED]: 'view_item',
  [EVENTS.MENU_PRODUCT_SELECTED]: 'select_item',
  [EVENTS.MENU_CATEGORY_CHANGED]: 'view_item_list',
  [EVENTS.PRODUCT_SUBMITED_TO_CART]: 'add_to_cart',
  [EVENTS.PRODUCT_ADDED_TO_FAVORITE]: 'add_to_wishlist',
  [EVENTS.DTP_ADDRESS_CONFIRMED]: 'add_shipping_info',
  [EVENTS.CHECKOUT_PAYMENT_TYPE_CHANGED]: 'change_payment_method',
  [EVENTS.USER_PAYMENT_CARD_ADDED]: 'add_payment_info',
  [EVENTS.CHECKOUT_PAYMENT_CARD_ADDED]: 'add_payment_info',
  [EVENTS.CART_OPENED]: 'view_cart',
  [EVENTS.CART_REMOVE_PRODUCT]: 'remove_from_cart',
  [EVENTS.CHECKOUT_OPENED]: 'begin_checkout',
  [EVENTS.MENU_SEARCH_PRODUCT]: 'search',
  [EVENTS.CHECKOUT_FIRST_ORDER]: 'first_purchase',
  [EVENTS.CHECKOUT_ORDER_CREATED]: 'purchase',
  [EVENTS.CHECKOUT_ORDER_ERROR]: 'purchase_failed',
  [EVENTS.CONTACTS_SUBMIT]: 'generate_lead',

  [EVENTS.AUTH_CONFIRM_OTP]: 'submit_otp',
  [EVENTS.AUTH_LOGGED_OUT]: 'logout',
  [EVENTS.ORDER_CANCELLED]: 'cancel_purchase',
  [EVENTS.CHECKOUT_PROMOCODE_APPLY_SUCCESS]: 'apply_promocode',
  [EVENTS.ORDER_OPENED]: 'view_order',
  [EVENTS.APP_LANGUAGE_CHANGES]: 'change_language',
  [EVENTS.USER_UPDATE_INFO]: 'update_profile',
  [EVENTS.MENU_SHOW]: 'show_menu',
  [EVENTS.USER_LOCATION_DETERMINED]: 'determine_location',
  [EVENTS.CROSS_SELLING_PRODUCT_ADDED_TO_CARD]: 'cross_selling_product_added_to_card',

  // MOENGAGE
  [EVENTS.VIEW_ITEM_LIST]: 'view_item_list',
  [EVENTS.SELECT_ITEM]: 'select_item',
  [EVENTS.ADD_TO_WISHLIST]: 'add_to_wishlist',
  [EVENTS.SELECT_ADDRESS]: 'select_address',
  [EVENTS.ADD_TO_CART]: 'add_to_cart',
  [EVENTS.VIEW_CART]: 'view_cart',
  [EVENTS.REMOVE_FROM_CART]: 'remove_from_cart',
  [EVENTS.REMOVE_ALL_FROM_CART]: 'remove_all_from_cart',
  [EVENTS.BEGIN_CHECKOUT]: 'begin_checkout',
  [EVENTS.ADD_SHIPPING_INFO]: 'add_shipping_info',
  [EVENTS.CHANGE_PAYMENT_METHOD]: 'change_payment_method',
  [EVENTS.SEARCH]: 'search',
  [EVENTS.PURCHASE_FAILED]: 'purchase_failed',
  [EVENTS.WALLET_CHOSEN]: 'wallet_chosen',
  [EVENTS.PROMO_CODE_CHOSEN]: 'promocode_chosen',
  [EVENTS.CHECK_WALLET_HISTORY]: 'check_wallet_history',
  [EVENTS.LOGIN]: 'login',
};

export const PAYMENT_RESPONSE_CODE = {
  ERROR_INVALID_REQUEST: 10000,

  ERROR_DELIVERY_FEE: 10001,
  ERROR_MIN_ORDER: 10002,
  ERROR_DELIVERY_TYPE: 10003,
  ERROR_PAYMENT_TYPE: 10004,
  ERROR_PREORDER_DISABLED: 10005,
  ERROR_ORDER_TIME: 10006,
  ERROR_DATE_FORMAT: 10007,
  ERROR_EMPTY_CART: 10008,
  ERROR_BRANCH_DISABLED: 10009,
  ERROR_AREA_DISABLED: 10010,
  ERROR_KIOSK_DISABLED: 10011,
  ERROR_USER_DISABLED: 10012,
  ERROR_WRONG_ADDRESS: 10013,
  ERROR_PICKUP_DISABLED: 10014,
  ERROR_VAT_TAX: 10015,

  ERROR_MAXIMUM_CASH_PAYMENT_IS_EXEEDED: 10016,
  ERROR_TOTAL_AMOUNT_IS_INCORRECT: 10017,
  ERROR_INCORRECT_MAX_CASH_PAYMENT: 10019,
  ERROR_KIOSK_WAS_DELETED: 10021,
  ERROR_USER_WAS_DELETED: 10022,
  ERROR_LOGIN_TO_CREATE_ORDER: 10024,
  ERROR_BRANCH_IS_BUSY: 10025,
  ERROR_BRANCH_PREORDER_IS_BUSY: 10026,

  ERROR_DISABLED_ITEMS_PRESENT: 10101,
  ERROR_DISABLED_MODIFIERS_PRESENT: 10102,
  ERROR_DISABLED_SIZES_PRESENT: 10103,
  ERROR_DISABLED_EXCLUSIONS_PRESENT: 10104,
  ERROR_MULTIPLE_DISABLED_ENTRIES_PRESENT: 10105,
  ERROR_OUT_OF_STOCK_ITEMS_PRESENT: 10106,

  ERROR_MULTIPLE_WRONG_PRICES_PRESENT: 10200,
  ERROR_WRONG_ITEM_PRICE_PRESENT: 10201,
  ERROR_WRONG_ITEM_SIZE_PRESENT: 10202,
  ERROR_WRONG_MODIFIER_PRICE_PRESENT: 10203,

  ERROR_CURBSIDE_NOT_PICKUP: 10301,
  ERROR_WRONG_CURBSIDE_CAR_DATA: 10302,
  ERROR_CURBSIDE_ADDON_DISABLED: 10303,
  ERROR_BRANCH_NOT_SUPPORTED_CURBSIDE: 10304,

  ERROR_PROMOCODE_IS_INVALID: 10401,
  ERROR_PROMOCODE_FOR_FIRST_USER_ORDER: 10402,
  ERROR_PROMOCODE_LOGIN_TO_APPLY: 10403,
  ERROR_PROMOCODE_WRONG_BRANCH: 10404,
  ERROR_PROMOCODE_WRONG_ORDER_TYPE: 10405,
  ERROR_PROMOCODE_WRONG_TIME: 10406,
  ERROR_PROMOCODE_WRONG_USER: 10407,
  ERROR_PROMOCODE_INVALID_MENU_ENTRIES: 10408,
  ERROR_PROMOCODE_INVALID_DINEIN_TYPE: 10409,
  ERROR_PROMOCODE_MINIMUM_ORDER: 10410,
  ERROR_PROMOCODE_EXPIRED: 10411,
  ERROR_POINTS_NOT_ENOUHT: 10501,

  WARNING_CASHBACK_UNAVAILABLE_FOR_BRANCH: 10601,
  WARNING_CASHBACK_CONDITIONS_CHANGED: 10602,
  WARNING_CASHBACK_IS_UNAVAILABLE: 10603,
};

export enum PAYMENT_EXCEPTION_CODE {
  ORDER_BECAME_UNAVAILABLE = 'OrderBecameUnavailableException',
  ORDER_CANNOT_BE_PAID = 'OrderCannotBePaidException',
  PAYMENT_EXCEPTION = 'PaymentException',
}
export enum DESTINATION_LOGS {
  FIREBASE,
  GTM,
  MOENGAGE,
}
