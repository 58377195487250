import { observer } from 'mobx-react';
import { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import { WINDOW_SIZES } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from 'src/mobx/barnchStore';
import { preorderStore } from 'src/mobx/preorderStore';
import Icon from 'src/view/components/Controls/Icon/Icon';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import Preorder from 'src/view/containers/Preorder/Preorder';
import './style.scss';

@observer
export default class PreorderPicker extends Component {
  onModalOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // Open modal if preorder is available
    if (preorderStore.preorderIsAvailable) {
      event.stopPropagation();
      appStore.handleModal('preorder', true);
    }
  };

  onModalClose = () => {
    appStore.handleModal('preorder', false);
    this.setState({ open: false });
  };

  onPrerderSubmit = (time?: number) => {
    preorderStore.setPreorder(time, true);
    this.onModalClose();
  };

  render() {
    return (
      <div
        className="preorder th-bg-background-secondary  d-flex align-items-center justify-content-center"
        id={'preorder-button'}
      >
        {branchStore.branch.preorder ? (
          <PopupModal
            backdrop
            onClose={this.onModalClose}
            visible={appStore.modals.preorder}
            selfClose={false}
            content={() => (
              <Preorder
                branch={branchStore.branch}
                delivery_type={branchStore.delivery_type}
                time={preorderStore.preorder_unix}
                onSubmit={this.onPrerderSubmit}
              />
            )}
          >
            <div
              onClick={this.onModalOpen}
              className={'preorder-button d-flex align-items-center justify-content-center'}
            >
              {window.IS_MOBILE ? null : (
                <ResponsiveContent max={WINDOW_SIZES.md}>
                  <Icon name={ICONS.CALENDAR} className={'th-color-primary'} />
                </ResponsiveContent>
              )}
              <ResponsiveContent min={WINDOW_SIZES.md}>
                <span className="preorder-text family-semibold th-color-primary d-none d-lg-flex">
                  {preorderStore.preorder_time}
                </span>
              </ResponsiveContent>
              {window.IS_MOBILE ? (
                <div
                  className={
                    'preorder-mobile preorder-button  d-flex align-items-center justify-content-center th-bg-background-secondary'
                  }
                >
                  <span className="preorder-mobile  family-regular preorder-text  th-color-primary d-lg-flex">
                    {preorderStore.preorder_time}
                  </span>
                </div>
              ) : null}
            </div>
          </PopupModal>
        ) : (
          <div className={'preorder-button  d-flex align-items-center justify-content-center'}>
            <span className="preorder-text family-semibold th-color-primary  d-none d-lg-flex">
              {preorderStore.preorder_time}
            </span>
          </div>
        )}
      </div>
    );
  }
}
