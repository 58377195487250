import { IBranchPaymentType } from '../../../types/branch';
import { PAYMENT_TYPES } from '../../../constants/main';
import { PaymentType } from '../../screens/CheckoutScreen/containers/CheckoutPaymentTypes/CheckoutPaymentType/CheckoutPaymentType';
import { getPaymentType } from '../../../utils/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { checkApplePayAvailable } from '../../../utils/ApplePay';
import './style.scss';
import { observer } from 'mobx-react';
import { userStore } from '../../../mobx/userStore';
import { localeStore } from '../../../mobx/localesStore';
import { PaymentTypeCard } from '../../screens/CheckoutScreen/containers/CheckoutPaymentTypes/CheckoutPaymentTypeCard/CheckoutPaymentTypeCard';
import { ICONS } from '../../../constants/icons';
import { IPaymentCard } from '../../../types/user';
import { requests } from '../../../requests';

interface IAvailablePaymentMethods {
  storeProps: any;
}

const AvailablePaymentMethods = observer(({ storeProps }: IAvailablePaymentMethods) => {
  const [isApplePayAvaliable, setIsApplePayAvaliable] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const isApplePayAvaliable = await checkApplePayAvailable();
    setIsApplePayAvaliable(isApplePayAvaliable);
  };

  const setPaymentType = (payment_type: PAYMENT_TYPES, action?: () => void) => () => {
    storeProps?.PaymentMethod.selectPaymentType(payment_type);
    if (action) action();
    storeProps?.setIsShowPaymentTypeList(false);
    storeProps?.PaymentMethod.closeModal();
  };

  const selectPaymentCard = useCallback((card: IPaymentCard) => {
    storeProps?.PaymentMethod.selectPaymentCard(card);
    storeProps?.setIsShowPaymentTypeList(false);
    storeProps?.PaymentMethod.closeModal();
  }, []);

  const onAddCrediCardPress = () => {
    setPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT);
    storeProps?.setIsShowPaymentTypeList(false);
    storeProps?.PaymentMethod.showAddToCardModal(true);
    storeProps?.PaymentMethod.closeModal();
  };

  const onDeleteCard = async (card: IPaymentCard) => {
    await requests.deleteCreditCard(card.id);
    storeProps?.PaymentMethod.clearPaymentCard();
    await userStore.fetchUserCards();
  };

  const renderOnlinePaymentTypes = () => {
    const { payment_cards } = userStore;
    return (
      <>
        {userStore.isLogin ? (
          <>
            {/* user payment cards */}
            {payment_cards?.map((item, index) => (
              <PaymentTypeCard
                key={index}
                card={item}
                onClick={() => selectPaymentCard(item)}
                onDelete={() => onDeleteCard(item)}
                loading={false}
              />
            ))}
            {payment_cards?.length ? (
              // Add card
              <PaymentType
                icon={ICONS.PLUS}
                type_id={PAYMENT_TYPES.ONLINE_PAYMENT}
                text={localeStore.t('txt_enter_card_details')}
                onClick={onAddCrediCardPress}
              />
            ) : (
              // Online payment
              <PaymentType
                icon={ICONS.CREDIT_CARD}
                type_id={PAYMENT_TYPES.ONLINE_PAYMENT}
                text={getPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT)}
                onClick={onAddCrediCardPress}
              />
            )}
          </>
        ) : (
          // Online payment if user not logged in
          <PaymentType
            icon={ICONS.CREDIT_CARD}
            type_id={PAYMENT_TYPES.ONLINE_PAYMENT}
            text={getPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT)}
            onClick={setPaymentType(PAYMENT_TYPES.ONLINE_PAYMENT)}
          />
        )}
        {/* Apple Pay */}
        {isApplePayAvaliable ? (
          <PaymentType
            icon={ICONS.APPLE_PAY}
            type_id={PAYMENT_TYPES.APPLE_PAY}
            text={localeStore.t('applePay')}
            onClick={setPaymentType(PAYMENT_TYPES.APPLE_PAY)}
          />
        ) : null}
      </>
    );
  };
  const renderPaymentTypes = ({ id }: IBranchPaymentType) => {
    switch (id) {
      case PAYMENT_TYPES.CASH:
        return <PaymentType key={id} type_id={id} text={getPaymentType(id)} onClick={setPaymentType(id)} />;
      case PAYMENT_TYPES.TERMINAL:
        return <PaymentType key={id} type_id={id} text={getPaymentType(id)} onClick={setPaymentType(id)} />;
      case PAYMENT_TYPES.ONLINE_PAYMENT:
        return <div key={id}>{renderOnlinePaymentTypes()}</div>;
      case PAYMENT_TYPES.POINTS:
        return <PaymentType key={id} type_id={id} text={getPaymentType(id)} onClick={setPaymentType(id)} />;
    }
  };

  return (
    <div className="payment-list-section d-flex">
      {storeProps?.PaymentMethod.availablePaymentTypes?.map(renderPaymentTypes)}
    </div>
  );
});

export default AvailablePaymentMethods;
