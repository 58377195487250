import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { Integrations } from "@sentry/tracing";
import './assets/fonts/TIcon/css/icons.css';
import './assets/styles/main.scss';
import { ignoreList } from './constants/sentryIgnoreList';
import reportWebVitals from './reportWebVitals';
import { ISettings } from './types/app';

declare global {
  interface Window {
    APP_SETTINGS: string | undefined;
    SETTINGS: ISettings | undefined;
    ApplePaySession: any;
    IS_MOBILE?: boolean;
  }
}

// window.IS_MOBILE = process.env.NODE_ENV === 'development' || window.IS_MOBILE;
// console.log(process.env.NODE_ENV, process.env.REACT_APP_SENTRY, process.env.REACT_APP_SENTRY_DNS);
window.SETTINGS =
  process.env.NODE_ENV === 'production' && window.APP_SETTINGS ? JSON.parse(window.APP_SETTINGS) : undefined;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  debug: true,
  release: 'production',
  integrations:
    process.env.NODE_ENV === 'production'
      ? []
      : [
          new Sentry.Integrations.Breadcrumbs({
            console: false,
          }),
        ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  ignoreErrors: ignoreList,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// without sentry
// 150.97 KB  build/static/js/2.chunk.js
// 48.32 KB   build/static/js/main.chunk.js
// 42.86 KB   build/static/css/main.chunk.css
// 24 KB      build/static/css/2.chunk.css
// 1.58 KB    build/static/js/3.chunk.js
// 1.14 KB    build/static/js/runtime-main.js

// with sentry
// 170.76 KB  build/static/js/2.chunk.js
// 48.45 KB   build/static/js/main.chunk.js
// 42.86 KB   build/static/css/main.chunk.css
// 24 KB      build/static/css/2.chunk.css
// 1.58 KB    build/static/js/3.chunk.js
// 1.14 KB    build/static/js/runtime-main.js

// with sentry and tracing
// 180.9 KB  build/static/js/2.chunk.js
// 48.44 KB  build/static/js/main.chunk.js
// 42.86 KB  build/static/css/main.chunk.css
// 24 KB     build/static/css/2.chunk.css
// 1.58 KB   build/static/js/3.chunk.js
// 1.14 KB   build/static/js/runtime-main.js
