import Tooltip from '../../../components/Tooltip/Tooltip';
import { localeStore } from '../../../../mobx/localesStore';
import Icon from '../../../components/Controls/Icon/Icon';
import { ICONS } from '../../../../constants/icons';
import React from 'react';
import './style.scss';

type PaymentCardProps = {
  text: string;
  store: any;
  icon?: ICONS;
  image?: any;
  selected?: boolean;
  style?: React.CSSProperties;
  available?: boolean;
  rightIconProps?: string;
  onPress?: ((event: any) => void) | undefined;
};

const PaymentCard = ({ text, icon, selected, onPress, store }: PaymentCardProps) => {
  return (
    <div style={{ maxWidth: 340 }} className="selected-payment-container d-flex full-width flex-column">
      <div className={'text-size-14 family-opensans title'}>{localeStore.t('select_payment_method_label')}</div>

      <div className="selected-payment d-flex full-width justify-content-between align-items-center" onClick={onPress}>
        <div className="d-flex flex-row align-items-center">
          <div className={`payment_type ${selected ? 'selected' : ''}`}>
            <Icon name={icon || ''} className={`payment_type-icon`} />
            <p className={'text-size-16 family-opensans selected-card'}>{text}</p>
          </div>

          {!store?.isPaymentTypeAvailable ? (
            <Tooltip
              content={() => (
                <div className="card" style={{ padding: 15, minWidth: 200 }}>
                  <p className={'color-dark text-size-14 family-regular'}>
                    {localeStore.t('txt_invalid_payment_type', { paymentType: store.payment_type })}
                  </p>
                </div>
              )}
              position="top"
              direction="left"
            >
              <Icon name={ICONS.DANGER} size={20} color="#FF6D6D" className="ml-2 invalid-payment-types-icon" />
            </Tooltip>
          ) : null}
        </div>
        <Icon name={ICONS.CHEVRON} className="arrow-right" />
      </div>
    </div>
  );
};

export default PaymentCard;
