import { ordersStore } from '../../../mobx/ordersStore';
import { requests } from '../../../requests';
import { throttle } from '../../../utils/perfomance';
import { PAYMENT_STATUS } from '../../../constants/main';
import { IOrder } from '../../../types/orders';
import orderCreationRedirect from '../../../hooks/orderCreationRedirect';
import { RouterChildContext } from 'react-router-dom';

class Handlers {
  tryPaymentAgain = (history: RouterChildContext['router']['history']) => {
    if (!ordersStore.order) {
      return;
    }
    orderCreationRedirect(ordersStore.order.id, ordersStore.order, history);
  };

  checkOrderStatus = throttle(async () => {
    if (!ordersStore.order) {
      return;
    }

    if (![PAYMENT_STATUS.FAIL, PAYMENT_STATUS.SUCCESS].includes(ordersStore.order?.payment_status)) {
      await requests.orderActualize(ordersStore.order.id);
    }

    ordersStore.countDownTimer.secondsInput(59);
    return;
  }, 500);
}

export default new Handlers();
