import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import { preorderStore } from 'src/mobx/preorderStore';
import { productUtils } from 'src/utils/productUtils';
import { DELIVERY_TYPES } from '../../../constants/deliveryTypes';
import { ORDER_STATUS } from '../../../constants/main';
import { appStore } from '../../../mobx/appStore';
import { branchStore } from '../../../mobx/barnchStore';
import { addressStore } from '../../../mobx/barnchStore/address';
import { cartStore } from '../../../mobx/cartStore';
import { checkoutStore } from '../../../mobx/checkoutStore';
import { userStore } from '../../../mobx/userStore';
import client from '../../../services/client';
import { typeSMS } from '../../../types/orders';
import { IProduct } from '../../../types/products';
import Spinner from '../../components/Controls/Spinner/Spinner';
import ProductScreen from '../ProductScreen/ProductScreen';
import LinkError from './components/LinkError/LinkError';
import './style.scss';

type URLParam<T> = T | null;

const PaymentScreen: React.FC = observer(() => {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [isExpire, setIsExpire] = useState<boolean | null>(false);
  const [type, setType] = useState<typeSMS>(1);
  const [errorIcon, setErrorIcon] = useState<boolean>(true);
  const [errorKey, setErrorKey] = useState<string>('link_expired');

  useEffect(() => {
    const URLParams = new URLSearchParams(location.search);

    const [expires, orderId, type] = [URLParams.get('expires'), URLParams.get('order_id'), URLParams.get('type')] as [
      URLParam<string>,
      URLParam<number>,
      URLParam<number>
    ];

    cartStore.closeCart();
    cartStore.closeCheckout();
    branchStore.closeBranches();
    if (!orderId || !type || !expires || moment(+expires * 1000).isBefore(moment())) {
      setIsExpire(true);
      setErrorKey('link_expired');
      setLoading(false);
      return;
    }
    cartStore.isSMSPage(true);
    cartStore.SMSType(+type);
    setType(+type);
    reorder(orderId, expires, type);
  }, [cartStore.isSMSType]);

  useEffect(() => {
    if (!isExpire && !loading) showScreens();
  }, [isExpire, loading]);

  const isOrderPalaces = (error: string = 'order_placed', errorIcon: boolean = true) => {
    setIsExpire(true);
    setErrorKey(error);
    setErrorIcon(errorIcon);
    setLoading(false);
  };
  const reorder = useCallback(async (orderId: number, expires: string, type: number) => {
    try {
      setLoading(true);

      const response = await client.get(`/v3/me/orders/${orderId}/reorder?is_sms=1&expires=${expires}&type=${type}`, {
        headers: { 'Accept-Language': localeStore.language },
      });
      if (response.order.order_status_code !== ORDER_STATUS.WAITING_FOR_PAYMENT) {
        if (response.order.order_status_code === ORDER_STATUS.CANCELED && response.order.payment_result_message) {
          isOrderPalaces(response.order.payment_result_message, true);
          return;
        }

        isOrderPalaces('order_placed', false);
        return;
      }

      // User logout if the current user is not logged in
      if (userStore.isLogin && response.order.customer_mobile !== userStore.user.mobile) {
        await userStore.logout();
      }

      let area = null;
      if (response.order.delivery_type_code === DELIVERY_TYPES.DELIVERY) {
        area = {
          ...response.area,
          id: response.address_id,
          address: response.address,
        };
      }

      branchStore.setDefaultBranchId(response.branch.id);
      branchStore.setDefaultBranchPaymentMethods(response.order.payment_type);

      branchStore.setBranch(response.branch, response.order?.delivery_type_code);
      addressStore.addNewAddress(response.area);
      checkoutStore.setReorderStore(response);
      if (response.order.preorder) {
        await preorderStore.setPreorder(moment(response.order.order_date, 'YYYY-MM-DD hh:mm:ss').unix());
      }

      appStore.smsPageSession();
      cartStore.clear();
      response.products.forEach((_product: IProduct) => {
        const product = productUtils.transformProduct(_product);
        cartStore.add(product);
      });

      // Set Notes
      if (response.notes) cartStore.setNotes(response.notes);

      // Set Order for someone else
      if (response.order.is_order_for_someone_else) {
        cartStore.toggleSomeone(response.order.is_order_for_someone_else);
        cartStore.setSomeoneValue('mobile', response.order.stranger_customer_info.mobile);
        cartStore.setSomeoneValue('name', response.order.stranger_customer_info.name);
      }

      // Set Curbside
      if (response.order?.curbside) {
        cartStore.toggleCurbside();
        cartStore.setCurbsideValue('car_brand', response.order?.curbside?.car_brand);
        cartStore.setCurbsideValue('car_color', response.order?.curbside?.car_color);
        cartStore.setCurbsideValue('car_number', response.order?.curbside?.car_number);
        cartStore.setCurbsideValue('car_type', response.order?.curbside?.car_type);
      }

      if (userStore.isLogin && response.order.wallet_discount) {
        cartStore.setCheckoutWallet(1);
      }

      setLoading(false);
    } catch (error: any) {
      isOrderPalaces(error.response?.data?.message || 'error_try_again', true);
    }
  }, []);

  const showScreens = () => {
    if (type === 1) {
      cartStore.openCart();
    } else {
      branchStore.openBranches();
    }
  };

  if (loading) {
    return (
      <Spinner size={50} className="button-spinner sms_pg d-flex justify-content-center full-width" color={'#000'} />
    );
  }

  if (isExpire) {
    return <LinkError errorKey={errorKey} errorIcon={errorIcon} />;
  }
  return (
    <Switch>
      <Route path={SCREENS.SMS_PRODUCT(':id')} component={ProductScreen} />
    </Switch>
  );
});

export default PaymentScreen;
