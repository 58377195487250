import { IProductInsideCombo } from 'src/types/combo';
import {
  IProduct,
  IProductExclusion,
  IProductModifier,
  IProductModifierOption,
  IProductSize,
  PRODUCT_TYPE,
} from 'src/types/products';
import { logError } from 'src/utils/helpers';
import { isSameComboItems } from '../combo';

export const transformProductsFromCalculator = (
  products: IProduct[] | undefined = [],
  productsFromCalculator: IProduct[] | undefined = []
): { updatedProducts: IProduct[]; haveErrors: boolean } => {
  try {
    const updatedProducts = products.map((product: IProduct, index) => {
      const findProduct: IProduct | undefined = productsFromCalculator.find(
        (_, calculatedIndex) => index === calculatedIndex
      );
      const isMeal: boolean = findProduct?.type === PRODUCT_TYPE.MEAL_COMBO;

      if (findProduct) {
        product.error = findProduct.error ? findProduct.error[0] : '';
        product.price = findProduct.price;
        product.orderable = 'orderable' in findProduct ? findProduct.orderable : true;
        product.status = findProduct.status;
        product.out_of_stock = findProduct.out_of_stock;
        product.order_type = findProduct.order_type;

        product.image = findProduct.image;
        product.images = findProduct.images;

        if (findProduct.modifiers?.length) {
          product.modifiers = _transformModifiers(product.modifiers, findProduct.modifiers);
        }

        if (findProduct.exclusions && product.exclusions?.length) {
          product.exclusions = _transformExclusions(product.exclusions, findProduct.exclusions);
        }

        if (findProduct.sizes && product.size && product.size.id) {
          product.size = _transformSize(product.size, findProduct.sizes);
        }

        if (findProduct.type) {
          product.items = _transformComboItems(product.items, findProduct.items, isMeal);
        }

        // TODO:TM-3358 Temporary fix
        // if error in options
        // @ts-ignore
        const error = (product.modifiers || []).reduce((prev, curr) => {
          const optionWithError = (curr.options || []).find((option) => option?.error);
          if (optionWithError) {
            return optionWithError?.error;
          }
          return prev;
        }, '');
        // @ts-ignore
        product.error = error || product.error;
      }

      return product;
    });

    const haveErrors: boolean = updatedProducts.some((product: IProduct) => {
      if (product.error || !product.orderable) return true;

      const errorInSize: boolean = product.size ? Boolean(product.size.error) : false;
      if (errorInSize) return true;

      const errorInModifiers: boolean = _checkModifiersErrors(product.modifiers);
      if (errorInModifiers) return true;

      const errorInExclusions: boolean = _checkExclusionsErrors(product.exclusions);
      if (errorInExclusions) return true;

      const errorInProductInsideCombo: boolean = _checkProductInsideComboErrors(product.items);
      if (errorInProductInsideCombo) return true;

      return false;
    });

    return { updatedProducts, haveErrors };
  } catch (err: any) {
    logError(err);
    return { updatedProducts: products, haveErrors: false };
  }
};

const _checkModifiersErrors = (modifiers: IProductModifier[] | undefined): boolean => {
  if (!modifiers?.length) return false;

  return modifiers.some((modifier) => Boolean(modifier.error) || _checkModifierOptionsErrors(modifier.options));
};

const _checkModifierOptionsErrors = (options: IProductModifierOption[] | undefined): boolean => {
  if (!options?.length) return false;

  return options.some((option) => Boolean(option.error));
};

const _checkExclusionsErrors = (exclusions: IProductExclusion[] | undefined): boolean => {
  if (!exclusions?.length) return false;

  return exclusions.some((exclusion) => Boolean(exclusion.error));
};

const _checkProductInsideComboErrors = (items: IProductInsideCombo[] | undefined): boolean => {
  if (!items?.length) return false;

  return items.some((item) => Boolean(item.error) || _checkModifiersErrors(item.modifiers));
  // TODO: combo exclusions
  // return items.some((item) => Boolean(item.error) || _checkModifiersErrors(item.modifiers) || _checkExclusionsErrors(item.exclusions));
};

const _transformSize = (size: IProductSize, sizesFromCalculator: IProductSize[]): IProductSize => {
  const findSize: IProductSize | undefined = sizesFromCalculator.find((item) => item.id === size.id);

  if (findSize) {
    size.price = findSize.price;
    size.error = findSize.error || '';
  }

  return size;
};

const _transformModifiers = (
  modifiers: IProductModifier[] | undefined,
  modifiersFromCalculator: IProductModifier[] | undefined
): IProductModifier[] => {
  if (!modifiers?.length || !modifiersFromCalculator?.length) return modifiers || [];

  return modifiers?.map((modifier) => {
    const findModifier: IProductModifier | undefined = modifiersFromCalculator?.find((item) => item.id === modifier.id);

    if (findModifier) {
      modifier.error = findModifier.error ? findModifier.error[0] : '';
      modifier.options = _transformModifierOptions(modifier.options, findModifier.options);
    }

    return modifier;
  });
};

const _transformModifierOptions = (
  options: IProductModifierOption[] | undefined,
  optionsFromCalculator: IProductModifierOption[] | undefined
): IProductModifierOption[] => {
  if (!options?.length || !optionsFromCalculator?.length) return options || [];

  return options.map((option) => {
    const findOption: IProductModifierOption | undefined = optionsFromCalculator.find((item) => item.id === option.id);

    if (findOption) {
      option.price = findOption.price;
      option.error = findOption.error ? findOption.error[0] : '';
    }

    return option;
  });
};

const _transformExclusions = (
  exclusions: IProductExclusion[] | undefined,
  exclusionsFromCalculator: IProductExclusion[] | undefined
): IProductExclusion[] => {
  if (!exclusions?.length || !exclusionsFromCalculator?.length) return exclusions || [];

  return exclusions.map((exclusion) => {
    const findExclusion: IProductExclusion | undefined = exclusionsFromCalculator.find(
      (item) => item.id === exclusion.id
    );

    if (findExclusion) {
      exclusion.error = findExclusion.error ? findExclusion.error[0] : '';
    }

    return exclusion;
  });
};

const _transformComboItems = (
  items: IProductInsideCombo[] | undefined,
  itemsFromCalculator: IProductInsideCombo[] | undefined,
  isMeal: boolean
): IProductInsideCombo[] => {
  if (!items?.length || !itemsFromCalculator?.length) return items || [];

  if (isMeal) {
    return items.map((item) => {
      const findItem: IProductInsideCombo | undefined = itemsFromCalculator.find((_item) =>
        isSameComboItems(_item, item)
      );

      if (findItem) {
        item.out_of_stock = findItem.out_of_stock;
        item.price = findItem.price;
        item.status = findItem.status;
        item.error = findItem.error ? findItem.error[0] : '';
        item.modifiers = _transformModifiers(item.modifiers, findItem.modifiers);
        // TODO: combo exclusions
        // item.exclusions = _transformExclusions(item.exclusions, findItem.exclusions)
      }

      return item;
    });
  }

  return itemsFromCalculator.map((item) => {
    const findItem: IProductInsideCombo | undefined = itemsFromCalculator.find((_item) => _item.id === item.id);

    if (findItem) {
      item.error = findItem.error ? findItem.error[0] : '';
    }

    return item;
  });
};
