import { action, computed, observable } from 'mobx';
import { IAddressCreate, IAddressResponse, VIEW_MODE } from '../../types/address';
import { createAddress, deleteUserAddress, getUserAddresses, updateAddress } from '../../requests/address';
import { userStore } from '../userStore';
import { requests } from '../../requests';
import { DELIVERY_TYPES } from '../../constants/deliveryTypes';
import { branchStore } from './index';
import { defaultBranch } from '../../constants/main';
import { persist } from 'mobx-persist';
import { logError } from '../../utils/helpers';
import { localeStore } from '../localesStore';
import branchesScreenStore from '../../view/screens/BranchesScreen/branchesScreenStore';
import { cartStore } from '../cartStore';

export class AddressStore {
  @observable loading: boolean = false;
  @persist('object') @observable list: IAddressResponse[] = [];
  @persist('object') @observable tempAddress: IAddressCreate | undefined = undefined;
  @persist @observable selectedAddressId: number | null = null;
  @observable editAddressOnMobileMap: boolean = false;

  @computed get address(): IAddressResponse | undefined {
    // return temporary address if user is not logged in
    if (!userStore.isLogin || cartStore.isSMS) return this.tempAddress;

    return this.list.find((address) => address.id === this.selectedAddressId);
  }

  @action selectAddress = (id: IAddressResponse['id']) => (this.selectedAddressId = id);

  @action setAddressesList = (list: IAddressResponse[]) => {
    this.list = list;
    this.validateIfAddressAvailable();
  };

  /**
   * Validate address.
   * If the user is not logged in - the address is not created
   */
  @action validateTempAddress = async () => {
    if (!this.tempAddress || !userStore.isLogin || cartStore.isSMS) return;
    const address = await this.createAddress(this.tempAddress);
    if (address) {
      this.tempAddress = undefined;
      this.selectedAddressId = address.id;
    }
  };

  //  Validate if address available
  @action validateIfAddressAvailable = () => {
    if (!this.list.length) {
      branchesScreenStore.setViewMode(VIEW_MODE.CREATE);
      return;
    }
    if (!this.selectedAddressId && this.list.some((address) => address.id !== this.selectedAddressId)) {
      branchesScreenStore.selectAddressId(this.list[0].id);
    } else {
      branchesScreenStore.selectAddressId(this.selectedAddressId);
    }
  };
  /**
   * Fetch all user addresses
   */
  @action fetchAddresses = async () => {
    if (cartStore.isSMS) return null;
    if (!userStore.isLogin) {
      branchesScreenStore.setViewMode(VIEW_MODE.CREATE);
      return null;
    }
    this.loading = true;
    try {
      const response = await getUserAddresses(userStore.user?.id);
      this.setAddressesList(response);
    } catch (error: any) {
      logError('error');
    } finally {
      this.loading = false;
    }
  };

  /**
   * Push or Update address
   */
  @action addOrUpdateAddressInList = (address: IAddressResponse) => {
    const index = this.list.findIndex((listAddress) => listAddress.id === address.id);
    if (~index) {
      this.list[index] = address;
    } else {
      this.list = [address, ...this.list];
    }
  };

  /**
   * Update address
   */
  @action updateAddress = async (id: number, address: IAddressCreate): Promise<IAddressResponse | undefined> => {
    try {
      const data = await updateAddress(id, address);
      this.addOrUpdateAddressInList(data);
      return data;
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  /**
   * Create address
   */
  @action createAddress = async (address: IAddressCreate): Promise<IAddressResponse | undefined> => {
    try {
      this.loading = true;
      const data = await createAddress(address);
      this.addOrUpdateAddressInList(data);
      return data;
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  };

  @action deleteAddress = async (id: IAddressResponse['id']) => {
    try {
      this.loading = true;
      await deleteUserAddress(id);
      this.list = this.list.filter((address) => address.id !== id);
      this.selectedAddressId = null;
      branchesScreenStore.backHandler();
      this.validateIfAddressAvailable();
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  };

  /**
   * Add new address
   */
  @action addNewAddress = async (address: IAddressCreate) => {
    try {
      // save the address as a temporary address until the user logs in or is on the sms page
      if (!userStore.isLogin || cartStore.isSMS) {
        const data = await requests.branches.branchSearchAddress({
          latitude: address?.latitude || address.lat,
          longitude: address?.longitude || address.lng,
          delivery_type: DELIVERY_TYPES.DELIVERY,
        });
        address.id = 0;
        address.address = data ? data.address : localeStore.t('no_results_found');
        this.tempAddress = address;
        return this.tempAddress;
      }
      // create address instead
      return await this.createAddress(address);
    } catch (error) {
      throw error;
    }
  };
  @action toggleAddressOnMap = async (status: boolean) => {
    this.editAddressOnMobileMap = status;
  };

  /**
   * Clear store
   */
  @action clear = async () => {
    this.list = [];
    this.tempAddress = undefined;
    this.selectedAddressId = null;
    branchStore.setBranch(defaultBranch, DELIVERY_TYPES.DELIVERY);
  };

  afterHydration() {
    this.fetchAddresses();
    this.validateIfAddressAvailable();
  }
}

export const addressStore = new AddressStore();
