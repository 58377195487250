import {
  ICheckoutModifier,
  ICheckoutModifierOption,
  ICheckoutProduct,
  ICheckoutProductInsideCombo,
} from 'src/types/cart';
import { IProductInsideCombo } from 'src/types/combo';
import {
  IProduct,
  IProductExclusion,
  IProductModifier,
  IProductModifierOption,
  PRODUCT_TYPE,
} from 'src/types/products';
import { calculateProductPrice } from '../calculateProductPrice/calculateProductPrice';

export const transformProductForCalculator = (product: IProduct): ICheckoutProduct => {
  const isMeal: boolean = product.type === PRODUCT_TYPE.MEAL_COMBO;
  // if meal - then price of product and price of size are equal to sum of prices of selected items
  const _price: number = isMeal ? calculateProductPrice(product, true) : product.price;

  return {
    id: product.id,
    quantity: product.quantity,
    price: _price,
    notes: product.notes,
    size: product.size ? { id: product.size.id, price: isMeal ? _price : product.size.price } : undefined,
    modifiers: _transformModifiers(product.modifiers),
    exclusions: _transformExclusions(product.exclusions),
    items: _transformProductItems(product.items, product.size?.id, isMeal),
  };
};

const _transformModifiers = (modifiers: IProductModifier[] | undefined): ICheckoutModifier[] => {
  if (!modifiers?.length) return [];

  return modifiers.reduce((sum: ICheckoutModifier[], current) => {
    const selectedOptions = _transformModifierOptions(current.options);

    if (selectedOptions.length) {
      sum.push({ id: current.id, options: selectedOptions });
    }

    return sum;
  }, []);
};

const _transformModifierOptions = (options: IProductModifierOption[] | undefined): ICheckoutModifierOption[] => {
  if (!options?.length) return [];

  return options.reduce((sum: ICheckoutModifierOption[], current) => {
    if (current.selected) sum.push({ id: current.id, price: current.price, quantity: current.quantity });

    return sum;
  }, []);
};

const _transformExclusions = (exclusions: IProductExclusion[] | undefined): number[] => {
  if (!exclusions?.length) return [];

  return exclusions.reduce((sum: number[], current) => {
    if (current.selected) sum.push(current.id);

    return sum;
  }, []);
};

const _transformProductItems = (
  items: IProductInsideCombo[] | undefined,
  sizeId: number | undefined,
  isMeal: boolean
): ICheckoutProductInsideCombo[] => {
  if (!items?.length) return [];

  if (!isMeal) return items.map((item) => ({ ...item, modifiers: [], exclusions: [] }));

  return items.reduce((sum: ICheckoutProductInsideCombo[], current) => {
    if (current.is_selected && current.size_id === sizeId) {
      sum.push({
        ...current,
        modifiers: _transformModifiers(current.modifiers),
        // TODO: combo exclusions
        // exclusions: _transformExclusions(current.exclusions),
        exclusions: [],
      });
    }

    return sum;
  }, []);
};
