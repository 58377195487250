import { observer } from 'mobx-react';
import * as React from 'react';
import CheckoutTotalInfoDelivery from './containers/CheckoutTotalInfoDelivery/CheckoutTotalInfoDelivery';
import CheckoutTotalInfoDiscount from './containers/CheckoutTotalInfoDiscount/CheckoutTotalInfoDiscount';
import CheckoutTotalInfoPoints from './containers/CheckoutTotalInfoPoints/CheckoutTotalInfoPoints';
import CheckoutTotalInfoTotal from './containers/CheckoutTotalInfoTotal/CheckoutTotalInfoTotal';
import CheckoutTotalInfoVat from './containers/CheckoutTotalInfoVat/CheckoutTotalInfoVat';
import CheckoutTotalInfoWallet from './containers/CheckoutTotalInfoWallet/CheckoutTotalInfoWallet';
import './styles.scss';

@observer
export default class CheckoutTotalInfo extends React.Component {
  public render() {
    return (
      <div className="checkout-total-info">
        {/*  Total*/}
        <CheckoutTotalInfoTotal />

        {/* Discount */}
        <CheckoutTotalInfoDiscount />

        {/* Delivery fee */}
        <CheckoutTotalInfoDelivery />

        {/* VAT % */}
        <CheckoutTotalInfoVat />

        {/* Wallet discount  */}
        <CheckoutTotalInfoWallet />

        {/* Points */}
        <CheckoutTotalInfoPoints />
      </div>
    );
  }
}
