import { ORDER_STATUS, PAYMENT_STATUS, PAYMENT_TYPES } from '../../../../../constants/main';
import { Link, useHistory } from 'react-router-dom';
import { SCREENS } from '../../../../../constants/screens';
import { localeStore } from '../../../../../mobx/localesStore';
import ButtonComponent from '../../../../components/Controls/Button/Button';
import React, { useCallback, useMemo } from 'react';
import { tryPaymentAgainStore } from '../../store';
import { observer } from 'mobx-react';
import handlers from '../../handlers';
import { IOrder } from '../../../../../types/orders';

const PaymentActionBtn = observer(() => {
  const history = useHistory();

  const orderCompleteBtnText = useMemo(() => {
    const showPayButton: boolean =
      tryPaymentAgainStore.order?.order_status_code === ORDER_STATUS.WAITING_FOR_PAYMENT &&
      !tryPaymentAgainStore.timeOut;

    const isPaymentConfirmTxt: boolean = [PAYMENT_TYPES.CASH, PAYMENT_TYPES.TERMINAL].includes(
      tryPaymentAgainStore.PaymentMethod?.selectedPaymentType as PAYMENT_TYPES
    );

    if (isPaymentConfirmTxt && showPayButton) {
      return 'txt_place_order';
    }

    if (showPayButton) {
      return 'complete_payment_button';
    }

    return 'show_menu';
  }, [
    tryPaymentAgainStore.order?.order_status_code,
    tryPaymentAgainStore.timeOut,
    tryPaymentAgainStore.PaymentMethod?.selectedPaymentType,
  ]);

  const handlerOrderComplete = useCallback(async () => {
    if (
      tryPaymentAgainStore.order?.order_status_code === ORDER_STATUS.WAITING_FOR_PAYMENT &&
      !tryPaymentAgainStore.timeOut
    ) {
      await tryPaymentAgainStore.payOrder(history);
    } else {
      history.push(SCREENS.MENU());
    }
  }, []);

  const CompletePayment = () => {
    const { payment_status, is_tpa_needed, order_status_code } = tryPaymentAgainStore.order as IOrder;

    const paymentNonReceived = [PAYMENT_STATUS.NON_RECEIVED, PAYMENT_STATUS.UNDEFINED].includes(payment_status);
    const orderHasNotBeenCanceled = order_status_code !== ORDER_STATUS.CANCELED;

    if (paymentNonReceived && orderHasNotBeenCanceled && !is_tpa_needed) {
      const countDownTextMessage = () => {
        if (tryPaymentAgainStore.countDownTimer.disabled) {
          return localeStore.t('check_status_button') + ' ' + tryPaymentAgainStore.countDownTimer.formattedResult;
        }

        if (!tryPaymentAgainStore.countDownTimer.disabled) {
          return localeStore.t('check_status_button');
        }

        return null;
      };

      return (
        <div className="d-flex full-width mb-2">
          <ButtonComponent
            id={'checkStatusButton'}
            style={{ minWidth: 340 }}
            className="full-width background-light"
            disabled={tryPaymentAgainStore.countDownTimer.disabled}
            // @ts-ignore
            onClick={() => handlers.checkOrderStatus(tryPaymentAgainStore.order?.id)}
            title={localeStore.t(orderCompleteBtnText)}
          >
            <span className="color-dark"> {countDownTextMessage()} </span>
          </ButtonComponent>
        </div>
      );
    }

    return (
      <div className="d-flex full-width mb-2">
        <ButtonComponent
          id={'show_order'}
          style={{ minWidth: 340 }}
          className="full-width"
          onClick={handlerOrderComplete}
          title={localeStore.t(orderCompleteBtnText)}
        />
      </div>
    );
  };
  return (
    <div className="d-flex flex-column">
      {CompletePayment()}

      <Link
        to={{
          pathname: SCREENS.ORDER(tryPaymentAgainStore.order?.id || ''),
          search: `?language=${localeStore.language}`,
        }}
        className="d-flex full-width"
      >
        <ButtonComponent style={{ minWidth: 340 }} className="full-width background-light">
          <span className="color-dark">{localeStore.t('txt_order_details')}</span>
        </ButtonComponent>
      </Link>
    </div>
  );
});

export default PaymentActionBtn;
