import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { RouteComponentProps, StaticContext } from 'react-router';
import { Link } from 'react-router-dom';
import { ICONS } from 'src/constants/icons';
import { SCREENS } from 'src/constants/screens';
import { localeStore } from 'src/mobx/localesStore';
import { requests } from 'src/requests';
import { IOrder } from 'src/types/orders';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import DropDownModal from 'src/view/components/Controls/DropDownModal/DropDownModal';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Image from 'src/view/components/Image/Image';
import Donation from 'src/view/components/Tooltip/Donation/Donation';
import '../../style.scss';

interface IState {
  order?: IOrder;
  loading: boolean;
}

interface LocationState {
  order: IOrder | undefined;
}

interface IOrderCompletedScreenProps extends RouteComponentProps<{ id: string }, StaticContext, LocationState> {}

export default class OrderCompleted extends React.Component<IOrderCompletedScreenProps, IState> {
  ref: any = null;
  state: IState = {
    order: this.props.location.state?.order,
    loading: false,
  };

  componentDidMount = async () => {
    const { order } = this.state;
    this.ref?.scrollIntoView({ block: 'end' });
    if (!order) {
      this.fetchOrder();
      return;
    }
    this.logOrderCreationEvent(order);
  };

  componentDidUpdate = () => {
    this.ref?.scrollIntoView({ block: 'end' });
  };

  fetchOrder = async () => {
    try {
      const order = await requests.getOrderById(this.props.match.params.id);
      this.setState({ order });
      this.logOrderCreationEvent(order);
    } catch (error: any) {
      this.props.history.replace(SCREENS.NOT_FOUND());
    }
  };

  logOrderCreationEvent = (order: IOrder) => {
    // logger.logEvent(EVENTS.CHECKOUT_ORDER_CREATED, order);
  };

  handleClientArrived = async () => {
    try {
      this.setState({ loading: true });
      const response = await requests.clientArrivedRequest(this.state.order!.id);

      if (response.result && response.data?.client_arrived && response.data?.id === this.state.order?.id) {
        const curbside: IOrder['curbside'] = this.state.order?.curbside
          ? {
              ...this.state.order?.curbside,
              client_arrived: 1,
            }
          : {
              driver_name: '',
              car_brand: '',
              car_color: '',
              car_type: '',
              car_number: '',
              curbside_fee: 0,
              client_arrived: 1,
              client_arrived_at: null,
            };
        this.setState(
          {
            order: {
              ...this.state.order!,
              curbside,
            },
            loading: false,
          },
          this.updateState
        );
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  updateState = () => {
    if (!this.state.order) return null;
    this.props.history.replace(SCREENS.ORDER_COMPLETED(this.state.order.id), { order: this.state.order });
  };

  renderCurbsideModal = ({ closeModal }: { closeModal: any }) => (
    <div className="d-flex flex-column full-width curbside-confirm-modal">
      <div className="info-popup-content d-flex flex-column">
        <h1 className="info-popup-title">
          <h2 className="color-dark text-size-18 mb-3 text-center">{localeStore.t('have_you_arrived')}</h2>
        </h1>
        <p className="color-dark text-size-16 mb-3 text-center">{localeStore.t('inform_admin_curbside')}</p>
        <Icon className="info-popup-close" name={ICONS.CLOSE} size={14} color={'#B6B6B6'} />
      </div>

      <div className="info-popup-buttons d-flex flex-row full-width justify-content-between">
        <ButtonComponent
          className="info-popup-button curbside-confirm-button"
          onClick={this.handleClientArrived}
          title={localeStore.t('txt_yes')}
        />
        <div className="mr-1"></div>
        <ButtonComponent className="info-popup-button background-light color-gray">
          <span className="color-dark">{localeStore.t('txt_no')}</span>
        </ButtonComponent>
      </div>
    </div>
  );

  public render() {
    if (!this.state.order) return null;
    return (
      <div
        className="container  completed-order d-flex flex-column justify-content-center align-items-center"
        ref={(ref) => (this.ref = ref)}
      >
        <div className="d-flex flex-column flex-grow-1 flex-lg-grow-0 justify-content-center full-width">
          <div className="d-flex flex-column">
            <div className="d-flex flex-column align-items-center">
              <h1 className="family-regular mb-3 mb-lg-4 completed-order-title">
                {localeStore.t('Thanks for your payment')}
              </h1>
              <div className="d-flex flex-row mb-lg-5 mb-0">
                <p className="family-semibold text-md-size-14 completed-order-subtitle">
                  {localeStore.t('order_number_is')}{' '}
                  <Link
                    id={'order_number'}
                    className="ml-3"
                    to={{
                      pathname: SCREENS.ORDER(this.props.match.params.id),
                      search: `?language=${localeStore.language}`,
                    }}
                  >
                    {this.state.order?.order_key || <Skeleton width={130} />}
                  </Link>
                </p>
              </div>
            </div>
            <div className=" d-flex flex-column full-width mt-4 mt-lg-5 completed-order-products pl-2 pb-2 pt-1">
              <p className="color-darkgray mb-2 mb-lg-4 text-md-size-14 ompleted-order-subtitle">
                {this.state.order?.products.length} {localeStore.t('txt_items')}
              </p>
              <div className="row d-flex completed-order-list flex-row flex-lg-wrap full-width">
                {this.state.order?.products.map((product) => {
                  return (
                    <div key={product.id} className="col-3 ">
                      <div className="completed-order-product ">
                        <Image
                          src={product.image}
                          wrapperClassName={'full-width '}
                          key={product.id}
                          aspectRatio={1.5}
                          width={150}
                        />
                        {product.is_donation ? (
                          <div className="completed-order-donation">
                            <Donation />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column full-width mb-4 mt-lg-5">
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-column flex-grow-1  flex-lg-grow-0">
              {this.state.order?.is_curbside ? (
                <DropDownModal
                  position={'top'}
                  asPopupInMobile
                  disabled={Boolean(this.state.order.curbside?.client_arrived || this.state.loading)}
                  content={this.renderCurbsideModal}
                >
                  <ButtonComponent
                    className="curbside-button full-width mb-2"
                    disabled={Boolean(this.state.order.curbside?.client_arrived || this.state.loading)}
                    loading={this.state.loading}
                  >
                    <div className="d-flex flex-row">
                      {this.state.order.curbside?.client_arrived ? (
                        <Icon className={'mr-3'} name={ICONS.CHECK} color={'#fff'} />
                      ) : null}
                      <div className="curbside-button-text">{localeStore.t('im_here')}</div>
                    </div>
                  </ButtonComponent>
                </DropDownModal>
              ) : null}

              <Link
                to={{
                  pathname: SCREENS.ORDER(this.state.order?.id),
                  search: `?language=${localeStore.language}`,
                }}
                className="d-flex full-width  mb-2"
              >
                <ButtonComponent
                  id={'show_order'}
                  style={{ minWidth: 340 }}
                  className="full-width"
                  title={localeStore.t('show_order')}
                />
              </Link>

              <Link
                to={{
                  pathname: SCREENS.MENU(),
                  search: `?language=${localeStore.language}`,
                }}
                className="d-flex full-width "
              >
                <ButtonComponent style={{ minWidth: 340 }} className="full-width background-light">
                  <span className="color-dark">{localeStore.t('txt_back_to_menu')}</span>
                </ButtonComponent>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
