import * as React from 'react';
import { throttle } from 'src/utils/perfomance';
import { CSSProperties, RefObject } from 'react';
import './style.scss';

interface IScrollableListProps<T> {
  list: T[];
  current_page: number;
  last_page: number;
  renderItem: (item: T | undefined, index: number) => any;
  renderEmptyList?: React.ReactElement;
  renderHeader?: () => React.ReactElement | React.ReactElement;
  loading?: boolean;
  fetch: (page?: number) => void;
  clear?: (cancellable: boolean) => void;
  style?: CSSProperties;
  scrollElement?: RefObject<any>;
  className?: string;
}

export default class ScrollableList extends React.Component<IScrollableListProps<any>> {
  static defaultProps = {
    classname: '',
    renderHeader: () => null,
  };
  constructor(props: IScrollableListProps<any>) {
    super(props);
    if (props.clear) {
      props.clear(false);
    }
  }

  scrollElement = this.props.scrollElement?.current || window;
  isEmpty = () => !this.props.loading && this.props.current_page === this.props.last_page && !this.props.list.length;

  componentDidMount() {
    this.props.fetch(1);
    this.scrollElement.addEventListener('scroll', this.throttleScroll);
  }

  componentWillUnmount = () => {
    if (this.props.clear) {
      this.props.clear(true);
    }
    this.scrollElement.removeEventListener('scroll', this.throttleScroll);
  };

  onWindowScroll = () => {
    if (this.props.loading || this.props.current_page >= this.props.last_page) return;
    if (this.scrollElement === window) {
      const offset = document.getElementById('footer')?.clientHeight || 0;
      const currentScroll = window.innerHeight + document.documentElement.scrollTop - offset;
      const currentHeight = window.document.body.clientHeight - offset;

      if (currentHeight - currentScroll < 600) {
        this.props.fetch();
      }
    } else {
      if (this.scrollElement.scrollHeight - this.scrollElement.scrollTop < 1100) {
        this.props.fetch();
      }
    }
  };

  throttleScroll = throttle(this.onWindowScroll, 200);

  renderLoader = (i: any, index: number) => this.props.renderItem(undefined, index);

  renderFooter = () => {
    if (!this.props.loading && this.props.current_page >= this.props.last_page) return null;
    return [1, 2, 3, 4, 5].map(this.renderLoader);
  };

  renderEmptyList = () => {
    if (this.isEmpty()) {
      return this.props.renderEmptyList;
    }
    return null;
  };

  public render() {
    return (
      <div
        style={{ ...this.props.style }}
        className={`scrollable-list ${this.isEmpty() ? 'empty' : ''} ${this.props.className ?? ''}`}
      >
        {this.props.renderHeader ? this.props.renderHeader() : null}
        {this.props.list.map(this.props.renderItem)}
        {this.renderEmptyList()}
        {this.renderFooter()}
      </div>
    );
  }
}
