import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { IReservation } from 'src/types/reservations';
import { EmptyList } from 'src/view/components/ScrollableList/EmptyList';
import ScrollableList from 'src/view/components/ScrollableList/ScrollableList';
import { SCREENS } from '../../../constants/screens';
import { reservationsStore } from '../../../mobx/reservationsStore';
import { PageContainer } from '../../components/PageContainer/PageContainer';
import ReservationCreateScreen from '../ReservationCreateScreen/ReservationCreateScreen';
import ReservationScreen from '../ReservationScreen/ReservationScreen';
import ReservationItem from './components/ReservationItem/ReservationItem';

@observer
export default class ReservationsScreen extends Component {
  renderItem = (reservation: IReservation | undefined, index: number) => (
    <ReservationItem reservation={reservation} key={reservation?.id || `loading-${index}`} />
  );

  render() {
    return (
      <PageContainer>
        <ScrollableList
          clear={reservationsStore.clear}
          renderItem={this.renderItem}
          renderEmptyList={<EmptyList />}
          current_page={reservationsStore.pagination.current_page}
          last_page={reservationsStore.pagination.last_page}
          list={reservationsStore.pagination.data}
          loading={reservationsStore.pagination.loading}
          fetch={reservationsStore.fetchReservations}
        ></ScrollableList>

        <Switch>
          <Route path={SCREENS.CREATE_RESERVATION()} component={ReservationCreateScreen} />
          <Route path={SCREENS.RESERVATION(':id')} component={ReservationScreen} />
        </Switch>
      </PageContainer>
    );
  }
}
