import React from 'react';
import Modal from 'react-modal';
import { ICONS } from 'src/constants/icons';
import ResponsiveContent from '../../ResponsiveContent/ResponsiveContent';
import Icon from '../Icon/Icon';

import './style.scss';

Modal.setAppElement('#root');

export interface IModalComponentProps {
  isOpen: boolean;
  fullScreen?: boolean;
  style?: object;
  children?: React.ReactNode;
  withCloseIcon?: boolean;
  withPane?: boolean;
  onAfterOpen?: () => void;
  onRequestClose?: () => void;
  className?: string;
  defaultAnimation?: string;
  iconClassName?: string;
  closeBtnClass?: string;
  disableSlideDownFor?: string[];
  [x: string]: any; // rest props
}

interface State {
  height: number;
}
export default class CustomModalComponent extends React.Component<IModalComponentProps, State> {
  x: number[] = [];
  toushStart = null;
  touchEnd = null;

  state = {
    height: 0,
  };

  public render() {
    const {
      isOpen = false,
      style = {},
      withCloseIcon = false,
      withPane = false,
      children = null,
      onAfterOpen,
      onRequestClose,
      className,
      defaultAnimation = 'slideup',
      iconClassName,
      disableSlideDownFor,
      fullScreen = false,
      closeBtnClass = '',
      ...props
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        overlayElement={(props, contentElement) => <div {...props}>{contentElement}</div>}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={onRequestClose}
        onAfterOpen={onAfterOpen}
        style={{
          overlay: {
            background: 'rgba(0,0,0,.25)',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto',
          },
          ...style,
        }}
        contentElement={() => (
          <div
            onClick={(e) => e.stopPropagation()}
            className={`modal-content ${defaultAnimation} ${className} ${withPane ? 'modal_pane' : ''} ${
              fullScreen ? 'full-screen-modal' : ''
            }`}
            style={{ marginTop: this.state.height }}
          >
            {children}
            {withCloseIcon && (
              <button className={`modal-close-icon d-none d-lg-flex ${closeBtnClass}`} onClick={onRequestClose}>
                <Icon className={iconClassName} name={ICONS.CLOSE} />
              </button>
            )}
            <ResponsiveContent max={1024}>
              {withPane && <div onClick={onRequestClose} className={'modal-pane'} />}
            </ResponsiveContent>
          </div>
        )}
        {...props}
      />
    );
  }
}
