import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { PAYMENT_TYPES } from 'src/constants/main';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import { userStore } from 'src/mobx/userStore';
import { formatPrice } from 'src/utils/formatter';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import { ICONS } from '../../../../../constants/icons';
import Icon from '../../../../components/Controls/Icon/Icon';
import { appStore } from '../../../../../mobx/appStore';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutSubmit extends Component<Props> {
  get buttonStatus(): [string, any] {
    let message = localeStore.t('txt_place_order');
    const props = {
      disabled: false,
    };
    if (!userStore.isLogin && !cartStore.checkout.modals.login) {
      message = localeStore.t('txt_login');
    } else if (cartStore.checkout.modals.login) {
      // if payment type if points
      props.disabled = true;
      message = localeStore.t('Logging in...');
    } else if (!cartStore.isValidPaymentType) {
      // if payment type if points
      props.disabled = true;
      message = localeStore.t('Select_payment_type_field');
    } else if (cartStore.checkout.payment_type === PAYMENT_TYPES.CASH && branchStore.branch.max_cash_payment) {
      // if branch is not working and preorder not available
      if (
        (!cartStore.calculator.promocode && branchStore.branch.max_cash_payment < cartStore.calculator.total) ||
        (cartStore.calculator.promocode && branchStore.branch.max_cash_payment < cartStore.calculator.discounted_total)
      ) {
        props.disabled = true;
        message = localeStore.t('max_cash_payment_is', { max_cash: formatPrice(branchStore.branch.max_cash_payment) });
      }
    } else if (cartStore.checkout.payment_type === PAYMENT_TYPES.POINTS) {
      // if payment type if points
      if (userStore.user.points < cartStore.calculator.redeem_points) {
        // not enough points
        props.disabled = true;
        message = localeStore.t('not_enough_points');
      }
    } else if (branchStore.delivery_type === DELIVERY_TYPES.DELIVERY) {
      // if delivery
      if (!cartStore.calculator.promocode && cartStore.calculator.left_to_min_order) {
        // minimum order
        props.disabled = true;
        message = `${localeStore.t('minimum_order_alert_message')} ${formatPrice(cartStore.calculator.min_order)}`;
      } else if (branchStore.branch.max_cash_payment && cartStore.checkout.payment_type === PAYMENT_TYPES.CASH) {
        // max cash payment
        if (!cartStore.calculator.promocode && branchStore.branch.max_cash_payment < cartStore.calculator.total) {
          props.disabled = true;
          message = `${localeStore.t('max_cash_order')} ${formatPrice(branchStore.branch.max_cash_payment)}`;
        } else if (
          cartStore.calculator.promocode &&
          branchStore.branch.max_cash_payment < cartStore.calculator.discounted_total
        ) {
          props.disabled = true;
          message = `${localeStore.t('max_cash_order')} ${formatPrice(branchStore.branch.max_cash_payment)}`;
        }
      }
    } else if (
      !cartStore.calculator.branch?.is_working_at_specified_time &&
      !branchStore.branch.preorder &&
      !cartStore.checkout.is_donation_only
    ) {
      // if branch is not working and preorder not available
      props.disabled = true;
    } else if (
      branchStore.branch.delivery_type !== DELIVERY_TYPES.BOTH &&
      branchStore.delivery_type !== branchStore.branch.delivery_type &&
      !appStore.settings?.donation?.enabled
    ) {
      // if selected deliivery type is not available any more
      message =
        branchStore.delivery_type === DELIVERY_TYPES.PICKUP
          ? localeStore.t('txt_pickup_not_allowed')
          : localeStore.t('txt_delivery_not_allowed');
      props.disabled = true;
    } else {
      message = localeStore.t('txt_place_order');
    }

    if (cartStore.areAllProductsUnavailable) {
      props.disabled = true;
    }

    return [message, props];
  }

  render() {
    const [message, props] = this.buttonStatus;
    const loading = cartStore.calculator.loading || cartStore.checkout.loading;
    const isApplePay = cartStore.checkout.payment_type === PAYMENT_TYPES.APPLE_PAY && userStore.isLogin;

    return (
      <div className="d-flex full-width align-items-center">
        <ButtonComponent
          {...props}
          id={'checkout_submit'}
          loading={loading}
          disabled={props.disabled}
          style={{
            maxWidth: 'auto',
            width: '100%',
            ...(isApplePay && {
              background: '#000000',
              padding: 0,
            }),
          }}
          onClick={cartStore.onCheckoutSubmit}
        >
          {isApplePay && cartStore.isValidPaymentType ? (
            <Icon name={ICONS.APPLE_PAY} size={40} color="#FFFFFF" />
          ) : (
            message
          )}
        </ButtonComponent>
      </div>
    );
  }
}

export default withRouter(CheckoutSubmit);
