import React, { ChangeEvent, Component } from 'react';
import { observer } from 'mobx-react';

import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from '../../../../../mobx/localesStore';

import PhoneInput from '../../../../components/Controls/PhoneInput/PhoneInput';
import Toggle from '../../../../components/Controls/Toggle/Toggle';
import Input from '../../../../components/Controls/Input/Input';
import './style.scss';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { ICONS } from 'src/constants/icons';
import { appStore } from '../../../../../mobx/appStore';

type Props = {};
type State = {};

@observer
export default class CheckoutSomeone extends Component<Props, State> {
  state = {
    phoneNumber: '',
  };

  toggleSomeone = () => {
    cartStore.setSomeoneValue('mobile', `${appStore.settings?.default_country_code || '966'}`);
    cartStore.toggleSomeone();
  };

  handleChange = (name: 'mobile' | 'name') => (event: ChangeEvent<HTMLInputElement> | string) => {
    cartStore.setSomeoneValue(name, typeof event === 'string' ? event : event.target.value);
  };

  handleToggleContactedWithDriver = () =>
    (cartStore.checkout.should_contact_by_driver = !cartStore.checkout.should_contact_by_driver);

  openModal = () => {
    cartStore.setModalValue('someone', true);
  };

  render() {
    const { stranger_customer_info, is_order_for_someone_else } = cartStore.checkout;
    const { name, mobile } = stranger_customer_info;
    return (
      <div className="checkout-someone mb-0 mb-lg-4">
        <div className="d-none d-lg-flex flex-column justify-content-between">
          <label style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <Toggle checked={is_order_for_someone_else} onToggle={this.toggleSomeone} />
            <span className="color-dark family-regular text-size-18">{localeStore.t('order_for_sb')}</span>
          </label>

          {is_order_for_someone_else && (
            <div className="d-flex flex-row align-items-start mt-4">
              <div className="d-flex flex-column full-width mb-2 mr-1 someone-phone">
                <PhoneInput
                  required
                  value={mobile}
                  inputClass="input-phone"
                  inputProps={{
                    name: 'mobile',
                    required: true,
                  }}
                  enableSearch={false}
                  disableSearchIcon={true}
                  enableAreaCodes
                  onChange={this.handleChange('mobile')}
                  placeholder={localeStore.t('mobile_of_recipient')}
                  countryCodeEditable={false}
                />
                {(cartStore.checkout.stranger_customer_info.mobile.length < 9 ||
                  cartStore.checkout.stranger_customer_info.mobile.length > 13) && (
                  <p className="login-error color-danger family-regular mt-1">
                    {localeStore.t('error_invalid_mobile')}
                  </p>
                )}
              </div>

              <div className="d-flex flex-column full-width ml-1 ">
                <Input
                  value={name}
                  className="full-width"
                  inputClassName="full-width family-semibold text-size-16 color-dark"
                  onChange={this.handleChange('name')}
                  placeholder={localeStore.t('name_of_recipient')}
                  required
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="d-flex d-lg-none flex-row justify-content-between align-items-center py-3"
          onClick={this.openModal}
        >
          <div className="d-flex flex-column overflow-hidden">
            <span className="text-size-16 family-semibold color-dark">{localeStore.t('order_for_sb')}</span>
            <span className="text-size-10 color-gray checkout_curbside-text">{`${mobile} ${name}`}</span>
          </div>
          {!mobile ? (
            <Icon name={ICONS.CHEVRON} className="color-dark arrow-right" />
          ) : (
            <Icon name={ICONS.PENCIL} className="d-flex align-items-center color-dark" />
          )}
        </div>
      </div>
    );
  }
}
