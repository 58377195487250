import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { ORDER_STATUS } from 'src/constants/main';
import { SCREENS } from 'src/constants/screens';
import { branchStore } from 'src/mobx/barnchStore';
import { cartStore } from 'src/mobx/cartStore';
import { localeStore } from 'src/mobx/localesStore';
import client from 'src/services/client';
import { IOrder } from 'src/types/orders';
import { IProduct } from 'src/types/products';
import { getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import ButtonComponent from 'src/view/components/Controls/Button/Button';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';
import { addressStore } from '../../../../../mobx/barnchStore/address';
import branchesScreenStore from '../../../BranchesScreen/branchesScreenStore';

interface Props extends RouteComponentProps {
  order?: IOrder | null;
}
interface State {
  confirmModal: boolean;
  loading: boolean;
  error: string;
}

class OrderReorder extends Component<Props, State> {
  state = {
    confirmModal: false,
    loading: false,
    error: '',
  };

  closeModal = () => {
    this.setState({ confirmModal: false, error: '' });
  };

  confirmReorder = () => {
    this.setState({ confirmModal: true });
  };

  reorder = async () => {
    try {
      if (!this.props.order) return null;
      this.setState({ loading: true, confirmModal: false });

      const response = await client.get(`/v3/me/orders/${this.props.order?.id}/reorder`);

      /**
       * if we are trying to create a repeat order, we need to check the type of delivery,
       * if we create an order with the type of delivery, check whether we have such an address in the list,
       * if not - we need to create a new address using the coordinates from the reorder
       */
      if (response.order.delivery_type_code === DELIVERY_TYPES.DELIVERY) {
        // check if this address is available
        const getAddress = await addressStore.list.find((address) => address.id === response.address_id);
        // If the address is available - select it
        if (getAddress) {
          addressStore.selectAddress(getAddress.id);
        } else {
          // If there is no address - create a new one
          // @ts-ignore
          const data = await addressStore.addNewAddress({
            latitude: response.area.latitude,
            longitude: response.area.longitude,
          });
          if (data?.id) {
            addressStore.selectAddress(data.id);
            branchesScreenStore.selectAddressId(data.id);
          }
        }
      }

      branchStore.setBranch(response.branch, response.order?.delivery_type_code);
      cartStore.clear();
      response.products.forEach((_product: IProduct) => {
        const product = productUtils.transformProduct(_product);
        cartStore.add(product);
      });
      cartStore.openCart();

      // Set Order for someone else
      if (response.order.is_order_for_someone_else) {
        cartStore.toggleSomeone(response.order.is_order_for_someone_else);
        cartStore.setSomeoneValue('mobile', response.order.stranger_customer_info.mobile);
        cartStore.setSomeoneValue('name', response.order.stranger_customer_info.name);
      }

      // Set Curbside
      if (response.order?.curbside) {
        cartStore.toggleCurbside();
        cartStore.setCurbsideValue('car_brand', response.order?.curbside?.car_brand);
        cartStore.setCurbsideValue('car_color', response.order?.curbside?.car_color);
        cartStore.setCurbsideValue('car_number', response.order?.curbside?.car_number);
        cartStore.setCurbsideValue('car_type', response.order?.curbside?.car_type);
      }

      this.props.history.push(SCREENS.MENU());

      this.setState({ loading: false });
    } catch (error: any) {
      if (error && error.response) {
        this.setState({ error: error.response.data.message, loading: false });
      } else {
        this.setState({ error: error.message, loading: false });
      }
    }
  };

  render() {
    const { order } = this.props;
    if (
      !order ||
      (order.order_status_code !== ORDER_STATUS.COMPLETED && order.order_status_code !== ORDER_STATUS.CANCELED)
    )
      return null;
    return (
      <>
        <PopupModal
          position={'top'}
          direction={'down'}
          justify={'center'}
          selfClose={false}
          backdrop
          mobile={{ popup: true }}
          visible={Boolean(this.state.error) || this.state.confirmModal}
          content={({ closeModal }) =>
            Boolean(this.state.error) ? (
              <ModalMessage
                wrapperClassName={` px-2 py-3 `}
                minWidth={350}
                maxWidth={400}
                closeModal={() => {
                  this.closeModal();
                  closeModal();
                }}
                title={this.state.error}
                options={[{ text: localeStore.t('Close') }]}
              />
            ) : (
              <ModalMessage
                wrapperClassName={` px-2 py-3 `}
                minWidth={350}
                maxWidth={400}
                closeModal={() => {
                  this.closeModal();
                  closeModal();
                }}
                title={
                  order.delivery_type_code === DELIVERY_TYPES.DELIVERY
                    ? localeStore.t('confirm_delivery', { address: order.address })
                    : localeStore.t('confirm_pickup', {
                        branch_name: order.branch ? getTranslate(order.branch.name) : '',
                      })
                }
                options={[
                  { text: localeStore.t('txt_yes'), onPress: this.reorder },
                  { text: localeStore.t('txt_no'), className: 'background-light', textClassName: 'color-dark' },
                ]}
              />
            )
          }
        >
          <ButtonComponent
            disabled={this.state.loading}
            className="cancel-order-button full-width mt-2 mt-lg-4"
            loading={this.state.loading}
            onClick={this.confirmReorder}
            spinnerColor={'#F1F1F1'}
          >
            {localeStore.t('reorder')}
          </ButtonComponent>
        </PopupModal>
      </>
    );
  }
}

export default withRouter(OrderReorder);
