import { IError } from 'src/types/main';
import { cartStore } from '.';
import { localeStore } from '../localesStore';
import { messagesStore } from 'src/mobx/messagesStore';
import { CashbackErrorCode, cashbackErrorDesc, cashbackErrorTitles } from 'src/constants/errors';
import { formatPrice } from 'src/utils/formatter';
import { appStore } from 'src/mobx/appStore';
import { branchStore } from '../barnchStore';

export const CHECKOUT_HANDLERS = {
  MESSAGE: (message: string) => {
    cartStore.setModalValue(
      'error',
      cartStore.createError(localeStore.t(message), '', [
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('Close'),
        },
      ])
    );
  },
  MESSAGE_EVENT: (error: IError) => cartStore.setModalValue('error', error),
  PREORDER: ({ message, details }: { message: string; details?: { busy_expire_in: string } }) => {
    if (branchStore.branch && details?.busy_expire_in) {
      branchStore.branch.is_busy = true;
      branchStore.branch.busy_expire_in = details.busy_expire_in as unknown as Date;
    }
    cartStore.setModalValue('preorder', { visible: true, message });
  },
  CASHBACK: (code: number, amount: number) => {
    if (!cartStore.isCheckout && !cartStore.isCart) {
      return;
    }
    let modalMessage = localeStore.t(cashbackErrorDesc[code as CashbackErrorCode] || '');

    if (code === CashbackErrorCode.ChangedConditions) {
      const currency =
        localeStore.language === 'ar' ? appStore.settings?.wallet_currency_ar : appStore.settings?.wallet_currency;

      modalMessage += `. ${localeStore.t(
        'keep_cart_cashback_conditions_changed_popup_updated_cashback_amount'
      )} ${formatPrice(amount, currency)}`;
    }

    messagesStore.addMessage({
      id: 'cashback',
      type: 'modal',
      title: localeStore.t(cashbackErrorTitles[code as CashbackErrorCode] || ''),
      message: modalMessage,
      onRequestClose: cartStore.disableCashbackErrors,
      options: [
        {
          text: localeStore.t(
            cartStore.isCheckout ? 'txt_place_order' : 'keep_cart_cashback_conditions_changed_popup_show_cart_btn'
          ),
          className: 'full-width',
          onPress: () => {
            cartStore.disableCashbackErrors();
            if (cartStore.isCheckout) {
              cartStore._createOrder();
            } else {
              cartStore.openCart();
            }
          },
        },
      ],
    });
  },
};

export const RESPONSE_ERROR_HANDLER = async (error: any) => {
  if (!error) return;

  const { data, message } = error;

  if (data.code === 10106 || data.code === 10101) {
    CHECKOUT_HANDLERS.MESSAGE_EVENT({
      title: data?.message || message,
      options: [
        {
          className: 'background-light',
          textClassName: 'color-dark',
          text: localeStore.t('Close'),
          onPress: () => {
            cartStore.closeCheckout();
          },
        },
      ],
    });
    return;
  }
  if (data.code === 10026) {
    CHECKOUT_HANDLERS.PREORDER(data);
    return;
  }
  if (data.message || message) {
    CHECKOUT_HANDLERS.MESSAGE(data?.message || message);
  }
};
