import client from 'src/services/client';
import { ICartCheckout } from 'src/types/cart';
import { IPaymentCard } from 'src/types/user';

const user = {
  async fetchCards(branch_id?: number): Promise<IPaymentCard[]> {
    return await client.get(`/v3/me/cards`, { params: { branch_id } });
  },

  async addCrediCard(data: ICartCheckout['card'], branch_id?: number) {
    return await client.post(`/v3/me/cards`, { ...data, branch_id });
  },
};

export default user;
