import React from 'react';
import './style.scss';

type Props = {
  checked: boolean;
  onToggle: () => void;
  activeClassName?: string;
  inactiveClassName?: string;
};

const Toggle = ({
  checked,
  onToggle,
  activeClassName = 'background-primary',
  inactiveClassName = 'background-lightgray',
}: Props) => (
  <label className={`toggle-track ${checked ? activeClassName : inactiveClassName}`}>
    <input type="checkbox" defaultChecked={checked} onClick={onToggle} />
    <span className={`toggle-slider ${checked && 'toggle-slider--checked'}`}></span>
  </label>
);

export default Toggle;
