import { FC, ReactElement, memo } from 'react';
import { IProductInsideCombo } from 'src/types/combo';
import { IProductExclusion, IProductModifier } from 'src/types/products';
import { MealItemModifiers } from '../../../../../../containers/MealItemModifiers/MealItemModifiers';

interface IProps {
  item: IProductInsideCombo;
  modifiersErrors: string[];
  needScrollToError: boolean;
  readOnly?: boolean;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
  setNeedScrollToError: (need: boolean) => void;
}

export const Selectors: FC<IProps> = memo(({ item, ...props }): ReactElement | null => {
  // TODO: combo exclusions
  // selectedItem?.modifiers?.length || selectedItem?.exclusions?.length
  if (!item?.modifiers?.length) return null;

  return (
    <div className="product-scroll">
      <MealItemModifiers
        item={item}
        errors={props.modifiersErrors}
        needScrollToError={props.needScrollToError}
        readOnly={props.readOnly}
        onModifierChange={props.onModifierChange}
        setNeedScrollToError={props.setNeedScrollToError}
      />

      {/* TODO: combo exclusions */}
    </div>
  );
});
