import { observer } from 'mobx-react';
import * as React from 'react';
import './style.scss';
export interface ISwitcherProps {
  active: boolean;
  disabled?: boolean;
  firstButtonText: string;
  secondButtonText: string;
  firstButtonId?: string;
  secondButtonId?: string;
  onChange?: (active: boolean) => void;
}

export interface ISwitcherState {
  active: boolean;
  offset: number;
  width: number;
  ready: boolean;
}

@observer
class Switcher extends React.Component<ISwitcherProps, ISwitcherState> {
  firstButton: any = null;
  secondButton: any = null;
  state = {
    active: this.props.active,
    ready: false,
    offset: 0,
    width: 0,
  };

  componentDidUpdate = (props: ISwitcherProps) => {
    // if status was chenged in props it should be updated here
    if (props.active !== this.props.active) {
      this.setState({ active: this.props.active });
    }
  };

  toggle = () => {
    if (this.props.disabled) return;
    const active = !this.state.active;
    this.setState({ active });
    if (this.props.onChange) this.props.onChange(active);
  };

  public render() {
    const { firstButtonText, secondButtonText } = this.props;
    const { active } = this.state;
    return (
      <div
        aria-disabled={this.props.disabled}
        className="switcher th-background-secondary th-bg-background-secondary "
        onClick={this.toggle}
      >
        <div
          id={this.props.firstButtonId}
          ref={(ref) => (this.firstButton = ref)}
          className={`switcher-button th-color-secondary th-selected_active-secondary th-bg-selected_active-primary ${
            !active ? 'active' : ''
          }`}
        >
          <p className="switcher-text family-semibold ">{firstButtonText}</p>
        </div>

        <div
          id={this.props.secondButtonId}
          ref={(ref) => (this.secondButton = ref)}
          className={`switcher-button  th-color-secondary th-selected_active-secondary th-bg-selected_active-primary ${
            active ? 'active' : ''
          }`}
        >
          <p className="switcher-text family-semibold ">{secondButtonText}</p>
        </div>
      </div>
    );
  }
}

export default Switcher;
