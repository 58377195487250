import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { cartStore } from 'src/mobx/cartStore';
import PopupModal from 'src/view/components/Controls/Popup/Popup';
import ModalMessage from 'src/view/components/ModalMessage/ModalMessage';
import './style.scss';

interface Props extends RouteComponentProps<null> {}

@observer
class CheckoutModals extends Component<Props> {
  closeModal = () => {
    cartStore.setModalValue('error', null);
  };

  renderError = ({ closeModal }: { closeModal: () => void }) => {
    const { error } = cartStore.checkout.modals;
    return (
      <ModalMessage
        wrapperClassName={'px-0 pb-0 pt-3'}
        minWidth={320}
        title={error?.title}
        message={error?.message}
        options={error?.options}
        closeModal={closeModal}
      />
    );
  };

  render() {
    const { modals } = cartStore.checkout;

    return (
      <PopupModal
        position={'top'}
        direction={'down'}
        justify={'center'}
        selfClose
        backdrop
        onClose={this.closeModal}
        visible={!!modals.error}
        content={this.renderError}
        modalClassName="checkout-modal-error"
        mobile={{
          popup: true,
          sheet: false,
        }}
      />
    );
  }
}

export default withRouter(CheckoutModals);
