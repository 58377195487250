import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import { ICONS } from 'src/constants/icons';
import Icon from 'src/view/components/Controls/Icon/Icon';
import Spinner from 'src/view/components/Controls/Spinner/Spinner';
import './style.scss';
interface Props {
  onZoomLevelChanged: (increase: boolean) => void;
  setCenter: (center: GoogleMapReact.Coords, position?: GeolocationPosition | null) => void;
  getUserPosition: (goToMyLocation: boolean) => void;
  gettingUserPosition: boolean;
  showMyLocation?: boolean;
}

export default class BranchesMapControls extends Component<Props> {
  increaseZoomLevel = () => this.props.onZoomLevelChanged(true);
  decreaseZoomLevel = () => this.props.onZoomLevelChanged(false);

  handleMyLocation = () => {
    this.props.getUserPosition(true);
  };

  componentDidMount(): void {
    let prevState = 'denied';

    try {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((result) => {
          prevState = result.state;
          console.info(result.state);
          result.onchange = () => {
            console.info(result.state);
            if (prevState === 'prompt' && result.state === 'granted') {
              this.handleMyLocation();
            }
          };
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return (
      <div className="branches-controls-wrapper">
        <div className="branches-controls-control d-flex flex-column">
          <Icon
            onPress={this.increaseZoomLevel}
            name={ICONS.PLUS}
            size={20}
            className={'color-dark  branches-controls-icon'}
          />
          <Icon
            onPress={this.decreaseZoomLevel}
            name={ICONS.MINUS}
            size={20}
            className={'color-dark branches-controls-icon'}
          />
        </div>
        {this.props.showMyLocation ? (
          <div className="branches-controls-control  d-flex flex-column mt-4">
            <Icon
              onPress={this.handleMyLocation}
              disabled={this.props.gettingUserPosition}
              name={ICONS.TARGET}
              size={20}
              className={'color-dark branches-controls-icon'}
            />
            {this.props.gettingUserPosition ? <Spinner className={'branches-controls-spinner'} /> : null}
          </div>
        ) : null}
      </div>
    );
  }
}
