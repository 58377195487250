import * as React from 'react';
import { WINDOW_SIZES } from 'src/constants/main';
import { IBranch, IBranchArea } from 'src/types/branch';
import ResponsiveContent from 'src/view/components/ResponsiveContent/ResponsiveContent';
import BranchesSubmitButton from '../../components/BranchesSubmitButton/BranchesSubmitButton';
import PickupBranch from '../../components/PickupBranch/PickupBranch';
import PickupBranchSkeleton from '../../components/PickupBranch/PickupBranchSkeleton';
import './style.scss';
import BranchSearchBox from '../../components/BranchSearchBox/BranchSearchBox';
import { observer } from 'mobx-react';
import { branchStore } from '../../../../../mobx/barnchStore';
import { IMAGES } from '../../../../../constants/images';
import { localeStore } from '../../../../../mobx/localesStore';
export interface IDeliveryTypeSidebarProps {
  branches: IBranch[];
  onSelectBranch: (branch: IBranch) => void;
  delivery_type: string;
  loading: boolean;
  selected?: IBranch['id'] | undefined;
  branch: IBranch | null;
}

@observer
export default class DeliveryTypeSidebar extends React.Component<IDeliveryTypeSidebarProps> {
  renderBranch = (branch: IBranch) => {
    return (
      <PickupBranch
        key={branch.id}
        onSelectBranch={this.props.onSelectBranch}
        branch={branch}
        selected={this.props.selected}
      />
    );
  };
  emptySearch = () => {
    return (
      <div className="no_results-search">
        <img
          width="210"
          height="72"
          src={IMAGES.EMPTY_SEARCH}
          className="no_results-search-img"
          title="App Store"
          alt="App Store"
        />
        <p className="no_results-search-text">{localeStore.t('no_results_found')}</p>
      </div>
    );
  };

  /**
   * Format distance to normal format.
   *
   * Example "3,523.23 km" => 3523.23
   */
  formatDistance = (distance?: string) => {
    return distance ? Number(distance.split(' ')[0].replace(',', '')) : Infinity;
  };

  sortBranchesByDistance = (firstBranch: IBranch, secondBranch: IBranch) => {
    const firstDistance = this.formatDistance(firstBranch.distance);
    const secondDistance = this.formatDistance(secondBranch.distance);

    return firstDistance - secondDistance;
  };

  get branches() {
    if (branchStore.searchValue?.length) {
      return branchStore.searchBranch?.length ? branchStore.searchBranch?.map(this.renderBranch) : this.emptySearch();
    } else {
      return this.props.branches.sort(this.sortBranchesByDistance).map(this.renderBranch);
    }
  }

  get validateSearchBranches(): string {
    if (branchStore.searchValue?.length && !branchStore.searchBranch?.length) {
      return 'not_fount';
    }
    return '';
  }
  render() {
    return (
      <div
        className={`branches-sidebar animation slide_in_right d-flex flex-column ${
          branchStore.isOpenPickupMap ? 'hide-screen' : ''
        }`}
      >
        {!this.props.loading ? <BranchSearchBox customClass={'searchBranch'} searchMapIcon searchBranch /> : null}
        <div className="branches-sidebar-scroll d-flex flex-grow-1  hide-scrollbar">
          <div className={`branches-sidebar-list ${this.validateSearchBranches}  d-flex flex-column`}>
            {this.props.loading ? (
              <React.Fragment>
                <PickupBranchSkeleton />
                <PickupBranchSkeleton />
                <PickupBranchSkeleton />
                <PickupBranchSkeleton />
              </React.Fragment>
            ) : (
              this.branches
            )}
          </div>
        </div>
        {/* <ResponsiveContent max={WINDOW_SIZES.md}>
          <div className="d-flex flex-column px-3 py-3">
            <BranchesSubmitButton
              delivery_type={this.props.delivery_type}
              loading={this.props.loading}
              branch={this.props.branch}
            />
          </div>
        </ResponsiveContent>*/}
        <div className="d-flex flex-column px-3 py-3">
          <BranchesSubmitButton
            delivery_type={this.props.delivery_type}
            loading={this.props.loading}
            branch={this.props.branch}
          />
        </div>
      </div>
    );
  }
}
