import React, { FC } from 'react';
import { observer } from 'mobx-react';
import AddressForm from './components/AddressForm/AddressForm';
import branchesScreenStore from '../../branchesScreenStore';
import { VIEW_MODE } from '../../../../../types/address';
import { addressStore } from '../../../../../mobx/barnchStore/address';
import { IBranch } from '../../../../../types/branch';
interface IAddressForm {
  branch: IBranch | null;
}
const AddressFormDraft: FC<IAddressForm> = observer((props: IAddressForm) => {
  if (!addressStore.list.length || [VIEW_MODE.EDIT, VIEW_MODE.CREATE].includes(branchesScreenStore.viewMode))
    return <AddressForm branch={props.branch} />;
  return null;
});
export default AddressFormDraft;
