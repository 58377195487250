import GoogleMapReact from 'google-map-react';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ICONS } from 'src/constants/icons';
import { defaultBranch } from 'src/constants/main';
import { appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import client from 'src/services/client';
import { IReservation } from 'src/types/reservations';
import Icon from 'src/view/components/Controls/Icon/Icon';
import { Status } from 'src/view/components/Status/Status';
import { SCREENS } from '../../../constants/screens';
import Modal from '../../components/Controls/Modal/Modal';
import AddressPopup from '../BranchesScreen/containers/BranchPopup/AddressPopup';
import ReservationCancel from './components/ReservationCancel/ReservationCancel';
import { getUserPosition } from 'src/utils/helpers';
import './style.scss';
import Marker from 'src/view/components/Marker/Marker';

interface LicationState {
  reservation: IReservation | undefined;
}

interface IProps extends RouteComponentProps<{ id: string }, StaticContext, LicationState> {}

interface IState {
  isOpen: boolean;
  ready: boolean;
  reservation: IReservation | undefined;
  loading: boolean;
  userPosition: { lat: number; lng: number } | undefined;
}

@observer
export default class ReservationScreen extends React.Component<IProps, IState> {
  map: any = null;
  private timerID: any;
  state: IState = {
    isOpen: true,
    ready: false,
    reservation: this.props.location.state?.reservation,
    loading: false,
    userPosition: undefined,
  };

  componentDidMount = () => {
    this.getUserPosition();
    if (!this.state.reservation) {
      this.fetchReservation();
    }
    this.timerID = setInterval(() => {
      this.fetchReservation();
    }, 60000);
  };

  componentWillUnmount = () => {
    clearInterval(this.timerID);
  };

  getUserPosition = async (goToMyLocation = false) => {
    const position = await getUserPosition();
    if (position) {
      this.setState({
        userPosition: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    }
  };

  fetchReservation = async () => {
    try {
      const response = await client.get(`/v3/me/reservations/${this.props.match.params.id}`);
      this.setState({ reservation: response });
    } catch (error: any) {
      console.error(error);
      this.props.history.push(SCREENS.NOT_FOUND());
    }
  };

  closeModal = () => {
    this.setState({ isOpen: false }, () => {
      this.props.history.push(SCREENS.RESERVATIONS());
    });
  };

  render() {
    const { reservation, userPosition } = this.state;

    const date = reservation?.reservation_date || new Date();
    return (
      <Modal
        className="reservation-modal-wrapper d-flex flex-grow-1 flex-column"
        isOpen={this.state.isOpen}
        onRequestClose={this.closeModal}
      >
        {/* <Helmet title={'title_reservation'} /> */}
        <div className={`reservation-modal d-flex flex-grow-1 flex-column`}>
          <div className={'background-white reservation-head'}>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row align-items-center justify-content-between mb-4 mb-lg-5">
                <h1 className="color-primary family-bold">
                  {reservation ? `#${reservation.whaitlist_id}` : <Skeleton width={150} />}{' '}
                </h1>
                {reservation ? (
                  <Status statusCode={reservation?.status} status={localeStore.t(`txt_${reservation.status}`)} />
                ) : (
                  <Skeleton className={'status-component'} width={150} />
                )}
              </div>

              <div className="d-flex flex-column flex-lg-row">
                <div className="d-flex flex-column  mr-0 mr-lg-5  mb-4 mb-lg-0">
                  <p className="color-dark text-md-size-14 text-size-16  family-semibold mb-2 mb-lg-3">
                    {localeStore.t('txt_Date')}
                  </p>
                  <div className="d-flex flex-row align-items-center">
                    <Icon name={ICONS.CALENDAR} className="color-gray mr-3" />
                    <p className="color-darkgray text-uppercase text-md-size-14 text-size-18 family-regular">
                      {reservation ? (
                        `${localeStore.t(`month_${moment(date).format('M')}`)} ${moment(date).format('DD hh:mm a')}`
                      ) : (
                        <Skeleton width={200} />
                      )}
                    </p>
                  </div>
                </div>

                <div className="d-flex flex-column mr-0 mr-lg-5 mb-4 mb-lg-0">
                  <p className="color-dark text-md-size-14 text-size-16  family-semibold  mb-2 mb-lg-3">
                    {localeStore.t('reservation_type')}
                  </p>
                  <div className="d-flex flex-row align-items-center">
                    <Icon name={ICONS.BELL} className="color-gray mr-3" />
                    <p className="color-darkgray text-uppercase text-md-size-14 text-size-18 family-regular">
                      {reservation ? reservation.type : <Skeleton width={80} />}
                    </p>
                  </div>
                </div>

                <div className="d-flex flex-column  mr-0 mr-lg-5  ">
                  <p className="color-dark text-md-size-14 text-size-16  family-semibold  mb-2 mb-lg-3">
                    {localeStore.t('number_of_people')}
                  </p>
                  <div className="d-flex flex-row align-items-center">
                    <Icon name={ICONS.PROFILE} className="color-gray mr-3" />
                    <p className="color-darkgray text-uppercase text-md-size-14 text-size-18 family-regular">
                      {reservation?.people_number || <Skeleton width={50} />}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`d-flex flex-row flex-grow-1 `}>
            <div className={`branches-map-wrapper d-flex flex-grow-1`}>
              <GoogleMapReact
                zoom={16}
                onGoogleApiLoaded={async () => {
                  this.setState({ ready: true });
                }}
                bootstrapURLKeys={{
                  key: appStore.settings?.google_maps_pk || '',
                  libraries: [],
                }}
                center={
                  reservation
                    ? {
                        lat: reservation.branch.lat,
                        lng: reservation.branch.lng,
                      }
                    : {
                        lat: defaultBranch.latitude,
                        lng: defaultBranch.longitude,
                      }
                }
                ref={(map) => (this.map = map)}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  fullscreenControl: false,
                  draggable: false,
                  zoomControl: false,
                  scrollwheel: false,
                  maxZoom: 16,
                  minZoom: 16,
                }}
              >
                {/* User Position */}
                {userPosition ? (
                  <Marker lat={userPosition?.lat} lng={userPosition?.lng}>
                    <span className="user-dot" id="fuck" />
                  </Marker>
                ) : null}

                {this.state.ready && reservation ? (
                  <AddressPopup
                    lat={reservation.branch.lat || defaultBranch.latitude}
                    lng={reservation.branch.lng || defaultBranch.longitude}
                    address={reservation.branch.address}
                    loading={false}
                  />
                ) : null}
              </GoogleMapReact>
              <ReservationCancel reservation={this.state.reservation} loading={this.state.loading} />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
