import { IProductModifier, IProductModifierOption } from 'src/types/products';

export const validateMultiChoiseModifier = (modifier: IProductModifier) => {
  let valid = true;
  // get count of all selected options
  const count: number = modifier.options.reduce(
    (_count: number, option: IProductModifierOption) => (option.selected ? _count + (option.quantity || 1) : _count),
    0
  );
  if (modifier.maximum === 0) {
    if (modifier.minimum === 0)
      // select any amount
      valid = true;
    if (count < modifier.minimum)
      // selected less of minimum options
      valid = false;
  } else if (count > modifier.maximum) {
    // selected more of maximum options
    valid = false;
  } else if (count < modifier.minimum) {
    // selected less of minimum options
    valid = false;
  } else if (modifier.required && (!count || count < 0)) {
    // modifier is required
    valid = false;
  } else if (modifier.options.find((option) => option.quantity > option.maximum)) {
    valid = false;
    // minimum one option should be active
  }
  // else if (!modifier.options.some((option) => option.status)) {
  //   valid = false;
  // }
  return valid;
};

export const validateSingleChoiseModifier = (modifier: IProductModifier) => {
  // const modifierIdx = this.state.modifiers.findIndex()
  let valid = true;
  // get count of all selected options
  const count: number = modifier.options.reduce(
    (_count: number, option: IProductModifierOption) => (option.selected ? _count + (option.quantity || 1) : _count),
    0
  );

  // minimum one option should be active
  // if (!modifier.options.some((option) => option.status)) {
  //   valid = false;
  // }
  if (!count) {
    // minimum one option should be selected in single choice
    valid = false;
  } else if (count > 1) {
    // maximum one option should be selected in single choice
    valid = false;
  }
  return valid;
};
