import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DELIVERY_TYPES } from 'src/constants/deliveryTypes';
import { SCREENS } from 'src/constants/screens';
import { AppStore, appStore } from 'src/mobx/appStore';
import { localeStore } from 'src/mobx/localesStore';
import { STORES } from 'src/mobx/mainStore';
import { getRestaurantName, setMetaTags } from 'src/utils/helpers';
import ScreenWrapper from 'src/view/containers/ScreenWrapper/ScreenWrapper';
import { branchStore } from '../../../mobx/barnchStore';
import Modal from '../../components/Controls/Modal/Modal';
import branchesScreenStore from './branchesScreenStore';
import BranchesMapContainer from './containers/BranchesMapContainer/BranchesMapContainer';
import './style.scss';
interface Props extends RouteComponentProps<any> {
  appStore: AppStore;
}
interface State {
  loading: boolean;
  delivery_type: string;
  isAddressesScreen: boolean;
}

@observer
class BranchesScreen extends Component<Props, State> {
  state = {
    loading: true,
    delivery_type: '',
    isAddressesScreen: false,
  };

  componentDidMount = () => {
    setMetaTags({
      'og:title': `${getRestaurantName()} - ${localeStore.t('Pickup')}`,
      title: `${getRestaurantName()} - ${localeStore.t('Pickup')}`,
    });
    // if app is opened on first start and no settings we should wait until settings loaded
    if (!appStore.timestamp) return;
    // get delivery type
    this.getDeliveryType();
    // @ts-ignore
    branchesScreenStore.setFrom(this.props.location?.state?.from);
  };

  componentDidUpdate = () => {
    // if delivery type not was selected and settings is loaded we should check delivery type
    if (!this.state.delivery_type && this.props.appStore.timestamp) {
      this.getDeliveryType();
    }
  };

  getDeliveryType = () => {
    if (!appStore.settings) return '';

    let { delivery_type } = this.props.match.params;
    // @ts-ignore
    if (this.props.location.state?.address_id) {
      this.setState({ isAddressesScreen: true });
      setTimeout(() => {
        // @ts-ignore
        branchesScreenStore.onAddressEdit(this.props.location.state?.address_id);
        if (branchesScreenStore.address) {
          branchStore.setCoordinate({
            latitude: branchesScreenStore.address?.latitude,
            longitude: branchesScreenStore.address?.longitude,
          });
        }
      }, 1000);
    } else {
      this.setState({ isAddressesScreen: false });
    }

    if (delivery_type) {
      const delivery_types: string[] = [DELIVERY_TYPES.DELIVERY, DELIVERY_TYPES.PICKUP];
      // check if delivery type is available
      const isValidDeliveryType = typeof delivery_type === 'string' ? delivery_types.includes(delivery_type) : false;

      if (!isValidDeliveryType) {
        // set default delivery type if it invalid
        delivery_type =
          appStore.settings.delivery_type === DELIVERY_TYPES.BOTH
            ? DELIVERY_TYPES.DELIVERY
            : appStore.settings.delivery_type;
      }
    }

    if (appStore.settings.delivery_type !== DELIVERY_TYPES.BOTH) delivery_type = appStore.settings.delivery_type;

    this.setState({ delivery_type }, () => {
      // this.props.history.push({
      //   pathname: SCREENS.BRANCHES(delivery_type),
      //   search: `?language=${localeStore.language}`,
      // });
    });
  };

  closeModal = () => {
    if (branchesScreenStore.from) {
      this.props.history.push(branchesScreenStore.from);
    } else {
      this.props.history.push(this.state.isAddressesScreen ? SCREENS.MY_ADDRESSES() : SCREENS.MENU());
    }
    branchesScreenStore.backHandler();
  };

  render() {
    return (
      <ScreenWrapper>
        <Modal
          isOpen={true}
          className="container"
          closeBtnClass={'branchClose'}
          onRequestClose={this.closeModal}
          withCloseIcon
          withPane
        >
          <div className="branches background-white">
            {/* TODO: show here skeleton until settings will load */}
            {appStore.timestamp && this.state.delivery_type ? (
              <BranchesMapContainer delivery_type={this.state.delivery_type} />
            ) : null}
          </div>
        </Modal>
      </ScreenWrapper>
    );
  }
}

export default inject(STORES.APP_STORE)(BranchesScreen);
