import { FC, ReactElement, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { localeStore } from 'src/mobx/localesStore';
import { IMealComboGroupWithItems, IProductInsideCombo } from 'src/types/combo';
import { IProductExclusion, IProductModifier } from 'src/types/products';
import { formatPrice, getTranslate } from 'src/utils/formatter';
import { productUtils } from 'src/utils/productUtils';
import Image from 'src/view/components/Image/Image';
import { MealComboGroupMobileItems } from '../../containers/MealComboGroupMobileItems/MealComboGroupMobileItems';
import './style.scss';
import { MealComboGroupMobileItemModifiers } from '../../containers/MealComboGroupMobileItemModifiers/MealComboGroupMobileItemModifiers';
import ButtonComponent from 'src/view/components/Controls/Button/Button';

interface IProps {
  group: IMealComboGroupWithItems;
  modifiersErrors: string[];
  needScrollToError: boolean;
  readOnly?: boolean;
  onNotesChanged: (notes: string) => void;
  onSelectItem: (item: IProductInsideCombo) => void;
  onModifierChange: (editedComboItem: IProductInsideCombo, modifier: IProductModifier) => void;
  onExclusionsChange: (editedComboItem: IProductInsideCombo, exclusions: IProductExclusion[]) => void;
  setNeedScrollToError: (need: boolean) => void;
}

export const MealComboGroupMobile: FC<IProps> = memo(({ group, ...props }: IProps): ReactElement | null => {
  const groupRef = useRef<HTMLDivElement>(null);
  const [openItemsList, setOpenItemsList] = useState<boolean>(false);

  const selectedItem: IProductInsideCombo | undefined = group.items.find((item) => item.is_selected);
  const unavailableMessage = useMemo((): string | undefined => {
    if (!selectedItem) return undefined;

    const unavailable: string | undefined = productUtils.getComboItemUnavailableMessage(selectedItem, true);
    if (unavailable) return unavailable;

    const isSelectedItemModifierError: boolean = Boolean(
      selectedItem.modifiers?.some((modifier) =>
        productUtils.isMealModifierWithError(selectedItem, modifier.id, props.modifiersErrors)
      )
    );
    if (isSelectedItemModifierError) return 'missing_required_modifiers_combo_error';

    return;
  }, [props.modifiersErrors, selectedItem]);

  useEffect(() => {
    scrollOnError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unavailableMessage, props.needScrollToError]);

  const scrollOnError = () => {
    if (unavailableMessage && props.needScrollToError && props.setNeedScrollToError) {
      groupRef.current?.scrollIntoView({ behavior: 'smooth' });
      props.setNeedScrollToError(false);
    }
  };

  const onSelectItem = useCallback((item: IProductInsideCombo) => {
    props.onSelectItem(item);
    setOpenItemsList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!selectedItem) return null;

  return (
    <div ref={groupRef} className="product-meal-group-mobile__wrapper" onClick={() => setOpenItemsList(true)}>
      <p className="family-semibold text-size-14 color-dark text-overflow">{getTranslate(group.name)}</p>

      {unavailableMessage ? (
        <p className="product-meal-group-mobile__unavailable color-red">{localeStore.t(unavailableMessage)}</p>
      ) : null}

      <div className="product-meal-group-mobile">
        <div className="product-meal-group-mobile__content-main">
          <Image
            aspectRatio={1.125}
            src={selectedItem.image}
            className="product-meal-group-mobile__image"
            wrapperClassName={`product-meal-group-mobile__image-wrapper ${unavailableMessage ? ' opacity-50' : ''}`}
          />

          <div className="d-flex flex-column flex-grow-1 pxpy-2">
            <p className="family-semibold text-size-16 color-dark text-overflow">{getTranslate(selectedItem?.name)}</p>

            <div className="d-flex flex-row align-items-center justify-content-between pxmt-2">
              <ButtonComponent
                id={'replace'}
                disabled={false}
                onClick={undefined}
                className={'replace-button'}
                title={localeStore.t('replace')}
              />
              <p className="family-semibold text-size-14 color-dark">
                {formatPrice(productUtils.calculateProductInsideComboPrice(selectedItem))}
              </p>
            </div>
          </div>
        </div>

        <MealComboGroupMobileItemModifiers modifiers={selectedItem.modifiers} />
        {/* TODO: combo exclusions */}

        {openItemsList && (
          <MealComboGroupMobileItems
            group={group}
            readOnly={props.readOnly}
            onSelectItem={onSelectItem}
            onModifierChange={props.onModifierChange}
            onExclusionsChange={props.onExclusionsChange}
            onCloseList={() => setOpenItemsList(false)}
          />
        )}
      </div>
    </div>
  );
});
